import { Button, ButtonProps, Spinner, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export default function ElectRolloverButton({
  isSubmitting,
  fontSize = 'sm',
  size = 'sm',
  variant = 'secondary',
  w = 'full',
  ...props
}: { isSubmitting: boolean } & ButtonProps) {
  return (
    <Button fontSize={fontSize} size={size} variant={variant} w={w} {...props}>
      {isSubmitting && (
        <>
          <Spinner size="sm" mr="2" />{' '}
        </>
      )}
      <Text as="span" variant="body-sm/semibold">
        <Trans>Save edits</Trans>
      </Text>
    </Button>
  )
}
