import { Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import OverflowBox from '../../../../components/elements/OverflowBox'

export default function SummaryDetails({ text }: { text: ReactNode }) {
  return (
    <OverflowBox maxW="170px" w="full" textAlign="right" lineHeight={1}>
      <Text
        as="span"
        color="blue.9"
        variant="body-xs/medium"
        whiteSpace="nowrap"
      >
        {text}
      </Text>
    </OverflowBox>
  )
}
