import { Image } from '@chakra-ui/react'
import complete from '../../../../assets/icons/pointsCard/status-complete.svg'
import incomplete from '../../../../assets/icons/pointsCard/status-incomplete.svg'
import ongoing from '../../../../assets/icons/pointsCard/status-ongoing.svg'
import started from '../../../../assets/icons/pointsCard/status-started.svg'
import { CardStatus, PointsCardStatusProps } from '../../utils'
import PointsCardProgress from './PointsCardProgress'

const cardStatusMap: Record<CardStatus, string> = {
  complete,
  incomplete,
  ongoing,
  started,
}

const getImageSrc = (status: CardStatus) => cardStatusMap[status] ?? incomplete

const renderPointsCardProgress = (
  variant: CardStatus,
  startedTotal?: number,
  startedValue?: number,
  multiplier?: number
) => {
  return variant === 'started' && startedTotal ? (
    <PointsCardProgress
      color="blue.3"
      totalValue={startedTotal}
      value={startedValue ?? 0}
      multiplier={multiplier}
    />
  ) : null
}

export default function PointsCardStatus({
  variant,
  startedTotal,
  startedValue,
  multiplier,
}: PointsCardStatusProps) {
  return (
    renderPointsCardProgress(
      variant,
      startedTotal,
      startedValue,
      multiplier
    ) || (
      <Image
        boxSize={variant === 'started' ? '55px' : '43px'}
        src={getImageSrc(variant)}
        alt={variant}
      />
    )
  )
}
