import {
  Box,
  Button,
  Checkbox,
  Select,
  Skeleton,
  TableCellProps,
  TableContainer,
  Text,
} from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'

import dayjs from 'dayjs'
import { BigNumber, FixedNumber, constants } from 'ethers'
import {
  Table,
  TableBody as Tbody,
  Td,
  TableFoot as Tfoot,
  TableHead as Thead,
  Tr,
} from '../../../../components/elements/Table'

import { ReactNode, useEffect, useState } from 'react'
import Chip from '../../../../components/elements/Chip'
import TransactionHash from '../../../../components/elements/TransactionHash'
import {
  Currency,
  DraftBorrowEdit,
  SubmittedBorrowTender,
  TenderId,
} from '../../../../data/model'
import { EditErrors } from '../../../../data/parse'
import {
  bigToFixedNumber,
  fixedToBigNumber,
} from '../../../../helpers/conversions'
import { evaluate } from '../../../../helpers/math'
import {
  formatBigToken,
  formatFixedPercentage,
  formatFixedToken,
  formatFixedUsd,
  parseUserInput,
} from '../../../../helpers/utils'
import { getRequiredMargin } from '../../utils'
import EditableCell from './EditableCell'
import TableCell from './TableCell'
import TableHeaderCell from './TableHeaderCell'

function BorrowTableCell({
  paddingInline,
  textAlign,
  children,
  ...props
}: {
  paddingInline?: TableCellProps['paddingInline']
  textAlign?: TableCellProps['textAlign']
  children: ReactNode
}): JSX.Element {
  return (
    <TableCell
      paddingInline={paddingInline}
      n={8}
      textAlign={textAlign}
      {...props}
    >
      {children}
    </TableCell>
  )
}

export default function BorrowTendersTable({
  rows,
  purchaseCurrency,
  purchaseTokenPrice,
  collateralCurrency,
  collateralTokenPrice,
  initialMarginRatio,
  selectedRows,
  setSelectedRows,
  editing,
  isLoadingMissingRates,
  onOpenLoadInterestRate,
  getBlockExplorerTransactionUrl,
}: {
  rows: SubmittedBorrowTender[]
  purchaseCurrency: Currency
  purchaseTokenPrice: FixedNumber
  collateralCurrency: Currency
  collateralTokenPrice: FixedNumber
  initialMarginRatio: FixedNumber
  selectedRows: string[]
  setSelectedRows: (rows: string[]) => void
  editing:
    | false
    | {
        edits: Record<TenderId, DraftBorrowEdit>
        onChange: (id: TenderId, edit: DraftBorrowEdit) => void
      }
  isLoadingMissingRates: boolean
  onOpenLoadInterestRate: () => void
  getBlockExplorerTransactionUrl?: (address: string) => string
}) {
  const toggleAll = () => {
    if (rows.length === selectedRows.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(rows.map((row) => row.id))
    }
  }

  const toggleCheckbox = (id: string) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }

  const totalAmount = bigToFixedNumber(
    rows.reduce(
      (acc, tender) => acc.add(tender.amount?.shiftedValue ?? '0'),
      BigNumber.from(0)
    ),
    purchaseCurrency.decimals
  ).toFormat('fixed128x18')

  const totalRequiredCollateral = evaluate(
    {
      nodeKind: 'div',
      args: [
        {
          nodeKind: 'mul',
          args: [
            {
              nodeKind: 'value',
              value: totalAmount,
            },
            {
              nodeKind: 'value',
              value: purchaseTokenPrice,
            },
            {
              nodeKind: 'value',
              value: initialMarginRatio,
            },
          ],
        },
        {
          nodeKind: 'value',
          value: collateralTokenPrice,
        },
      ],
    },
    totalAmount.format.decimals
  )

  const totalRequiredCollateralUsd = evaluate({
    nodeKind: 'mul',
    args: [
      {
        nodeKind: 'value',
        value: totalRequiredCollateral,
      },
      {
        nodeKind: 'value',
        value: collateralTokenPrice,
      },
    ],
  })

  const totalDepositedCollateral = bigToFixedNumber(
    rows.reduce(
      (acc, tender) => acc.add(tender.collateral?.shiftedValue ?? '0'),
      BigNumber.from(0)
    ),
    collateralCurrency.decimals
  ).toFormat('fixed128x18')

  const totalDepositedCollateralUsd = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: totalDepositedCollateral,
        },
        {
          nodeKind: 'value',
          value: collateralTokenPrice,
        },
      ],
    },
    totalDepositedCollateral.format.decimals
  )

  const [asset, setAsset] = useState(collateralCurrency.symbol)

  useEffect(() => {
    if (editing) {
      setAsset(collateralCurrency.symbol)
    }
  }, [editing, collateralCurrency.symbol])

  const hasAllRowsWithMissingRates = rows.every((row) => !row.interestRate)

  return (
    <VStack w="full" spacing={0}>
      <HStack
        w="full"
        justify="flex-end"
        bg="gray.1"
        h={8}
        borderColor="gray.2"
        borderBottomWidth={0}
        borderTopRightRadius="md"
        borderTopLeftRadius="md"
      >
        <HStack
          w="33.350%"
          pl={6}
          borderLeftWidth="1px"
          borderLeftColor="gray.2"
          justifyContent="center"
        >
          <Text color="blue.9" variant="body-xs/medium">
            Collateral
          </Text>
          <Select
            size="sm"
            pt={0.5}
            pb={1}
            bg="gray.2"
            color="gray.6"
            fontWeight="medium"
            borderRadius={8}
            w="max-content"
            fontSize="xs"
            h={4}
            pl={0.5}
            pr={0}
            onChange={(e) =>
              rows.length > 0 ? setAsset(e.target.value) : undefined
            }
            value={asset}
            isDisabled={editing !== false}
          >
            <option value={collateralCurrency.symbol}>
              {collateralCurrency.symbol}
            </option>
            <option value={'usd'}>USD</option>
            <option value="ratio">Ratio</option>
          </Select>
        </HStack>
      </HStack>
      <TableContainer w="full" mt="0 !important">
        <Table isFixedLayout noPadding>
          <Thead>
            <Tr noBorder>
              <TableHeaderCell w="160px">
                <HStack spacing={4}>
                  {!!rows.length && (
                    <Checkbox
                      onChange={toggleAll}
                      isChecked={
                        !!rows.length && selectedRows.length === rows.length
                      }
                      isDisabled={editing !== false}
                    />
                  )}
                  <Box>Submitted</Box>
                </HStack>
              </TableHeaderCell>
              <TableHeaderCell textAlign="right" w="180px">
                <HStack spacing={1} w="full" justifyContent="flex-end">
                  <Text as="span">Borrow amount</Text>
                  <Chip size="xs">{purchaseCurrency.symbol}</Chip>
                </HStack>
              </TableHeaderCell>
              <TableHeaderCell textAlign="right" w="108px">
                <Text as="span" pr={1}>
                  Max. rate
                </Text>
                <Chip size="xs">APR</Chip>
              </TableHeaderCell>
              <TableHeaderCell
                textAlign="right"
                p="9px 40px 9px 8px"
                w="190px"
                borderTop="none"
              >
                Transaction
              </TableHeaderCell>
              <TableHeaderCell
                w={{
                  base: '120px',
                  xl: '168px',
                }}
                textAlign="right"
                borderLeftWidth="1px"
                borderTop="none"
              >
                Initial required
              </TableHeaderCell>
              <TableHeaderCell
                w={{
                  base: '237px',
                  xl: '322px',
                }}
                textAlign="right"
                p="8px 112px 8px 8px"
                borderTop="none"
              >
                Deposited
              </TableHeaderCell>
            </Tr>
          </Thead>

          {rows.map((row) => {
            const amount = bigToFixedNumber(
              row.amount.shiftedValue,
              purchaseCurrency.decimals
            )

            const { requiredMarginUsd, requiredMargin } = getRequiredMargin(
              editing
                ? FixedNumber.from(
                    !!editing.edits[row.id]?.amount
                      ? editing.edits[row.id].amount
                      : '0'
                  )
                : amount,
              initialMarginRatio,
              purchaseTokenPrice,
              collateralTokenPrice
            )

            const borrowedAmountUsd = evaluate(
              {
                nodeKind: 'mul',
                args: [
                  {
                    nodeKind: 'value',
                    value: amount,
                  },
                  {
                    nodeKind: 'value',
                    value: purchaseTokenPrice,
                  },
                ],
              },
              amount.format.decimals
            )

            const depositedAmount = bigToFixedNumber(
              row.collateral?.shiftedValue ?? '0',
              collateralCurrency.decimals
            )

            const depositedAmountUsd = evaluate(
              {
                nodeKind: 'mul',
                args: [
                  {
                    nodeKind: 'value',
                    value: depositedAmount,
                  },
                  {
                    nodeKind: 'value',
                    value: collateralTokenPrice,
                  },
                ],
              },
              depositedAmount.format.decimals
            )

            const depositedRatio = !depositedAmountUsd.isZero()
              ? evaluate(
                  {
                    nodeKind: 'div',
                    args: [
                      {
                        nodeKind: 'value',
                        value: depositedAmountUsd,
                      },
                      {
                        nodeKind: 'value',
                        value: borrowedAmountUsd,
                      },
                    ],
                  },
                  depositedAmount.format.decimals
                )
              : FixedNumber.fromString('0', depositedAmountUsd.format)

            const collateralError =
              editing && editing.edits[row.id]?.collateral
                ? ((editing.edits[row.id] as DraftBorrowEdit)
                    ?.globalCollateralError ??
                  (editing.edits[row.id] as DraftBorrowEdit)?.collateralError)
                : undefined

            return (
              <Tbody key={row.id}>
                <Tr
                  noBorder
                  bg={selectedRows.includes(row.id) ? '#F7FAFC' : 'inherit'}
                >
                  {/* Submitted */}
                  <BorrowTableCell>
                    <HStack spacing={4}>
                      <Checkbox
                        isChecked={selectedRows.includes(row.id)}
                        onChange={() => toggleCheckbox(row.id)}
                        isDisabled={editing !== false}
                      />
                      <Box>
                        {dayjs.unix(row.submittedDate).format('MMMM D, h:mma')}
                      </Box>
                    </HStack>
                  </BorrowTableCell>
                  {/* Borrow amount */}
                  {editing === false ||
                  !editing.edits.hasOwnProperty(row.id) ? (
                    <BorrowTableCell textAlign="right">
                      {formatBigToken(
                        row.amount.shiftedValue,
                        purchaseCurrency.decimals,
                        purchaseCurrency.symbol,
                        true
                      )}
                    </BorrowTableCell>
                  ) : (
                    <EditableCell
                      textAlign="right"
                      value={editing.edits[row.id].amount}
                      editingError={
                        editing.edits[row.id].amount
                          ? (editing.edits[row.id] as DraftBorrowEdit)
                              ?.amountError
                          : undefined
                      }
                      onChange={(event) =>
                        editing.onChange(row.id, {
                          ...editing.edits[row.id],
                          amount: parseUserInput(
                            event.target.value,
                            true,
                            purchaseCurrency.decimals
                          ),
                        })
                      }
                    />
                  )}
                  {/* Max. rate */}
                  {editing === false || !(row.id in editing.edits) ? (
                    <Skeleton
                      isLoaded={!isLoadingMissingRates || !!row.interestRate}
                    >
                      <BorrowTableCell textAlign="right">
                        {row.interestRate ? (
                          formatFixedPercentage(
                            row.interestRate,
                            undefined,
                            undefined,
                            FixedNumber.fromString('1.0', 'fixed128x18')
                          )
                        ) : !hasAllRowsWithMissingRates ? (
                          <Button
                            variant="link"
                            onClick={onOpenLoadInterestRate}
                            px={0}
                          >
                            <Text
                              as="span"
                              variant="body-xs/semibold"
                              color="blue.5"
                            >
                              Load missing rate
                            </Text>
                          </Button>
                        ) : (
                          `-`
                        )}
                      </BorrowTableCell>
                    </Skeleton>
                  ) : (
                    <EditableCell
                      textAlign="right"
                      value={
                        (editing.edits[row.id] as DraftBorrowEdit).interestRate
                      }
                      editingError={
                        editing.edits[row.id].interestRate
                          ? (editing.edits[row.id] as DraftBorrowEdit)
                              ?.interestRateError
                          : undefined
                      }
                      onChange={(event) =>
                        editing.onChange(row.id, {
                          ...editing.edits[row.id],
                          interestRate: event.target.value,
                        })
                      }
                    />
                  )}
                  {/* Transaction */}
                  <BorrowTableCell
                    textAlign="right"
                    paddingInline="8px 40px !important"
                  >
                    <TransactionHash
                      getBlockExplorerTransactionUrl={
                        getBlockExplorerTransactionUrl
                      }
                      address={row.transaction}
                      headChars={6}
                      tailChars={4}
                    />
                  </BorrowTableCell>
                  {/* Initial required */}
                  <BorrowTableCell
                    textAlign="right"
                    // @ts-ignore
                    color={
                      editing
                        ? collateralError === EditErrors.INSUFFICIENT_COLLATERAL
                          ? 'red.5'
                          : undefined
                        : undefined
                    }
                  >
                    {
                      // Collateral
                      asset === collateralCurrency.symbol
                        ? formatFixedToken(
                            requiredMargin,
                            collateralCurrency.symbol,
                            true
                          )
                        : // Ratio
                          asset === 'ratio'
                          ? !fixedToBigNumber(initialMarginRatio).eq(
                              constants.MaxUint256
                            )
                            ? formatFixedPercentage(initialMarginRatio)
                            : '∞'
                          : // USD
                            formatFixedUsd(requiredMarginUsd, false)
                    }
                  </BorrowTableCell>
                  {/* Deposited */}
                  {editing === false ||
                  !editing.edits.hasOwnProperty(row.id) ? (
                    <BorrowTableCell
                      paddingInline="8px 112px !important"
                      textAlign="right"
                    >
                      {
                        // Collateral
                        asset === collateralCurrency.symbol
                          ? formatFixedToken(
                              depositedAmount,
                              collateralCurrency.symbol,
                              true
                            )
                          : // Ratio
                            asset === 'ratio'
                            ? !fixedToBigNumber(depositedRatio).eq(
                                constants.MaxUint256
                              )
                              ? formatFixedPercentage(
                                  depositedRatio,
                                  undefined,
                                  undefined,
                                  undefined,
                                  true
                                )
                              : '∞'
                            : // USD
                              formatFixedUsd(depositedAmountUsd, false)
                      }
                    </BorrowTableCell>
                  ) : (
                    <EditableCell
                      maxW="calc(100% - 112px)"
                      textAlign="right"
                      value={editing.edits[row.id].collateral}
                      editingError={collateralError}
                      onChange={(event) =>
                        editing.onChange(row.id, {
                          ...editing.edits[row.id],
                          collateral: parseUserInput(
                            event.target.value,
                            true,
                            collateralCurrency.decimals
                          ),
                        })
                      }
                    />
                  )}
                </Tr>
              </Tbody>
            )
          })}
          {!rows.length && (
            <Tbody>
              <Tr noBorder>
                <Td colSpan={4} p="8px 20px 8px 20px" borderBottomWidth={0}>
                  You have not submitted any bids in this auction.
                </Td>
              </Tr>
            </Tbody>
          )}
          {!!rows.length && (
            <Tfoot noTopBorder>
              <Tr noBorder>
                <TableHeaderCell isBold>Total</TableHeaderCell>
                {/* Total Borrows */}
                <TableHeaderCell isBold textAlign="right">
                  {formatFixedToken(totalAmount, purchaseCurrency.symbol, true)}
                </TableHeaderCell>
                <TableHeaderCell isBold textAlign="right">
                  {hasAllRowsWithMissingRates && (
                    <Button
                      variant="link"
                      onClick={onOpenLoadInterestRate}
                      px={0}
                    >
                      <Text as="span" variant="body-xs/semibold" color="blue.5">
                        Load missing rates
                      </Text>
                    </Button>
                  )}
                </TableHeaderCell>
                <TableHeaderCell isBold> </TableHeaderCell>
                {/* Total Required */}
                <TableHeaderCell isBold textAlign="right">
                  {asset === collateralCurrency.symbol
                    ? formatFixedToken(
                        totalRequiredCollateral,
                        collateralCurrency.symbol,
                        true
                      )
                    : // Ratio
                      asset === 'ratio'
                      ? '-'
                      : // USD
                        formatFixedUsd(totalRequiredCollateralUsd, false)}
                </TableHeaderCell>
                {/* Total Deposited */}
                <TableHeaderCell isBold textAlign="right" p="8px 112px 8px 8px">
                  {asset === collateralCurrency.symbol
                    ? formatFixedToken(
                        totalDepositedCollateral,
                        collateralCurrency.symbol,
                        true
                      )
                    : // Ratio
                      asset === 'ratio'
                      ? '-'
                      : // USD
                        formatFixedUsd(totalDepositedCollateralUsd, false)}
                </TableHeaderCell>
              </Tr>
            </Tfoot>
          )}
        </Table>
      </TableContainer>
    </VStack>
  )
}
