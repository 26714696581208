import { FormControlProps } from '@chakra-ui/react'
import TokenAmountField from '../../../../components/elements/TokenAmountField'
import { FixedNumber } from 'ethers'
import { formatFixedToken } from '../../../../helpers/utils'
import { ReactNode } from 'react'
import { Trans } from '@lingui/macro'

const BurnTokenField = ({
  type = 'text',
  value,
  onChange,
  onChangeToken,
  isDisabled,
  isError,
  w,
  purchaseTokenSymbol,
  termTokenSymbol,
  termTokenBalance,
  termTokenDecimals,
  helperText,
  maxInputAmount,
}: {
  type?: string
  value: string
  onChange: (value: string) => void
  onChangeToken: (symbol: string) => void
  isDisabled?: boolean
  isError?: boolean
  w?: FormControlProps['w']
  purchaseTokenSymbol: string
  termTokenSymbol: string
  termTokenBalance: FixedNumber
  termTokenDecimals: number
  helperText: ReactNode
  maxInputAmount: FixedNumber
}) => {
  return (
    <TokenAmountField
      label="Repo Tokens (to burn)"
      rightLabel={
        <>
          <Trans>Available:</Trans>{' '}
          {formatFixedToken(
            termTokenBalance,
            purchaseTokenSymbol,
            true,
            false,
            termTokenSymbol
          )}
        </>
      }
      type={type}
      value={value}
      placeholder="0.00"
      onChange={onChange}
      onChangeToken={onChangeToken}
      onMax={() => {
        onChange(maxInputAmount.toString())
      }}
      isDisabled={isDisabled}
      isError={isError}
      w={w}
      tokenSymbol={termTokenSymbol}
      tokenDecimals={termTokenDecimals}
      // helperText is amount in USD
      helperText={helperText}
    />
  )
}

export default BurnTokenField
