import { Flex, Box, keyframes, FlexProps } from '@chakra-ui/react'

const pulse = keyframes({
  '0%': {
    boxShadow: '0 0 0 0px rgba(10, 94, 217, 0.4)',
  },
  '100%': {
    boxShadow: '0 0 0 8px rgba(10, 94, 217, 0)',
  },
})

export default function ClearingPulse(props: FlexProps) {
  return (
    <Flex
      w={props.w ?? 4}
      h={props.h ?? 4}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Box
        bgColor="#0A5ED9"
        width={2}
        height={2}
        borderRadius="9999px"
        boxShadow="0px 0px 1px 1px #0000001a"
        animation={`${pulse} 2s infinite`}
      />
    </Flex>
  )
}
