import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { ChainId, TransactionStatus, useSendTransaction } from '@usedapp/core'
import { Contract, BigNumberish, BigNumber } from 'ethers'
import { Wrappable } from '../../abi-generated'
import WrappableABI from '../../abi-external/Wrappable.json'
import { Address } from '../model'
import { useCallback, useEffect, useMemo } from 'react'
import { waitForStatus } from '../../helpers/wait'
import { useConfig } from '../../providers/config'

export function useTokenWrapper(
  chainId: ChainId,
  wrappedGasTokenAddress: Address,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  wrapGasToken: (value: BigNumberish) => Promise<void>
  unwrapGasToken: (value: BigNumberish) => Promise<void>
  wrapToken: (
    nakedCurrencyAddress: string,
    nakedCurrencySymbol: string,
    value: BigNumberish,
    currentBalance?: BigNumberish
  ) => Promise<void>
  unwrapToken: (nakedAddress: string, value: BigNumberish) => Promise<void>
} {
  const wrappedGasTokenContract = useMemo(
    () =>
      new Contract(wrappedGasTokenAddress, WrappableABI, provider) as Wrappable,
    [wrappedGasTokenAddress, provider]
  )

  // Auction is gonna be USDC vs WSTBT, user will bring either STBT or WSTBT
  // if user brings STBT, we need to wrap it to WSTBT
  // we will have to check the naked version of STBT against WSTBT

  const config = useConfig()
  const chainConfig = useMemo(
    () => config.chains[chainId.toString()],
    [config.chains, chainId]
  )

  const chainTokenContracts = useMemo(() => {
    const contracts: { [naked: string]: Wrappable } = {}
    chainConfig?.wrappedTokenMapping &&
      Object.entries(chainConfig.wrappedTokenMapping).forEach(
        ([wrapped, naked]) => {
          contracts[naked] = new Contract(
            wrapped,
            WrappableABI,
            provider
          ) as Wrappable
        }
      )
    return contracts
  }, [chainConfig?.wrappedTokenMapping, provider])

  // Native gas token wrapper
  const {
    sendTransaction: sendDepositTransaction,
    state: sendDepositState,
    resetState: resetDepositState,
  } = useSendTransaction()

  // Native gas token unwrapper
  const {
    sendTransaction: sendWithdrawTransaction,
    state: sendWithdrawState,
    resetState: resetWithdrawState,
  } = useSendTransaction()

  // Generic token wrapper
  const {
    sendTransaction: sendWrapTransaction,
    state: sendWrapState,
    resetState: resetWrapState,
  } = useSendTransaction()
  // Generic token unwrapper
  const {
    sendTransaction: sendUnwrapTransaction,
    state: sendUnwrapState,
    resetState: resetUnwrapState,
  } = useSendTransaction()

  const transactionStates = useMemo(
    () =>
      ({}) as {
        deposit: TransactionStatus | undefined
        withdraw: TransactionStatus | undefined
        amountToWrap: TransactionStatus | undefined
        wrap: TransactionStatus | undefined
        unwrap: TransactionStatus | undefined
      },
    []
  )

  useEffect(() => {
    transactionStates.deposit = sendDepositState
    transactionStates.withdraw = sendWithdrawState
    transactionStates.wrap = sendWrapState
    transactionStates.unwrap = sendUnwrapState
  }, [
    sendDepositState,
    sendWithdrawState,
    sendWrapState,
    sendUnwrapState,
    transactionStates,
  ])

  const validateActiveNetwork = useCallback(async () => {
    const activeNetwork = await provider?.getNetwork()
    return activeNetwork?.chainId === chainId
  }, [chainId, provider])

  const wrapGasToken = useCallback(
    async (value: BigNumberish) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        `wrapping gas token - depositing ${value} tokens to ${wrappedGasTokenAddress}`
      )

      const wrapData =
        wrappedGasTokenContract.interface.encodeFunctionData('deposit')

      await sendDepositTransaction({
        // TODO: add chain id
        to: wrappedGasTokenAddress,
        data: wrapData,
        value,
      })

      await waitForStatus(
        async () => transactionStates.deposit,
        resetDepositState,
        ['Success', 'Fail', 'Exception'],
        100,
        1000,
        wrappedGasTokenContract
      )

      const result = transactionStates?.deposit?.status ?? 'None'

      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }
    },
    [
      validateActiveNetwork,
      wrappedGasTokenAddress,
      wrappedGasTokenContract,
      sendDepositTransaction,
      resetDepositState,
      transactionStates.deposit,
    ]
  )

  const unwrapGasToken = useCallback(
    async (value: BigNumberish) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      console.info(
        `unwrapping gas token - withdrawing ${value} tokens from ${wrappedGasTokenAddress}`
      )

      const unwrapData = wrappedGasTokenContract.interface.encodeFunctionData(
        'withdraw',
        [value]
      )

      await sendWithdrawTransaction({
        // TODO: add chain id
        to: wrappedGasTokenAddress,
        data: unwrapData,
      })

      await waitForStatus(
        async () => transactionStates.withdraw,
        resetWithdrawState,
        ['Success', 'Fail', 'Exception'],
        100,
        1000,
        wrappedGasTokenContract
      )

      const result = transactionStates?.withdraw?.status ?? 'None'

      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }
    },
    [
      validateActiveNetwork,
      wrappedGasTokenAddress,
      wrappedGasTokenContract,
      sendWithdrawTransaction,
      resetWithdrawState,
      transactionStates.withdraw,
    ]
  )

  const wrapToken = useCallback(
    async (
      nakedCurrencyAddress: string,
      nakedCurrencySymbol: string,
      value: BigNumberish,
      currentBalance?: BigNumberish
    ) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      const contract = chainTokenContracts[nakedCurrencyAddress]

      console.info(
        `wrapping token - sending ${value} tokens from ${nakedCurrencyAddress} to ${contract.address}`
      )

      // TODO: add error handling + extrapolate this to a helper method and make more generic!
      let amountNecessaryToWrap: BigNumber
      switch (nakedCurrencySymbol) {
        case 'STBT':
        default:
          // wrapping takes STBT required amount as parameter
          const minAmountNecessaryToWrap = await contract
            .getStbtByWstbt(value)
            .then((result) => {
              console.info(`getStbtByWstbt result: ${result}`)
              return result
            })

          // handles rounding issue on wstbt
          if (!currentBalance) {
            amountNecessaryToWrap = minAmountNecessaryToWrap
          } else {
            amountNecessaryToWrap = minAmountNecessaryToWrap.add(
              BigNumber.from('2')
            )

            // avoid errors if adding 2 to minAmountNecessaryToWrap is greater than current balance
            if (amountNecessaryToWrap.gt(currentBalance)) {
              console.info('setting wrap amount to max balance')
              amountNecessaryToWrap = BigNumber.from(currentBalance)
            }
          }
          break
      }

      console.info(
        `sending ${amountNecessaryToWrap} tokens to be wrapped to ${contract.address}`
      )

      const wrapData = contract.interface.encodeFunctionData('wrap', [
        amountNecessaryToWrap,
      ])

      await sendWrapTransaction({
        // TODO: add chain id
        to: contract.address,
        data: wrapData,
      })

      await waitForStatus(
        async () => transactionStates.wrap,
        resetWrapState,
        ['Success', 'Fail', 'Exception'],
        100,
        1000,
        contract
      )

      const result = transactionStates?.wrap?.status ?? 'None'

      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }
    },
    [
      validateActiveNetwork,
      chainTokenContracts,
      sendWrapTransaction,
      resetWrapState,
      transactionStates.wrap,
    ]
  )

  const unwrapToken = useCallback(
    async (nakedAddress: string, value: BigNumberish) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      const contract = chainTokenContracts[nakedAddress]
      const unwrapData = contract.interface.encodeFunctionData('unwrap', [
        value,
      ])

      console.info(
        `unwrapping token - unwrapping ${value} tokens from ${contract.address} to ${nakedAddress}`
      )

      await sendUnwrapTransaction({
        // TODO: add chain id
        to: contract.address,
        data: unwrapData,
      })

      await waitForStatus(
        async () => transactionStates.unwrap,
        resetUnwrapState,
        ['Success', 'Fail', 'Exception'],
        100,
        1000,
        contract
      )

      const result = transactionStates?.unwrap?.status ?? 'None'

      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }
    },
    [
      validateActiveNetwork,
      chainTokenContracts,
      sendUnwrapTransaction,
      resetUnwrapState,
      transactionStates.unwrap,
    ]
  )

  return {
    wrapGasToken,
    unwrapGasToken,
    wrapToken,
    unwrapToken,
  }
}
