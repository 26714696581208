import { useState, useCallback } from 'react'
import { FixedNumber, BigNumber } from 'ethers'
import {
  Address,
  RevealTenderStruct,
  LoadMissingPricesRequest,
  LoadMissingPricesResponse,
  SubmittedLoanTender,
  SubmittedBorrowTender,
  SubmittedRollover,
} from '../model'
import { captureException } from '@sentry/react'
import { Signer } from 'ethers'
import { getSignInSignature } from '../../helpers/eip4361'
import { useStorage } from '../../providers/storage'

import {
  createOrUpdatePrivateBorrowTender,
  createOrUpdatePrivateLoanTender,
} from '../../services/private-bids-and-offers'
import { useConfig } from '../../providers/config'
import { serializeRollover } from '../../services/private-rollovers'

export function useMissingTenderRates(
  account: Address | undefined,
  loanTenders: SubmittedLoanTender[] | undefined,
  borrowTenders: SubmittedBorrowTender[] | undefined,
  signer: Signer | undefined
): [
  // load missing rates
  (
    chainId: string,
    bids: string[] | undefined,
    offers: string[] | undefined,
    isRollover?: boolean
  ) => Promise<void>,
  // is loading
  boolean,
  // has errored
  boolean,
] {
  const [hasFailed, setHasFailed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { storage: localStorage, updateStorage } = useStorage()
  const config = useConfig()

  const loadMissingRates = useCallback(
    async (
      chainId: string,
      bids: string[] | undefined,
      offers: string[] | undefined,
      isRollover: boolean = false
    ) => {
      try {
        console.info('Requesting missing interest rates...')

        const chainConfig = config.chains[chainId]

        const revealServerUrl = chainConfig?.revealServerUrl

        if (!revealServerUrl || !signer || !account) {
          console.error('Cannot load rates without signer or reveal server')
          return
        }

        setIsLoading(true)

        // get challenge
        const res = await fetch(
          `${revealServerUrl}/challenge?account=${account}`
        ).then((res) => res.json())
        const challenge: string = res.challenge

        // get signature
        const signature = await getSignInSignature(signer, challenge)

        // get private tenders
        const response = await fetch(`${revealServerUrl}/revealed`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          body: JSON.stringify({
            signature,
            account,
            bids: bids ?? [],
            offers: offers ?? [],
          } as LoadMissingPricesRequest),
        })

        // service is not available
        if (!response.ok) {
          throw new Error('Reveal service unavailable, bad response')
        }
        const revealedTenders =
          (await response.json()) as LoadMissingPricesResponse

        // store tenders in localStorage
        const loans: RevealTenderStruct[] = revealedTenders.offers
        const borrows: RevealTenderStruct[] = revealedTenders.bids
        await Promise.all([
          Promise.all(
            loans.map(async (r) => {
              const loanTender = loanTenders?.find((t) => t.id === r.id)
              if (loanTender) {
                const loan = {
                  ...loanTender,
                  interestRate: FixedNumber.fromValue(
                    BigNumber.from(r.price),
                    18
                  ).mulUnsafe(FixedNumber.from(100)), // TODO (matt): update result from reveal server
                  nonce: BigNumber.from(r.nonce),
                } as SubmittedLoanTender
                return createOrUpdatePrivateLoanTender(loan, localStorage)
              } else {
                console.warn('Could not find loan tender with id: ', r.id)
                return
              }
            })
          ),
          Promise.all(
            borrows.map(async (r) => {
              // update rollover local storage entry
              if (isRollover) {
                const rollover = {
                  chainId,
                  id: r.termId,
                  borrower: await signer.getAddress(),
                  interestRate: FixedNumber.fromValue(
                    BigNumber.from(r.price),
                    18
                  ).mulUnsafe(FixedNumber.from(100)), // TODO (matt): update result from reveal server
                } as SubmittedRollover
                return updateStorage(
                  'rollover-' + r.termId,
                  serializeRollover(rollover)
                )
              }

              const borrowTender = borrowTenders?.find((t) => t.id === r.id)
              if (borrowTender && !isRollover) {
                const borrow = {
                  ...borrowTender,
                  interestRate: FixedNumber.fromValue(
                    BigNumber.from(r.price),
                    18
                  ).mulUnsafe(FixedNumber.from(100)), // TODO (matt): update result from reveal server
                  nonce: BigNumber.from(r.nonce),
                } as SubmittedBorrowTender
                return createOrUpdatePrivateBorrowTender(borrow, localStorage)
              } else {
                console.warn('Could not find borrow tender with id: ', r.id)
                return
              }
            })
          ),
        ])
        setHasFailed(false)
        setIsLoading(false)
      } catch (err) {
        captureException(err)
        console.error('Failed to load missing rates: %o', err)
        setIsLoading(false)
        setHasFailed(true)
      }
    },
    [
      config.chains,
      signer,
      account,
      loanTenders,
      localStorage,
      borrowTenders,
      updateStorage,
    ]
  )

  return [loadMissingRates, isLoading, hasFailed]
}
