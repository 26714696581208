import { Button, ButtonProps, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export default function CollapseBorrowButton({
  onClick,
  isDisabled,
  isLoading,
}: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      isDisabled={isDisabled}
      variant="secondary"
      isLoading={isLoading}
      size="sm"
      w="full"
    >
      <Text as="span" variant="body-sm/semibold">
        <Trans>Collapse borrow</Trans>
      </Text>
    </Button>
  )
}
