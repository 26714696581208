import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { HStack } from '../../../../components/elements/Stack'
import { WalletAnalyzerProps } from '../../utils'
import { TotalPointsBox } from './TotalPointsBox'
import { UnlockPointsRow } from './UnlockPointsRow'

export const ResultsSummary = ({
  walletUserType,
  title,
  comments,
  bonusPoints,
  onCloseWalletAnalyzer,
  onAddAnotherWallet,
}: WalletAnalyzerProps) => {
  const [maxColWidth, setMaxColWidth] = useState(0)

  const handleColumnWidth = (width: number) => {
    setMaxColWidth((oldWidth) => Math.max(oldWidth, width))
  }

  const formattedWalletUserType =
    walletUserType !== 'up-and-comer'
      ? walletUserType.replace(/-/g, ' ')
      : walletUserType

  const bonusPointsFiltered = bonusPoints.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) => t.address === item.address && t.points === item.points
      )
  ) // remove any duplicated bonusPoints > address and points entries

  const totalLockedPoints = bonusPointsFiltered.reduce(
    (total, bonusPoint) => total + (bonusPoint.points ?? 0),
    0
  ) // totalLockedPoints is the sum of all the bonusPoints > points

  const isPending = bonusPoints.some((item) => item.points === undefined) //isPending if any of the bonusPoints > points are undefined

  const isExistingAccount = bonusPoints.some(
    (bonusPoint, index, self) =>
      self.filter((b, i) => b.address === bonusPoint.address && i !== index)
        .length > 0
  ) // isExistingAccount if any of the bonusPoints > address are duplicated

  return (
    <>
      <Flex gap={{ base: 8, xl: 48 }}>
        {/* left column */}
        <VStack align="start" w="610px">
          <VStack align="start" gap={4} mb={14}>
            <Text
              variant="body-l/medium"
              color="blue.9"
              textTransform="uppercase"
            >
              Hi, {formattedWalletUserType}!
            </Text>
            <Text variant="body-3xl/semibold" fontSize="38px" color="blue.5">
              {title}
            </Text>
          </VStack>
          <VStack align="start" gap={8} mb={9}>
            {comments.map((comment: string, index: number) => (
              <Text key={index} variant="body-xl/normal" color="blue.9">
                {comment}
              </Text>
            ))}
            <Text variant="body-xl/normal" color="blue.9">
              Otherwise, start unlocking your points by participating in Term.
            </Text>
          </VStack>
          <VStack align="start" mb={20} w="full">
            <Text variant="body-l/medium" color="blue.9" mb={2}>
              BONUS POINTS:
            </Text>
            {bonusPointsFiltered.map((item: any, index: any) => (
              <UnlockPointsRow
                key={index}
                points={item.points}
                address={item.address}
                handleColumnWidth={handleColumnWidth}
                maxColWidth={maxColWidth}
              />
            ))}
          </VStack>
          <HStack justify="end" w="full">
            {/* <LinkWalletButton
              variant="link"
              color="blue.5"
              onClick={onAddAnotherWallet}
            >
              Add another wallet
            </LinkWalletButton> */}
            <Button variant="secondary" onClick={onCloseWalletAnalyzer}>
              Go to dashboard
            </Button>
          </HStack>
        </VStack>
        {/* right column */}
        <VStack align="center" justify="center" w="fit-content">
          <TotalPointsBox
            totalLockedPoints={totalLockedPoints}
            isPending={isPending}
            isExistingAccount={isExistingAccount}
          />
        </VStack>
      </Flex>
      {/* {isExistingAccount && (
        <ModalToast
          title="Account already exists"
          subtitle="Try another or switch to that account"
          position="fixed"
          top={5}
          right={5}
        />
      )} */}
    </>
  )
}
