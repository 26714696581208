import {
  Heading,
  ModalBody as CModalBody,
  Text,
  Button,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'

export const ModalHeading = () => (
  <VStack align="left" mx={7} gap={0}>
    <Heading
      variant="heading/h1"
      fontWeight={600}
      lineHeight={'32px'}
      color="blue.9"
      as="h1"
    >
      Link wallet?
    </Heading>
  </VStack>
)

type BodyProps = {
  account?: string
  onSignMessage?: (isSkipWalletAnalyzerAnimation?: boolean) => void
  onSignUp: () => void
  onClose: () => void
}

export const ModalBody = ({
  account,
  onSignMessage,
  onSignUp,
  onClose,
}: BodyProps) => {
  const shortenedAddress = account
    ? `${account?.slice(0, 4)}..${account?.slice(account.length - 2, account.length)}`
    : ''

  return (
    <CModalBody pt={0}>
      <VStack gap={2}>
        <Text variant="body-md/normal" color="blue.9" fontWeight={400}>
          Would you like to link this wallet to your profile? Linking wallets
          will let you{' '}
          <Text as="span" fontWeight={600}>
            share your points and multipliers.
          </Text>
        </Text>
        <Text
          variant="body-sm/normal"
          color="blue.9"
          fontWeight={400}
          opacity={0.6}
        >
          By linking, you will be asked to sign a message (free!).
        </Text>
        <Button
          w="full"
          variant="secondary"
          onClick={() => (onSignMessage ? onSignMessage(false) : undefined)}
        >
          Yes, link wallet
        </Button>
      </VStack>
      <OrDivider />
      <VStack gap={2}>
        <Text
          variant="body-sm/normal"
          color="blue.9"
          fontWeight={400}
          opacity={0.6}
        >
          Create a new profile for {shortenedAddress} (you will be signed out).
        </Text>
        <Button w="full" variant="tertiary" onClick={onSignUp}>
          Create new profile
        </Button>
        <Button
          mt={2}
          size="md"
          variant="tertiary"
          border={0}
          w="100%"
          onClick={onClose}
        >
          Skip
        </Button>
      </VStack>
    </CModalBody>
  )
}

const OrDivider = () => (
  <Flex align="center" my={4} style={{ color: '#667085' }}>
    <Divider style={{ borderColor: '#BCBCBC' }} />
    <Text variant="body-sm/normal" fontWeight={400} padding="2">
      OR
    </Text>
    <Divider style={{ borderColor: '#BCBCBC' }} />
  </Flex>
)
