import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { TransactionState } from '@usedapp/core'
import dayjs from 'dayjs'
import { BigNumber, FixedNumber } from 'ethers'
import { useState } from 'react'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { Currency } from '../../../../data/model'
import { GAS_TOKEN_SYMBOL } from '../../../../helpers/constants'
import { bigToFixedNumber } from '../../../../helpers/conversions'
import { formatFixedUsd } from '../../../../helpers/utils'
import RedeemOverview from '../RedeemOverview'
import RedeemRolloverWarning from '../RedeemRolloverWarning'
import RedeemSummary from '../RedeemSummary'
import RedeemTermTokensField from '../RedeemTermTokensField'
import RedeemButton from './RedeemButton'
import { add, multiply, subtract } from '../../../../helpers/math'
import { isRejectedTxn } from '../../../../helpers/toasts'
import { captureException } from '@sentry/react'
import { Trans } from '@lingui/macro'

const zero = FixedNumber.fromString('0')

function RedeemHelperText({
  usdValueOfRedeemAmount,
  termTokenBalanceAfter,
}: {
  usdValueOfRedeemAmount: FixedNumber
  termTokenBalanceAfter: FixedNumber
}) {
  if (termTokenBalanceAfter.isNegative()) {
    return (
      <Text variant="body-xs/normal" color="red.5">
        <Trans>You cannot redeem more than your outstanding credit</Trans>
      </Text>
    )
  }

  return <p>{formatFixedUsd(usdValueOfRedeemAmount)}</p>
}

export default function RedeemModal({
  purchaseCurrency,
  isReceivedViaTokenTransfer,
  termTokenCurrency,
  termTokenPrice,
  termTokenBalance,
  termTokenPrincipal,
  termTokenInterest,
  collateralCurrency,
  purchasePrice,
  purchaseBalance,
  hasOutstandingRepoExposure,

  rolloverAmount,

  maturityTimestamp,

  defaultTokensToBurn,

  auctionLabel,

  onRedeem,

  isOpen,
  onClose,

  onKytCheck,
}: {
  purchaseCurrency: Currency

  isReceivedViaTokenTransfer: boolean
  termTokenCurrency: Currency
  termTokenPrice: FixedNumber
  termTokenBalance: FixedNumber
  termTokenPrincipal: BigNumber
  termTokenInterest: BigNumber

  collateralCurrency: Currency
  purchasePrice: FixedNumber
  purchaseBalance: FixedNumber
  hasOutstandingRepoExposure: boolean
  rolloverAmount: BigNumber

  maturityTimestamp: number

  defaultTokensToBurn?: string

  auctionLabel: string

  onRedeem: (
    termTokensToBurn: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>

  isOpen: boolean
  onClose: () => void
  onKytCheck: () => Promise<boolean>
}) {
  const termToast = useTermToast()

  const [tokensToBurnRaw, setTokensToBurnRaw] = useState(
    defaultTokensToBurn || ''
  )
  const [tokenSymbol, setTokenSymbol] = useState(termTokenCurrency.symbol)
  const [isRedeeming, setIsRedeeming] = useState(false)
  const ttPrincipalFn = bigToFixedNumber(
    termTokenPrincipal,
    termTokenCurrency.decimals
  )
  const ttInterestFn = bigToFixedNumber(
    termTokenInterest,
    termTokenCurrency.decimals
  )

  const onCloseModal = () => {
    setTokensToBurnRaw(defaultTokensToBurn || '')
    setTokenSymbol(termTokenCurrency.symbol)
    setIsRedeeming(false)
    onClose()
  }

  const ttPrincipalUsd = termTokenPrice
    ? multiply(ttPrincipalFn, termTokenPrice, termTokenPrice.format.decimals)
    : zero
  const ttInterestUsd = termTokenPrice
    ? multiply(ttInterestFn, termTokenPrice, termTokenPrice.format.decimals)
    : zero

  const purchaseBalanceUsd = multiply(
    purchaseBalance,
    purchasePrice,
    purchasePrice.format.decimals
  )

  const tokensToBurnFn = FixedNumber.fromString(
    tokensToBurnRaw || '0',
    termTokenBalance.format
  )

  const tokensToBurnUsd = termTokenPrice
    ? multiply(
        tokensToBurnFn,
        multiply(termTokenPrice, purchasePrice),
        tokensToBurnFn.format.decimals
      )
    : zero

  const termTokenBalanceAfter = subtract(
    termTokenBalance,
    tokensToBurnFn,
    termTokenBalance.format.decimals
  )

  const maturityStringForToast = dayjs.unix(maturityTimestamp).format('MMM D')

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} variant="standard">
      <ModalOverlay />
      <ModalContent p="4px">
        <ModalHeader>
          <Trans>Redeem Loan</Trans>
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody>
          <Text mb="20px" color="blue.9" variant="body-sm/normal">
            <Trans>
              Redeem your loan by burning the associated Repo Tokens.
            </Trans>
          </Text>
          <RedeemOverview
            purchaseTokenSymbol={purchaseCurrency.symbol}
            termTokenPrincipalUsd={ttPrincipalUsd}
            termTokenInterestUsd={ttInterestUsd}
            auctionLabel={auctionLabel}
          />
          <Box mb="20px" />
          <RedeemTermTokensField
            value={tokensToBurnRaw}
            onChange={setTokensToBurnRaw}
            onChangeToken={setTokenSymbol}
            termTokenBalance={termTokenBalance}
            termTokenSymbol={tokenSymbol}
            termTokenBalanceAfter={termTokenBalanceAfter}
            helperText={
              tokensToBurnRaw ? (
                <RedeemHelperText
                  usdValueOfRedeemAmount={tokensToBurnUsd}
                  termTokenBalanceAfter={termTokenBalanceAfter}
                />
              ) : undefined
            }
          />
          {rolloverAmount.gt(0) && (
            <>
              <Box mb="20px" />
              <RedeemRolloverWarning />
            </>
          )}
          <HStack
            justifyContent="center"
            mt="20px"
            mb={!tokensToBurnFn.isZero() ? '20px' : 0}
            w="full"
          >
            <RedeemButton
              isDisabled={
                isRedeeming ||
                tokensToBurnFn.isZero() ||
                subtract(
                  termTokenBalance,
                  tokensToBurnFn,
                  termTokenBalance.format.decimals
                ).isNegative()
              }
              onClick={async () => {
                setIsRedeeming(true)

                if (hasOutstandingRepoExposure) {
                  termToast.failure(termToastMessages.redeemLoan.paymentsDue)
                  onCloseModal()
                  return
                }

                // If there's no initial loan associated with this position, then perform
                // kyt check before allowing redemption
                if (isReceivedViaTokenTransfer) {
                  const hasKytCheckPassed = await onKytCheck()

                  if (!hasKytCheckPassed) {
                    console.error('unable to redeem tokens')
                    onCloseModal()
                    return
                  }
                }

                const redeemingPromise = onRedeem(
                  tokensToBurnFn,
                  tokenSymbol === GAS_TOKEN_SYMBOL
                )

                termToast.pending(termToastMessages.redeemLoan.pending)
                try {
                  await redeemingPromise
                  termToast.success(
                    termToastMessages.redeemLoan.success(
                      `${purchaseCurrency.symbol}(${collateralCurrency.symbol})-${maturityStringForToast}`
                    )
                  )
                  onCloseModal()
                } catch (error) {
                  console.error('error redeeming loan:', error)
                  captureException(error)
                  if (isRejectedTxn(error)) {
                    termToast.dismissed()
                  } else {
                    termToast.failure(termToastMessages.redeemLoan.failure)
                  }
                  onCloseModal()
                }
              }}
              isLoading={isRedeeming}
            />
          </HStack>
          {!tokensToBurnFn.isZero() && !termTokenBalanceAfter.isNegative() && (
            <RedeemSummary
              purchaseBalanceUsd={purchaseBalanceUsd}
              purchaseNewBalanceUsd={add(
                purchaseBalanceUsd,
                tokensToBurnUsd,
                purchaseBalanceUsd.format.decimals
              )}
              termTokenBalance={termTokenBalance}
              termTokenBalanceNew={termTokenBalanceAfter}
              termTokenSymbol={termTokenCurrency.symbol}
              purchaseTokenSymbol={purchaseCurrency.symbol}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
