import {
  Box,
  Button,
  ModalBody as CModalBody,
  HStack,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import closeIcon from '../../../../assets/icons/close.svg'
import { InfoIcon } from '../../../../components/elements/Icons'
import ProgressBarWithStepper from '../../../../components/elements/ProgressBarWithStepper'
import Tooltip from '../../../../components/elements/Tooltip'
import { formatNumber } from '../../utils'

const ColouredBox = () => <Box bg="cyan.4" w="8px" h="8px" borderRadius={2} />

type HeaderProps = {
  onClose: () => void
}

export const ModalHeader = ({ onClose }: HeaderProps) => (
  <VStack align="left" gap={'25px'}>
    <Box position="relative">
      <HStack gap={1.5}>
        <ColouredBox />
        <Text
          variant="body-xs/medium"
          fontStyle="normal"
          lineHeight="20px"
          color="gray.5"
        >
          Earn points for submitting offers & bids
        </Text>
      </HStack>
      <Text
        variant="body-2xl/medium"
        fontStyle="normal"
        lineHeight="24px"
        color="blue.9"
        mt="5px"
      >
        Participate in auctions
      </Text>
      <Box position="absolute" right={'4px'} bottom={'2px'}>
        <Image
          src={closeIcon}
          boxSize="16px"
          cursor="pointer"
          onClick={onClose}
        />
      </Box>
    </Box>
    <Text
      variant="body-sm/normal"
      fontStyle="nomral"
      fontWeight={400}
      color="blue.9"
      opacity={0.7}
    >
      Submit bids or offers at the interest rate that you’re comfortable with.
      When the auction ends, our smart contracts will automatically determine
      the optimal interest rate. Earn points depending on the rates you submit.
    </Text>
  </VStack>
)

const Blurred = () => (
  <Box
    as="span"
    opacity={0.5}
    style={{
      color: 'transparent',
      textShadow: '0 0 15px #001027, 0 0 10px #001027',
    }}
  >
    {/* <Tooltip label="Hidden to prevent gaming." noDelay placement="bottom-end"> */}
    0.0
    {/* </Tooltip> */}
  </Box>
)

const HowToRow = ({
  label,
  numPoints,
  info,
}: {
  label: string
  numPoints: number
  info?: string
}) => (
  <HStack
    justify="space-between"
    py={2.5}
    px={2}
    w="full"
    _odd={{ bg: 'blue.0' }}
  >
    <HStack gap="4px">
      <Text variant="body-sm/normal" fontWeight={400} color="gray.6">
        {label}
      </Text>
      <Box>
        {info && (
          <Tooltip leftAlign label={info} noDelay>
            <InfoIcon color="gray.4" boxSize="16px" />
          </Tooltip>
        )}
      </Box>
    </HStack>
    <HStack gap={'6px'}>
      <Text variant="body-sm/medium" color="blue.9">
        Earn {numPoints === 0 ? <Blurred /> : numPoints}
        {numPoints === 1 ? ' point' : ' points'} / dollar
      </Text>
      <ColouredBox />
    </HStack>
  </HStack>
)

const Participation = ({
  target,
  value,
  t,
}: {
  target: number
  value: number
  t: string
}) => (
  <Box>
    <Text variant="body-xs/medium" color="blue.9" opacity={0.5} mb="2px">
      {formatNumber(value, false, 1)}/{formatNumber(target, false, 1)} {t}{' '}
      assigned
    </Text>
    <Box w="200px">
      <ProgressBarWithStepper
        currentStep={value >= target ? 2 : 1}
        progressPercent={(value / target) * 100}
        totalSteps={2}
      />
    </Box>
  </Box>
)

type BodyProps = {
  totalLoansFilled: number
  totalLoansTendered: number
  totalBorrowsFilled: number
  totalBorrowsTendered: number
}

export const ModalBody = ({
  totalLoansFilled,
  totalLoansTendered,
  totalBorrowsFilled,
  totalBorrowsTendered,
}: BodyProps) => (
  <CModalBody p={0}>
    <Text variant="body-l/medium" color="blue.9">
      How to earn auction points
    </Text>
    <VStack mb={'30px'}>
      <HowToRow
        label={'Submit competitive offers'}
        numPoints={0.2}
        info="Competitive bids / offers within 80% / 125% of the auction clearing rate earn 0.2 points per dollar for the portion not assigned."
      />
      <HowToRow label={'Assigned bids or offers'} numPoints={0.5} />
    </VStack>
    <Text variant="body-sm/medium" color="blue.9" mb={3}>
      Track your participation
    </Text>
    <HStack justify="space-between" w="full">
      <Participation
        target={totalLoansTendered}
        value={totalLoansFilled}
        t="offers"
      />
      <Participation
        target={totalBorrowsTendered}
        value={totalBorrowsFilled}
        t={'bids'}
      />
    </HStack>
  </CModalBody>
)

export const ModalFooter = () => (
  <Box w="full" mt={'30px'}>
    <Link href={window.location.origin}>
      <Button w="full" variant="secondary">
        View auctions
      </Button>
    </Link>
  </Box>
)
