import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Spacer,
  Text,
  useDisclosure,
  Image,
  Flex,
  Link,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import Chip from '../../../../components/elements/Chip'
import nexusIcon from '../../../../assets/icons/projects/nexus.svg'
import { HStack, VStack } from '../../../../components/elements/Stack'
import {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from '../../../../helpers/constants'
import { externalLinks } from '../../../../links'
import { Trans, t } from '@lingui/macro'

export default function Sidebar({
  pathPrefix = '/portfolio',
  openOffersCount,
  openPositionsCount,
}: {
  pathPrefix?: string
  openOffersCount: number
  openPositionsCount: number
}) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  })
  const navigate = useNavigate()
  const location = useLocation()

  const isHistory = location.pathname.includes('history')
  const isOffers = location.pathname.includes('offers')
  const isPositions = location.pathname.includes('positions')
  return (
    <Box
      maxW={isOpen ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH}
      mr="8px"
      pos="sticky"
      top="20px"
      borderRightWidth="1px"
      borderColor="gray.2"
    >
      <HStack mt="26px" mr="8px" ml="8px" mb="8px">
        <Spacer />
        <Button
          size="xs"
          variant="link"
          onClick={() => (isOpen ? onClose() : onOpen())}
        >
          <ChevronLeftIcon
            color="blue.5"
            transform={isOpen ? '' : 'rotate(180deg)'}
          />
          <Text variant="body-xs/semibold" color="blue.5">
            {isOpen ? 'Hide' : 'Open'}
          </Text>
        </Button>
      </HStack>
      {isOpen && (
        <VStack w="full" gap="40px">
          <Box w="full">
            <Text pl="20px" variant="body-xs/semibold" color="gray.5">
              <Trans>Views</Trans>
            </Text>
            <SidebarOption
              selected={!isOffers && !isHistory && !isPositions}
              label={t`Summary`}
              onClick={() => navigate(pathPrefix)}
            />
            <SidebarOption
              selected={isPositions}
              label={t`Open Positions`}
              count={openPositionsCount}
              onClick={() => navigate(pathPrefix + '/positions')}
            />
            <SidebarOption
              selected={isOffers}
              label={t`Open Tenders`}
              count={openOffersCount}
              onClick={() => navigate(pathPrefix + '/offers')}
            />
            <SidebarOption
              selected={isHistory}
              label={t`History`}
              onClick={() => navigate(pathPrefix + '/history')}
            />
          </Box>
          <Box w="full">
            <Text pl="20px" variant="body-xs/semibold" color="gray.5">
              <Trans>Ecosystem</Trans>
            </Text>
            <SidebarOption
              selected={false}
              label={
                <Link
                  isExternal
                  href={externalLinks.nexusMutual}
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                  role="group"
                >
                  <Flex>
                    <Text
                      color="blue.9"
                      mr="5px"
                      my="auto"
                      transition="0.3s"
                      opacity={0.6}
                      _groupHover={{ opacity: 1 }}
                    >
                      <Trans>Buy cover</Trans>
                    </Text>
                    <Image
                      display="inline-block"
                      w="11.973px"
                      h="12px"
                      src={nexusIcon}
                      mr="5px"
                      my="auto"
                      transition="0.3s"
                      opacity={0.6}
                      _groupHover={{
                        opacity: 1,
                        transform: 'rotate(-90deg)',
                      }}
                    />
                    <ExternalLinkIcon
                      w="12px"
                      h="12px"
                      display="inline-block"
                      color="gray.4"
                      my="auto"
                    />
                  </Flex>
                </Link>
              }
              onClick={() => {}}
            />
          </Box>
        </VStack>
      )}
    </Box>
  )
}

function SidebarOption({
  selected,
  count,
  label,

  onClick,
}: {
  selected?: boolean
  count?: number
  label: React.ReactNode

  onClick?: () => void
}) {
  return (
    <HStack
      py="9px"
      px="20px"
      backgroundColor={selected ? 'rgba(228, 231, 235, 0.4)' : undefined}
      role="button"
      onClick={onClick}
      cursor={onClick ? 'pointer' : undefined}
      spacing="12px"
    >
      <Text
        as="span"
        variant="body-sm/semibold"
        color="blue.9"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
      {count !== undefined && <Chip>{count}</Chip>}
    </HStack>
  )
}
