import { useState, useEffect, useMemo, useRef } from 'react'
import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import {
  Address,
  Auction,
  Price,
  AuctionActivityData,
  TermPeriod,
} from '../model'
import { PageAuctionsQuery, PagePortfolioQuery } from '../../gql/graphql'
import { graphResultToAuction } from '../../helpers/conversions'
import { usePrices } from './use-prices'
import { useBombPots } from './use-bomb-pots'
import { BigNumber } from 'ethers'
import { ChainId } from '@usedapp/core'
import { useConfig } from '../../providers/config'
import { captureException } from '@sentry/react'
import { useCurrentTimeSlow } from './helper-hooks'
import dayjs from 'dayjs'
import { produce } from 'immer'
import { useExternalProjectPoints } from './use-external-project-points'

export function useGraphAuctions(
  provider: JsonRpcProvider | FallbackProvider | undefined,
  auctionsResult:
    | Record<string, PageAuctionsQuery | PagePortfolioQuery>
    | undefined,
  account: Address | undefined,
  lastEndTime?: number
): [
  // list of auctions
  Auction[] | null | undefined,
  // term data
  Partial<TermPeriod>[] | undefined,
  // bids/offers count data
  { [auctionAddress: string]: AuctionActivityData },
] {
  const config = useConfig()

  const currentTime = useCurrentTimeSlow()
  const bombPots = useBombPots(provider)
  const externalProjectPoints = useExternalProjectPoints()

  const previousPriceMapRef = useRef<
    { [chainId: string]: { [token: string]: Price } } | undefined
  >()

  const [auctions, setAuctions] = useState<Auction[] | undefined>()
  const [terms, setTerms] = useState<Partial<TermPeriod>[] | undefined>()
  const [auctionActivityData, setAuctionActivityData] = useState<{
    [auctionAddress: Address]: AuctionActivityData
  }>({})

  // Auctions are grouped by chain id
  const aggregatedAuctionsData = useMemo(() => {
    let allAuctions: Auction[] | undefined
    let allTerms: Partial<TermPeriod>[] | undefined

    const auctionActivityData: { [auctionId: string]: AuctionActivityData } = {}
    const tokensByChain: {
      [chainId: string]: { address: Address; decimals: number }[]
    } = {}

    if (auctionsResult) {
      Object.entries(auctionsResult).forEach(([chainId, result]) => {
        const tokens = new Set<Address>()

        if (!config.chains[chainId]) {
          captureException(
            new Error(
              `No chain config found for provided chain id: ${chainId}!`
            )
          )
        }

        // auctions to filter out is chain specific
        const auctionsToFilterOut = config.chains[chainId].auctionsToFilterOut

        const auctionsFromGraph =
          result?.termAuctions
            ?.map((auction) => graphResultToAuction(chainId, auction))
            ?.filter((result) =>
              lastEndTime ? result.auctionEndTimestamp >= lastEndTime : true
            )
            ?.filter(
              (result) =>
                !auctionsToFilterOut.includes(result.address.toLowerCase())
            ) ?? []

        // [DOSPORE] temp set for bombPots
        if (Number(chainId) === ChainId.Mainnet && bombPots) {
          auctionsFromGraph.forEach((auction) => {
            const bombPot = bombPots[auction.auctionId]
            if (bombPot) {
              auction.bombPotAuction = true
              auction.bombPotAmount = Number(bombPot.reward)
              auction.bombPotRewardTokenSymbol = bombPot.rewardTokenSymbol
            }
          })
        }

        if (externalProjectPoints) {
          auctionsFromGraph.forEach((auction) => {
            const externalProject =
              externalProjectPoints[Number(chainId) as ChainId]?.[
                auction.collateralCurrency
              ]
            if (externalProject) {
              auction.project = externalProject.project
            }
          })
        }

        const termsFromGraph =
          result?.termAuctions?.map((auction) => ({
            chainId,
            id: auction.term.id,
            repoServicerAddress: auction.term.termRepoServicer,
            rolloverManagerAddress: auction.term.termRepoRolloverManager,
            collateralManagerAddress: auction.term.termRepoCollateralManager,
            termRepoLockerAddress: auction.term.termRepoLocker,
            termRepoTokenAddress: auction.term.termRepoToken,
          })) ?? []

        // Aggregating auctions
        allAuctions = [...(allAuctions ?? []), ...auctionsFromGraph]
        // Aggregating terms
        allTerms = [...(allTerms ?? []), ...termsFromGraph]
        // Aggregating tokens
        auctionsFromGraph.forEach((auction) => {
          // only look up prices for tokens in auctions that are still active
          if (dayjs.unix(auction.auctionRevealTimestamp).isAfter(currentTime)) {
            tokens.add(auction.purchaseCurrency)
            tokens.add(auction.collateralCurrency)
          }
        })

        result?.termAuctions?.forEach((auction) => {
          if (dayjs.unix(auction.revealTime).isAfter(currentTime)) {
            tokensByChain[chainId] = tokensByChain[chainId] || []

            const addToken = (tokenAddress: Address, tokenDecimals: number) => {
              if (
                tokenAddress &&
                !tokensByChain[chainId].some(
                  (token) => token.address === tokenAddress
                )
              ) {
                tokensByChain[chainId].push({
                  address: tokenAddress,
                  decimals: tokenDecimals,
                })
              }
            }

            addToken(
              auction.term.purchaseToken,
              auction.term.purchaseTokenMeta?.decimals ?? 18
            )
            auction.term.collateralTokens?.forEach((token, index) => {
              addToken(
                token,
                auction.term.collateralTokensMeta?.[index]?.decimals
              )
            })
          }
        })

        // Calculate bids/offers count data
        result?.termBids?.forEach((bid) => {
          const auctionId = bid.auction?.id

          if (!auctionActivityData[auctionId]) {
            auctionActivityData[auctionId] = {
              bidsCount: 0,
              offersCount: 0,
              bidsAmount: BigNumber.from(0),
              offersAmount: BigNumber.from(0),

              accountBidsCount: 0,
              accountOffersCount: 0,
              accountBidsAmount: BigNumber.from(0),
              accountOffersAmount: BigNumber.from(0),
            }
          }

          auctionActivityData[auctionId].bidsCount += 1
          auctionActivityData[auctionId].bidsAmount = auctionActivityData[
            auctionId
          ].bidsAmount.add(bid.amount ?? 0)

          if (bid.bidder?.toLowerCase() === account?.toLowerCase()) {
            ;(auctionActivityData[auctionId].accountBidsCount as number) += 1
            auctionActivityData[auctionId].accountBidsAmount = (
              auctionActivityData[auctionId].accountBidsAmount as BigNumber
            ).add(bid.amount ?? 0)
          }
        })

        result?.termOffers?.forEach((offer) => {
          const auctionId = offer.auction?.id

          if (!auctionActivityData[auctionId]) {
            auctionActivityData[auctionId] = {
              bidsCount: 0,
              offersCount: 0,
              bidsAmount: BigNumber.from(0),
              offersAmount: BigNumber.from(0),

              accountBidsCount: 0,
              accountOffersCount: 0,
              accountBidsAmount: BigNumber.from(0),
              accountOffersAmount: BigNumber.from(0),
            }
          }

          auctionActivityData[auctionId].offersCount += 1
          auctionActivityData[auctionId].offersAmount = auctionActivityData[
            auctionId
          ].offersAmount.add(offer.amount ?? 0)

          if (offer.offeror?.toLowerCase() === account?.toLowerCase()) {
            ;(auctionActivityData[auctionId].accountOffersCount as number) += 1
            auctionActivityData[auctionId].accountOffersAmount = (
              auctionActivityData[auctionId].accountOffersAmount as BigNumber
            ).add(offer.amount ?? 0)
          }
        })
      })
      setTerms(allTerms)
      setAuctionActivityData(auctionActivityData)
    }
    return {
      allAuctions,
      allTerms,
      tokensByChain,
      auctionActivityData,
      externalProjectPoints,
    }
  }, [
    auctionsResult,
    config.chains,
    bombPots,
    lastEndTime,
    currentTime,
    account,
    externalProjectPoints,
  ])

  const prices = usePrices(
    aggregatedAuctionsData.tokensByChain,
    undefined,
    provider
  )

  const [priceMap, setPriceMap] = useState<{
    [chainId: string]: { [token: Address]: Price }
  }>({})
  useEffect(() => {
    if (prices) {
      setPriceMap(
        produce(
          (draftState: { [chainId: string]: { [token: Address]: Price } }) => {
            Object.entries(prices).forEach(([chainId, priceArray]) => {
              if (!draftState[chainId]) {
                draftState[chainId] = {}
              }
              priceArray.forEach((price) => {
                if (!draftState[chainId][price.token]) {
                  draftState[chainId][price.token] = price
                } else if (
                  !draftState[chainId][price.token].price.eq(price.price)
                ) {
                  draftState[chainId][price.token].price = price.price
                }
              })
            })
          }
        )
      )
    }
  }, [prices])

  useEffect(() => {
    if (aggregatedAuctionsData.allAuctions) {
      setAuctions(
        aggregatedAuctionsData.allAuctions.map((auction) => ({
          ...auction,
          purchaseCurrencyOraclePriceUSDC:
            priceMap[auction.chainId]?.[auction.purchaseCurrency]?.price ??
            auction.purchaseCurrencyOraclePriceUSDC,
          purchaseCurrencyOraclePriceDecimals:
            priceMap[auction.chainId]?.[auction.purchaseCurrency]?.decimals ??
            18,
          collateralCurrencyOraclePriceUSDC:
            priceMap[auction.chainId]?.[auction.collateralCurrency]?.price ??
            auction.collateralCurrencyOraclePriceUSDC,
          collateralCurrencyOraclePriceDecimals:
            priceMap[auction.chainId]?.[auction.collateralCurrency]?.decimals ??
            18,
        }))
      )
      previousPriceMapRef.current = priceMap
    }
  }, [aggregatedAuctionsData.allAuctions, priceMap])

  return [auctions, terms, auctionActivityData]
}
