import { createIcon } from '@chakra-ui/icons'

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 11 12',
  path: (
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.69627 5.1813V4.10895C1.69627 2.16324 3.27357 0.585938 5.21928 0.585938C7.16497 0.585938 8.74229 2.16324 8.74229 4.10895V5.1813C9.32413 5.22478 9.703 5.33444 9.97994 5.61142C10.4386 6.07004 10.4386 6.80815 10.4386 8.28437C10.4386 9.76059 10.4386 10.4987 9.97994 10.9573C9.52132 11.4159 8.78321 11.4159 7.30699 11.4159H3.13157C1.65533 11.4159 0.917215 11.4159 0.458607 10.9573C0 10.4987 0 9.76059 0 8.28437C0 6.80815 0 6.07004 0.458607 5.61142C0.735558 5.33444 1.11445 5.22478 1.69627 5.1813ZM2.47916 4.10895C2.47916 2.59563 3.70595 1.36883 5.21928 1.36883C6.73261 1.36883 7.9594 2.59563 7.9594 4.10895V5.15468C7.7595 5.1528 7.54264 5.1528 7.30699 5.1528H3.13157C2.89591 5.1528 2.67906 5.1528 2.47916 5.15468V4.10895Z"
      fill="currentColor"
    />
  ),
})

export const CopyIcon = createIcon({
  displayName: 'CopyIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M17.6842 0H2.52632C1.13684 0 0 0.981818 0 2.18182V17.4545H2.52632V2.18182H17.6842V0ZM21.4737 4.36364H7.57895C6.18947 4.36364 5.05263 5.34545 5.05263 6.54545V21.8182C5.05263 23.0182 6.18947 24 7.57895 24H21.4737C22.8632 24 24 23.0182 24 21.8182V6.54545C24 5.34545 22.8632 4.36364 21.4737 4.36364ZM21.4737 21.8182H7.57895V6.54545H21.4737V21.8182Z"
      fill="currentColor"
    />
  ),
})

export const DeleteIcon = createIcon({
  displayName: 'DeleteIcon',
  viewBox: '0 0 12 12',
  path: (
    <path
      d="M6.41602 5.19483L10.583 1.02783C10.9665 0.644361 11.5882 0.644361 11.9717 1.02783V1.02783C12.3552 1.4113 12.3552 2.03303 11.9717 2.4165L7.80469 6.5835L11.9717 10.7505C12.3552 11.134 12.3552 11.7557 11.9717 12.1392V12.1392C11.5882 12.5226 10.9665 12.5226 10.583 12.1392L6.41602 7.97217L2.24902 12.1392C1.86555 12.5226 1.24382 12.5226 0.860351 12.1392V12.1392C0.47688 11.7557 0.47688 11.134 0.860351 10.7505L5.02735 6.5835L0.860352 2.4165C0.476881 2.03303 0.47688 1.4113 0.860351 1.02783V1.02783C1.24382 0.64436 1.86555 0.644361 2.24902 1.02783L6.41602 5.19483Z"
      fill="currentColor"
    />
  ),
})

export const ArrowUpRight = createIcon({
  displayName: 'ArrowUpRight',
  viewBox: '0 0 12 12',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41667 3.45833C4.41667 3.2052 4.62187 3 4.875 3H8.54167C8.7948 3 9 3.2052 9 3.45833V7.125C9 7.37813 8.7948 7.58333 8.54167 7.58333C8.28854 7.58333 8.08333 7.37813 8.08333 7.125V4.56485L4.28242 8.36576C4.10343 8.54475 3.81323 8.54475 3.63424 8.36576C3.45525 8.18677 3.45525 7.89657 3.63424 7.71758L7.43515 3.91667H4.875C4.62187 3.91667 4.41667 3.71146 4.41667 3.45833Z"
      fill="currentColor"
    />
  ),
})

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18.3333C5.58 18.3333 1.66667 14.42 1.66667 10C1.66667 5.58 5.58 1.66667 10 1.66667C14.42 1.66667 18.3333 5.58 18.3333 10C18.3333 14.42 14.42 18.3333 10 18.3333Z"
        fill="currentColor"
      />
      <path
        d="M9.16602 13.6667V8.83341C9.16602 8.32715 9.57642 7.91675 10.0827 7.91675C10.5889 7.91675 10.9993 8.32715 10.9993 8.83341V13.6667C10.9993 14.173 10.5889 14.5834 10.0827 14.5834C9.57642 14.5834 9.16602 14.173 9.16602 13.6667Z"
        fill="currentColor"
      />
      <path
        d="M10.0827 5C10.5889 5 10.9993 5.41041 10.9993 5.91667C10.9993 6.42293 10.5889 6.83333 10.0827 6.83333C9.57642 6.83333 9.16602 6.42293 9.16602 5.91667C9.16602 5.41041 9.57642 5 10.0827 5Z"
        fill="currentColor"
      />
    </>
  ),
})
