import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Spinner,
} from '@chakra-ui/react'
import { VStack, HStack } from '../Stack'
import { BigNumber } from 'ethers'
import ApproveStepIndicator from '../ApproveStepIndicator'
import WrapApproveButton from './WrapApproveButton'
import { Currency } from '../../../data/model'
import { ModalHeader, ModalHeading, ModalSubHeading } from './modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTermToast } from '../../../hooks/toasts'
import { isRejectedTxn, termToastMessages } from '../../../helpers/toasts'
import { useConfig } from '../../../providers/config'

export default function WrapApproveModal({
  unwrappedBalance,
  unwrappedCurrency,
  wrappedCurrency,
  wrappingApprovedAmount,
  amountToApproveForWrapping,
  amountToWrap,
  termContractApprovedAmount,
  amountToApproveForTermContract,
  tenderType = 'bids',
  isNativeCurrency,
  isOpen,
  onWrapNativeToken,
  onApproveNakedToken,
  onWrapNakedToken,
  onApproveWrappedToken,
  onKytCheck,
  onClose,
}: {
  unwrappedBalance?: BigNumber
  unwrappedCurrency: Currency
  wrappedCurrency: Currency
  wrappingApprovedAmount: BigNumber
  amountToApproveForWrapping: BigNumber
  amountToWrap: BigNumber
  termContractApprovedAmount: BigNumber
  amountToApproveForTermContract: BigNumber
  tenderType?: string
  isNativeCurrency: boolean
  isOpen: boolean
  onWrapNativeToken: (amount: BigNumber) => Promise<void>
  onApproveNakedToken: (amount: string) => Promise<void>
  onWrapNakedToken: (
    address: string,
    symbol: string,
    amount: BigNumber,
    balance?: BigNumber
  ) => Promise<void>
  onApproveWrappedToken: (amount?: string) => Promise<void>
  onKytCheck: () => Promise<boolean>
  onClose: (completed: boolean) => void
}) {
  const termToast = useTermToast()
  const config = useConfig()

  const [approvingNaked, setApprovingNaked] = useState<boolean>(false)
  const [hasApprovedNaked, setHasApprovedNaked] = useState<boolean>(false)

  const [wrapping, setWrapping] = useState<boolean>(false)
  const [hasWrapped, setHasWrapped] = useState<boolean>(false)

  const [approvingWrapped, setApprovingWrapped] = useState<boolean>(false)
  const [hasApprovedWrapped, setHasApprovedWrapped] = useState<boolean>(false)

  const wrappingNeedsApproval = useMemo(
    () =>
      !isNativeCurrency &&
      amountToApproveForWrapping.gt(wrappingApprovedAmount),
    [amountToApproveForWrapping, isNativeCurrency, wrappingApprovedAmount]
  )

  const defaultActiveStep = useMemo(() => {
    return wrappingNeedsApproval ? 'approveNaked' : 'wrapCurrency'
  }, [wrappingNeedsApproval])

  // set to correct default values
  const [activeStep, setActiveStep] = useState<
    'approveNaked' | 'wrapCurrency' | 'approveWrapped' | undefined
  >(undefined)

  const termContractNeedsApproval = useMemo(
    () => amountToApproveForTermContract.gt(termContractApprovedAmount),
    [amountToApproveForTermContract, termContractApprovedAmount]
  )

  // determine the correct step to activate
  useEffect(() => {
    let newActiveStep:
      | 'approveNaked'
      | 'wrapCurrency'
      | 'approveWrapped'
      | undefined = undefined
    if (wrappingNeedsApproval && !hasApprovedNaked) {
      newActiveStep = 'approveNaked'
    } else if (!hasWrapped) {
      newActiveStep = 'wrapCurrency'
    } else if (termContractNeedsApproval && hasWrapped && !hasApprovedWrapped) {
      newActiveStep = 'approveWrapped'
    }

    if (newActiveStep && newActiveStep !== activeStep) {
      setActiveStep(newActiveStep)
    }
  }, [
    wrappingNeedsApproval,
    hasApprovedNaked,
    hasWrapped,
    termContractNeedsApproval,
    hasApprovedWrapped,
    activeStep,
  ])

  const buttonIcon = (
    initial: number,
    isInProgress: boolean,
    isFinished: boolean
  ) => {
    switch (true) {
      case isInProgress:
        return <Spinner size="sm" />
      case isFinished:
        return <FontAwesomeIcon icon={['far', 'check']} color="blue.5" />
      default:
        return initial
    }
  }

  const approveNaked = async () => {
    setApprovingNaked(true)
    termToast.pending(
      termToastMessages.approving.pending(unwrappedCurrency.symbol)
    )
    try {
      // set high default value for approval for wrapping
      await onApproveNakedToken(config.approveAmount)
      termToast.success(
        termToastMessages.approving.success(unwrappedCurrency.symbol)
      )
      setHasApprovedNaked(true)
      setActiveStep('wrapCurrency')
    } catch (e) {
      console.error(e)
      if (isRejectedTxn(e)) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.approving.failure)
      }
    } finally {
      setApprovingNaked(false)
    }
  }

  const wrapCurrency = async () => {
    setWrapping(true)
    termToast.pending(
      termToastMessages.wrapping.pending(
        unwrappedCurrency.symbol,
        wrappedCurrency.symbol
      )
    )

    try {
      if (isNativeCurrency) {
        await onWrapNativeToken(amountToWrap)
      } else {
        await onWrapNakedToken(
          unwrappedCurrency.address,
          unwrappedCurrency.symbol,
          amountToWrap,
          unwrappedBalance
        )
      }

      termToast.success(
        termToastMessages.wrapping.success(unwrappedCurrency.symbol)
      )
      setHasWrapped(true)
      setActiveStep('approveWrapped')
    } catch (e) {
      console.error(e)
      if (isRejectedTxn(e)) {
        termToast.dismissed()
      } else {
        termToast.failure(
          termToastMessages.wrapping.failure(unwrappedCurrency.symbol)
        )
      }
    } finally {
      setWrapping(false)
    }
  }

  const approveWrapped = async () => {
    setApprovingWrapped(true)
    termToast.pending(
      termToastMessages.approving.pending(wrappedCurrency.symbol)
    )
    try {
      const hasKytCheckPassed = await onKytCheck()

      if (!hasKytCheckPassed) {
        setApprovingWrapped(false)
        return
      }

      await onApproveWrappedToken(amountToApproveForTermContract.toString())
      termToast.success(
        termToastMessages.approving.success(wrappedCurrency.symbol)
      )
      setHasApprovedWrapped(true)
    } catch (e) {
      console.error(e)
      if (isRejectedTxn(e)) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.approving.failure)
      }
    } finally {
      setApprovingWrapped(false)
    }
  }

  const onModalClose = (completed: boolean) => {
    setApprovingNaked(false)
    setHasApprovedNaked(false)
    setWrapping(false)
    setHasWrapped(false)
    setApprovingWrapped(false)
    setHasApprovedWrapped(false)
    setActiveStep(defaultActiveStep)
    onClose(completed)
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => onModalClose(false)}>
        <ModalOverlay />
        <ModalContent pb={7} maxW="480px">
          <ModalHeader>
            <ModalHeading>{`Wrap ${unwrappedCurrency.symbol}`}</ModalHeading>
            <ModalSubHeading>{`Wrap your ${unwrappedCurrency.symbol} to ${wrappedCurrency.symbol} to prepare it for the auction`}</ModalSubHeading>
          </ModalHeader>
          <ModalBody pb={1.25} pt={1.25}>
            <VStack w="full" alignItems="flex-start" spacing={3}>
              {wrappingNeedsApproval && (
                <Box opacity={activeStep === 'approveNaked' ? 1.0 : 0.6}>
                  <HStack>
                    <ApproveStepIndicator
                      bg="blue.1"
                      color="blue.500"
                      position="static"
                    >
                      {buttonIcon(1, approvingNaked, hasApprovedNaked)}
                    </ApproveStepIndicator>
                    <VStack align="left" spacing={0.25}>
                      <Text variant="body-sm/bold">{`Approve ${unwrappedCurrency.symbol}`}</Text>
                      <Text variant="body-sm/normal">
                        Allow it to be used by the wrapping contract
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
              )}
              <Box opacity={activeStep === 'wrapCurrency' ? 1.0 : 0.6}>
                <HStack>
                  <ApproveStepIndicator
                    bg="blue.1"
                    color="blue.500"
                    position="static"
                  >
                    {buttonIcon(
                      wrappingNeedsApproval ? 2 : 1,
                      wrapping,
                      hasWrapped
                    )}
                  </ApproveStepIndicator>
                  <VStack align="left" spacing={0.25}>
                    <Text variant="body-sm/bold">{`Wrap ${unwrappedCurrency.symbol}`}</Text>
                    <Text variant="body-sm/normal">{`Convert ${unwrappedCurrency.symbol} to ${wrappedCurrency.symbol}`}</Text>
                  </VStack>
                </HStack>
              </Box>
              {termContractNeedsApproval && (
                <Box opacity={activeStep === 'approveWrapped' ? 1.0 : 0.6}>
                  <HStack>
                    <ApproveStepIndicator
                      bg="blue.1"
                      color="blue.500"
                      position="static"
                    >
                      {buttonIcon(
                        wrappingNeedsApproval ? 3 : 2,
                        approvingWrapped,
                        hasApprovedWrapped
                      )}
                    </ApproveStepIndicator>
                    <VStack align="left" spacing={0.25}>
                      <Text variant="body-sm/bold">{`Approve ${wrappedCurrency.symbol}`}</Text>
                      <Text variant="body-sm/normal">{`Allow our contracts to use it so that you can submit ${tenderType}`}</Text>
                    </VStack>
                  </HStack>
                </Box>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            {activeStep === 'approveNaked' && (
              <WrapApproveButton
                isDisabled={approvingNaked}
                isSubmitting={approvingNaked}
                onClick={approveNaked}
                label={`Approve ${unwrappedCurrency.symbol}`}
              />
            )}
            {activeStep === 'wrapCurrency' && (
              <WrapApproveButton
                isDisabled={wrapping}
                isSubmitting={wrapping}
                onClick={async () => {
                  await wrapCurrency()
                  if (!termContractNeedsApproval) {
                    onModalClose(true)
                  }
                }}
                label={`Wrap ${unwrappedCurrency.symbol}`}
              />
            )}
            {activeStep === 'approveWrapped' && (
              <WrapApproveButton
                isDisabled={approvingWrapped}
                isSubmitting={approvingWrapped}
                onClick={async () => {
                  await approveWrapped()
                  onModalClose(true)
                }}
                label={`Approve ${wrappedCurrency.symbol}`}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
