import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

export const StorageContext = createContext({
  storage: global.localStorage,
  // eslint-disable-next-line unused-imports/no-unused-vars
  updateStorage: (key: string, serializedValue: string) => {},
  borrowsUpdated: {},
  loansUpdated: {},
  rolloversUpdated: {},
})

export function StorageProvider({
  storage,
  children,
}: {
  storage?: Storage
  children: ReactNode
}) {
  // indicators that storage item has changed for downstream updates
  const [borrowsUpdated, setBorrowsUpdated] = useState({})
  const [loansUpdated, setLoansUpdated] = useState({})
  const [rolloversUpdated, setRolloversUpdated] = useState({})

  const updateStorage = useCallback((key: string, serializedValue: string) => {
    localStorage.setItem(key, serializedValue)
    if (key.startsWith('borrow-')) {
      setBorrowsUpdated({})
    }
    if (key.startsWith('loan-')) {
      setLoansUpdated({})
    }
    if (key.startsWith('rollover-')) {
      setRolloversUpdated({})
    }
  }, [])

  return (
    <StorageContext.Provider
      value={{
        storage: storage ?? localStorage,
        updateStorage,
        borrowsUpdated,
        loansUpdated,
        rolloversUpdated,
      }}
    >
      {children}
    </StorageContext.Provider>
  )
}

export function useStorage() {
  return useContext(StorageContext)
}
