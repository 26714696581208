import InfoBox from '../../../../components/elements/InfoBox'
import dayjs from 'dayjs'
import { t } from '@lingui/macro'

export default function LoanLiquidationInfo({
  partial,
  defaultLiquidation,
  liquidationTimestamp,
}: {
  partial: boolean
  defaultLiquidation: boolean
  liquidationTimestamp: number
}) {
  return (
    <InfoBox kind="info">
      {defaultLiquidation
        ? t`You did not repay your loan before the repayment window closed on ${dayjs
            .unix(liquidationTimestamp ?? 0)
            .format('MMMM D, ha')}.`
        : partial
          ? t`Your collateral fell below the minimum requirement. Deposit excess collateral to reduce your risk of being liquidated.`
          : t`Your collateral fell below the minimum requirement. `}
    </InfoBox>
  )
}
