import { useMemo } from 'react'
import { useConfig } from '../../../providers/config'
import { HStack } from '../../elements/Stack'
import { NavLink } from './NavLink'
import c from './content.json'

export const NavLinks = ({
  location,
  search,
}: {
  location: {
    pathname: string
  }
  search: URLSearchParams
}) => {
  const config = useConfig()
  // This hides allows specific pages to be hidden (except from storybook) until ready.

  const disabledLinks = useMemo(() => {
    if (config.isInternalEnvironment) {
      return new Set<string>()
    } else {
      return new Set<string>(['vaults'])
    }
  }, [config.isInternalEnvironment])

  return (
    <HStack
      as={'nav'}
      spacing="32px"
      display="flex"
      marginRight={{
        base: '0',
        lg: '67px',
      }}
      width={{
        base: '100%',
        lg: 'auto',
      }}
      alignItems="center"
      justifyContent={{
        base: 'space-between',
        lg: 'flex-start',
      }}
      px={{
        base: '80px',
        lg: '0',
      }}
    >
      {c.links.map((link) => (
        <NavLink
          key={link.title}
          to={
            disabledLinks.has(link.paths[0])
              ? undefined
              : `${link.paths[0]}?${search.toString()}`
          }
          active={
            link.paths.some((path) => location.pathname.endsWith(path)) ||
            (c.links.every(
              (l) => !l.paths.some((path) => location.pathname.endsWith(path))
            ) &&
              link.default)
          }
          isNew={link.isNew}
        >
          {link.title}
        </NavLink>
      ))}
      {/* <NavLink>Term Repos</NavLink> */}
    </HStack>
  )
}
