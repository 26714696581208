import { Box, BoxProps } from '@chakra-ui/react'

export default function Container({
  maxW,
  children,
}: {
  maxW: string | number
  children?: BoxProps['children']
}) {
  return (
    <Box
      mx={{
        base: 2,
        xl: 20,
      }}
      maxW={maxW}
    >
      {children}
    </Box>
  )
}
