import { GridItem, Text, Skeleton } from '@chakra-ui/react'

export const InviteStats = ({
  title,
  value,
  isLoading,
}: {
  title: string
  value: string | number
  isLoading?: boolean
}) => (
  <GridItem>
    <Text variant="body-md/medium" color="gray.6" pb={2.5} isTruncated>
      {title}
    </Text>
    {isLoading ? (
      <Skeleton minH="32px" w="50px" />
    ) : (
      <Text
        variant="body-2xl/medium"
        color={title.toLowerCase().includes('share') ? 'green.5' : 'blue.9'}
      >
        {value}
      </Text>
    )}
  </GridItem>
)

export default InviteStats
