import { VStack } from '../../../../components/elements/Stack'

import { ReactNode } from 'react'

export default function SummaryBody({ children }: { children: ReactNode }) {
  return (
    <VStack rowGap={4} p={4} pt={0} w="full">
      {children}
    </VStack>
  )
}
