import { Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import StatsBeforeAfter from '../../../../components/elements/StatsBeforeAfter'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import TokenChip from '../../../../components/elements/TokenChip'
import Tooltip from '../../../../components/elements/Tooltip'
import { formatFixedToken, formatMaturityDate } from '../../../../helpers/utils'
import { evaluate } from '../../../../helpers/math'
import OverflowBox from '../../../../components/elements/OverflowBox'
import { t, Trans } from '@lingui/macro'

export default function CollapseBorrowDetail({
  outstandingDebt,
  collapseAmount,
  rolloverAmount,
  maturityDate,
  auctionLabel,
  purchaseTokenSymbol,
  hasError,
}: {
  outstandingDebt: FixedNumber
  collapseAmount: FixedNumber
  rolloverAmount: FixedNumber
  maturityDate: number
  auctionLabel: string
  purchaseTokenSymbol: string
  hasError: boolean
}) {
  const repayIn = formatMaturityDate(maturityDate)

  const collapsibleDebt = evaluate(
    {
      nodeKind: 'sub',
      args: [
        {
          nodeKind: 'value',
          value: outstandingDebt,
        },
        {
          nodeKind: 'value',
          value: rolloverAmount,
        },
      ],
    },
    outstandingDebt.format.decimals
  )

  const afterCollapsibleDebt = evaluate(
    {
      nodeKind: 'sub',
      args: [
        {
          nodeKind: 'value',
          value: collapsibleDebt,
        },
        {
          nodeKind: 'value',
          value: collapseAmount,
        },
      ],
    },
    outstandingDebt.format.decimals
  )

  const afterOutstandingDebt = evaluate(
    {
      nodeKind: 'sub',
      args: [
        {
          nodeKind: 'value',
          value: outstandingDebt,
        },
        {
          nodeKind: 'value',
          value: collapseAmount,
        },
      ],
    },
    outstandingDebt.format.decimals
  )

  return (
    <StatsCard>
      <StatsTitle value={auctionLabel}>Loan Details</StatsTitle>
      <StatsRow
        title={
          <HStack spacing={1} w="full" justifyContent="flex-end">
            <Text as="span">
              <Trans>Collapsible Debt</Trans>
            </Text>
            <TokenChip token={purchaseTokenSymbol} />
          </HStack>
        }
        tooltip={<RolloverTooltip />}
      >
        {collapseAmount.isZero() || hasError ? (
          <OverflowBox
            whiteSpace="nowrap"
            columnGap={1}
            mt="0 !important"
            lineHeight="20px"
          >
            <Text as="span" variant="body-sm/semibold" color="blue.9">
              {formatFixedToken(collapsibleDebt, purchaseTokenSymbol, true)}
            </Text>
          </OverflowBox>
        ) : (
          <StatsBeforeAfter
            lineHeight="20px"
            before={formatFixedToken(
              collapsibleDebt,
              purchaseTokenSymbol,
              true,
              true
            )}
            after={formatFixedToken(
              afterCollapsibleDebt,
              purchaseTokenSymbol,
              true,
              true
            )}
          />
        )}
      </StatsRow>
      <StatsRow
        title={
          <HStack spacing={1} w="full" justifyContent="flex-end">
            <Text as="span">
              <Trans>Outstanding Debt</Trans>
            </Text>
          </HStack>
        }
      >
        {collapseAmount.isZero() || hasError ? (
          <OverflowBox
            whiteSpace="nowrap"
            columnGap={1}
            mt="0 !important"
            lineHeight="20px"
          >
            <Text as="span" variant="body-sm/normal" color="blue.9">
              {formatFixedToken(outstandingDebt, purchaseTokenSymbol, true)}
            </Text>
          </OverflowBox>
        ) : (
          <StatsBeforeAfter
            lineHeight="20px"
            before={formatFixedToken(
              outstandingDebt,
              purchaseTokenSymbol,
              true,
              true
            )}
            after={formatFixedToken(
              afterOutstandingDebt,
              purchaseTokenSymbol,
              true,
              true
            )}
          />
        )}
      </StatsRow>
      <StatsRow
        title={
          <HStack spacing={1} w="full" justifyContent="flex-end">
            <Text as="span">
              <Trans>Rollover Amount</Trans>
            </Text>
          </HStack>
        }
      >
        <Text variant="body-sm/normal" color="blue.9">
          {formatFixedToken(rolloverAmount, purchaseTokenSymbol, true)}
        </Text>
      </StatsRow>
      <StatsRow title={t`Maturity`}>
        <Text variant="body-sm/normal" color="blue.9">
          {repayIn}
        </Text>
      </StatsRow>
    </StatsCard>
  )
}

function RolloverTooltip() {
  return (
    <Tooltip
      label={t`Please note, any debt that is scheduled to rollover cannot be collapsed.`}
      aria-label={t`rollover-tooltip`}
    />
  )
}
