import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Contract, BigNumber } from 'ethers'
import ERC20ABI from '../../abi/v0.4.1/IERC20MetadataUpgradeable.json'
import { IERC20MetadataUpgradeable } from '../../abi-generated'
import TermRepoTokenABI_0_2_4 from '../../abi/v0.2.4/TermRepoToken.json'
import TermRepoTokenABI_0_4_1 from '../../abi/v0.4.1/TermRepoToken.json'
import TermRepoTokenABI_0_6_0 from '../../abi/v0.6.0/TermRepoToken.json'
import { TermRepoToken as TermRepoToken_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermRepoToken'
import { TermRepoToken as TermRepoToken_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermRepoToken'
import { TermRepoToken as TermRepoToken_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermRepoToken'
import { useEffect, useMemo } from 'react'
import { captureException } from '@sentry/react'
import { Address, Currency, ERC20Currency, TermRepoCurrency } from '../model'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { getABIVersion } from '../../helpers/conversions'

export interface TokenInfo {
  address: Address
  isRepoToken: boolean
  version: string
}

export function useCurrencies(
  tokenData: { [chainId: string]: TokenInfo[] | undefined } | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): { [chainId: string]: Currency[] } | null | undefined {
  // filters out duplicate tokens
  const uniqueTokenData = useMemo(() => {
    const filteredTokenData: { [chainId: string]: TokenInfo[] } = {}

    tokenData &&
      Object.entries(tokenData).forEach(([chainId, tokens]) => {
        if (tokens) {
          const seenTokens = new Set()
          filteredTokenData[chainId] = tokens.filter((token) => {
            if (seenTokens.has(token.address)) {
              return false
            }
            seenTokens.add(token.address)
            return true
          })
        }
      })

    return filteredTokenData
  }, [tokenData])

  const tokenContracts = useMemo(() => {
    const contracts = {} as Record<
      string,
      Record<
        Address,
        | IERC20MetadataUpgradeable
        | TermRepoToken_0_2_4
        | TermRepoToken_0_4_1
        | TermRepoToken_0_6_0
      >
    >

    Object.entries(uniqueTokenData).forEach(([chainId, tokens]) => {
      contracts[chainId] = {}
      tokens.forEach((token) => {
        const abiVersion = getABIVersion(token.version)

        // TODO: clean this up
        if (token.isRepoToken) {
          switch (abiVersion) {
            case '0.2.4':
              contracts[chainId][token.address] = new Contract(
                token.address,
                TermRepoTokenABI_0_2_4,
                provider
              ) as TermRepoToken_0_2_4
              break
            case '0.4.1':
              contracts[chainId][token.address] = new Contract(
                token.address,
                TermRepoTokenABI_0_4_1,
                provider
              ) as TermRepoToken_0_4_1
              break
            case '0.6.0':
            default:
              contracts[chainId][token.address] = new Contract(
                token.address,
                TermRepoTokenABI_0_6_0,
                provider
              ) as TermRepoToken_0_6_0
              break
          }
        } else {
          contracts[chainId][token.address] = new Contract(
            token.address,
            ERC20ABI,
            provider
          ) as IERC20MetadataUpgradeable
        }
      })
    })
    return contracts
  }, [uniqueTokenData, provider])

  const tokenCalls: MultichainCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    Object.entries(tokenContracts).forEach(([chainId, contracts]) => {
      calls[chainId] = Object.entries(contracts)
        .map(([address, contract]) => {
          const isRepoToken = uniqueTokenData?.[chainId]?.find(
            (t) => t.address === address
          )?.isRepoToken
          return [
            { contract, method: 'decimals', args: [] },
            { contract, method: 'symbol', args: [] },
            ...(isRepoToken ? [{ contract, method: 'config', args: [] }] : []),
          ]
        })
        .flat()
    })

    return calls
  }, [tokenContracts, uniqueTokenData])

  // useDebug('useCurrencies currency token calls', tokenCalls)

  const tokenResultsGrouped = useMultichainCalls(tokenCalls)

  // useDebug('useCurrencies currency token responses', tokenResultsGrouped)

  const currencies = useMemo(() => {
    let isLengthMismatch = false
    let areAnyResultsUndefined = true

    // Check if any of the results for each chain ID are undefined
    areAnyResultsUndefined = Object.values(tokenResultsGrouped).some(
      (tokenResults) => tokenResults.some((result) => result === undefined)
    )

    // Check if the length of calls and results match for each chain ID
    isLengthMismatch = Object.entries(tokenCalls).some(([chainId, calls]) => {
      const results = tokenResultsGrouped?.[chainId]
      if (!results) return true
      const totalCalls = calls.length
      const totalResults = results.length
      return totalCalls !== totalResults
    })

    // Return undefined if any results are undefined
    if (areAnyResultsUndefined || isLengthMismatch) {
      return undefined
    }

    const result: { [chainId: string]: Currency[] } = {}

    tokenResultsGrouped &&
      Object.entries(tokenResultsGrouped).forEach(([chainId, tokenResults]) => {
        const tokens = uniqueTokenData?.[chainId]
        if (!tokens) return

        const currencies: Currency[] = []
        let resultIndex = 0
        tokens.forEach(({ address, isRepoToken, version }) => {
          const decimalsResult = tokenResults[resultIndex]?.value?.[0]
          const symbolResult = tokenResults[resultIndex + 1]?.value?.[0]
          const configResult = isRepoToken
            ? tokenResults[resultIndex + 2]?.value
            : undefined

          const decimals = decimalsResult ? parseInt(decimalsResult) : undefined
          const symbol = symbolResult || undefined

          let currency: Currency | undefined
          if (isRepoToken) {
            // TODO: this needs to be cleaned up when we have no more 0.2.4 terms listed
            const abiVersion = getABIVersion(version)
            const redemptionTimestampRaw =
              abiVersion === '0.2.4'
                ? configResult?.maturityTimestamp
                : configResult?.redemptionTimestamp

            const redemptionTimestamp = configResult
              ? parseFloat(BigNumber.from(redemptionTimestampRaw).toString())
              : undefined
            const purchaseToken = configResult
              ? configResult.purchaseToken.toLowerCase()
              : undefined
            currency = {
              address,
              symbol,
              decimals,
              isRepoToken: true,
              redemptionTimestamp,
              purchaseToken,
            } as TermRepoCurrency
          } else {
            currency = {
              address,
              symbol,
              decimals,
              isRepoToken: false,
            } as ERC20Currency
          }

          if (currency && currency.decimals !== undefined && currency.symbol) {
            currencies.push(currency)
          }

          resultIndex += isRepoToken ? 3 : 2
        })

        result[chainId] = currencies
      })

    return Object.keys(result).length > 0 ? result : undefined
  }, [tokenCalls, tokenResultsGrouped, uniqueTokenData])

  useEffect(() => {
    tokenResultsGrouped &&
      Object.entries(tokenResultsGrouped).forEach(([chainId, tokenResults]) => {
        tokenResults.forEach((result) => {
          if (result && result.error) {
            console.log(`Error on chain ${chainId}:`, tokenCalls.length)
            console.error(`Error on chain ${chainId}:`, result.error)
            captureException(result.error)
          }
        })
      })
  }, [tokenCalls.length, tokenResultsGrouped])

  return currencies
}
