import { useToken } from '@chakra-ui/react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

export default function ProgressKeyhole({
  color,
  totalValue,
  value,
}: {
  color: string
  totalValue: number
  value: number
}) {
  const [fillColor] = useToken('colors', [color])
  const [trackColor] = useToken('colors', ['gray.3'])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={[{ value: totalValue }]}
          cx="50%"
          cy="50%"
          startAngle={250}
          endAngle={-70}
          innerRadius="50%"
          outerRadius="100%"
          fill={trackColor}
          paddingAngle={5}
          dataKey="value"
        />
        <Pie
          data={[{ value: value }]}
          cx="50%"
          cy="50%"
          startAngle={250}
          endAngle={250 - (value / totalValue) * 360}
          innerRadius="50%"
          outerRadius="100%"
          fill={fillColor}
          paddingAngle={5}
          dataKey="value"
        >
          <Cell fill={fillColor} />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
