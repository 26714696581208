import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Link,
  TooltipProps,
} from '@chakra-ui/react'
import { HStack, VStack, Stack } from '../../../../components/elements/Stack'
import { useState } from 'react'
import Tooltip from '../../../../components/elements/Tooltip'
import { ArrowUpRight } from '../../../../components/elements/Icons'
import ClickCopy from '../../../../components/elements/ClickCopy'
import { Trans, t } from '@lingui/macro'

export const referralCodeInfoTooltipText =
  'The wallet address corresponding to the referral link you used'
export const ReferralCodeInfoTooltip = (
  props?: Omit<TooltipProps, 'children'>
) => <Tooltip label={referralCodeInfoTooltipText} {...props} />

export default function ConfirmDeleteReferralCodeModal({
  isOpen,
  referralCode,
  getBlockExplorerAddressUrl,
  onClose,
  onConfirm,
}: {
  referralCode: string
  isOpen: boolean
  getBlockExplorerAddressUrl?: (address: string) => string
  onClose: () => void
  onConfirm: () => Promise<void>
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <Stack p="28px" spacing={'20px'}>
            <HStack justify="space-between">
              <Heading
                variant="heading/h3"
                fontWeight="700"
                mt="0px"
                mb="0px"
                textAlign="start"
                color="blue.9"
              >
                <Trans>Confirm Referral Removal</Trans>
              </Heading>
              <ModalCloseButton position="static" color="blue.9" />
            </HStack>
            <Text color="blue.9" variant="body-sm/normal">
              <Trans>
                Are you sure you want to remove the affiliate’s association with
                your address? This will revoke all referral benefits and
                discounts associated with their referral, and cannot be undone.
              </Trans>
            </Text>
            <VStack w="full" spacing="28px">
              <VStack
                alignItems="center"
                borderRadius="md"
                bg="blue.0"
                w="full"
                borderWidth="1px"
                borderColor="gray.2"
              >
                <HStack pt="8px" px="16px" w="full">
                  <Text as="span" color="gray.6" variant="body-sm/medium">
                    <Trans>Referral Address</Trans>
                  </Text>
                  <ReferralCodeInfoTooltip />
                </HStack>
                <HStack pb="8px" px="16px" w="full">
                  <Text as="span" color="blue.9" variant="body-xs/semibold">
                    {referralCode}
                  </Text>
                  <ClickCopy
                    copyText={referralCode}
                    helperText={t`Copy address`}
                    tooltipProps={{
                      placement: 'bottom-end',
                      offset: [8, 8],
                    }}
                  />
                  <Link
                    href={
                      getBlockExplorerAddressUrl
                        ? getBlockExplorerAddressUrl(referralCode)
                        : '#'
                    }
                    isExternal
                    color="gray.3"
                  >
                    <ArrowUpRight h={4} />
                  </Link>
                </HStack>
              </VStack>
            </VStack>
            <Button
              variant="error"
              w="full"
              onClick={async () => {
                setIsLoading(true)
                try {
                  await onConfirm()
                } catch (error) {
                  console.log(error)
                } finally {
                  setIsLoading(false)
                }
              }}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              <Trans>Remove referral</Trans>
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
