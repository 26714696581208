import { useState, useEffect, useMemo } from 'react'
import { TermPeriod } from '../model'
import { PagePortfolioQuery, TermRepo } from '../../gql/graphql'
import { graphResultToTerm } from '../../helpers/conversions'
import { useConfig } from '../../providers/config'

export function useGraphTerms(
  data: { [chainId: string]: PagePortfolioQuery } | undefined
):
  | { [chainId: string]: { [termRepoTokenAddress: string]: TermPeriod } }
  | undefined {
  const config = useConfig()
  const chainConfigs = useMemo(() => config.chains, [config.chains])

  const [terms, setTerms] = useState<{
    [chainId: string]: { [termRepoTokenAddress: string]: TermPeriod }
  }>()

  const mappedTerms = useMemo(() => {
    const allTerms: TermPeriod[] = []
    if (data) {
      Object.keys(data).forEach((chainId) => {
        const termsToFilterOut = chainConfigs[chainId].termsToFilterOut
        const chainData = data[chainId]

        allTerms.push(
          ...(chainData.termRepos
            ?.map((result) =>
              graphResultToTerm(chainId, result as Partial<TermRepo>)
            )
            ?.filter(
              (result) => !termsToFilterOut.includes(result.id.toLowerCase())
            ) || [])
        )
      })
    }
    return allTerms
  }, [chainConfigs, data])

  useEffect(() => {
    setTerms((prevTerms) => {
      // Group terms by chainId. Each chainId can have multiple termRepoTokenAddresses.
      const newTermsObject = mappedTerms.reduce(
        (
          acc: {
            [chainId: string]: { [termRepoTokenAddress: string]: TermPeriod }
          },
          term
        ) => {
          const chainId = term.chainId
          if (!acc[chainId]) {
            acc[chainId] = {}
          }
          acc[chainId][term.termRepoTokenAddress] = term
          return acc
        },
        {}
      )

      // Merge with previous state.
      // This assumes that prevTerms is structured as Record<chainId, Record<termRepoTokenAddress, TermPeriod>>.
      const mergedTerms = { ...prevTerms }
      for (const [chainId, terms] of Object.entries(newTermsObject)) {
        mergedTerms[chainId] = {
          ...mergedTerms[chainId],
          ...terms,
        }
      }

      return mergedTerms
    })
  }, [mappedTerms])

  return terms
}
