import { Button, Flex, Image } from '@chakra-ui/react'
import SectionHeader from './SectionHeader'
import twitter_icon from '../../../../assets/icons/twitter-x.svg'
import discord_icon from '../../../../assets/icons/discord.svg'
import { ProfileSocials } from '../../../../data/model'
import { SocialActions } from '../../../../models/rewards'

const renderSocialButton = (
  platform: 'twitter' | 'discord',
  icon: string,
  socialLink: string | undefined,
  onLink: (redirPath?: string) => void,
  onRemove: () => void
) => (
  <Flex
    width="480px"
    p="12px"
    bg="gray.0"
    borderRadius="4px"
    justifyContent="space-between"
    alignItems="center"
  >
    <Flex justifyContent="flex-start" alignItems="center" gap="8px">
      <Flex borderRadius="6px" justifyContent="center" alignItems="center">
        <Image src={icon} width="24px" height="24px" />
        {socialLink ? (
          <Button
            variant="link"
            textColor="blue.5"
            as="a"
            href={
              platform === 'twitter'
                ? `https://x.com/${socialLink}`
                : `https://discord.com/${socialLink}`
            }
          >
            {`@${socialLink}`}
          </Button>
        ) : (
          <Button
            variant="link"
            onClick={() =>
              platform === 'twitter'
                ? onLink(`/manage-profile?action=${SocialActions.LinkTwitter}`)
                : onLink(`/manage-profile?action=${SocialActions.LinkDiscord}`)
            }
            textColor="blue.5"
          >
            Link {platform === 'twitter' ? 'X' : 'Discord'}
          </Button>
        )}
      </Flex>
    </Flex>
    {socialLink && (
      <Button variant="link" textColor="red.5" onClick={onRemove}>
        Remove
      </Button>
    )}
  </Flex>
)

export default function SocialSignIn({
  socials,
  onLinkTwitter,
  onLinkDiscord,
  onRemoveTwitter,
  onRemoveDiscord,
}: {
  socials?: ProfileSocials
  onLinkTwitter: (redirPath?: string) => void
  onLinkDiscord: (redirPath?: string) => Promise<void>
  onRemoveTwitter: () => void
  onRemoveDiscord: () => void
}) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap={5}
    >
      <SectionHeader
        heading="Social Sign-In"
        description="To sign in to your profile without a wallet, you may authorize additional authentication methods."
      />
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={4}
      >
        {renderSocialButton(
          'twitter',
          twitter_icon,
          socials?.twitter,
          onLinkTwitter,
          onRemoveTwitter
        )}
        {renderSocialButton(
          'discord',
          discord_icon,
          socials?.discord,
          onLinkDiscord,
          onRemoveDiscord
        )}
      </Flex>
    </Flex>
  )
}
