import { Image, Text } from '@chakra-ui/react'
import highLtv from '../../../assets/icons/highltv.svg'
import Tooltip from '../Tooltip'
import { FixedNumber } from 'ethers'
import { multiply } from '../../../helpers/math'
import { fixedToFormattedPercentage } from '../../../helpers/conversions'
import { VStack, HStack } from '../Stack'

const HighLTVLabel = ({
  ltvRatio,
  maintenanceCollateralRatio,
}: {
  ltvRatio: FixedNumber
  maintenanceCollateralRatio: FixedNumber
}) => {
  const ltvPercentageValue = ltvRatio
    ? multiply(ltvRatio, FixedNumber.fromString('100', ltvRatio.format))
    : FixedNumber.fromString('0', 18)
  const { formattedPercentage: formattedLTVPercentage } =
    fixedToFormattedPercentage(ltvPercentageValue, 0, true)

  const collatPercentageValue = maintenanceCollateralRatio
    ? multiply(
        maintenanceCollateralRatio,
        FixedNumber.fromString('100', maintenanceCollateralRatio.format)
      )
    : FixedNumber.fromString('0', 18)
  const { formattedPercentage: formattedCollatPercentage } =
    fixedToFormattedPercentage(collatPercentageValue, 0, true)

  return (
    <Tooltip
      placement="bottom"
      label={
        <VStack alignItems="flex-start" w="full" color="white">
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/medium" color="white">
              LTV (Loan-To-Value) Ratio:
            </Text>
            <Text as="span" variant="body-sm/medium" color="white">
              {formattedLTVPercentage}%
            </Text>
          </HStack>
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/medium" color="white">
              Maintenance Collateral Ratio:
            </Text>
            <Text as="span" variant="body-sm/medium" color="white">
              ~{formattedCollatPercentage}%
            </Text>
          </HStack>
        </VStack>
      }
    >
      <Image src={highLtv} alt={'High LTV'} />
    </Tooltip>
  )
}

export default HighLTVLabel
