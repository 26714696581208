import { Flex, VStack, Text, Button, Skeleton } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

interface Props {
  hasProfile: boolean
  isLoggedIn: boolean
  profileName?: string
  isLoading?: boolean
  onSignUp?: () => void
  onSignIn?: () => void
}

export const ProfileButton = ({
  hasProfile,
  isLoggedIn,
  profileName,
  isLoading,
  onSignUp,
  onSignIn,
}: Props) => {
  return (
    <VStack alignItems="right" gap={1}>
      <Flex direction="row" justifyContent="flex-end">
        <Text variant="body-md/medium" color="gray.6">
          User Profile
        </Text>
      </Flex>
      <Flex direction="row" justifyContent="flex-end">
        {isLoading ? (
          <Skeleton h="24px" w="80px" />
        ) : (
          <>
            {hasProfile && isLoggedIn && (
              <Link to="/manage-profile" state={{ previousPage: '/rewards' }}>
                <Text variant="body-md/medium" color="blue.5" textAlign="right">
                  {profileName}
                </Text>
              </Link>
            )}
            {hasProfile && !isLoggedIn && (
              <Button px={0} variant="link" onClick={onSignIn}>
                <Text
                  variant="body-md/medium"
                  color={'blue.5'}
                  textAlign="right"
                >
                  Sign in
                </Text>
              </Button>
            )}
            {!hasProfile && (
              <Button px={0} variant="link" onClick={onSignUp}>
                <Text
                  variant="body-md/medium"
                  color={'blue.5'}
                  textAlign="right"
                >
                  Sign up
                </Text>
              </Button>
            )}
          </>
        )}
      </Flex>
    </VStack>
  )
}

export default ProfileButton
