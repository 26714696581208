import { useMemo } from 'react'
import { networkIcons, networkWhiteIcons } from '../../helpers/networkIcons'
import switchNetworkIcons from '../../helpers/switchNetworkIcons'
import { ChainId } from '@usedapp/core'
import { convertChainId } from '../../helpers/conversions'

export function useNetworkIcon(chainId: ChainId | string) {
  const parsedChainId =
    typeof chainId === 'number' ? chainId : convertChainId(chainId)
  return useMemo(
    () => networkIcons[parsedChainId] || networkIcons[-1],
    [parsedChainId]
  )
}

export function useNetworkWhiteIcons(chainId: ChainId | string) {
  const parsedChainId =
    typeof chainId === 'number' ? chainId : convertChainId(chainId)
  return useMemo(
    () => networkWhiteIcons[parsedChainId] || networkWhiteIcons[-1],
    [parsedChainId]
  )
}

export function useSwitchNetworkIcon(chainId: ChainId | string) {
  const parsedChainId =
    typeof chainId === 'number' ? chainId : convertChainId(chainId)
  return useMemo(
    () => switchNetworkIcons[parsedChainId] || switchNetworkIcons[-1],
    [parsedChainId]
  )
}
