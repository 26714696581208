import { Box, Button, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FixedNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { VaultData } from '../../../../data/model'
import VaultCard from '../VaultCard'
import VaultCardLoading from '../VaultCard/VaultCardLoading'

const sortButtons: { id: keyof VaultData; label: string }[] = [
  {
    id: 'currentYield',
    label: 'Current Yield',
  },
  {
    id: 'collateral',
    label: 'Collateral',
  },
  {
    id: 'curator',
    label: 'Curator',
  },
  {
    id: 'vaultName',
    label: 'Vault',
  },
  {
    id: 'totalAssetValue',
    label: 'Total Asset Value',
  },
]

const ColumnSortButton = ({
  label,
  isActive,
  onClick,
}: {
  label: string
  isActive: boolean
  onClick: () => void
}) => (
  <Button variant="unstyled" height="fit-content" onClick={onClick}>
    <HStack>
      <Text as="span" variant="body-sm/medium" color="grey.6">
        {label}
      </Text>
      <Box
        marginInlineStart={0}
        color={isActive ? 'black' : 'gray.5'}
        as="span"
        transform={isActive ? 'rotate(0deg)' : 'rotate(180deg)'}
        transition="transform 0.2s"
      >
        <FontAwesomeIcon
          icon={['far', 'chevron-up']}
          role="button"
          width="12px"
        />
      </Box>
    </HStack>
  </Button>
)

export default function VaultsTable({
  isDataLoading,
  selectedDepositToken,
  vaultsData,
  selectedChainId,
}: {
  isDataLoading?: boolean
  selectedDepositToken: string
  vaultsData: any[]
  selectedChainId: string
}) {
  const [activeSort, setActiveSort] = useState('')
  const [activeVaultId, setActiveVaultId] = useState<string | undefined>(
    undefined
  )
  const [filteredAndSortedData, setFilteredAndSortedData] = useState(vaultsData)
  const [sortConfig, setSortConfig] = useState<{
    key: keyof VaultData
    direction: string
  }>({
    key: 'currentYield',
    direction: 'DESC',
  })

  useEffect(() => {
    // Step 1: Filter data based on selectedChainId
    let updatedData: VaultData[] = []

    if (selectedChainId === '') {
      updatedData = vaultsData
    } else {
      updatedData = vaultsData.filter(
        (lend: VaultData) => lend.chainId === selectedChainId
      )
    }

    // Step 2: Sort data based on current sortConfig
    if (sortConfig.key) {
      const direction = sortConfig.direction === 'ASC' ? 1 : -1
      updatedData = [...updatedData].sort((a, b) => {
        let valueA: any = a[sortConfig.key]
        let valueB: any = b[sortConfig.key]

        if (valueA instanceof FixedNumber) {
          valueA = parseFloat(valueA.toString())
        }
        if (valueB instanceof FixedNumber) {
          valueB = parseFloat(valueB.toString())
        }

        if (typeof valueA === 'string') {
          valueA = parseFloat(valueA.replace(/,/g, ''))
        }
        if (typeof valueB === 'string') {
          valueB = parseFloat(valueB.replace(/,/g, ''))
        }

        if (valueA === undefined && valueB === undefined) return 0
        if (valueA === undefined) return direction
        if (valueB === undefined) return -direction

        return valueA < valueB ? -direction : valueA > valueB ? direction : 0
      })
    }

    // Step 3: Update state if necessary to prevent redundant re-renders
    if (JSON.stringify(updatedData) !== JSON.stringify(filteredAndSortedData)) {
      setFilteredAndSortedData(updatedData)
    }
  }, [filteredAndSortedData, vaultsData, selectedChainId, sortConfig])

  const handleSort = (key: keyof VaultData) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === 'ASC' ? 'DESC' : 'ASC'
    setSortConfig({ key, direction })
    setActiveSort(activeSort === key ? '' : key)
  }

  return (
    <>
      <Flex direction="column" cursor="pointer">
        {/* Table Header */}
        <Flex px={3} align="center">
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
            mr="auto"
            pr={5}
            py={4}
            alignItems="center"
          >
            <Box h="100%" w="130px" pr={2}>
              {sortButtons
                .filter(({ id }) => id === 'currentYield')
                .map(({ id, label }) => (
                  <ColumnSortButton
                    key={id}
                    label={label}
                    isActive={id === activeSort}
                    onClick={() => handleSort(id)}
                  />
                ))}
            </Box>
            <Grid
              gridTemplateColumns={{
                base: '100px 120px 150px',
              }}
              gap={{
                base: '38px',
                '1xl': '89px',
                '3xl': 36,
              }}
            >
              {sortButtons
                .filter(
                  ({ id }) => id !== 'currentYield' && id !== 'totalAssetValue'
                )
                .map(({ id, label }) => (
                  <ColumnSortButton
                    key={id}
                    label={label}
                    isActive={id === activeSort}
                    onClick={() => handleSort(id)}
                  />
                ))}
            </Grid>
          </Flex>
          <Flex
            flexDirection="column"
            align="start"
            verticalAlign="top"
            w="290px"
            alignItems="start"
          >
            {sortButtons
              .filter(({ id }) => id === 'totalAssetValue')
              .map(({ id, label }) => (
                <ColumnSortButton
                  key={id}
                  label={label}
                  isActive={id === activeSort}
                  onClick={() => handleSort(id)}
                />
              ))}
          </Flex>
        </Flex>
        {/* Table Body */}
        <Flex
          direction="column"
          gap={5}
          pt={
            filteredAndSortedData && filteredAndSortedData[0]?.externalProjects
              ? 3
              : 0
          }
        >
          {isDataLoading
            ? Array.from({ length: 5 }).map((_, idx) => (
                <VaultCardLoading key={idx} />
              ))
            : filteredAndSortedData.map((vault: any) => (
                <VaultCard
                  key={vault.id}
                  vault={vault}
                  activeVaultId={activeVaultId}
                  setActiveVaultId={setActiveVaultId}
                />
              ))}
        </Flex>
      </Flex>
    </>
  )
}
