import { Flex, FlexProps, Text } from '@chakra-ui/layout'
import { ReactNode } from 'react'

export default function ApproveStepIndicator(
  props: FlexProps & { children: ReactNode }
) {
  return (
    <Flex
      position="absolute"
      left={3}
      top="50%"
      transform="translateY(-50%)"
      alignItems="center"
      justifyContent="center"
      as="div"
      borderRadius="md"
      bg={props.bg ?? 'blue.3'}
      w={5}
      h={5}
      zIndex={1}
      {...props}
    >
      <Text as="span" variant="body-xs/medium" color={props.color ?? 'white'}>
        {props.children}
      </Text>
    </Flex>
  )
}
