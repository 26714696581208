import {
  Box,
  Checkbox,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Table, Td, Th } from '../../../../components/elements/Table'
import { Currency, ListingsTransaction } from '../../../../data/model'
import { add, subtract } from '../../../../helpers/math'
import { termToastMessages } from '../../../../helpers/toasts'
import { formatFixedToken } from '../../../../helpers/utils'
import { useTermToast } from '../../../../hooks/toasts'
import CancelListingButton from './CancelListingButton'
import { useChainConfig } from '../../../../providers/config'
import { formatFixed } from '../../../../helpers/conversions'
import { HStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'

const TABLE_HEADERS = [
  { column: 'listed', label: 'Listed' },
  { column: 'amount', label: 'Amount' },
]

const TableHeaderCell = ({
  column,
  sortColumn,
  sortDirection,
  children,
  hasCheckbox,
  isDisabled,
  isChecked,
  isIndeterminate,
  handleClick,
  handleToggleListings,
}: {
  column: string
  sortColumn?: string | null
  sortDirection?: boolean
  children: React.ReactNode
  hasCheckbox?: boolean
  isDisabled?: boolean
  isChecked?: boolean
  isIndeterminate?: boolean
  handleClick?: (column: any) => void
  handleToggleListings?: () => void
}) => (
  <Th
    cursor="pointer"
    borderBottom="1px"
    style={{ borderColor: 'rgba(0, 16, 39, 0.10)' }}
  >
    <HStack w="full" justify={column === 'amount' ? 'end' : undefined}>
      {hasCheckbox && (
        <Checkbox
          pr={1}
          isDisabled={isDisabled}
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          onChange={handleToggleListings}
        />
      )}
      <HStack onClick={() => handleClick && handleClick(column)}>
        <Text
          variant="body-sm/semibold"
          color={sortColumn === column ? 'blue.9' : 'gray.6'}
        >
          {children}
        </Text>
        <Box
          color={sortColumn === column ? 'blue.9' : 'gray.6'}
          as="span"
          transform={
            sortColumn === column
              ? sortDirection
                ? 'rotate(180deg)'
                : 'rotate(0deg)'
              : undefined
          }
          transition="transform 0.2s"
        >
          <FontAwesomeIcon
            icon={['far', 'chevron-down']}
            role="button"
            width="10px"
          />
        </Box>
      </HStack>
    </HStack>
  </Th>
)

const TableCell = ({
  children,
  variant = 'body-sm/normal',
  hasCheckbox,
  isDisabled,
  isChecked,
  handleCheckboxChange,
}: {
  children: React.ReactNode
  variant?: string
  isDisabled?: boolean
  hasCheckbox?: boolean
  isChecked?: boolean
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <Td py={5}>
      <HStack justify={hasCheckbox ? undefined : 'end'}>
        {hasCheckbox && (
          <Checkbox
            isDisabled={isDisabled}
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            pr={1}
          />
        )}
        <Text variant={variant} color="blue.9">
          {children}
        </Text>
      </HStack>
    </Td>
  )
}

export default function CancelListingModal({
  isOpen,
  isMature,
  chainId,
  purchaseCurrencySymbol,
  termTokenCurrency,
  tokenListings,
  listingsChecked = true,
  onCancelListings,
  onClose,
}: {
  isOpen: boolean
  isMature: boolean
  chainId: string
  purchaseCurrencySymbol: string
  termTokenCurrency: Currency
  tokenListings: ListingsTransaction[]
  listingsChecked: boolean
  onCancelListings: (
    chainId: string,
    listingIds: number[],
    skipRedeem?: boolean
  ) => Promise<void>
  onClose: () => void
}) {
  const chainConfig = useChainConfig(chainId)

  const termToast = useTermToast()

  const [isDelisting, setIsDelisting] = useState(false)
  const [sortColumn, setSortColumn] =
    useState<keyof ListingsTransaction>('remainingAmount')
  const [sortDirection, setSortDirection] = useState<boolean>(false)
  const [toggleListingsOn, setToggleListingsOn] =
    useState<boolean>(listingsChecked)
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set())
  const isIndeterminate =
    selectedItems.size > 0 && selectedItems.size < tokenListings.length
  const isChecked = selectedItems.size === tokenListings.length

  const [isRedeemChecked, setIsRedeemChecked] = useState(true)

  useEffect(() => {
    if (isMature) {
      // Select all listings
      setSelectedItems(new Set(tokenListings.map((_, index) => index)))
    }
  }, [isMature, tokenListings])

  const sortedTableData = useMemo(() => {
    if (!sortColumn) {
      return tokenListings
    }

    const sortedData = tokenListings.sort((a, b) => {
      let aValue: number
      let bValue: number

      if (sortColumn === 'listingAmount' || sortColumn === 'remainingAmount') {
        aValue = parseFloat(a[sortColumn].toString())
        bValue = parseFloat(b[sortColumn].toString())
      } else {
        aValue = a[sortColumn] as unknown as number
        bValue = b[sortColumn] as unknown as number
      }

      if (aValue < bValue) return sortDirection ? -1 : 1
      if (aValue > bValue) return sortDirection ? 1 : -1
      return 0
    })

    return sortedData
  }, [tokenListings, sortColumn, sortDirection])

  const handleCheckboxChange = (itemId: number, isChecked: boolean) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems)
      isChecked ? newSelectedItems.add(itemId) : newSelectedItems.delete(itemId)
      return newSelectedItems
    })
  }

  const handleThClick = (column: keyof ListingsTransaction) => {
    setSortDirection((prevSortDirection) => !prevSortDirection)
    setSortColumn(column)
  }

  const handleToggleListings = () => {
    setToggleListingsOn((prevToggleListings) => !prevToggleListings)
    setSelectedItems(new Set())
    if (toggleListingsOn === true) {
      setSelectedItems(new Set(sortedTableData.map((_, index) => index)))
      return
    }
  }

  useEffect(() => {
    handleToggleListings()
  }, [])

  const onCloseModal = useCallback(() => {
    setIsDelisting(false)
    onClose()
  }, [onClose])

  const submit = useCallback(async () => {
    setIsDelisting(true)
    try {
      const listingIds = [...selectedItems].map((idx) =>
        Number(tokenListings[idx].id)
      )
      const tokensToDelistFn = [...selectedItems].reduce(
        (accumulator, idx) => {
          const transaction = tokenListings[idx]
          const diff = subtract(
            transaction.listingAmount,
            transaction.remainingAmount
          )
          return add(accumulator, diff, 8)
        },
        FixedNumber.fromString('0', 8)
      )
      await onCancelListings(chainId, listingIds, !isRedeemChecked)
      termToast.success(
        termToastMessages.delisting.success(
          formatFixedToken(
            tokensToDelistFn,
            purchaseCurrencySymbol,
            true,
            true
          ),
          isMature && !isRedeemChecked
        )
      )
      onCloseModal()
    } catch (error) {
      setIsDelisting(false)
      termToast.failure(termToastMessages.delisting.failure)
    }
  }, [
    onCancelListings,
    chainId,
    selectedItems,
    isMature,
    isRedeemChecked,
    termToast,
    tokenListings,
    purchaseCurrencySymbol,
    onCloseModal,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} variant="standard">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isMature ? (
            <Text>Cancel and Redeem</Text>
          ) : (
            <Trans>Cancel Listing</Trans>
          )}
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody>
          <Text color="blue.9" variant="body-sm/normal" mb="16px">
            {isMature ? (
              <Text>
                Canceling your listing will automatically redeem your tokens and
                transfer your {purchaseCurrencySymbol} to your wallet.
              </Text>
            ) : (
              <Trans>
                Cancel your open listings to return the tokens to your wallet.
              </Trans>
            )}
          </Text>
          <Box mb="20px" />
          <Text color="gray.5" variant="body-sm/semibold" mb={3}>
            <Trans>
              {selectedItems.size
                ? `${selectedItems.size} listing${selectedItems.size > 1 ? 's' : ''} selected`
                : 'Select listings to cancel'}
            </Trans>
          </Text>
          <TableContainer w="full" mt="0 !important">
            <Table isFixedLayout noPadding>
              <Thead>
                <Tr>
                  {TABLE_HEADERS.map(({ column, label }) => (
                    <TableHeaderCell
                      key={column}
                      column={column}
                      handleClick={handleThClick}
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                      hasCheckbox={column === 'listed'}
                      isDisabled={isMature}
                      isChecked={isChecked || isMature}
                      handleToggleListings={handleToggleListings}
                      isIndeterminate={isIndeterminate}
                    >
                      {label}
                    </TableHeaderCell>
                  ))}
                </Tr>
              </Thead>
              <Tbody w="full">
                {sortedTableData.length > 0
                  ? sortedTableData.map((listing, index) => (
                      <Tr
                        key={listing.transactionHash}
                        bg={index % 2 !== 0 ? 'blue.0' : undefined}
                      >
                        <TableCell
                          hasCheckbox
                          isDisabled={isMature}
                          isChecked={selectedItems.has(index) || isMature}
                          handleCheckboxChange={(e) =>
                            handleCheckboxChange(index, e.target.checked)
                          }
                        >
                          <Tooltip
                            noDelay
                            shouldWrapChildren
                            label={dayjs
                              .unix(Number(listing.timestamp))
                              .format('MMM D, hh:mma')}
                          >
                            <Text variant="body-xs/medium" marginBottom="4px">
                              {dayjs.unix(Number(listing.timestamp)).fromNow()}
                              {''}
                            </Text>
                          </Tooltip>
                          <Link
                            href={chainConfig?.getExplorerTransactionLink(
                              listing.transactionHash
                            )}
                            isExternal
                            color="gray.3"
                            px={1}
                          >
                            <FontAwesomeIcon
                              icon={['far', 'arrow-up-right']}
                              width="10px"
                              height="10px"
                            />
                          </Link>
                        </TableCell>
                        <TableCell variant="body-sm/semibold">
                          {formatFixed(listing.remainingAmount)}
                        </TableCell>
                      </Tr>
                    ))
                  : null}
              </Tbody>
            </Table>
          </TableContainer>
          {isMature && (
            <>
              <Box mb="20px" />
              <HStack>
                {isMature && (
                  <Checkbox
                    isDisabled={!isMature}
                    isChecked={isRedeemChecked}
                    onChange={(e) => setIsRedeemChecked(e.target.checked)}
                    pr={1}
                  />
                )}
                <Text variant="body-sm/normal" color="blue.9">
                  Uncheck to disable auto-redemptions
                </Text>
              </HStack>
            </>
          )}
          <CancelListingButton
            onClick={submit}
            isCancelAndRedeem={isMature && isRedeemChecked}
            isDisabled={
              tokenListings.length === 0 ||
              selectedItems.size === 0 ||
              isDelisting
            }
            isLoading={isDelisting}
            variant="secondary"
            size="sm"
            w="full"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
