import { Box, Flex, HStack, Image, Link, Text, VStack } from '@chakra-ui/react'
import sparkle from '../../../../assets/icons/sparkle.svg'
import { FixedNumber } from 'ethers'
import Tooltip from '../../../../components/elements/Tooltip'
import { multiply } from '../../../../helpers/math'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useConfig } from '../../../../providers/config'
import { Trans } from '@lingui/macro'

const ListingTooltip = ({
  listingRate,
  auctionClearingRate,
  listingMarkup,
  auctionId,
  isInheritParentTextVariant = false,
}: {
  listingRate: FixedNumber
  auctionClearingRate: FixedNumber
  listingMarkup: FixedNumber
  auctionId: string
  isInheritParentTextVariant?: boolean
}) => {
  const gradientTextStyle = {
    background:
      'linear-gradient(to left, rgba(204, 0, 255, 1), rgba(10, 94, 217, 1))',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  }

  const containerStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'bottom',
    bg: 'white',
  }

  const listingRatePercentageValue = listingRate
    ? multiply(listingRate, FixedNumber.fromString('100', listingRate.format))
    : FixedNumber.fromString('0', 18)
  const { formattedPercentage: formattedListingRatePercentage } =
    fixedToFormattedPercentage(listingRatePercentageValue, 3, true)

  const auctionClearingRatePercentageValue = auctionClearingRate
    ? multiply(
        auctionClearingRate,
        FixedNumber.fromString('100', auctionClearingRate.format)
      )
    : FixedNumber.fromString('0', 18)
  const { formattedPercentage: formattedAuctionClearingRatePercentage } =
    fixedToFormattedPercentage(auctionClearingRatePercentageValue, 3, true)

  const listingMarkupPercentageValue = listingMarkup
    ? multiply(
        listingMarkup,
        FixedNumber.fromString('100', listingMarkup.format)
      )
    : FixedNumber.fromString('0', 18)
  const { formattedPercentage: formattedListingMarkupPercentage } =
    fixedToFormattedPercentage(listingMarkupPercentageValue, 3, true)

  const config = useConfig()
  const detailsLink =
    config.isMainnet && config.resultsBucket
      ? `https://${config.resultsBucket}.s3.amazonaws.com/mainnet/${auctionId}.pdf`
      : undefined

  return (
    <Tooltip
      noDelay
      placement="bottom"
      label={
        <VStack alignItems="flex-start" w="full" color="white">
          <HStack
            justifyContent="space-between"
            w="full"
            mb={4}
            mt="0 !important"
          >
            <Text as="span" variant="body-md/bold" color="white">
              <Trans>Listing rate:</Trans>
            </Text>
            <Text as="span" variant="body-md/bold" color="white">
              {formattedListingRatePercentage}%
            </Text>
          </HStack>
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/normal" color="gray.400">
              <Trans>Clearing rate:</Trans>
              <Link href={detailsLink} isExternal color="gray.3">
                <FontAwesomeIcon
                  icon={['far', 'arrow-up-right']}
                  width="12px"
                  height="12px"
                />
              </Link>
            </Text>
            <Text as="span" variant="body-sm/normal" color="gray.400">
              {formattedAuctionClearingRatePercentage}%
            </Text>
          </HStack>
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/normal" color="gray.400">
              <Trans>Discount Rate Mark-Up:</Trans>
            </Text>
            <Text as="span" variant="body-sm/normal" color="gray.400">
              {formattedListingMarkupPercentage}%
            </Text>
          </HStack>
        </VStack>
      }
    >
      <Box display="inline-flex" alignItems="center" sx={containerStyle}>
        <Flex alignItems="center" gap="4px" bg="white">
          {/* Image next to Text */}
          <Image src={sparkle} alt={'Listing Markup'} />
          <Text
            sx={gradientTextStyle}
            bgClip="text"
            whiteSpace="nowrap"
            variant={isInheritParentTextVariant ? 'inherit' : 'body-sm/normal'}
            fontWeight="bold"
          >
            {formattedListingRatePercentage}%
          </Text>
        </Flex>
      </Box>
    </Tooltip>
  )
}

export default ListingTooltip
