import { ChainId } from '@usedapp/core'
import { GRADIENTS, OPACITIES, VERTICAL_GRADIENTS } from '../chain-config'
import { ChainSpecificConfig, Config } from '../config'

export const config = {
  chains: {
    [ChainId.Mainnet.toString()]: {
      chainId: ChainId.Mainnet,
      chainName: 'Ethereum',
      listingsContractAddress: '0x721231231231231231212',
      verticalGradient: VERTICAL_GRADIENTS[ChainId.Mainnet],
      gradient: GRADIENTS[ChainId.Mainnet],
      opacity: OPACITIES[ChainId.Mainnet],
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.Avalanche.toString()]: {
      chainId: ChainId.Avalanche,
      chainName: 'Avalanche',
      listingsContractAddress: '0x721231231231231231212',
      verticalGradient: VERTICAL_GRADIENTS[ChainId.Avalanche],
      gradient: GRADIENTS[ChainId.Avalanche],
      opacity: OPACITIES[ChainId.Avalanche],
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.Polygon.toString()]: {
      chainId: ChainId.Polygon,
      chainName: 'Polygon',
      listingsContractAddress: '0x721231231231231231212',
      verticalGradient: VERTICAL_GRADIENTS[ChainId.Polygon],
      gradient: GRADIENTS[ChainId.Polygon],
      opacity: 0.5,
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.Mumbai.toString()]: {
      chainId: ChainId.Mumbai,
      chainName: 'Mumbai',
      listingsContractAddress: '0x721231231231231231212',
      verticalGradient: VERTICAL_GRADIENTS[ChainId.Polygon],
      gradient: GRADIENTS[ChainId.Polygon],
      opacity: 0.5,
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.Base.toString()]: {
      chainId: ChainId.Base,
      chainName: 'Base',
      listingsContractAddress: '0x721231231231231231212',
      verticalGradient: VERTICAL_GRADIENTS[ChainId.Base],
      gradient: GRADIENTS[ChainId.Base],
      opacity: 0.5,
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.Sepolia.toString()]: {
      chainId: ChainId.Sepolia,
      chainName: 'Sepolia',
      listingsContractAddress: '0x721231231231231231212',
      verticalGradient: VERTICAL_GRADIENTS[ChainId.Mainnet],
      opacity: OPACITIES[ChainId.Mainnet],
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.Arbitrum.toString()]: {
      chainId: ChainId.Arbitrum,
      chainName: 'Arbitrum',
      opacity: 0.5,
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.BSC.toString()]: {
      chainId: ChainId.BSC,
      chainName: 'BSC',
      opacity: 0.5,
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
    [ChainId.Localhost.toString()]: {
      chainId: ChainId.Localhost,
      chainName: 'Localhost',
      opacity: 0.5,
      getExplorerAddressLink: (address: string) =>
        `https://etherscan.io/address/${address}`,
      getExplorerTransactionLink: (hash: string) =>
        `https://etherscan.io/tx/${hash}`,
    } as ChainSpecificConfig,
  },
} as Config
