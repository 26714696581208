import { useMemo } from 'react'
import { PageAuctionQuery } from '../../gql/graphql'
import { AuctionActivityData } from '../model'
import { BigNumber } from 'ethers'

export function useAuctionActivity(
  termBids: PageAuctionQuery['auctionTermBids'],
  termOffers: PageAuctionQuery['auctionTermOffers']
): AuctionActivityData {
  return useMemo(() => {
    const auctionActivityData = {
      bidsCount: 0,
      offersCount: 0,
      bidsAmount: BigNumber.from(0),
      offersAmount: BigNumber.from(0),
    }
    if (termBids && termOffers) {
      auctionActivityData.bidsCount = termBids.length
      auctionActivityData.offersCount = termOffers.length

      auctionActivityData.bidsAmount = termBids.reduce(
        (o, bid) => o.add(bid.amount),
        BigNumber.from(0)
      )
      auctionActivityData.offersAmount = termOffers.reduce(
        (o, offer) => o.add(offer.amount),
        BigNumber.from(0)
      )
    }
    return auctionActivityData
  }, [termBids, termOffers])
}
