import { Box, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

interface HighlightedTextProps {
  children: React.ReactNode
  color?: string
  fontSize?: string
  fontWeight?: string
  mb?: number
  pb?: number
  borderBottomColor: string
  minColWidth?: number
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({
  children,
  color = 'white',
  fontSize = '32px',
  fontWeight = '400',
  mb = 2.5,
  pb = 2.5,
  borderBottomColor,
  minColWidth,
}) => {
  const [minW, setMinW] = useState(minColWidth)

  useEffect(() => {
    if (minColWidth !== undefined) {
      setMinW(minColWidth)
    }
  }, [minColWidth])

  return (
    <Box position="relative" mb={mb} pb={pb} minW={minW + 'px'}>
      <Text
        variant="body-2xl/bold"
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        pb={1.5}
        whiteSpace="nowrap"
      >
        {children}
      </Text>
      <Box
        position="absolute"
        bottom="0"
        left="0"
        height="6px"
        width="100%"
        bg={borderBottomColor}
      />
    </Box>
  )
}

export default HighlightedText
