import { Flex, Text } from '@chakra-ui/react'
import TrackPointsSummary from '../TrackPointsSummary'
import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'

export default function TrackSectionLoading() {
  return (
    <Flex direction="column" gap={6}>
      <Text color={'blue.9'} variant={'body-2xl/medium'}>
        Track how you earn
      </Text>
      <TrackPointsSummary
        data={undefined}
        preseasonPoints={undefined}
        season={{
          id: 1,
          start: dayjs().toISOString(),
          end: dayjs().toISOString(),
          name: '',
          isLocked: false,
          meta: {
            socialPoints: 17500,
          },
        }}
        totalNumberOfAuctions={0}
        totalNumberOfWeeks={0}
        totalPoints={0}
        totalActiveLoans={FixedNumber.fromString('0')}
        totalNumOfActiveLoans={0}
        isLoading={true}
      />
    </Flex>
  )
}
