import { TooltipProps } from '@chakra-ui/react'
import { useState } from 'react'
import copy from 'copy-to-clipboard'
import Tooltip from '../Tooltip'
import { CopyIcon } from '../Icons'

export const ClickCopy = ({
  copyText,
  helperText,
  tooltipProps,
  stopPropagation,
}: {
  copyText: string
  helperText: string
  tooltipProps?: Omit<TooltipProps, 'children'>
  stopPropagation?: boolean
}) => {
  const [copied, setCopied] = useState(false)
  const onCopy = () => {
    setCopied(true)
    copy(copyText)
    let timeout = setTimeout(() => {
      setCopied(false)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }

  const handleClick = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    onCopy()
  }

  return (
    <Tooltip noDelay label={copied ? 'Copied' : helperText} {...tooltipProps}>
      <CopyIcon
        width={3}
        height={3}
        cursor="pointer"
        color="gray.3"
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default ClickCopy
