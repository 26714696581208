import { useMemo } from 'react'
import ThemedIcon from '../components/elements/ThemedIcon'
import { CheckboxOption } from '../components/layout/Sidebar/Filter'
import {
  Address,
  Auction,
  AuctionActivityData,
  Currency,
  AuctionStatus as Status,
  TermPeriod,
} from '../data/model'
import { AuctionFilters, useFilters } from '../providers/filters'
import {
  bucketMaturityTimestamp,
  generateFilterOptions,
  getAuctionStatus,
  getCurrency,
  termLengthToColor,
} from '../helpers/utils'
import { Dayjs } from 'dayjs'
import { useCurrentTimeSlow } from '../data/hooks/helper-hooks'
import { fromPairs } from 'lodash'
import TokenIcon from '../components/elements/TokenIcon'
import ClearingPulse from '../pages/Auctions/elements/ClearingPulse'
import StatusIndicator from '../components/elements/StatusIndicator'
import { useConfig } from '../providers/config'
import NetworkIcon from '../components/elements/NetworkIcon'

export type AuctionsPageParams = {
  currencies: Record<string, Currency[]> | null | undefined
  auctions: Auction[] | null | undefined
  terms:
    | Pick<
        TermPeriod,
        | 'id'
        | 'repoServicerAddress'
        | 'rolloverManagerAddress'
        | 'collateralManagerAddress'
        | 'termRepoLockerAddress'
        | 'termRepoTokenAddress'
      >[]
    | undefined
  auctionActivityData:
    | { [auctionAddress: Address]: AuctionActivityData }
    | null
    | undefined
  onViewAuction: (auctionAddress: string, chainId: string) => void
}

const statusTypes: Status[] = ['clearing', 'upcoming', 'live', 'closed']

export default function AuctionsPageViewModel(params: AuctionsPageParams) {
  const auctions = params.auctions
  const terms = params.terms
  const currencies = params.currencies
  const auctionActivityData = params.auctionActivityData
  const onViewAuction = params.onViewAuction

  const flattenedCurrencies = useMemo(() => {
    return currencies && Object.values(currencies).flat()
  }, [currencies])

  const isDataLoading = useMemo(
    () => !auctions || !currencies,
    [auctions, currencies]
  )

  const { filters: filtersWithoutDefaults } = useFilters()
  const nowSlow = useCurrentTimeSlow()

  const config = useConfig()

  const {
    auctionsByStatus,
    termsById,
    loanTermOptions,
    lendingOptions,
    collateralOptions,
    statusOptions,
    chainOptions,
  } = useMemo(() => {
    const { tenors, lends, collats, statuses, chains } = generateFilterOptions(
      config,
      auctions ?? [],
      flattenedCurrencies ?? [],
      nowSlow
    )

    const loanTermOptions: CheckboxOption<'term'>[] = tenors.map((term) => ({
      label: term.tenorstring,
      value: term.tenorword,
      icon: (
        <ThemedIcon
          icon={['far', 'clock']}
          color={termLengthToColor(term.tenorstring)}
        />
      ),
      resultCount: term.count,
    }))

    const lendingOptions: CheckboxOption<'lending'>[] = lends.map((lend) => ({
      label: lend.symbol,
      value: lend.symbol,
      icon: <TokenIcon token={lend.symbol} key={lend.symbol} />,
      resultCount: lend.count,
    }))

    const collateralOptions: CheckboxOption<'collateral'>[] = collats.map(
      (collat) => ({
        label: collat.symbol,
        value: collat.symbol,
        icon: <TokenIcon token={collat.symbol} key={collat.symbol} />,
        resultCount: collat.count,
      })
    )

    const statusOptions: CheckboxOption<'status'>[] = statuses.map(
      (status) => ({
        label: status.status[0].toUpperCase() + status.status.slice(1),
        value: status.status,
        icon:
          status.status === 'clearing' ? (
            <ClearingPulse w={6} h={6} />
          ) : (
            <StatusIndicator status={status.status} />
          ),
        resultCount: status.count,
      })
    )

    const chainOptions: CheckboxOption<'chain'>[] = chains.map((chain) => ({
      label: chain.name,
      value: chain.id.toString(),
      icon: <NetworkIcon chainId={chain.id} />,
      resultCount: chain.count,
    }))

    const filters = {
      term: fromPairs(
        loanTermOptions.map((o) => {
          return [o.value, filtersWithoutDefaults.term[o.value] ?? false]
        })
      ),
      lending: fromPairs(
        lendingOptions.map((o) => [
          o.value,
          filtersWithoutDefaults.lending[o.value] ?? false,
        ])
      ),
      collateral: fromPairs(
        collateralOptions.map((o) => [
          o.value,
          filtersWithoutDefaults.collateral[o.value] ?? false,
        ])
      ),
      status: fromPairs(
        statusOptions.map((o) => [
          o.value,
          filtersWithoutDefaults.status[o.value] ?? false,
        ])
      ),
      chain: fromPairs(
        chainOptions.map((o) => [
          o.value,
          filtersWithoutDefaults.chain[o.value] ?? false,
        ])
      ),
    } as AuctionFilters

    const filteredAuctions =
      auctions && flattenedCurrencies
        ? auctions.filter((a) =>
            filterAuctions(filters, a, flattenedCurrencies, nowSlow)
          )
        : []

    const auctionsByStatus = fromPairs(
      statusTypes.map((status: Status) => [
        status,
        filteredAuctions.filter(
          (a) =>
            getAuctionStatus(
              a.auctionStartTimestamp,
              a.auctionRevealTimestamp,
              a.auctionEndTimestamp,
              nowSlow,
              a.closed
            ).status === status
        ),
      ])
    )

    const termsById = fromPairs(terms?.map((term) => [term.id, term]))

    return {
      auctionsByStatus,
      termsById,
      loanTermOptions,
      lendingOptions,
      collateralOptions,
      statusOptions,
      chainOptions,
    }
  }, [
    config,
    auctions,
    terms,
    nowSlow,
    flattenedCurrencies,
    filtersWithoutDefaults,
  ])

  return {
    isDataLoading,
    currencies,
    termsById,
    auctionsByStatus,
    auctionActivityData,
    loanTermOptions,
    lendingOptions,
    collateralOptions,
    statusOptions,
    chainOptions,
    onViewAuction,
  }
}

function filterAuctions(
  filters: AuctionFilters,
  auction: Auction,
  currencies: Currency[],
  now: Dayjs
): boolean {
  const auctionStatus = getAuctionStatus(
    auction.auctionStartTimestamp,
    auction.auctionRevealTimestamp,
    auction.auctionEndTimestamp,
    now,
    auction.closed
  )

  const purchaseCurrency = getCurrency(currencies, auction.purchaseCurrency)
  const collateralCurrency = getCurrency(currencies, auction.collateralCurrency)

  const bucket = bucketMaturityTimestamp(
    auction.auctionEndTimestamp,
    auction.maturityTimestamp
  )

  const isStatusFilterSelected = Object.values(filters.status).some(
    (value) => value
  )
  const isTermFilterSelected = Object.values(filters.term).some(
    (value) => value
  )
  const isLendingFilterSelected = Object.values(filters.lending).some(
    (value) => value
  )
  const isCollateralFilterSelected = Object.values(filters.collateral).some(
    (value) => value
  )
  const isChainFilterSelected = Object.values(filters.chain).some(
    (value) => value
  )

  if (
    !isStatusFilterSelected &&
    !isTermFilterSelected &&
    !isLendingFilterSelected &&
    !isCollateralFilterSelected &&
    !isChainFilterSelected
  ) {
    return true // Show all auctions if no filters are selected
  }

  if (isStatusFilterSelected && filters.status[auctionStatus.status] !== true) {
    return false // Exclude auctions that don't match the selected status filter
  }

  if (isTermFilterSelected && filters.term[bucket] !== true) {
    return false // Exclude auctions that don't match the selected term filter
  }

  if (
    isLendingFilterSelected &&
    purchaseCurrency &&
    // filters.lending[auction.purchaseCurrency] !== true
    filters.lending[purchaseCurrency.symbol] !== true
  ) {
    return false // Exclude auctions that don't match the selected lending filter
  }

  if (
    isCollateralFilterSelected &&
    collateralCurrency &&
    // filters.collateral[auction.collateralCurrency] !== true
    filters.collateral[collateralCurrency.symbol] !== true
  ) {
    return false // Exclude auctions that don't match the selected collateral filter
  }

  if (isChainFilterSelected && filters.chain[auction.chainId] !== true) {
    return false // Exclude auctions that don't match the selected chain filter
  }

  return true
}
