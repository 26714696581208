export const retryUntil = async <T>(
  fn: () => Promise<T>,
  retries: number,
  retryIntervalMs: number
) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn()
    } catch (err) {
      console.warn('Retrying due to error: ', err)
      await new Promise((resolve) => setTimeout(resolve, retryIntervalMs))
    }
  }
  throw new Error('Timeout')
}
