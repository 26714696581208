import { Connector, ConnectorUpdateData, ConnectorEvent } from '@usedapp/core'
import { SafeAppProvider } from '@safe-global/safe-apps-provider'
import SafeAppsSDK, { Opts } from '@safe-global/safe-apps-sdk'
import { providers } from 'ethers'

export class GnosisSafeConnector implements Connector {
  public provider?: providers.Web3Provider
  public readonly name = 'GnosisSafe'

  readonly update = new ConnectorEvent<ConnectorUpdateData>()
  private sdk?: SafeAppsSDK
  private safeProvider?: SafeAppProvider

  constructor(private readonly opts: Opts = {}) {
    this.sdk = new SafeAppsSDK(opts)
  }

  private async init() {
    const sdk = this.sdk
    if (!sdk) {
      throw new Error('Could not load sdk')
    }
    const safeInfo = await sdk.safe.getInfo()
    if (!safeInfo) {
      throw new Error('Could not load Safe information')
    }
    this.safeProvider = new SafeAppProvider(safeInfo, sdk)
  }

  async connectEagerly(): Promise<void> {
    try {
      await this.init()
      if (!this.safeProvider) {
        throw new Error('Could not initialize Safe provider')
      }
      const accounts = (await this.safeProvider.request({
        method: 'eth_accounts',
      })) as string[]
      const chainId = await this.safeProvider.request({ method: 'eth_chainId' })
      this.provider = new providers.Web3Provider(this.safeProvider)
      if (accounts.length > 0) {
        // Only emit an update if accounts are found, indicating an existing connection.
        this.update.emit({ chainId, accounts })
      }
    } catch (e: any) {
      console.error(e)
      // In an eager connection, we typically don't throw errors but handle them silently.
      // So, we don't throw an error here like in the activate method.
    }
  }

  async activate(): Promise<void> {
    try {
      await this.init()
      if (!this.safeProvider) {
        throw new Error('Could not initialize Safe provider')
      }
      await this.safeProvider.connect()

      const accounts = (await this.safeProvider.request({
        method: 'eth_accounts',
      })) as string[]
      const chainId = await this.safeProvider.request({ method: 'eth_chainId' })
      this.provider = new providers.Web3Provider(this.safeProvider)
      this.update.emit({ chainId: parseInt(chainId), accounts })
    } catch (e: any) {
      console.error(e)
      throw new Error(
        'Could not activate Gnosis Safe connector: ' + (e.message ?? '')
      )
    }
  }

  async deactivate(): Promise<void> {
    // Gnosis Safe doesn't have a disconnect method in the same sense as traditional wallets
    this.provider = undefined
    this.safeProvider = undefined
  }
}
