import { BigNumber } from 'ethers'
import { PagePortfolioQuery } from '../../gql/graphql'
import { useMemo } from 'react'

export function useGraphAllRepoExposures(
  data: Record<string, PagePortfolioQuery> | undefined
): { [termId: string]: BigNumber } | undefined {
  const result = useMemo(() => {
    const sums: { [key: string]: BigNumber } = {}

    if (data) {
      Object.values(data).forEach((pagePortfolioQuery) => {
        pagePortfolioQuery.termRepoCollaterals.forEach((repoCollateral) => {
          const termId: string = repoCollateral.term.id
          const exposure: BigNumber =
            repoCollateral.repoExposure?.repoExposure || BigNumber.from(0)

          sums[termId] = (sums[termId] || BigNumber.from(0)).add(exposure)
        })
      })
    }

    return sums
  }, [data])

  return result
}
