import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { captureException } from '@sentry/react'
import { ChainId, TransactionStatus, useSendTransaction } from '@usedapp/core'
import { BigNumber, Contract, FixedNumber, Signer } from 'ethers'
import { isAddress, randomBytes, solidityKeccak256 } from 'ethers/lib/utils'
import { range } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { PageAuctionQuery } from '../../gql/graphql'
import { TermAuctionOfferLocker as TermAuctionOfferLocker_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermAuctionOfferLocker'
import { TermAuctionOfferLocker as TermAuctionOfferLocker_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermAuctionOfferLocker'
import { TermAuctionOfferLocker as TermAuctionOfferLocker_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermAuctionOfferLocker'
import { TermAuctionOfferSubmissionStruct as TermAuctionOfferSubmissionStruct_0_2_4 } from '../../abi-generated/abi/v0.2.4/ITermAuctionOfferLocker'
import { TermAuctionOfferSubmissionStruct as TermAuctionOfferSubmissionStruct_0_4_1 } from '../../abi-generated/abi/v0.4.1/ITermAuctionOfferLocker'
import { TermAuctionOfferSubmissionStruct as TermAuctionOfferSubmissionStruct_0_6_0 } from '../../abi-generated/abi/v0.6.0/ITermAuctionOfferLocker'
import TermAuctionOfferLockerABI_0_2_4 from '../../abi/v0.2.4/TermAuctionOfferLocker.json'
import TermAuctionOfferLockerABI_0_4_1 from '../../abi/v0.4.1/TermAuctionOfferLocker.json'
import TermAuctionOfferLockerABI_0_6_0 from '../../abi/v0.6.0/TermAuctionOfferLocker.json'
import {
  fixedToBigNumber,
  getGeneratedTenderId,
  getABIVersion,
} from '../../helpers/conversions'
import { retryUntil } from '../../helpers/retry'
import { waitForStatus } from '../../helpers/wait'
import { useStorage } from '../../providers/storage'
import { useClock } from '../../providers/time'
import {
  createOrUpdatePrivateLoanTender,
  deserializeLoanTender,
} from '../../services/private-bids-and-offers'
import { generateTermAuth } from '../auth'
import {
  Address,
  Amount,
  RevealTenderRequest,
  SubmittedLoanTender,
  TenderId,
  ValidatedLoanTender,
} from '../model'
import { obfuscatePrice } from '../obfuscate'
import { useTxTimestamp } from './helper-hooks'
import { EvmError, decodeError } from '../../helpers/evm'
import ReactGA from 'react-ga4'

import { Strategy } from '../../abi-generated/abi/yearn-vault/Strategy'
import StrategyABI from '../../abi/yearn-vault/Strategy.json'
import { useConfig } from '../../providers/config'

declare global {
  interface Window {
    safary?: {
      track: (args: {
        eventType: string
        eventName: string
        parameters: { [key: string]: string }
      }) => void
    }
  }
}

export type TermAuctionOfferLocker =
  | TermAuctionOfferLocker_0_2_4
  | TermAuctionOfferLocker_0_4_1
  | TermAuctionOfferLocker_0_6_0

export function useLoanTenders(
  chainId: ChainId,
  version: string,
  account: Address | undefined,
  auction: Address,
  decimals: { [chainId: string]: { [address: string]: number } } | undefined,
  prices: { [chainId: string]: { [address: string]: FixedNumber } } | undefined,
  offerLockerAddress: Address | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  queryResult: PageAuctionQuery['termOffers'] | undefined,
  readFromSubgraph: () => void,
  revealServerUrl?: string,
  vaultContractAddress?: string
): [
  // loan tenders list
  SubmittedLoanTender[] | undefined,
  // reload tenders
  () => void,
  // delete tenders
  (
    | ((
        ids: TenderId[],
        offerLockerAddress: Address,
        contractVersion: string
      ) => Promise<void>)
    | undefined
  ),
  // lock tenders
  (
    | ((
        tenders: ValidatedLoanTender[],
        lender: string,
        termId: string,
        auctionId: string,
        offerLockerAddress: Address,
        contractVersion: string,
        referralCode?: string
      ) => Promise<SubmittedLoanTender[] | undefined>)
    | undefined
  ),
  // lock loan tenders through vault
  (
    | ((
        tenders: ValidatedLoanTender[],
        lender: string,
        termId: string,
        auctionId: string,
        termAuction: Address,
        termRepoToken: Address
      ) => Promise<SubmittedLoanTender[] | undefined>)
    | undefined
  ),
  // delete vault tenders
  ((ids: TenderId[]) => Promise<void>) | undefined,
] {
  const [localResult, setLocalResult] = useState<SubmittedLoanTender[]>()
  const [result, setResult] = useState<SubmittedLoanTender[]>()
  const config = useConfig()
  const clock = useClock()
  const { storage: localStorage } = useStorage()
  const transactionStates = useMemo(
    () =>
      ({}) as {
        lockTenders: TransactionStatus | undefined
        lockVaultTenders: TransactionStatus | undefined
        deleteTenders: TransactionStatus | undefined
        deleteVaultTenders: TransactionStatus | undefined
      },
    []
  )

  const validateActiveNetwork = useCallback(async () => {
    const activeNetwork = await signer?.provider?.getNetwork()
    return activeNetwork?.chainId === chainId
  }, [chainId, signer])

  const {
    sendTransaction: deleteTendersTransaction,
    state: deleteTendersTransactionState,
    resetState: resetDeleteTendersState,
  } = useSendTransaction()
  const {
    sendTransaction: lockTendersTransaction,
    state: lockTendersTransactionState,
    resetState: resetLockTendersState,
  } = useSendTransaction()

  const {
    sendTransaction: lockVaultTendersTransaction,
    state: lockVaultTendersTransactionState,
    resetState: resetLockVaultTendersState,
  } = useSendTransaction()

  const {
    sendTransaction: deleteVaultTendersTransaction,
    state: deleteVaultTendersTransactionState,
    resetState: resetDeleteVaultTendersState,
  } = useSendTransaction()

  const lookupTxTimestamp = useTxTimestamp(provider)

  // WARNING: These are used to update in-place so that the lock/delete
  //          methods always have access to the latest transaction state.
  useEffect(() => {
    transactionStates.lockTenders = lockTendersTransactionState
  }, [lockTendersTransactionState, transactionStates])
  useEffect(() => {
    transactionStates.lockVaultTenders = lockVaultTendersTransactionState
  }, [lockVaultTendersTransactionState, transactionStates])
  useEffect(() => {
    transactionStates.deleteTenders = deleteTendersTransactionState
  }, [deleteTendersTransactionState, transactionStates])
  useEffect(() => {
    transactionStates.deleteVaultTenders = deleteVaultTendersTransactionState
  }, [deleteVaultTendersTransactionState, transactionStates])

  const offerLockerContract = useMemo(() => {
    const contract: {
      [offerLockerAddress: Address]: {
        offerLocker: TermAuctionOfferLocker
        version: string
      }
    } = {}

    if (!offerLockerAddress) {
      return undefined
    }

    if (contract[offerLockerAddress]) {
      return contract
    }

    const tmpContractData = {} as {
      offerLocker: TermAuctionOfferLocker
      version: string
    }

    tmpContractData.version = version

    switch (version) {
      case '0.6.0':
        tmpContractData.offerLocker = new Contract(
          offerLockerAddress,
          TermAuctionOfferLockerABI_0_6_0,
          provider
        ) as TermAuctionOfferLocker_0_6_0
        break
      case '0.4.1':
        tmpContractData.offerLocker = new Contract(
          offerLockerAddress,
          TermAuctionOfferLockerABI_0_4_1,
          provider
        ) as TermAuctionOfferLocker_0_4_1
        break
      case '0.2.4':
      default:
        tmpContractData.offerLocker = new Contract(
          offerLockerAddress,
          TermAuctionOfferLockerABI_0_2_4,
          provider
        ) as TermAuctionOfferLocker_0_2_4
    }

    contract[offerLockerAddress] = tmpContractData

    return contract
  }, [offerLockerAddress, provider, version])

  const yearnVaultContract = useMemo(() => {
    if (!vaultContractAddress || !isAddress(vaultContractAddress))
      return undefined
    return new Contract(vaultContractAddress, StrategyABI, provider) as Strategy
  }, [provider, vaultContractAddress])

  const readFromLocalStorage = useCallback(() => {
    if (account === undefined || auction === undefined) {
      return
    }

    // Read from localstorage
    const tenders: SubmittedLoanTender[] = range(localStorage?.length ?? 0)
      .map((i) => localStorage.key(i))
      .filter((key): key is string => key?.startsWith('loan-') ?? false)
      .map((key) => localStorage.getItem(key))
      .filter((val): val is string => val !== null)
      .map((val) => deserializeLoanTender(val))
      .filter((val): val is SubmittedLoanTender => {
        // TODO: Validate the the loan tenders have all required fields.
        return val !== undefined
      })

    setLocalResult(
      tenders.filter(
        (t) =>
          t.lender.toLowerCase() === account.toLowerCase() &&
          t.auction === auction
      )
    )
  }, [account, auction, localStorage])

  const subgraphResultWithoutTimestamps = useMemo(() => {
    if (account === undefined || auction === undefined) {
      return undefined
    }

    return (
      queryResult
        ?.filter((tender) => tender.locked)
        ?.map(
          (remoteTender) =>
            ({
              chainId: chainId.toString(),
              type: 'loan',
              id: remoteTender.id,
              amount: {
                currency:
                  remoteTender?.auction?.term?.purchaseToken?.toString(),
                shiftedValue: BigNumber.from(remoteTender.amount?.toString()),
              },
              auction: auction.toString(),
              lender: remoteTender.offeror as string,
              transaction: remoteTender.lastTransaction,
            }) as SubmittedLoanTender
        ) ?? []
    )
  }, [account, auction, chainId, queryResult])

  const [subgraphResult, setSubgraphResult] = useState<
    SubmittedLoanTender[] | undefined
  >()
  useEffect(() => {
    ;(async () => {
      if (subgraphResultWithoutTimestamps === undefined) {
        setSubgraphResult(subgraphResultWithoutTimestamps)
        return
      }
      const subgraphResult = [] as SubmittedLoanTender[]
      for (const tender of subgraphResultWithoutTimestamps) {
        const txTimestamp = tender.transaction
          ? await lookupTxTimestamp(tender.transaction)
          : undefined
        if (txTimestamp) {
          tender.submittedDate = txTimestamp
        }
        subgraphResult.push(tender)
      }
      setSubgraphResult(subgraphResult)
    })()
  }, [lookupTxTimestamp, subgraphResultWithoutTimestamps])

  const combineResults = useCallback(() => {
    const localIds = localResult?.reduce((acc, val) => {
      acc.add(val.id)
      return acc
    }, new Set<string>())
    const remoteIds = subgraphResult?.reduce((acc, val) => {
      acc.add(val.id)
      return acc
    }, new Set<string>())
    const localOnly = localResult?.filter((val) => !remoteIds?.has(val.id))
    const remoteOnly = subgraphResult?.filter((val) => !localIds?.has(val.id))
    const both = localResult
      ?.filter((val) => remoteIds?.has(val.id))
      .map((local) => {
        const remote = subgraphResult?.find((remote) => remote.id === local.id)
        // Combine localTenders with remoteTenders. Newer data should overwrite older data. Local data should generally overwrite remote data?
        return {
          ...remote,
          ...local,
        }
      })

    const result = [
      ...(remoteOnly || []),
      ...(both || []),
      ...(localOnly || []),
    ].sort((a, b) => {
      if (a?.submittedDate === undefined) {
        return 1
      }
      if (b?.submittedDate === undefined) {
        return -1
      }
      return a.submittedDate - b.submittedDate
    })
    setResult(result)
  }, [subgraphResult, localResult])

  useEffect(() => {
    if (account === undefined || auction === undefined) {
      return
    }

    window.addEventListener('storage', readFromLocalStorage)
    readFromLocalStorage()

    return () => {
      window.removeEventListener('storage', readFromLocalStorage)
      setResult(undefined)
    }
  }, [readFromLocalStorage, account, auction])

  useEffect(combineResults, [subgraphResult, localResult, combineResults])

  const deleteTenders = useCallback(
    async (
      ids: TenderId[],
      offerLockerAddress: Address,
      contractVersion: string
    ) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || auction === undefined) {
        return
      }

      if (!offerLockerContract || !signer) {
        return
      }

      // Remove from localstorage
      ids.forEach((id) => localStorage.removeItem(`loan-${id}`))

      const abiVersion = getABIVersion(contractVersion)

      let data: any

      const offerLocker = offerLockerContract[offerLockerAddress].offerLocker

      switch (abiVersion) {
        case '0.6.0':
          console.info('deleting tenders using 0.6.0 abi')
          // Remove from smart contract
          data = (
            offerLocker as TermAuctionOfferLocker_0_6_0
          ).interface.encodeFunctionData('unlockOffers', [ids])
          break
        case '0.4.1':
          console.info('deleting tenders using 0.4.1 abi')
          // Remove from smart contract
          data = (
            offerLocker as TermAuctionOfferLocker_0_4_1
          ).interface.encodeFunctionData('unlockOffers', [ids])
          break
        case '0.2.4':
        default:
          console.info('deleting tenders using 0.2.4 abi')
          // Remove from smart contract
          data = (
            offerLocker as TermAuctionOfferLocker_0_2_4
          )?.interface.encodeFunctionData('unlockOffers', [
            ids,
            await generateTermAuth(
              signer,
              offerLocker as TermAuctionOfferLocker_0_2_4,
              'unlockOffers',
              [ids],
              clock.now().unix()
            ),
          ])
      }

      await deleteTendersTransaction({
        chainId,
        to: offerLockerAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deleteTenders,
        resetDeleteTendersState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      validateActiveNetwork,
      account,
      auction,
      offerLockerContract,
      signer,
      deleteTendersTransaction,
      chainId,
      resetDeleteTendersState,
      readFromSubgraph,
      localStorage,
      clock,
      transactionStates.deleteTenders,
    ]
  )

  const revealTender = useCallback(
    async (
      termId: string,
      auctionId: string,
      id: string,
      amount: FixedNumber,
      nonce: BigNumber
    ) => {
      if (!revealServerUrl) {
        return
      }

      await fetch(`${revealServerUrl}/reveal-offer`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({
          id,
          termId,
          auctionId,
          price: fixedToBigNumber(amount).div(100).toString(),
          nonce: nonce.toString(),
        } as RevealTenderRequest),
      })
    },
    [revealServerUrl]
  )

  const lockTenders = useCallback(
    async (
      tendersWithMaybeId: ValidatedLoanTender[],
      offeror: string,
      termId: string,
      auctionId: string,
      offerLockerAddress: Address,
      contractVersion: string,
      referralCode?: string
    ) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || auction === undefined) {
        return
      }

      if (!offerLockerContract || !signer) {
        return undefined
      }

      const abiVersion = getABIVersion(contractVersion)

      const tenderOffchainIdToOnchainIdMap = new Map<string, string>()

      const signerAddress = await signer.getAddress()

      const processTenders = (offerLockerContract: TermAuctionOfferLocker) => {
        const tenders = tendersWithMaybeId.map((tender) => {
          let tenderId: string
          if (tender.id) {
            tenderId = tender.id
            tenderOffchainIdToOnchainIdMap.set(tenderId, tender.id)
          } else {
            tenderId = solidityKeccak256(['string'], [v4()]) // create uuid that will be used to create a new on chain tender id
            const onChainTenderId = getGeneratedTenderId(
              tenderId,
              offerLockerContract,
              signerAddress
            )
            tenderOffchainIdToOnchainIdMap.set(tenderId, onChainTenderId)
          }

          return {
            ...tender,
            id: tenderId,
            nonce: BigNumber.from(randomBytes(32)),
          }
        })

        return tenders
      }

      // handle multiple contract versions:

      let offers: (
        | TermAuctionOfferSubmissionStruct_0_2_4
        | TermAuctionOfferSubmissionStruct_0_4_1
        | TermAuctionOfferSubmissionStruct_0_6_0
      )[] = []
      let data: any
      let tenders: {
        id: string
        nonce: BigNumber
        type: 'loan'
        amount: Amount
        interestRate?: FixedNumber | undefined
      }[]

      const offerLocker = offerLockerContract[offerLockerAddress].offerLocker

      let abi:
        | TermAuctionOfferLocker_0_2_4
        | TermAuctionOfferLocker_0_4_1
        | TermAuctionOfferLocker_0_6_0

      switch (abiVersion) {
        case '0.6.0':
          console.info('submitting tenders using 0.6.0 abi')
          if (!offerLocker) {
            console.error('offer locker not found! %o', abiVersion)
            captureException(
              new Error(
                `OfferLocker contract not found for version ${abiVersion}`
              )
            )
            return
          }

          tenders = processTenders(offerLocker)

          // Submit to smart contract
          offers = tenders.map((tender) => ({
            id: tender.id,
            amount: tender.amount.shiftedValue,
            offeror,
            offerPriceHash: obfuscatePrice(
              tender?.interestRate as FixedNumber,
              tender.nonce
            ),
            purchaseToken: tender.amount.currency,
          })) as TermAuctionOfferSubmissionStruct_0_6_0[]
          abi = offerLocker as TermAuctionOfferLocker_0_6_0

          if (referralCode && isAddress(referralCode)) {
            data = abi?.interface.encodeFunctionData('lockOffersWithReferral', [
              offers,
              referralCode,
            ])
          } else {
            data = abi?.interface.encodeFunctionData('lockOffers', [offers])
          }
          break
        case '0.4.1':
          console.info('submitting tenders using 0.4.1 abi')
          if (!offerLocker) {
            console.error('offer locker not found! %o', abiVersion)
            captureException(
              new Error(
                `OfferLocker contract not found for version ${abiVersion}`
              )
            )
            return
          }

          tenders = processTenders(offerLocker)

          // Submit to smart contract
          offers = tenders.map((tender) => ({
            id: tender.id,
            amount: tender.amount.shiftedValue,
            offeror,
            offerPriceHash: obfuscatePrice(
              tender?.interestRate as FixedNumber,
              tender.nonce
            ),
            purchaseToken: tender.amount.currency,
          })) as TermAuctionOfferSubmissionStruct_0_4_1[]
          abi = offerLocker as TermAuctionOfferLocker_0_4_1

          if (referralCode && isAddress(referralCode)) {
            data = abi?.interface.encodeFunctionData('lockOffersWithReferral', [
              offers,
              referralCode,
            ])
          } else {
            data = abi?.interface.encodeFunctionData('lockOffers', [offers])
          }
          break
        case '0.2.4':
        default:
          console.info('submitting tenders using 0.2.4 abi')
          if (!offerLocker) {
            console.error('offer locker not found! %o', abiVersion)
            captureException(
              new Error(
                `OfferLocker contract not found for version ${abiVersion}`
              )
            )
            return
          }

          tenders = processTenders(offerLocker)

          // Submit to smart contract
          offers = tenders.map((tender) => ({
            id: tender.id,
            amount: tender.amount.shiftedValue,
            offeror,
            offerPriceHash: obfuscatePrice(
              tender?.interestRate as FixedNumber,
              tender.nonce
            ),
            purchaseToken: tender.amount.currency,
          })) as TermAuctionOfferSubmissionStruct_0_2_4[]

          abi = offerLocker as TermAuctionOfferLocker_0_2_4
          data = abi?.interface.encodeFunctionData('lockOffers', [
            offers,
            await generateTermAuth(
              signer,
              offerLocker as TermAuctionOfferLocker_0_2_4,
              'lockOffers',
              [offers],
              clock.now().unix()
            ),
          ])
          break
      }

      const revealTx = Promise.all(
        tenders.map((tender) =>
          retryUntil(
            () =>
              revealTender(
                termId,
                auctionId,
                tenderOffchainIdToOnchainIdMap.get(tender.id) || tender.id,
                tender?.interestRate as FixedNumber,
                tender.nonce
              ),
            100,
            1000
          )
        )
      )

      await revealTx

      let resultHash: string | undefined
      try {
        resultHash = (
          await lockTendersTransaction({
            chainId,
            to: offerLockerAddress,
            from: account,
            data,
          })
        )?.transactionHash

        // Wait for state of lock transaction to complete.
        await waitForStatus(
          async () => transactionStates.lockTenders,
          resetLockTendersState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000,
          offerLocker
        )
      } catch (err) {
        if (err instanceof Error) {
          try {
            const decodedErr = decodeError(err, offerLocker.interface)
            captureException(new EvmError(err, decodedErr))
          } catch (e) {
            captureException(e)
            captureException(err)
            console.error(`Failed to decode error: ${JSON.stringify(e)}`)
          }
        } else {
          captureException(err)
        }
        throw err
      }

      const submitted = tenders.map((tender) => ({
        ...tender,
        chainId: chainId.toString(),
        submittedDate: clock.now().unix(), // TODO: This should come from the blockchain
        auction,
        id: tenderOffchainIdToOnchainIdMap.get(tender.id) || tender.id,
        lender: account,
        transaction: resultHash || '',
      }))

      // Add to localstorage
      for (const tender of submitted) {
        await createOrUpdatePrivateLoanTender(tender, localStorage)

        const amountFN = FixedNumber.fromValue(
          tender.amount.shiftedValue,
          decimals?.[chainId]?.[tender.amount.currency] ?? 18
        )
        const amountUSD = prices?.[chainId]?.[tender.amount.currency]
          ? amountFN.mulUnsafe(
              prices[chainId][tender.amount.currency].toFormat(amountFN.format)
            )
          : FixedNumber.from(0)
        ReactGA.event('purchase', {
          currency: 'USD',
          value: amountUSD.toUnsafeFloat(),
          transaction_id: `lend-${resultHash || v4()}`,
          // coupon: "",  // TODO: Add invite/referral codes here
        })
      }

      readFromSubgraph()

      return submitted
    },
    [
      validateActiveNetwork,
      account,
      auction,
      offerLockerContract,
      signer,
      readFromSubgraph,
      clock,
      revealTender,
      lockTendersTransaction,
      chainId,
      resetLockTendersState,
      transactionStates.lockTenders,
      localStorage,
      decimals,
      prices,
    ]
  )

  const lockVaultTenders = useCallback(
    async (
      tendersWithMaybeId: ValidatedLoanTender[],
      offeror: string,
      termId: string,
      auctionId: string,
      termAuction: string,
      termRepoToken: string
    ) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || auction === undefined) {
        return
      }

      if (
        !yearnVaultContract ||
        !vaultContractAddress ||
        !signer ||
        !offerLockerContract ||
        !offerLockerAddress
      ) {
        return undefined
      }

      console.log('lockVaultTenders: %o', tendersWithMaybeId)

      const offerLocker = offerLockerContract[offerLockerAddress].offerLocker

      const tenderOffchainIdToOnchainIdMap = new Map<string, string>()

      const processTenders = (yearnVaultContract: Strategy) => {
        const tenders = tendersWithMaybeId.map((tender) => {
          let tenderId: string
          if (tender.id) {
            tenderId = tender.id
            tenderOffchainIdToOnchainIdMap.set(tenderId, tender.id)
          } else {
            tenderId = solidityKeccak256(['string'], [v4()]) // create uuid that will be used to create a new on chain tender id
            const onChainTenderId = getGeneratedTenderId(
              tenderId,
              offerLocker,
              yearnVaultContract.address
            )
            tenderOffchainIdToOnchainIdMap.set(tenderId, onChainTenderId)
          }

          return {
            ...tender,
            id: tenderId,
            nonce: BigNumber.from(randomBytes(32)),
          }
        })

        return tenders
      }

      const tenders = processTenders(yearnVaultContract)

      const offers = tenders.map((tender) => ({
        id: tender.id,
        amount: tender.amount.shiftedValue,
        offeror,
        offerPriceHash: obfuscatePrice(
          tender?.interestRate as FixedNumber,
          tender.nonce
        ),
        purchaseToken: tender.amount.currency,
      })) as TermAuctionOfferSubmissionStruct_0_6_0[]

      console.log(
        'submitting tenders using vault...id: %o, onChainId: %o',
        offers?.[0]?.id.toString(),
        tenderOffchainIdToOnchainIdMap.get(offers?.[0]?.id.toString())
      )

      console.log(
        'call data: [%o, %o, %o, %o, %o]',
        termAuction,
        termRepoToken,
        offers?.[0].id,
        offers?.[0].offerPriceHash,
        offers?.[0].amount.toString()
      )

      const data = yearnVaultContract?.interface.encodeFunctionData(
        'submitAuctionOffer',
        [
          termAuction,
          termRepoToken,
          offers?.[0].id,
          offers?.[0].offerPriceHash,
          offers?.[0].amount,
        ]
      )

      const revealTx = Promise.all(
        tenders.map((tender) =>
          retryUntil(
            () =>
              revealTender(
                termId,
                auctionId,
                tenderOffchainIdToOnchainIdMap.get(tender.id) || tender.id,
                tender?.interestRate as FixedNumber,
                tender.nonce
              ),
            100,
            1000
          )
        )
      )

      await revealTx

      let resultHash: string | undefined
      try {
        resultHash = (
          await lockVaultTendersTransaction({
            chainId,
            to: vaultContractAddress,
            from: await signer.getAddress(),
            data,
          })
        )?.transactionHash

        // Wait for state of lock transaction to complete.
        await waitForStatus(
          async () => transactionStates.lockVaultTenders,
          resetLockVaultTendersState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000,
          yearnVaultContract
        )
      } catch (err) {
        if (err instanceof Error) {
          try {
            const decodedErr = decodeError(err, yearnVaultContract.interface)
            captureException(new EvmError(err, decodedErr))
          } catch (e) {
            captureException(e)
            captureException(err)
            console.error(`Failed to decode error: ${JSON.stringify(e)}`)
          }
        } else {
          captureException(err)
        }
        throw err
      }

      const submitted = tenders.map((tender) => ({
        ...tender,
        chainId: chainId.toString(),
        submittedDate: clock.now().unix(), // TODO: This should come from the blockchain
        auction,
        id: tenderOffchainIdToOnchainIdMap.get(tender.id) || tender.id,
        lender: vaultContractAddress,
        transaction: resultHash || '',
      }))

      // Add to localstorage
      for (const tender of submitted) {
        await createOrUpdatePrivateLoanTender(tender, localStorage)

        // const amountFN = FixedNumber.fromValue(
        //   tender.amount.shiftedValue,
        //   decimals?.[chainId]?.[tender.amount.currency] ?? 18
        // )
        // const amountUSD = prices?.[chainId]?.[tender.amount.currency]
        //   ? amountFN.mulUnsafe(
        //     prices[chainId][tender.amount.currency].toFormat(amountFN.format)
        //   )
        //   : FixedNumber.from(0)
        // ReactGA.event('purchase', {
        //   currency: 'USD',
        //   value: amountUSD.toUnsafeFloat(),
        //   transaction_id: `lend-${resultHash || v4()}`,
        //   // coupon: "",  // TODO: Add invite/referral codes here
        // })

        // Mark tenders as locked in safary if setup.
        if (window.safary) {
          window.safary.track({
            eventType: config.safary?.bidToBorrow?.type ?? 'click',
            eventName: `Offer ${tender.id} from ${account} to supply ${tender.amount.shiftedValue} ${tender.amount.currency}`,
            parameters: {
              tenderId: tender.id,
              borrower: account,
              amount: tender.amount.shiftedValue.toString(),
              currency: tender.amount.currency,
              auctionId: auctionId,
              termId: termId,
              transactionId: resultHash ?? '',
            },
          })
        }
      }

      readFromSubgraph()

      return submitted
    },
    [
      validateActiveNetwork,
      account,
      auction,
      yearnVaultContract,
      signer,
      readFromSubgraph,
      clock,
      revealTender,
      lockVaultTendersTransaction,
      chainId,
      resetLockVaultTendersState,
      transactionStates.lockVaultTenders,
      localStorage,
      offerLockerAddress,
      offerLockerContract,
      vaultContractAddress,
    ]
  )

  const deleteVaultTenders = useCallback(
    async (ids: TenderId[]) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || auction === undefined) {
        return
      }

      if (!yearnVaultContract || !signer || !vaultContractAddress) {
        return
      }

      // Remove from localstorage
      ids.forEach((id) => localStorage.removeItem(`loan-${id}`))

      const data = yearnVaultContract?.interface.encodeFunctionData(
        'deleteAuctionOffers',
        [auction, ids]
      )

      await deleteVaultTendersTransaction({
        chainId,
        to: vaultContractAddress,
        from: await signer.getAddress(),
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deleteVaultTenders,
        resetDeleteVaultTendersState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      validateActiveNetwork,
      account,
      auction,
      yearnVaultContract,
      signer,
      deleteVaultTendersTransaction,
      chainId,
      resetDeleteVaultTendersState,
      readFromSubgraph,
      localStorage,
      transactionStates.deleteVaultTenders,
      vaultContractAddress,
    ]
  )

  return [
    result,
    readFromLocalStorage,
    offerLockerContract ? deleteTenders : undefined,
    offerLockerContract ? lockTenders : undefined,
    yearnVaultContract ? lockVaultTenders : undefined,
    yearnVaultContract ? deleteVaultTenders : undefined,
  ]
}
