import { Button, ButtonProps, Spinner } from '@chakra-ui/react'

import { Trans } from '@lingui/macro'

export default function CancelRolloverButton({
  isSubmitting,
  fontSize = 'sm',
  size = 'sm',
  variant = 'error',
  w = 'full',
  ...props
}: { isSubmitting: boolean } & ButtonProps) {
  return (
    <Button fontSize={fontSize} size={size} variant={variant} w={w} {...props}>
      {isSubmitting && (
        <>
          <Spinner size="sm" mr="2" />{' '}
        </>
      )}
      <Trans>Cancel rollover</Trans>
    </Button>
  )
}
