import { Box, HStack, Link, Text } from '@chakra-ui/react'

export default function BlueSheetsAnnouncementBanner() {
  return (
    <Box as="section" bg="blue.5" py={2} overflow="hidden">
      <HStack columnGap={1} w="max-content" margin="0 auto">
        <Text as="span" variant="body-md/medium" color="white">
          Blue Sheets has launched.
        </Text>
        <Link href={'/earn'}>
          <Text as="u" variant="body-md/semibold" color="white">
            Participate now.
          </Text>
        </Link>
      </HStack>
    </Box>
  )
}
