import { Button, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'

export default function EditingButtons({
  selectedRows,
  isLoading,
  saveable,
  onCancel,
  onRevert,
  onSave,
}: {
  selectedRows: string[]
  isLoading?: boolean
  saveable: boolean
  onCancel: () => void
  onRevert: () => void
  onSave: () => void
}) {
  const numRows = selectedRows.length
  return (
    <HStack spacing={2}>
      <Text color="gray.5" variant="body-xs/normal">
        Editing {numRows} tender{numRows > 1 ? 's' : ''}
      </Text>
      <Button variant="link" onClick={onCancel} isLoading={isLoading} px={0}>
        <Text as="span" variant="body-xs/semibold" color="blue.5">
          Cancel {numRows > 1 ? 'all' : ''}
        </Text>
      </Button>
      <Button variant="link" onClick={onRevert} isLoading={isLoading} px={0}>
        <Text as="span" variant="body-xs/semibold" color="blue.5">
          Revert {numRows > 1 ? 'all' : ''}
        </Text>
      </Button>
      <Button
        variant="secondary"
        size="xs"
        onClick={onSave}
        isDisabled={(selectedRows?.length ?? 0) < 1 || !saveable}
        isLoading={isLoading}
      >
        Save {numRows > 1 ? 'all' : ''}
      </Button>
    </HStack>
  )
}
