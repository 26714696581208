import {
  Box,
  Divider,
  // TagProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import { HStack, Stack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import { ReactNode } from 'react'
import StatsBeforeAfter from '../../../../components/elements/StatsBeforeAfter'
import { Currency } from '../../../../data/model'
import {
  formatFixedToken,
  formatFixedUsd,
  getDisplayToken,
} from '../../../../helpers/utils'
import { calculateExcess } from '../../utils'
import { isEffectivelyZero } from '../../../../helpers/utils'
import { t } from '@lingui/macro'
import Tooltip from '../../../../components/elements/Tooltip'

export default function ManageMarginSummary({
  loanAmountUsd,
  currency,
  balance,
  tokenPrice,
  requiredMarginRatio,
  transaction,
  auctionLabel,
}: {
  loanAmountUsd: FixedNumber
  currency: Currency
  balance: FixedNumber
  tokenPrice: FixedNumber
  requiredMarginRatio: FixedNumber
  transaction: FixedNumber
  auctionLabel: string
}) {
  const {
    balanceUsd,
    // balanceRatio,
    requiredUsd,
    required,
    excessUsd,
    excess,
    balanceUsdAfter,
    balanceAfter,
    // balanceRatioAfter,
    excessUsdAfter,
    excessAfter,
  } = calculateExcess(
    loanAmountUsd,
    balance,
    tokenPrice,
    requiredMarginRatio,
    transaction
  )

  const getDollarValueColor = (value: FixedNumber) => {
    if (isEffectivelyZero(value)) {
      return 'blue.9'
    }
    return value.isNegative() ? 'red.5' : 'green.5'
  }

  // const requiredMarginRatioPercentage = requiredMarginRatio

  return (
    <Container>
      <SectionHeader title={t`Your Collateral Balance`} value={auctionLabel} />
      <Section name={t`Deposited Collateral`}>
        <Box>
          {transaction.isZero() ? (
            <DollarValue>{formatFixedUsd(balanceUsd)}</DollarValue>
          ) : (
            <StatsBeforeAfter
              maxW="240px"
              before={formatFixedUsd(balanceUsd, false, true)}
              after={
                <Text
                  as="span"
                  color={excessAfter.isNegative() ? 'red.5' : 'blue.9'}
                >
                  {formatFixedUsd(balanceUsdAfter, false, true)}
                </Text>
              }
            />
          )}
        </Box>
        <Box>
          {transaction.isZero() ? (
            <TokenValue symbol={currency.symbol}>
              {formatFixedToken(balance, currency.symbol, true)}
            </TokenValue>
          ) : (
            <StatsBeforeAfter
              maxW="240px"
              before={formatFixedToken(balance, currency.symbol, true, true)}
              after={
                <Text
                  as="span"
                  variant="body-sm/normal"
                  color={excessAfter.isNegative() ? 'red.5' : 'blue.9'}
                >
                  {formatFixedToken(balanceAfter, currency.symbol, false, true)}
                </Text>
              }
            />
          )}
        </Box>
      </Section>
      <Section name={t`Required Collateral`}>
        <Box>
          <DollarValue>{formatFixedUsd(requiredUsd)}</DollarValue>
        </Box>
        <Box>
          <TokenValue symbol={currency.symbol}>
            {formatFixedToken(required, currency.symbol, true)}
          </TokenValue>
        </Box>
      </Section>
      <Box w="full" px={4}>
        <Divider borderColor="gray.2" />
      </Box>
      <Section name="Excess Collateral">
        <Box>
          {transaction.isZero() ? (
            <DollarValue color={getDollarValueColor(excessUsdAfter)}>
              {formatFixedUsd(excessUsd)}
            </DollarValue>
          ) : (
            <StatsBeforeAfter
              before={formatFixedUsd(excessUsd, false, true)}
              after={
                <DollarValue textColor={getDollarValueColor(excessUsdAfter)}>
                  {formatFixedUsd(excessUsdAfter, false, true)}
                </DollarValue>
              }
            />
          )}
        </Box>
        <Box>
          {transaction.isZero() ? (
            <TokenValue symbol={currency.symbol}>
              {formatFixedToken(excess, currency.symbol, true)}
            </TokenValue>
          ) : (
            <StatsBeforeAfter
              before={
                <TokenValue color="gray.4">
                  {formatFixedToken(excess, currency.symbol, true, true)}
                </TokenValue>
              }
              after={
                <TokenValue
                  symbol={currency.symbol}
                  variant="body-sm/normal"
                  color={excessAfter.isNegative() ? 'red.5' : 'blue.9'}
                >
                  {formatFixedToken(excessAfter, currency.symbol, true, true)}
                </TokenValue>
              }
            />
          )}
        </Box>
      </Section>
    </Container>
  )
}

function Container({ children }: { children: ReactNode }) {
  return (
    <Stack
      bgColor="blue.0"
      borderColor="gray.2"
      borderWidth={1}
      borderRadius="md"
      spacing={4}
      fontSize="sm"
      lineHeight="5"
      pb={4}
    >
      {children}
    </Stack>
  )
}

function SectionHeader({ title, value }: { title: string; value: string }) {
  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      borderBottomWidth="1px"
      border="gray.2"
      px={4}
      py={2}
    >
      <Text as="span" variant="body-sm/semibold" color="gray.6">
        {title}
      </Text>
      <Box px={3} py={0.5} borderRadius="md" bg="gray.2">
        <Text as="span" variant="body-xs/medium" color="gray.6">
          {value}
        </Text>
      </Box>
    </HStack>
  )
}

function Section({ name, children }: { name: string; children: ReactNode }) {
  return (
    <HStack alignItems="start" justifyContent="space-between" px={4}>
      <Box>
        <Text as="span" variant="body-sm/medium" color="gray.6">
          {name}
        </Text>
      </Box>
      <Stack spacing={1} textAlign="end">
        {children}
      </Stack>
    </HStack>
  )
}

function TokenValue({
  symbol,
  children,
  ...props
}: { symbol?: string } & TextProps) {
  const formattedSymbol = symbol && getDisplayToken(symbol)
  return (
    <Tooltip
      noDelay
      leftAlign
      label={
        formattedSymbol && formattedSymbol.isTruncated ? symbol : undefined
      }
      placement="bottom"
    >
      <Text as="span" color="blue.9" {...props}>
        {children}
        {formattedSymbol && ` ${formattedSymbol.displayToken}`}
      </Text>
    </Tooltip>
  )
}

function DollarValue(props: TextProps) {
  return <Text as="span" variant="body-sm/bold" color="blue.9" {...props} />
}

// function RatioValue(props: TagProps) {
//   return <Text variant="body-sm/normal" {...props} />
// }
