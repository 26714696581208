import TabGroup from '../../../../components/elements/TabGroup'
import { t } from '@lingui/macro'

export default function TabButtons({
  isDisabled,
  value,
  onChange,
}: {
  isDisabled?: boolean
  value: 'loan' | 'borrow'
  onChange: (value: 'loan' | 'borrow') => void
}) {
  return (
    <TabGroup
      left={{ label: t`Offer to Supply`, value: 'loan' }}
      right={{ label: t`Bid to Borrow`, value: 'borrow' }}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  )
}
