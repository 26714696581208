import { StackProps } from '@chakra-ui/react'
import { Stack } from '../../../../components/elements/Stack'

import { ReactNode } from 'react'

export default function SummaryContainer({
  w,
  children,
}: {
  w?: StackProps['w']
  children: ReactNode
}) {
  return (
    <Stack
      background="blue.0"
      w={w ?? '380px'}
      // borderWidth="1px"
      // borderColor="gray.2"
      borderRadius="md"
      spacing={4}
    >
      {children}
    </Stack>
  )
}
