import { Box, StackProps } from '@chakra-ui/react'
import { HStack } from '../Stack'
import TermToastTokenIcon from '../TermToastTokenIcon'

interface TokenIconMultipleProps {
  symbols: string[]
  boxSize?: number
}

export default function TokenIconMultiple({
  symbols,
  boxSize = 24,
  ...props
}: TokenIconMultipleProps & StackProps) {
  const dynamicWidth = `${boxSize + (symbols.length - 1) * (boxSize * 0.75)}px`
  return (
    <HStack
      w="100%"
      h={`${boxSize}px`}
      display="inline-block"
      {...props}
      align="start"
    >
      <Box
        boxSize={`${symbols.length * boxSize}px`}
        width={dynamicWidth}
        bg="transparent"
        position="relative"
        zIndex={0}
      >
        {symbols
          .slice()
          .reverse()
          .map((symbol, index) => (
            <TermToastTokenIcon
              key={symbol}
              symbol={symbol}
              position="absolute"
              left={`${index * (boxSize * 0.75)}px`}
              zIndex={index + 1}
              boxSize={`${boxSize}px`}
            />
          ))}
      </Box>
    </HStack>
  )
}
