import { useDisclosure } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { BigNumber, FixedNumber, constants } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { fromPairs, keys, mapValues } from 'lodash'
import { useCallback, useReducer, useState } from 'react'
import { produce } from 'immer'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages, BidOrOffer } from '../../../../helpers/toasts'
import {
  Auction,
  Currency,
  DraftBorrowEdit,
  DraftLoanEdit,
  NativeCurrency,
  ParsedBorrowTender,
  ParsedLoanTender,
  SubmittedBorrowTender,
  SubmittedLoanTender,
  TenderId,
  ValidatedBorrowTender,
  ValidatedLoanTender,
} from '../../../../data/model'
import {
  TenderDelete,
  TenderEdit,
  diffBorrowEdit,
  diffLoanEdit,
  parseBorrowEdit,
  parseLoanEdit,
  EditErrors,
  BorrowEditErrors,
} from '../../../../data/parse'
import {
  bigToFixedNumber,
  fixedToBigNumber,
} from '../../../../helpers/conversions'
import BorrowTendersTable from '../TendersTable/BorrowTendersTable'
import LoanTendersTable from '../TendersTable/LoanTendersTable'
import LoanTendersTableLoading from '../TendersTable/LoanTendersTableLoading'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import ConfirmEditModal from './ConfirmEditModal'
import Container from './Container'
import Controls from './Controls'
import EditingButtons from './EditingButtons'
import LoadInterestRateModal from './LoadInterestRateModal'
import SelectingButtons from './SelectingButtons'
import TabButtons from './TabButtons'
import { useChainConfig } from '../../../../providers/config'

type AuctionSubset = Pick<
  Auction,
  | 'chainId'
  | 'purchaseCurrencyOraclePriceUSDC'
  | 'purchaseCurrencyOraclePriceDecimals'
  | 'collateralCurrencyOraclePriceUSDC'
  | 'collateralCurrencyOraclePriceDecimals'
  | 'initialMarginRatio'
  | 'minBidAmount'
  | 'minOfferAmount'
  | 'maxBidPrice'
  | 'maxOfferPrice'
>

export default function OpenTenders({
  isDataLoading,
  isLoadingMissingRates,
  auction,
  loanTenders,
  borrowTenders,
  purchaseTokenBalance,
  purchaseCurrency,
  collateralTokenBalance,
  collateralCurrency,
  gasTokenCurrency,
  gasTokenBalance,
  onDelete,
  onEditLoanTenders,
  onEditBorrowTenders,
  purchaseTokenAllowance,
  onApprovePurchaseToken,
  collateralTokenAllowance,
  onApproveCollateralToken,
  onKytCheck,
  onLoadMissingTenderRates,
  onWrapGasTokenCall,
  onUnwrapGasTokenCall,
}: {
  isDataLoading?: boolean
  isLoadingMissingRates: boolean
  auction: AuctionSubset
  loanTenders: SubmittedLoanTender[]
  borrowTenders: SubmittedBorrowTender[]
  purchaseTokenBalance: BigNumber
  gasTokenCurrency: NativeCurrency
  gasTokenBalance: FixedNumber
  purchaseCurrency: Currency
  collateralTokenBalance: BigNumber
  collateralCurrency: Currency
  onDelete: (type: 'loan' | 'borrow', ids: TenderId[]) => Promise<void>
  onEditLoanTenders: (edits: ValidatedLoanTender[]) => Promise<void>
  onEditBorrowTenders: (edits: ValidatedBorrowTender[]) => Promise<void>
  purchaseTokenAllowance: BigNumber
  onApprovePurchaseToken: () => Promise<void>
  collateralTokenAllowance: BigNumber
  onApproveCollateralToken: () => Promise<void>
  onKytCheck: () => Promise<boolean>
  onLoadMissingTenderRates: (
    bids: string[] | undefined,
    offers: string[] | undefined
  ) => Promise<void>
  onWrapGasTokenCall: (value: BigNumber) => Promise<void>
  onUnwrapGasTokenCall: (value: BigNumber) => Promise<void>
}) {
  const {
    isOpen: isConfirmEditOpen,
    onOpen: onConfirmEditOpen,
    onClose: onConfirmEditClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure()
  const {
    isOpen: isLoadInterestRateOpen,
    onClose: onLoadInterestRateClose,
    onOpen: onOpenLoadInterestRate,
  } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { tab, mode } = state
  const loanTendersMap = fromPairs(
    loanTenders.map((tender) => [tender.id, tender])
  )
  const borrowTendersMap = fromPairs(
    borrowTenders.map((tender) => [tender.id, tender])
  )
  const [tenderEdits, setTenderEdits] = useState<TenderEdit[]>([])
  const [tenderDeletes, setTenderDeletes] = useState<TenderDelete[]>([])

  const termToast = useTermToast()

  const chainConfig = useChainConfig(auction.chainId)

  const editTenders = useCallback(async () => {
    if (mode !== 'editing') {
      return
    }
    switch (state.tab) {
      case 'loan': {
        const parsedEdits = Object.entries(state.edits).map(
          ([tenderId, edit]) =>
            [
              tenderId,
              parseLoanEdit(
                edit,
                loanTendersMap[tenderId],
                purchaseCurrency.decimals,
                auction.minOfferAmount,
                purchaseTokenBalance,
                FixedNumber.fromString('0', 18),
                FixedNumber.fromValue(auction.maxOfferPrice, 18)
              ),
            ] as [TenderId, ParsedLoanTender]
        )
        const validatedTenders: ValidatedLoanTender[] = parsedEdits.map(
          ([id, parsed]) => ({
            type: 'loan',
            id,
            amount: {
              currency: purchaseCurrency.address,
              shiftedValue: parsed.amount.value,
            },
            interestRate: parsed.interestRate.value,
          })
        )
        await onEditLoanTenders(validatedTenders)
        break
      }
      case 'borrow': {
        const parsedEdits = Object.entries(state.edits).map(
          ([tenderId, edit]) =>
            [
              tenderId,
              parseBorrowEdit(
                edit,
                borrowTendersMap[tenderId],
                purchaseCurrency.decimals,
                collateralCurrency.decimals,
                auction.minBidAmount,
                constants.MaxUint256.div(32),
                FixedNumber.fromString('0', 18),
                FixedNumber.fromValue(auction.maxBidPrice, 18)
              ),
            ] as [TenderId, ParsedBorrowTender]
        )
        const validatedTenders: ValidatedBorrowTender[] = parsedEdits.map(
          ([id, parsed]) => ({
            type: 'borrow',
            id,
            amount: {
              currency: purchaseCurrency.address,
              shiftedValue: parsed.amount.value,
            },
            interestRate: parsed.interestRate.value,
            collateral: {
              currency: collateralCurrency.address,
              shiftedValue: parsed.collateral.value,
            },
          })
        )
        await onEditBorrowTenders(validatedTenders)
        break
      }
    }
  }, [
    auction.maxBidPrice,
    auction.maxOfferPrice,
    auction.minBidAmount,
    auction.minOfferAmount,
    borrowTendersMap,
    collateralCurrency.address,
    collateralCurrency.decimals,
    loanTendersMap,
    mode,
    onEditBorrowTenders,
    onEditLoanTenders,
    purchaseCurrency.address,
    purchaseCurrency.decimals,
    purchaseTokenBalance,
    state,
  ])

  const notifyEditStart = useCallback(
    (tenderKind: BidOrOffer) => {
      return termToast.pending(
        termToastMessages.editBidOrOffer.pending(tenderKind, tenderEdits.length)
      )
    },
    [tenderEdits.length, termToast]
  )
  const notifyEditSuccess = useCallback(
    (tenderKind: BidOrOffer) => {
      return termToast.success(
        termToastMessages.editBidOrOffer.success(tenderKind, tenderEdits.length)
      )
    },
    [tenderEdits.length, termToast]
  )
  const notifyEditError = useCallback(
    (tenderKind: BidOrOffer) => {
      return termToast.failure(
        termToastMessages.editBidOrOffer.failure(tenderKind, tenderEdits.length)
      )
    },
    [tenderEdits.length, termToast]
  )
  const notifyDeleteStart = useCallback(
    (tenderKind: BidOrOffer) => {
      return termToast.pending(
        termToastMessages.deleteBidOrOffer.pending(
          tenderKind,
          tenderDeletes.length
        )
      )
    },
    [tenderDeletes.length, termToast]
  )

  const notifyDeleteSuccess = useCallback(
    (tenderKind: BidOrOffer) => {
      // keep functionality as state.mode === 'selecting' && state.selection.length > 1
      return termToast.success(
        termToastMessages.deleteBidOrOffer.success(
          tenderKind,
          state.mode === 'selecting' ? state.selection.length : 0
        )
      )
    },
    [state, termToast]
  )

  const notifyDeleteError = useCallback(
    (tenderKind: BidOrOffer) => {
      termToast.failure(
        termToastMessages.deleteBidOrOffer.failure(
          tenderKind,
          tenderDeletes.length
        )
      )
    },
    [tenderDeletes.length, termToast]
  )

  const purchaseTokenPrice = bigToFixedNumber(
    auction.purchaseCurrencyOraclePriceUSDC,
    auction.purchaseCurrencyOraclePriceDecimals
  )
  const collateralTokenPrice = bigToFixedNumber(
    auction.collateralCurrencyOraclePriceUSDC,
    auction.collateralCurrencyOraclePriceDecimals
  )

  return (
    <>
      <Controls>
        <TabButtons
          isDataLoading={!!isDataLoading}
          numLoans={loanTenders.length}
          numBorrows={borrowTenders.length}
          value={tab}
          onChange={(tab) => dispatch({ name: 'open-tab', tab })}
        />
        {mode === 'selecting' && (
          <SelectingButtons
            selectedRows={state.selection}
            onDelete={() => {
              const tenderDeletes =
                state.tab === 'loan'
                  ? state.selection.map(
                      (tenderId) =>
                        ({
                          id: tenderId,
                          transaction: loanTendersMap[tenderId]?.transaction,
                          oldAmount: loanTendersMap[tenderId]?.amount
                            ?.shiftedValue
                            ? bigToFixedNumber(
                                loanTendersMap[tenderId]?.amount?.shiftedValue,
                                purchaseCurrency.decimals
                              )
                            : undefined,
                          symbol: purchaseCurrency.symbol,
                        }) as TenderDelete
                    )
                  : state.selection.map(
                      (tenderId) =>
                        ({
                          id: tenderId,
                          transaction: borrowTendersMap[tenderId]?.transaction,
                          oldAmount: borrowTendersMap[tenderId]?.amount
                            ?.shiftedValue
                            ? bigToFixedNumber(
                                borrowTendersMap[tenderId]?.amount
                                  ?.shiftedValue,
                                purchaseCurrency.decimals
                              )
                            : undefined,
                          symbol: purchaseCurrency.symbol,
                        }) as TenderDelete
                    )
              setTenderDeletes(tenderDeletes)
              onConfirmDeleteOpen()
            }}
            onEdit={() =>
              dispatch({
                name: 'start-editing',
                tab: state.tab,
                tenders: state.tab === 'loan' ? loanTenders : borrowTenders,
                purchaseCurrency,
                purchaseTokenBalance,
                collateralCurrency,
                auction,
                purchaseTokenPrice,
                collateralTokenPrice,
              })
            }
          />
        )}
        {mode === 'editing' && (
          <EditingButtons
            isLoading={isLoading}
            saveable={state.saveable}
            selectedRows={keys(state.edits)}
            onCancel={() => dispatch({ name: 'cancel-editing' })}
            onRevert={() =>
              dispatch(
                state.tab === 'loan'
                  ? {
                      name: 'revert-loan-edits',
                      tenders: loanTenders,
                      purchaseCurrency,
                    }
                  : {
                      name: 'revert-borrow-edits',
                      tenders: borrowTenders,
                      purchaseCurrency,
                      collateralCurrency,
                    }
              )
            }
            onSave={() => {
              const tenderEdits =
                state.tab === 'loan'
                  ? Object.entries(state.edits).map(([tenderId, edit]) => ({
                      id: tenderId,
                      transaction: loanTendersMap[tenderId]?.transaction,
                      differences: diffLoanEdit(
                        edit,
                        loanTendersMap[tenderId],
                        purchaseCurrency.decimals,
                        auction.minOfferAmount,
                        purchaseTokenBalance,
                        FixedNumber.fromString('0', 18),
                        bigToFixedNumber(auction.maxOfferPrice, 18),
                        purchaseCurrency.symbol
                      ).differences,
                    }))
                  : Object.entries(state.edits).map(([tenderId, edit]) => ({
                      id: tenderId,
                      transaction: borrowTendersMap[tenderId]?.transaction,
                      differences: diffBorrowEdit(
                        edit,
                        borrowTendersMap[tenderId],
                        purchaseCurrency.decimals,
                        collateralCurrency.decimals,
                        auction.minOfferAmount,
                        constants.MaxUint256.div(32),
                        FixedNumber.fromString('0', 18),
                        bigToFixedNumber(auction.maxOfferPrice, 18),
                        purchaseCurrency.symbol,
                        collateralCurrency.symbol,
                        purchaseTokenPrice,
                        collateralTokenPrice,
                        auction.initialMarginRatio
                      ).differences,
                    }))
              setTenderEdits(tenderEdits)
              onConfirmEditOpen()
            }}
          />
        )}
      </Controls>
      <Container>
        {isDataLoading && <LoanTendersTableLoading />}
        {!isDataLoading && tab === 'loan' && (
          <LoanTendersTable
            editing={
              mode === 'editing'
                ? {
                    edits: state.edits,
                    onChange: (id, edit) =>
                      dispatch({
                        name: 'update-tender-edit',
                        tab: state.tab,
                        id,
                        with: edit,
                        auction,
                        collateralCurrency,
                        purchaseTokenBalance,
                        purchaseCurrency,
                        tender: loanTendersMap[id],
                      }),
                  }
                : false
            }
            rows={loanTenders}
            getBlockExplorerTransactionUrl={
              chainConfig?.getExplorerTransactionLink
            }
            purchaseCurrency={purchaseCurrency}
            selectedRows={
              mode === 'editing' ? keys(state.edits) : state.selection
            }
            setSelectedRows={(selection) =>
              dispatch({ name: 'update-selection', with: selection })
            }
            onOpenLoadInterestRate={onOpenLoadInterestRate}
            isLoadingMissingRates={isLoadingMissingRates}
          />
        )}

        {!isDataLoading && tab === 'borrow' && (
          <BorrowTendersTable
            editing={
              mode === 'editing'
                ? {
                    edits: state.edits,
                    onChange: (id, edit) =>
                      dispatch({
                        name: 'update-tender-edit',
                        tab: state.tab,
                        id,
                        with: edit,
                        auction,
                        collateralCurrency,
                        purchaseCurrency,
                        collateralTokenPrice,
                        purchaseTokenPrice,
                        collateralTokenBalance,
                        tender: borrowTendersMap[id],
                      }),
                  }
                : false
            }
            rows={borrowTenders}
            getBlockExplorerTransactionUrl={
              chainConfig?.getExplorerTransactionLink
            }
            purchaseCurrency={purchaseCurrency}
            purchaseTokenPrice={purchaseTokenPrice}
            collateralCurrency={collateralCurrency}
            collateralTokenPrice={collateralTokenPrice}
            initialMarginRatio={auction.initialMarginRatio}
            selectedRows={
              mode === 'editing' ? keys(state.edits) : state.selection
            }
            setSelectedRows={(selection) =>
              dispatch({ name: 'update-selection', with: selection })
            }
            onOpenLoadInterestRate={onOpenLoadInterestRate}
            isLoadingMissingRates={isLoadingMissingRates}
          />
        )}
      </Container>
      <LoadInterestRateModal
        isOpen={isLoadInterestRateOpen}
        onClose={onLoadInterestRateClose}
        // chainId={auction.chainId}
        onLoadRates={async () => {
          const bidIds = borrowTenders
            .filter((tender) => !tender.interestRate)
            .map((tender) => tender.id)
          const offerIds = loanTenders
            .filter((tender) => !tender.interestRate)
            .map((tender) => tender.id)
          onLoadMissingTenderRates(bidIds, offerIds)
          onLoadInterestRateClose()
        }}
      />
      <ConfirmEditModal
        purchaseCurrency={purchaseCurrency}
        purchaseTokenAllowance={purchaseTokenAllowance}
        onApprovePurchaseToken={onApprovePurchaseToken}
        collateralCurrency={collateralCurrency}
        collateralTokenAllowance={collateralTokenAllowance}
        onApproveCollateralToken={onApproveCollateralToken}
        onKytCheck={onKytCheck}
        tenderEdits={tenderEdits}
        tenderKind={state.tab === 'loan' ? 'offer' : 'bid'}
        isOpen={isConfirmEditOpen}
        onClose={onConfirmEditClose}
        getBlockExplorerTransactionUrl={chainConfig?.getExplorerTransactionLink}
        onConfirm={async () => {
          setIsLoading(true)
          try {
            notifyEditStart(state.tab === 'loan' ? 'offer' : 'bid')
            await editTenders()
            dispatch({ name: 'save-success' })
            notifyEditSuccess(state.tab === 'loan' ? 'offer' : 'bid')
            onConfirmEditClose()
          } catch (err) {
            console.log(err)
            dispatch({ name: 'save-error' })
            captureException(err)
            notifyEditError(state.tab === 'loan' ? 'offer' : 'bid')
          } finally {
            setIsLoading(false)
          }
        }}
      />
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteOpen}
        onClose={onConfirmDeleteClose}
        onConfirm={async () => {
          if (state.mode !== 'selecting') {
            console.error('Cannot delete when not in selecting mode')
            return
          }
          setIsLoading(true)
          try {
            notifyDeleteStart(state.tab === 'loan' ? 'offer' : 'bid')
            await onDelete(state.tab, state.selection)
            dispatch({ name: 'save-success' })
            notifyDeleteSuccess(state.tab === 'loan' ? 'offer' : 'bid')
            onConfirmDeleteClose()
          } catch (err) {
            console.log(err)
            dispatch({ name: 'save-error' })
            captureException(err)
            notifyDeleteError(state.tab === 'loan' ? 'offer' : 'bid')
          } finally {
            setIsLoading(false)
          }
        }}
        deletes={tenderDeletes}
        tenderKind={state.tab === 'loan' ? 'offer' : 'bid'}
        getBlockExplorerTransactionUrl={chainConfig?.getExplorerTransactionLink}
      />
    </>
  )
}

type State = SelectingLoans | SelectingBorrows | EditingLoans | EditingBorrows
interface SelectingLoans {
  tab: 'loan'
  mode: 'selecting'
  selection: TenderId[]
}
interface SelectingBorrows {
  tab: 'borrow'
  mode: 'selecting'
  selection: TenderId[]
}
interface EditingLoans {
  tab: 'loan'
  mode: 'editing'
  edits: Record<TenderId, DraftLoanEdit>
  parsedEdits: Record<TenderId, TenderEdit>
  saveable: boolean
}
interface EditingBorrows {
  tab: 'borrow'
  mode: 'editing'
  edits: Record<TenderId, DraftBorrowEdit>
  parsedEdits: Record<TenderId, TenderEdit>
  saveable: boolean
}

const initialState: State = { tab: 'loan', mode: 'selecting', selection: [] }

type Action<
  T extends ['loan', SubmittedLoanTender] | ['borrow', SubmittedBorrowTender],
> =
  | OpenTab
  | UpdateSelection
  | StartEditing<T>
  | UpdateLoanTenderEdit
  | UpdateBorrowTenderEdit
  | RevertEdits
  | CancelEditing
  | SaveStart
  | SaveSuccess
  | SaveError
interface OpenTab {
  name: 'open-tab'
  tab: 'loan' | 'borrow'
}
interface UpdateSelection {
  name: 'update-selection'
  with: TenderId[]
}
interface StartEditing<
  T extends ['loan', SubmittedLoanTender] | ['borrow', SubmittedBorrowTender],
> {
  name: 'start-editing'
  tab: T[0]
  tenders: T[1][]
  auction: AuctionSubset
  purchaseCurrency: Currency
  purchaseTokenBalance: BigNumber
  collateralCurrency: Currency
  purchaseTokenPrice: FixedNumber
  collateralTokenPrice: FixedNumber
}
interface UpdateLoanTenderEdit {
  name: 'update-tender-edit'
  id: TenderId
  tab: 'loan'
  tender: SubmittedLoanTender
  with: DraftLoanEdit
  auction: AuctionSubset
  purchaseCurrency: Currency
  purchaseTokenBalance: BigNumber
  collateralCurrency: Currency
}
interface UpdateBorrowTenderEdit {
  name: 'update-tender-edit'
  id: TenderId
  tab: 'borrow'
  tender: SubmittedBorrowTender
  with: DraftBorrowEdit
  auction: AuctionSubset
  purchaseCurrency: Currency
  collateralCurrency: Currency
  purchaseTokenPrice: FixedNumber
  collateralTokenPrice: FixedNumber
  collateralTokenBalance: BigNumber
}
type RevertEdits = RevertLoanEdits | RevertBorrowEdits
interface RevertLoanEdits {
  name: 'revert-loan-edits'
  tenders: SubmittedLoanTender[]
  purchaseCurrency: Currency
}
interface RevertBorrowEdits {
  name: 'revert-borrow-edits'
  tenders: SubmittedBorrowTender[]
  purchaseCurrency: Currency
  collateralCurrency: Currency
}
interface CancelEditing {
  name: 'cancel-editing'
}
interface SaveStart {
  name: 'save-start'
}
interface SaveSuccess {
  name: 'save-success'
}
interface SaveError {
  name: 'save-error'
}

function reducer<
  T extends ['loan', SubmittedLoanTender] | ['borrow', SubmittedBorrowTender],
>(state: State, action: Action<T>): State {
  switch (action.name) {
    case 'open-tab': {
      return {
        tab: action.tab,
        mode: 'selecting',
        selection: [],
      }
    }
    case 'update-selection': {
      if (state.mode !== 'selecting') {
        throw new Error('Cannot update selection while editing')
      }
      return {
        ...state,
        selection: action.with,
      }
    }
    case 'start-editing': {
      if (state.mode !== 'selecting') {
        throw new Error('Can only start editing from selecting mode')
      }
      switch (state.tab) {
        case 'loan': {
          if (action.tab !== 'loan') {
            throw new Error('Can only start editing loans from loan tab')
          }
          const tendersById = fromPairs(
            action.tenders.map((t) => [t.id, t])
          ) as Record<TenderId, SubmittedLoanTender>
          const edits = fromPairs(
            state.selection.map((id) => {
              const tender = tendersById[id]
              if (tender === undefined) {
                throw new Error('Editing a tender that was not found')
              }
              return [
                id,
                {
                  amount: formatUnits(
                    tender.amount.shiftedValue,
                    action.purchaseCurrency.decimals
                  ),
                  interestRate: tender.interestRate?.toString() ?? '',
                },
              ]
            })
          )
          const parsedEdits = fromPairs(
            Object.entries(edits).map(([id, edit]) => {
              const tender = tendersById[id]
              if (tender === undefined) {
                throw new Error('Editing a tender that was not found')
              }
              return [
                id,
                {
                  id,
                  transaction: tender.transaction,
                  differences: diffLoanEdit(
                    edit,
                    tender,
                    action.purchaseCurrency.decimals,
                    action.auction.minOfferAmount,
                    action.purchaseTokenBalance,
                    FixedNumber.fromString('0'),
                    bigToFixedNumber(action.auction.maxOfferPrice, 18),
                    action.purchaseCurrency.symbol
                  ).differences,
                },
              ]
            })
          )
          return {
            ...state,
            mode: 'editing',
            edits,
            parsedEdits,
            saveable: false,
          }
        }
        case 'borrow': {
          if (action.tab !== 'borrow') {
            throw new Error('Can only start editing borrows from borrow tab')
          }
          const tendersById = fromPairs(
            action.tenders.map((t) => [t.id, t])
          ) as Record<TenderId, SubmittedBorrowTender>
          const edits = fromPairs(
            state.selection.map((id) => {
              const tender = tendersById[id]
              if (tender === undefined) {
                throw new Error('Editing a tender that was not found')
              }
              return [
                id,
                {
                  amount: formatUnits(
                    tender.amount.shiftedValue,
                    action.purchaseCurrency.decimals
                  ),
                  collateral: formatUnits(
                    tender.collateral.shiftedValue,
                    action.collateralCurrency.decimals
                  ),
                  interestRate: tender.interestRate?.toString() as string,
                },
              ]
            })
          )
          const parsedEdits = fromPairs(
            Object.entries(edits).map(([id, edit]) => {
              const tender = tendersById[id]
              if (tender === undefined) {
                throw new Error('Editing a tender that was not found')
              }

              return [
                id,
                {
                  id,
                  transaction: tender.transaction,
                  differences: diffBorrowEdit(
                    edit,
                    tender,
                    action.purchaseCurrency.decimals,
                    action.collateralCurrency.decimals,
                    action.auction.minBidAmount,
                    constants.MaxUint256.div(32),
                    FixedNumber.fromString('0'),
                    bigToFixedNumber(action.auction.maxBidPrice, 18),
                    action.purchaseCurrency.symbol,
                    action.collateralCurrency.symbol,
                    action.purchaseTokenPrice,
                    action.collateralTokenPrice,
                    action.auction.initialMarginRatio
                  ).differences,
                },
              ]
            })
          )
          return {
            ...state,
            mode: 'editing',
            edits,
            parsedEdits,
            saveable: false,
          }
        }
        default: {
          const exhaustiveCheck: never = state
          throw new Error(
            `Reducer not exhaustive for state: ${JSON.stringify(
              exhaustiveCheck
            )}`
          )
        }
      }
    }
    case 'update-tender-edit': {
      if (state.mode !== 'editing') {
        throw new Error('cannot update tender edit from a non-editing state')
      }
      switch (action.tab) {
        case 'loan': {
          if (state.tab !== 'loan') {
            throw new Error('can only edit loan from loan tab')
          }
          return produce(state, (draftState) => {
            const loanDecimals = action.purchaseCurrency.decimals
            const availableTokenBalance = (action as UpdateLoanTenderEdit)
              .purchaseTokenBalance
            const id = action.tender.id

            const { differences, errors } = diffLoanEdit(
              action.with,
              action.tender as SubmittedLoanTender,
              loanDecimals,
              action.auction.minOfferAmount,
              availableTokenBalance,
              FixedNumber.fromString('0'),
              bigToFixedNumber(action.auction.maxOfferPrice, 18),
              action.purchaseCurrency.symbol
            )

            draftState.parsedEdits[id] = {
              id,
              transaction: action.tender.transaction,
              differences,
              errors,
            } as TenderEdit

            draftState.edits[id] = {
              ...action.with,
              ...errors,
              globalAmountError: undefined,
            }

            // check global errors
            //   - find which tenders have changed
            //   - calculate the new supplied loan amount
            //   - check if this amount is greater than token balance
            // if errors are found, need to append them to the effected edits
            // only care about changed amounts there is no global interest rate errors
            let changedEdits: string[] = []
            let someTenderCollateralError: string | undefined = undefined
            Object.keys(draftState.parsedEdits).forEach((k) => {
              const e = draftState.parsedEdits[k]
              if (e?.differences.length) {
                changedEdits.push(k)
              }

              // record if there is some other tender throwing an INSUFFICIENT_FUNDS error
              if (
                draftState.edits[k]?.amountError &&
                (draftState.edits[k].amountError as string).includes(
                  EditErrors.INSUFFICIENT_FUNDS
                )
              ) {
                someTenderCollateralError = k
              }
            })

            const newSuppliedAmount = changedEdits.reduce((b, k) => {
              const draft = draftState.parsedEdits[k].differences.find(
                (e) => e.label === 'Amount'
              )
              if (!draft) {
                return b
              } else {
                return b.add(
                  fixedToBigNumber(draft.newValue).sub(
                    fixedToBigNumber(draft.oldValue)
                  )
                )
              }
            }, BigNumber.from('0'))

            let globalAmountError: string | undefined = undefined
            // check if the differences are valid if not set a globalAmountError
            if (newSuppliedAmount.gt(availableTokenBalance)) {
              globalAmountError = `${
                EditErrors.INSUFFICIENT_FUNDS
              } ${formatUnits(availableTokenBalance, loanDecimals)} available`
            }

            // if there is some other tender with an amount error we also want to set a globalAmountError for any changedEdits
            if (!!someTenderCollateralError) {
              globalAmountError =
                draftState.edits[someTenderCollateralError]?.amountError
            }

            // add global errors only to tenders which have changed
            changedEdits.forEach((k) => {
              draftState.edits[k].globalAmountError = globalAmountError
            })

            // valid save if there is no errors
            const errored: boolean = !!Object.values(draftState.edits).find(
              (e: DraftLoanEdit) => e?.amountError || e?.interestRateError
            )

            const hasMadeChanges: boolean = !!Object.values(
              draftState.parsedEdits
            ).find((e) => e?.differences?.length)

            draftState.tab = 'loan'
            draftState.mode = 'editing'
            draftState.saveable = !errored && hasMadeChanges
          })
        }
        case 'borrow': {
          if (state.tab !== 'borrow') {
            throw new Error('can only edit borrow from borrow tab')
          }
          return produce(state, (draftState) => {
            const { differences, errors } = diffBorrowEdit(
              action.with as DraftBorrowEdit,
              action.tender as SubmittedBorrowTender,
              action.purchaseCurrency.decimals,
              action.collateralCurrency.decimals,
              action.auction.minBidAmount,
              constants.MaxUint256.div(32), // TODO get max bid amount based on collateralisation ratio
              FixedNumber.fromString('0'),
              bigToFixedNumber(action.auction.maxBidPrice, 18),
              action.purchaseCurrency.symbol,
              action.collateralCurrency.symbol,
              (action as UpdateBorrowTenderEdit).purchaseTokenPrice,
              (action as UpdateBorrowTenderEdit).collateralTokenPrice,
              action.auction.initialMarginRatio
            )

            const id = action.tender.id

            draftState.parsedEdits[id] = {
              id,
              transaction: action.tender.transaction,
              differences,
              errors,
            }

            draftState.edits[id] = {
              ...action.with,
              ...errors,
              globalCollateralError: undefined,
            } as DraftBorrowEdit

            let changedEdits: string[] = []
            let someTenderCollateralError: string | undefined
            Object.keys(draftState.parsedEdits).forEach((k) => {
              const e = draftState.parsedEdits[k]
              if (e.differences.length) {
                changedEdits.push(k)
              }

              if (
                (e.errors as BorrowEditErrors)?.collateralError &&
                (
                  (e.errors as BorrowEditErrors).collateralError as string
                ).includes(EditErrors.INSUFFICIENT_FUNDS)
              ) {
                someTenderCollateralError = k
              }
            })

            const newSuppliedAmount = changedEdits.reduce((b, k) => {
              const draft = draftState.parsedEdits[k].differences.find(
                (e) => e.label === 'Collateral'
              )
              if (!draft) {
                return b
              } else {
                return b.add(
                  fixedToBigNumber(draft.newValue).sub(
                    fixedToBigNumber(draft.oldValue)
                  )
                )
              }
            }, BigNumber.from('0'))

            let globalCollateralError: string | undefined
            const availableTokenBalance = (action as UpdateBorrowTenderEdit)
              .collateralTokenBalance
            if (newSuppliedAmount.gt(availableTokenBalance)) {
              globalCollateralError = `${
                EditErrors.INSUFFICIENT_FUNDS
              } ${formatUnits(
                availableTokenBalance,
                action.collateralCurrency.decimals
              )} available`
            }

            if (!!someTenderCollateralError) {
              globalCollateralError =
                draftState.edits[someTenderCollateralError]?.collateralError
            }

            changedEdits.forEach((k) => {
              draftState.edits[k].globalCollateralError = globalCollateralError
            })

            // valid save if there is no errors
            const errored: boolean = !!Object.values(draftState.edits).find(
              (e: DraftBorrowEdit) =>
                e?.amountError || e?.interestRateError || e?.collateralError
            )

            const hasMadeChanges: boolean = !!Object.values(
              draftState.parsedEdits
            ).find((e) => e?.differences?.length)

            draftState.tab = 'borrow'
            draftState.mode = 'editing'
            draftState.saveable = !errored && hasMadeChanges
          })
        }
        default: {
          const exhaustiveCheck: never = action.tab
          throw new Error(`Non exhaustive: ${JSON.stringify(exhaustiveCheck)}`)
        }
      }
    }
    case 'cancel-editing': {
      if (state.mode !== 'editing') {
        throw new Error('cannot cancel editing from non-editing state')
      }
      return { ...state, mode: 'selecting', selection: keys(state.edits) }
    }
    case 'revert-loan-edits': {
      if (state.mode !== 'editing') {
        throw new Error('cannot revert editing from non-editing state')
      }
      return {
        tab: 'loan',
        mode: 'editing',
        edits: mapValues(state.edits, (edit, id): DraftLoanEdit => {
          const tender = action.tenders.find((t) => t.id === id)
          if (tender === undefined) {
            throw new Error('Editing a tender that was not found')
          }
          return {
            amount: formatUnits(
              tender.amount.shiftedValue,
              action.purchaseCurrency.decimals
            ),
            interestRate: tender?.interestRate?.toString() as string,
          }
        }),
        parsedEdits: mapValues(state.parsedEdits, (edit, id): TenderEdit => {
          const tender = action.tenders.find((t) => t.id === id)
          if (tender === undefined) {
            throw new Error('Editing a tender that was not found')
          }
          return {
            id,
            transaction: tender.transaction,
            differences: [],
          }
        }),
        saveable: false,
      }
    }
    case 'revert-borrow-edits': {
      if (state.mode !== 'editing') {
        throw new Error('cannot revert editing from non-editing state')
      }
      return {
        tab: 'borrow',
        mode: 'editing',
        edits: mapValues(state.edits, (edit, id): DraftBorrowEdit => {
          const tender = action.tenders.find((t) => t.id === id)
          if (tender === undefined) {
            throw new Error('Editing a borrow tender that was not found')
          }
          return {
            amount: formatUnits(
              tender.amount.shiftedValue,
              action.purchaseCurrency.decimals
            ),
            interestRate: tender.interestRate?.toString() as string,
            collateral: formatUnits(
              tender.collateral.shiftedValue,
              action.collateralCurrency.decimals
            ),
          }
        }),
        parsedEdits: mapValues(state.parsedEdits, (edit, id): TenderEdit => {
          const tender = action.tenders.find((t) => t.id === id)
          if (tender === undefined) {
            throw new Error('Editing a borrow tender that was not found')
          }
          return {
            id,
            transaction: tender.transaction,
            differences: [],
          }
        }),
        saveable: false,
      }
    }
    case 'save-start': {
      // TODO: Show the user that we're saving.
      return state
    }
    case 'save-success': {
      return {
        tab: state.tab,
        mode: 'selecting',
        selection: [],
      }
    }
    case 'save-error': {
      // TODO: Show error.
      return state
    }
  }
}
