import { ChainId } from '@usedapp/core'
import { Button, Text, Box } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import { useNavigate } from 'react-router-dom'
import {
  formatFixedUsd,
  getTextColorFromAmount,
} from '../../../../helpers/utils'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { Trans } from '@lingui/macro'

export default function OpenPositionsTile({
  netPositionUsd,
  openPositions,
  openPositionNetworks,
}: {
  netPositionUsd: FixedNumber
  openPositions: number
  openPositionNetworks: ChainId[]
}) {
  const fixedTokenNumber = formatFixedUsd(netPositionUsd, false, true)
  const [wholeAmount, decimals] = fixedTokenNumber.toString().split('.')
  const navigate = useNavigate()

  return (
    <>
      {openPositions > 0 ? (
        <Box minH="88px" display="flex" flexDirection="column" rowGap={3}>
          <Text variant="body-sm/normal" color="gray.6" whiteSpace="nowrap">
            <Trans>Net Position</Trans>
          </Text>
          <VStack alignItems="flex-start">
            <HStack
              alignItems="flex-end"
              color={getTextColorFromAmount(netPositionUsd, true)}
              spacing={0}
            >
              <Text
                as="div"
                fontSize={{ base: '20px', xl: '30px' }}
                lineHeight="1"
                variant="body-sm/medium"
              >
                {wholeAmount}
              </Text>
              <Text
                as="div"
                fontSize="20px"
                lineHeight="1"
                ml="0 !important"
                transform="translateY(-2px)"
                variant="body-sm/medium"
              >
                .
              </Text>
              <Text
                as="div"
                fontSize="14px"
                lineHeight="1"
                ml="2px !important"
                transform="translateY(-2px)"
                variant="body-sm/normal"
              >
                {decimals === '0' ? '00' : decimals}
              </Text>
            </HStack>
            <HStack spacing={1}>
              <Text as="span" variant="body-xs/normal" color="gray.4">
                <Trans>On</Trans>
              </Text>
              <HStack spacing={0}>
                {openPositionNetworks?.map((chainId) => (
                  <NetworkIcon key={chainId} chainId={chainId} />
                ))}
              </HStack>
            </HStack>
          </VStack>
        </Box>
      ) : (
        <VStack spacing={4} h="88px">
          <Text
            as="span"
            variant="body-sm/medium"
            color="gray.6"
            w="full"
            textAlign="center"
            whiteSpace="nowrap"
          >
            <Trans>You have no open positions.</Trans>
          </Text>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => navigate('/')}
            textDecoration="none"
          >
            <Trans>Browse auctions</Trans>
          </Button>
        </VStack>
      )}
    </>
  )
}
