import { JsonRpcProvider } from '@ethersproject/providers'
import { captureException } from '@sentry/react'
import { convertChainId } from './conversions'

export const watchAsset = async (
  chainId: string,
  provider: JsonRpcProvider,
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string = ''
): Promise<boolean> => {
  try {
    const parsedChainId = convertChainId(chainId)
    const watchAssetParam = {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        chainId: parsedChainId,
        // symbol: tokenSymbol,
        // decimals: tokenDecimals,
        // image: tokenImage,
      },
    }
    console.log(
      "Sending 'wallet_watchAsset' request to MetaMask",
      watchAssetParam
    )

    const watchedAsset = (window as any)?.ethereum?.request({
      method: 'wallet_watchAsset',
      params: watchAssetParam,
    })

    if (!watchedAsset) {
      return await provider.send('wallet_watchAsset', [watchAssetParam])
    }
    // else
    return watchedAsset
  } catch (err) {
    console.error(err)
    captureException(err)
  } finally {
    return false
  }
}
