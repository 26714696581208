import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from '../../../chakra-theme'

export default function ThemedIcon({
  icon,
  color,
  shade,
}: {
  icon: IconProp
  color: string
  shade?: string
}) {
  let iconColor: string = ''

  if (color.includes('#')) {
    iconColor = color
  }

  if (shade) {
    iconColor = theme.colors[color][shade]
  }

  return <FontAwesomeIcon icon={icon} color={iconColor} />
}
