import { Box } from '@chakra-ui/layout'

export default function TabelBorder({
  children,
  isDisabled,
}: {
  children: React.ReactNode
  isDisabled: boolean
}) {
  return (
    <Box
      bg="white"
      borderWidth="1px"
      borderColor="gray.3"
      borderRadius="6px"
      pt={6}
      pl={4}
      pb={10}
      pr={10}
      opacity={isDisabled ? 0.5 : 1}
      pointerEvents={isDisabled ? 'none' : 'auto'}
    >
      {children}
    </Box>
  )
}
