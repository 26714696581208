import { Box, HStack, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, Td, Tr } from '../../../../components/elements/Table'
import { components } from '../../../../models/profile-api'
import ProgressKeyhole from '../ProgressKeyhole'
import TableBorder from './TableBorder'
import TableHeader from './TableHeader'

const InviteTable = ({
  points,
  totalPoints,
  usedInvites,
  totalInvites,
  isDisabled = false,
  collapsed = false,
  maxColWidth = 100,
  handleColumnWidth,
  isLoading,
}: {
  points: components['schemas']['InvitePoints'] | undefined
  totalPoints: number
  usedInvites?: number
  totalInvites?: number
  isDisabled?: boolean
  collapsed: boolean
  maxColWidth: number
  handleColumnWidth?: (width: number) => void
  isLoading?: boolean
}) => {
  const percentTotalPoints = useMemo(() => {
    const percentTotalPoints =
      points && totalPoints ? (points._subtotal / totalPoints) * 100 : undefined

    return percentTotalPoints
  }, [points, totalPoints])

  const [isTableBodyVisible, setTableBodyVisible] = useState(true)

  const toggleTableBody = () => {
    setTableBodyVisible(!isTableBodyVisible)
  }

  useEffect(() => {
    setTableBodyVisible(!collapsed)
  }, [collapsed])

  return (
    <TableBorder isDisabled={isDisabled}>
      <Table noPadding>
        <TableHeader
          category="invites"
          title="Invite users to Term and receive 10% of the points they earn."
          handleClick={toggleTableBody}
          isTableBodyVisible={isDisabled ? false : isTableBodyVisible}
          handleColumnWidth={handleColumnWidth}
          totalPoints={points?._subtotal || 0}
          percentOfTotalPointsEarned={percentTotalPoints}
          maxColWidth={maxColWidth}
          isLoading={isLoading}
        />
        <TableBody
          style={{
            display: !isTableBodyVisible || isDisabled ? 'none' : 'table',
          }}
          w="100%"
          mt={8}
        >
          <Tr noBorder _hover={{}}>
            <Td w="400px" h="auto"></Td>
            <Td colSpan={2} pb={0} px={0} h="auto">
              <Text
                color="blue.9"
                variant="body-md/semibold"
                borderBottom="1px"
                borderColor="gray.4"
                w="full"
                pb={1}
                mb={3}
              >
                Details
              </Text>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}}>
                <Td pb={0}>
                  <HStack>
                    <Text color="gray.6" variant="body-md/semibold">
                      Active Invites{' '}
                    </Text>
                    {/* <Tooltip
                      iconColor="gray.4"
                      aria-label="active-invites-tooltip"
                      label="Active Invites Info"
                      noDelay
                    >
                      <Image src={info} boxSize="16px" opacity="0.5" />
                    </Tooltip> */}
                  </HStack>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" px={isLoading ? 2 : 0}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : (
                      <HStack>
                        <Text color="blue.9" variant="body-md/semibold">
                          {usedInvites ?? (
                            <Text color="gray.5" as="span">
                              0
                            </Text>
                          )}
                          {` / ${totalInvites ?? 5} invites`}
                        </Text>
                        <Box w="30px" h="30px" ml="auto">
                          <ProgressKeyhole
                            color="blue.3"
                            totalValue={totalInvites || 5}
                            value={usedInvites || 0}
                          />
                        </Box>
                      </HStack>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}}>
                <Td pb={0}>
                  <Text color="gray.6" variant="body-md/semibold">
                    Invitee Points
                  </Text>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" px={2}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : (
                      <Text color="blue.9" variant="body-md/semibold">
                        {points?.meta?.totalInvitePoints ? (
                          `${points?.meta?.totalInvitePoints.toLocaleString()} points`
                        ) : (
                          <Text
                            color="gray.5"
                            variant="body-md/normal"
                            as="span"
                          >
                            &mdash;
                          </Text>
                        )}
                      </Text>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}}>
                <Td pb={0}>
                  <Text color="gray.6" variant="body-md/semibold">
                    Your Points
                  </Text>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" px={2}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : (
                      <Text color="blue.9" variant="body-md/semibold">
                        {points?._subtotal ? (
                          `${points?._subtotal.toLocaleString()} points`
                        ) : (
                          <Text
                            color="gray.5"
                            variant="body-md/normal"
                            as="span"
                          >
                            &mdash;
                          </Text>
                        )}
                      </Text>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
        </TableBody>
      </Table>
    </TableBorder>
  )
}
export default InviteTable
