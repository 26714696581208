import { useEffect, useState } from 'react'
import { Address, Balance, NativeCurrency } from '../model'
import { bigToFixedNumber, fixedToBigNumber } from '../../helpers/conversions'
import { captureException } from '@sentry/react'
import { ChainId, useEtherBalance } from '@usedapp/core'
import { BigNumber } from 'ethers'

export function useNativeTokenBalance(
  account: Address | undefined,
  gasToken: NativeCurrency | undefined,
  chainId: ChainId | undefined
): Balance | null | undefined {
  const [gasTokenBalance, setGasTokenBalance] = useState<
    Balance | null | undefined
  >(undefined)

  const ethBalanceBN = useEtherBalance(account, {
    refresh: 'everyBlock',
    chainId,
  })

  // Set up balance change listener
  useEffect(() => {
    if (account && gasToken) {
      try {
        setGasTokenBalance((prevState) => {
          // previous state undefined on first render
          if (!prevState) {
            return {
              address: '0x0',
              symbol: gasToken.symbol,
              decimals: gasToken.decimals,
              balance: bigToFixedNumber(
                ethBalanceBN ?? BigNumber.from(0),
                gasToken.decimals
              ),
            } as Balance
          }
          if (!ethBalanceBN) {
            return prevState
          }
          // if they don't match then update the balance
          if (
            !fixedToBigNumber(prevState.balance, gasToken.decimals).eq(
              ethBalanceBN
            )
          ) {
            return {
              ...prevState,
              balance: bigToFixedNumber(ethBalanceBN, gasToken.decimals),
            }
            // otherwise return the previous state
          } else {
            return prevState
          }
        })
      } catch (err) {
        console.error('Error occurred getting balance, retrying...')
        captureException(err)
      }
    }
  }, [account, gasToken, ethBalanceBN])

  return gasTokenBalance
}
