import dayjs from 'dayjs'
import { MigrationPoints, InviteCode } from '../models/points'
import { components } from '../models/profile-api'

export const referralCodes: Record<string, boolean> = {
  abcde: true,
  edcba: false,
}
export const seasonInfo: Record<
  number | string,
  components['schemas']['Season']
> = {
  0: {
    id: 0,
    start: dayjs('2023-06-01').toISOString(),
    end: dayjs('2023-08-01').toISOString(),
    name: 'Pre-season',
    isLocked: true,
    meta: {
      socialPoints: 0,
    },
  },
  1: {
    id: 1,
    start: dayjs('2023-08-02').toISOString(),
    end: dayjs('2024-06-01').toISOString(),
    name: 'Season 1',
    isLocked: false,
    meta: {
      socialPoints: 123,
    },
  },
}

export const inviteCodes: Record<string, InviteCode | string> = {
  abcde: {
    code: 'abcde',
    profileId: 1,
    refereeId: 0,
    meta: {
      progress: {
        usedInvite: true,
        signedUp: false,
        earnedPoints: false,
      },
    },
    points: {
      referralPoints: 0,
    },
    // consumedTimestamp?: Date
  },
  abcd1: 'Not Found',
  abcd2: 'Already Claimed',
  abcd3: 'Forbidden',
}

export const profiles: Record<string, { profileExists: boolean }> = {
  '0xFe62875e936DC7842C9300d6390d9572d31BfA9D': {
    profileExists: false,
  },
  '0x9332e38f1a9BA964e166DE3eb5c637bc36cD4D27': {
    profileExists: true,
  },
}

export const migrationPoints: Record<string, MigrationPoints> = {
  // can add more test wallets here
  '0xFe62875e936DC7842C9300d6390d9572d31BfA9D': {
    // dais wallet
    walletAddress: '0xFe62875e936DC7842C9300d6390d9572d31BfA9D',
    hasClaimed: false,
    points: {
      earnedPoints: 100,
      eligiblePoints: 150,
    },
    meta: {
      firstDate: '2022-01-01',
      lastDate: '2022-12-31',
      daysBetween: 365,
      a3NumTransactions: 10,
      a2NumTransactions: 20,
      c2NumTransactions: 15,
      c3NumTransactions: 25,
      m1NumTransactions: 5,
      s1NumTransactions: 8,
      a3HasChurned: false,
      a2HasChurned: true,
      c3HasChurned: false,
      c2HasChurned: true,
      m1HasChurned: false,
      s1HasChurned: true,
      ethLTV: {
        ltvSupply: 1000,
        ltvBorrow: 500,
        ltvTotal: 50,
      },
      btcLTV: {
        ltvSupply: 2000,
        ltvBorrow: 1000,
        ltvTotal: 50,
      },
      stablesLTV: {
        ltvSupply: 3000,
        ltvBorrow: 1500,
        ltvTotal: 50,
      },
      buckets: {
        btcNetAveragePositiveBucket: 'Bucket1',
        btcNetPositiveDaysBucket: 'Bucket2',
        btcNetPositivePct: 'Bucket3',
        btcNetAverageNegativeBucket: 'Bucket4',
        btcNetNegativeDaysBucket: 'Bucket5',
        btcNetNegativePct: 'Bucket6',
        btcTxChurn: 'Bucket7',
        ethNetAveragePositiveBucket: 'Bucket8',
        ethNetPositiveDaysBucket: 'Bucket9',
        ethNetPositivePct: 'Bucket10',
        ethNetAverageNegativeBucket: 'Bucket11',
        ethNetNegativeDaysBucket: 'Bucket12',
        ethNetNegativePct: 'Bucket13',
        ethTxChurn: 'Bucket14',
        stablesNetAveragePositiveBucket: 'Bucket15',
        stablesNetPositiveDaysBucket: 'Bucket16',
        stablesNetPositivePct: 'Bucket17',
        stablesNetAverageNegativeBucket: 'Bucket18',
        stablesNetNegativeDaysBucket: 'Bucket19',
        stablesNetNegativePct: 'Bucket20',
        stablesTxChurn: 'Bucket21',
        totalDaysBucket: 'Bucket22',
      },
    },
  },
}

const seasonPattern = /^\/seasons$/
const walletPattern = /^\/wallet-analyzer\/([^/]+)$/
const inviteCodePattern = /^\/invite-code\/validate-invite\/([^/]+)$/
const referralPattern = /^\/profile\/validate-referral\/([^/]+)$/

export const getMockPointsData = (url: string): any => {
  if (seasonPattern.test(url)) {
    return [seasonInfo[0], seasonInfo[1]]
  } else if (walletPattern.test(url)) {
    const walletAddress = url.match(walletPattern)?.[1]
    if (!walletAddress) {
      return
    }
    return migrationPoints[walletAddress]
  } else if (inviteCodePattern.test(url)) {
    const code = url.match(inviteCodePattern)?.[1]
    if (!code) {
      return
    }
    return inviteCodes[code]
  } else if (referralPattern.test(url)) {
    const referralCode = url.match(referralPattern)?.[1]
    if (!referralCode) {
      return
    }
    return { isValid: referralCodes[referralCode] }
  }

  return { data: `Unknown route: ${url}` }
}
