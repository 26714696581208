import { Box, Link, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import TokenChip from '../../../../components/elements/TokenChip'
import Tooltip from '../../../../components/elements/Tooltip'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { RolloverAuctionInfo } from '../../../../data/model'
import {
  bigToFixedNumber,
  fixedToBigNumber,
} from '../../../../helpers/conversions'
import { divide, evaluate, multiply } from '../../../../helpers/math'
import {
  formatFixedPercentage,
  formatFixedToken,
  getAuctionDisplayId,
  getAuctionTimeLeft,
} from '../../../../helpers/utils'
import BorrowerRolloverInfoBox from '../BorrowerRolloverInfoBox'
import { Trans, t } from '@lingui/macro'

export default function BorrowerRolloverSummary({
  selectedAuction,
  repurchaseWindowEnd,
  rolloverMarginRequirement,
  rolloverAmount: rolloverAmountRaw,
  purchaseSymbol,
  collateralDeposited,
  collateralDepositedUSD,
  outstandingDebt,
  purchasePrice,
  collateralSymbol,
  interestBidRate,
}: {
  selectedAuction: RolloverAuctionInfo
  repurchaseWindowEnd: number
  rolloverMarginRequirement: FixedNumber
  rolloverAmount: FixedNumber | undefined
  purchaseSymbol: string
  collateralDeposited: FixedNumber
  collateralDepositedUSD: FixedNumber
  outstandingDebt: FixedNumber
  purchasePrice: FixedNumber
  collateralSymbol: string
  interestBidRate: FixedNumber
}) {
  const formatPercent = (value: FixedNumber) => {
    const formattedValue = evaluate(
      {
        nodeKind: 'mul',
        args: [
          {
            nodeKind: 'value',
            value: value,
          },
          {
            nodeKind: 'value',
            value: FixedNumber.fromString('100', value.format),
          },
        ],
      },
      2
    )
    return `${formattedValue.toString()}%`
  }

  const rolloverAmount = rolloverAmountRaw ?? FixedNumber.fromString('0')
  const bidAmountBN = fixedToBigNumber(rolloverAmount.toFormat('fixed128x18'))
    .mul(parseUnits('1', 18))
    .div(parseUnits('1', 18).sub(selectedAuction.servicingFee))
  const bidAmount = bigToFixedNumber(bidAmountBN, 18)
    .round(rolloverAmount.format.decimals)
    .toFormat(rolloverAmount.format)

  const interestAnnualised = multiply(
    bidAmount,
    divide(
      interestBidRate,
      FixedNumber.fromString('100', interestBidRate.format)
    ),
    rolloverAmount.format.decimals
  )

  const interest = multiply(
    interestAnnualised,
    divide(
      FixedNumber.fromString(
        `${
          (selectedAuction.endOfRepurchaseWindowTimestamp -
            selectedAuction.auctionEndTimestamp) /
          86400
        }`,
        18
      ),
      FixedNumber.fromString('360', 18)
    ),
    interestAnnualised.format.decimals
  )

  const rolloverRepurchase = evaluate({
    nodeKind: 'add',
    args: [
      {
        nodeKind: 'value',
        value: bidAmount,
      },
      {
        nodeKind: 'value',
        value: interest,
      },
    ],
  })

  const rolloverRepurchaseUSD = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: rolloverRepurchase,
        },
        {
          nodeKind: 'value',
          value: purchasePrice,
        },
      ],
    },
    bidAmount.format.decimals
  )

  const portionOfLoanRolledOver = evaluate(
    {
      nodeKind: 'div',
      args: [
        {
          nodeKind: 'value',
          value: rolloverAmount,
        },
        {
          nodeKind: 'value',
          value: outstandingDebt,
        },
      ],
    },
    rolloverAmount.format.decimals
  )

  const collateralRolledOver = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: collateralDeposited,
        },
        {
          nodeKind: 'value',
          value: portionOfLoanRolledOver,
        },
      ],
    },
    portionOfLoanRolledOver.format.decimals
  )

  const collateralRolledOverUSD = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: collateralDepositedUSD,
        },
        {
          nodeKind: 'value',
          value: portionOfLoanRolledOver,
        },
      ],
    },
    portionOfLoanRolledOver.format.decimals
  )

  const rolloverCollateralRatio = !rolloverRepurchaseUSD.isZero()
    ? evaluate(
        {
          nodeKind: 'div',
          args: [
            {
              nodeKind: 'value',
              value: collateralRolledOverUSD,
            },
            {
              nodeKind: 'value',
              value: rolloverRepurchaseUSD,
            },
          ],
        },
        collateralRolledOverUSD.format.decimals
      )
    : undefined

  const isUnderCollateralRequirement =
    (rolloverCollateralRatio ?? FixedNumber.fromString('0')) <
    rolloverMarginRequirement
  const now = useCurrentTime()

  return (
    <>
      <StatsCard>
        <StatsTitle
          value={
            selectedAuction
              ? getAuctionDisplayId({
                  auctionEndTimestamp: selectedAuction.auctionEndTimestamp,
                  maturityTimestamp: selectedAuction.maturityTimestamp,
                  termSymbol: selectedAuction.purchaseCurrencySymbol,
                  collateralSymbol: selectedAuction.collateralCurrencySymbol,
                })
              : undefined
          }
          titleSub={
            <Text
              maxWidth="320px"
              as="span"
              overflow="hidden"
              whiteSpace="nowrap"
              display="inline-flex"
              textOverflow="ellipsis"
            >
              If the rollover bid rate is &nbsp;
              {formatFixedPercentage(
                interestBidRate,
                undefined,
                false,
                FixedNumber.fromString('1.0', interestBidRate.format)
              )}
            </Text>
          }
          valueSub={getAuctionTimeLeft(
            selectedAuction.auctionEndTimestamp,
            now
          )}
        >
          <Trans>Rollover Instructions</Trans>
        </StatsTitle>
        <StatsRow
          title={
            <>
              <Trans>Bid Amount</Trans> &nbsp;
              <TokenChip token={purchaseSymbol} />
            </>
          }
          tooltip={
            <Tooltip
              label={
                <Text variant="body-sm/normal" as="span" color="white">
                  <Trans>Rollover amount plus the</Trans>{' '}
                  <Link
                    href="https://docs.term.finance/protocol/fees-and-penalties/servicing-fee"
                    isExternal
                  >
                    <Trans>servicing fee</Trans>
                  </Link>
                  {'. '}
                </Text>
              }
              aria-label="rollover-bid-tooltip"
            />
          }
        >
          <Text variant="body-sm/normal" as="span" color="blue.9">
            {formatFixedToken(bidAmount, purchaseSymbol, true)}
          </Text>
        </StatsRow>
        <StatsRow
          title={
            <>
              <Trans>Due at Maturity</Trans>&nbsp;
              <TokenChip token={purchaseSymbol} />
            </>
          }
          tooltip={
            <Tooltip
              label={t`Bid amount plus the interest payable, assuming that the rollover auction clears at the bid rate specified above.`}
              aria-label="rollover-debt-tooltip"
            />
          }
        >
          <Text
            overflow="hidden"
            whiteSpace="nowrap"
            variant="body-sm/normal"
            maxW={'280px'}
            as="span"
            color="blue.9"
          >
            {formatFixedToken(rolloverRepurchase, purchaseSymbol, true)}
          </Text>
        </StatsRow>
        <StatsRow
          title={
            <>
              <Trans>Collateral</Trans>&nbsp;
              <TokenChip token={collateralSymbol} />
            </>
          }
          tooltip={
            <Tooltip
              label={t`The amount of your existing collateral that will be rolled over is proportional to the rollover amount.`}
              aria-label="rollover-collateral-tooltip"
            />
          }
        >
          <Text variant="body-sm/normal" as="span" color="blue.9">
            {formatFixedToken(collateralRolledOver, collateralSymbol, true)}
          </Text>
        </StatsRow>
        <StatsRow title="Collateral Ratio / Required">
          <HStack>
            <Text
              variant="body-sm/medium"
              as="span"
              color={isUnderCollateralRequirement ? 'red.5' : 'green.5'}
            >
              {formatPercent(
                rolloverCollateralRatio ?? FixedNumber.fromString('0')
              )}
            </Text>
            <Text variant="body-sm/normal" as="span" color="blue.9">
              / {formatFixedPercentage(rolloverMarginRequirement, 1)}
            </Text>
          </HStack>
        </StatsRow>
      </StatsCard>
      <Box mt="16px" />
      {rolloverCollateralRatio &&
      !evaluate(
        {
          nodeKind: 'sub',
          args: [
            {
              nodeKind: 'value',
              value: rolloverCollateralRatio,
            },
            {
              nodeKind: 'value',
              value: rolloverMarginRequirement,
            },
          ],
        },
        rolloverCollateralRatio.format.decimals
      ).isNegative() ? (
        <BorrowerRolloverInfoBox kind="info">
          <Trans>
            Your{' '}
            <Text as="span" color="green.5" variant="body-sm/medium">
              existing collateral would be sufficient
            </Text>{' '}
            if the auction clears at
          </Trans>{' '}
          <Box
            as="span"
            maxWidth="285px"
            overflow="hidden"
            textOverflow="ellipsis"
            display="inline-flex"
          >
            {formatFixedPercentage(
              interestBidRate,
              undefined,
              false,
              FixedNumber.fromString('1.0', interestBidRate.format)
            )}
            {'.'}
          </Box>{' '}
          <Trans>
            Please note, if your rollover fails you will be required to repay
            your total debt before the repayment window closes on{' '}
          </Trans>{' '}
          {dayjs.unix(repurchaseWindowEnd).format('MMM D, ha')}.
        </BorrowerRolloverInfoBox>
      ) : (
        <BorrowerRolloverInfoBox kind="error">
          <Trans>
            Your{' '}
            <Text as="span" color="red.5" variant="body-sm/medium">
              existing collateral would not be sufficient
            </Text>{' '}
            if the auction clears at
          </Trans>{' '}
          <Box
            as="span"
            maxWidth="285px"
            overflow="hidden"
            textOverflow="ellipsis"
            display="inline-flex"
          >
            {formatFixedPercentage(
              interestBidRate,
              undefined,
              false,
              FixedNumber.fromString('1.0', interestBidRate.format)
            )}
            {'.'}
          </Box>{' '}
          <Trans>
            Deposit more collateral to reduce the risk of your rollover failing.
          </Trans>
        </BorrowerRolloverInfoBox>
      )}
      <Box mt="16px" />
    </>
  )
}
