import { Box, HStack, Skeleton, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Table, TableBody, Td, Tr } from '../../../../components/elements/Table'
import { components } from '../../../../models/profile-api'
import { formatNumber } from '../../utils'
import ProgressBar from '../ProgressBar'
import ProgressKeyhole from '../ProgressKeyhole'
import TableBorder from './TableBorder'
import TableHeader from './TableHeader'

const AuctionTable = ({
  points,
  totalPoints,
  totalNumberOfWeeks,
  totalNumberOfAuctions,
  isDisabled = false,
  collapsed = false,
  maxColWidth,
  handleColumnWidth,
  isLoading,
}: {
  points: components['schemas']['AuctionPoints'] | undefined
  totalPoints: number
  totalNumberOfWeeks: number
  totalNumberOfAuctions: number
  isDisabled?: boolean
  collapsed: boolean
  maxColWidth: number
  handleColumnWidth?: (width: number) => void
  isLoading?: boolean
}) => {
  const { percentAssignedBids, percentAssignedOffers, percentTotalPoints } =
    useMemo(() => {
      if (!points) {
        return {
          percentAssignedBids: 0,
          percentAssignedOffers: 0,
          percentTotalPoints: 0,
        }
      }
      const percentAssignedBids =
        points.meta?.totalAssignedBidAmount && points.meta?.totalBidAmount
          ? (points.meta.totalAssignedBidAmount / points.meta.totalBidAmount) *
            100
          : undefined

      const percentAssignedOffers =
        points.meta?.totalAssignedOfferAmount && points.meta?.totalOfferAmount
          ? (points.meta.totalAssignedOfferAmount /
              points.meta.totalOfferAmount) *
            100
          : undefined

      const percentTotalPoints = totalPoints
        ? (points._subtotal / totalPoints) * 100
        : undefined

      return {
        percentAssignedBids,
        percentAssignedOffers,
        percentTotalPoints,
      }
    }, [points, totalPoints])

  const [isTableBodyVisible, setTableBodyVisible] = useState(true)

  const toggleTableBody = () => {
    setTableBodyVisible(!isTableBodyVisible)
  }

  useEffect(() => {
    setTableBodyVisible(!collapsed)
  }, [collapsed])

  const bidsTextRef = useRef<HTMLDivElement>(null)
  const offersTextRef = useRef<HTMLDivElement>(null)
  const [bidsTextWidth, setBidsTextWidth] = useState(0)
  const [offersTextWidth, setOffersTextWidth] = useState(0)

  useEffect(() => {
    if (bidsTextRef.current) {
      setBidsTextWidth(bidsTextRef.current.offsetWidth)
    }
    if (offersTextRef.current) {
      setOffersTextWidth(offersTextRef.current.offsetWidth)
    }
  }, [points])

  return (
    <TableBorder isDisabled={isDisabled}>
      <Table noPadding>
        <TableHeader
          category="auctions"
          title="Earn points for creating offers to lend, and bids to borrow."
          totalPoints={points?._subtotal || 0}
          multiplier={points?.meta?.multiplierConsistency}
          handleClick={toggleTableBody}
          isTableBodyVisible={isDisabled ? false : isTableBodyVisible}
          percentOfTotalPointsEarned={percentTotalPoints}
          handleColumnWidth={handleColumnWidth}
          maxColWidth={maxColWidth}
          isLoading={isLoading}
        />
        <TableBody
          style={{
            display: !isTableBodyVisible || isDisabled ? 'none' : 'table',
          }}
          w="100%"
          mt={8}
        >
          <Tr noBorder _hover={{}}>
            <Td w="400px" h="auto"></Td>
            <Td colSpan={2} pb={0} px={0} h="auto">
              <Text
                color="blue.9"
                variant="body-md/semibold"
                borderBottom="1px"
                borderColor="gray.4"
                w="full"
                pb={1}
                mb={3}
              >
                Details
              </Text>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}}>
                <Td pb={0}>
                  <Text color="gray.6" variant="body-md/semibold">
                    Weeks Participated
                  </Text>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" px={isLoading ? 2 : 0}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : (
                      <HStack>
                        <Text color="blue.9" variant="body-md/semibold">
                          {points?.meta?.noWeeksParticipated || (
                            <Text as="span" color="gray.5">
                              0
                            </Text>
                          )}{' '}
                          / {totalNumberOfWeeks} weeks
                        </Text>
                        <Box w="30px" h="30px" ml="auto">
                          <ProgressKeyhole
                            color="blue.3"
                            totalValue={totalNumberOfWeeks}
                            value={points?.meta?.noWeeksParticipated || 0}
                          />
                        </Box>
                      </HStack>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}} bg="blue.0">
                <Td pb={0}>
                  <Text color="gray.6" variant="body-md/semibold">
                    Auctions Participated
                  </Text>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" px={isLoading ? 2 : 0}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : (
                      <HStack>
                        <Text color="blue.9" variant="body-md/semibold">
                          {points?.meta?.noAuctionsParticipated || (
                            <Text as="span" color="gray.5">
                              0
                            </Text>
                          )}{' '}
                          / {totalNumberOfAuctions} auctions
                        </Text>
                        <Box w="30px" h="30px" ml="auto">
                          <ProgressKeyhole
                            color="blue.3"
                            totalValue={totalNumberOfAuctions}
                            value={points?.meta?.noAuctionsParticipated || 0}
                          />
                        </Box>
                      </HStack>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}}>
                <Td alignItems="start" pb={0}>
                  <Text color="gray.6" variant="body-md/semibold" py={3}>
                    Your Assigned Bids
                  </Text>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" py={3} px={2}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : points?.meta?.totalAssignedBidAmount &&
                      points?.meta?.totalBidAmount &&
                      percentAssignedBids ? (
                      <>
                        <Text
                          ref={bidsTextRef}
                          color="blue.9"
                          variant="body-md/semibold"
                          mb={3}
                        >
                          <Text as="span" color="blue.3">
                            $
                            {formatNumber(
                              points?.meta?.totalAssignedBidAmount || 0
                            )}
                          </Text>{' '}
                          / ${formatNumber(points?.meta?.totalBidAmount || 0)}{' '}
                          tendered
                        </Text>
                        <Box width={`${bidsTextWidth}px`}>
                          <ProgressBar
                            value={percentAssignedBids || 0}
                            trackColor="gray.3"
                            lineColor="blue.3"
                          />
                        </Box>
                      </>
                    ) : (
                      <Text color="gray.5" variant="body-md/normal">
                        &mdash;
                      </Text>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}} bg="blue.0">
                <Td display="flex" alignItems="start" pb={0}>
                  <Text color="gray.6" variant="body-md/semibold" py={3}>
                    Your Assigned Offers
                  </Text>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box ml="auto" w="fit-content" py={3} px={2}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : points?.meta?.totalAssignedOfferAmount &&
                      points.meta?.totalOfferAmount &&
                      percentAssignedOffers ? (
                      <>
                        <Text
                          ref={offersTextRef}
                          color="blue.9"
                          variant="body-md/semibold"
                          mb={3}
                        >
                          <Text as="span" color="blue.3">
                            $
                            {formatNumber(
                              points?.meta?.totalAssignedOfferAmount || 0
                            )}
                          </Text>{' '}
                          / ${formatNumber(points?.meta?.totalOfferAmount || 0)}{' '}
                          tendered
                        </Text>
                        <Box width={`${offersTextWidth}px`}>
                          <ProgressBar
                            value={percentAssignedOffers || 0}
                            trackColor="gray.3"
                            lineColor="blue.3"
                          />
                        </Box>
                      </>
                    ) : (
                      <Text color="gray.5" variant="body-md/normal">
                        &mdash;
                      </Text>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
        </TableBody>
      </Table>
    </TableBorder>
  )
}
export default AuctionTable
