import {
  Box,
  Button,
  Center,
  Flex,
  Link,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AuctionBoxHeading from '../AuctionBox/AuctionBoxHeading'
import { Link as RouterLink } from 'react-router-dom'
import Tooltip from '../../../../components/elements/Tooltip'

const headingSkeletonWidth = {
  base: '90px',
  xl: '120px',
}

export default function AuctionBoxLoading() {
  return (
    <>
      <Box
        bg="white"
        w={'100%'}
        borderRadius="6px"
        boxShadow="0px 4px 4px rgba(49, 130, 206, 0.03)"
      >
        <Flex
          gap={{
            base: '0',
            xl: '30px',
          }}
          flexDirection={{
            base: 'column',
            xl: 'row',
          }}
        >
          <Box
            h="100%"
            pt={{
              base: '8px',
              xl: '22px',
            }}
            px={{
              base: '16px',
              xl: '22px',
            }}
            pb={{
              base: '8px',
              xl: '17px',
            }}
            borderRight={{
              base: 'none',
              xl: '1px solid',
            }}
            borderBottom={{
              base: '1px solid',
              xl: 'none',
            }}
            borderBottomColor={{
              base: 'gray.2',
              xl: 'none',
            }}
            borderRightColor={{
              base: 'none',
              xl: 'gray.2',
            }}
            w={{
              base: '100%',
              xl: '96px',
            }}
          >
            <Center
              flexDirection="column"
              display={{
                base: 'none',
                xl: 'flex',
              }}
            >
              <Text variant="body-xs/semibold" color="gray.6">
                Opened
              </Text>
              <Skeleton w="45px" h={8} />
            </Center>
            <HStack
              display={{
                base: 'flex',
                xl: 'none',
              }}
              w="full"
              justifyContent="center"
            >
              <Text as="span" variant="body-md/normal" color="blue.9">
                Opened
              </Text>
            </HStack>
          </Box>
          <Box
            display={{
              base: 'grid',
              xl: 'flex',
            }}
            gridTemplateColumns={{
              base: '1fr 1fr',
              xl: '1fr',
            }}
            gap={{
              base: '16px',
              xl: '64px',
            }}
            w="100%"
            py={{
              base: '16px',
              xl: '20px',
            }}
            pr={{
              base: '16px',
              xl: '17px',
            }}
            pl={{
              base: '16px',
              xl: '0',
            }}
          >
            <Flex
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
              px={{
                base: '20px',
                xl: '0',
              }}
            >
              <AuctionBoxHeading title="Loan Term" />
              <Skeleton w={headingSkeletonWidth} h={6} />
            </Flex>
            <Flex
              minW="160px" // important
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
              px={{
                base: '20px',
                xl: '0',
              }}
            >
              <AuctionBoxHeading title="Maturity" />
              <Skeleton w={headingSkeletonWidth} h={6} />
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
              px={{
                base: '20px',
                xl: '0',
              }}
            >
              <AuctionBoxHeading title="Supply / Borrow" />
              <Skeleton w={headingSkeletonWidth} h={6} />
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
              flexGrow="1" // important
              px={{
                base: '20px',
                xl: '0',
              }}
            >
              <AuctionBoxHeading title="Collateral" />
              <Skeleton w={headingSkeletonWidth} h={6} />
            </Flex>
            <Flex
              flexDirection="column"
              textAlign="right"
              justifyContent="center"
              gridColumn="span 2 / span 2"
              rowGap={1}
            >
              <Skeleton
                w="141px"
                h={5}
                display={{
                  base: 'none',
                  xl: 'block',
                }}
              />
              <Tooltip
                label={
                  <>
                    You can no longer participate as this auction is clearing.
                    View your tenders in{' '}
                    <Link as={RouterLink} to="/portfolio/offers">
                      your portfolio
                    </Link>
                  </>
                }
                aria-label="auction-clearing-tooltip"
                shouldWrapChildren
              >
                <Button
                  variant="secondary"
                  size="md"
                  alignSelf="flex-end"
                  rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
                  isDisabled
                  w={{
                    base: '100%',
                    xl: '141px',
                  }}
                >
                  Participate
                </Button>
              </Tooltip>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  )
}
