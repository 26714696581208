import { Flex, type FlexProps } from '@chakra-ui/react'
import {
  faEllipsisVertical,
  type IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function IconButton(
  props: FlexProps & {
    hasShadow?: boolean
    icon?: IconDefinition
  }
) {
  return (
    <Flex
      as="span"
      borderWidth="1px"
      borderColor="blue.5"
      borderRadius="md"
      alignItems="center"
      justifyContent="center"
      w="24px"
      h="24px"
      bg="white"
      boxShadow={
        props.hasShadow ? '0px 3px 4px rgba(40, 53, 99, 0.15)' : 'none'
      }
      _hover={{
        bg: 'blue.1',
      }}
      _active={{
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #D2E4FF',
        bgBlendMode: 'overlay',
      }}
      _disabled={{
        opacity: 0.5,
        pointerEvents: 'none',
      }}
    >
      <FontAwesomeIcon
        icon={props.icon ?? faEllipsisVertical}
        color="#0A5ED9"
        width="3px"
        height="12px"
      />
    </Flex>
  )
}
