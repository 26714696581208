import { Image, Tag, Text } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import multiplier from '../../../../assets/icons/pointsCard/3x-mulitplier.svg'
import unlock from '../../../../assets/icons/pointsCard/unlock.svg'
import { CategoryTagIcon, TagIconProps } from '../../utils'

export default function PointsCardTag({ variant, points }: TagIconProps) {
  const [padding, setPadding] = useState(2)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const textElement = textRef.current
    if (textElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const targetElement = entry.target as HTMLElement
          if (targetElement.offsetHeight > 28) {
            setPadding(1)
          } else {
            setPadding(2)
          }
        }
      })
      resizeObserver.observe(textElement)
      return () => resizeObserver.disconnect()
    }
  }, [])

  return (
    <Tag bg="#F4F4F4" p={padding} h="auto" minH="28px">
      {variant === '3xMultiplier' ? (
        <Image boxSize="16px" src={multiplier} alt={'3xMultipler'} />
      ) : variant === 'unlock' ? (
        <Image boxSize="12px" src={unlock} alt={'Unlock'} />
      ) : (
        <CategoryTagIcon variant={variant} />
      )}
      <Text variant="body-xs/medium" color="gray.6">
        {points}
      </Text>
    </Tag>
  )
}
