import { Box, Text, Button, Image, Link } from '@chakra-ui/react'
import term_logo from '../../../../assets/term-logo-short.svg'
import { socialLinks } from '../../../../socials'
import { InfoIcon } from '../../../../components/elements/Icons'
import { HStack } from '../../../../components/elements/Stack'

type Props = {
  onConnect: () => void
  onSignInModalOpen?: () => void
}

const ConnectWallet = ({ onConnect, onSignInModalOpen }: Props) => {
  return (
    <Box
      w="420px"
      p={5}
      mt="105px"
      mx="auto"
      borderRadius="6px"
      bg="white"
      boxShadow="0px 4px 4px 0px rgba(49, 130, 206, 0.03)"
    >
      <HStack spacing="8px">
        <Image src={term_logo} h="24px" />
        <Text
          variant="body-md/medium"
          fontWeight={500}
          opacity={0.9}
          color="blue.9"
        >
          Introducing Rewards
        </Text>
      </HStack>
      <Box mt={5}>
        <HStack gap={0}>
          <Text variant="body-2xl/bold" color="blue.9">
            <Text color="green.5" as="span">
              Earn
            </Text>{' '}
            <Text as="span">Term Points</Text>
          </Text>
          <Link
            target="_blank"
            href={socialLinks.rewardsAnnouncement}
            isExternal
          >
            <InfoIcon
              boxSize="20px"
              color="gray.3"
              ml={2}
              mb="3px"
              style={{ verticalAlign: 'center' }}
            />
          </Link>
        </HStack>
        <Text variant="body-md/medium" color="blue.9" opacity={0.75}>
          for lending & participating in auctions
        </Text>
      </Box>
      <Text variant="body-sm/normal" mt={3} color="blue.9" opacity={0.7}>
        <Text>
          Lenders can now earn Term Points redeemable for rewards at season’s
          end, for a limited time.
        </Text>
        <br />
        <Text>
          Existing users are already earning points. Connect to check your
          points balance and start earning!
        </Text>
      </Text>
      <Box mt={6}>
        <Button variant="primary" onClick={onConnect} w="full">
          Connect
        </Button>
      </Box>
      <Box>
        <Button
          mt={2}
          size="md"
          color="blue.9"
          variant="tertiary"
          border={0}
          w="100%"
          onClick={() => {
            if (onSignInModalOpen) {
              onSignInModalOpen()
            }
          }}
        >
          <Text variant="body-sm/normal" opacity={0.5}>
            Already have a profile?
            <Text as="span" ml={1} variant="body-sm/medium" opacity={0.75}>
              Sign in with socials
            </Text>
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

export default ConnectWallet
