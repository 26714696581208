import TabGroup from '../../../../components/elements/TabGroup'

export default function TabButtons({
  value,
  numLoans,
  numBorrows,
  onChange,
  isDataLoading,
}: {
  value: 'loan' | 'borrow'
  numLoans: number
  numBorrows: number
  onChange: (value: 'loan' | 'borrow') => void
  isDataLoading: boolean
}) {
  return (
    <TabGroup
      left={{
        label: `Open Offers ${
          isDataLoading || numLoans === 0 ? '' : `(${numLoans})`
        }`,
        value: 'loan' as const,
      }}
      right={{
        label: `Open Bids ${
          isDataLoading || numBorrows === 0 ? '' : `(${numBorrows})`
        }`,
        value: 'borrow' as const,
      }}
      value={value}
      onChange={onChange}
    />
  )
}
