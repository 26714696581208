import { Box, Divider, Text } from '@chakra-ui/react'
import { HStack, VStack, Stack } from '../../../../components/elements/Stack'
import { ReactNode } from 'react'
import AuctionTermBadge from '../../../../components/elements/AuctionTermBadge'
import StatusIndicator from '../../../../components/elements/StatusIndicator'
import TokenIconLabel from '../../../../components/elements/TokenLabelIcon'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { Auction, TermPeriod } from '../../../../data/model'
import {
  CONTAINER_PADDING,
  MOBILE_CONTAINER_PADDING,
} from '../../../../helpers/constants'
import {
  formatBigToken,
  formatFixedPercentage,
  formatMaturityDate,
  getAuctionDisplayId,
  getAuctionStatus,
} from '../../../../helpers/utils'
import ClearingPulse from '../../../Auctions/elements/ClearingPulse'
import LivePulse from '../../../Auctions/elements/LivePulse'
import ContractAddressesDropdown from '../../../../components/elements/ContractAddressesDropdown'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { useChainConfig } from '../../../../providers/config'
import { Trans, t } from '@lingui/macro'

const OpenAuctionStatistics = ({
  chainId,
  auction: {
    address,
    bidLockerAddress,
    offerLockerAddress,
    auctionStartTimestamp,
    auctionRevealTimestamp,
    auctionEndTimestamp,
    maturityTimestamp,
    initialMarginRatio,
    maintenanceMarginRatio,
    collateralCurrencyOraclePriceUSDC,
    purchaseCurrencyOraclePriceUSDC,
    closed,
  },
  term,
  purchaseSymbol,
  collateralSymbol,
}: {
  chainId: number
  auction: Pick<
    Auction,
    | 'address'
    | 'bidLockerAddress'
    | 'offerLockerAddress'
    | 'auctionStartTimestamp'
    | 'auctionRevealTimestamp'
    | 'auctionEndTimestamp'
    | 'maturityTimestamp'
    | 'initialMarginRatio'
    | 'maintenanceMarginRatio'
    | 'purchaseCurrencyOraclePriceUSDC'
    | 'collateralCurrencyOraclePriceUSDC'
    | 'closed'
  >
  term: Pick<
    TermPeriod,
    | 'repoServicerAddress'
    | 'rolloverManagerAddress'
    | 'collateralManagerAddress'
    | 'termRepoLockerAddress'
    | 'termRepoTokenAddress'
  >
  purchaseSymbol: string
  collateralSymbol: string
}) => {
  const now = useCurrentTime()
  const { status, statusTime } = getAuctionStatus(
    auctionStartTimestamp,
    auctionRevealTimestamp,
    auctionEndTimestamp,
    now,
    closed
  )

  const statusTitles = {
    live: t`Closes In`,
    clearing: t`Clears`,
    upcoming: t`Opens In`,
    closed: t`Closed`,
  }

  const chainConfig = useChainConfig(chainId)

  return (
    <HStack
      position="relative"
      py="14px"
      px={{
        base: MOBILE_CONTAINER_PADDING,
        xl: CONTAINER_PADDING,
      }}
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      spacing="76px"
    >
      <Box
        position="absolute"
        zIndex="-1"
        top="0"
        left="-50vw"
        width="150vw"
        height="100%"
        background={chainConfig?.verticalGradient}
      />
      <Divider
        borderColor="gray.2"
        pointerEvents="none"
        margin={0}
        position="absolute"
        bottom={0}
        left="50%"
        transform="translateX(-50%)"
        w={{
          base: 'calc(100% - 40px)',
          xl: 'calc(100% - 160px)',
        }}
      />
      <VStack spacing={0} alignItems="start" ml="8px">
        <HStack spacing={1}>
          <Text
            as="h2"
            variant={{
              base: 'body-md/bold',
              xl: 'body-xl/bold',
            }}
            color="blue.9"
            whiteSpace="nowrap"
          >
            {getAuctionDisplayId({
              auctionEndTimestamp,
              maturityTimestamp,
              termSymbol: purchaseSymbol,
              collateralSymbol,
            })}
          </Text>
          <NetworkIcon chainId={chainId} boxSize={'20px'} variant="faded" />
        </HStack>
        <ContractAddressesDropdown
          chainId={chainId}
          auction={address}
          bidLocker={bidLockerAddress}
          offerLocker={offerLockerAddress}
          repoLocker={term.termRepoLockerAddress}
          repoServicer={term.repoServicerAddress}
          collateralManager={term.collateralManagerAddress}
          rolloverManager={term.rolloverManagerAddress}
          termRepoToken={term.termRepoTokenAddress}
        />
      </VStack>
      <HStack
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        spacing="12px"
        p={0}
        ml="0 !important"
      >
        <Stack spacing={1}>
          <Text
            color="gray.6"
            whiteSpace="nowrap"
            variant="body-xs/normal"
            mb="0"
          >
            {statusTitles[status]}
          </Text>
          <HStack alignItems="center" spacing={1}>
            {
              {
                live: (
                  <>
                    <LivePulse w={6} h={6} />
                    <Text fontWeight="700" fontSize="sm" whiteSpace="nowrap">
                      {statusTime}
                    </Text>
                  </>
                ),
                clearing: (
                  <>
                    <ClearingPulse w={6} h={6} />
                    <Text fontWeight="700" fontSize="sm" whiteSpace="nowrap">
                      <Trans>Clearing</Trans>
                    </Text>
                  </>
                ),
                upcoming: (
                  <>
                    <StatusIndicator status="upcoming" />
                    <Text variant="body-sm/semibold">{statusTime}</Text>
                  </>
                ),
                closed: (
                  <>
                    <StatusIndicator status="closed" />
                    <Text variant="body-sm/semibold">{statusTime}</Text>
                  </>
                ),
              }[status]
            }
          </HStack>
        </Stack>
        <AuctionStat name="Term">
          <AuctionTermBadge
            auction={{
              auctionEndTimestamp,
              maturityTimestamp,
            }}
          />
        </AuctionStat>

        <AuctionStat name="Maturity">
          <Text color="blue.9" whiteSpace="nowrap" variant="body-sm/normal">
            {formatMaturityDate(maturityTimestamp)}
          </Text>
        </AuctionStat>
        <AuctionStat name="Supply / Borrow">
          {/* Placeholder price */}
          <TokenIconLabel
            isSmall
            priceDisplay={{
              base: 'none',
              xl: 'flex',
            }}
            token={purchaseSymbol}
            price={`${formatBigToken(
              purchaseCurrencyOraclePriceUSDC,
              18,
              'USD',
              undefined,
              true
            )}`}
          />
        </AuctionStat>
        <AuctionStat name="Collateral">
          <TokenIconLabel
            isSmall
            priceDisplay={{
              base: 'none',
              xl: 'flex',
            }}
            token={collateralSymbol}
            price={`${formatBigToken(
              collateralCurrencyOraclePriceUSDC,
              18,
              'USD',
              undefined,
              true
            )}`}
          />
        </AuctionStat>
        <AuctionStat
          name={
            <>
              <Box as="span" display={{ base: 'none', xl: 'inline' }}>
                <Trans>Collateral Ratio (Initial • Maintenance)</Trans>
              </Box>
              <Box as="span" display={{ base: 'inline', xl: 'none' }}>
                <Trans>Collateral Ratio (Init. • Main.)</Trans>
              </Box>
            </>
          }
        >
          <Text variant="body-sm/normal">
            {formatFixedPercentage(initialMarginRatio, 1)}
            {' / '}
            {formatFixedPercentage(maintenanceMarginRatio, 1)}
          </Text>
        </AuctionStat>
      </HStack>
    </HStack>
  )
}

export default OpenAuctionStatistics

function AuctionStat({
  name,
  children,
}: {
  name: string | ReactNode
  children: ReactNode
}) {
  return (
    <VStack spacing={1} alignItems="start" ml="0 !important">
      <Text as="h2" color="gray.6" variant="body-xs/normal">
        {name}
      </Text>
      <Box fontSize="sm">{children}</Box>
    </VStack>
  )
}
