import { TableColumnHeaderProps, Text, Th } from '@chakra-ui/react'
import { ReactNode } from 'react'

export default function TableHeaderCell({
  isBold,
  p,
  colSpan,
  textAlign,
  children,
  ...props
}: TableColumnHeaderProps & {
  isBold?: boolean
  children?: ReactNode
}) {
  return (
    <Th
      color="blue.9"
      background="gray.1"
      borderColor="gray.2"
      fontSize="xs"
      borderLeftColor="gray.2"
      borderY="none"
      padding={p ?? '8px'}
      textTransform={'capitalize'}
      textAlign={textAlign ?? 'left'}
      colSpan={colSpan ?? 1}
      {...props}
    >
      <Text
        as="span"
        variant={isBold ? 'body-xs/bold' : 'body-xs/medium'}
        color="blue.9"
      >
        {children}
      </Text>
    </Th>
  )
}
