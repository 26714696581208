import { Box, Heading, Spinner } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useMemo, useRef } from 'react'
import Chip from '../../../../components/elements/Chip'
import { HStack } from '../../../../components/elements/Stack'
import {
  Table,
  TableFoot,
  TableHead,
  Th,
  Tr,
} from '../../../../components/elements/Table'
import { useColumnWidths } from '../../../../hooks/portfolio'
import { OpenBorrowHeader, OpenLoanHeader } from '../OpenPositionsSection'

interface ColumnWidths {
  [key: string]: number
}

const COLUMN_WIDTHS: ColumnWidths = {
  network: 122,
  status: 54,
  maturity: 65,
  repayRedeem: 77,
  outstanding: 126,
  initial: 91,
  interest: 61,
  assetSelection: 50,
  liquidationPrice: 69,
  required: 85,
  depositedAndExcess: 85,
  emptyColumn: 132,
}

export default function OpenPositionsSectionLoading() {
  // Refs to tables to calculate column widths
  const bidsTableRef = useRef(null)
  const offersTableRef = useRef(null)

  const tableRefs = useMemo(
    () => [bidsTableRef, offersTableRef],
    [bidsTableRef, offersTableRef]
  )

  const excludedColumnsFromEmptySpaceCalculation = useMemo(
    () => new Set(['asset']),
    []
  )

  const columnWidths = useColumnWidths(
    tableRefs,
    COLUMN_WIDTHS,
    excludedColumnsFromEmptySpaceCalculation,
    true
  )
  return (
    <Box my={5} mr="20px !important">
      <HStack mb="8px">
        <Heading variant="heading/h3" color="blue.9">
          <Trans>Borrowing</Trans>
        </Heading>
        <Chip>
          <Trans>0</Trans>
        </Chip>
      </HStack>
      <Table tableRef={bidsTableRef} isFixedLayout noPadding>
        <TableHead>
          <OpenBorrowHeader
            asset={'ratio'}
            handleToggleSortOrder={() => {}}
            sortDirection={'asc'}
            isFirst={true}
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableFoot noTopBorder>
          <Tr noBorder>
            <Th textAlign="center" colSpan={12} p="14px 0">
              <HStack w="full" justifyContent="center">
                <Spinner
                  color="blue.500"
                  w="42px"
                  h="42px"
                  className="chromatic-ignore"
                />
              </HStack>
            </Th>
          </Tr>
        </TableFoot>
      </Table>
      <HStack mb="8px" mt="40px">
        <Heading variant="heading/h3" color="blue.9">
          <Trans>Lending</Trans>
        </Heading>
        <Chip>
          <Trans>0</Trans>
        </Chip>
      </HStack>
      <Table tableRef={offersTableRef} isFixedLayout noPadding>
        <TableHead>
          <OpenLoanHeader
            hasAssetColumn={false}
            isFirst={true}
            handleToggleSortOrder={() => {}}
            sortDirection={'asc'}
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableFoot noTopBorder>
          <Tr noBorder>
            <Th textAlign="center" colSpan={12} p="14px 0">
              <HStack w="full" justifyContent="center">
                <Spinner
                  color="blue.500"
                  w="42px"
                  h="42px"
                  className="chromatic-ignore"
                />
              </HStack>
            </Th>
          </Tr>
        </TableFoot>
      </Table>
    </Box>
  )
}
