import { useMemo } from 'react'
import {
  Address,
  Currency,
  LiquidationForDisplay,
  SubmittedRollover,
  TermBorrow,
} from '../model'
import { PagePortfolioQuery } from '../../gql/graphql'
import { BigNumber } from 'ethers'
import { bigToFixedNumber } from '../../helpers/conversions'
import { useStorage } from '../../providers/storage'
import { range } from 'lodash'
import { deserializeRollover } from '../../services/private-rollovers'

// Gets all borrows for a user across all terms/auctions.
export function useAllBorrows(
  account: Address | undefined,
  currencies: Record<string, { [address: string]: Currency }> | undefined,
  data: Record<string, PagePortfolioQuery> | undefined
): TermBorrow[] | undefined {
  const { storage: localStorage, rolloversUpdated } = useStorage()

  const localResult = useMemo(() => {
    if (account === undefined) {
      return
    }
    const rolloverMap = {} as {
      [termId: string]: SubmittedRollover
    }

    const rollovers: SubmittedRollover[] = range(localStorage?.length ?? 0)
      .map((i) => localStorage.key(i))
      .filter((key): key is string => key?.startsWith('rollover-') ?? false)
      .map((key) => localStorage.getItem(key))
      .filter((val): val is string => val !== null)
      .map((val) => deserializeRollover(val))
      .filter((val): val is SubmittedRollover => {
        // TODO: Validate the the borrow tenders have all required fields.
        return val !== undefined
      })
      .filter((t) => t.borrower === account)
    for (const rollover of rollovers) {
      rolloverMap[rollover.id] = rollover
    }
    return rolloverMap
    // rolloversUpdated is a dependency here because we want to look at localstorage
    // again when missing rates are loaded from revealer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, localStorage, rolloversUpdated])

  const results = useMemo(() => {
    if (!data) {
      return undefined
    }
    const borrowsMap = {} as {
      [termId: string]: TermBorrow
    }

    // Iterate over each chain's data
    Object.entries(data).forEach(([chainId, pagePortfolioQuery]) => {
      const repoCollaterals = pagePortfolioQuery.termRepoCollaterals

      pagePortfolioQuery.termRepoExposures.forEach((repoExposure) => {
        const purchaseCurrency =
          currencies?.[chainId]?.[repoExposure.term.purchaseToken]

        const relevantRepoCollaterals = repoCollaterals.filter(
          (collateral) =>
            collateral.term.id === repoExposure.term.id &&
            collateral.repoExposure?.id === repoExposure.id
        )

        const primaryCollateralToken =
          relevantRepoCollaterals.length > 0
            ? relevantRepoCollaterals[0].collateralToken
            : undefined

        const collateralCurrency = primaryCollateralToken
          ? currencies?.[chainId]?.[primaryCollateralToken]
          : undefined
        // })

        // const mergedData = data?.termRepoExposures.map((repoExposure) => ({
        //   ...repoExposure,
        //   termRepoCollaterals: data?.termRepoCollaterals.filter(
        //     (repoCollateral) =>
        //       repoCollateral.term.id === repoExposure.term.id &&
        //       repoCollateral.repoExposure?.id === repoExposure.id
        //   ),
        // }))

        // for (const repoExposure of mergedData) {
        //   // TODO: wire up for multiple collateral currency support later
        //   const collateralCurrency =
        //     currencies?.[repoExposure?.termRepoCollaterals[0]?.collateralToken]
        //   const purchaseCurrency = currencies?.[repoExposure?.term?.purchaseToken]
        if (
          !collateralCurrency ||
          !purchaseCurrency ||
          !relevantRepoCollaterals.length
        ) {
          return
        }
        if (!borrowsMap[repoExposure.term.id]) {
          borrowsMap[repoExposure.term.id] = {
            chainId,
            id: repoExposure.id,
            version: repoExposure?.term?.version ?? '0.0.0',
            maturityTimestamp: repoExposure?.term?.repurchaseTimestamp,
            repaymentTimestamp: repoExposure?.term?.endOfRepurchaseWindow,
            redemptionTimestamp: repoExposure?.term?.redemptionTimestamp,

            repoCollateralManager:
              repoExposure?.term?.termRepoCollateralManager,
            repoRolloverManager: repoExposure?.term?.termRepoRolloverManager,
            repoServicer: repoExposure?.term?.termRepoServicer,
            repoLocker: repoExposure?.term?.termRepoLocker,

            purchasePrice: bigToFixedNumber(
              repoExposure?.purchasePrice,
              purchaseCurrency?.decimals ?? 18
            ),
            repurchasePrice: bigToFixedNumber(
              repoExposure?.repurchasePrice,
              purchaseCurrency?.decimals ?? 18
            ),
            borrowBalance: bigToFixedNumber(
              repoExposure?.repoExposure,
              purchaseCurrency?.decimals ?? 18
            ),
            // TODO: wire up for multiple collateral currency/balance support later
            collateralBalances: {
              [primaryCollateralToken]: bigToFixedNumber(
                relevantRepoCollaterals[0].amountLocked,
                collateralCurrency?.decimals ?? 18
              ),
            },

            // TODO: Support multiple collateral tokens.
            maintenanceMarginRatio: bigToFixedNumber(
              BigNumber.from(
                repoExposure?.term.collateralRatios?.[0]?.maintenanceRatio?.toString() ??
                  '0'
              ),
              18
            ),

            collateralCurrency: primaryCollateralToken,
            purchaseCurrency: repoExposure?.term?.purchaseToken,
            termTokenCurrency: repoExposure?.term?.termRepoToken,
            amountCollected: bigToFixedNumber(
              repoExposure?.amountCollected,
              purchaseCurrency?.decimals ?? 18
            ),
            amountLiquidated: bigToFixedNumber(
              repoExposure?.amountCoveredInLiquidation,
              purchaseCurrency?.decimals ?? 18
            ),

            termId: repoExposure.term?.id,
          }
          if (
            repoExposure?.liquidations &&
            repoExposure?.liquidations.length > 0
          ) {
            const sortedLiquidations = repoExposure?.liquidations.sort(
              (a, b) => b.timestamp - a.timestamp
            )
            borrowsMap[repoExposure.term.id].lastLiquidationTxHash =
              sortedLiquidations[0].transactionHash

            const borrowLiquidations: LiquidationForDisplay[] =
              sortedLiquidations.map((item) => ({
                timestamp: item.timestamp,
                transactionHash: item.transactionHash,
                collateralTokensLiquidated: bigToFixedNumber(
                  item.amountLiquidated,
                  collateralCurrency?.decimals ?? 18
                ),
                borrowTokenSymbol: purchaseCurrency.symbol,
                collateralTokenSymbol: collateralCurrency.symbol,
                loanCovered: bigToFixedNumber(
                  item.coverAmount,
                  purchaseCurrency?.decimals ?? 18
                ),
                loanRemaining: bigToFixedNumber(
                  item.repoExposureAfterLiquidation,
                  purchaseCurrency?.decimals ?? 18
                ),
                defaultLiquidation: item.defaultLiquidation,
              }))
            borrowsMap[repoExposure.term.id].liquidations = borrowLiquidations
          }
        } else {
          borrowsMap[repoExposure.term.id].collateralBalances[
            primaryCollateralToken
          ] = bigToFixedNumber(
            relevantRepoCollaterals[0].amountLocked,
            collateralCurrency?.decimals ?? 18
          )
        }
      })

      pagePortfolioQuery.termRolloverInstructions.forEach(
        (rolloverInstruction) => {
          if (borrowsMap[rolloverInstruction.oldTerm.id]) {
            const purchaseCurrency =
              currencies?.[chainId]?.[rolloverInstruction.oldTerm.purchaseToken]

            borrowsMap[rolloverInstruction.oldTerm.id].rolloverAmount =
              bigToFixedNumber(
                rolloverInstruction.cancelled
                  ? BigNumber.from(0)
                  : rolloverInstruction.rolloverAmount,
                purchaseCurrency?.decimals ?? 18
              )
            borrowsMap[rolloverInstruction.oldTerm.id].rolloverFulfilled =
              rolloverInstruction.fulfilled
            borrowsMap[rolloverInstruction.oldTerm.id].rolloverFulfilledAmount =
              bigToFixedNumber(
                rolloverInstruction.amountFulfilled,
                purchaseCurrency?.decimals ?? 18
              )
            borrowsMap[rolloverInstruction.oldTerm.id].rolloverLocked =
              rolloverInstruction.locked
            borrowsMap[rolloverInstruction.oldTerm.id].rolloverCancelled =
              rolloverInstruction.cancelled || false
            borrowsMap[
              rolloverInstruction.oldTerm.id
            ].rolloverAuctionBidLocker = rolloverInstruction.rolloverAuction
            // TODO: review the logic here
            borrowsMap[rolloverInstruction.oldTerm.id].rolloverInterestRate =
              localResult?.[rolloverInstruction.newTerm.id]?.interestRate
          }
        }
      )
    })

    return Object.values(borrowsMap)
  }, [data, currencies, localResult])

  return results
}
