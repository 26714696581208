import { evaluate } from '../../helpers/math'
import { FixedNumber } from 'ethers'

export const getRequiredMargin = (
  amount: FixedNumber,
  initialMarginRatio: FixedNumber,
  purchaseTokenPrice: FixedNumber,
  collateralTokenPrice: FixedNumber
): {
  requiredMargin: FixedNumber
  requiredMarginUsd: FixedNumber
} => {
  const requiredMarginUsd = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: amount,
        },
        {
          nodeKind: 'value',
          value: initialMarginRatio,
        },
        {
          nodeKind: 'value',
          value: purchaseTokenPrice,
        },
      ],
    },
    initialMarginRatio.format.decimals
  )

  const requiredMargin = evaluate(
    {
      nodeKind: 'div',
      args: [
        {
          nodeKind: 'value',
          value: requiredMarginUsd,
        },
        {
          nodeKind: 'value',
          value: collateralTokenPrice,
        },
      ],
    },
    collateralTokenPrice.format.decimals
  )

  return {
    requiredMargin,
    requiredMarginUsd,
  }
}
