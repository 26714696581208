import { Box, Circle, Flex, Text } from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'

const Step = ({
  variant = 'outline',
  step,
  currentStep = 0,
  isLastStep,
  totalSteps,
}: {
  variant: 'solid' | 'outline'
  step: number
  currentStep?: number
  isLastStep: boolean
  totalSteps: number
}) => (
  <>
    <Box textAlign="center">
      <Circle size="12px" bg="#D9D9D9">
        {step <= currentStep && (
          <Circle size={variant === 'solid' ? '12px' : '6px'} bg="blue.500" />
        )}
      </Circle>
    </Box>
    {!isLastStep && (
      <Box
        border="1px solid"
        borderColor={step < currentStep ? 'blue.500' : '#D9D9D9'}
        borderWidth={totalSteps < 15 ? '2px' : '1px'}
        flexGrow="1"
      />
    )}
  </>
)

export default function ProgressLine({
  variant = 'outline',
  totalSteps,
  currentStep = 0,
  label,
  tooltip,
}: {
  variant?: 'solid' | 'outline'
  totalSteps: number
  currentStep?: number
  label?: string
  tooltip?: string
}) {
  totalSteps = totalSteps > 15 ? 2 : totalSteps
  return (
    <VStack w="full" align="start" h="43px" cursor="default">
      {tooltip ? (
        <Tooltip noDelay label={tooltip}>
          <Text variant="body-xs/medium" color="blue.9" opacity="0.5">
            {label}
          </Text>
        </Tooltip>
      ) : (
        <Text variant="body-xs/medium" color="blue.9" opacity="0.5">
          {label}
        </Text>
      )}
      <Flex justify="space-between" align="center" w="full">
        {[...Array(totalSteps)].map((_, step) => (
          <Step
            key={step}
            variant={variant}
            step={step}
            currentStep={currentStep - 1}
            isLastStep={step === totalSteps - 1}
            totalSteps={totalSteps}
          />
        ))}
      </Flex>
    </VStack>
  )
}
