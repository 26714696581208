import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  Text,
} from '@chakra-ui/react'
import { HStack } from '../../elements/Stack'
import { ChainId } from '@usedapp/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../../../components/elements/Tooltip'
import { Wallet } from '../../../data/model'
import HelpMenuPopout from '../../elements/HelpMenuPopout'
import ProfileMenuPopout from '../../elements/ProfileMenuPopout'

import invalidIcon from '../../../assets/icons/network/Invalid.svg'
import { networkIcons } from '../../../helpers/networkIcons'
import { isValidNetwork as checkIsValidNetwork } from '../../../helpers/networks'

import { useConfig } from '../../../providers/config'

export const RightNav = ({
  wallet,
  chainId,
  account,
  onConnectModalOpen,
  onSwitchNetworkModalOpen,
  onClaimTestTokens,
  onDisconnect,
  onLogoutProfile,
  onManageProfile,
  hasNotification,
}: {
  wallet: Wallet
  chainId: ChainId | undefined
  account: string | undefined
  onConnectModalOpen: () => void
  onSwitchNetworkModalOpen: () => void
  onClaimTestTokens: () => void
  onDisconnect: () => void
  onLogoutProfile?: () => void
  onManageProfile: () => void
  hasNotification?: boolean
}) => {
  const config = useConfig()
  const chain = chainId ? config.chains[chainId] : undefined
  const isValidNetwork = checkIsValidNetwork(config.isMainnet, chainId)

  return (
    <HStack as="div" display="flex" spacing="28px">
      <Menu>
        <MenuButton
          as={Button}
          variant="without-padding"
          border="none"
          opacity={0.8}
        >
          <Text color="blue.8" as="span" variant="body-sm/medium">
            Help
          </Text>
          <Box color="blue.8" as="span">
            <FontAwesomeIcon
              icon={['far', 'chevron-down']}
              role="button"
              width="12px"
              style={{
                marginLeft: '7px',
              }}
            />
          </Box>
        </MenuButton>
        <HelpMenuPopout />
      </Menu>
      {wallet.status === 'connected' ? (
        <Box>
          {' '}
          {/* [Dospore] Required for console warnings https://github.com/chakra-ui/chakra-ui/issues/3440 */}
          <Menu>
            <MenuButton
              as={Button}
              leftIcon={
                chainId && isValidNetwork ? (
                  <Image src={networkIcons[chainId]} w="16px" h="16px" />
                ) : (
                  <Tooltip
                    label="Your wallet is connected to an unsupported network."
                    placement="bottom-end"
                    offset={[6, 6]}
                  >
                    <Image src={invalidIcon} w="16px" h="16px" />
                  </Tooltip>
                )
              }
              variant="without-padding"
              border="none"
              opacity={0.8}
            >
              <HStack>
                <Box position="relative">
                  <Text
                    as="span"
                    variant="body-sm/medium"
                    color="blue.8"
                    opacity="0.8"
                  >
                    {wallet.accountShort}
                  </Text>
                  {hasNotification && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      as="span"
                      bg="red.5"
                      borderRadius="9999px"
                      w={4}
                      h={4}
                      position="absolute"
                      top={-2}
                      right={-2}
                      pointerEvents="none"
                    >
                      <Text as="span" variant="body-xs/medium" color="white">
                        1
                      </Text>
                    </Flex>
                  )}
                </Box>
                <Box color="blue.8" as="span">
                  <FontAwesomeIcon
                    icon={['far', 'chevron-down']}
                    role="button"
                    width="12px"
                  />
                </Box>
              </HStack>
            </MenuButton>
            <ProfileMenuPopout
              chainId={chainId}
              isMainnet={config.isMainnet}
              isValidNetwork={isValidNetwork}
              walletAddress={account}
              onClaimTestTokens={onClaimTestTokens}
              onDisconnect={onDisconnect}
              onLogoutProfile={onLogoutProfile}
              onManageProfile={onManageProfile}
              hasNotification={hasNotification}
              onSwitchNetwork={onSwitchNetworkModalOpen}
              getExplorerAddressLink={chain?.getExplorerAddressLink}
            />
          </Menu>
        </Box>
      ) : (
        <Button variant="primary" onClick={onConnectModalOpen}>
          Connect
        </Button>
      )}
    </HStack>
  )
}
