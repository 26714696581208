import { Box } from '@chakra-ui/react'

type Props = {
  color?: string
}

const ColouredBox = ({ color }: Props) => (
  <Box bg={color} w="8px" h="8px" borderRadius={2} />
)

export default ColouredBox
