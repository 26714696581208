import React from 'react'
import { Text, Button } from '@chakra-ui/react'
import ReferralCode from '../ReferralCode'
import TermToast from '../TermToast'
import { WarningIcon } from '../TermToast'
import { VStack, HStack } from '../Stack'

type Props = {
  connectedAddress: string
  referralAddress: string
  onRemove: () => void
}

export const ReferralCodeIsUser: React.FC<Props> = ({
  connectedAddress,
  referralAddress,
  onRemove,
}) => (
  <TermToast
    title={'Invalid referral address'}
    duration={null}
    icon={<WarningIcon />}
    children={
      <VStack alignItems="start" spacing="24px">
        <Text color="gray.6" variant="body-sm/normal">
          Using your connected wallet's address as a referral is not possible.
          To proceed, remove the address, switch your wallet, or use a different
          referral link.
        </Text>
        <VStack spacing="4px" w="full">
          <HStack w="full">
            <HStack pr="16px" w="full">
              <Text as="span" color="gray.6" variant="body-sm/medium">
                Connected Address
              </Text>
            </HStack>
            <HStack ml="auto">
              <ReferralCode
                referralCode={connectedAddress}
                variant="body-sm/medium"
              />
            </HStack>
          </HStack>
          <HStack w="full" mt="0px!important">
            <HStack pr="16px" w="full">
              <Text as="span" color="gray.6" variant="body-sm/medium">
                Referral Address
              </Text>
            </HStack>
            <HStack ml="auto">
              <ReferralCode
                referralCode={referralAddress}
                variant="body-sm/medium"
              />
            </HStack>
          </HStack>
        </VStack>
        <HStack>
          <Button size="sm" variant="tertiary" onClick={onRemove}>
            <Text variant="body-sm/medium">Remove address</Text>
          </Button>
        </HStack>
      </VStack>
    }
  />
)

export default ReferralCodeIsUser
