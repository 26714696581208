/**
 * A private database of tenders.
 *
 * Ideally, by private, we mean only accessible to the current user authenticated with the
 * current wallet.
 *
 * For now, we'll just use localstorage, so it'll be private to the user/device, but not really
 * private to a current wallet.
 *
 * TODO: Find a secure way to store private tenders.
 *
 * Why does this exist?
 *
 * When tenders are created, their information is public because they end up on the blockchain.
 * But to maintain integrity of the auction, tenders need to be private. Therefore, details
 * of each tender are hashed before stored on the blockchain.
 *
 * To allow the current user/wallet to see bids they have previously submitted, we'll need
 * to store the unhashed values here.
 */

import { captureException } from '@sentry/react'
import { BigNumber, FixedNumber } from 'ethers'
import { SubmittedBorrowTender, SubmittedLoanTender } from '../data/model'

type JSONBigNumber = {
  hex: string
  type: 'BigNumber'
}

export function serializeLoanTender(loan: SubmittedLoanTender) {
  // interestRate is a FixedNumber, which is not serializable to JSON.
  const modifiedLoan = {
    ...loan,
    interestRate: loan?.interestRate?.toString(),
    amount: {
      ...loan.amount,
      shiftedValue: loan.amount.shiftedValue.toJSON(),
    },
    nonce: loan.nonce.toJSON(),
  }
  return JSON.stringify(modifiedLoan)
}

export function deserializeLoanTender(serializedLoan: string) {
  try {
    const loan: SubmittedLoanTender & { interestRate: string | number } =
      JSON.parse(serializedLoan)
    // interestRate is a FixedNumber, which is not serializable to JSON.
    const result = {
      ...loan,
      interestRate: loan.interestRate
        ? FixedNumber.fromString(loan.interestRate.toString())
        : undefined,
    } as SubmittedLoanTender

    // Patch the deserialization of BigNumbers from JSON.
    result.amount.shiftedValue = BigNumber.from(
      (result.amount.shiftedValue as any).hex
    )
    if (result.nonce) {
      result.nonce = BigNumber.from((result.nonce as any).hex)
    }

    return result
  } catch (err) {
    console.error('Failed to deserialize loan tender', serializedLoan, err)
    captureException(err)
    return undefined
  }
}

export function serializeBorrowTender(loan: SubmittedBorrowTender) {
  // interestRate is a FixedNumber, which is not serializable to JSON.
  const modifiedLoan = {
    ...loan,
    interestRate: loan.interestRate?.toString() as string,
    amount: {
      ...loan.amount,
      shiftedValue: loan.amount.shiftedValue.toJSON(),
    },
    collateral: {
      ...loan.collateral,
      shiftedValue: loan.collateral.shiftedValue.toJSON(),
    },
    nonce: loan.nonce.toJSON(),
  }
  return JSON.stringify(modifiedLoan)
}

export function deserializeBorrowTender(serializedLoan: string) {
  try {
    const loan: SubmittedBorrowTender & { interestRate: string | number } =
      JSON.parse(serializedLoan)
    // interestRate is a FixedNumber, which is not serializable to JSON.
    const result = {
      ...loan,
      interestRate: loan.interestRate
        ? FixedNumber.fromString(loan.interestRate.toString())
        : undefined,
    } as SubmittedBorrowTender

    // Patch the deserialization of BigNumbers from JSON.
    result.amount.shiftedValue = BigNumber.from(
      (result.amount.shiftedValue as any as JSONBigNumber).hex
    )
    result.collateral.shiftedValue = BigNumber.from(
      (result.collateral.shiftedValue as any as JSONBigNumber).hex
    )
    if (result.nonce) {
      result.nonce = BigNumber.from((result.nonce as any as JSONBigNumber).hex)
    }

    return result
  } catch (err) {
    console.error('Failed to deserialize borrow tender', serializedLoan, err)
    captureException(err)
    return undefined
  }
}

export async function getPrivateLoanTender(
  id: string,
  localStorage: Storage = global.localStorage
): Promise<SubmittedLoanTender> {
  const item = localStorage.getItem('loan-' + id)
  if (item === null) {
    throw new Error('Private loan ' + id + ' not found')
  }
  const loan: SubmittedLoanTender | undefined = deserializeLoanTender(item)
  if (loan === undefined) {
    throw new Error('Private loan ' + id + ' not found')
  }

  // TODO: Validate that the tender has its required fields set

  return loan
}

export async function createOrUpdatePrivateLoanTender(
  loan: SubmittedLoanTender,
  localStorage: Storage = global.localStorage
): Promise<void> {
  localStorage.setItem('loan-' + loan.id, serializeLoanTender(loan))
}

export async function getPrivateBorrowTender(
  id: string,
  localStorage: Storage = global.localStorage
): Promise<SubmittedBorrowTender> {
  const item = localStorage.getItem('borrow-' + id)
  if (item === null) {
    throw new Error('Private borrow ' + id + ' not found')
  }
  const borrow: SubmittedBorrowTender | undefined =
    deserializeBorrowTender(item)
  if (borrow === undefined) {
    throw new Error('Private borrow ' + id + ' not found')
  }

  // TODO: Validate that the tender has its required fields set

  return borrow
}

export async function createOrUpdatePrivateBorrowTender(
  borrow: SubmittedBorrowTender,
  localStorage: Storage = global.localStorage
): Promise<void> {
  localStorage.setItem('borrow-' + borrow.id, serializeBorrowTender(borrow))
}
