import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  useOutsideClick,
} from '@chakra-ui/react'
import { ReactNode, useRef, useState } from 'react'

import { HStack, VStack } from '../../../../components/elements/Stack'
import { parseUserInput } from '../../../../helpers/utils'
import RatesHelper from '../../../../components/elements/RatesHelper'
import { SuggestedRatesByPlatform } from '../../../../data/model'
import Chip from '../../../../components/elements/Chip'

const CreateTenderField = ({
  isInterestRate,
  label,
  type = 'text',
  placeholder,
  rightElement,
  superElement,
  infoElement,
  errorElement,
  warningElement,
  tooltip,
  leftElement,
  helperText,
  value,
  tokenDecimals,
  onChange,
  disabled,
  w,
  rightElementTooltip,
  isError,
  variant,
  rateSuggestions,
}: {
  isInterestRate?: boolean
  label: string
  type?: string
  placeholder?: string
  rightElement?: string | ReactNode
  leftElement?: string | ReactNode
  superElement?: ReactNode
  infoElement?: ReactNode
  errorElement?: ReactNode
  warningElement?: ReactNode
  tooltip?: ReactNode
  helperText?: ReactNode
  value: string
  tokenDecimals: number
  onChange: (value: string) => void
  disabled?: boolean
  w?: FormControlProps['w']
  rightElementTooltip?: string
  isError?: boolean
  variant?: 'supply' | 'borrow'
  rateSuggestions?: SuggestedRatesByPlatform
}) => {
  const [isRatesHelperOpen, setIsRatesHelperOpen] = useState(false)

  const handleRateSelection = (item: string) => {
    if (isInterestRate && rateSuggestions) {
      onChange(item)
      setIsRatesHelperOpen(false)
    }
  }

  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: ref,
    handler: () => setIsRatesHelperOpen(false),
  })

  return (
    <FormControl as="fieldset" w={w} flexShrink={0}>
      <VStack spacing={2} align="start">
        <HStack
          justify="space-between"
          alignItems="flex-start"
          alignSelf="stretch"
          height="32px"
        >
          <HStack alignItems="center">
            <FormLabel
              color="gray.6"
              as="legend"
              fontWeight={500}
              fontSize="sm"
              whiteSpace="nowrap"
              variant="create-tender"
              mb={0}
              mr="0 !important"
            >
              {label}
            </FormLabel>
            {tooltip && tooltip}
          </HStack>
          {helperText && (
            <FormHelperText
              whiteSpace="nowrap"
              color="gray.5"
              fontSize="xs"
              lineHeight="4"
              mt={0}
            >
              {helperText}
            </FormHelperText>
          )}
        </HStack>
        <Box ref={ref} position="relative" w="100%">
          <InputGroup w="100%">
            {leftElement && (
              <InputLeftElement
                pointerEvents="none"
                color="gray.500"
                w={typeof leftElement === 'string' ? 'fit-content' : '20px'}
                children={leftElement}
                fontSize="sm"
              />
            )}
            <Input
              aria-label={label}
              value={value}
              type={type}
              variant="flushed"
              isDisabled={disabled}
              placeholder={placeholder}
              pl={leftElement ? '10px' : '4px'}
              pr="2px"
              border={isError ? '1px solid' : undefined}
              borderColor={isError ? 'red.5' : undefined}
              _focus={{
                border: isError ? '1px solid' : undefined,
                borderColor: isError ? 'red.5' : 'blue.5',
              }}
              onChange={(ev) =>
                onChange(parseUserInput(ev.target.value, true, tokenDecimals))
              }
              onFocus={() =>
                isInterestRate &&
                rateSuggestions &&
                Object.keys(rateSuggestions).length > 0 &&
                setIsRatesHelperOpen(true)
              }
            />
            {rightElement && (
              <InputRightElement
                top="50%"
                transform="translateY(-50%) translateX(-2px)"
                pointerEvents="auto"
                color="gray.6"
                bg="gray.2"
                height="20px"
                borderRadius="6px"
                children={
                  <Chip size="sm" tooltip={rightElementTooltip}>
                    {rightElement}
                  </Chip>
                }
                fontWeight={500}
                fontSize="xs"
                w="fit-content"
              />
            )}
          </InputGroup>
          {isInterestRate && variant && rateSuggestions ? (
            <RatesHelper
              variant={variant}
              rateSuggestions={rateSuggestions}
              isOpen={isRatesHelperOpen}
              handleSelection={handleRateSelection}
            />
          ) : undefined}
        </Box>
        {(superElement || infoElement || warningElement || errorElement) && (
          <HStack w="100%" pr="8px">
            <Box
              textColor={
                errorElement
                  ? 'red.5'
                  : warningElement
                    ? 'orange.5'
                    : infoElement
                      ? 'gray.6'
                      : undefined
              }
              fontSize="xs"
            >
              {errorElement ?? warningElement ?? infoElement}
            </Box>
            <Spacer />
            {superElement}
          </HStack>
        )}
      </VStack>
    </FormControl>
  )
}

export default CreateTenderField
