import { Box } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { Cell, Label, Pie, PieChart, Tooltip as RTooltip } from 'recharts'
import { formatNumber } from '../../utils'
import { EarningsChartTooltip } from './EarningsChartTooltip'

const CustomTooltip = ({
  active,
  payload,
  totalPoints,
}: {
  active: boolean
  payload: any[]
  totalPoints: number
}) => {
  if (active && payload && payload.length) {
    return (
      <EarningsChartTooltip
        category={payload[0].payload.category}
        totalPoints={totalPoints}
        value={payload[0].value}
      />
    )
  }
  return null
}

export const EarningsChart = ({
  data,
  totalPoints,
}: {
  data: any
  totalPoints: number
}) => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [isMouseOver, setIsMouseOver] = useState(false)

  const throttle = (
    func: (...args: any[]) => void,
    delay: number
  ): ((...args: any[]) => void) => {
    let lastCall = 0
    return (...args: any[]) => {
      const now = new Date().getTime()
      return now - lastCall < delay
        ? undefined
        : ((lastCall = now), func(...args))
    }
  }

  const handleMouseOver = useCallback(
    throttle((event: React.MouseEvent) => {
      setCoordinates({ x: event.clientX, y: event.clientY })
      setIsMouseOver(true)
    }, 500),
    []
  )

  const handleMouseOut = () => {
    setIsMouseOver(false)
    setCoordinates({ x: 0, y: 0 })
  }

  return (
    <Box onMouseOut={handleMouseOut}>
      <PieChart width={92} height={92}>
        <Pie
          data={data}
          innerRadius={37}
          outerRadius={45}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
          stroke="none"
        >
          {data.map((item: { color: string | undefined }, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={item.color}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            />
          ))}
          <Label
            value={formatNumber(totalPoints, true, 1, false, true)}
            position="center"
            color="white"
            style={{ fontSize: '20px', fontWeight: 'bold', fill: 'white' }}
          />
        </Pie>
        {isMouseOver && (
          <RTooltip
            wrapperStyle={{
              visibility: 'visible',
              position: 'fixed',
              left: coordinates.x - 16,
              top: coordinates.y,
            }}
            content={
              <CustomTooltip
                totalPoints={totalPoints}
                active={false}
                payload={[]}
              />
            }
          />
        )}
      </PieChart>
    </Box>
  )
}

export default EarningsChart
