import { Box, useDisclosure } from '@chakra-ui/react'
import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk'
import {
  captureException,
  setUser,
  withSentryReactRouterV6Routing,
} from '@sentry/react'
import { ChainId, shortenAddress, useEthers } from '@usedapp/core'
import { FixedNumber, Signer } from 'ethers'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga4'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import AccessViaDesktopOverlay from './components/elements/AccessViaDesktopOverlay'
import BlueSheetsAnnouncementBanner from './components/elements/BlueSheetsAnnouncementBanner'
import FaucetModal, {
  alreadyClaimedStorageKey,
  alreadyClaimedStorageValue,
} from './components/elements/FaucetModal'
import KytDeniedModal from './components/elements/KytCheckDenied'
import PageNotFound from './components/elements/PageNotFound'
import RewardsAnnouncementModal from './components/elements/RewardsAnnouncementModal'
import SwitchNetworkModal from './components/elements/SwitchNetworkModal'
import ToSModal from './components/elements/ToSModal'
import { ToSPanelId } from './components/elements/ToSModal/modal'
import VaultBanner from './components/elements/VaultBanner'
import Footer from './components/layout/Footer'
import Navbar from './components/layout/Navbar'
import { fetchKytStatus } from './data/hooks/helper-hooks'
import { useTestnetTokens } from './data/hooks/use-testnet-tokens'
import {
  Address,
  Balance,
  DiscordOAuthCallback,
  TwitterOAuthCallback,
  Wallet,
} from './data/model'
import { setupEIP6963OnPageLoad } from './helpers/eip6963'
import { watchAsset } from './helpers/eip747'
import { isRenderedInSafeApp } from './helpers/methods'
import { isSupportedTestnet } from './helpers/networks'
import { useAuctionPage } from './hooks/auction'
import { useAuctionsPage } from './hooks/auctions'
import { OAuth2CallbackPage } from './hooks/helpers/oauth'
import { useReferralCodes } from './hooks/helpers/referral'
import { useLendPage } from './hooks/lend'
import { usePortfolioPage } from './hooks/portfolio'
import { useRewardsPage } from './hooks/rewards'
import { useVaultPage } from './hooks/vault'
import { RewardsPageParams } from './models/rewards'
import AuctionPage from './pages/Auction'
import AuctionsPage from './pages/Auctions'
import LendPage from './pages/Lend'
import PortfolioPage from './pages/Portfolio'
import RewardsPage from './pages/Rewards'
import ProfileManagement from './pages/Rewards/elements/ProfileManagement'
import VaultPage from './pages/Vault'
import VaultsPage from './pages/Vaults'
import { useConfig } from './providers/config'
import { FiltersProvider } from './providers/filters'
import { useStorage } from './providers/storage'

library.add(fal)
library.add(far)

// ============================================================================
// Wired up page components
// ============================================================================

function WiredUpAuctionsPage({
  provider,
  account,
}: {
  provider: JsonRpcProvider | FallbackProvider | undefined
  account: Address | undefined
}) {
  const params = useAuctionsPage(provider, account)
  return <AuctionsPage {...params} />
}
function WiredUpAuctionPage({
  provider,
  signer,
  account,
  activeNetwork,
  referralCode,
  onConnect,
  onCheckActiveNetwork,
  onKytCheck,
  onDeleteReferralCode,
}: {
  provider: JsonRpcProvider | FallbackProvider | undefined
  signer: Signer | undefined
  account: Address | undefined
  activeNetwork: ChainId | undefined
  referralCode: Address | undefined
  onConnect: () => void
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>
  onKytCheck: () => Promise<boolean>
  onDeleteReferralCode: () => void
  onSwitchNetwork: (chainId?: ChainId) => Promise<boolean>
}) {
  const params = useAuctionPage(
    activeNetwork,
    account,
    provider,
    signer,
    referralCode,
    onConnect,
    onCheckActiveNetwork,
    onKytCheck,
    onDeleteReferralCode
  )
  return <AuctionPage {...params} />
}

function WiredUpVaultPage({
  provider,
  signer,
  account,
  activeNetwork,
  referralCode,
  onConnect,
  onCheckActiveNetwork,
  onKytCheck,
  onDeleteReferralCode,
}: {
  provider: JsonRpcProvider | FallbackProvider | undefined
  signer: Signer | undefined
  account: Address | undefined
  activeNetwork: ChainId | undefined
  referralCode: Address | undefined
  onConnect: () => void
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>
  onKytCheck: () => Promise<boolean>
  onDeleteReferralCode: () => void
  onSwitchNetwork: (chainId?: ChainId) => Promise<boolean>
}) {
  const params = useVaultPage(
    activeNetwork,
    account,
    provider,
    signer,
    referralCode,
    onConnect,
    onCheckActiveNetwork,
    onKytCheck,
    onDeleteReferralCode
  )
  return <VaultPage {...params} />
}

function WiredUpVaultsPage() {
  return <VaultsPage currencies={undefined} vaultsData={undefined} />
}

function WiredUpPortfolioPage({
  provider,
  signer,
  account,
  onCheckActiveNetwork,
  onKytCheck,
}: {
  provider: JsonRpcProvider | FallbackProvider | undefined
  signer: Signer | undefined
  account: string | undefined
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>
  onKytCheck: () => Promise<boolean>
}) {
  const params = usePortfolioPage(
    account,
    provider,
    signer,
    onCheckActiveNetwork,
    onKytCheck
  )
  return <PortfolioPage {...params} />
}

function WiredUpLendPage({
  provider,
  signer,
  account,
  onConnect,
  onCheckActiveNetwork,
  onKytCheck,
}: {
  provider: JsonRpcProvider | FallbackProvider | undefined
  signer: Signer | undefined
  account: string | undefined
  onConnect: () => void
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>
  onKytCheck: () => Promise<boolean>
}) {
  const params = useLendPage(
    account,
    provider,
    signer,
    onConnect,
    onCheckActiveNetwork,
    onKytCheck
  )
  return <LendPage {...params} />
}

function WiredUpRewardsPage({ params }: { params: RewardsPageParams }) {
  return <RewardsPage {...params} />
}

function WiredUpProfileManagementPage({
  params,
  location,
  provider,
  connectedWallet,
}: {
  params: RewardsPageParams
  location: any
  provider: JsonRpcProvider | FallbackProvider | undefined
  connectedWallet: string
}) {
  return (
    <ProfileManagement
      username={params.profileName}
      profileImageUrl={params.profileImageUrl}
      wallets={params.fullProfile?.wallets.map((wallet: any) => ({
        address: wallet.address,
        meta: {
          isContractAccount: wallet.meta.isContractAccount,
        },
        isPrimary: wallet.isPrimary,
        createdAt: wallet.createdAt,
      }))}
      socials={{
        twitter: params.fullProfile?.profile.twitterId ?? undefined,
        discord: params.fullProfile?.profile.discordId ?? undefined,
      }}
      onLinkExtraWallet={params.onLinkExtraWallet}
      onLinkDiscord={params.onLinkDiscord!!}
      onLinkTwitter={params.onLinkTwitter!!}
      onLinkMultiSigWallet={params.onLinkMultiSigWallet!!}
      onRemoveWallet={params.onRemoveWallet}
      onRemoveDiscord={params.onRemoveDiscord}
      onRemoveTwitter={params.onRemoveTwitter}
      onDeleteProfile={params.onDeleteProfile}
      redirectPath={location.state?.previousPage ?? '/rewards'}
      connectedWallet={connectedWallet}
      provider={provider}
    />
  )
}

// ============================================================================
// TermApp
// ============================================================================

export const termJWTLocalStorageKey = 'termJWT'

export default function TermApp() {
  const {
    activate,
    activateBrowserWallet,
    account: baseAccount,
    library: provider,
    deactivate,
    switchNetwork,
    error,
  } = useEthers()

  const config = useConfig()
  const { storage } = useStorage()

  const { connected: isSafeConnected } = useSafeAppsSDK()
  const [searchParams] = useSearchParams()
  const overrideAccount = searchParams.get('address')
  const account = overrideAccount ?? baseAccount

  const [twitterData, setTwitterData] = useState<TwitterOAuthCallback>({
    code: undefined,
    verifier: 'challenge',
    status: undefined,
    action: undefined,
    refName: undefined,
    refImage: undefined,
  })

  const [discordData, setDiscordData] = useState<DiscordOAuthCallback>({
    token: undefined,
    status: undefined,
    action: undefined,
    refName: undefined,
    refImage: undefined,
  })

  // const [multiSigWallet, setMultiSigWallet] = useState<string | undefined>(
  //   undefined
  // )
  const location = useLocation()

  useEffect(() => {
    setupEIP6963OnPageLoad()
  }, [])

  useEffect(() => {
    Object.entries(config.provider.connectors ?? {}).forEach(
      ([name, connector]) => {
        if (name === 'safe' && isRenderedInSafeApp()) {
          try {
            console.info('activating safe connector...')
            connector.activate()
          } catch (error) {
            console.error(`Failed to activate ${name} connector:`, error)
            captureException(error)
          }
        }
      }
    )
  }, [activate, config.provider])

  useEffect(() => {
    if (isSafeConnected && config.provider.connectors?.safe?.provider) {
      console.info('gnosis safe connection made, activating through useDapp...')
      try {
        activate(config.provider.connectors.safe.provider)
      } catch (error) {
        console.error('Failed to activate safe provider:', error)
        captureException(error)
      }
    }
  }, [isSafeConnected, config, activate])

  useEffect(() => {
    console.log('config', config)
  }, [config])

  const [walletActivationError, setWalletActivationError] = useState<
    string | undefined
  >('')

  // Record user wallet address with sentry errors.
  useEffect(() => {
    setUser(account ? { id: account } : null)
  }, [account])

  useEffect(() => {
    setWalletActivationError(error?.message)
  }, [error?.message])

  // Record user wallet address for google analytics.
  useEffect(() => {
    try {
      ReactGA.set({ user_id: account })
    } catch {
      // This is likely not of concern.
      console.warn(
        `Failed to set user_id for google analytics. Account: ${account}`
      )
    }
  }, [account])
  // Record page navigation events for google analytics after first load.
  useEffect(() => {
    try {
      ReactGA.send({ hitType: 'pageview', path: location.pathname })
    } catch (e) {
      console.warn(`Failed to send pageview event`, e)
    }
  }, [location.pathname])

  const { referralCode, deleteReferralCode } = useReferralCodes(account)

  // Just get the testnet token data for the active chain
  const chainTestnetTokens = useMemo(() => {
    const activeChainId = provider?.network?.chainId
    if (!activeChainId) return undefined

    const activeChainIdStr = activeChainId.toString()
    const tokens = config.chains[activeChainIdStr]?.testnetTokens
    return tokens ? { [activeChainIdStr]: tokens } : undefined
  }, [config.chains, provider?.network?.chainId])

  // Get information about testnet tokens.
  const {
    testnetTokenBalances,
    ticketsRemaining,
    claimsMade,
    mintAmounts,
    mintTestnetTokens,
  } = useTestnetTokens(account, chainTestnetTokens, provider)

  const onImportTestnetToken = useCallback(
    async (
      chainId: string,
      address: string,
      symbol: string,
      decimals: number
    ) => {
      if (!provider) {
        return false
      }
      return await watchAsset(
        chainId,
        provider as JsonRpcProvider,
        address,
        symbol,
        decimals
      )
    },
    [provider]
  )

  const wallet: Wallet = useMemo(
    () =>
      account === undefined || provider === undefined
        ? {
            status: 'not-connected',
            onConnectMetamask: () =>
              activateBrowserWallet({ type: 'metamask' }),
            onConnectWalletconnect: () =>
              activateBrowserWallet({ type: 'walletConnectV2' }),
            onConnectCoinbase: () =>
              activateBrowserWallet({ type: 'coinbase' }),
          }
        : {
            status: 'connected',
            accountShort: shortenAddress(account),
          },
    [account, activateBrowserWallet, provider]
  )
  const signer = useMemo(
    () =>
      !account || !provider
        ? undefined
        : (provider as JsonRpcProvider).getSigner(),
    [account, provider]
  )

  // useCallback(async () => {
  //   if (
  //     account !== undefined &&
  //     wallet.status === 'connected' &&
  //     (await provider?.getCode(account)) !== '0x'
  //   ) {
  //     setMultiSigWallet(account)
  //   }
  // }, [wallet, account, provider])

  const {
    isOpen: isFaucetModalOpen,
    onOpen: onFaucetModalOpen,
    onClose: onFaucetModalClose,
  } = useDisclosure()
  const {
    isOpen: isConnectModalOpen,
    onOpen: onConnectModalOpen,
    onClose: onConnectModalClose,
  } = useDisclosure()

  const {
    isOpen: isSwitchNetworkModalOpen,
    onOpen: onSwitchNetworkModalOpen,
    onClose: onSwitchNetworkModalClose,
  } = useDisclosure()

  const openFaucetModal = useCallback(() => {
    if (!isSupportedTestnet(provider?.network?.chainId)) {
      onSwitchNetworkModalOpen()
    } else {
      onFaucetModalOpen()
    }
  }, [provider?.network?.chainId, onSwitchNetworkModalOpen, onFaucetModalOpen])

  const onConnectClose = useCallback(() => {
    onConnectModalClose()
    const alreadyClaimed =
      storage.getItem(alreadyClaimedStorageKey) === alreadyClaimedStorageValue

    if (
      !alreadyClaimed &&
      wallet?.status === 'connected' &&
      !config.isMainnet
    ) {
      openFaucetModal()
    }
  }, [
    onConnectModalClose,
    openFaucetModal,
    storage,
    wallet?.status,
    config.isMainnet,
  ])

  const onSwitchClose = useCallback(
    (switchedNetwork?: ChainId | undefined) => {
      onSwitchNetworkModalClose()
      const alreadyClaimed =
        storage.getItem(alreadyClaimedStorageKey) === alreadyClaimedStorageValue

      // use config.isMainnet here since provider?.network?.chainId wont have changed since this is called back from switchClose
      if (
        !alreadyClaimed &&
        wallet?.status === 'connected' &&
        isSupportedTestnet(switchedNetwork)
      ) {
        onFaucetModalOpen()
      }
    },
    [onSwitchNetworkModalClose, onFaucetModalOpen, storage, wallet?.status]
  )

  useEffect(() => {}, [account, storage, wallet?.status])

  const {
    isOpen: isKytDeniedModalOpen,
    onOpen: onKytDeniedModalOpen,
    onClose: onKytDeniedModalClose,
  } = useDisclosure()

  const onKytCheck = async () => {
    const didPassKyt = await fetchKytStatus(
      account,
      config.guardianServerUrl,
      config.isMainnet
    )

    if (!didPassKyt) {
      console.error('Wallet fails AML/KYT check, disconnected')
      deactivate()
      onKytDeniedModalOpen()
      return false
    } else {
      return true
    }
  }

  const onSwitchNetwork = useCallback(
    async (chainId?: ChainId) => {
      if (chainId) {
        try {
          await switchNetwork(chainId)
          return true
        } catch (e) {
          console.error('failed to switch network', e)
          return false
        }
      } else {
        console.warn('no chain provided for switch network request')
        return false
      }
    },
    [switchNetwork]
  )

  const onCheckActiveNetwork = useCallback(
    async (chainId?: ChainId, chainName?: string) => {
      if (provider?.network && provider.network.chainId !== chainId) {
        console.info('switching active network to', chainName)
        return onSwitchNetwork(chainId)
      } else {
        return true
      }
    },
    [onSwitchNetwork, provider?.network]
  )

  const rewardsParams = useRewardsPage({
    account,
    signer,
    provider,
    twitterData,
    discordData,
    onConnect: onConnectModalOpen,
  })

  // TODO: Re-enable this after testnet
  // useEffect(() => {
  //   if (wallet.status === 'connected') {
  //     onConnectClose()
  //   }
  // }, [onConnectClose, wallet])

  return (
    <>
      <ParameterizedTermApp
        wallet={wallet}
        walletActivationError={walletActivationError}
        account={account}
        activeNetwork={provider?.network?.chainId}
        testnetTokenBalances={testnetTokenBalances}
        testnetTokenTicketsRemaining={ticketsRemaining}
        testnetTokenClaimsMade={claimsMade}
        mintingAmounts={mintAmounts}
        isConnectModalOpen={isConnectModalOpen}
        onConnectModalOpen={onConnectModalOpen}
        onConnectModalClose={onConnectClose}
        isFaucetModalOpen={isFaucetModalOpen}
        onFaucetModalOpen={openFaucetModal}
        onFaucetModalClose={onFaucetModalClose}
        isKytDeniedModalOpen={isKytDeniedModalOpen}
        onKytDeniedModalOpen={onKytDeniedModalOpen}
        onKytDeniedModalClose={onKytDeniedModalClose}
        onImportTestnetToken={onImportTestnetToken}
        onClaimTestnetTokens={mintTestnetTokens}
        onDisconnect={deactivate}
        onLogoutProfile={rewardsParams.onLogout}
        onSwitchNetwork={onSwitchNetwork}
        isSwitchNetworkModalOpen={isSwitchNetworkModalOpen}
        onSwitchNetworkModalOpen={onSwitchNetworkModalOpen}
        onSwitchNetworkModalClose={onSwitchClose}
        auctionsPage={
          <>
            <BlueSheetsAnnouncementBanner />
            <WiredUpAuctionsPage provider={provider} account={account} />
          </>
        }
        auctionPage={
          <WiredUpAuctionPage
            provider={provider}
            account={account}
            signer={signer}
            onCheckActiveNetwork={onCheckActiveNetwork}
            activeNetwork={provider?.network?.chainId}
            onConnect={onConnectModalOpen}
            onKytCheck={onKytCheck}
            referralCode={referralCode}
            onDeleteReferralCode={deleteReferralCode}
            onSwitchNetwork={onSwitchNetwork}
          />
        }
        vaultsPage={<WiredUpVaultsPage />}
        vaultPage={
          <>
            <VaultBanner />
            <WiredUpVaultPage
              provider={provider}
              account={account}
              signer={signer}
              onCheckActiveNetwork={onCheckActiveNetwork}
              activeNetwork={provider?.network?.chainId}
              onConnect={onConnectModalOpen}
              onKytCheck={onKytCheck}
              referralCode={referralCode}
              onDeleteReferralCode={deleteReferralCode}
              onSwitchNetwork={onSwitchNetwork}
            />
          </>
        }
        portfolioPage={
          <WiredUpPortfolioPage
            provider={provider}
            account={account}
            signer={signer}
            onKytCheck={onKytCheck}
            onCheckActiveNetwork={onCheckActiveNetwork}
          />
        }
        profileManagementPage={
          <WiredUpProfileManagementPage
            params={rewardsParams}
            location={location}
            provider={provider}
            connectedWallet={account!!}
          />
        }
        lendPage={
          <WiredUpLendPage
            provider={provider}
            account={account}
            signer={signer}
            onKytCheck={onKytCheck}
            onCheckActiveNetwork={onCheckActiveNetwork}
            onConnect={onConnectModalOpen}
          />
        }
        rewardsPage={<WiredUpRewardsPage params={rewardsParams} />}
        twitterCallbackPage={
          <OAuth2CallbackPage
            type="twitter"
            clientId={config.oauth.twitter.clientId}
            redirectUri={config.oauth.twitter.redirectUri}
            tokenUrl={config.oauth.twitter.tokenUrl}
            loading={<p>Loading...</p>}
            success={(token, path) => <Navigate to={path ?? '/rewards'} />} // This may need to redirect to the manage profile modal as well
            failure={(e, path) => <Navigate to={path ?? '/rewards'} />}
            onSuccess={(code, action, skipTwitter, refName, refImage) => {
              console.log('twitter onSuccess triggered')
              setTwitterData({
                code,
                verifier: 'challenge',
                status: 'success',
                action,
                refName,
                refImage,
              })
            }}
            onFailure={(error, action, skipTwitter, refName, refImage) => {
              console.log('twitter onFailure triggered for action: %o', action)
              setTwitterData({
                code: undefined,
                verifier: 'challenge',
                status: 'failure',
                action,
                refName,
                refImage,
              })
              console.error(error)
            }}
            codeVerifier={twitterData?.verifier}
          />
        }
        discordCallbackPage={
          <OAuth2CallbackPage
            type="discord"
            clientId={config.oauth.discord.clientId}
            redirectUri={config.oauth.discord.redirectUri}
            tokenUrl={config.oauth.discord.tokenUrl}
            loading={<p>Loading...</p>}
            success={(token, path) => {
              console.log('discord - navigating to redir path now..')
              return <Navigate to={path ?? '/rewards'} />
            }} // This may need to redirect to the manage profile modal as well
            failure={(e, path) => <Navigate to={path ?? '/rewards'} />}
            onSuccess={(token, action, skipTwitter, refName, refImage) => {
              console.log('success with discord token')
              setDiscordData({
                token,
                status: 'success',
                action,
                hasSkippedTwitter: skipTwitter,
                refName,
                refImage,
              })
            }}
            onFailure={(error, action, skipTwitter, refName, refImage) => {
              console.error(error)
              setDiscordData({
                token: undefined,
                status: 'failure',
                action,
                hasSkippedTwitter: skipTwitter,
                refName,
                refImage,
              })
            }}
          />
        }
      />
    </>
  )
}

// ============================================================================
// ParameterizedTermApp
// ============================================================================

// See: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
const SentryRoutes = withSentryReactRouterV6Routing(Routes)

/**
 * This component exists to be a "stupid" version of the app that doesn't depend
 * on any global context, data, or hooks, so that it can be easily mocked or placed
 * into a storybook to test/interact with in a prototype.
 */
export function ParameterizedTermApp({
  wallet,
  walletActivationError,
  account,
  activeNetwork,
  testnetTokenBalances,
  testnetTokenTicketsRemaining,
  testnetTokenClaimsMade,
  mintingAmounts,
  auctionsPage,
  auctionPage,
  vaultPage,
  vaultsPage,
  portfolioPage,
  profileManagementPage,
  lendPage,
  rewardsPage,
  twitterCallbackPage,
  discordCallbackPage,
  onClaimTestnetTokens,
  onImportTestnetToken,
  onSwitchNetwork,

  isConnectModalOpen,
  onConnectModalOpen,
  onConnectModalClose,

  isSwitchNetworkModalOpen,
  onSwitchNetworkModalOpen,
  onSwitchNetworkModalClose,

  isFaucetModalOpen,
  onFaucetModalOpen,
  onFaucetModalClose,

  isKytDeniedModalOpen,
  onKytDeniedModalClose,

  defaultConnectPanel,
  onDisconnect,
  onLogoutProfile,
}: {
  wallet: Wallet
  walletActivationError: string | undefined
  account: Address | undefined
  activeNetwork: ChainId | undefined
  testnetTokenBalances?: Balance[]
  testnetTokenTicketsRemaining?: number[]
  testnetTokenClaimsMade?: number[]
  mintingAmounts?: { [symbol: string]: FixedNumber }
  auctionsPage: ReactNode
  auctionPage: ReactNode
  vaultPage: ReactNode
  vaultsPage: ReactNode
  portfolioPage: ReactNode
  profileManagementPage: ReactNode
  lendPage: ReactNode
  rewardsPage: ReactNode
  twitterCallbackPage: ReactNode
  discordCallbackPage: ReactNode
  onClaimTestnetTokens?: (
    chainId: number,
    tokenAddress: Address
  ) => Promise<void>
  onImportTestnetToken?: (
    chainId: string,
    address: string,
    symbol: string,
    decimals: number
  ) => Promise<boolean>

  onSwitchNetwork?: (chainId?: ChainId) => Promise<boolean>

  isConnectModalOpen: boolean
  onConnectModalOpen: (panel?: ToSPanelId) => void
  onConnectModalClose: () => void

  isSwitchNetworkModalOpen: boolean
  onSwitchNetworkModalOpen: () => void
  onSwitchNetworkModalClose: (switchedNetwork?: ChainId) => void

  isFaucetModalOpen: boolean
  onFaucetModalOpen: (panel?: ToSPanelId) => void
  onFaucetModalClose: () => void

  isKytDeniedModalOpen: boolean
  onKytDeniedModalOpen: () => void
  onKytDeniedModalClose: () => void

  defaultConnectPanel?: ToSPanelId
  onDisconnect: () => void
  onLogoutProfile?: () => void
}) {
  const [resetToS, setResetToS] = useState({})
  const onConnect = useCallback(() => {
    setResetToS({}) // Reset the ToS modal
    onConnectModalOpen()
  }, [onConnectModalOpen])

  const onClose = () => {
    onConnectModalClose()
    setResetToS({}) // Reset the ToS modal
  }
  const { storage } = useStorage()
  const location = useLocation()
  const navigate = useNavigate()
  const isOnPortfolioPage = location.pathname.includes('/portfolio')
  const isOnRewardsPage = location.pathname.includes('/rewards')
  const isOnManageProfilePage = location.pathname.includes('/manage-profile')

  const isUsingVault = useMemo(() => {
    const search = new URLSearchParams(location.search)
    return !!search.get('vault')
  }, [location.search])

  const config = useConfig()

  const {
    isOpen: isRewardsAnnouncementModalOpen,
    onOpen: onRewardsAnnouncementModalOpen,
    onClose: onRewardsAnnouncementModalClose,
  } = useDisclosure()

  useEffect(() => {
    if (!isOnRewardsPage) {
      if (storage.getItem('ackRewardsAnnouncement') !== 'true') {
        onRewardsAnnouncementModalOpen()
      }
    }
  }, [onRewardsAnnouncementModalOpen, storage, isOnRewardsPage])

  const onCloseRewardsAnnouncementModal = useCallback(() => {
    storage.setItem('ackRewardsAnnouncement', 'true')
    onRewardsAnnouncementModalClose()
  }, [onRewardsAnnouncementModalClose, storage])

  const onConnectWalletRewardsModal = useCallback(() => {
    storage.setItem('ackRewardsAnnouncement', 'true')
    onRewardsAnnouncementModalClose()
    onConnectModalOpen()
  }, [onConnectModalOpen, onRewardsAnnouncementModalClose, storage])

  return (
    <>
      <AccessViaDesktopOverlay />
      <FiltersProvider>
        <Box position="relative" minHeight="100vh">
          <Box paddingBottom={isOnPortfolioPage ? '60px' : '112px'}>
            {!isOnManageProfilePage && (
              <Navbar
                wallet={wallet}
                account={account}
                onConnect={onConnect}
                onSwitchNetworkModalOpen={onSwitchNetworkModalOpen}
                onClaim={onFaucetModalOpen}
                onDisconnect={onDisconnect}
                onLogoutProfile={onLogoutProfile}
                onManageProfile={() =>
                  navigate('/manage-profile', {
                    state: { previousPage: location.pathname },
                  })
                }
                chainId={activeNetwork}
              />
            )}
            <SentryRoutes>
              <Route index path="/" element={auctionsPage} />
              <Route index path="/auctions" element={<Navigate to="/" />} />
              <Route
                path="/auctions/:auctionAddress/:chainId"
                element={isUsingVault ? vaultPage : auctionPage}
              />
              <Route path="vaults/*" element={vaultsPage} />
              <Route path="portfolio/*" element={portfolioPage} />
              <Route path="earn/*" element={lendPage} />
              <Route path="rewards/*" element={rewardsPage} />
              <Route path="callback/twitter" element={twitterCallbackPage} />
              <Route path="callback/discord" element={discordCallbackPage} />
              <Route path="manage-profile/*" element={profileManagementPage} />

              <Route path="*" element={<PageNotFound />} />
            </SentryRoutes>
          </Box>
          <Footer />
        </Box>
        <ToSModal
          isOpen={isConnectModalOpen}
          onClose={onClose}
          onConnectMetamask={
            wallet.status === 'not-connected'
              ? wallet.onConnectMetamask
              : undefined
          }
          onConnectWalletconnect={
            wallet.status === 'not-connected'
              ? wallet.onConnectWalletconnect
              : undefined
          }
          onConnectCoinbase={
            wallet.status === 'not-connected'
              ? wallet.onConnectCoinbase
              : undefined
          }
          account={account}
          defaultPanel={defaultConnectPanel ?? 1}
          resetModalSignal={resetToS}
          walletActivationError={walletActivationError}
        />
        <SwitchNetworkModal
          isOpen={isSwitchNetworkModalOpen}
          onClose={onSwitchNetworkModalClose}
          onSwitchNetwork={onSwitchNetwork}
          networks={Object.values(config.chains)}
          activeNetwork={activeNetwork}
        />
        <KytDeniedModal
          isOpen={isKytDeniedModalOpen}
          account={account}
          onClose={onKytDeniedModalClose}
        />
        {!config.isMainnet && !isOnRewardsPage && (
          <FaucetModal
            isOpen={isFaucetModalOpen}
            onClose={onFaucetModalClose}
            onClaimTestnetTokens={
              wallet.status === 'connected' ? onClaimTestnetTokens : undefined
            }
            onImportTestnetToken={
              wallet.status === 'connected' ? onImportTestnetToken : undefined
            }
            testnetTokenBalances={testnetTokenBalances}
            testnetTokenTicketsRemaining={testnetTokenTicketsRemaining}
            testnetTokenClaimsMade={testnetTokenClaimsMade}
            mintingAmounts={mintingAmounts}
            onSwitchNetwork={onSwitchNetwork}
          />
        )}
        {!isOnRewardsPage && (
          <RewardsAnnouncementModal
            isWalletConnected={!!account}
            onConnect={onConnectWalletRewardsModal}
            isOpen={isRewardsAnnouncementModalOpen}
            onClose={onCloseRewardsAnnouncementModal}
          />
        )}
      </FiltersProvider>
    </>
  )
}
