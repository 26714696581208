import { Text, TextProps, Link } from '@chakra-ui/react'
import { ArrowUpRight } from '../Icons'
import ClickCopy from '../ClickCopy'
import { shortenAddress } from '../../../helpers/utils'

type Props = {
  referralCode: string
  hideTooltip?: boolean
} & TextProps

export const ReferralCode = ({
  referralCode,
  hideTooltip,
  ...textProps
}: Props) => (
  <>
    <Text as="span" {...textProps}>
      {shortenAddress(referralCode)}
    </Text>
    <ClickCopy
      copyText={referralCode}
      helperText={'Copy address'}
      tooltipProps={{
        placement: 'bottom-end',
        offset: [8, 8],
        display: !!hideTooltip ? 'none' : undefined,
      }}
    />
    <Link
      href={`https://goerli.base.org/address/${referralCode}`}
      isExternal
      color="gray.3"
    >
      <ArrowUpRight h={4} />
    </Link>
  </>
)

export default ReferralCode
