interface EIP6963ProviderInfo {
  uuid: string
  name: string
  icon: string
  rdns: string
}

interface EIP6963ProviderDetail {
  info: EIP6963ProviderInfo
  provider: any // EIP1193Provider;
}

// Announce Event dispatched by a Wallet
interface EIP6963AnnounceProviderEvent extends Event {
  type: 'eip6963:announceProvider'
  detail: EIP6963ProviderDetail
}

export let connectedWalletDetail: EIP6963ProviderDetail | undefined

export const setupEIP6963OnPageLoad = () => {
  window.addEventListener('eip6963:announceProvider', (event: Event) => {
    connectedWalletDetail = (event as EIP6963AnnounceProviderEvent).detail
  })

  window.dispatchEvent(new Event('eip6963:requestProvider'))
}

export const isConnectedToMetaMask = () =>
  connectedWalletDetail?.info?.name === 'MetaMask'
