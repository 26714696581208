import { Modal, ModalOverlay, ModalContent, Divider } from '@chakra-ui/react'

import { ModalHeader, ModalBody, ModalFooter } from './modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  totalLoansFilled: number
  totalLoansTendered: number
  totalBorrowsFilled: number
  totalBorrowsTendered: number
}

export default function ParticipateInAuctionsModal({
  isOpen,
  onClose,
  totalLoansFilled,
  totalLoansTendered,
  totalBorrowsFilled,
  totalBorrowsTendered,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={true}>
      <ModalOverlay />
      <ModalContent p={5} w="520px">
        <ModalHeader onClose={onClose} />
        <Divider my={6} bg="#0000001a" />
        <ModalBody
          totalLoansFilled={totalLoansFilled}
          totalLoansTendered={totalLoansTendered}
          totalBorrowsFilled={totalBorrowsFilled}
          totalBorrowsTendered={totalBorrowsTendered}
        />
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
