import { BigNumber, FixedNumber } from 'ethers'
import { add, divide, multiply, subtract } from '../../helpers/math'
import { ExternalProject } from '../../data/model'

export type LendBoxProps = {
  isDataLoading?: boolean
  tokenSymbol: string
  tokenDecimals: number
  value: string
  termPointsMultiplier?: number
  externalProjects?: {
    project: ExternalProject
    label: string
    pointsMultiplier: number
  }[]
  onChange: (value: string) => void
  isDisabled?: boolean
  connected: boolean
  onConnectModalOpen: () => void
  onTriggerHoverEffect: () => void
  selectedApy: boolean
  discountRateMarkup: FixedNumber
  purchaseTokenAllowance: BigNumber
  amountToReceive: FixedNumber
  lendAmountUsd: FixedNumber
  walletBalance: FixedNumber
  availableLiquidity: FixedNumber
  auctionClearingRate: FixedNumber
  apy: FixedNumber
  redemptionTimestamp: number
  isPurchasingMax: boolean
  onLend: (purchaseAmount: string) => Promise<void>
  onKytCheck: () => Promise<boolean>
  onMax: (isPurchasingMax: boolean) => void
  onApprovePurchaseToken: (amount?: string) => Promise<void>
}

// purchase token * (1+(rate - markup)* timeRemaining / 360)
// timeRemaining = redemptionTimestamp - block.timestamp
export const calculateRepoTokensReceived = (
  purchaseTokenAmount: FixedNumber,
  redemptionTimestamp: number,
  discountRateMarkup: FixedNumber,
  lastAuctionClearingPrice: FixedNumber,
  currentTime: number,
  outputDecimals: number
) => {
  const timeRemaining = Math.max(redemptionTimestamp - currentTime, 0)
  const timeRemainingFn = FixedNumber.fromString(timeRemaining.toString(), 18)

  const rateMultiplier = timeRemainingFn.isZero()
    ? FixedNumber.fromString('0', 18)
    : add(
        FixedNumber.fromString('1'),
        multiply(
          subtract(lastAuctionClearingPrice, discountRateMarkup),
          divide(
            timeRemainingFn,
            FixedNumber.fromString((360 * 86400).toString(), 18)
          )
        )
      )
  const repoTokensReceived = multiply(
    purchaseTokenAmount,
    rateMultiplier,
    outputDecimals
  )
  return repoTokensReceived
}

// Back-calculates the purchase token amount required to receive a given amount of repo tokens
export const calculatePurchaseTokensRequired = (
  repoTokens: FixedNumber,
  redemptionTimestamp: number,
  discountRateMarkup: FixedNumber,
  lastAuctionClearingPrice: FixedNumber,
  currentTime: number,
  outputDecimals: number
) => {
  const timeRemaining = Math.max(redemptionTimestamp - currentTime, 0)
  const timeRemainingFn = FixedNumber.fromString(timeRemaining.toString(), 18)

  const rateMultiplier = timeRemainingFn.isZero()
    ? FixedNumber.fromString('0', 18)
    : add(
        FixedNumber.fromString('1'),
        multiply(
          subtract(lastAuctionClearingPrice, discountRateMarkup),
          divide(
            timeRemainingFn,
            FixedNumber.fromString((360 * 86400).toString(), 18)
          )
        )
      )

  // Inverse the clearing price calculation to find the corresponding purchase tokens required
  const purchaseTokenAmount = rateMultiplier.isZero()
    ? FixedNumber.fromString('0', `fixed128x${outputDecimals}`)
    : divide(repoTokens, rateMultiplier, outputDecimals)

  return purchaseTokenAmount
}
