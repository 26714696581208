import { Flex, Image, MenuItem, MenuList, Text } from '@chakra-ui/react'
import faqIcon from '../../../assets/icons/faq.svg'
import gitbookIcon from '../../../assets/icons/gitbook.svg'
import telegramIcon from '../../../assets/icons/telegram.svg'
import discordIcon from '../../../assets/icons/discord.svg'
import videoIcon from '../../../assets/icons/video.svg'
import { socialLinks } from '../../../socials'

export default function HelpMenuPopout() {
  return (
    <MenuList>
      <MenuItem as="a" href={socialLinks.youtube} target="_blank">
        <Flex alignItems="center">
          <Image boxSize="16px" src={videoIcon} mr="6px" />
          <Text as="span" ml="6px">
            Video Tutorials
          </Text>
        </Flex>
      </MenuItem>
      <MenuItem as="a" href={socialLinks.faq} target="_blank">
        <Flex alignItems="center">
          <Image boxSize="16px" src={faqIcon} mr="6px" />
          <Text as="span" ml="6px">
            FAQs
          </Text>
        </Flex>
      </MenuItem>
      <MenuItem as="a" href={socialLinks.gitbook} target="_blank">
        <Flex alignItems="center">
          <Image boxSize="16px" src={gitbookIcon} mr="6px" />
          <Text as="span" ml="6px">
            Documentation
          </Text>
        </Flex>
      </MenuItem>
      <MenuItem as="a" href={socialLinks.discord} target="_blank">
        <Flex alignItems="center">
          <Image boxSize="16px" src={discordIcon} mr="6px" />
          <Text as="span" ml="6px">
            Contact Team
          </Text>
        </Flex>
      </MenuItem>
      <MenuItem as="a" href={socialLinks.announcementsTelegram} target="_blank">
        <Flex alignItems="center">
          <Image boxSize="16px" src={telegramIcon} mr="6px" />
          <Text as="span" ml="6px">
            Stay Informed
          </Text>
        </Flex>
      </MenuItem>
    </MenuList>
  )
}
