import { Text } from '@chakra-ui/react'

export default function PageTitle({ title }: { title: string }) {
  return (
    <Text
      data-cy="page-title"
      color="blue.9"
      variant="body-l/semibold"
      mb="8px"
    >
      {title}
    </Text>
  )
}
