import { ChainId } from '@usedapp/core'
import { Box, Divider } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import SummaryFormulaTerm from '../SummaryFormulaTerm'
import SummaryFormulaOperator from '../SummaryFormulaOperator'
import OpenPositionsTile from './OpenPositionsTile'
import { t } from '@lingui/macro'

export default function SummaryHeader({
  totalLoanAmountUsd,
  totalOpenListingsUsd,
  interestToReceiveUsd,
  totalBorrowAmountUsd,
  interestToPayUsd,
  netPositionUsd,
  openLoanCount,
  openBorrowCount,
  openPositionCount,
  openPositionNetworks,
}: {
  totalLoanAmountUsd: FixedNumber
  totalOpenListingsUsd: FixedNumber
  interestToReceiveUsd: FixedNumber
  totalBorrowAmountUsd: FixedNumber
  interestToPayUsd: FixedNumber
  netPositionUsd: FixedNumber
  openLoanCount: number
  openBorrowCount: number
  openPositionCount: number
  openPositionNetworks: ChainId[]
}) {
  const hasOpenPositions = openPositionCount > 0
  return (
    <Box
      p={{
        base: '8px',
        xl: '24px',
      }}
      display="flex"
      borderRadius="md"
      w="full"
      bg="white"
      position="relative"
      overflow="hidden"
    >
      <HStack
        spacing={4}
        w="full"
        justifyContent="space-between"
        position="relative"
        zIndex={1}
      >
        <OpenPositionsTile
          netPositionUsd={netPositionUsd}
          openPositions={openPositionCount}
          openPositionNetworks={openPositionNetworks}
        />
        {openPositionCount === 0 && (
          <>
            <Divider orientation="vertical" borderColor="gray.2" />
            <SummaryFormulaTerm
              label={t`Net Position`}
              usd={netPositionUsd}
              isIconColored
            />
          </>
        )}
        <SummaryFormulaOperator isDark={hasOpenPositions}>
          =
        </SummaryFormulaOperator>
        <HStack spacing={4}>
          <SummaryFormulaTerm
            label={t`Total Lending`}
            usd={totalLoanAmountUsd}
            itemCount={openLoanCount}
            itemText={t`loans`}
            hasAddSign
            isIconColored={hasOpenPositions}
          />
          <SummaryFormulaTerm
            label={t`Interest Receivable`}
            usd={interestToReceiveUsd}
            hasAddSign
            isIconColored={hasOpenPositions}
          />
        </HStack>
        <HStack spacing={4}>
          <SummaryFormulaTerm
            label={t`Total Borrowing`}
            usd={totalBorrowAmountUsd}
            itemCount={openBorrowCount}
            itemText={t`borrows`}
            isIconColored={hasOpenPositions}
          />
          <SummaryFormulaTerm
            label={t`Interest Payable`}
            usd={interestToPayUsd}
            isIconColored={hasOpenPositions}
          />
        </HStack>
      </HStack>
    </Box>
  )
}
