import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { BigNumber, FixedNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useCallback, useMemo, useState } from 'react'
import OverflowBox from '../../../../components/elements/OverflowBox'
import Tooltip from '../../../../components/elements/Tooltip'
import {
  Auction,
  Currency,
  DraftBorrowTender,
  SuggestedRatesByPlatform,
} from '../../../../data/model'
import { parseBorrowTender } from '../../../../data/parse'
import { INTEREST_RATE_DECIMALS } from '../../../../helpers/constants'
import { bigToFixedNumber } from '../../../../helpers/conversions'
import { evaluate } from '../../../../helpers/math'
import {
  formatBigPercentage,
  formatBigToken,
  formatFixedPercentage,
  formatFixedToken,
  formatFixedUsd,
  getDisplayToken,
  getTokenDecimals,
} from '../../../../helpers/utils'
import CreateTenderField from '../CreateTenderField'
import WrappingTooltip from '../WrappingTooltip'
import { useChainConfig } from '../../../../providers/config'
import { Trans, t } from '@lingui/macro'

const calculateMinCollateralWithBuffer = (
  minCollateral: FixedNumber,
  initialMarginRatio: FixedNumber,
  collateralDecimals: number
) => {
  const diff = evaluate({
    nodeKind: 'sub',
    args: [
      {
        nodeKind: 'value',
        value: initialMarginRatio,
      },
      {
        nodeKind: 'value',
        value: FixedNumber.fromString('1', 'fixed128x18'),
      },
    ],
  })
  const bufferDecimal = evaluate({
    nodeKind: 'div',
    args: [
      {
        nodeKind: 'value',
        value: diff,
      },
      {
        nodeKind: 'value',
        value: FixedNumber.fromString('10', 'fixed128x18'),
      },
    ],
  })
  const buffer = evaluate({
    nodeKind: 'mul',
    args: [
      {
        nodeKind: 'value',
        value: bufferDecimal,
      },
      {
        nodeKind: 'value',
        value: minCollateral,
      },
    ],
  })
  const newMinCollateral = evaluate(
    {
      nodeKind: 'add',
      args: [
        {
          nodeKind: 'value',
          value: minCollateral,
        },
        {
          nodeKind: 'value',
          value: buffer,
        },
      ],
    },
    collateralDecimals
  )
  return newMinCollateral
}

const calculateMinCollateral = (
  amount: BigNumber,
  purchaseDecimals: number,
  collateralDecimals: number,
  purchasePrice: BigNumber,
  purchasePriceDecimals: number,
  collateralPrice: BigNumber,
  collateralPriceDecimals: number,
  initialMarginRatio: FixedNumber
) => {
  const loanAmountUsdc = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: bigToFixedNumber(amount, purchaseDecimals),
        },
        {
          nodeKind: 'value',
          value: bigToFixedNumber(purchasePrice, purchasePriceDecimals),
        },
      ],
    },
    collateralDecimals
  )

  const requiredCollateralUsdc = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: loanAmountUsdc,
        },
        {
          nodeKind: 'value',
          value: initialMarginRatio,
        },
      ],
    },
    collateralDecimals
  )

  const requiredCollateral = evaluate(
    {
      nodeKind: 'div',
      args: [
        {
          nodeKind: 'value',
          value: requiredCollateralUsdc,
        },
        {
          nodeKind: 'value',
          value: bigToFixedNumber(collateralPrice, collateralPriceDecimals),
        },
      ],
    },
    collateralDecimals
  )

  return requiredCollateral
}

const calculateCollateralRatio = (
  collateralValue: BigNumber,
  collateralCurrency: Currency,
  collateralCurrencyOraclePrice: BigNumber,
  collateralCurrencyOraclePriceDecimals: number,
  purchaseAmountValue: BigNumber,
  purchaseCurrency: Currency,
  purchaseCurrencyOraclePrice: BigNumber,
  purchaseCurrencyOraclePriceDecimals: number
) => {
  return evaluate(
    {
      nodeKind: 'div',
      args: [
        {
          nodeKind: 'mul',
          args: [
            {
              nodeKind: 'value',
              value: bigToFixedNumber(
                collateralValue,
                collateralCurrency.decimals
              ),
            },
            {
              nodeKind: 'value',
              value: bigToFixedNumber(
                collateralCurrencyOraclePrice,
                collateralCurrencyOraclePriceDecimals
              ),
            },
          ],
        },
        {
          nodeKind: 'mul',
          args: [
            {
              nodeKind: 'value',
              value: bigToFixedNumber(
                purchaseAmountValue,
                purchaseCurrency.decimals
              ),
            },
            {
              nodeKind: 'value',
              value: bigToFixedNumber(
                purchaseCurrencyOraclePrice,
                purchaseCurrencyOraclePriceDecimals
              ),
            },
          ],
        },
      ],
    },
    collateralCurrency.decimals
  )
}

const calculateValueAsUsd = (
  amount: BigNumber,
  amountDecimals: number,
  oraclePrice: BigNumber,
  oraclePriceDecimals: number
) => {
  const amountUsdc = evaluate(
    {
      nodeKind: 'mul',
      args: [
        {
          nodeKind: 'value',
          value: bigToFixedNumber(amount, amountDecimals),
        },
        {
          nodeKind: 'value',
          value: bigToFixedNumber(oraclePrice, oraclePriceDecimals),
        },
      ],
    },
    oraclePriceDecimals
  )

  return amountUsdc
}

export default function BorrowTenderForm({
  auction,
  purchaseCurrency,
  collateralCurrency,
  nakedCollateralCurrency,
  collateralTokenBalance,
  convertedNakedCollateralTokenBalance,
  availCollateralTokensToSupply,
  rateSuggestions,
  value,
  index,
  isLastIndex,
  tendersCount,
  isSubmitting,
  onChange,
  onRemove,
}: {
  auction: Pick<
    Auction,
    | 'chainId'
    | 'purchaseCurrencyOraclePriceUSDC'
    | 'purchaseCurrencyOraclePriceDecimals'
    | 'collateralCurrencyOraclePriceUSDC'
    | 'collateralCurrencyOraclePriceDecimals'
    | 'initialMarginRatio'
    | 'minBidAmount'
    | 'maxBidPrice'
  >
  purchaseCurrency: Currency
  collateralCurrency: Currency
  nakedCollateralCurrency: Currency | undefined
  collateralTokenBalance: BigNumber
  convertedNakedCollateralTokenBalance: BigNumber | undefined
  availCollateralTokensToSupply: BigNumber
  rateSuggestions: SuggestedRatesByPlatform | undefined
  value: DraftBorrowTender
  index: number
  isLastIndex: Boolean
  tendersCount: number
  isSubmitting: boolean
  onChange: (value: DraftBorrowTender) => void
  onRemove: () => void
}) {
  const [hasEditedAmount, setHasEditedAmount] = useState(false)
  const [hasEditedRate, setHasEditedRate] = useState(false)
  const [hasEditedCollateral, setHasEditedCollateral] = useState(false)

  const chainConfig = useChainConfig(auction.chainId)

  const [isAmountFieldChangedToEmpty, setIsAmountFieldChangedToEmpty] =
    useState(false)
  const [isRateFieldChangedToEmpty, setIsRateFieldChangedToEmpty] =
    useState(false)
  const [isCollateralFieldChangedToEmpty, setIsCollateralFieldChangedToEmpty] =
    useState(false)

  const minCollateral: (draft: DraftBorrowTender) => {
    minimumCollateral: FixedNumber
    minimumCollateralWithBuffer: FixedNumber
  } = useCallback(
    (draft: DraftBorrowTender) => {
      const parsedDraft = parseBorrowTender(
        draft,
        purchaseCurrency.decimals,
        collateralCurrency.decimals,
        FixedNumber.fromString('0', 'fixed128x18'),
        auction.minBidAmount,
        availCollateralTokensToSupply,
        FixedNumber.fromString('0', 18),
        auction.maxBidPrice
          ? FixedNumber.fromValue(auction.maxBidPrice.mul(100), 18)
          : FixedNumber.fromString('0', 18)
      )
      const minCollateral = calculateMinCollateral(
        parsedDraft.amount.value,
        purchaseCurrency.decimals,
        collateralCurrency.decimals,
        auction.purchaseCurrencyOraclePriceUSDC,
        auction.purchaseCurrencyOraclePriceDecimals,
        auction.collateralCurrencyOraclePriceUSDC,
        auction.collateralCurrencyOraclePriceDecimals,
        auction.initialMarginRatio
      )
      const minimumCollateralWithBuffer = calculateMinCollateralWithBuffer(
        minCollateral,
        auction.initialMarginRatio,
        getTokenDecimals(collateralCurrency.symbol)
      )

      return {
        minimumCollateral: minCollateral,
        minimumCollateralWithBuffer,
      }
    },
    [
      purchaseCurrency.decimals,
      collateralCurrency.decimals,
      collateralCurrency.symbol,
      auction.minBidAmount,
      auction.maxBidPrice,
      auction.purchaseCurrencyOraclePriceUSDC,
      auction.purchaseCurrencyOraclePriceDecimals,
      auction.collateralCurrencyOraclePriceUSDC,
      auction.collateralCurrencyOraclePriceDecimals,
      auction.initialMarginRatio,
      availCollateralTokensToSupply,
    ]
  )

  const { minimumCollateral, minimumCollateralWithBuffer } = useMemo(
    () => minCollateral(value),
    [minCollateral, value]
  )

  const parsed = useMemo(
    () =>
      parseBorrowTender(
        value,
        purchaseCurrency.decimals,
        collateralCurrency.decimals,
        minimumCollateral,
        auction.minBidAmount,
        availCollateralTokensToSupply,
        FixedNumber.fromString('0', 18),
        FixedNumber.fromValue(auction.maxBidPrice.mul(100), 18)
      ),
    [
      value,
      purchaseCurrency.decimals,
      collateralCurrency.decimals,
      minimumCollateral,
      auction.minBidAmount,
      auction.maxBidPrice,
      availCollateralTokensToSupply,
    ]
  )

  const onCollateralChange = (collateral: string) => {
    onChange({ ...value, collateral })
    if (collateral === '') {
      setIsCollateralFieldChangedToEmpty(true)
    } else {
      setIsCollateralFieldChangedToEmpty(false)
    }

    if (!hasEditedCollateral) {
      setHasEditedCollateral(true)
    }
  }

  const { displayToken: collateralSymbol, isTruncated: isCollateralTruncated } =
    getDisplayToken(collateralCurrency.symbol)

  return (
    <Box>
      {tendersCount > 1 && (
        <Flex alignItems="center" mb="8px" columnGap="8px">
          <Box px="4px" borderRadius="6px" bg="gray.2">
            <Text whiteSpace="nowrap" color="gray.6" variant="body-xs/medium">
              <Trans>Tender {index + 1}</Trans>
            </Text>
          </Box>
          <Divider />
          <Box
            onClick={() => onRemove()}
            aria-label={`delete-tender-${index}`}
            cursor="pointer"
          >
            <Text as="span" variant="body-xs/semibold" color="blue.5">
              <Trans>Clear</Trans>
            </Text>
          </Box>
        </Flex>
      )}
      <HStack mb="16px" alignItems="top" spacing={4}>
        <CreateTenderField
          label={t`Borrow Amount`}
          placeholder="0.00"
          rightElement={purchaseCurrency.symbol}
          value={value.amount}
          tokenDecimals={purchaseCurrency.decimals}
          onChange={(amount) => {
            // Update the amount field.
            const updatedDraft = { ...value, amount }
            onChange(updatedDraft)

            if (amount === '') {
              setIsAmountFieldChangedToEmpty(true)
            } else {
              setIsAmountFieldChangedToEmpty(false)
            }

            if (!hasEditedAmount) {
              setHasEditedAmount(true)
            }
          }}
          w={{
            base: '200px',
            xl: '280px',
          }}
          warningElement={
            hasEditedAmount && parsed?.amount?.error === 'too-small' ? (
              <>
                Minimum borrow amount is{' '}
                {formatBigToken(
                  auction.minBidAmount,
                  purchaseCurrency.decimals,
                  purchaseCurrency.symbol,
                  true
                )}
              </>
            ) : undefined
          }
          errorElement={
            // Do not show error if isSubmitting === true
            // (balance may reduce to lower than input value during transaction)
            !isSubmitting && hasEditedAmount ? (
              isAmountFieldChangedToEmpty &&
              parsed?.amount?.error === 'empty' ? (
                <>Specify a borrow amount</>
              ) : parsed?.amount?.error === 'invalid' ? (
                <>Invalid borrow amount</>
              ) : parsed?.amount?.error === 'too-many-decimals' ? (
                <>
                  Maximum number of decimal places allowed for this currency are{' '}
                  {' ' + purchaseCurrency.decimals}
                </>
              ) : undefined
            ) : undefined
          }
          infoElement={
            hasEditedAmount && parsed?.amount?.isValid ? (
              <>
                <OverflowBox maxW="240px">
                  {formatFixedUsd(
                    calculateValueAsUsd(
                      parsed?.amount?.value,
                      purchaseCurrency.decimals,
                      auction.purchaseCurrencyOraclePriceUSDC,
                      auction.purchaseCurrencyOraclePriceDecimals
                    ),
                    false,
                    true
                  )}
                </OverflowBox>
              </>
            ) : undefined
          }
        />
        <CreateTenderField
          label={t`Maximum Interest Rate`}
          isInterestRate
          variant="borrow"
          placeholder={'0.000%'}
          value={value.interestRate}
          tokenDecimals={INTEREST_RATE_DECIMALS}
          onChange={(interestRate) => {
            onChange({ ...value, interestRate })

            if (interestRate === '') {
              setIsRateFieldChangedToEmpty(true)
            } else {
              setIsRateFieldChangedToEmpty(false)
            }

            if (!hasEditedRate) {
              setHasEditedRate(true)
            }
          }}
          w={{
            base: '140px',
            xl: '180px',
          }}
          tooltip={<MaxInterestRateTooltip />}
          rightElement={t`APR %`}
          rightElementTooltip={t`The interest rate (%) is expressed as an Annual Percentage Rate (APR). `}
          warningElement={
            hasEditedRate && parsed?.interestRate?.error === 'too-large' ? (
              <>
                Maximum interest rate is{' '}
                {formatBigPercentage(auction.maxBidPrice)}
              </>
            ) : undefined
          }
          errorElement={
            hasEditedRate ? (
              isRateFieldChangedToEmpty &&
              parsed?.interestRate?.error === 'empty' ? (
                <>Specify a minimum interest rate</>
              ) : parsed?.interestRate?.error === 'too-small' ? (
                <>Minimum interest rate is 0.0%</>
              ) : parsed?.interestRate?.error === 'invalid' ? (
                <>Invalid minimum interest rate</>
              ) : undefined
            ) : undefined
          }
          rateSuggestions={rateSuggestions}
        />

        <CreateTenderField
          label={t`Collateral`}
          placeholder="0.00"
          rightElement={collateralSymbol}
          rightElementTooltip={
            isCollateralTruncated ? collateralCurrency.symbol : undefined
          }
          helperText={
            <>
              {isLastIndex ? (
                <OverflowBox
                  maxW="206px"
                  textAlign="right"
                  cursor="pointer"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    onCollateralChange(
                      formatUnits(
                        availCollateralTokensToSupply,
                        collateralCurrency.decimals
                      )
                    )
                  }}
                >
                  {nakedCollateralCurrency &&
                  chainConfig?.wrappedTokenMapping &&
                  convertedNakedCollateralTokenBalance &&
                  collateralCurrency.address in
                    chainConfig.wrappedTokenMapping ? (
                    <WrappingTooltip
                      unwrappedTokenSymbol={nakedCollateralCurrency.symbol}
                      unwrappedTokenBalance={bigToFixedNumber(
                        convertedNakedCollateralTokenBalance,
                        nakedCollateralCurrency.decimals
                      )}
                      wrappedCurrency={collateralCurrency}
                      wrappedTokenBalance={collateralTokenBalance}
                      availTokensToSupply={availCollateralTokensToSupply}
                    >
                      <div>
                        Available:{' '}
                        {formatBigToken(
                          availCollateralTokensToSupply,
                          collateralCurrency.decimals,
                          collateralCurrency.symbol,
                          true,
                          true
                        )}
                      </div>
                    </WrappingTooltip>
                  ) : (
                    <div>
                      Available:{' '}
                      {formatBigToken(
                        availCollateralTokensToSupply,
                        collateralCurrency.decimals,
                        collateralCurrency.symbol,
                        true,
                        true
                      )}
                    </div>
                  )}
                </OverflowBox>
              ) : null}
              {!!value.amount && (
                <OverflowBox
                  color={
                    // TODO: check this out with other errors like 'too-large', 'invalid' etc
                    !isAmountFieldChangedToEmpty &&
                    parsed?.collateral?.error !== 'empty'
                      ? parsed?.collateral?.error === 'too-small'
                        ? 'red.5'
                        : undefined
                      : undefined
                  }
                  maxW="206px"
                  textAlign="right"
                  cursor="pointer"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    onCollateralChange(minimumCollateralWithBuffer.toString())
                  }}
                >
                  <Tooltip
                    aria-label="autofill-buffer-tooltip"
                    label={t`Autofill with minimum (+ buffer to secure your bid in case of unfavorable price movements).`}
                  >
                    <div>
                      <Trans>Min:</Trans>{' '}
                      {formatFixedToken(
                        minimumCollateral,
                        collateralCurrency.symbol,
                        true,
                        true
                      )}
                    </div>
                  </Tooltip>
                </OverflowBox>
              )}
            </>
          }
          value={value.collateral}
          tokenDecimals={collateralCurrency.decimals}
          onChange={onCollateralChange}
          w={{
            base: '200px',
            xl: '280px',
          }}
          infoElement={
            hasEditedCollateral && parsed?.collateral?.isValid ? (
              <>
                <OverflowBox maxW="270px" textAlign="left" color="gray.5">
                  <Trans>Collateral ratio:</Trans>{' '}
                  {parsed.amount.value.gt('0')
                    ? formatFixedPercentage(
                        calculateCollateralRatio(
                          parsed.collateral.value,
                          collateralCurrency,
                          auction.collateralCurrencyOraclePriceUSDC,
                          auction.collateralCurrencyOraclePriceDecimals,
                          parsed.amount.value,
                          purchaseCurrency,
                          auction.purchaseCurrencyOraclePriceUSDC,
                          auction.purchaseCurrencyOraclePriceDecimals
                        ),
                        undefined,
                        undefined,
                        undefined,
                        true
                      )
                    : undefined}
                </OverflowBox>
              </>
            ) : undefined
          }
          errorElement={
            hasEditedCollateral ? (
              isCollateralFieldChangedToEmpty &&
              parsed?.collateral?.error === 'empty' ? (
                <>
                  <Trans>Specify a collateral amount</Trans>
                </>
              ) : parsed?.collateral?.error === 'invalid' ? (
                <>Invalid collateral amount</>
              ) : parsed?.collateral?.error === 'too-many-decimals' ? (
                <>
                  <Trans>
                    Maximum number of decimal places allowed for this currency
                    are
                  </Trans>{' '}
                  {' ' + collateralCurrency.decimals}
                </>
              ) : parsed?.collateral?.error === 'too-large' ? (
                <>
                  <OverflowBox maxW="270px" textAlign="left" color="gray.5">
                    <Trans>Collateral ratio:</Trans>{' '}
                    {parsed.amount.value.gt('0')
                      ? formatFixedPercentage(
                          calculateCollateralRatio(
                            parsed.collateral.value,
                            collateralCurrency,
                            auction.collateralCurrencyOraclePriceUSDC,
                            auction.collateralCurrencyOraclePriceDecimals,
                            parsed.amount.value,
                            purchaseCurrency,
                            auction.purchaseCurrencyOraclePriceUSDC,
                            auction.purchaseCurrencyOraclePriceDecimals
                          ),
                          undefined,
                          undefined,
                          undefined,
                          true
                        )
                      : undefined}
                  </OverflowBox>
                  <Trans>Insufficient collateral in wallet</Trans>
                </>
              ) : parsed?.collateral?.error === 'too-small' ? (
                <>
                  <OverflowBox maxW="270px" textAlign="left" color="gray.5">
                    <Trans>Collateral ratio:</Trans>{' '}
                    {parsed.amount.value.gt('0')
                      ? formatFixedPercentage(
                          calculateCollateralRatio(
                            parsed.collateral.value,
                            collateralCurrency,
                            auction.collateralCurrencyOraclePriceUSDC,
                            auction.collateralCurrencyOraclePriceDecimals,
                            parsed.amount.value,
                            purchaseCurrency,
                            auction.purchaseCurrencyOraclePriceUSDC,
                            auction.purchaseCurrencyOraclePriceDecimals
                          )
                        )
                      : undefined}
                  </OverflowBox>
                  <Trans>Insufficient collateral</Trans>
                </>
              ) : undefined
            ) : undefined
          }
        />
      </HStack>
    </Box>
  )
}

function MaxInterestRateTooltip() {
  return (
    <Tooltip
      noDelay
      label={t`If your bid is successful, the interest that you pay for your loan will be no more than what you input here.`}
    />
  )
}
