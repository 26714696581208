/**
 * A private database of rollovers.
 *
 * Ideally, by private, we mean only accessible to the current user authenticated with the
 * current wallet.
 *
 * For now, we'll just use localstorage, so it'll be private to the user/device, but not really
 * private to a current wallet.
 *
 * TODO: Find a secure way to store private tenders.
 *
 * Why does this exist?
 *
 * When tenders are created, their information is public because they end up on the blockchain.
 * But to maintain integrity of the auction, tenders need to be private. Therefore, details
 * of each tender are hashed before stored on the blockchain.
 *
 * To allow the current user/wallet to see bids they have previously submitted, we'll need
 * to store the unhashed values here.
 */

import { captureException } from '@sentry/react'
import { FixedNumber } from 'ethers'
import { SubmittedRollover } from '../data/model'

export function serializeRollover(rollover: SubmittedRollover) {
  // interestRate is a FixedNumber, which is not serializable to JSON.
  const modifiedRollover = {
    ...rollover,
    interestRate: rollover.interestRate.toString(),
  }
  return JSON.stringify(modifiedRollover)
}

export function deserializeRollover(serializedRollover: string) {
  try {
    const rollover: SubmittedRollover & { interestRate: string | number } =
      JSON.parse(serializedRollover)
    // interestRate is a FixedNumber, which is not serializable to JSON.
    const result = {
      ...rollover,
      interestRate: FixedNumber.fromString(rollover.interestRate.toString()),
    } as SubmittedRollover
    return result
  } catch (err) {
    console.error('Failed to deserialize rollover', serializedRollover, err)
    captureException(err)
    return undefined
  }
}

export async function getPrivateRollover(
  id: string
): Promise<SubmittedRollover> {
  const item = localStorage.getItem('rollover-' + id)
  if (item === null) {
    throw new Error('Private rollover ' + id + ' not found')
  }
  const rollover: SubmittedRollover | undefined = deserializeRollover(item)
  if (rollover === undefined) {
    throw new Error('Private rollover ' + id + ' not found')
  }

  // TODO: Validate that the tender has its required fields set

  return rollover
}

export async function createOrUpdatePrivateRollover(
  rollover: SubmittedRollover,
  localStorage: Storage = global.localStorage
): Promise<void> {
  localStorage.setItem('rollover-' + rollover.id, serializeRollover(rollover))
}

export async function removePrivateRollover(
  rolloverId: string,
  localStorage: Storage = global.localStorage
): Promise<void> {
  if (!localStorage) {
    return
  }
  localStorage.removeItem(`rollover-${rolloverId}`)
}
