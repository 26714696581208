import { ButtonProps } from '@chakra-ui/react'
import { BigNumberish, FixedNumber } from 'ethers'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { Currency } from '../../../../data/model'
import { fixedToBigNumber } from '../../../../helpers/conversions'
import { t } from '@lingui/macro'

export default function DepositButton({
  onClick,
  isDisabled,
  onApproveCollateralTokens,
  depositAmountParsed,
  approvedCollateralTokens,
  collateralCurrency,
  onClose,
}: {
  onApproveCollateralTokens: (amount: BigNumberish) => Promise<void>
  depositAmountParsed: FixedNumber
  approvedCollateralTokens: FixedNumber
  collateralCurrency: Currency
  onClick?: () => Promise<void>
  isDisabled?: ButtonProps['isDisabled']
  onClose?: () => void
}) {
  const termToast = useTermToast()

  return (
    <SubmitApproveButton
      onKytCheck={async () => true}
      onApprove={async () => {
        await onApproveCollateralTokens(fixedToBigNumber(depositAmountParsed))
      }}
      approvalAmount={fixedToBigNumber(depositAmountParsed)}
      approvedAmount={fixedToBigNumber(approvedCollateralTokens)}
      submitLabel={t`Deposit`}
      onSubmit={async () => {
        termToast.pending(
          termToastMessages.depositOrWithdraw.pending('deposit')
        )
        try {
          if (onClick) {
            await onClick()
          }
          termToast.success(
            termToastMessages.depositOrWithdraw.success('deposit')
          )
          if (onClose) {
            onClose()
          }
        } catch (error) {
          if ((error as Error).message.includes('code=ACTION_REJECTED')) {
            termToast.dismissed()
          } else {
            termToast.failure(
              termToastMessages.depositOrWithdraw.failure('deposit')
            )
          }
        }
      }}
      isDisabled={isDisabled}
      variant="secondary"
      approvingCurrencySymbol={collateralCurrency.symbol}
    />
  )
}
