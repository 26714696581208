import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGraphAuctions } from '../data/hooks/use-auctions'
import { useGlobalRefresher } from '../providers/refresher'
import AuctionsPageViewModel, {
  AuctionsPageParams,
} from '../models/auctionsPageViewModel'
import { useCurrentTimeSlow, useGraphQueries } from '../data/hooks/helper-hooks'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import { useChainConfigs } from '../providers/config'
import {
  graphAuctionResultToCollateralCurrencies,
  graphAuctionResultToPurchaseCurrency,
} from '../helpers/conversions'
import { Address, Currency } from '../data/model'
import { PageAuctionsQuery, PageAuctionsQueryVariables } from '../gql/graphql'

export function useAuctionsPage(
  provider: JsonRpcProvider | FallbackProvider | undefined,
  account: Address | undefined
) {
  const navigate = useNavigate()
  const location = useLocation()
  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const { slow: autoRefresher } = useGlobalRefresher()
  const currentTimeSlow = useCurrentTimeSlow()

  const chainConfigs = useChainConfigs()

  const queries = useMemo(() => {
    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.PAGE_AUCTIONS
      )
      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.PAGE_AUCTIONS,
        variables: {
          currentTime: currentTimeSlow.unix(),
          lastAuctionCutOff: currentTimeSlow.subtract(4, 'months').unix(),
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.subgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [chainConfigs, currentTimeSlow])

  // TODO: wire up error handling
  const {
    results: data,
    // fetching,
    // error,
    refresh: readFromSubgraph,
  } = useGraphQueries<PageAuctionsQuery, PageAuctionsQueryVariables>(queries)

  useEffect(() => {
    readFromSubgraph()
  }, [readFromSubgraph, autoRefresher])

  // Load blockchain data
  const [auctions, partialTermData, auctionActivityData] = useGraphAuctions(
    provider,
    data,
    account
  )

  const nonCancelledAuctions = useMemo(() => {
    return auctions?.filter((auction) => !auction.cancelled)
  }, [auctions])

  const currencies = useMemo(() => {
    const currenciesByChain = {} as { [chainId: string]: Currency[] }
    Object.entries(data).forEach(([chainId, result]) => {
      if (result?.termAuctions) {
        if (!currenciesByChain[chainId]) {
          currenciesByChain[chainId] = []
        }

        // stop duplicate currency mappings
        const uniqueAddresses = new Set<Address>()

        result?.termAuctions.forEach((auction) => {
          const purchaseCurrency = graphAuctionResultToPurchaseCurrency(
            auction.term
          )
          if (!uniqueAddresses.has(purchaseCurrency.address)) {
            uniqueAddresses.add(purchaseCurrency.address)
            currenciesByChain[chainId].push(purchaseCurrency)
          }

          const collateralCurrency = graphAuctionResultToCollateralCurrencies(
            auction.term
          )
          if (!uniqueAddresses.has(collateralCurrency.address)) {
            uniqueAddresses.add(collateralCurrency.address)
            currenciesByChain[chainId].push(collateralCurrency)
          }
        })
      } else {
        return undefined
      }
    })
    return currenciesByChain
  }, [data])

  const pageData = useMemo(() => {
    return {
      currencies,
      auctions: nonCancelledAuctions,
      terms: partialTermData,
      auctionActivityData,
      onViewAuction: (auctionAddress: string, chainId: string) =>
        navigate(`/auctions/${auctionAddress}/${chainId}?${search.toString()}`),
    } as AuctionsPageParams
  }, [
    currencies,
    navigate,
    nonCancelledAuctions,
    partialTermData,
    auctionActivityData,
    search,
  ])

  return AuctionsPageViewModel(pageData)
}
