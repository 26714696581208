import { Button, Heading, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import site_under_maintenance_graphic from '../../../assets/site-under-maintenance-graphic.svg'
import { socialLinks } from '../../../socials'
import { VStack } from '../Stack'

export default function ErrorOverlay() {
  return (
    <VStack
      py="60px"
      alignItems="center"
      w="full"
      overflow="hidden"
      pos="fixed"
      top="0"
      left="0"
      zIndex="999"
      bg="blue.0"
      minH="100vh"
    >
      <Image
        src={site_under_maintenance_graphic}
        alt="Error"
        w="581px"
        h="322px"
        mb={4}
      />
      <VStack spacing={2} alignItems="center" color="blue.9" maxW="529px">
        <Text as="h1" variant="body-3xl/bold">
          Oops!
        </Text>
        <Heading as="h2" variant="heading/h2">
          Something went wrong
        </Heading>
        <Text
          variant="body-sm/normal"
          textAlign="center"
          display="block"
          mb="12px !important"
          maxW="529px"
        >
          Please copy the console log and send it to our team on Discord. With
          your help, we’ll fix all these little bugs. Otherwise, return to the
          auctions page!
        </Text>
        <Link to="/">
          <Button variant="primary">Go to auctions</Button>
        </Link>
        <a href={socialLinks.discord} target="_blank" rel="noopener noreferrer">
          <Text as="span" variant="body-sm/normal" color="blue.5">
            Contact us on Discord
          </Text>
        </a>
      </VStack>
    </VStack>
  )
}
