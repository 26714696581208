import { BigNumber, constants, Contract, FixedNumber, Signer } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGraphQuery } from '../data/hooks/helper-hooks'
import { useAllowances } from '../data/hooks/use-allowances'
import { useGraphAuction } from '../data/hooks/use-auction'
import { useBorrowTenders } from '../data/hooks/use-borrow-tenders'
import { useCurrencies } from '../data/hooks/use-currencies'
import { useLoanTenders } from '../data/hooks/use-loan-tenders'
import { useTokenApprove } from '../data/hooks/use-token-approve'
import { getABIVersion } from '../helpers/conversions'
import { AuctionPageParams } from '../models/auction'
import { useGlobalRefresher } from '../providers/refresher'
import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { ChainId, useCall, useTokenBalance } from '@usedapp/core'
import { useMissingTenderRates } from '../data/hooks/use-missing-tender-rates'
import { useTokenWrapper } from '../data/hooks/use-token-wrapper'
import { useNativeTokenBalance } from '../data/hooks/use-native-token-balance'
import { useConvertedRebasingTokenBalance } from '../data/hooks/use-rebased-token-balance'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import { useChainConfig } from '../providers/config'
import { PageAuctionQuery, PageAuctionQueryVariables } from '../gql/graphql'
import { useAuctionActivity } from '../data/hooks/use-auction-activity'
import { useSuggestedRates } from '../data/hooks/use-suggested-rates'
import { useTermToast } from './toasts'
import { isAddress } from 'ethers/lib/utils'
import StrategyABI from '../abi/yearn-vault/Strategy.json'

const zero = BigNumber.from(0)

export function useVaultPage(
  activeNetwork: ChainId | undefined,
  account: string | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  referralCode: string | undefined,
  onConnect: () => void,
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>,
  onKytCheck: () => Promise<boolean>,
  onDeleteReferralCode: () => void
) {
  const { auctionAddress, chainId } = useParams()
  if (auctionAddress === undefined) {
    throw new Error(
      'Auction component may only be used within a route with id param'
    )
  }

  if (chainId === undefined) {
    throw new Error(
      'Auction component may only be used within a route with chain param'
    )
  }

  const parsedChainId = parseInt(chainId, 10)
  if (isNaN(parsedChainId)) {
    throw new Error(`Invalid value provided for ${parsedChainId}`)
  }

  const chainData = useChainConfig(parsedChainId)

  if (!chainData) {
    throw new Error(`Invalid chain id ${parsedChainId} - no chain config found`)
  }

  const [query, setQuery] = useState<any>({})
  // TODO: wire up what to do when refresh happens
  const [refreshTriggered, setRefreshTriggered] = useState(false)

  const [searchParams] = useSearchParams()
  const vaultAddress = searchParams.get('vault') ?? undefined

  const termToast = useTermToast()

  useEffect(() => {
    if (vaultAddress && !isAddress(vaultAddress)) {
      termToast.failure({
        title: 'Invalid vault address',
        children: 'Please provide a valid vault address',
      })
    }
  }, [termToast, vaultAddress])

  useEffect(() => {
    if (!vaultAddress) {
      return
    }
    const subgraphVersion = chainData.getSubgraphVersion()
    const queryDoc = getQueryDocument(
      subgraphVersion,
      DocumentType.PAGE_AUCTION
    )
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.PAGE_AUCTION,
      variables: {
        auction: auctionAddress,
        wallet: vaultAddress,
      },
    })
    const query = {
      chainId: parsedChainId,
      url: chainData.subgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setQuery(query)
  }, [vaultAddress, auctionAddress, chainData, parsedChainId])

  // Query data from subgraph for page
  // TODO: wire up fetching, error flags
  const {
    results: data,
    fetching,
    error,
    refresh: readFromSubgraph,
  } = useGraphQuery<PageAuctionQuery, PageAuctionQueryVariables>(query)

  const { slow: autoRefresher } = useGlobalRefresher()
  useEffect(() => {
    readFromSubgraph()
  }, [readFromSubgraph, autoRefresher])

  // Load blockchain data
  const { auction, term: partialTerm } = useGraphAuction(
    parsedChainId.toString(),
    auctionAddress,
    provider,
    data?.termAuctions
  )

  const auctionActivityData = useAuctionActivity(
    data?.auctionTermBids,
    data?.auctionTermOffers
  )

  const isUsingRebasingToken = useMemo(() => {
    return !!(
      auction?.collateralCurrency &&
      chainData.wrappedTokenMapping &&
      auction.collateralCurrency in chainData.wrappedTokenMapping
    )
  }, [auction?.collateralCurrency, chainData.wrappedTokenMapping])

  // { [chainId]: [purchase, collateral] }
  const tokenData = useMemo(() => {
    if (!auction) return { [chainId]: undefined }

    const addressesObject = [
      auction.purchaseCurrency,
      auction.collateralCurrency,
    ].map((c) => ({
      address: c,
      isRepoToken: false,
      version: auction.version,
    }))

    // if there is a wrapped token for the collateral, add the rebasing/underlying token to the list of tokens to fetch currency data for
    if (isUsingRebasingToken && chainData.wrappedTokenMapping) {
      addressesObject.push({
        address: chainData.wrappedTokenMapping[auction.collateralCurrency],
        isRepoToken: false,
        version: auction.version,
      })
    }

    return { [chainId]: addressesObject }
  }, [auction, chainData.wrappedTokenMapping, chainId, isUsingRebasingToken])

  const [bidLockerAddress, offerLockerAddress, version] = useMemo(() => {
    const blA = auction ? auction.bidLockerAddress : undefined
    const olA = auction ? auction.offerLockerAddress : undefined
    const version = getABIVersion(auction?.version)
    return [blA, olA, version]
  }, [auction])

  const currencies = useCurrencies(tokenData, provider)
  const [purchaseCurrency, collateralCurrency, nakedCollateralCurrency] =
    useMemo(() => {
      if (!currencies) return [undefined, undefined, undefined]

      const purchaseCurrency = currencies?.[chainId]?.[0]
      const collateralCurrency = currencies?.[chainId]?.[1]
      const nakedCollateralCurrency = currencies?.[chainId]?.[2]
      return [purchaseCurrency, collateralCurrency, nakedCollateralCurrency]
    }, [currencies, chainId])

  const { value: vaultBalanceRaw } =
    useCall(
      vaultAddress && {
        contract: new Contract(vaultAddress, StrategyABI, provider),
        method: 'totalLiquidBalance',
        args: [],
      },
      {
        chainId: parsedChainId,
      }
    ) ?? {}

  const vaultPurchaseTokenBalance = vaultBalanceRaw?.[0] ?? zero

  const collateralTokenBalance =
    useTokenBalance(auction?.collateralCurrency, vaultAddress, {
      chainId: parsedChainId,
    }) ?? zero

  // if auction is using a wrapped token as collat then also fetch balance of underlying token too
  const nakedCollateralTokenBalance =
    useTokenBalance(nakedCollateralCurrency?.address, vaultAddress, {
      chainId: parsedChainId,
    }) ?? zero

  // if naked collat token is a rebasing token, we need to get the equivalent balance in wrapped format for accurate max values in tooltip/button
  const convertedNakedCollateralTokenBalance =
    useConvertedRebasingTokenBalance(
      collateralCurrency,
      nakedCollateralTokenBalance,
      provider,
      { chainId: parsedChainId }
    ) ?? zero

  const prices = useMemo(() => {
    if (!auction || !purchaseCurrency || !collateralCurrency) {
      return undefined
    }
    const prices = {
      [chainId]: {
        [auction.purchaseCurrency]: FixedNumber.fromValue(
          auction.purchaseCurrencyOraclePriceUSDC,
          auction.purchaseCurrencyOraclePriceDecimals
        ),
        [auction.collateralCurrency]: FixedNumber.fromValue(
          auction.collateralCurrencyOraclePriceUSDC,
          auction.collateralCurrencyOraclePriceDecimals
        ),
      },
    }
    return prices
  }, [auction, chainId, purchaseCurrency, collateralCurrency])

  const decimals = useMemo(() => {
    if (!auction || !purchaseCurrency || !collateralCurrency) {
      return undefined
    }
    const decimals = {
      [chainId]: {
        [auction.purchaseCurrency]: auction.purchaseCurrencyOraclePriceDecimals,
        [auction.collateralCurrency]:
          auction.collateralCurrencyOraclePriceDecimals,
      },
    }
    return decimals
  }, [auction, chainId, purchaseCurrency, collateralCurrency])

  const [
    loanTenders,
    reloadLoanTenders,
    deleteLoanTenders,
    lockLoanTenders,
    lockVaultLoanTenders,
    deleteVaultLoanTenders,
  ] = useLoanTenders(
    chainData.chainId,
    version,
    vaultAddress,
    auctionAddress,
    decimals,
    prices,
    offerLockerAddress,
    provider,
    signer,
    data?.termOffers,
    readFromSubgraph,
    chainData.revealServerUrl,
    vaultAddress
  )

  const [
    borrowTenders,
    reloadBorrowTenders,
    deleteBorrowTenders,
    lockBorrowTenders,
  ] = useBorrowTenders(
    chainData.chainId,
    version,
    account,
    auctionAddress,
    decimals,
    prices,
    bidLockerAddress,
    provider,
    signer,
    data?.termBids,
    readFromSubgraph,
    chainData.revealServerUrl
  )

  const [loadMissingTenderRates, isLoadingMissingRates] = useMissingTenderRates(
    vaultAddress,
    loanTenders,
    borrowTenders,
    signer
  )

  const purchaseTokenApprove = useTokenApprove(
    signer,
    purchaseCurrency,
    parsedChainId
  )
  const collateralTokenApprove = useTokenApprove(
    signer,
    collateralCurrency,
    parsedChainId
  )
  const nakedCollateralTokenApprove = useTokenApprove(
    signer,
    nakedCollateralCurrency,
    parsedChainId
  )

  // TODO: this is not necessary with vaults
  const tokenAllowances = useMemo(() => {
    if (!vaultAddress || !auction || !purchaseCurrency || !collateralCurrency) {
      return undefined
    }
    const allowances = [
      {
        token: purchaseCurrency.address,
        spender: auction.repoLockerAddress,
        owner: vaultAddress,
        termId: auction.termId,
      },
      {
        token: collateralCurrency.address,
        spender: auction.repoLockerAddress,
        owner: vaultAddress,
        termId: auction.termId,
      },
    ]

    if (nakedCollateralCurrency) {
      allowances.push({
        token: nakedCollateralCurrency.address,
        spender: collateralCurrency.address,
        owner: vaultAddress,
        termId: auction.termId,
      })
    }

    return {
      [chainId]: allowances,
    }
  }, [
    vaultAddress,
    auction,
    chainId,
    collateralCurrency,
    nakedCollateralCurrency,
    purchaseCurrency,
  ])
  const allowances = useAllowances(tokenAllowances, provider)

  const purchaseTokenAllowance = constants.MaxUint256

  const {
    wrapGasToken: wrapGasTokenCall,
    unwrapGasToken: unwrapGasTokenCall,
    wrapToken: wrapTokenCall,
    unwrapToken: unwrapTokenCall,
  } = useTokenWrapper(
    chainData.chainId,
    chainData.contracts.wrappedGasToken,
    provider
  )

  const gasTokenCurrency = useMemo(
    () => chainData.nativeCurrency,
    [chainData.nativeCurrency]
  )
  const gasTokenBalance = useNativeTokenBalance(
    vaultAddress,
    gasTokenCurrency,
    auction?.chainId ? Number(auction.chainId) : undefined
  )

  const rateSuggestions = useSuggestedRates(
    auction?.chainId,
    auction?.purchaseCurrency,
    auction?.collateralCurrency,
    auction?.maintenanceMarginRatio
  )

  const params = useMemo(
    () =>
      ({
        account: vaultAddress,
        auction,
        auctionActivityData,
        term: partialTerm,
        purchaseCurrency,
        purchaseTokenBalance: vaultPurchaseTokenBalance,
        purchaseTokenAllowance,
        collateralCurrency,
        collateralTokenBalance: collateralTokenBalance,
        collateralTokenAllowance:
          allowances?.[chainId]?.[auction?.termId ?? '']?.[1],
        nakedCollateralCurrency: nakedCollateralCurrency,
        nakedCollateralTokenBalance: nakedCollateralTokenBalance,
        convertedNakedCollateralTokenBalance:
          convertedNakedCollateralTokenBalance,
        nakedCollateralTokenAllowance:
          allowances?.[chainId]?.[auction?.termId ?? '']?.[2],
        gasTokenCurrency,
        gasTokenBalance: gasTokenBalance?.balance,
        loanTenders: loanTenders,
        rateSuggestions,
        referralCode,
        reloadLoanTenders: () => {
          reloadLoanTenders()
          readFromSubgraph()
        },
        deleteLoanTenders: deleteLoanTenders
          ? (tenders) =>
              deleteLoanTenders(
                tenders,
                auction?.offerLockerAddress ?? '',
                auction?.version ?? ''
              )
          : undefined,
        lockLoanTenders: lockLoanTenders
          ? (tenders, lender) =>
              lockLoanTenders(
                tenders,
                lender,
                auction?.termId ?? '',
                auction?.auctionId ?? '',
                auction?.offerLockerAddress ?? '',
                auction?.version ?? '',
                referralCode
              )
          : undefined,
        lockVaultLoanTenders: lockVaultLoanTenders
          ? (tenders, lender) =>
              lockVaultLoanTenders(
                tenders,
                lender,
                auction?.termId ?? '',
                auction?.auctionId ?? '',
                auction?.address ?? '',
                auction?.termRepoTokenAddress ?? ''
              )
          : undefined,
        deleteVaultLoanTenders: deleteVaultLoanTenders
          ? (tenders) => deleteVaultLoanTenders(tenders)
          : undefined,
        borrowTenders: borrowTenders,
        reloadBorrowTenders: () => {
          reloadBorrowTenders()
          readFromSubgraph()
        },
        deleteBorrowTenders: deleteBorrowTenders
          ? (tenders) =>
              deleteBorrowTenders(
                tenders,
                auction?.bidLockerAddress ?? '',
                auction?.version ?? ''
              )
          : undefined,
        lockBorrowTenders: lockBorrowTenders
          ? (tenders, borrower) =>
              lockBorrowTenders(
                tenders,
                borrower,
                auction?.termId ?? '',
                auction?.auctionId ?? '',
                auction?.bidLockerAddress ?? '',
                auction?.version ?? '',
                referralCode
              )
          : undefined,
        purchaseTokenApprove: purchaseTokenApprove,
        collateralTokenApprove: collateralTokenApprove,
        onConnect,
        onCheckActiveNetwork,
        onKytCheck,
        nakedCollateralTokenApprove,
        onDeleteReferralCode,
        loadMissingTenderRates,
        wrapGasTokenCall,
        wrapTokenCall,
        unwrapGasTokenCall,
        unwrapTokenCall,
        isLoadingMissingRates,
      }) as AuctionPageParams,
    [
      vaultAddress,
      chainId,
      auction,
      auctionActivityData,
      partialTerm,
      purchaseCurrency,
      vaultPurchaseTokenBalance,
      allowances,
      collateralCurrency,
      collateralTokenBalance,
      nakedCollateralCurrency,
      nakedCollateralTokenBalance,
      convertedNakedCollateralTokenBalance,
      gasTokenCurrency,
      gasTokenBalance?.balance,
      loanTenders,
      rateSuggestions,
      referralCode,
      deleteLoanTenders,
      deleteVaultLoanTenders,
      lockLoanTenders,
      lockVaultLoanTenders,
      borrowTenders,
      deleteBorrowTenders,
      lockBorrowTenders,
      purchaseTokenApprove,
      collateralTokenApprove,
      nakedCollateralTokenApprove,
      onConnect,
      onCheckActiveNetwork,
      onKytCheck,
      onDeleteReferralCode,
      loadMissingTenderRates,
      wrapGasTokenCall,
      wrapTokenCall,
      unwrapGasTokenCall,
      unwrapTokenCall,
      isLoadingMissingRates,
      reloadLoanTenders,
      readFromSubgraph,
      reloadBorrowTenders,
    ]
  )
  return params
}
