import { createContext, ReactNode, useContext, useMemo } from 'react'
import defaultConfig, { ChainSpecificConfig, Config } from '../config'
import { isRenderedInSafeApp } from '../helpers/methods'
import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk'

export const ConfigContext = createContext<Config>(defaultConfig)

export function ConfigProvider({
  config,
  children,
}: {
  config?: Config
  children: ReactNode
}) {
  return (
    <ConfigContext.Provider value={config ?? defaultConfig}>
      {children}
    </ConfigContext.Provider>
  )
}

export function useConfig(): Config {
  return useContext(ConfigContext)
}

export function useChainConfig(
  chainId: string | number | undefined
): ChainSpecificConfig | undefined {
  const config = useConfig()
  return useMemo(() => {
    if (!chainId) {
      return
    } else {
      return config.chains[chainId]
    }
  }, [config, chainId])
}

export function useChainConfigs(): ChainSpecificConfig[] {
  const config = useConfig()
  const { connected: isSafeConnected, safe } = useSafeAppsSDK()

  return useMemo(() => {
    // If connected via Safe app, only show the active chain auctions
    if (!isRenderedInSafeApp()) {
      return Object.values(config.chains)
    } else {
      // Need to wait to check if safe is connected
      if (!isSafeConnected) {
        return []
      } else {
        return [config.chains[safe.chainId]]
      }
    }
  }, [config.chains, isSafeConnected, safe.chainId])
}
