import { Box, Text } from '@chakra-ui/react'
import BombPot from '../../../../components/elements/BombPotGif'
import Tooltip from '../../../../components/elements/Tooltip'
import dayjs from 'dayjs'

type Props = {
  bombPotAmount: number
  auctionEndTimestamp: number
  bombPotRewardTokenSymbol: string
}

export default function BombPotIcon({
  bombPotAmount,
  auctionEndTimestamp,
  bombPotRewardTokenSymbol,
}: Props) {
  return (
    <Tooltip
      placement="left-start"
      gutter={16}
      w={'243px'}
      label={
        <Text
          as="div"
          variant="body-sm/normal"
          color="rgba(255, 255, 255, 0.60)"
          textAlign="left"
        >
          <Text>
            {dayjs.unix(auctionEndTimestamp).format('MMM D')} Bomb Pot:{'   '}
            <Text variant="body-sm/medium" as="span" color="white">
              {bombPotAmount}{' '}
              <Text textTransform="uppercase" as="span">
                {bombPotRewardTokenSymbol}
              </Text>
            </Text>
          </Text>
          <br />
          <Text>
            Supply in this auction and earn your portion of the bomb pot.
          </Text>
        </Text>
      }
    >
      <Box position="relative">
        <BombPot opacity={0.7} w="24px" />
      </Box>
    </Tooltip>
  )
}
