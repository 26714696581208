import { Flex, Image, Link, Text } from '@chakra-ui/react'
import arrow_up_right from '../../../assets/icons/arrow-up-right.svg'
import { useTokenIcon } from '../../../data/hooks/use-token-icons'
import { ERROR_STR } from '../../../helpers/constants'
import { HStack } from '../Stack'
import Tooltip from '../Tooltip'
import { getDisplayToken } from '../../../helpers/utils'

const TokenIconLabel = ({
  isSmall,
  isSkipTruncation,
  priceDisplay = {
    base: 'flex',
  },
  token,
  price,
  href,
  isBold,
  isInheritParentTextVariant = false,
  boxSize = '24px',
}: {
  isSmall?: boolean
  isSkipTruncation?: boolean
  priceDisplay?: {} | string
  token: string
  price?: string
  href?: string
  isBold?: boolean
  isInheritParentTextVariant?: boolean
  boxSize?: string
}) => {
  const src = useTokenIcon(token)
  const fontWeight = isBold ? 'bold' : 'normal'
  let textVariant = isSmall ? 'body-sm/normal' : 'body-md/normal'
  textVariant = isInheritParentTextVariant ? 'inherit' : textVariant

  const { displayToken, isTruncated } = getDisplayToken(token, {
    skipTruncation: isSkipTruncation,
  })

  return (
    <Flex alignItems="center" columnGap="4px">
      {token !== ERROR_STR && <Image boxSize={boxSize} src={src} alt={token} />}
      <Tooltip
        leftAlign
        label={isTruncated ? token.toUpperCase() : undefined}
        placement="bottom"
      >
        <Text
          color="blue.9"
          whiteSpace="nowrap"
          variant={textVariant}
          fontWeight={fontWeight}
        >
          {displayToken.toUpperCase()}
        </Text>
      </Tooltip>
      {price && (
        <HStack display={priceDisplay}>
          <Text color="blue.9" whiteSpace="nowrap" variant={textVariant}>
            •
          </Text>
          <Text color="gray.5" whiteSpace="nowrap" variant={textVariant}>
            {price}
          </Text>
          {href && (
            <Link isExternal href={href}>
              <Image boxSize={boxSize} src={arrow_up_right} alt="arrow" />
            </Link>
          )}
        </HStack>
      )}
    </Flex>
  )
}

export default TokenIconLabel
