import { Image, ImageProps } from '@chakra-ui/react'
import { useTokenIcon } from '../../../data/hooks/use-token-icons'

export default function TermToastTokenIcon({
  symbol,
  src,
  alt,
  boxSize,
  ...props
}: {
  symbol: string
} & ImageProps) {
  const icon = useTokenIcon(symbol)
  return (
    <Image
      boxSize={boxSize ?? '34px'}
      src={src ?? icon}
      alt={alt ?? symbol}
      {...props}
    />
  )
}
