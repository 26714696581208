import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import ApproveStepIndicator from '../../../../components/elements/ApproveStepIndicator'
import { formatFixedToken } from '../../../../helpers/utils'
import { FixedNumber } from 'ethers'
import { NativeCurrency } from '../../../../data/model'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'

import Tooltip from '../../../../components/elements/Tooltip'

export default function WrapGasButton({
  isWrapButtonDisabled,
  amountToWrap,
  gasTokenCurrency,

  onWrap,
  onWrappingStatusChange,
  onWrappedStatusChange,
}: {
  isWrapButtonDisabled: boolean
  amountToWrap: FixedNumber
  gasTokenCurrency: NativeCurrency

  onWrap: () => Promise<void>
  onWrappingStatusChange: (wrapping: boolean) => void
  onWrappedStatusChange: (hasWrapped: boolean) => void
}) {
  const [wrapping, setWrapping] = useState(false)
  const [hasWrapped, setHasWrapped] = useState(false)
  const termToast = useTermToast()

  const buttonIcon = useMemo(() => {
    switch (true) {
      case !wrapping && !hasWrapped:
        return (
          <Tooltip
            w="320px"
            label={`Firstly, we will wrap your ${gasTokenCurrency.symbol} into ${gasTokenCurrency.wrappedGasSymbol} to ensure you have the necessary funds for submitting your tender(s). Please reserve sufficient ${gasTokenCurrency.symbol} to cover gas fees.`}
          >
            <FontAwesomeIcon
              fontSize={'14px'}
              icon={['far', 'info-circle']}
              color="white"
            />
          </Tooltip>
        )
      case wrapping:
        return <Spinner size="sm" />
      case hasWrapped:
        return <FontAwesomeIcon icon={['far', 'check']} color="white" />
    }
  }, [wrapping, hasWrapped, gasTokenCurrency])

  const isDisabled = isWrapButtonDisabled || wrapping

  return (
    <Box position="relative" w="full">
      <ApproveStepIndicator>1</ApproveStepIndicator>
      <Button
        variant="primary"
        size="sm"
        w="full"
        opacity={isDisabled ? 0.5 : 1}
        isDisabled={isDisabled}
        onClick={async () => {
          if (isWrapButtonDisabled) {
            return
          }
          const { symbol: gasTokenSymbol, wrappedGasSymbol } = gasTokenCurrency
          try {
            setWrapping(true)
            onWrappingStatusChange(true)
            termToast.pending(
              termToastMessages.wrapping.pending(
                gasTokenSymbol,
                wrappedGasSymbol
              )
            )

            await onWrap()

            termToast.success(
              termToastMessages.wrapping.success(gasTokenSymbol)
            )
            // Use hasWrapped for keeping Wrap button
            // visible until refresh on first time wrapping
            setHasWrapped(true)
            onWrappedStatusChange(true)
          } catch (e) {
            setHasWrapped(false)
            onWrappedStatusChange(false)
            console.error(e)
            if ((e as Error).message.includes('user rejected transaction')) {
              termToast.dismissed()
            } else {
              termToast.failure(
                termToastMessages.wrapping.failure(gasTokenSymbol)
              )
            }
          } finally {
            setWrapping(false)
            onWrappingStatusChange(false)
          }
        }}
      >
        <Flex as="span" alignItems="center" columnGap={1.5}>
          <Text as="span" variant="body-sm/semibold">
            {hasWrapped
              ? 'Wrapped'
              : 'Wrap ' +
                formatFixedToken(
                  amountToWrap,
                  gasTokenCurrency.symbol,
                  false,
                  true
                )}
          </Text>
          {buttonIcon}
        </Flex>
      </Button>
    </Box>
  )
}
