import { Box, Heading, Text, Divider, Skeleton } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import { Fragment, ReactNode } from 'react'
import ValueTooltip from '../../../../components/elements/ValueTooltip'
import { formatFixed } from '../../../../helpers/conversions'
import {
  formatFixedUsd,
  getTextColorFromAmount,
} from '../../../../helpers/utils'
import { Trans, t } from '@lingui/macro'

export default function SummaryBreakdown({
  isDataLoading,
  title,
  values,
  noValuesText,
}: {
  isDataLoading?: boolean
  title: string
  values?: { label: string; value?: FixedNumber; node?: ReactNode }[]
  noValuesText: string
}) {
  return (
    <VStack w="full" alignItems="flex-start">
      <Box pl={5} mb={2}>
        {title === t`Collateral Overview` ? (
          <Text as="span" variant="body-sm/medium" color="blue.9">
            {title}
          </Text>
        ) : (
          <Heading
            variant="heading/h3"
            as="h3"
            color="blue.9"
            fontWeight="bold"
          >
            {title}
          </Heading>
        )}
      </Box>
      {title === t`Open Tenders` && values?.length && (
        <Box pl={5}>
          <Text variant="body-sm/medium" as="span" color="blue.9">
            <Trans>Bids</Trans>
          </Text>
        </Box>
      )}
      <VStack
        bg="rgba(174, 188, 208, 0.06)"
        w="full"
        borderRadius="md"
        spacing={0}
      >
        {values?.length ? (
          values.map(({ label, value, node }) => {
            const isValueRounded = formatFixed(value, {
              displayDecimals: 2,
            })[1]
            const fixedTokenNumber = formatFixedUsd(value, false, true)
            const [wholeAmount, decimals] = fixedTokenNumber
              .toString()
              .split('.')

            return (
              <Fragment key={label}>
                {title === t`Open Tenders` && label === t`Total Offers` && (
                  <HStack key={title} mt="0 !important" bg="blue.0" w="full">
                    <Box pl={5} mt={5}>
                      <Text variant="body-sm/medium" as="span" color="blue.9">
                        <Trans>Offers</Trans>
                      </Text>
                    </Box>
                  </HStack>
                )}
                <HStack
                  key={label}
                  py={2}
                  px={5}
                  w="full"
                  mt="0 !important"
                  justifyContent="space-between"
                >
                  <Text as="span" variant="body-sm/normal" color="gray.6">
                    {label}
                  </Text>
                  <Box>
                    {isDataLoading ? (
                      <Skeleton h={6} w="100px" />
                    ) : node ? (
                      node
                    ) : (
                      <ValueTooltip
                        hidden={!isValueRounded || !value}
                        value={`$${value?.toString()}`}
                        aria-label={`${label}-tooltip`}
                      >
                        <HStack
                          alignItems="flex-end"
                          color={
                            label === t`Total Excess Collateral`
                              ? getTextColorFromAmount(
                                  FixedNumber.fromString(
                                    wholeAmount
                                      .replace('~', '')
                                      .replaceAll('$', '')
                                      .replaceAll(',', '')
                                  ),
                                  true
                                )
                              : 'blue.9'
                          }
                          spacing={0}
                        >
                          <Text
                            as="div"
                            fontSize="16px"
                            lineHeight="1"
                            fontWeight="medium"
                          >
                            {wholeAmount}
                          </Text>
                          <Text
                            as="div"
                            fontSize="12px"
                            lineHeight="1"
                            ml="0px !important"
                            fontWeight="medium"
                          >
                            .
                          </Text>
                          <Text
                            as="div"
                            fontSize="12px"
                            lineHeight="1"
                            ml="0px !important"
                            fontWeight="medium"
                          >
                            {decimals === '0' ? '00' : decimals}
                          </Text>
                        </HStack>
                      </ValueTooltip>
                    )}
                  </Box>
                </HStack>
                <Divider
                  key={`divider-${label}`}
                  mt="0 !important"
                  bg="gray.2"
                  w="full"
                  _last={{ display: 'none' }}
                />
              </Fragment>
            )
          })
        ) : (
          <Text
            pl={5}
            as="span"
            variant="body-sm/medium"
            color="gray.6"
            w="full"
            py={2}
          >
            {noValuesText}
          </Text>
        )}
      </VStack>
    </VStack>
  )
}
