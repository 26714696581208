import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTokenIcon } from '../../../data/hooks/use-token-icons'
import { USDC_TOKEN_SYMBOL } from '../../../helpers/constants'
import { getDisplayToken } from '../../../helpers/utils'

const TokenChips = ({
  token,
  tokens,
  onChange,
}: {
  token: string
  tokens: string[]
  onChange: (token: string) => void
}) => {
  const imageSrc = useTokenIcon(token)

  const { displayToken } = getDisplayToken(token)

  return (
    <Menu>
      <MenuButton>
        <Flex
          alignItems="center"
          backgroundColor="gray.50"
          pt="4px"
          pb="4px"
          pl="8px"
          pr="8px"
          borderRadius="md"
          fontSize="xs"
          fontWeight="400"
          lineHeight="4"
          color="gray.700"
        >
          {imageSrc && token !== USDC_TOKEN_SYMBOL && (
            <Image boxSize="16px" src={imageSrc} alt={token} mr="4px" />
          )}
          {displayToken.toUpperCase()}
          <Box ml="4px">
            <FontAwesomeIcon icon={['far', 'chevron-down']} />
          </Box>
        </Flex>
      </MenuButton>
      <MenuList>
        {tokens?.map((token) => (
          <MenuItem key={token} onClick={() => onChange(token)}>
            <Flex
              alignItems="center"
              pt="4px"
              pb="4px"
              pl="8px"
              pr="8px"
              fontSize="xs"
              fontWeight="400"
              lineHeight="4"
              color="gray.700"
            >
              {imageSrc && (
                <Image boxSize="16px" src={imageSrc} alt={token} mr="4px" />
              )}
              {token.toUpperCase()}
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default TokenChips
