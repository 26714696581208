import { Modal, ModalOverlay } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useStorage } from '../../../providers/storage'
import { ConditionsOfUse, tosAcceptedStorageKey } from './ConditionsOfUse'
import { UnableToConnect } from './UnableToConnect'
import { SelectWalletProvider } from './SelectWalletProvider'
import { ToSPanelId } from './modal'

const rejection = (error: string) =>
  error.includes('User rejected the request.')

export default function ToSModal({
  isOpen,
  onClose,
  onConnectMetamask,
  onConnectWalletconnect,
  defaultPanel = 1,
  account,
  walletActivationError,

  resetModalSignal,
}: {
  isOpen: boolean
  onClose: () => void
  onConnectMetamask?: () => void
  onConnectWalletconnect?: () => void
  onConnectCoinbase?: () => void
  defaultPanel?: ToSPanelId
  account: string | undefined
  walletActivationError?: string

  resetModalSignal?: {}
}) {
  const { storage } = useStorage()
  const [panel, setPanel] = useState<ToSPanelId>(defaultPanel)

  // Reset the modal if a new object is passed to resetModalSignal
  useEffect(() => {
    if (resetModalSignal) {
      setPanel(defaultPanel)
    }
  }, [defaultPanel, resetModalSignal])

  // Skip conditions of use if already accepted
  useEffect(() => {
    if (panel === 1 && storage.getItem(tosAcceptedStorageKey) === 'true') {
      setPanel(2)
    }
  }, [panel, storage])

  // Close the modal if the user has connected their wallet properly
  useEffect(() => {
    if (panel === 3 && account) {
      console.log("Closing ToS modal because user's wallet is connected")
      onClose()
    }
  }, [account, onClose, panel])

  // is walletActivationError revert to panel(2)
  useEffect(() => {
    if (walletActivationError && rejection(walletActivationError)) {
      console.log('Rejected: returning to previous panel')
      setPanel(2)
    }
  }, [setPanel, walletActivationError])

  const content = useMemo(() => {
    switch (panel) {
      case 1:
        return <ConditionsOfUse onNextPanel={() => setPanel(2)} />
      case 2:
        return (
          <SelectWalletProvider
            key={walletActivationError}
            onConnectMetamask={onConnectMetamask}
            onConnectWalletconnect={onConnectWalletconnect}
            onNextPanel={() => {
              console.log(
                "Setting panel to 3 because user's wallet is connecting"
              )
              setPanel(3)
            }}
          />
        )
      case 3:
        if (walletActivationError && !rejection(walletActivationError)) {
          return <UnableToConnect />
        } else {
          return undefined
        }
      default:
        return undefined
    }
  }, [panel, onConnectMetamask, onConnectWalletconnect, walletActivationError])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      size="lg"
      // variant="connectModal"
    >
      <ModalOverlay />
      {content}
    </Modal>
  )
}
