import { ToastId } from '@chakra-ui/react'
import { useEffect, useState, useCallback, useRef } from 'react'
import { captureException } from '@sentry/react'
import { isAddress } from 'ethers/lib/utils'
import { useLocation } from 'react-router-dom'
import { useStorage } from '../../providers/storage'
import { useTermToast } from '../toasts'
import { termToastMessages } from '../../helpers/toasts'
import { Address } from '../../data/model'
import ReplaceReferralCodeToast from '../../components/elements/ReplaceReferralCodeToast'
import ReferralCodeIsUserToast from '../../components/elements/ReferralCodeIsUserToast'

const STORAGE_KEY = 'referrer'

const isValidCode = (code: string | null | undefined): boolean =>
  !!code && isAddress(code)

export function useReferralCodes(account: Address | undefined): {
  referralCode: Address | undefined
  deleteReferralCode: () => void
} {
  const termToast = useTermToast()
  const location = useLocation()
  const { storage } = useStorage()

  // refs to open toasts
  const matchesRef = useRef<null | ToastId>(null)
  const addedRef = useRef<null | ToastId>(null)
  const replacesRef = useRef<null | ToastId>(null)

  const [referralCode, setReferralCode] = useState<Address | undefined>(
    undefined
  )

  // only function that should set storage item and internal state to a some code
  const saveCodeIfValid = useCallback(
    (referralAddr: string | null | undefined, onlyInternalSet?: boolean) => {
      if (!!referralAddr && isValidCode(referralAddr)) {
        setReferralCode(referralAddr)
        if (onlyInternalSet) {
          return // return
        }
        // Store referral data in local storage
        storage.setItem(STORAGE_KEY, referralAddr)
        // remove existing addedCode toasts
        if (!!addedRef.current) {
          termToast.close(addedRef.current)
          addedRef.current = null
        }
        // display added code toast
        addedRef.current = termToast.success(
          termToastMessages.referral.added(referralAddr)
        )
      } else {
        // TODO handle invalid address
      }
    },
    [setReferralCode, termToast]
  )

  const deleteReferralCode = useCallback(() => {
    try {
      const activeAddress = storage.getItem(STORAGE_KEY)
      if (activeAddress) {
        if (!!addedRef.current) {
          termToast.close(addedRef.current)
          addedRef.current = null
        }
        storage.removeItem(STORAGE_KEY)
        setReferralCode(undefined)
      } else {
        throw new Error('No existing referrer found, nothing to delete')
      }
    } catch (e) {
      captureException(e)
      console.error('Error deleting referral data', e)
      termToast.failure(termToastMessages.referral.deleteFailure)
    }
  }, [storage, termToast])

  // connected account
  useEffect(() => {
    if (!!account) {
      const activeAddress = storage.getItem(STORAGE_KEY)

      const removeExistingInvalidToast = () => {
        if (!!matchesRef.current) {
          termToast.close(matchesRef.current)
          matchesRef.current = null
        }
      }
      if (
        activeAddress &&
        activeAddress.toLowerCase() === account.toLowerCase()
      ) {
        removeExistingInvalidToast()
        matchesRef.current = termToast.custom({ duration: null }, (props) => {
          return (
            <ReferralCodeIsUserToast
              connectedAddress={account}
              referralAddress={activeAddress}
              onRemove={() => {
                deleteReferralCode()
                props.onClose()
              }}
            />
          )
        })
      } else {
        removeExistingInvalidToast()
        saveCodeIfValid(activeAddress)
      }
    }
  }, [account, referralCode, termToast, deleteReferralCode, storage])

  // disconnected account automatically updated internal code state
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const incomingAddress = queryParams.get('auctionRef')
    if (!!incomingAddress && isValidCode(incomingAddress)) {
      const activeAddress = storage.getItem(STORAGE_KEY)
      if (!activeAddress) {
        console.info('No existing referrer found, saving now...')
        saveCodeIfValid(incomingAddress)
      } else if (incomingAddress !== activeAddress) {
        saveCodeIfValid(activeAddress, true) // save existing activeAddress locally
        if (!!replacesRef.current) {
          termToast.close(replacesRef.current)
          replacesRef.current = null
        }
        console.info(
          'Referral code already present',
          incomingAddress,
          activeAddress
        )
        replacesRef.current = termToast.custom({ duration: null }, (props) => (
          <ReplaceReferralCodeToast
            existingAddress={activeAddress}
            replacementAddress={incomingAddress}
            onReplace={() => {
              console.info(`Replacing ${activeAddress} with ${incomingAddress}`)
              saveCodeIfValid(incomingAddress)
              props.onClose()
            }}
            onKeep={() => {
              console.info('Keeping existing referral address', activeAddress)
              // do nothing
              props.onClose()
            }}
          />
        ))
      }
    }
  }, [saveCodeIfValid, location.search, termToast, storage])

  return {
    referralCode,
    deleteReferralCode,
  }
}
