import { Button, ButtonProps, Text } from '@chakra-ui/react'

export default function ClearButton({
  onClick,
  isDisabled,
  label,
}: { label: string } & ButtonProps) {
  return (
    <Button
      onClick={onClick}
      isDisabled={isDisabled}
      variant="link"
      p={0}
      mt={0}
    >
      <Text as="span" variant="body-sm/semibold" color="blue.5">
        {label}
      </Text>
    </Button>
  )
}
