// This document contains a simple query language for interacting with JSON REST APIs.
//
// Some illustrative examples:
//
// {
//   "1": {
//     "token": {
//       "#call": {
//         "url": "https://api.example.com/token",
//         "method": "GET"
//       }
//     }
//   },
//   "137": {
//     "token": {
//       "#call": {
//         "url": "https://api.example.com/token",
//         "method": "GET"
//       }
//     }
//   }
// }
//
// Would yield a result of the form:
//
// {
//   "1": {
//     "token": { "balance": 100 }
//   },
//   "137": {
//     "token": { "balance": 200 }
//   }
// }
//
// The query language supports nested objects and arrays, as well as calls to REST APIs.
// Objects and arrays are represented as their normal JSON selves while calls are represented
// as objects with a single key "#call" containing the call information.
//
// NOTE: These helper functions are separated from the main rest.tsx file to make
//       testing easier.

export type JsonRestQueryObjectNode = {
  [key: string]: JsonRestQueryNode
}

export type JsonRestQueryArrayNode = JsonRestQueryNode[]

export type JsonRestQueryCallNode = {
  '#call': RequestInfo
}

export type JsonRestQueryNode =
  | JsonRestQueryObjectNode
  | JsonRestQueryArrayNode
  | JsonRestQueryCallNode

export type JsonRestResponseObjectNode = {
  [key: string]: JsonRestResponseNode
}

export type JsonRestResponseArrayNode = JsonRestResponseNode[]

export type JsonRestResponseNode =
  | JsonRestResponseObjectNode
  | JsonRestResponseArrayNode
  | any

type QueryPath = (string | number)[]

export const flattenQueryNode = (
  node: JsonRestQueryNode,
  currentPath: QueryPath = []
): { calls: RequestInfo[]; paths: QueryPath[] } => {
  const calls: RequestInfo[] = []
  const paths: QueryPath[] = []

  if (Array.isArray(node)) {
    node.forEach((subNode, index) => {
      const { calls: subCalls, paths: subPaths } = flattenQueryNode(subNode, [
        ...currentPath,
        index,
      ])
      calls.push(...subCalls)
      paths.push(...subPaths.map((path) => [...path, index]))
    })
  } else if (typeof node === 'object') {
    if (node['#call']) {
      calls.push((node as JsonRestQueryCallNode)['#call'])
      paths.push(currentPath)
    } else {
      Object.entries(node).forEach(([key, subNode]) => {
        const { calls: subCalls, paths: subPaths } = flattenQueryNode(subNode, [
          ...currentPath,
          key,
        ])
        calls.push(...subCalls)
        paths.push(...subPaths.map((path) => [...path, key]))
      })
    }
  } else {
    throw new Error(`Invalid node type: ${typeof node}`)
  }

  return { calls, paths }
}

export const normalizeFlatResults = (
  flatResults: any[],
  paths: QueryPath[]
): JsonRestResponseNode => {
  let resultContainer: { result?: JsonRestResponseNode } = {}

  // Ensure that results and paths are the same length
  if (flatResults.length !== paths.length) {
    throw new Error(
      `Results length (${flatResults.length}) does not match paths length (${paths.length})`
    )
  }

  for (let i = 0; i < flatResults.length; i++) {
    const path = paths[i]
    const result = flatResults[i]

    // Start at the root of the result container
    let grandParent: JsonRestResponseNode = resultContainer
    let parentPath: string | number = 'result'
    for (const element of path) {
      // If the element is a number, it's an array index
      if (typeof element === 'number') {
        let parent = grandParent[parentPath] as JsonRestResponseArrayNode

        // Check for the special case of this being an empty root node
        if (!parent) {
          grandParent[parentPath] = []
          parent = grandParent[parentPath] as JsonRestResponseArrayNode
        }

        // Ensure that the parent is an array
        if (!Array.isArray(parent)) {
          throw new Error(`Expected array at path ${path}`)
        }

        // Ensure that the array is long enough to hold this element
        while (parent.length <= element) {
          parent.push(null)
        }

        // Move down to the next level
        grandParent = parent
        parentPath = element
      } else if (typeof element === 'string') {
        let parent: JsonRestResponseNode | undefined = grandParent[parentPath]

        // Check for the special case of this being an empty root node
        if (!parent) {
          grandParent[parentPath] = {}
          parent = grandParent[parentPath] as JsonRestResponseObjectNode
        }

        // Ensure that the parent is an object
        if (typeof parent !== 'object') {
          throw new Error('Invalid query path: object key on non-object node')
        }

        // Move down to the next level
        grandParent = parent
        parentPath = element
      } else {
        throw new Error('Invalid query path')
      }
    }

    // Assign the results to the final node
    grandParent[parentPath] = result
  }

  return resultContainer.result
}
