import { Auction } from '../data/model'

export type SortOrder = 'asc' | 'desc'

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export const sortByAuctionEndLast = (a: Auction, b: Auction): number =>
  b.auctionEndTimestamp - a.auctionEndTimestamp

export const sortByAuctionEndFirst = (a: Auction, b: Auction): number =>
  a.auctionEndTimestamp - b.auctionEndTimestamp
