import { Flex, Link, Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useCurrentTime } from '../../../data/hooks/helper-hooks'
import { CONTAINER_PADDING } from '../../../helpers/constants'
import { externalLinks } from '../../../links'
import socialLinkContent, { socialLinks } from '../../../socials'
import RefreshButton from '../../elements/RefreshButton'
import { HStack } from '../../elements/Stack'

const navLinkContent = [
  {
    label: 'Privacy Policy',
    href: externalLinks.privacyPolicy,
  },
  {
    label: 'Terms of Use',
    href: externalLinks.termsOfUse,
  },

  {
    label: 'Documentation',
    href: socialLinks.gitbook,
  },
]

export default function Footer() {
  const now = useCurrentTime()
  const isOnPortfolioPage = useLocation().pathname.includes('/portfolio')

  return (
    <Flex
      height="60px"
      w="full"
      bottom="0"
      position="absolute"
      px={CONTAINER_PADDING}
      py="20px"
      borderTop="1px solid"
      borderColor="gray.2"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        justifyContent="center"
        position="absolute"
        bottom="calc(100% + 12px)"
        pl={isOnPortfolioPage ? '184px' : '80px'}
        pr={isOnPortfolioPage ? '20px' : '80px'}
        left={0}
        right={0}
      >
        <Text variant="body-xs/normal" color="gray.4" textAlign="center">
          THE INTERFACE AND RELATED SERVICES ARE INFORMATIONAL AND ARE NOT A
          SOLICITATION, RECOMMENDATION, OR OFFER TO BUY OR SELL ANY FINANCIAL
          PRODUCT OR INSTRUMENT, OR ENTER INTO ANY TRANSACTION.
        </Text>
      </Flex>
      <Flex
        alignItems={{
          base: 'flex-start',
          md: 'center',
        }}
        columnGap="20px"
        rowGap="12px"
        direction={{
          base: 'column',
          md: 'row',
        }}
      >
        <Text as="span" variant="body-sm/normal" color="blue.9">
          &copy; {now.year()} Term Finance
        </Text>
        <Flex alignItems="center" columnGap="8px">
          {socialLinkContent.map(({ alt, href, icon }) => (
            <Link
              key={alt}
              href={href}
              isExternal
              color="blue.5"
              _hover={{ textDecoration: 'none' }}
            >
              <img src={icon} alt={alt} width="20px" height="20px" />
            </Link>
          ))}
        </Flex>
        {RefreshButton()}
      </Flex>
      <HStack>
        <Flex
          alignItems={{
            base: 'flex-start',
            md: 'center',
          }}
          columnGap="16px"
          rowGap="12px"
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          {navLinkContent.map(({ label, href }) => (
            <Link key={href} href={href} textDecoration="none" isExternal>
              <Text as="span" variant="body-sm/normal">
                {label}
              </Text>
            </Link>
          ))}
        </Flex>
      </HStack>
    </Flex>
  )
}
