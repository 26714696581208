// https://docs.metamask.io/wallet/reference/wallet_requestpermissions
export const requestAccounts = async (): Promise<string[]> => {
  const library = (window as any).ethereum

  return library
    .request({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    })
    .then((res: any) => {
      const accounts = res?.[0]?.caveats?.[0]?.value
      return accounts
    })
}

export const getAccountSignature = async (
  account: string,
  challenge: string
): Promise<string> => {
  const library = (window as any).ethereum
  return library.request({
    method: 'personal_sign',
    params: [challenge, account],
  })
}
