import { ChainId } from '@usedapp/core'
import unknown from '../assets/icons/error.svg'
//import arbitrum from '../assets/icons/network/ArbitrumChain.svg'
//import base from '../assets/icons/network/BaseChain.svg'
//import bsc from '../assets/icons/network/BnbChain.svg'
//import polygon from '../assets/icons/network/PolygonChain.svg'
import ethereum from '../assets/icons/network/EthereumChain.svg'
import avalanche from '../assets/icons/network/AvalancheChain.svg'

import mumbai from '../assets/icons/network/MumbaiChain.svg'
import sepolia from '../assets/icons/network/SepoliaChain.svg'

import avalanche_white from '../assets/icons/network/white/AvalancheChain.svg'
import ethereum_white from '../assets/icons/network/white/EthereumChain.svg'

export const networkIcons: {
  [chainId: number]: string
} = {
  // mainnet
  [ChainId.Mainnet]: ethereum,
  [ChainId.Avalanche]: avalanche,
  // [ChainId.Arbitrum]: arbitrum,
  // [ChainId.Base]: base,
  // [ChainId.BSC]: bsc,
  // [ChainId.Polygon]: polygon,

  // testnet
  [ChainId.Mumbai]: mumbai,
  [ChainId.Sepolia]: sepolia,

  [-1]: unknown,
}

export const networkWhiteIcons: {
  [chainId: number]: string
} = {
  // mainnet
  [ChainId.Mainnet]: ethereum_white,
  [ChainId.Avalanche]: avalanche_white,

  // testnet
  [ChainId.Sepolia]: ethereum_white,

  [-1]: unknown,
}
