import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { Dictionary, sumBy, values } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import AuctionStatus from '../../components/elements/AuctionStatus'
import NoAuctionsFound from '../../components/elements/NoAuctionsFound'
import Sidebar from '../../components/layout/Sidebar'
import {
  Address,
  Auction,
  AuctionActivityData,
  Currency,
  AuctionStatus as Status,
  TermPeriod,
} from '../../data/model'
import {
  CONTAINER_PADDING,
  MOBILE_CONTAINER_PADDING,
} from '../../helpers/constants'
import { sortByAuctionEndFirst, sortByAuctionEndLast } from '../../helpers/sort'

import { CheckboxOption } from '../../components/layout/Sidebar/Filter'
import AuctionBox from './elements/AuctionBox'
import AuctionBoxLoading from './elements/AuctionBoxLoading'
import AuctionModal from './elements/AuctionModal'

export type Term = Pick<
  TermPeriod,
  | 'id'
  | 'repoServicerAddress'
  | 'rolloverManagerAddress'
  | 'collateralManagerAddress'
  | 'termRepoLockerAddress'
  | 'termRepoTokenAddress'
>

export default function AuctionsPage(viewModel: {
  isDataLoading: boolean
  currencies: Record<string, Currency[]> | null | undefined
  auctionsByStatus: Dictionary<Auction[]>
  termsById: Dictionary<Term>
  auctionActivityData: { [auctionAddress: Address]: any } | null | undefined
  loanTermOptions: CheckboxOption<'term'>[]
  lendingOptions: CheckboxOption<'lending'>[]
  collateralOptions: CheckboxOption<'collateral'>[]
  statusOptions: CheckboxOption<'status'>[]
  chainOptions: CheckboxOption<'chain'>[]
  onViewAuction: (auctionAddress: string, chainId: string) => void
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalProps, setModalProps] = useState<
    | {
        status: Status
        auction: Auction
        term: Term
      }
    | undefined
  >()

  const hasNoResults = useMemo(
    () =>
      !viewModel.isDataLoading &&
      sumBy(values(viewModel.auctionsByStatus), 'length') === 0,
    [viewModel.auctionsByStatus, viewModel.isDataLoading]
  )

  const sortedAuctions = useMemo(() => {
    return {
      clearing:
        viewModel.auctionsByStatus['clearing'].sort(sortByAuctionEndLast),
      live: viewModel.auctionsByStatus['live'].sort(sortByAuctionEndFirst),
      upcoming: viewModel.auctionsByStatus['upcoming'].sort(
        sortByAuctionEndFirst
      ),
      closed: viewModel.auctionsByStatus['closed'].sort(sortByAuctionEndLast),
    }
  }, [viewModel.auctionsByStatus])

  const onViewAuctionModal = useCallback(
    (status: Status, auction: Auction, term: Term) => {
      setModalProps({
        status,
        auction,
        term,
      })
      onOpen()
    },
    [setModalProps]
  )

  return (
    <Flex
      w="100%"
      flexDirection="column"
      px={{
        base: MOBILE_CONTAINER_PADDING,
        '1xl': CONTAINER_PADDING,
      }}
      pt={4}
    >
      <Flex>
        <Sidebar
          isDataLoading={viewModel.isDataLoading}
          loanTermOptions={viewModel.loanTermOptions}
          lendingOptions={viewModel.lendingOptions}
          collateralOptions={viewModel.collateralOptions}
          statusOptions={viewModel.statusOptions}
          chainOptions={viewModel.chainOptions}
        />
        <Box flex="1">
          {/* Shows skeleton state for 3 open and 3 upcoming auctions */}
          {viewModel.isDataLoading ? (
            <>
              <AuctionRowsEmpty status="clearing" />
              <AuctionRowsEmpty status="upcoming" />
            </>
          ) : (
            <>
              {!viewModel.isDataLoading && hasNoResults ? (
                <NoAuctionsFound />
              ) : (
                <>
                  <Box
                    position="fixed"
                    right={{
                      base: MOBILE_CONTAINER_PADDING,
                      xl: CONTAINER_PADDING,
                    }}
                    top="80px"
                    zIndex={2}
                  ></Box>
                  <AuctionRows
                    currencies={viewModel.currencies}
                    auctions={sortedAuctions.clearing}
                    terms={viewModel.termsById}
                    auctionActivityData={viewModel.auctionActivityData}
                    status="clearing"
                    onViewAuction={viewModel.onViewAuction}
                    onViewAuctionModal={onViewAuctionModal}
                  />
                  <AuctionRows
                    currencies={viewModel.currencies}
                    auctions={sortedAuctions.live}
                    terms={viewModel.termsById}
                    status="live"
                    auctionActivityData={viewModel.auctionActivityData}
                    onViewAuction={viewModel.onViewAuction}
                    onViewAuctionModal={onViewAuctionModal}
                  />
                  <AuctionRows
                    currencies={viewModel.currencies}
                    auctions={sortedAuctions.upcoming}
                    terms={viewModel.termsById}
                    status="upcoming"
                    onViewAuction={viewModel.onViewAuction}
                    onViewAuctionModal={onViewAuctionModal}
                  />
                  <AuctionRows
                    currencies={viewModel.currencies}
                    auctions={sortedAuctions.closed}
                    terms={viewModel.termsById}
                    status="closed"
                    onViewAuction={viewModel.onViewAuction}
                    onViewAuctionModal={onViewAuctionModal}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Flex>
      {modalProps && (
        <AuctionModal
          status={modalProps.status}
          auction={modalProps.auction}
          term={modalProps.term}
          currencies={viewModel.currencies ?? {}}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Flex>
  )
}

function AuctionRows({
  currencies,
  auctions,
  terms,
  auctionActivityData,
  status,
  onViewAuction,
  onViewAuctionModal,
}: {
  currencies: Record<string, Currency[]> | null | undefined
  auctions: Auction[]
  terms: Dictionary<Term>
  auctionActivityData?:
    | { [auctionAddress: Address]: AuctionActivityData }
    | null
    | undefined
  status: Status
  onViewAuction: (auctionAddress: string, chainId: string) => void
  onViewAuctionModal: (
    auctionStatus: Status,
    auction: Auction,
    term: Term
  ) => void
}) {
  if (auctions.length === 0 || !currencies) {
    return null
  }
  return (
    <Box mb="36px">
      <AuctionStatus status={status} />
      {/* Grid with 1 column above xl breakpoint, 2 columns above md, and 1 column for base  */}
      <Grid
        templateColumns={{
          base: '1fr',
          md: '1fr 1fr',
          xl: '1fr',
        }}
        gap={6}
      >
        {auctions.map((auction) => (
          <AuctionBox
            key={auction?.auctionId}
            currencies={currencies}
            auction={auction}
            term={terms[auction?.termId]}
            auctionActivity={auctionActivityData?.[auction.auctionId]}
            onViewAuction={() =>
              onViewAuction(auction?.address, auction?.chainId)
            }
            onViewAuctionModal={onViewAuctionModal}
          />
        ))}
      </Grid>
    </Box>
  )
}

function AuctionRowsEmpty({ status }: { status: Status }) {
  return (
    <Box mb="36px">
      <AuctionStatus status={status} />
      {/* Grid with 1 column above xl breakpoint, 2 columns above md, and 1 column for base  */}
      <Grid
        templateColumns={{
          base: '1fr',
          md: '1fr 1fr',
          xl: '1fr',
        }}
        gap={5}
      >
        {Array.from({ length: 3 }).map((_, i) => (
          <AuctionBoxLoading key={i} />
        ))}
      </Grid>
    </Box>
  )
}
