import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  VStack,
} from '@chakra-ui/react'
import { ModalHeading, ModalBody } from './modal'
import closeIcon from '../../../../assets/icons/close.svg'

export default function LinkWalletModal({
  account,
  isOpen,
  onSignUp,
  onClose,
  onLinkWallet,
}: {
  account?: string
  isOpen: boolean
  onSignUp: () => void
  onClose: () => void
  onLinkWallet?: (isSkipWalletAnalyzerAnimation?: boolean) => Promise<void>
}) {
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none">
        <Box bg="white" pt={7} borderRadius="6px" w="480px" position="relative">
          <Image
            position="absolute"
            cursor="pointer"
            top={7}
            right={7}
            w="24px"
            p={'4px'}
            src={closeIcon}
            onClick={() => onClose()}
          />
          <VStack align="left" gap={5}>
            <ModalHeading />
            <ModalBody
              account={account}
              onSignMessage={onLinkWallet}
              onSignUp={onSignUp}
              onClose={onClose}
            />
          </VStack>
        </Box>
      </ModalContent>
    </Modal>
  )
}
