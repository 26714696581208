import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { BigNumberish, FixedNumber } from 'ethers'
import { useCallback, useMemo, useReducer } from 'react'
import { Currency } from '../../../../data/model'
import { evaluate } from '../../../../helpers/math'
import {
  formatFixedToken,
  formatFixedUsd,
  parseUserInputCurrencyAmountFN,
} from '../../../../helpers/utils'
import { TransactionState } from '@usedapp/core'
import { calculateExcess } from '../../utils'
import ManageMarginField from '../ManageMarginField'
import ManageMarginSummary from '../ManageMarginSummary'
import DepositButton from './DepositButton'
import TabButtons from './TabButtons'
import WithdrawButton from './WithdrawButton'
import { Trans, t } from '@lingui/macro'

function ExcessDepositedCollateral({
  excess,
  excessAfter,
  collateralCurrency,
  setAmount,
}: {
  excess: FixedNumber
  excessAfter: FixedNumber
  collateralCurrency: Currency
  setAmount: (value: string) => void
}) {
  return (
    <Text
      as="span"
      textColor={excessAfter?.isNegative() ? 'red.5' : undefined}
      cursor="pointer"
      _hover={{ textDecoration: 'underline' }}
      onClick={() => setAmount(excess.toString())}
    >
      <Trans>Available:</Trans>{' '}
      {formatFixedToken(excess, collateralCurrency.symbol, true)}
    </Text>
  )
}

function AvailableWalletCollateral({
  collateralWalletBalance,
  collateralWalletBalanceAfter,
  collateralCurrency,
  setAmount,
}: {
  collateralWalletBalance: FixedNumber
  collateralWalletBalanceAfter: FixedNumber
  collateralCurrency: Currency
  setAmount: (value: string) => void
}) {
  return (
    <Text
      as="span"
      textColor={
        collateralWalletBalanceAfter?.isNegative() ? 'red.5' : undefined
      }
      cursor="pointer"
      _hover={{
        textDecoration: 'underline',
      }}
      onClick={() => setAmount(collateralWalletBalance.toString())}
    >
      <Trans>Available:</Trans>{' '}
      {formatFixedToken(
        collateralWalletBalance,
        collateralCurrency.symbol,
        true
      )}
    </Text>
  )
}

function DepositHelperText({
  usdValueOfAmount,
  collateralWalletBalanceAfter,
  pastRepayment,
}: {
  usdValueOfAmount: FixedNumber
  amount: FixedNumber
  collateralWalletBalance: FixedNumber
  collateralWalletBalanceAfter: FixedNumber
  collateralCurrency: Currency
  pastRepayment: Boolean
}) {
  if (pastRepayment) {
    return (
      <Text color="red">
        <Trans>Collateral deposits closed after repayment</Trans>
      </Text>
    )
  }
  if (collateralWalletBalanceAfter.isNegative()) {
    return (
      <Text color="red">
        <Trans>Insufficient collateral in connected wallet</Trans>
      </Text>
    )
  }

  return <Text>{formatFixedUsd(usdValueOfAmount)}</Text>
}

function WithdrawHelperText({
  usdValueOfAmount,
  excessAfter,
  pastRedemption,
}: {
  usdValueOfAmount: FixedNumber
  excessAfter: FixedNumber
  pastRedemption: Boolean
}) {
  if (excessAfter.isNegative() && !pastRedemption) {
    return (
      <Text color="red">
        <Trans>Insufficient excess collateral</Trans>
      </Text>
    )
  }

  if (excessAfter.isNegative() && pastRedemption) {
    return (
      <Text color="red">
        <Trans>Insufficient deposited collateral to withdrawal</Trans>
      </Text>
    )
  }

  return <Text>{formatFixedUsd(usdValueOfAmount)}</Text>
}

export default function ManageMarginModal({
  collateralCurrency,
  collateralDeposited,
  collateralWalletBalance,
  collateralTokenPrice,
  purchaseCurrency,
  loanAmountUsd,
  requiredMarginRatio,
  auctionLabel,
  pastRedemption,
  pastRepayment,
  approvedCollateralTokens,

  defaultTab = 'deposit',

  onWithdrawCollateral,
  onDepositCollateral,
  onApproveCollateralTokens,

  isOpen,
  onClose,
}: {
  collateralCurrency: Currency
  collateralDeposited: FixedNumber
  collateralWalletBalance: FixedNumber
  collateralTokenPrice: FixedNumber
  purchaseCurrency: Currency
  loanAmountUsd: FixedNumber
  requiredMarginRatio: FixedNumber
  auctionLabel: string
  pastRedemption: Boolean
  pastRepayment: boolean

  defaultTab?: 'deposit' | 'withdraw'

  onWithdrawCollateral: (
    withdrawnAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  onDepositCollateral: (
    depositedAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>
  approvedCollateralTokens: FixedNumber
  onApproveCollateralTokens: (amount: BigNumberish) => Promise<void>

  isOpen: boolean
  onClose: () => void
}) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    tab: defaultTab || 'withdraw',
  })
  const { tab, amount: amountRaw, amountStr } = state
  const amount = useMemo(() => {
    return (
      amountRaw ??
      FixedNumber.fromString('0', `fixed128x${purchaseCurrency.decimals}`)
    )
  }, [amountRaw, purchaseCurrency.decimals])

  const submitDeposit = useCallback(async () => {
    return onDepositCollateral(amount, false)
  }, [amount, onDepositCollateral])

  const submitWithdraw = useCallback(async () => {
    return onWithdrawCollateral(amount, false)
  }, [amount, onWithdrawCollateral])

  const usdValueOfAmount = useMemo(
    () =>
      collateralTokenPrice
        ? evaluate(
            {
              nodeKind: 'mul',
              args: [
                {
                  nodeKind: 'value',
                  value: amount,
                },
                {
                  nodeKind: 'value',
                  value: collateralTokenPrice,
                },
              ],
            },
            amount.format.decimals
          )
        : amount,
    [amount, collateralTokenPrice]
  )

  const transaction = useMemo(
    () =>
      tab === 'deposit'
        ? amount
        : evaluate(
            {
              nodeKind: 'mul',
              args: [
                {
                  nodeKind: 'value',
                  value: amount,
                },
                {
                  nodeKind: 'value',
                  value: FixedNumber.fromString('-1'),
                },
              ],
            },
            amount.format.decimals
          ),
    [amount, tab]
  )

  // const transactionFN = useMemo(
  //   () =>
  //     FixedNumber.from(
  //       transaction.toString(),
  //       collateralDeposited.format.decimals
  //     ),
  //   [transaction, collateralDeposited]
  // )

  const { excess, excessAfter, requiredUsd } = useMemo(
    () =>
      calculateExcess(
        loanAmountUsd,
        collateralDeposited,
        collateralTokenPrice,
        requiredMarginRatio,
        transaction
      ),
    [
      collateralDeposited,
      collateralTokenPrice,
      loanAmountUsd,
      requiredMarginRatio,
      transaction,
    ]
  )

  const collateralWalletBalanceAfter = useMemo(
    () =>
      evaluate(
        {
          nodeKind: 'sub',
          args: [
            {
              nodeKind: 'value',
              value: collateralWalletBalance,
            },
            {
              nodeKind: 'value',
              value: amount,
            },
          ],
        },
        collateralWalletBalance.format.decimals
      ),
    [amount, collateralWalletBalance]
  )

  const setAmount = (value: string) => {
    dispatch({
      name: 'update-value',
      with: value,
      decimals: collateralCurrency.decimals,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent p="4px">
        <ModalHeader
          color="gray.900"
          fontSize="lg"
          fontWeight="700"
          lineHeight="7"
        >
          <Trans>Manage Collateral</Trans>
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody px={7} pb={7} pt={0}>
          <ManageMarginSummary
            loanAmountUsd={loanAmountUsd}
            balance={collateralDeposited}
            currency={collateralCurrency}
            requiredMarginRatio={requiredMarginRatio}
            tokenPrice={collateralTokenPrice}
            transaction={
              (
                tab === 'deposit'
                  ? collateralWalletBalanceAfter.isNegative()
                  : excessAfter.isNegative()
              )
                ? FixedNumber.fromString('0')
                : transaction
            }
            auctionLabel={auctionLabel}
          />
          <Flex justifyContent="stretch" mt="20px" mb="20px">
            <TabButtons
              isDepositDisabled={
                loanAmountUsd.isZero() || loanAmountUsd.isNegative()
              }
              value={tab}
              onChange={(tab) =>
                dispatch({
                  name: 'choose-tab',
                  tab,
                  decimals: purchaseCurrency.decimals,
                })
              }
            />
          </Flex>
          <ManageMarginField
            label={tab === 'deposit' ? t`Deposit Amount` : t`Withdraw Amount`}
            isDisabled={tab === 'deposit' && pastRepayment ? true : false}
            rightLabel={
              tab === 'deposit' ? (
                <AvailableWalletCollateral
                  collateralWalletBalance={collateralWalletBalance}
                  collateralWalletBalanceAfter={collateralWalletBalanceAfter}
                  collateralCurrency={collateralCurrency}
                  setAmount={setAmount}
                />
              ) : (
                <ExcessDepositedCollateral
                  excess={excess}
                  excessAfter={excessAfter}
                  collateralCurrency={collateralCurrency}
                  setAmount={setAmount}
                />
              )
            }
            helperText={
              tab === 'deposit' ? (
                <DepositHelperText
                  usdValueOfAmount={usdValueOfAmount}
                  amount={amount}
                  collateralWalletBalance={collateralWalletBalance}
                  collateralWalletBalanceAfter={collateralWalletBalanceAfter}
                  collateralCurrency={collateralCurrency}
                  pastRepayment={pastRepayment}
                />
              ) : (
                <WithdrawHelperText
                  usdValueOfAmount={usdValueOfAmount}
                  excessAfter={excessAfter}
                  pastRedemption={pastRedemption}
                />
              )
            }
            isError={
              tab === 'deposit'
                ? collateralWalletBalanceAfter.isNegative()
                : excessAfter.isNegative()
            }
            // Only show Max button if required collateral === 0
            isMaxable={requiredUsd.isZero()}
            maxAmount={excess}
            value={amountStr}
            collateralDecimals={collateralCurrency.decimals}
            onChange={setAmount}
            termTokenSymbol={collateralCurrency.symbol}
          />
          <Spacer mt="20px" />
          {/* {excessAfter.isNegative() || excessAfter.isZero() ? (
            <>
              <ManageMarginWithdrawlWarning />
              <Spacer mt="20px" />
            </>
          ) : (
            <></>
          )} */}
          <Flex justifyContent="center" w="full">
            {tab === 'deposit' ? (
              <DepositButton
                isDisabled={
                  amount.isZero() ||
                  collateralWalletBalanceAfter.isNegative() ||
                  pastRepayment ||
                  (tab === 'deposit' &&
                    collateralWalletBalanceAfter.isNegative()) ||
                  (tab !== 'deposit' &&
                    excessAfter.isNegative() &&
                    !pastRedemption)
                }
                onClick={submitDeposit}
                onApproveCollateralTokens={onApproveCollateralTokens}
                depositAmountParsed={amount}
                approvedCollateralTokens={approvedCollateralTokens}
                collateralCurrency={collateralCurrency}
                onClose={onClose}
              />
            ) : (
              <WithdrawButton
                isDisabled={amount.isZero() || excessAfter.isNegative()}
                onClick={submitWithdraw}
                onClose={onClose}
              />
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

type State = {
  tab: 'deposit' | 'withdraw'
  amountStr: string
  amount: FixedNumber | undefined
}

const initialState: State = {
  tab: 'deposit',
  amountStr: '',
  amount: undefined,
}

type Action = ChooseTab | UpdateAmount
interface ChooseTab {
  name: 'choose-tab'
  tab: State['tab']
  decimals: number
}
interface UpdateAmount {
  name: 'update-value'
  with: string
  decimals: number
}

function reducer(state: State, action: Action): State {
  switch (action.name) {
    case 'choose-tab': {
      return {
        tab: action.tab,
        amountStr: '',
        amount: FixedNumber.fromString('0', `fixed128x${action.decimals}`),
      }
    }
    case 'update-value': {
      return {
        ...state,
        amountStr: action.with,
        amount: parseUserInputCurrencyAmountFN(
          action.with,
          action.decimals,
          '0'
        ),
      } as State
    }
  }
}
