import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { captureException } from '@sentry/react'
import { ChainId, TransactionStatus, useSendTransaction } from '@usedapp/core'
import {
  BigNumber,
  BigNumberish,
  Contract,
  FixedNumber,
  Signer,
  constants,
} from 'ethers'
import { isAddress, randomBytes, solidityKeccak256 } from 'ethers/lib/utils'
import { range } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 } from 'uuid'
import { PageAuctionQuery } from '../../gql/graphql'
import {
  fixedToBigNumber,
  getGeneratedTenderId,
  getABIVersion,
} from '../../helpers/conversions'
import { retryUntil } from '../../helpers/retry'
import { waitForStatus } from '../../helpers/wait'
import { useStorage } from '../../providers/storage'
import { useClock } from '../../providers/time'
import {
  createOrUpdatePrivateBorrowTender,
  deserializeBorrowTender,
} from '../../services/private-bids-and-offers'
import { generateTermAuth } from '../auth'
import {
  Address,
  Amount,
  RevealTenderRequest,
  SubmittedBorrowTender,
  TenderId,
  ValidatedBorrowTender,
} from '../model'
import { obfuscatePrice } from '../obfuscate'
import { useTxTimestamp } from './helper-hooks'
import { EvmError, decodeError } from '../../helpers/evm'
import {
  TermAuctionBidSubmissionStruct as TermAuctionBidSubmissionStruct_0_2_4,
  TermAuctionBidLocker as TermAuctionBidLocker_0_2_4,
} from '../../abi-generated/abi/v0.2.4/TermAuctionBidLocker'
import {
  TermAuctionBidSubmissionStruct as TermAuctionBidSubmissionStruct_0_4_1,
  TermAuctionBidLocker as TermAuctionBidLocker_0_4_1,
} from '../../abi-generated/abi/v0.4.1/TermAuctionBidLocker'
import {
  TermAuctionBidSubmissionStruct as TermAuctionBidSubmissionStruct_0_6_0,
  TermAuctionBidLocker as TermAuctionBidLocker_0_6_0,
} from '../../abi-generated/abi/v0.6.0/TermAuctionBidLocker'
import ReactGA from 'react-ga4'

import TermAuctionBidLockerABI_0_2_4 from '../../abi/v0.2.4/TermAuctionBidLocker.json'
import TermAuctionBidLockerABI_0_4_1 from '../../abi/v0.4.1/TermAuctionBidLocker.json'
import TermAuctionBidLockerABI_0_6_0 from '../../abi/v0.6.0/TermAuctionBidLocker.json'
import { useConfig } from '../../providers/config'

declare global {
  interface Window {
    safary?: {
      track: (args: {
        eventType: string
        eventName: string
        parameters: { [key: string]: string }
      }) => void
    }
  }
}

export type TermAuctionBidLocker =
  | TermAuctionBidLocker_0_2_4
  | TermAuctionBidLocker_0_4_1
  | TermAuctionBidLocker_0_6_0

// We load tenders from both the smart contract and localstorage. localStorage stores unrevealed prices.
export function useBorrowTenders(
  chainId: ChainId,
  version: string,
  account: Address | undefined,
  auction: Address,
  decimals: { [chainId: string]: { [address: string]: number } } | undefined,
  prices: { [chainId: string]: { [address: string]: FixedNumber } } | undefined,
  bidLockerAddress: Address | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  queryResult: PageAuctionQuery['termBids'] | undefined,
  readFromSubgraph: () => void,
  revealServerUrl?: string
): [
  // borrow tenders list
  SubmittedBorrowTender[] | undefined,
  // reload tenders
  () => void,
  // delete tenders
  (
    | ((
        ids: TenderId[],
        bidLockerAddress: Address,
        contractVersion: string
      ) => Promise<void>)
    | undefined
  ),
  // lock tenders
  (
    | ((
        tenders: ValidatedBorrowTender[],
        lender: string,
        termId: string,
        auctionId: string,
        bidLockerAddress: Address,
        contractVersion: string,
        referralCode?: string
      ) => Promise<SubmittedBorrowTender[] | undefined>)
    | undefined
  ),
] {
  const [localResult, setLocalResult] = useState<SubmittedBorrowTender[]>()
  const [result, setResult] = useState<SubmittedBorrowTender[]>()
  const config = useConfig()
  const clock = useClock()
  const { storage: localStorage } = useStorage()
  const transactionStates = useMemo(
    () =>
      ({}) as {
        lockTenders: TransactionStatus | undefined
        deleteTenders: TransactionStatus | undefined
      },
    []
  )
  const {
    sendTransaction: lockTendersTransaction,
    state: lockTendersTransactionState,
    resetState: resetLockTendersState,
  } = useSendTransaction()
  const {
    sendTransaction: deleteTendersTransaction,
    state: deleteTendersTransactionState,
    resetState: resetDeleteTendersState,
  } = useSendTransaction()
  const lookupTxTimestamp = useTxTimestamp(provider)

  // WARNING: These are used to update in-place so that the lock/delete
  //          methods always have access to the latest transaction state.
  useEffect(() => {
    transactionStates.lockTenders = lockTendersTransactionState
  }, [lockTendersTransactionState, transactionStates])
  useEffect(() => {
    transactionStates.deleteTenders = deleteTendersTransactionState
  }, [deleteTendersTransactionState, transactionStates])

  const bidLockerContract = useMemo(() => {
    const contract: {
      [bidLockerAddress: Address]: {
        bidLocker: TermAuctionBidLocker
        version: string
      }
    } = {}

    if (!bidLockerAddress) {
      return undefined
    }

    if (contract[bidLockerAddress]) {
      return contract
    }

    const tmpContractData = {} as {
      bidLocker: TermAuctionBidLocker
      version: string
    }

    tmpContractData.version = version

    switch (version) {
      case '0.6.0':
        tmpContractData.bidLocker = new Contract(
          bidLockerAddress,
          TermAuctionBidLockerABI_0_6_0,
          provider
        ) as TermAuctionBidLocker_0_6_0
        break
      case '0.4.1':
        tmpContractData.bidLocker = new Contract(
          bidLockerAddress,
          TermAuctionBidLockerABI_0_4_1,
          provider
        ) as TermAuctionBidLocker_0_4_1
        break
      case '0.2.4':
      default:
        tmpContractData.bidLocker = new Contract(
          bidLockerAddress,
          TermAuctionBidLockerABI_0_2_4,
          provider
        ) as TermAuctionBidLocker_0_2_4
    }

    contract[bidLockerAddress] = tmpContractData

    return contract
  }, [bidLockerAddress, provider, version])

  const readFromLocalStorage = useCallback(() => {
    if (account === undefined || auction === undefined) {
      return
    }
    const tenders: SubmittedBorrowTender[] = range(localStorage?.length ?? 0)
      .map((i) => localStorage.key(i))
      .filter((key): key is string => key?.startsWith('borrow-') ?? false)
      .map((key) => localStorage.getItem(key))
      .filter((val): val is string => val !== null)
      .map((val) => deserializeBorrowTender(val))
      .filter((val): val is SubmittedBorrowTender => {
        // TODO: Validate the the borrow tenders have all required fields.
        return val !== undefined
      })
    setLocalResult(
      tenders.filter(
        (t) =>
          t.borrower.toLowerCase() === account.toLowerCase() &&
          t.auction === auction
      )
    )
  }, [account, auction, localStorage])

  const subgraphResultWithoutTimestamps = useMemo(() => {
    if (account === undefined || auction === undefined) {
      return undefined
    }

    // Combine localTenders with remoteTenders. Newer data should overwrite older data. Local data should generally overwrite remote data?
    const tenders: SubmittedBorrowTender[] =
      queryResult
        ?.filter((tender) => tender.locked)
        ?.map(
          (remoteTender) =>
            ({
              type: 'borrow',
              chainId: chainId.toString(),
              id: remoteTender.id,
              amount: {
                currency:
                  remoteTender?.auction?.term?.purchaseToken?.toString(),
                shiftedValue: BigNumber.from(remoteTender.amount?.toString()),
              },
              collateral:
                remoteTender?.bidCollateral?.[0]?.collateralToken &&
                remoteTender?.bidCollateral?.[0]?.amount
                  ? {
                      currency:
                        remoteTender.bidCollateral[0].collateralToken.toString(),
                      shiftedValue: BigNumber.from(
                        remoteTender.bidCollateral[0].amount ?? 0
                      ),
                    }
                  : undefined,
              auction: auction.toString(),
              borrower: remoteTender.bidder as string,
              transaction: remoteTender.lastTransaction,
            }) as SubmittedBorrowTender
        ) ?? ([] as SubmittedBorrowTender[])
    return tenders
  }, [account, auction, chainId, queryResult])

  const [subgraphResult, setSubgraphResult] = useState<
    SubmittedBorrowTender[] | undefined
  >()
  useEffect(() => {
    ;(async () => {
      if (!subgraphResultWithoutTimestamps) {
        setSubgraphResult(subgraphResultWithoutTimestamps)
        return
      }
      const subgraphResult = [] as SubmittedBorrowTender[]
      for (const tender of subgraphResultWithoutTimestamps) {
        const txTimestamp = tender.transaction
          ? await lookupTxTimestamp(tender.transaction)
          : undefined
        if (txTimestamp) {
          tender.submittedDate = txTimestamp
        }
        subgraphResult.push(tender)
      }
      setSubgraphResult(subgraphResult)
    })()
  }, [lookupTxTimestamp, subgraphResultWithoutTimestamps])

  const combineResults = useCallback(() => {
    const localIds = localResult?.reduce((acc, val) => {
      acc.add(val.id)
      return acc
    }, new Set<string>())
    const remoteIds = subgraphResult?.reduce((acc, val) => {
      acc.add(val.id)
      return acc
    }, new Set<string>())
    const localOnly = localResult?.filter((val) => !remoteIds?.has(val.id))
    const remoteOnly = subgraphResult?.filter((val) => !localIds?.has(val.id))
    const both = localResult
      ?.filter((val) => remoteIds?.has(val.id))
      .map((local) => {
        const remote = subgraphResult?.find((remote) => remote.id === local.id)
        return {
          ...remote,
          ...local,
        }
      })

    const result = [
      ...(remoteOnly || []),
      ...(both || []),
      ...(localOnly || []),
    ].sort((a, b) => {
      if (a.submittedDate === undefined) {
        return 1
      }
      if (b.submittedDate === undefined) {
        return -1
      }
      return a.submittedDate - b.submittedDate
    })
    setResult(result)
  }, [subgraphResult, localResult])

  useEffect(() => {
    if (account === undefined || auction === undefined) {
      return
    }
    window.addEventListener('storage', readFromLocalStorage)
    readFromLocalStorage()

    return () => {
      window.removeEventListener('storage', readFromLocalStorage)
      setResult(undefined)
    }
  }, [readFromLocalStorage, account, auction])

  useEffect(combineResults, [subgraphResult, localResult, combineResults])

  const validateActiveNetwork = useCallback(async () => {
    const activeNetwork = await signer?.provider?.getNetwork()
    return activeNetwork?.chainId === chainId
  }, [chainId, signer])

  const deleteTenders = useCallback(
    async (
      ids: TenderId[],
      bidLockerAddress: Address,
      contractVersion: string
    ) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || auction === undefined) {
        return
      }

      if (!bidLockerContract || !signer) {
        return
      }

      // Remove from localstorage
      ids.forEach((id) => localStorage.removeItem(`borrow-${id}`))

      const abiVersion = getABIVersion(contractVersion)

      let data: any

      const bidLocker = bidLockerContract[bidLockerAddress].bidLocker

      switch (abiVersion) {
        case '0.6.0':
          console.info('deleting tenders using 0.6.0 abi')
          data = (
            bidLocker as TermAuctionBidLocker_0_6_0
          ).interface.encodeFunctionData('unlockBids', [ids])
          break
        case '0.4.1':
          console.info('deleting tenders using 0.4.1 abi')
          data = (
            bidLocker as TermAuctionBidLocker_0_4_1
          ).interface.encodeFunctionData('unlockBids', [ids])
          break
        case '0.2.4':
          // Remove from smart contract
          console.info('deleting tenders using 0.2.4 abi')
          data = (
            bidLocker as TermAuctionBidLocker_0_2_4
          ).interface.encodeFunctionData('unlockBids', [
            ids,
            await generateTermAuth(
              signer,
              bidLocker as TermAuctionBidLocker_0_2_4,
              'unlockBids',
              [ids],
              clock.now().unix()
            ),
          ])
          break
      }

      await deleteTendersTransaction({
        chainId,
        to: bidLockerAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deleteTenders,
        resetDeleteTendersState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      readFromSubgraph()
    },
    [
      validateActiveNetwork,
      account,
      auction,
      bidLockerContract,
      signer,
      deleteTendersTransaction,
      chainId,
      resetDeleteTendersState,
      readFromSubgraph,
      localStorage,
      clock,
      transactionStates.deleteTenders,
    ]
  )

  const revealTender = useCallback(
    async (
      termId: string,
      auctionId: string,
      id: string,
      amount: FixedNumber,
      nonce: BigNumber
    ) => {
      if (!revealServerUrl) {
        return
      }
      await fetch(`${revealServerUrl}/reveal-bid`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({
          id,
          termId,
          auctionId,
          price: fixedToBigNumber(amount).div(100).toString(),
          nonce: nonce.toString(),
        } as RevealTenderRequest),
      })
    },
    [revealServerUrl]
  )

  const lockTenders = useCallback(
    async (
      tendersWithMaybeId: ValidatedBorrowTender[],
      bidder: string,
      termId: string,
      auctionId: string,
      bidLockerAddress: Address,
      contractVersion: string,
      referralCode?: string
    ) => {
      if (!(await validateActiveNetwork())) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || auction === undefined) {
        return
      }

      if (!bidLockerContract || !signer) {
        return undefined
      }

      const abiVersion = getABIVersion(contractVersion)

      const tenderOffchainIdToOnchainIdMap = new Map<string, string>()

      const signerAddress = await signer.getAddress()

      const processTenders = (bidLockerContract: TermAuctionBidLocker) => {
        const tenders = tendersWithMaybeId.map((tender) => {
          let tenderId: string
          if (tender.id) {
            tenderId = tender.id
            tenderOffchainIdToOnchainIdMap.set(tenderId, tender.id)
          } else {
            tenderId = solidityKeccak256(['string'], [v4()]) // create uuid that will be used to create a new on chain tender id
            const onChainTenderId = getGeneratedTenderId(
              tenderId,
              bidLockerContract,
              signerAddress
            )
            tenderOffchainIdToOnchainIdMap.set(tenderId, onChainTenderId)
          }

          return {
            ...tender,
            id: tenderId,
            nonce: BigNumber.from(randomBytes(32)),
          }
        })

        return tenders
      }

      let bids: (
        | TermAuctionBidSubmissionStruct_0_2_4
        | TermAuctionBidSubmissionStruct_0_4_1
        | TermAuctionBidSubmissionStruct_0_6_0
      )[] = []
      let data: any
      let tenders: {
        id: string
        nonce: BigNumber
        type: 'borrow'
        amount: Amount
        interestRate?: FixedNumber | undefined
        collateral: Amount
      }[]

      const bidLocker = bidLockerContract[bidLockerAddress].bidLocker

      let abi:
        | TermAuctionBidLocker_0_2_4
        | TermAuctionBidLocker_0_4_1
        | TermAuctionBidLocker_0_6_0

      switch (abiVersion) {
        case '0.6.0':
          console.info('submitting tenders using 0.6.0 abi')
          if (!bidLockerContract) {
            console.error('no bidLockerContract found %o', abiVersion)
            captureException(
              new Error(
                `BidLocker contract not found for version ${abiVersion}`
              )
            )
            return
          }

          tenders = processTenders(bidLocker)

          // Submit to smart contract
          bids = tenders.map((tender) => ({
            id: tender.id,
            amount: tender.amount.shiftedValue,
            bidder,
            bidPriceHash: obfuscatePrice(
              tender.interestRate as FixedNumber,
              tender.nonce
            ),
            purchaseToken: tender.amount.currency,
            collateralAmounts: [tender.collateral.shiftedValue as BigNumberish],
            collateralTokens: [tender.collateral.currency],
            isRollover: false,
            rolloverPairOffTermManager: constants.AddressZero,
          })) as TermAuctionBidSubmissionStruct_0_6_0[]

          abi = bidLocker as TermAuctionBidLocker_0_6_0

          if (referralCode && isAddress(referralCode)) {
            data = abi?.interface.encodeFunctionData('lockBidsWithReferral', [
              bids,
              referralCode,
            ])
          } else {
            data = abi?.interface.encodeFunctionData('lockBids', [bids])
          }

          break
        case '0.4.1':
          console.info('submitting tenders using 0.4.1 abi')
          if (!bidLockerContract) {
            console.error('no bidLockerContract found %o', abiVersion)
            captureException(
              new Error(
                `BidLocker contract not found for version ${abiVersion}`
              )
            )
            return
          }

          tenders = processTenders(bidLocker)

          // Submit to smart contract
          bids = tenders.map((tender) => ({
            id: tender.id,
            amount: tender.amount.shiftedValue,
            bidder,
            bidPriceHash: obfuscatePrice(
              tender.interestRate as FixedNumber,
              tender.nonce
            ),
            purchaseToken: tender.amount.currency,
            collateralAmounts: [tender.collateral.shiftedValue as BigNumberish],
            collateralTokens: [tender.collateral.currency],
            isRollover: false,
            rolloverPairOffTermManager: constants.AddressZero,
          })) as TermAuctionBidSubmissionStruct_0_4_1[]

          abi = bidLocker as TermAuctionBidLocker_0_4_1

          if (referralCode && isAddress(referralCode)) {
            data = abi?.interface.encodeFunctionData('lockBidsWithReferral', [
              bids,
              referralCode,
            ])
          } else {
            data = abi?.interface.encodeFunctionData('lockBids', [bids])
          }

          break
        case '0.2.4':
        default:
          console.info('submitting tenders using 0.2.4 abi')
          if (!bidLockerContract) {
            console.error('no bidlocker contract found for 0.2.4')
            captureException(
              new Error(
                `BidLocker contract not found for version ${abiVersion}`
              )
            )
            return
          }

          tenders = processTenders(bidLocker)

          // Submit to smart contract
          bids = tenders.map((tender) => ({
            id: tender.id,
            amount: tender.amount.shiftedValue,
            bidder,
            bidPriceHash: obfuscatePrice(
              tender.interestRate as FixedNumber,
              tender.nonce
            ),
            purchaseToken: tender.amount.currency,
            collateralAmounts: [tender.collateral.shiftedValue as BigNumberish],
            collateralTokens: [tender.collateral.currency],
            isRollover: false,
            rolloverPairOffTermManager: constants.AddressZero,
          })) as TermAuctionBidSubmissionStruct_0_2_4[]

          abi = bidLocker as TermAuctionBidLocker_0_2_4
          data = abi?.interface.encodeFunctionData('lockBids', [
            bids,
            await generateTermAuth(
              signer,
              bidLocker as TermAuctionBidLocker_0_2_4,
              'lockBids',
              [bids],
              clock.now().unix()
            ),
          ])

          break
      }

      const revealTx = Promise.all(
        tenders.map((tender) =>
          retryUntil(
            () =>
              revealTender(
                termId,
                auctionId,
                tenderOffchainIdToOnchainIdMap.get(tender.id) || tender.id, // for tender id, calculate on chain tender id hash
                tender.interestRate as FixedNumber,
                tender.nonce
              ),
            100,
            1000
          )
        )
      )

      await revealTx

      let resultHash: string | undefined
      try {
        resultHash = (
          await lockTendersTransaction({
            chainId,
            to: bidLockerAddress,
            from: account,
            data,
          })
        )?.transactionHash

        // Wait for state of lock transaction to complete.
        await waitForStatus(
          async () => transactionStates.lockTenders,
          resetLockTendersState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000,
          bidLocker
        )
      } catch (err) {
        if (err instanceof Error) {
          try {
            const decodedErr = decodeError(err, bidLocker.interface)
            captureException(new EvmError(err, decodedErr))
          } catch (e) {
            captureException(e)
            captureException(err)
            console.error(`Failed to decode error: ${JSON.stringify(e)}`)
          }
        } else {
          captureException(err)
        }
        throw err
      }

      const submitted: SubmittedBorrowTender[] = tenders.map((tender) => ({
        ...tender,
        chainId: chainId.toString(),
        submittedDate: clock.now().unix(), // TODO: This should come from the blockchain/graph
        auction,
        id: tenderOffchainIdToOnchainIdMap.get(tender.id) || tender.id, // for tender id, calculate on chain tender id hash
        borrower: account,
        transaction: resultHash || '',
      }))

      // Add to localstorage
      for (const tender of submitted) {
        await createOrUpdatePrivateBorrowTender(tender, localStorage)

        // Convert tender.amount to USD
        const amountFN = FixedNumber.fromValue(
          tender.amount.shiftedValue,
          decimals?.[chainId]?.[tender.amount.currency] ?? 18
        )
        const amountUSD = prices?.[chainId]?.[tender.amount.currency]
          ? amountFN.mulUnsafe(
              prices[chainId][tender.amount.currency].toFormat(amountFN.format)
            )
          : FixedNumber.from(0)
        ReactGA.event('purchase', {
          currency: 'USD',
          value: amountUSD.toUnsafeFloat(),
          transaction_id: `borrow-${resultHash ?? ''}`,
          // coupon: "",  // TODO: Add invite/referral codes here
        })

        // Mark tenders as locked in safary if setup.
        if (window.safary) {
          window.safary.track({
            eventType: config.safary?.bidToBorrow?.type ?? 'click',
            eventName: `Bid ${tender.id} from ${account} to borrow ${tender.amount.shiftedValue} ${tender.amount.currency}`,
            parameters: {
              tenderId: tender.id,
              borrower: account,
              amount: tender.amount.shiftedValue.toString(),
              currency: tender.amount.currency,
              auctionId: auctionId,
              termId: termId,
              transactionId: resultHash ?? '',
            },
          })
        }
      }

      readFromSubgraph()

      return submitted
    },
    [
      validateActiveNetwork,
      account,
      auction,
      bidLockerContract,
      signer,
      readFromSubgraph,
      clock,
      revealTender,
      lockTendersTransaction,
      chainId,
      resetLockTendersState,
      transactionStates.lockTenders,
      localStorage,
    ]
  )

  return [
    result,
    readFromLocalStorage,
    bidLockerContract ? deleteTenders : undefined,
    bidLockerContract ? lockTenders : undefined,
  ]
}
