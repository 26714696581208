import React, { useState, useCallback, useMemo } from 'react'
import { Button, Spinner, Image, Text } from '@chakra-ui/react'
import refreshIcon from '../../../assets/icons/refresh.svg'
import checkIcon from '../../../assets/icons/Linear.svg'
import { debounce } from 'lodash'
import { useGlobalRefresher } from '../../../providers/refresher'

const BUTTON_STATES = {
  INITIAL: 'initial',
  PENDING: 'pending',
  COMPLETED: 'completed',
  ERROR: 'error',
}

const RefreshButton = () => {
  const { refreshNow } = useGlobalRefresher()
  const [buttonState, setButtonState] = useState(BUTTON_STATES.INITIAL)
  const debouncedRefresh = useMemo(
    () => debounce(refreshNow, 2000),
    [refreshNow]
  )

  const handleRefresh = useCallback(async () => {
    setButtonState(BUTTON_STATES.PENDING)
    try {
      await debouncedRefresh()
      setButtonState(BUTTON_STATES.COMPLETED)
      setTimeout(() => setButtonState(BUTTON_STATES.INITIAL), 3000)
    } catch (error) {
      console.error(error)
      setButtonState(BUTTON_STATES.ERROR)
      setTimeout(() => setButtonState(BUTTON_STATES.INITIAL), 3000)
    }
  }, [debouncedRefresh])

  const handleClick = () => {
    if (
      buttonState === BUTTON_STATES.INITIAL ||
      buttonState === BUTTON_STATES.ERROR
    ) {
      handleRefresh()
    }
  }

  const renderButtonImage = () => {
    switch (buttonState) {
      case BUTTON_STATES.INITIAL:
        return (
          <Image src={refreshIcon} width="20px" height="20px" alt="refresh" />
        )
      case BUTTON_STATES.PENDING:
        return <Spinner width="20px" height="20px" />
      case BUTTON_STATES.COMPLETED:
        return <Image src={checkIcon} width="20px" height="20px" alt="check" />
      default:
        return (
          <Image src={refreshIcon} width="20px" height="20px" alt="refresh" />
        )
    }
  }

  return (
    <Button
      onClick={handleClick}
      isDisabled={
        buttonState === BUTTON_STATES.PENDING ||
        buttonState === BUTTON_STATES.COMPLETED
      }
      variant="tertiary"
      size="sm"
      border={'none'}
    >
      {renderButtonImage()}
      <Text as="span" ml="1" variant="body-sm/medium">
        Refresh data
      </Text>
    </Button>
  )
}

export default RefreshButton
