import { Box, Button, Heading, Spacer, Spinner, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { useMemo, useRef } from 'react'
import Chip from '../../../../components/elements/Chip'
import {
  Table,
  TableFoot,
  TableHead,
  Th,
  Tr,
} from '../../../../components/elements/Table'
import { useColumnWidths } from '../../../../hooks/portfolio'
import {
  OpenBorrowTenderHeader,
  OpenLoanTenderHeader,
} from '../OpenOffersSection'
import { Trans } from '@lingui/macro'

interface ColumnWidths {
  [key: string]: number
}

const COLUMN_WIDTHS: ColumnWidths = {
  auctionId: 200,
  closesIn: 85,
  asset: 80,
  submitted: 95,
  amount: 104,
  rate: 75,
  disp: 70,
  required: 69,
  deposited: 69,
  transaction: 91,
  emptyColumn: 132,
}
export default function OpenOffersSection() {
  // Refs to tables to calculate column widths
  const bidsTableRef = useRef(null)
  const offersTableRef = useRef(null)

  const tableRefs = useMemo(
    () => [bidsTableRef, offersTableRef],
    [bidsTableRef, offersTableRef]
  )

  const excludedColumnsFromEmptySpaceCalculation = useMemo(
    () => new Set(['asset']),
    []
  )

  const columnWidths = useColumnWidths(
    tableRefs,
    COLUMN_WIDTHS,
    excludedColumnsFromEmptySpaceCalculation,
    true
  )
  return (
    <Box my={5} mr="20px !important">
      <HStack mb="8px">
        <Heading variant="heading/h3" color="gray.900">
          <Trans>Bids</Trans>
        </Heading>
        <Chip>
          <Trans>0</Trans>
        </Chip>
        <Spacer />
        <HStack>
          <HStack opacity={0.5}>
            <Button size="xs" variant="secondary">
              <Trans>Edit</Trans>
            </Button>
            <Button size="xs" variant="link" px={0.5}>
              <Text as="span" color="blue.5" variant="body-xs/semibold">
                <Trans>Delete</Trans>
              </Text>
            </Button>
          </HStack>
        </HStack>
      </HStack>
      <Table tableRef={bidsTableRef} isFixedLayout noPadding>
        <TableHead>
          <OpenBorrowTenderHeader
            hasAuctions={false}
            asset=""
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableFoot noTopBorder>
          <Tr noBorder>
            <Th textAlign="center" colSpan={11} p="14px 0">
              <HStack w="full" justifyContent="center">
                <Spinner
                  color="blue.500"
                  w="42px"
                  h="42px"
                  className="chromatic-ignore"
                />
              </HStack>
            </Th>
          </Tr>
        </TableFoot>
      </Table>
      <HStack mb="8px" mt="40px">
        <Heading variant="heading/h3" color="gray.900">
          <Trans>Offers</Trans>
        </Heading>
        <Chip>
          <Trans>0</Trans>
        </Chip>
        <Spacer />
        <HStack>
          <HStack opacity={0.5}>
            <Button size="xs" variant="secondary">
              <Trans>Edit</Trans>
            </Button>
            <Button size="xs" variant="link" px={0.5}>
              <Text as="span" color="blue.5" variant="body-xs/semibold">
                <Trans>Delete</Trans>
              </Text>
            </Button>
          </HStack>
        </HStack>
      </HStack>
      <Table tableRef={offersTableRef} isFixedLayout noPadding>
        <TableHead>
          <OpenLoanTenderHeader
            hasAuctions={false}
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableFoot noTopBorder>
          <Tr noBorder>
            <Th textAlign="center" colSpan={11} p="14px 0">
              <HStack w="full" justifyContent="center">
                <Spinner
                  color="blue.500"
                  w="42px"
                  h="42px"
                  className="chromatic-ignore"
                />
              </HStack>
            </Th>
          </Tr>
        </TableFoot>
      </Table>
    </Box>
  )
}
