import { Box, Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import auctionsImage from '../../../../assets/icons/pointsCard/bid-offer.svg'
import unlockImage from '../../../../assets/icons/pointsCard/multi-coins.svg'
import lendImage from '../../../../assets/icons/pointsCard/usdc-eth.svg'
import { Project } from '../../../../components/elements/ProjectIcon'
import { HStack } from '../../../../components/elements/Stack'
import { components as ProfileSchema } from '../../../../models/profile-api'
import { EligibleRetweets, Socials } from '../../../../models/rewards'
import { RewardedTweet } from '../../../../socials'
import LendOnTermModal from '../LendOnTermModal'
import ParticipateInAuctionsModal from '../ParticipateInAuctionsModal'
import PointsCardGrid from '../PointsCardGrid'
import PointsCardLarge from '../PointsCardLarge'
import UnlockMigrationModal from '../UnlockMigrationModal'

type Disclosure = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

// TODO [Dospore] confirm these props
// is totalLoansFilled a USD value
// is totalLoans the same value as totalLoansFilled or totalLoansTendered
type Props = {
  season: ProfileSchema['schemas']['Season'] | undefined
  socialPoints?: number
  isSignedIn: boolean
  hasProfile: boolean
  accountExistsInProfile: boolean
  totalLoansFilled: number
  totalLoansTendered: number
  totalBorrowsFilled: number
  totalBorrowsTendered: number
  participateInAuctionsModalControls: Disclosure

  totalLoans: FixedNumber
  totalNumOfActiveLoans: number
  totalNumberOfWeeks: number
  activeLoanBalance: FixedNumber
  averageLoanBalance: FixedNumber
  lendOnTermModalControls: Disclosure

  migrationBonusComplete: boolean
  lockedMigrationPoints: number
  accountActiveSince: number
  accountActiveOn: Project[]
  numAccountTransactions: number
  positionsFilled: number
  connectedWallet?: string
  unlockMigrationModalControls: Disclosure
  preseasonPoints: ProfileSchema['schemas']['PreseasonPoints'] | undefined
  auctionPoints: ProfileSchema['schemas']['AuctionPoints'] | undefined
  inviteCodes: ProfileSchema['schemas']['InviteCode'][] | undefined
  completedRetweets: Partial<Record<RewardedTweet, boolean>>
  connectedSocials: Record<Socials, boolean> | undefined
  onSignUp: () => void
  onSignIn: () => void
  onNavigateToAuctions: () => void
  onNavigateToInvites: () => void
  onRetweet: (tweet: EligibleRetweets) => void
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => Promise<void>
  onLinkWallet: () => void
  onUnlockMigrationSignUp: () => void
}

export default function EarnSection({
  season,
  socialPoints,
  isSignedIn,
  hasProfile,
  accountExistsInProfile,
  // Participate in auctions modal
  totalLoansFilled,
  totalLoansTendered,
  totalBorrowsFilled,
  totalBorrowsTendered,
  participateInAuctionsModalControls: {
    isOpen: isParticipateInAuctionsModalOpen,
    onOpen: onOpenParticipateInAuctionsModal,
    onClose: onCloseParticipateInAuctionsModal,
  },

  // lend on term modal
  totalLoans,
  totalNumOfActiveLoans,
  totalNumberOfWeeks,
  activeLoanBalance,
  averageLoanBalance,
  lendOnTermModalControls: {
    isOpen: isLendOnTermModalOpen,
    onOpen: onOpenLendOnTermModal,
    onClose: onCloseLendOnTermModal,
  },

  // unlock migration modal
  migrationBonusComplete,
  lockedMigrationPoints,
  accountActiveSince,
  accountActiveOn,
  numAccountTransactions,
  positionsFilled,
  connectedWallet,
  unlockMigrationModalControls: {
    isOpen: isUnlockMigrationModalOpen,
    onOpen: onOpenUnlockMigrationModal,
    onClose: onCloseUnlockMigrationModal,
  },
  preseasonPoints,
  auctionPoints,
  inviteCodes,
  completedRetweets,
  connectedSocials,
  onSignIn,
  onSignUp,
  onNavigateToAuctions,
  onNavigateToInvites,
  onRetweet,
  onLinkTwitter,
  onLinkDiscord,
  onLinkWallet,
  onUnlockMigrationSignUp,
}: Props) {
  return (
    <>
      <Box mb={6}>
        <Text color={'blue.9'} variant={'body-2xl/medium'}>
          Take action to earn more points
        </Text>
      </Box>
      <HStack spacing={4} mb={4} w="full">
        {lockedMigrationPoints !== 0 && (
          <PointsCardLarge
            category="unlock"
            points={`Unlock ${lockedMigrationPoints.toLocaleString()} points`}
            complete={hasProfile && migrationBonusComplete}
            title="Unlock migration bonus"
            buttonText="Details"
            buttonAction={onOpenUnlockMigrationModal}
            thumbnail={unlockImage}
          />
        )}
        <PointsCardLarge
          category="auctions"
          points="Earn points for submitting offers and bids"
          title="Participate in auction"
          buttonAction={onOpenParticipateInAuctionsModal}
          buttonText="Details"
          thumbnail={auctionsImage}
        />
        <PointsCardLarge
          category="lending"
          points="Accrue 1 point per dollar / day"
          title="Lend on Term"
          buttonAction={onOpenLendOnTermModal}
          buttonText="Details"
          thumbnail={lendImage}
        />
        {lockedMigrationPoints === 0 && ( // adding element twice for positioning purposes
          <PointsCardLarge
            category="unlock"
            points="No points available"
            complete={migrationBonusComplete}
            title="Unlock migration bonus"
            buttonText="Details"
            buttonAction={onOpenUnlockMigrationModal}
            thumbnail={unlockImage}
          />
        )}
      </HStack>
      <PointsCardGrid
        season={season}
        socialPoints={socialPoints}
        isSignedIn={isSignedIn}
        hasProfile={hasProfile}
        accountExistsInProfile={accountExistsInProfile}
        totalNumberOfWeeks={totalNumberOfWeeks}
        preseasonPoints={preseasonPoints}
        auctionPoints={auctionPoints}
        inviteCodes={inviteCodes}
        completedRetweets={completedRetweets}
        connectedSocials={connectedSocials}
        onSignIn={onSignIn}
        onSignUp={onSignUp}
        onNavigateToAuctions={onNavigateToAuctions}
        onNavigateToInvites={onNavigateToInvites}
        onRetweet={onRetweet}
        onLinkTwitter={onLinkTwitter}
        onLinkDiscord={onLinkDiscord}
        onLinkWallet={onLinkWallet}
      />
      <ParticipateInAuctionsModal
        isOpen={isParticipateInAuctionsModalOpen}
        onClose={onCloseParticipateInAuctionsModal}
        totalLoansFilled={totalLoansFilled}
        totalLoansTendered={totalLoansTendered}
        totalBorrowsFilled={totalBorrowsFilled}
        totalBorrowsTendered={totalBorrowsTendered}
      />
      <LendOnTermModal
        isOpen={isLendOnTermModalOpen}
        onClose={onCloseLendOnTermModal}
        numPositions={totalNumOfActiveLoans}
        activeLoanBalance={activeLoanBalance}
        averageLoanBalance={averageLoanBalance}
        season={season}
      />
      <UnlockMigrationModal
        hasProfile={hasProfile}
        isOpen={isUnlockMigrationModalOpen}
        onClose={onCloseUnlockMigrationModal}
        lockedPoints={lockedMigrationPoints}
        activeSince={accountActiveSince}
        activeOn={accountActiveOn}
        numTransactions={numAccountTransactions}
        positionsFilled={positionsFilled}
        connectedWallet={connectedWallet}
        onUnlockMigrationSignUp={onUnlockMigrationSignUp}
      />
    </>
  )
}
