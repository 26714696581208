import { Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'

import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import TokenChip from '../../../../components/elements/TokenChip'
import { evaluate } from '../../../../helpers/math'
import {
  formatFixedPercentage,
  formatFixedToken,
} from '../../../../helpers/utils'
import { Trans, t } from '@lingui/macro'

export default function BorrowerRolloverOverview({
  maturityTimestamp,
  outstandingDebt,
  marginDeposited,
  marginRatio,

  purchaseTokenSymbol,
  collateralTokenSymbol,
  title,
  auctionLabel,
}: {
  maturityTimestamp: number
  outstandingDebt: FixedNumber
  marginDeposited: FixedNumber
  marginRatio: FixedNumber

  purchaseTokenSymbol: string
  collateralTokenSymbol: string
  title: string
  auctionLabel: string
}) {
  return (
    <StatsCard>
      <StatsTitle value={auctionLabel}>{title}</StatsTitle>
      <StatsRow
        title={
          <>
            <Trans>Outstanding Debt</Trans> &nbsp;
            <TokenChip token={purchaseTokenSymbol} />
          </>
        }
      >
        <Text variant="body-sm/normal" as="span" color="blue.9">
          {formatFixedToken(outstandingDebt, purchaseTokenSymbol, true)}
        </Text>
      </StatsRow>
      <StatsRow title={t`Maturity`}>
        <HStack>
          <Text variant="body-sm/normal" as="span" color="blue.9">
            {dayjs.unix(maturityTimestamp).format('MMM D, ha')}
          </Text>
        </HStack>
      </StatsRow>
      <StatsRow
        title={
          <>
            <Trans>Collateral Deposited</Trans> &nbsp;
            <TokenChip token={collateralTokenSymbol} />
          </>
        }
      >
        <Text variant="body-sm/normal" as="span" color="blue.9">
          {formatFixedToken(marginDeposited, collateralTokenSymbol, true)}
        </Text>
      </StatsRow>
      <StatsRow title={t`Collateral Ratio`}>
        <Text variant="body-sm/normal" as="span" color="blue.9">
          {formatFixedPercentage(
            evaluate({
              nodeKind: 'mul',
              args: [
                {
                  nodeKind: 'value',
                  value: marginRatio,
                },
                {
                  nodeKind: 'value',
                  value: FixedNumber.fromString('100', marginRatio.format),
                },
              ],
            }).floor(),
            1,
            undefined,
            FixedNumber.fromString('1', marginRatio.format)
          )}
        </Text>
      </StatsRow>
    </StatsCard>
  )
}
