import {
  ModalCloseButton,
  ModalHeader as CModalHeader,
  ModalHeaderProps,
  Text,
  TextProps,
} from '@chakra-ui/react'

export const ModalHeading = ({
  children,
  fontWeight = '700',
  fontSize = '16px',
  lineHeight = '120%',
  color = 'blue.900',
  ...props
}: TextProps) => {
  return (
    <Text
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color}
      {...props}
    >
      {children}
    </Text>
  )
}

export const ModalSubHeading = ({
  children,
  fontWeight = '400',
  fontSize = '14px',
  lineHeight = '20px',
  color = 'gray.500',
  ...props
}: TextProps) => {
  return (
    <Text
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color}
      {...props}
    >
      {children}
    </Text>
  )
}

export const ModalHeader = ({
  children,
  pt = '28px',
  px = '28px',
  ...props
}: ModalHeaderProps) => {
  return (
    <CModalHeader pt={pt} px={px} {...props}>
      {children}
      <ModalCloseButton size="sm" mr="12px" mt="16px" color="blue.900" />
    </CModalHeader>
  )
}
