import { Divider, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { Project } from '../../../../components/elements/ProjectIcon'
import { ModalBody, ModalFooter, ModalHeader } from './modal'
import {
  ModalFooter as ModalFooterZeroPoints,
  ModalHeader as ModalHeaderZeroPoints,
} from './modalZeroPoints'

type Props = {
  isOpen: boolean
  onClose: () => void

  hasProfile: boolean
  lockedPoints: number
  activeSince: number
  activeOn: Project[]
  numTransactions: number
  positionsFilled: number
  connectedWallet?: string
  onUnlockMigrationSignUp: () => void
}

export default function UnlockMigrationModal({
  isOpen,
  onClose,

  hasProfile,
  lockedPoints,
  activeSince,
  activeOn,
  numTransactions,
  positionsFilled,
  connectedWallet,
  onUnlockMigrationSignUp,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={true}>
      <ModalOverlay />
      <ModalContent p={5} w="520px">
        {lockedPoints > 0 ? (
          <>
            <ModalHeader
              lockedPoints={lockedPoints}
              activeSince={activeSince}
              activeOn={activeOn}
              numTransactions={numTransactions}
              onClose={onClose}
            />
            <Divider my={6} bg="#0000001a" opacity={1} />
            <ModalBody
              positionsFilled={positionsFilled}
              hasProfile={hasProfile}
              onUnlockMigrationSignUp={onUnlockMigrationSignUp}
            />
            <ModalFooter />
          </>
        ) : (
          <>
            <ModalHeaderZeroPoints
              connectedWallet={connectedWallet}
              onClose={onClose}
            />
            <ModalFooterZeroPoints onClose={onClose} />
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
