import { Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { ReactNode } from 'react'

export default function SummaryFormulaOperator({
  isDark,
  children,
}: {
  isDark: boolean
  children: ReactNode
}) {
  return (
    <HStack alignItems="flex-end">
      <Text
        as="span"
        variant="body-3xl/normal"
        opacity={isDark ? 1 : 0.3}
        color="gray.4"
        marginTop="-4px"
      >
        {children}
      </Text>
    </HStack>
  )
}
