import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Image,
  Link,
  Skeleton,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import StatsCard from '../StatsCard'
import StatsRow from '../StatsCard/StatsRow'
import { formatFixedToken } from '../../../helpers/utils'
import { Address, Balance } from '../../../data/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copyIcon from '../../../assets/icons/copy.svg'
import { evaluate } from '../../../helpers/math'
import { useChainConfig } from '../../../providers/config'
import { HStack } from '../Stack'

const NUM_SKELETON_ROWS = 5

export default function TestNetTokensOverview({
  testnetTokenBalances,
  mintingAmounts,
  selectedTokens,
  onImportTestnetToken,
  onTokenSelectionToggle,
  activeChainId,
}: {
  testnetTokenBalances?: Balance[]
  mintingAmounts?: { [symbol: string]: FixedNumber }
  onImportTestnetToken?: (
    chainId: string,
    address: string,
    symbol: string,
    decimals: number
  ) => Promise<boolean>
  selectedTokens?: Set<Address>
  onTokenSelectionToggle?: (tokenAddress?: Address) => void
  activeChainId: number | undefined
}) {
  const selectedChain = useChainConfig(activeChainId)

  // Check if all tokens are selected
  const allSelected =
    testnetTokenBalances &&
    selectedTokens &&
    selectedTokens.size === testnetTokenBalances.length

  const handleSelectAll = () => {
    onTokenSelectionToggle && onTokenSelectionToggle() // Call with no token address to indicate select/deselect all
  }

  return (
    <StatsCard>
      <HStack px="16px" py="8px" mb="2px">
        {testnetTokenBalances && testnetTokenBalances.length > 0 ? (
          <Checkbox isChecked={allSelected} onChange={handleSelectAll} />
        ) : (
          <Checkbox isChecked={false} isDisabled />
        )}
        <Text as="span" variant="body-sm/semibold" color="gray.6">
          {selectedChain && selectedChain.chainName} Test Tokens
        </Text>
        <Spacer />
      </HStack>
      <Divider p="0px" m="0px" />
      {testnetTokenBalances && selectedChain && testnetTokenBalances.length > 0
        ? testnetTokenBalances.map((tokenEntry) => {
            const tokenAddress = selectedChain.getExplorerAddressLink(
              tokenEntry.address
            )
            return (
              <StatsRow
                key={tokenAddress}
                title={
                  <HStack>
                    <Checkbox
                      isChecked={selectedTokens?.has(tokenEntry.address)}
                      onChange={() =>
                        onTokenSelectionToggle?.(tokenEntry.address)
                      }
                    />
                    <Text>{tokenEntry.symbol}</Text>
                  </HStack>
                }
                tooltip={
                  <>
                    <Link href={tokenAddress} isExternal color="gray.3">
                      <FontAwesomeIcon
                        icon={['far', 'arrow-up-right']}
                        width="12px"
                        height="12px"
                      />
                    </Link>
                    <IconButton
                      ml="2px"
                      variant="unstyled"
                      size="xs"
                      icon={
                        <Image
                          width="16px"
                          height="16px"
                          src={copyIcon}
                          opacity="0.2"
                        />
                      }
                      onClick={() => {
                        navigator.clipboard.writeText(tokenEntry.address)
                      }}
                      aria-label="Copy token address"
                    />
                  </>
                }
              >
                <Box>
                  <Text as="span" variant="body-sm/normal" color="gray.4">
                    {formatFixedToken(
                      tokenEntry.balance,
                      tokenEntry.symbol,
                      true
                    )}
                  </Text>
                  <Text as="span" color="gray.4">
                    {' -> '}
                  </Text>
                  <Text variant="body-sm/normal" as="span" color="blue.9">
                    {mintingAmounts && mintingAmounts[tokenEntry.symbol]
                      ? formatFixedToken(
                          evaluate({
                            nodeKind: 'add',
                            args: [
                              {
                                nodeKind: 'value',
                                value: tokenEntry.balance,
                              },
                              {
                                nodeKind: 'value',
                                value: mintingAmounts[tokenEntry.symbol],
                              },
                            ],
                          }),
                          tokenEntry.symbol,
                          true
                        )
                      : FixedNumber.fromString('0').toString()}
                  </Text>
                </Box>
              </StatsRow>
            )
          })
        : Array.from({ length: NUM_SKELETON_ROWS }, (_, i) => i).map((i) => (
            <EmptyRow key={i} />
          ))}
    </StatsCard>
  )
}

function EmptyRow() {
  return (
    <StatsRow
      title={
        <HStack>
          <Skeleton w={20} h={5} />
          <Box color="gray.3">
            <FontAwesomeIcon
              icon={['far', 'arrow-up-right']}
              width="12px"
              height="12px"
            />
          </Box>
          <Image width="16px" height="16px" src={copyIcon} opacity="0.2" />
        </HStack>
      }
    >
      <Box>
        <Skeleton w="120px" h={5} />
      </Box>
    </StatsRow>
  )
}
