import { ChainId } from '@usedapp/core'
import {
  DraftBorrowTender,
  DraftLoanTender,
  MappedLendCurrencies,
  NativeCurrency,
} from '../data/model'
import { BigNumber } from 'ethers'

export const MOBILE_CONTAINER_PADDING = '20px'
export const CONTAINER_PADDING = '80px'
export const SIDEBAR_EXPANDED_WIDTH = '168px'
export const SIDEBAR_COLLAPSED_WIDTH = '66px'
export const SIDEBAR_MARGIN = '46px'
export const MOBILE_SIDEBAR_MARGIN = '32px'
export const ERROR_STR = '<ERROR>'

export const MAX_TOKEN_SYMBOL_LENGTH = 7

export const MATURITY_DATE_FORMAT = 'MMMM D, ha'

// TODO - move to subgraph manager when mocha is fixed
export enum SubgraphVersion {
  Latest = 'latest',
  // ... add more as needed
}

export const APPROVAL_BID_BUFFER_PERCENT = 5

export const DEFAULT_LOAN_TENDER: DraftLoanTender = {
  type: 'loan',
  amount: '',
  interestRate: '',
}

export const DEFAULT_BORROW_TENDER: DraftBorrowTender = {
  type: 'borrow',
  amount: '',
  interestRate: '',
  collateral: '',
}

// TODO: move this later to blockexplorer lookup
export const LOW_LIQUIDITY_ASSETS = [
  '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee', // WEETH
  '0xa1290d69c65a6fe4df752f95823fae25cb99e5a7', // RSETH
  '0xbf5495efe5db9ce00f80364c8b423567e58d2110', // EZETH
  '0xd9a442856c234a39a81a089c06451ebaa4306a72', // PUFETH
  '0xd31a59c85ae9d8edefec411d448f90841571b89c', // SOL (Wormhole)
  '0x9ba021b0a9b958b5e75ce9f6dff97c7ee52cb3e6', // APXETH
  '0x04c154b66cb340f3ae24111cc767e0184ed00cc6', // PXETH
  '0x004e9c3ef86bc1ca1f0bb5c7662861ee93350568', // UNIBTC
  '0x73a15fed60bf67631dc6cd7bc5b6e8da8190acf5', // USD0
  '0x35d8949372d46b7a3d5a56006ae77b215fc69bc0', // USD0++
  '0x152b9d0fdc40c096757f570a51e494bd4b943e50', // BTC.B Avax
  '0x8236a87084f8b84306f72007f36f2618a5634494', // LBTC
  '0x15700b564ca08d9439c58ca5053166e8317aa138', // DEUSD
  '0x2c537e5624e4af88a7ae4060c022609376c8d0eb', // TRYB
  '0xd11c452fc99cf405034ee446803b6f6c1f6d5ed8', // TETH
  '0xcbb7c0000ab88b473b1f5afd9ef808440eed33bf', // CBBTC
  '0xe00bd3df25fb187d6abbb620b3dfd19839947b81', // PT-sUSDE-27MAR2025
]

export const SIGN_UP_BENEFITS = [
  'Invite users and earn 10% of their points',
  'Combine multiple wallets in one profile for easy management',
  'Earn extra points by linking your socials',
]
export const UNLOCK_BONUS_POSITIONS_TARGET = 3

// $10 in WBTC (6 decimals).
// export const PROTOCOL_FEES = 10000000
export const PROTOCOL_FEES = 0

// TODO: Look these up from the blockchain.
export const GAS_TOKEN_SYMBOL = 'XXXXXXXXXXXXXXX'
export const WRAPPED_GAS_TOKEN_SYMBOL = 'XXXXXXXXXXXXXXX'

export const SUPPORTED_VAULT_TOKENS = ['SDAI']

export const TERM_REPO_TOKEN_SYMBOL = 'TRT'
export const USDC_TOKEN_SYMBOL = 'USDC'

export const INTEREST_RATE_DECIMALS = 18

export const STABLECOIN_LIST = ['usdc', 'dai', 'usdt']
export const BLUECHIP_LIST = ['weth', 'wsteth', 'cbeth', 'wbtc', 'btc.b']

export const CORRELATED_ASSETS: { [key: string]: string[] } = {
  usdc: ['susde', 'usde', 'sdai', 'usd0', 'usd0++'],
  weth: ['wsteth', 'weeth', 'cbeth', 'ezeth', 'apxeth'],
}

export const THOUSAND_IN_CENTS = BigNumber.from(100000)
export const MILLION_IN_CENTS = BigNumber.from(100000000)
export const BILLION_IN_CENTS = BigNumber.from(100000000000)

// ABI versions supported by the current version of the app.
// Multiple contract versions can be supported by the same ABI version.
// E.g. 0.2.4/0.2.5 may share 0.2.4 ABIs
export const SUPPORTED_ABI_VERSIONS: { [key: string]: string } = {
  '0.2.4': '0.2.4',
  '0.4.1': '0.4.1',
  '0.4.4': '0.4.1',
  '0.4.5': '0.4.1',
  '0.4.6': '0.4.1',
  '0.5.0': '0.4.1',
  '0.5.1': '0.4.1',
  '0.5.001': '0.4.1',
  '0.5.3': '0.4.1',
  '0.6.0': '0.6.0',
  '0.6.3': '0.6.0',
}

export const GAS_TOKEN_INFO_MAP: Record<number, NativeCurrency | undefined> = {
  1: {
    // Ethereum Mainnet
    symbol: 'ETH',
    decimals: 18,
    wrappedGasSymbol: 'WETH',
    wrapConversionFactor: 1,
  },
  80001: {
    // Polygon Mumbai
    symbol: 'MATIC',
    decimals: 18,
    wrappedGasSymbol: 'WMATIC',
    wrapConversionFactor: 1,
  },
  84531: {
    // Base Goerli
    symbol: 'ETH',
    decimals: 18,
    wrappedGasSymbol: 'WETH',
    wrapConversionFactor: 1,
  },
  43114: {
    symbol: 'AVAX',
    decimals: 18,
    wrappedGasSymbol: 'WAVAX',
    wrapConversionFactor: 1,
  },
}

// TODO: read this from external source
export const availableCurrencies: MappedLendCurrencies = {
  USDC: {
    [ChainId.Sepolia.toString()]: '0x27af15ed04dfb358ea86f22ff93625d6b7cef494',
  },
  USDT: {
    [ChainId.Sepolia.toString()]: '0xf83f3701c246ea60149880039395e8a781f34939',
  },
  WBTC: {
    [ChainId.Sepolia.toString()]: '0xf9b8784d7b595aed9f36f823bb7138aac293cb0e',
  },
  WETH: {
    [ChainId.Sepolia.toString()]: '0xf243b1edff0d00b0c3eb170511c3cae4de38cd55',
  },
  DAI: {
    [ChainId.Sepolia.toString()]: '0x91e481a99d0d87d7a9c4865933c0f8817dec00a6',
  },
}

// TODO (matt): move this to utils when mocha test issues with imports are resolved
export function getGasTokenInfo(
  chainId: number | undefined
): NativeCurrency | undefined {
  return GAS_TOKEN_INFO_MAP[chainId ?? -1]
}
