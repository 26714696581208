import { Flex, Heading } from '@chakra-ui/react'
import { AuctionStatus as AuctionStatusType } from '../../../data/model'
import ClearingPulse from '../../../pages/Auctions/elements/ClearingPulse'
import LivePulse from '../../../pages/Auctions/elements/LivePulse'
import StatusIndicator from '../StatusIndicator'

export default function AuctionStatus({
  status,
}: {
  status: AuctionStatusType
}) {
  return (
    <Flex mb="16px" alignItems="center">
      {
        {
          clearing: <ClearingPulse w={6} h={6} transform="translateX(-4px)" />,
          live: <LivePulse w={6} h={6} transform="translateX(-4px)" />,
          closed: <StatusIndicator status="closed" header />,
          upcoming: <StatusIndicator status="upcoming" header />,
        }[status]
      }
      <Heading
        fontSize="sm"
        fontWeight="700"
        color="black"
        textTransform="capitalize"
      >
        {status}
      </Heading>
    </Flex>
  )
}
