import { useMemo } from 'react'
import { TermLoan } from '../model'
import { PagePortfolioQuery } from '../../gql/graphql'
import { BigNumber, FixedNumber } from 'ethers'

// Gets all loans for a user across all terms/auctions.
export function useAllLoans(
  data: { [chainId: string]: PagePortfolioQuery } | undefined,
  balances:
    | {
        [chainId: string]: {
          [address: string]: FixedNumber
        }
      }
    | undefined
): TermLoan[] | undefined {
  const [mappedRedemptions, mappedRollovers] = useMemo(() => {
    const mappedRedemptions: { [redeemer: string]: BigNumber } = {}
    const mappedRollovers: { [borrower: string]: BigNumber } = {}

    if (data) {
      // iterate over chain
      Object.values(data).forEach((pagePortfolioQuery) => {
        // iterate over redemptions
        pagePortfolioQuery.repoTokenRedemptions.forEach((redemption) => {
          mappedRedemptions[redemption.term.id] = redemption.amountRedeemed
        })
        // iterate over rollovers
        pagePortfolioQuery.termRolloverInstructions.forEach((rollover) => {
          mappedRollovers[rollover.id] = rollover.rolloverAmount
        })
      })
    }
    return [mappedRedemptions, mappedRollovers]
  }, [data])

  const results = useMemo(() => {
    let allPurchases: TermLoan[] = []

    if (data && balances) {
      Object.entries(data).forEach(([chainId, pagePortfolioQuery]) => {
        const chainPurchases = pagePortfolioQuery.termPurchases?.map(
          (purchase) =>
            ({
              id: purchase.id,
              chainId,
              version: purchase.auction.term.version,
              auctionId: purchase.auction.id,
              termId: purchase.auction.term.id,
              auction: purchase.auction.auction,
              auctionBidLocker: purchase.auction.auctionBidLocker,
              auctionOfferLocker: purchase.auction.auctionOfferLocker,
              auctionEndTimestamp: purchase.auction.auctionEndTime,
              auctionClearingRate: BigNumber.from(
                purchase.auction.auctionClearingPrice
              ),
              maturityTimestamp: purchase.auction.term.repurchaseTimestamp,
              redemptionTimestamp: purchase.auction.term.redemptionTimestamp,

              repoServicerAddress: purchase.auction.term.termRepoServicer,

              redemptionAmount:
                mappedRedemptions[purchase.auction.term.id] ??
                BigNumber.from(0),
              // TODO: legacy loan side rollover, speak with Dion before removing this
              rolloverAmount: mappedRollovers?.bidder ?? BigNumber.from(0),
              purchasePrice: BigNumber.from(purchase.purchasePrice),
              repurchasePrice: BigNumber.from(purchase.repurchasePrice),

              // auction: loan.auction.auction,
              collateralCurrency: purchase.auction.term.collateralTokens?.[0],
              purchaseCurrency: purchase.auction.term.purchaseToken,
              termTokenCurrency: purchase.auction.term.termRepoToken,
              termTokenBalance:
                balances[chainId][purchase.auction.term.termRepoToken] ??
                FixedNumber.fromString('0'),
            }) as TermLoan
        )

        if (chainPurchases) {
          allPurchases = allPurchases.concat(chainPurchases)
        }
      })
    }

    return allPurchases.sort(
      (loan1, loan2) => loan1.maturityTimestamp - loan2.maturityTimestamp
    )
  }, [data, mappedRedemptions, mappedRollovers?.bidder, balances])

  // Return data/callbacks to components using this hook.
  return results
}
