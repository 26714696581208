import React from 'react'
import { Text, Button } from '@chakra-ui/react'
import ReferralCode from '../ReferralCode'
import TermToast from '../TermToast'
import { WarningIcon } from '../TermToast'
import { VStack, HStack } from '../Stack'

export type Props = {
  existingAddress: string
  replacementAddress: string
  onReplace: () => void
  onKeep: () => void
}

export const ReplaceReferralCodeToast: React.FC<Props> = ({
  existingAddress,
  replacementAddress,
  onReplace,
  onKeep,
}) => (
  <TermToast
    title={'Replace referral address?'}
    duration={null}
    iconMarginTop={'20px'}
    icon={<WarningIcon />}
    children={
      <VStack alignItems="start" spacing="24px">
        <Text color="gray.6" variant="body-sm/normal">
          You have an existing referral address. Would you like to replace it
          with the new one?
        </Text>
        <VStack spacing="4px" w="full">
          <HStack w="full">
            <HStack pr="16px" w="full">
              <Text as="span" color="gray.6" variant="body-sm/medium">
                Existing Referral Address
              </Text>
            </HStack>
            <HStack ml="auto">
              <ReferralCode
                referralCode={existingAddress}
                variant="body-sm/medium"
              />
            </HStack>
          </HStack>
          <HStack w="full" mt="0!important">
            <HStack pr="16px" w="full">
              <Text as="span" color="gray.6" variant="body-sm/medium">
                New Referral Address
              </Text>
            </HStack>
            <HStack ml="auto">
              <ReferralCode
                referralCode={replacementAddress}
                variant="body-sm/medium"
              />
            </HStack>
          </HStack>
        </VStack>
        <HStack>
          <Button size="sm" variant="tertiary" onClick={onReplace}>
            <Text variant="body-sm/medium">Replace</Text>
          </Button>
          <Button variant="link" onClick={onKeep}>
            <Text color="blue.5" variant="body-sm/medium">
              Keep existing
            </Text>
          </Button>
        </HStack>
      </VStack>
    }
  />
)

export default ReplaceReferralCodeToast
