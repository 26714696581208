import { Link, Text } from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { externalLinks } from '../../../../links'
import { useSafary } from '../../../../data/analytics/use-safary'
import { useConfig } from '../../../../providers/config'

export default function Subheading() {
  const config = useConfig()
  const { trackSafaryEvent } = useSafary()
  return (
    <VStack align="left" gap={0} opacity={0.8}>
      <Text variant="body-sm/semibold" color="blue.9">
        Select a Term Loan
        <Text as="span" variant="body-sm/normal">
          {' '}
          below to earn a fixed return.
        </Text>
      </Text>
      <Text variant="body-sm/normal">
        New to Term?{' '}
        <Link
          isExternal
          textDecoration="none"
          href={externalLinks.lending}
          onClick={() => {
            trackSafaryEvent(
              config?.safary?.earn?.blueSheetsDocsLink?.type ?? 'click',
              'blue sheets doc',
              {}
            )
          }}
        >
          <Text as="span" variant="body-sm/medium" color="blue.5">
            Learn how Blue Sheets works{' '}
            <Text as="span" color="gray.3">
              <FontAwesomeIcon icon={['far', 'arrow-up-right']} />
            </Text>
          </Text>
        </Link>
      </Text>
    </VStack>
  )
}
