// import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { postLogin } from '../../../helpers/swr'
import { paths } from '../../../models/profile-api'
import { useCallback, useEffect, useState } from 'react'
import { LoginActions } from '../../../models/rewards'

type LoginResult = {
  success: boolean
  token?: string
  error?: { message: string }
}

export function useLogin(): {
  token: string | undefined
  isValid: boolean
  isLoading: boolean
  login: (
    action: LoginActions,
    extraArguments: paths['/profile/login']['post']['requestBody']['content']['application/json'] & {
      token?: string
    }
  ) => Promise<LoginResult>
  logout: () => void
  error?: any
  action?: LoginActions
} {
  const { data, error, isMutating, trigger, reset } = useSWRMutation(
    `/login`,
    postLogin
    // {
    // throwOnError: false,
    // }
  )

  const [loginAction, setLoginAction] = useState<LoginActions | undefined>(
    undefined
  )

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const parseErrorMessage = useCallback((error: any) => {
    try {
      console.log('inside parseErrorMessage: %o', error.message)
      return JSON.parse(error.message)
    } catch (e) {
      console.error('error parsing message: %o', e)
    }
  }, [])

  const login = useCallback(
    async (
      action: LoginActions,
      extraArguments: paths['/profile/login']['post']['requestBody']['content']['application/json'] & {
        token?: string
      }
    ): Promise<LoginResult> => {
      try {
        const data = await trigger(extraArguments)
        setLoginAction(action)
        return { success: !!data?.jwt, token: data?.jwt }
      } catch (e) {
        // TODO [Dospore] this wont ever get caught since the useSWRMutation has throwOnError: false
        return { success: false, error: parseErrorMessage(e) }
      }
    },
    [parseErrorMessage, trigger]
  )

  useEffect(() => {
    if (error) {
      setErrorMessage(parseErrorMessage(error))
    }
  }, [isMutating, error, parseErrorMessage])

  const logout = useCallback(() => {
    console.log('Resetting login state')
    reset()
    setLoginAction(undefined)
    setErrorMessage(undefined)
  }, [reset])

  return {
    token: data?.jwt,
    isValid: !!data?.jwt,
    isLoading: isMutating,
    login,
    logout,
    error: errorMessage,
    action: loginAction,
  }
}
