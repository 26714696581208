import { useState } from 'react'
import { Button, ButtonProps, Spinner, Text } from '@chakra-ui/react'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { isRejectedTxn } from '../../../../helpers/toasts'

export default function WithdrawButton({
  onClick,
  isDisabled,
  onClose,
}: {
  onClick?: () => any
  isDisabled?: ButtonProps['isDisabled']
  onClose?: () => void
}) {
  const [isWithdrawingCollateral, setIsWithdrawingCollateral] = useState(false)
  const termToast = useTermToast()

  return (
    <Button
      isDisabled={isDisabled || isWithdrawingCollateral}
      onClick={async () => {
        setIsWithdrawingCollateral(true)
        termToast.pending(
          termToastMessages.depositOrWithdraw.pending('withdraw')
        )
        try {
          if (onClick) {
            await onClick()
            termToast.success(
              termToastMessages.depositOrWithdraw.success('withdraw')
            )
          }
          setIsWithdrawingCollateral(false)

          onClose?.()
        } catch (error) {
          setIsWithdrawingCollateral(false)
          if (isRejectedTxn(error)) {
            termToast.dismissed()
          } else {
            termToast.failure(
              termToastMessages.depositOrWithdraw.failure('withdraw')
            )
          }
        }
      }}
      variant="secondary"
      w="full"
    >
      <Text as="span" variant="body-sm/semibold">
        Withdraw
      </Text>
      {isWithdrawingCollateral && (
        <>
          <Spinner size="sm" ml="2" />{' '}
        </>
      )}
    </Button>
  )
}
