import { Heading } from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import Container from '../../../../components/layout/Container'
import SummaryBreakdown from '../SummarySection/SummaryBreakdown'
import SummaryHeaderLoading from './SummaryHeaderLoading'
import { Trans } from '@lingui/macro'

const zeroFN = FixedNumber.from(0)

const summaryData = [
  {
    label: 'Collateral Overview',
    values: [
      {
        label: 'Required Collateral',
        value: zeroFN,
      },
      {
        label: 'Deposited Collateral',
        value: zeroFN,
      },
      {
        label: 'Excess Collateral',
        value: zeroFN,
      },
      {
        label: 'Least Collateral Ratio / Requirement Ratio',
        node: '0% / 0%',
      },
    ],
    noValuesText: 'You have no collateral deposited.',
  },
  {
    label: 'Open Tenders',
    values: [
      {
        label: 'Total Bids',
        value: zeroFN,
      },
      {
        label: 'Total Initial Collateral Required',
        value: zeroFN,
      },
      {
        label: 'Total Collateral Deposited',
        value: zeroFN,
      },
      {
        label: 'Total Offers',
        value: zeroFN,
      },
    ],
    noValuesText: 'You have no open tenders.',
  },
]
export default function SummarySectionLoading() {
  return (
    <Container maxW="1043px">
      <Heading as="h1" my={5} variant="heading/h3" pl={5}>
        <Trans>Open Positions</Trans>
      </Heading>
      <SummaryHeaderLoading />
      <VStack w="full" spacing="48px" mt={12}>
        {summaryData.map(({ label, values, noValuesText }) => (
          <VStack key={label + '-loading'} w="full" rowGap={4}>
            <SummaryBreakdown
              isDataLoading
              key={label}
              title={label}
              values={values}
              noValuesText={noValuesText}
            />
          </VStack>
        ))}
      </VStack>
    </Container>
  )
}
