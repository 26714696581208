import { useEffect, useMemo, useCallback } from 'react'
import {
  TransactionStatus,
  TransactionState,
  useSendTransaction,
} from '@usedapp/core'
import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { Contract, Signer } from 'ethers'
import { useClock } from '../../providers/time'
import { waitForStatus } from '../../helpers/wait'
import { convertChainId, getABIVersion } from '../../helpers/conversions'

import { Auction, Address, TenderId } from '../model'

import { generateTermAuth } from '../auth'

import TermAuctionBidLockerABI_0_2_4 from '../../abi/v0.2.4/TermAuctionBidLocker.json'
import TermAuctionOfferLockerABI_0_2_4 from '../../abi/v0.2.4/TermAuctionOfferLocker.json'
import { TermAuctionOfferLocker as TermAuctionOfferLocker_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermAuctionOfferLocker'
import { TermAuctionBidLocker as TermAuctionBidLocker_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermAuctionBidLocker'

import TermAuctionBidLockerABI_0_4_1 from '../../abi/v0.4.1/TermAuctionBidLocker.json'
import TermAuctionOfferLockerABI_0_4_1 from '../../abi/v0.4.1/TermAuctionOfferLocker.json'
import { TermAuctionBidLocker as TermAuctionBidLocker_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermAuctionBidLocker'
import { TermAuctionOfferLocker as TermAuctionOfferLocker_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermAuctionOfferLocker'

import TermAuctionBidLockerABI_0_6_0 from '../../abi/v0.6.0/TermAuctionBidLocker.json'
import TermAuctionOfferLockerABI_0_6_0 from '../../abi/v0.6.0/TermAuctionOfferLocker.json'
import { TermAuctionBidLocker as TermAuctionBidLocker_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermAuctionBidLocker'
import { TermAuctionOfferLocker as TermAuctionOfferLocker_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermAuctionOfferLocker'

import { TermAuctionBidLocker } from './use-borrow-tenders'
import { TermAuctionOfferLocker } from './use-loan-tenders'

const createBidLocker = (
  version: string,
  provider: JsonRpcProvider | FallbackProvider,
  bidLockerAddress: string
): TermAuctionBidLocker => {
  switch (version) {
    case '0.6.0':
      return new Contract(
        bidLockerAddress,
        TermAuctionBidLockerABI_0_6_0,
        provider
      ) as TermAuctionBidLocker_0_6_0
    case '0.4.1':
      return new Contract(
        bidLockerAddress,
        TermAuctionBidLockerABI_0_4_1,
        provider
      ) as TermAuctionBidLocker_0_4_1
    case '0.2.4':
    default:
      return new Contract(
        bidLockerAddress,
        TermAuctionBidLockerABI_0_2_4,
        provider
      ) as TermAuctionBidLocker_0_2_4
  }
}

const createOfferLocker = (
  version: string,
  provider: JsonRpcProvider | FallbackProvider,
  offerLockerAddress: string
): TermAuctionOfferLocker => {
  switch (version) {
    case '0.6.0':
      return new Contract(
        offerLockerAddress,
        TermAuctionOfferLockerABI_0_6_0,
        provider
      ) as TermAuctionOfferLocker_0_6_0
    case '0.4.1':
      return new Contract(
        offerLockerAddress,
        TermAuctionOfferLockerABI_0_4_1,
        provider
      ) as TermAuctionOfferLocker_0_4_1
    case '0.2.4':
    default:
      return new Contract(
        offerLockerAddress,
        TermAuctionOfferLockerABI_0_2_4,
        provider
      ) as TermAuctionOfferLocker_0_2_4
  }
}

export function useDeleteTenders(
  account: Address | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,

  refreshBorrows: () => any,
  refreshLoans: () => any
): [
  // delete borrows
  (
    | ((
        chainId: string,
        ids: TenderId[],
        auction: Auction | undefined
      ) => Promise<TransactionState>)
    | undefined
  ),
  // delete loans
  (
    | ((
        chainId: string,
        ids: TenderId[],
        auction: Auction | undefined
      ) => Promise<TransactionState>)
    | undefined
  ),
] {
  const clock = useClock()

  const transactionStates = useMemo(
    () =>
      ({}) as {
        deleteBorrow: TransactionStatus | undefined
        deleteLoan: TransactionStatus | undefined
      },
    []
  )
  const {
    sendTransaction: deleteBorrowTransaction,
    state: deleteBorrowTransactionState,
    resetState: resetDeleteBorrowState,
  } = useSendTransaction()
  const {
    sendTransaction: deleteLoanTransaction,
    state: deleteLoanTransactionState,
    resetState: resetDeleteLoanState,
  } = useSendTransaction()
  // WARNING: These are used to update in-place so that the lock/delete
  //          methods always have access to the latest transaction state.
  useEffect(() => {
    transactionStates.deleteBorrow = deleteBorrowTransactionState
  }, [deleteBorrowTransactionState, transactionStates])
  useEffect(() => {
    transactionStates.deleteLoan = deleteLoanTransactionState
  }, [deleteLoanTransactionState, transactionStates])

  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return activeNetwork?.chainId === chainId
    },
    [signer]
  )

  const deleteLoanTenders = useCallback(
    async (chainId: string, ids: TenderId[], auction: Auction | undefined) => {
      const parsedChainId = convertChainId(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || !signer || !provider) {
        return 'None'
      }
      if (!auction) {
        console.error('Cant delete borrow tenders from an unknown auction')
        return 'None'
      }

      const offerLockerAddress = auction.offerLockerAddress

      // Remove from localstorage
      ids.forEach((id) => localStorage.removeItem(`loan-${id}`))

      const abiVersion = getABIVersion(auction.version)

      let data: any

      const offerLocker = createOfferLocker(
        abiVersion,
        provider,
        offerLockerAddress
      )

      switch (abiVersion) {
        case '0.6.0':
          console.info('deleting tenders from 0.6.0 contract')
          // Remove from smart contract
          data = (
            offerLocker as TermAuctionOfferLocker_0_6_0
          ).interface.encodeFunctionData('unlockOffers', [ids])
          break
        case '0.4.1':
          console.info('deleting tenders from 0.4.1 contract')
          // Remove from smart contract
          data = (
            offerLocker as TermAuctionOfferLocker_0_4_1
          ).interface.encodeFunctionData('unlockOffers', [ids])
          break
        case '0.2.4':
        default:
          console.info('deleting tenders from 0.2.4 contract')
          // Remove from smart contract
          data = (
            offerLocker as TermAuctionOfferLocker_0_2_4
          )?.interface.encodeFunctionData('unlockOffers', [
            ids,
            await generateTermAuth(
              signer,
              offerLocker as TermAuctionOfferLocker_0_2_4,
              'unlockOffers',
              [ids],
              clock.now().unix()
            ),
          ])
      }

      await deleteLoanTransaction({
        chainId: parsedChainId,
        to: offerLockerAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deleteLoan,
        resetDeleteLoanState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      refreshLoans()

      return transactionStates?.deleteLoan?.status ?? 'None'
    },
    [
      validateActiveNetwork,
      account,
      signer,
      provider,
      deleteLoanTransaction,
      resetDeleteLoanState,
      refreshLoans,
      transactionStates.deleteLoan,
      clock,
    ]
  )

  const deleteBorrowTenders = useCallback(
    async (chainId: string, ids: TenderId[], auction: Auction | undefined) => {
      const parsedChainId = convertChainId(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      if (account === undefined || !signer || !provider) {
        return 'None'
      }

      if (!auction) {
        console.error('Cant delete borrow tenders from an unknown auction')
        return 'None'
      }

      const bidLockerAddress = auction.bidLockerAddress

      // Remove from localstorage
      ids.forEach((id) => localStorage.removeItem(`borrow-${id}`))

      const abiVersion = getABIVersion(auction.version)

      let data: any

      const bidLocker = createBidLocker(abiVersion, provider, bidLockerAddress)

      switch (abiVersion) {
        case '0.6.0':
          console.info('deleting tenders from 0.6.0 contract')
          data = (
            bidLocker as TermAuctionBidLocker_0_6_0
          ).interface.encodeFunctionData('unlockBids', [ids])
          break
        case '0.4.1':
          console.info('deleting tenders from 0.4.1 contract')
          data = (
            bidLocker as TermAuctionBidLocker_0_4_1
          ).interface.encodeFunctionData('unlockBids', [ids])
          break
        case '0.2.4':
          // Remove from smart contract
          console.info('deleting tenders from 0.2.4 contract')
          data = (
            bidLocker as TermAuctionBidLocker_0_2_4
          ).interface.encodeFunctionData('unlockBids', [
            ids,
            await generateTermAuth(
              signer,
              bidLocker as TermAuctionBidLocker_0_2_4,
              'unlockBids',
              [ids],
              clock.now().unix()
            ),
          ])
          break
      }

      await deleteBorrowTransaction({
        chainId: parsedChainId,
        to: bidLockerAddress,
        from: account,
        data,
      })

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.deleteBorrow,
        resetDeleteBorrowState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000
      )

      refreshBorrows()

      return transactionStates?.deleteBorrow?.status ?? 'None'
    },
    [
      validateActiveNetwork,
      account,
      signer,
      provider,
      deleteBorrowTransaction,
      resetDeleteBorrowState,
      refreshBorrows,
      transactionStates.deleteBorrow,
      clock,
    ]
  )

  return [deleteBorrowTenders, deleteLoanTenders]
}
