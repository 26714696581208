import { Badge, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Auction } from '../../../data/model'
import { getAuctionTermString } from '../../../helpers/utils'

export default function AuctionTermBadge({
  // isSmall,
  auction: { auctionEndTimestamp, maturityTimestamp },
}: {
  isSmall?: boolean
  auction: Pick<Auction, 'auctionEndTimestamp' | 'maturityTimestamp'>
}) {
  const termString = getAuctionTermString(
    auctionEndTimestamp,
    maturityTimestamp
  )
  return (
    <Badge
      display="flex"
      alignItems="center"
      variant={termString.replace(' ', '')}
      fontSize="md"
      lineHeight="6"
      w="max-content"
    >
      <FontAwesomeIcon icon={['far', 'clock']} size="xs" />
      <Text ml="4px" variant="body-sm/medium">
        {termString}
      </Text>
    </Badge>
  )
}
