import { Button, ButtonProps } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export default function RedeemButton({
  onClick,
  isDisabled,
  isLoading,
}: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      isDisabled={isDisabled}
      fontSize="sm"
      variant="secondary"
      isLoading={isLoading}
      w="full"
    >
      <Trans>Redeem</Trans>
    </Button>
  )
}
