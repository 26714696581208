// import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { postValidateReferralCode } from '../../../helpers/swr'
import { components } from '../../../models/profile-api'

const statusMap: Record<number, string> = {
  404: 'Referral code not found',
  400: 'Invalid referral code',
}

const parseError = (error: any): string | undefined => {
  if (!error?.status) {
    return
  }
  return statusMap[error.status]
}

export function useValidateReferralCode(): {
  referralCode: components['schemas']['ValidatedProfileReferral'] | undefined
  isLoading: boolean
  validateReferralCode: (code: string) => void
  validationError?: string
} {
  const {
    data: referralCode,
    error,
    isMutating,
    trigger: validateReferralCode,
  } = useSWRMutation(`/profile/validate-referral`, postValidateReferralCode, {
    throwOnError: false,
  })

  const validationError = parseError(error)

  return {
    referralCode,
    isLoading: isMutating,
    validateReferralCode,
    validationError,
  }
}
