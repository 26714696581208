import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { BigNumber, FixedNumber } from 'ethers'
import { parseUnits, randomBytes } from 'ethers/lib/utils'
import { useMemo, useState, useEffect } from 'react'
import { ElectRollover, RolloverAuctionInfo } from '../../../../data/model'
import { obfuscatePrice } from '../../../../data/obfuscate'
import { evaluate } from '../../../../helpers/math'
import { formatFixedToken } from '../../../../helpers/utils'
import { useTermToast } from '../../../../hooks/toasts'
import { isRejectedTxn, termToastMessages } from '../../../../helpers/toasts'
import BorrowerRolloverAmountField from '../BorrowerRolloverAmountField'
import BorrowerRolloverAuctionField from '../BorrowerRolloverAuctionField'
import BorrowerRolloverInterestField from '../BorrowerRolloverInterestField'
import BorrowerRolloverOverview from '../BorrowerRolloverOverview'
import BorrowerRolloverSummary from '../BorrowerRolloverSummary'
import ElectRolloverButton from './ElectRolloverButton'
import SaveEditsButton from './SaveEditsButton'
import { Trans, t } from '@lingui/macro'

const existsAndIsNotZero = (fn: FixedNumber | undefined) => fn && !fn.isZero()

export default function BorrowerRolloverModal({
  chainId,
  collateralSymbol,
  purchaseSymbol,
  purchaseDecimals,
  auctionLabel,
  repurchaseWindowEnd,

  collateralPrice,
  purchasePrice,

  maturityTimestamp,
  depositedRatio,
  marginDeposited,
  outstandingDebt,

  rolloverAuctions,

  edit,

  defaultRolloverAmount,
  defaultInterestBidRate,
  defaultRolloverAuction,

  onElectRollover,
  onEditRollover,

  isOpen,
  onClose,
  onSwapToCancel,
  onKytCheck,
}: {
  chainId: string
  collateralSymbol: string
  purchaseSymbol: string
  purchaseDecimals: number
  auctionLabel: string
  repurchaseWindowEnd: number

  collateralPrice: FixedNumber
  purchasePrice: FixedNumber

  maturityTimestamp: number
  depositedRatio: FixedNumber
  marginDeposited: FixedNumber
  outstandingDebt: FixedNumber

  rolloverAuctions: RolloverAuctionInfo[]

  edit: boolean

  defaultRolloverAmount?: FixedNumber
  defaultInterestBidRate?: FixedNumber
  defaultRolloverAuction?: RolloverAuctionInfo

  onElectRollover: (electedRollover: ElectRollover) => Promise<void>
  onEditRollover: (electedRollover: ElectRollover) => Promise<void>

  isOpen: boolean
  onClose: () => void
  onSwapToCancel: () => void
  onKytCheck: () => Promise<boolean>
}) {
  const [rolloverAmount, setRolloverAmount] = useState<string>(
    existsAndIsNotZero(defaultRolloverAmount)
      ? (defaultRolloverAmount?.toString() as string)
      : ''
  )
  const [interestBidRate, setInterestBidRate] = useState<string>(
    existsAndIsNotZero(defaultInterestBidRate)
      ? (defaultInterestBidRate?.toString() as string)
      : ''
  )
  const [rolloverAuction, setRolloverAuction] = useState<
    RolloverAuctionInfo | undefined
  >(defaultRolloverAuction)

  const rolloverAmountFn = FixedNumber.fromString(
    rolloverAmount || '0',
    `fixed128x${purchaseDecimals}`
  )

  useEffect(() => {
    setRolloverAmount(
      existsAndIsNotZero(defaultRolloverAmount)
        ? (defaultRolloverAmount?.toString() as string)
        : ''
    )
  }, [defaultRolloverAmount])

  useEffect(() => {
    setInterestBidRate(
      existsAndIsNotZero(defaultInterestBidRate)
        ? (defaultInterestBidRate?.toString() as string)
        : ''
    )
  }, [defaultInterestBidRate])

  useEffect(() => {
    setRolloverAuction(defaultRolloverAuction)
  }, [defaultRolloverAuction])

  const onModalClose = () => {
    onClose()
    setRolloverAmount('')
    setInterestBidRate('')
    setRolloverAuction(undefined)
  }

  // const marginDepositedPurchaseToken = useMemo(
  //   () =>
  //     evaluate(
  //       {
  //         nodeKind: 'div',
  //         args: [
  //           {
  //             nodeKind: 'mul',
  //             args: [
  //               {
  //                 nodeKind: 'value',
  //                 value: marginDeposited,
  //               },
  //               {
  //                 nodeKind: 'value',
  //                 value: collateralPrice,
  //               },
  //             ],
  //           },
  //           {
  //             nodeKind: 'value',
  //             value: purchasePrice,
  //           },
  //         ],
  //       },
  //       marginDeposited.format.decimals
  //     ),
  //   [collateralPrice, marginDeposited, purchasePrice]
  // )
  const marginDepositedUSD = useMemo(
    () =>
      evaluate(
        {
          nodeKind: 'mul',
          args: [
            {
              nodeKind: 'value',
              value: marginDeposited,
            },
            {
              nodeKind: 'value',
              value: collateralPrice,
            },
          ],
        },
        marginDeposited.format.decimals
      ),
    [collateralPrice, marginDeposited]
  )

  const termToast = useTermToast()

  const isMoreThanOutstandingDebt = useMemo(
    () =>
      evaluate(
        {
          nodeKind: 'sub',
          args: [
            {
              nodeKind: 'value',
              value: outstandingDebt,
            },
            {
              nodeKind: 'value',
              value: rolloverAmountFn,
            },
          ],
        },
        outstandingDebt.format.decimals
      ).isNegative(),
    [outstandingDebt, rolloverAmountFn]
  )

  const [isElectingRollover, setIsElectingRollover] = useState(false)
  const [hasEdits, setHasEdits] = useState(false)

  const oldRolloverAmount = defaultRolloverAmount?.toString() || ''
  const oldInterestBidRate = defaultInterestBidRate?.toString() || ''
  const oldRolloverAuction = defaultRolloverAuction

  const interestBidRateFN = useMemo(
    () =>
      interestBidRate &&
      outstandingDebt?.format &&
      FixedNumber.fromString(interestBidRate, outstandingDebt.format),
    [interestBidRate, outstandingDebt.format]
  )

  useMemo(() => {
    if (
      rolloverAmount !== oldRolloverAmount ||
      interestBidRate !== oldInterestBidRate ||
      rolloverAuction !== oldRolloverAuction
    ) {
      setHasEdits(true)
    } else {
      setHasEdits(false)
    }
  }, [
    rolloverAmount,
    oldRolloverAmount,
    interestBidRate,
    oldInterestBidRate,
    rolloverAuction,
    oldRolloverAuction,
  ])

  const isZeroed = Number(rolloverAmount) === 0 && rolloverAmount !== ''

  const amountFieldHelperText = () => {
    if (isMoreThanOutstandingDebt) {
      return (
        <Text as="span" color="red.5">
          <Trans>Exceeds outstanding debt</Trans>
        </Text>
      )
    } else if (isZeroed) {
      return (
        <Trans>
          <Text as="span" color="red.5">
            {'Cancel your rollover'}{' '}
            <Text
              as="span"
              color="blue.5"
              cursor="pointer"
              _hover={{
                textDecoration: 'underline',
              }}
              onClick={onSwapToCancel}
            >
              here
            </Text>
          </Text>
        </Trans>
      )
    } else {
      return (
        <>
          <Trans>Available:</Trans>{' '}
          {formatFixedToken(outstandingDebt, purchaseSymbol, true)}
        </>
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} variant="wide">
      <ModalOverlay />
      <ModalContent p="4px">
        <ModalHeader>
          {!edit
            ? t`Need more time? Extend your borrow term`
            : t`Edit rollover`}
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody>
          <Text color="blue.9" variant="body-sm/normal" mb="16px">
            <Trans>
              Extend your term by electing to have your loan rolled into a new
              auction.
            </Trans>
          </Text>
          <BorrowerRolloverOverview
            marginDeposited={marginDeposited}
            marginRatio={depositedRatio}
            collateralTokenSymbol={collateralSymbol}
            outstandingDebt={outstandingDebt}
            maturityTimestamp={maturityTimestamp}
            purchaseTokenSymbol={purchaseSymbol}
            title={t`Current Loan Overview`}
            auctionLabel={auctionLabel}
          />
          <BorrowerRolloverAuctionField
            disabled={isElectingRollover || rolloverAuctions.length === 0}
            auctions={rolloverAuctions}
            purchaseCurrencySymbol={purchaseSymbol}
            collateralCurrencySymbol={collateralSymbol}
            value={rolloverAuction}
            onChange={setRolloverAuction}
          />
          <Grid templateColumns="1fr 1fr" gap="24px" mb="16px" mt="16px">
            <GridItem>
              <BorrowerRolloverAmountField
                disabled={isElectingRollover}
                purchaseTokenSymbol={purchaseSymbol}
                purchaseTokenDecimals={purchaseDecimals}
                outstandingDebt={outstandingDebt}
                onChange={setRolloverAmount}
                onChangeToken={(value) =>
                  console.log('TODO: onChangeToken', value)
                }
                value={rolloverAmount}
                isError={isMoreThanOutstandingDebt || isZeroed}
                helperText={amountFieldHelperText()}
              />
            </GridItem>
            <GridItem>
              <BorrowerRolloverInterestField
                disabled={isElectingRollover}
                value={interestBidRate}
                onChange={setInterestBidRate}
              />
            </GridItem>
          </Grid>
          {interestBidRate &&
            !rolloverAmountFn.isZero() &&
            !isMoreThanOutstandingDebt &&
            rolloverAuction &&
            interestBidRateFN && (
              <>
                <BorrowerRolloverSummary
                  selectedAuction={rolloverAuction}
                  rolloverAmount={rolloverAmountFn}
                  outstandingDebt={outstandingDebt}
                  rolloverMarginRequirement={rolloverAuction.marginRequirement}
                  purchaseSymbol={purchaseSymbol}
                  collateralDeposited={marginDeposited}
                  collateralDepositedUSD={marginDepositedUSD}
                  purchasePrice={purchasePrice}
                  collateralSymbol={collateralSymbol}
                  interestBidRate={interestBidRateFN}
                  repurchaseWindowEnd={repurchaseWindowEnd}
                />
              </>
            )}
          {edit ? (
            <HStack>
              <Button
                w="50%"
                isDisabled={isElectingRollover || !hasEdits}
                variant="tertiary"
                size="sm"
                onClick={() => {
                  setRolloverAmount(oldRolloverAmount)
                  setInterestBidRate(oldInterestBidRate)
                  setRolloverAuction(oldRolloverAuction)
                }}
              >
                <Text as="span" variant="body-sm/semibold">
                  <Trans>Revert</Trans>
                </Text>
              </Button>

              <SaveEditsButton
                w="50%"
                isDisabled={isElectingRollover || !hasEdits}
                isSubmitting={isElectingRollover}
                // isDisabled={!implicationsUnderstood}
                onClick={async () => {
                  setIsElectingRollover(true)
                  const hasKytCheckPassed = await onKytCheck()

                  if (!hasKytCheckPassed) {
                    console.error('unable to edit rollover')
                    setIsElectingRollover(false)
                    onClose()
                    return
                  }
                  const nonce = BigNumber.from(randomBytes(32))
                  const price = FixedNumber.fromString(interestBidRate || '0')
                  await onEditRollover({
                    chainId,
                    rolloverAmount: parseUnits(
                      rolloverAmount,
                      purchaseDecimals
                    ),
                    rolloverAuction: rolloverAuction?.auctionBidLocker ?? '',
                    rolloverBidPriceHash: obfuscatePrice(price, nonce),
                    nonce,
                    termId: rolloverAuction?.newTermId || '',
                    auctionId: rolloverAuction?.id || '',
                    revealedPrice: price,
                  })
                  setIsElectingRollover(false)
                  onClose()
                }}
              />
            </HStack>
          ) : (
            <ElectRolloverButton
              // isDisabled={!implicationsUnderstood}
              isDisabled={
                isMoreThanOutstandingDebt ||
                isZeroed ||
                !rolloverAmount ||
                !interestBidRate ||
                !rolloverAuction ||
                isElectingRollover
              }
              isSubmitting={isElectingRollover}
              onClick={async () => {
                setIsElectingRollover(true)
                const hasKytCheckPassed = await onKytCheck()

                if (!hasKytCheckPassed) {
                  console.error('unable to elect rollover')
                  setIsElectingRollover(false)
                  onClose()
                  return
                }

                termToast.pending(
                  termToastMessages.electOrEditRollover.pending('elect')
                )
                try {
                  const nonce = BigNumber.from(randomBytes(32))
                  const price = FixedNumber.fromString(interestBidRate || '0')

                  await onElectRollover({
                    chainId,
                    rolloverAmount: parseUnits(
                      rolloverAmount,
                      purchaseDecimals
                    ),
                    rolloverAuction: rolloverAuction?.auctionBidLocker ?? '',
                    rolloverBidPriceHash: obfuscatePrice(price, nonce),
                    nonce,
                    termId: rolloverAuction?.newTermId || '',
                    auctionId: rolloverAuction?.id || '',
                    revealedPrice: price,
                  })

                  setIsElectingRollover(false)

                  termToast.success(
                    termToastMessages.electOrEditRollover.success('elect')
                  )
                  // close modal after success
                  onClose()
                } catch (error) {
                  setIsElectingRollover(false)
                  if (isRejectedTxn(error)) {
                    termToast.dismissed()
                  } else {
                    termToast.failure(
                      termToastMessages.electOrEditRollover.failure('elect')
                    )
                  }
                }
              }}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
