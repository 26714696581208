import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { HStack, VStack } from '../Stack'
import { socialLinks } from '../../../socials'
import term_logo from '../../../assets/term-logo-short.svg'
import { Link as RouterLink } from 'react-router-dom'

export default function RewardsAnnouncementModal({
  isWalletConnected,
  isOpen,
  onConnect,
  onClose,
}: {
  isWalletConnected: boolean
  isOpen: boolean
  onConnect: () => void
  onClose: () => void
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount={false}
      variant="standard"
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={7} px={7}>
          <HStack>
            <Image src={term_logo} w={6} h={6} />
            <Text variant="body-md/medium" as="span">
              Introducing Rewards
            </Text>
          </HStack>
          <ModalCloseButton size="sm" mr="12px" mt="16px" color="blue.900" />
        </ModalHeader>
        <ModalBody px={7}>
          <VStack width="100%">
            <Box textAlign="left" mt={1.5} mb={2} pl={0}>
              <Text variant="body-xl/bold" color="green.5">
                Earn{' '}
                <Text as="span" color="blue.9">
                  Term Points
                </Text>
              </Text>
              <Text variant="body-md/medium" color="rgba(0, 16, 39, 0.75)">
                for lending & participating in auctions
              </Text>
              <br />
              <Text variant="body-sm/normal" color="#001027" opacity={0.5}>
                Lenders can now earn Term Points redeemable for rewards at
                season's end, for a limited time.
              </Text>
              <br />
              <Text variant="body-sm/normal" color="#001027" opacity={0.5}>
                Existing users are already earning points.{' '}
                {isWalletConnected ? (
                  <Text as="span">
                    Check your points balance and start earning!
                  </Text>
                ) : (
                  <Text as="span">
                    Connect to check your points balance and start earning!
                  </Text>
                )}
              </Text>
            </Box>
            <Box mt={4} w="100%">
              {isWalletConnected ? (
                <RouterLink to="/rewards">
                  <Button variant="secondary" w="100%" onClick={onClose}>
                    Start earning
                  </Button>
                </RouterLink>
              ) : (
                <Button variant="primary" w="100%" onClick={onConnect}>
                  Connect wallet
                </Button>
              )}
              <Button
                mt={2}
                size="md"
                variant="tertiary"
                border={0}
                w="100%"
                onClick={() => {
                  window.open(
                    socialLinks.rewardsAnnouncement,
                    '_blank',
                    'noopener,noreferrer'
                  )
                  onClose()
                }}
              >
                How can I earn points?
              </Button>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
