import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { LiquidationForDisplay } from '../../../../data/model'
import LoanLiquidationInfo from '../LoanLiquidationInfo'
import LoanLiquidationOverview from '../LoanLiquidationOverview'
import LoanLiquidationPrevious from '../LoanLiquidationPrevious'
import { convertChainId } from '../../../../helpers/conversions'
import { useChainConfig } from '../../../../providers/config'
import { Trans, t } from '@lingui/macro'

export default function LoanLiquidationModal({
  chainId,
  currentLiquidation,
  pastLiquidations,
  auctionLabel,
  isOpen,
  onClose,
}: {
  chainId: string
  currentLiquidation: LiquidationForDisplay
  pastLiquidations?: LiquidationForDisplay[]
  auctionLabel: string
  isOpen: boolean
  onClose: () => void
}) {
  const partial =
    !currentLiquidation.loanRemaining.isNegative() &&
    !currentLiquidation.loanRemaining.isZero()

  // TODO: use chainId from context
  const parsedChainId = convertChainId(chainId)
  const chainConfig = useChainConfig(parsedChainId)

  const getBlockExplorerTransactionUrl = chainConfig?.getExplorerTransactionLink
  const defaultLiquidation = currentLiquidation.defaultLiquidation
  const liquidationTimestamp = currentLiquidation.timestamp

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="blue.9"
          fontSize="lg"
          fontWeight="700"
          lineHeight="7"
        >
          {partial ? t`Loan Partially Liquidated` : t`Loan Liquidated`}
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody px={7} pb={7}>
          <LoanLiquidationOverview
            getBlockExplorerTransactionUrl={getBlockExplorerTransactionUrl}
            liquidation={currentLiquidation}
            auctionLabel={auctionLabel}
            partial={partial}
          />
          <Box mt="20px" />
          <LoanLiquidationInfo
            partial={partial}
            defaultLiquidation={defaultLiquidation}
            liquidationTimestamp={liquidationTimestamp}
          />
          <Box mt="20px" />
          <Button variant="secondary" size="sm" onClick={onClose} w="full">
            <Text as="span" variant="body-sm/semibold" color="blue.8">
              <Trans>Close</Trans>
            </Text>
          </Button>
          {pastLiquidations && pastLiquidations.length > 0 && (
            <>
              <Box mt="20px" />
              <LoanLiquidationPrevious
                getBlockExplorerTransactionUrl={getBlockExplorerTransactionUrl}
                liquidations={pastLiquidations}
              />
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
