import useSWR from 'swr'
import { pointsFetcher } from '../../../helpers/swr'
import { wait } from '../../../helpers/wait'
import { getMockPointsData } from '../../../test-helpers/data-points'
import config from '../../../config'
import { components } from '../../../models/profile-api'

export function useSeasonsInfo(): {
  seasonsInfo: components['schemas']['Seasons'] | undefined
  isLoading: boolean
} {
  const { data: seasonInfos, /* error, */ isLoading } = useSWR<
    components['schemas']['Seasons'] | undefined
  >(`/seasons`, pointsFetcher)

  return {
    seasonsInfo: seasonInfos,
    isLoading,
  }
}

// eslint-disable-next-line unused-imports/no-unused-vars
export const seasonsFetcher = async (path: string) => {
  const url = `${config.profileServerUrl}/seasons`

  // this is used for mocking api return data can be removed after testing this wait time can be changed
  if (process.env.REACT_APP_POINTS_TESTING) {
    return wait(2000).then(() => getMockPointsData(url)) as Promise<any>
  }

  const res = await fetch(url)
  return await res.json()
}
