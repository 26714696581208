import { IconButton, Image } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { useNetworkWhiteIcons } from '../../../../data/hooks/use-network-icons'
import { useConfig } from '../../../../providers/config'
import { useSafary } from '../../../../data/analytics/use-safary'

export const NetworkButton = ({
  chainName,
  chainId,
  selectedChainId,
  handleNetworkFilter,
}: {
  chainName: string
  chainId: string
  selectedChainId?: string
  handleNetworkFilter: (chainId: string) => void
}) => {
  const isSelected = selectedChainId === chainId
  const whiteSrc = useNetworkWhiteIcons(chainId)
  const config = useConfig()
  const { trackSafaryEvent } = useSafary()
  return (
    <IconButton
      key={chainName}
      variant="outline"
      borderColor={selectedChainId === chainId ? 'white' : 'gray.2'}
      bg={selectedChainId === chainId ? 'blue.5' : undefined}
      aria-label={chainName.charAt(0).toUpperCase() + chainName.slice(1)}
      icon={
        <>
          {isSelected ? (
            <>
              <Image boxSize="20px" src={whiteSrc} />
              <FontAwesomeIcon
                icon={['far', 'check']}
                role="button"
                width="10px"
                color="white"
              />
            </>
          ) : (
            <NetworkIcon chainId={chainId || 1} boxSize="20px" />
          )}
        </>
      }
      _hover={
        selectedChainId !== chainId ? { bg: 'gray.0', border: 'none' } : {}
      }
      onClick={() => {
        handleNetworkFilter(chainId)
        trackSafaryEvent(
          config?.safary?.earn?.selectChain?.type ?? 'click',
          'select chain',
          {
            chainId,
          }
        )
      }}
      w="44px"
      h="44px"
    />
  )
}
