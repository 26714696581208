import { BigNumber, FixedNumber, Signer } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { ChainId, useBlockMeta } from '@usedapp/core'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import {
  LendTokensDropdownQuery,
  LendTokensDropdownQueryVariables,
  PageLendQuery,
  PageLendQueryVariables,
} from '../gql/graphql'
import {
  GetActiveListingsQuery,
  GetActiveListingsQueryVariables,
} from '../gql/listings/graphql'
import { useGlobalRefresher } from '../providers/refresher'
import { useCurrentTimeSlow, useGraphQueries } from '../data/hooks/helper-hooks'
import { useChainConfigs, useConfig } from '../providers/config'
import {
  ActiveListingRepo,
  Address,
  Currency,
  ExternalProject,
  ListingsData,
  MappedLendCurrencies,
} from '../data/model'
import { add, multiply, subtract } from '../helpers/math'
import { TokenInfoPair, usePrices } from '../data/hooks/use-prices'
import { useTermRepoExposures } from '../data/hooks/use-term-repo-exposures'
import { useRepoLockerBalances } from '../data/hooks/use-repo-locker-balances'
import { useListings } from '../data/hooks/use-listings'
import { LendPageParams } from '../models/lend'
import { useBalances } from '../data/hooks/use-balances'
import { TokenAllowance, useAllowances } from '../data/hooks/use-allowances'
import { useTokenApprove } from '../data/hooks/use-token-approve'
import { bigToFixedNumber } from '../helpers/conversions'
import dayjs from 'dayjs'
import { calculateRepoTokensReceived } from '../pages/Lend/utils'
import { watchAsset } from '../helpers/eip747'
import { solidityKeccak256 } from 'ethers/lib/utils'
import { useExternalProjectPoints } from '../data/hooks/use-external-project-points'
import { useSafary } from '../data/analytics/use-safary'

export function useLendPage(
  account: string | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  onConnect: () => void,
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>,
  onKytCheck: () => Promise<boolean>
) {
  const [selectedPurchaseToken, setSelectedPurchaseToken] =
    useState<string>('USDC')
  const [activeLendItem, setActiveLendItem] = useState<
    ListingsData | undefined
  >(undefined)
  const [lendAmount, setLendAmount] = useState<string>('')

  const [isPurchasingMaxAvailable, setIsPurchasingMaxAvailable] =
    useState<boolean>(false)

  const { slow: autoRefresher } = useGlobalRefresher()
  const currentTimeSlow = useCurrentTimeSlow()
  const chainConfigs = useChainConfigs()
  const currentBlock = useBlockMeta()
  const config = useConfig()
  const externalProjectPoints = useExternalProjectPoints()

  const { trackSafaryEvent } = useSafary()

  // #region Listing Token Dropdown Query and Setup

  const lendTokensDropdownSubgraphQueries = useMemo(() => {
    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.LEND_TOKENS_DROPDOWN
      )
      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.LEND_TOKENS_DROPDOWN,
        variables: {
          currentTime: currentTimeSlow.unix(),
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.subgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [chainConfigs, currentTimeSlow])

  // Query data from subgraph for page
  // TODO: wire up fetching, error flags
  const {
    results: dropdownSubgraphData,
    // fetching,
    // error,
    refresh: readFromMainSubgraph_Dropdown,
  } = useGraphQueries<
    LendTokensDropdownQuery,
    LendTokensDropdownQueryVariables
  >(lendTokensDropdownSubgraphQueries)

  useEffect(() => {
    readFromMainSubgraph_Dropdown()
  }, [readFromMainSubgraph_Dropdown, autoRefresher])

  // build a map of currency symbol to token address across chains
  const mappedCurrencies = useMemo(() => {
    const result: MappedLendCurrencies = {}

    // Iterate over each chain's data
    for (const [chainIdStr, data] of Object.entries(dropdownSubgraphData)) {
      const chainId = Number(chainIdStr)
      const termRepos = data.termRepos || []

      // Iterate over each termRepo in the chain
      for (const termRepo of termRepos) {
        if (!termRepo.purchaseTokenMeta) continue

        const { symbol, id: tokenAddress } = termRepo.purchaseTokenMeta
        if (!result[symbol]) {
          result[symbol] = {}
        }
        result[symbol][chainId] = tokenAddress
      }
    }

    return result
  }, [dropdownSubgraphData])

  // #endregion

  // #region Main Subgraph Query and Extract Repo Token Addresses for selected purchase token

  const mainSubgraphQueries = useMemo(() => {
    if (!mappedCurrencies?.[selectedPurchaseToken]) return []
    return chainConfigs
      .map((chainConfig) => {
        if (!mappedCurrencies?.[selectedPurchaseToken]?.[chainConfig.chainId]) {
          console.debug(
            'no token address found for %s on chain %s, skipping',
            selectedPurchaseToken,
            chainConfig.chainId
          )
          return undefined
        }

        const subgraphVersion = chainConfig.getSubgraphVersion()
        const queryDoc = getQueryDocument(
          subgraphVersion,
          DocumentType.PAGE_LEND
        )
        const queryVariables = getQueryVariables({
          subgraphVersion,
          docType: DocumentType.PAGE_LEND,
          variables: {
            purchaseToken:
              mappedCurrencies?.[selectedPurchaseToken]?.[chainConfig.chainId],
            currentTime: currentTimeSlow.unix(),
          },
        })
        return {
          chainId: chainConfig.chainId,
          url: chainConfig.subgraphUrl,
          query: queryDoc,
          variables: queryVariables,
        }
      })
      .filter((query) => query !== undefined)
  }, [chainConfigs, mappedCurrencies, currentTimeSlow, selectedPurchaseToken])

  // Query data from subgraph for page
  // TODO: wire up fetching, error flags
  const {
    results: mainSubgraphData,
    // fetching,
    // error,
    refresh: readFromMainSubgraph,
  } = useGraphQueries<PageLendQuery, PageLendQueryVariables>(
    mainSubgraphQueries
  )

  useEffect(() => {
    readFromMainSubgraph()
  }, [readFromMainSubgraph, autoRefresher])

  const repoTokenAddressesByChain = useMemo(() => {
    const result: { [chainId: string]: Address[] } = {}
    if (mainSubgraphData) {
      for (const [chainId, data] of Object.entries(mainSubgraphData)) {
        result[chainId] = data?.termRepos?.map(
          (termRepo) => termRepo.termRepoToken
        )
      }
    }
    return result
  }, [mainSubgraphData])

  // #endregion

  // #region Listings Subgraph Query Setup

  const listingsSubgraphQueries = useMemo(() => {
    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(subgraphVersion, DocumentType.LISTINGS)
      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.LISTINGS,
        variables: {
          tokenList:
            repoTokenAddressesByChain &&
            repoTokenAddressesByChain?.[chainConfig.chainId]
              ? repoTokenAddressesByChain[chainConfig.chainId]
              : [],
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.listingsSubgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [chainConfigs, repoTokenAddressesByChain])

  // Query data from subgraph for page
  // TODO: wire up fetching, error flags
  const {
    results: listingsSubgraphData,
    // fetching,
    // error,
    refresh: readFromListingsSubgraph,
  } = useGraphQueries<GetActiveListingsQuery, GetActiveListingsQueryVariables>(
    listingsSubgraphQueries
  )

  useEffect(() => {
    readFromListingsSubgraph()
  }, [readFromListingsSubgraph, autoRefresher])

  // #endregion

  const activeListingsByToken = useMemo(() => {
    if (!mainSubgraphData || !listingsSubgraphData) {
      return undefined
    }

    // Compute groupedRepoTokenData
    const groupedRepoTokenData = Object.entries(mainSubgraphData).reduce(
      (
        acc: { [chainId: string]: { [termRepoToken: Address]: ListingsData } },
        [chainId, data]
      ) => {
        const repoTokensData = (data?.termRepos || []).reduce(
          (repoAcc: { [termRepoToken: Address]: ListingsData }, termRepo) => {
            const lastAuction = termRepo.completedAuctions?.[0] || {}

            const matchingProjects = termRepo.collateralTokens
              ?.map(
                (tokenAddress) =>
                  externalProjectPoints[Number(chainId) as ChainId]?.[
                    tokenAddress
                  ]
              )
              .filter(
                (
                  project
                ): project is {
                  label: string
                  project: ExternalProject
                  pointsMultiplier: number
                } => project !== undefined
              )

            repoAcc[termRepo.termRepoToken] = {
              id: solidityKeccak256(
                ['string'],
                [`${chainId}-${termRepo.termRepoToken}`]
              ),
              chainId: chainId,
              risk: 'Low', // TODO: wire this up
              termPointsMultiplier: 1, // TODO: wire this up
              remainingAmount: FixedNumber.fromString(
                '0',
                `fixed128x${Number(termRepo.purchaseTokenMeta?.decimals)}`
              ),
              termRepoServicer: termRepo.termRepoServicer,
              termRepoLocker: termRepo.termRepoLocker,
              termRepoToken: termRepo.termRepoToken,
              purchaseToken: termRepo.purchaseToken,
              purchaseTokenMeta: {
                address: termRepo.purchaseToken,
                symbol: termRepo.purchaseTokenMeta?.symbol ?? '',
                decimals: Number(termRepo.purchaseTokenMeta?.decimals),
              },
              repurchaseTimestamp: termRepo.repurchaseTimestamp,
              redemptionTimestamp: termRepo.redemptionTimestamp,
              collateralTokens: termRepo.collateralTokens ?? [],
              collateralTokensMeta:
                termRepo.collateralTokensMeta?.map((collateralTokenMeta) => ({
                  address: collateralTokenMeta.id,
                  symbol: collateralTokenMeta.symbol,
                  decimals: Number(collateralTokenMeta.decimals),
                })) ?? [],
              collateralRatios:
                termRepo.collateralRatios?.map((ratio) => ({
                  collateralToken: ratio.collateralToken,
                  maintenanceRatio: FixedNumber.fromValue(
                    ratio.maintenanceRatio,
                    18
                  ),
                })) ?? [],
              lastAuctionEndTime: lastAuction?.auctionEndTime,
              lastAuctionClearingPrice: FixedNumber.fromValue(
                lastAuction?.auctionClearingPrice ?? 0,
                18
              ),
              externalProjects: matchingProjects ?? undefined,
            }
            return repoAcc
          },
          {}
        )
        acc[chainId] = repoTokensData
        return acc
      },
      {}
    )

    // Compute activeListingsByToken
    const aggregatedRemainingByToken = Object.entries(
      listingsSubgraphData
    ).reduce(
      (
        acc: { [chainId: string]: { [termRepoToken: Address]: ListingsData } },
        [chainId, data]
      ) => {
        acc[chainId] = acc[chainId] || {}
        ;(data?.listings || []).forEach((listing) => {
          const tokenData =
            groupedRepoTokenData[chainId]?.[listing.token] ||
            acc[chainId][listing.token]

          if (tokenData) {
            tokenData.remainingAmount = add(
              tokenData.remainingAmount,
              FixedNumber.fromValue(
                listing.remainingAmount,
                tokenData.purchaseTokenMeta.decimals
              ),
              tokenData.purchaseTokenMeta.decimals
            )
            acc[chainId][listing.token] = tokenData
          }
        })
        return acc
      },
      {}
    )

    return aggregatedRemainingByToken
  }, [mainSubgraphData, listingsSubgraphData, externalProjectPoints])

  const { purchaseTokensByChain, repoTokensByChain } = useMemo(() => {
    const purchaseTokenResult: { [chainId: string]: Address[] } = {}
    const repoTokenResult: { [chainId: string]: Address[] } = {}
    if (activeListingsByToken) {
      for (const [chainId, data] of Object.entries(activeListingsByToken)) {
        repoTokenResult[chainId] = Object.keys(data)
        purchaseTokenResult[chainId] = Object.values(data).map(
          (listing) => listing.purchaseToken
        )
      }
    }
    return {
      purchaseTokensByChain: purchaseTokenResult,
      repoTokensByChain: repoTokenResult,
    }
  }, [activeListingsByToken])

  const erc20TokensByChain = useMemo(() => {
    const result: { [chainId: string]: TokenInfoPair[] } = {}
    if (mainSubgraphData) {
      for (const [chainId, data] of Object.entries(mainSubgraphData)) {
        const termRepos = data?.termRepos || []
        const seenAddresses = new Set<string>()
        const tokenInfoPairs: TokenInfoPair[] = []

        for (const termRepo of termRepos) {
          const address = termRepo.purchaseToken.toLowerCase()
          const decimals = termRepo.purchaseTokenMeta?.decimals

          if (!seenAddresses.has(address)) {
            seenAddresses.add(address)
            tokenInfoPairs.push({ address, decimals })
          }

          // Process collateralTokens and collateralTokensMeta arrays
          const collateralTokens = termRepo.collateralTokens || []
          const collateralTokensMeta = termRepo.collateralTokensMeta || []

          for (let i = 0; i < collateralTokens.length; i++) {
            const collateralAddress = collateralTokens[i].toLowerCase()
            const collateralDecimals = collateralTokensMeta[i]?.decimals

            if (collateralAddress && !seenAddresses.has(collateralAddress)) {
              seenAddresses.add(collateralAddress)
              tokenInfoPairs.push({
                address: collateralAddress,
                decimals: collateralDecimals,
              })
            }
          }
        }

        result[chainId] = tokenInfoPairs
      }
    }
    return result
  }, [mainSubgraphData])

  const {
    discountRateMarkups,
    swapPurchaseTokensForRepoTokens,
    purchaseRepoTokens,
  } = useListings(provider, signer, {}, () => {})

  const pricesData = usePrices(erc20TokensByChain, repoTokensByChain, provider)

  const prices = useMemo(() => {
    if (!pricesData) return undefined

    const result: { [chainId: string]: { [token: Address]: FixedNumber } } = {}

    pricesData &&
      Object.entries(pricesData).forEach(([chainId, priceArray]) => {
        result[chainId] =
          priceArray.reduce(
            (acc, price) => {
              acc[price.token] = FixedNumber.fromValue(
                price.price ?? BigNumber.from(0),
                price.decimals,
                `fixed128x${price.decimals}`
              )
              return acc
            },
            {} as { [token: Address]: FixedNumber }
          ) ?? {}
      })

    return result
  }, [pricesData])

  const currenciesByChain = useMemo(() => {
    const result: { [chainId: string]: { [address: Address]: Currency } } = {}
    if (mainSubgraphData) {
      for (const [chainId, data] of Object.entries(mainSubgraphData)) {
        result[chainId] = {}
        const termRepos = data?.termRepos || []
        for (const termRepo of termRepos) {
          const address = termRepo.purchaseToken.toLowerCase()
          if (!result[chainId][address]) {
            result[chainId][address] = {
              address,
              decimals: Number(termRepo.purchaseTokenMeta?.decimals),
              symbol: termRepo.purchaseTokenMeta?.symbol ?? '',
              isRepoToken: false,
            }
          }
        }
      }
    }
    return result
  }, [mainSubgraphData])

  const balancesData = useBalances(account, currenciesByChain, provider)

  const balances = useMemo(() => {
    const result: { [chainId: string]: { [address: Address]: FixedNumber } } =
      {}
    if (balancesData) {
      for (const [chainId, data] of Object.entries(balancesData)) {
        result[chainId] = {}
        for (const [idx, balance] of Object.entries(data)) {
          result[chainId][balance.address] = balance.balance
        }
      }
    }
    return result
  }, [balancesData])

  const { purchaseTokenBalance, purchaseTokenCurrency } = useMemo(() => {
    if (!balances || !activeLendItem || !balances?.[activeLendItem.chainId])
      return {
        purchaseTokenBalance: FixedNumber.from('0'),
        purchaseTokenCurrency: undefined,
      }

    return {
      purchaseTokenBalance:
        balances[activeLendItem.chainId][activeLendItem.purchaseToken],
      purchaseTokenCurrency:
        currenciesByChain?.[activeLendItem.chainId]?.[
          activeLendItem.purchaseToken
        ],
    }
  }, [balances, activeLendItem, currenciesByChain])

  /** make calls to lookup:
   * totalOutstandingRepurchaseExposure on servicer
   * collateral balance for repoLocker
   * discountRateMarkup on RepoTokenLinkedList
   **/

  const { repoServicerAddressesByChain, purchaseTokenDecimalsByChain } =
    useMemo(() => {
      const repoServicerAddresses: { [chainId: string]: Address[] } = {}
      const decimals: { [chainId: string]: number[] } = {}
      if (activeListingsByToken) {
        for (const [chainId, data] of Object.entries(activeListingsByToken)) {
          repoServicerAddresses[chainId] = Object.values(data).map(
            (listing) => listing.termRepoServicer
          )
          decimals[chainId] = Object.values(data).map(
            (listing) => listing.purchaseTokenMeta?.decimals
          )
        }
      }
      return {
        repoServicerAddressesByChain: repoServicerAddresses,
        purchaseTokenDecimalsByChain: decimals,
      }
    }, [activeListingsByToken])

  const totalOutstandingRepurchaseExposures = useTermRepoExposures(
    repoServicerAddressesByChain,
    purchaseTokenDecimalsByChain,
    provider
  )

  const repoLockerAddressesByChain = useMemo(() => {
    const result: {
      [chainId: string]: { [repoLockerAddress: Address]: Currency[] }
    } = {}
    if (activeListingsByToken) {
      for (const [chainId, data] of Object.entries(activeListingsByToken)) {
        result[chainId] = {}
        for (const listing of Object.values(data)) {
          const { termRepoLocker, collateralTokensMeta } = listing

          if (!result[chainId][termRepoLocker]) {
            result[chainId][termRepoLocker] = []
          }

          const existingCurrencies = result[chainId][termRepoLocker]
          const existingAddresses = new Set(
            existingCurrencies.map((c) => c.address?.toLowerCase())
          )

          for (const tokenMeta of collateralTokensMeta) {
            const address = tokenMeta?.address?.toLowerCase()
            if (!existingAddresses.has(address)) {
              const currency: Currency = {
                address: tokenMeta.address,
                decimals: Number(tokenMeta.decimals),
                symbol: tokenMeta.symbol,
                isRepoToken: false,
              }
              existingCurrencies.push(currency)
              existingAddresses.add(address)
            }
          }
        }
      }
    }
    return result
  }, [activeListingsByToken])

  const repoLockerBalances = useRepoLockerBalances(
    repoLockerAddressesByChain,
    provider
  )

  const listingAllowancesByChain = useMemo(() => {
    if (!account) return undefined
    const result: { [chainId: string]: TokenAllowance[] } = {}
    chainConfigs.forEach((chainConfig) => {
      result[chainConfig.chainId] = Object.values(
        activeListingsByToken?.[chainConfig.chainId] ?? {}
      ).flatMap((listing) => {
        return {
          token: listing.purchaseToken,
          spender: chainConfig.listingsContractAddress,
          owner: account,
          termId: listing.purchaseToken,
        }
      })
    })
    return result
  }, [account, activeListingsByToken, chainConfigs])

  const allowances = useAllowances(listingAllowancesByChain, provider)

  const purchaseTokenAllowance = useMemo(() => {
    if (!allowances || !activeLendItem) {
      return BigNumber.from(0)
    } else {
      return allowances[activeLendItem.chainId][
        activeLendItem.purchaseToken
      ]?.[0]
    }
  }, [allowances, activeLendItem])

  const purchaseTokenApprove = useTokenApprove(
    signer,
    purchaseTokenCurrency,
    Number(activeLendItem?.chainId)
  )

  const onChangePurchaseToken = useCallback(
    (tokenSymbol: string) => {
      if (mappedCurrencies.hasOwnProperty(tokenSymbol)) {
        setSelectedPurchaseToken(tokenSymbol)
        setActiveLendItem(undefined)
        trackSafaryEvent(
          config?.safary?.earn?.selectAsset?.type ?? 'click',
          'select asset',
          {
            page: 'earn',
            asset: tokenSymbol,
          }
        )
      } else {
        console.error('Invalid token address')
      }
    },
    [
      config?.safary?.earn?.selectAsset?.type,
      mappedCurrencies,
      trackSafaryEvent,
    ]
  )

  const flattenedLendData = useMemo(() => {
    if (
      !totalOutstandingRepurchaseExposures ||
      !repoLockerBalances ||
      !discountRateMarkups ||
      !prices
    ) {
      return undefined
    }

    return activeListingsByToken
      ? Object.entries(activeListingsByToken).flatMap(([chainId, chainData]) =>
          Object.values(chainData).map((listing) => {
            // Fetch totalDebt from totalOutstandingRepurchaseExposures
            const totalDebt =
              totalOutstandingRepurchaseExposures?.[chainId]?.[
                listing.termRepoServicer
              ] ?? FixedNumber.fromString('0')

            const isEarlyRepay =
              totalOutstandingRepurchaseExposures?.[chainId]?.[
                listing.termRepoServicer
              ] &&
              totalOutstandingRepurchaseExposures[chainId][
                listing.termRepoServicer
              ].isZero()

            // Fetch totalCollateral from repoLockerBalance
            const collateralData =
              repoLockerBalances?.[chainId]?.[listing.termRepoLocker]
            const totalCollateral = collateralData
              ? Object.values(collateralData).reduce(
                  (acc, balance) => add(acc, balance.balance),
                  FixedNumber.fromString('0')
                )
              : FixedNumber.from('0')

            // Calculate finalAPY
            const discountRateMarkup = bigToFixedNumber(
              discountRateMarkups?.[chainId] ?? FixedNumber.from('0'),
              18
            )
            const lendApy = subtract(
              listing.lastAuctionClearingPrice,
              discountRateMarkup
            )

            const totalDebtUsd = multiply(
              totalDebt,
              prices?.[chainId]?.[listing.purchaseToken] ??
                FixedNumber.from('0'),
              2
            )
            const totalCollateralUsd = multiply(
              totalCollateral,
              prices?.[chainId]?.[listing.collateralTokens[0]] ??
                FixedNumber.from('0'),
              2
            )

            return {
              ...listing,
              totalDebt,
              totalDebtUsd,
              totalCollateral,
              totalCollateralUsd,
              lendApy,
              discountRateMarkup,
              isEarlyRepay,
            }
          })
        )
      : undefined
  }, [
    activeListingsByToken,
    totalOutstandingRepurchaseExposures,
    repoLockerBalances,
    discountRateMarkups,
    prices,
  ])

  const selectActiveListingRepo = useCallback(
    (listing: ListingsData | undefined) => {
      setActiveLendItem((prev) => {
        // clear lend amount if switching between listings - graph refresh triggers this
        if (prev?.id !== listing?.id) {
          setLendAmount('')
          setIsPurchasingMaxAvailable(false)
        }
        return listing
      })
    },
    [setActiveLendItem]
  )

  const selectedTermRepo: ActiveListingRepo | undefined = useMemo(() => {
    if (!activeLendItem) {
      return undefined
    }

    let lendAmountUsd = FixedNumber.from('0')
    let repoTokensReceived = FixedNumber.from('0')

    if (lendAmount && prices && activeLendItem.purchaseTokenMeta?.address) {
      const price =
        prices[activeLendItem.chainId][activeLendItem.purchaseTokenMeta.address]
      lendAmountUsd = multiply(
        FixedNumber.fromString(
          lendAmount,
          `fixed128x${activeLendItem?.purchaseTokenMeta?.decimals ?? 18}`
        ),
        price
      )
    }

    if (
      activeLendItem?.lastAuctionClearingPrice &&
      lendAmount &&
      discountRateMarkups &&
      currentBlock?.timestamp
    ) {
      repoTokensReceived = calculateRepoTokensReceived(
        FixedNumber.fromString(
          lendAmount,
          activeLendItem?.purchaseTokenMeta?.decimals ?? 18
        ),
        activeLendItem.redemptionTimestamp,
        bigToFixedNumber(discountRateMarkups[activeLendItem.chainId], 18),
        activeLendItem.lastAuctionClearingPrice,
        dayjs(currentBlock.timestamp).unix(),
        activeLendItem?.purchaseTokenMeta?.decimals ?? 18
      )
    }

    if (isPurchasingMaxAvailable) {
      repoTokensReceived = activeLendItem.remainingAmount
    }

    return {
      ...activeLendItem,
      lendAmountRaw: lendAmount,
      lendAmountUsd,
      repoTokensReceived,
    }
  }, [
    isPurchasingMaxAvailable,
    activeLendItem,
    currentBlock.timestamp,
    discountRateMarkups,
    lendAmount,
    prices,
  ])

  // NOTE: the max input here is limited by the repo tokens received NOT the purchase token balance
  const onMax = useCallback(
    (isPurchasingMax: boolean) => {
      setIsPurchasingMaxAvailable(isPurchasingMax)
      if (selectedTermRepo && isPurchasingMax) {
        selectedTermRepo.repoTokensReceived = selectedTermRepo.remainingAmount
      }
    },
    [selectedTermRepo]
  )

  const onPurchaseTokenApprove = useCallback(
    async (approvalAmount: string) => {
      if (!selectedTermRepo) {
        throw new Error('No active listing selected')
      }
      const chainConfig = chainConfigs.find(
        (config) => config.chainId === Number(selectedTermRepo.chainId)
      )
      if (!chainConfig) {
        throw new Error('Chain config not found')
      }

      // check active network
      await onCheckActiveNetwork(chainConfig.chainId, chainConfig.chainName)

      const approvalAmountBN = BigNumber.from(approvalAmount)

      return await purchaseTokenApprove(
        chainConfig.listingsContractAddress,
        approvalAmountBN
      )
    },
    [chainConfigs, onCheckActiveNetwork, purchaseTokenApprove, selectedTermRepo]
  )

  const onLend = useCallback(
    async (purchaseAmount: string) => {
      if (!selectedTermRepo) {
        throw new Error('No active listing selected')
      }
      const chainConfig = chainConfigs.find(
        (config) => config.chainId === Number(selectedTermRepo.chainId)
      )
      if (!chainConfig) {
        throw new Error('Chain config not found')
      }
      if (!selectedTermRepo?.termRepoToken) {
        throw new Error('Term repo token not found')
      }
      if (!selectedTermRepo?.purchaseTokenMeta?.decimals) {
        throw new Error('Purchase token decimals not found')
      }
      // check active network
      await onCheckActiveNetwork(chainConfig.chainId, chainConfig.chainName)

      if (isPurchasingMaxAvailable) {
        console.info(
          'Purchasing all remaining repo tokens using purchase method'
        )
        await purchaseRepoTokens(
          chainConfig.chainId,
          selectedTermRepo.termRepoToken,
          selectedTermRepo.remainingAmount
        )
      } else {
        console.info(
          'Swapping purchase token amount for repo tokens using swap method'
        )
        const purchaseAmountFn = FixedNumber.fromString(
          purchaseAmount,
          selectedTermRepo.purchaseTokenMeta.decimals
        )
        await swapPurchaseTokensForRepoTokens(
          chainConfig.chainId,
          selectedTermRepo.termRepoToken,
          purchaseAmountFn
        )
      }
    },
    [
      selectedTermRepo,
      chainConfigs,
      isPurchasingMaxAvailable,
      onCheckActiveNetwork,
      purchaseRepoTokens,
      swapPurchaseTokensForRepoTokens,
    ]
  )

  const onAddTermToken = useCallback(
    async (
      chainId: string,
      address: string,
      symbol: string,
      decimals: number
    ) => {
      if (!provider) {
        return false
      }

      const chainConfig = chainConfigs.find(
        (config) => config.chainId === Number(chainId)
      )

      if (!chainConfig) {
        return false
      }

      await onCheckActiveNetwork(chainConfig.chainId, chainConfig.chainName)

      return await watchAsset(
        chainId,
        provider as JsonRpcProvider,
        address,
        symbol,
        decimals
      )
    },
    [chainConfigs, onCheckActiveNetwork, provider]
  )

  const params = useMemo(
    () =>
      ({
        account,
        availableCurrencies: mappedCurrencies,
        selectedPurchaseToken,
        purchaseTokenAllowance,
        purchaseTokenBalance,
        selectedTermRepo,
        listingRepos: flattenedLendData,
        isPurchasingMaxAvailable,
        onSelectActiveListingRepo: selectActiveListingRepo,
        onPurchaseTokenApprove,
        onLend,
        onChangeLendAmount: (amount: string) => {
          setLendAmount(amount)
        },
        onChangePurchaseToken,
        onConnect,
        onKytCheck,
        onMax,
        onAddTermToken,
      }) as LendPageParams,
    [
      account,
      mappedCurrencies,
      selectedPurchaseToken,
      purchaseTokenAllowance,
      purchaseTokenBalance,
      selectedTermRepo,
      flattenedLendData,
      isPurchasingMaxAvailable,
      selectActiveListingRepo,
      onPurchaseTokenApprove,
      onChangePurchaseToken,
      onLend,
      onConnect,
      onKytCheck,
      onMax,
      onAddTermToken,
    ]
  )

  return params
}
