import {
  Modal,
  Text,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Link,
  TextProps,
  ModalHeaderProps,
  Spinner,
  Box,
  Select,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import InfoBox from '../InfoBox'
import TestNetTokensOverview from './TestNetTokensOverview'
import { FixedNumber } from 'ethers'
import { Address, Balance } from '../../../data/model'
import { useTermToast } from '../../../hooks/toasts'
import { isRejectedTxn, termToastMessages } from '../../../helpers/toasts'
import { useStorage } from '../../../providers/storage'
import { captureException } from '@sentry/react'
import { useConfig } from '../../../providers/config'
import { ChainId, useEthers } from '@usedapp/core'

const FaucetHeading = ({
  children,
  fontWeight = '700',
  fontSize = '16px',
  lineHeight = '120%',
  color = 'blue.900',
  ...props
}: TextProps) => {
  return (
    <Text
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color}
      {...props}
    >
      {children}
    </Text>
  )
}

const FaucetSubHeading = ({
  children,
  fontWeight = '400',
  fontSize = '14px',
  lineHeight = '20px',
  color = 'gray.500',
  ...props
}: TextProps) => {
  return (
    <Text
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color}
      {...props}
    >
      {children}
    </Text>
  )
}

const FaucetHeader = ({
  children,
  pt = '28px',
  px = '28px',
  ...props
}: ModalHeaderProps) => {
  return (
    <ModalHeader pt={pt} px={px} {...props}>
      {children}
      <ModalCloseButton size="sm" mr="12px" mt="16px" color="blue.900" />
    </ModalHeader>
  )
}

export const alreadyClaimedStorageKey = 'hasClaimedTestnetTokens'
export const alreadyClaimedStorageValue = 'true'
const FaucetModal = ({
  isOpen,
  onClose,
  onClaimTestnetTokens,
  onImportTestnetToken,
  testnetTokenBalances,
  testnetTokenTicketsRemaining,
  testnetTokenClaimsMade,
  mintingAmounts,
  onSwitchNetwork,
}: {
  isOpen: boolean
  onClose: () => void
  onClaimTestnetTokens?: (
    chainId: number,
    tokenAddress: Address
  ) => Promise<void>
  onImportTestnetToken?: (
    chainId: string,
    address: string,
    symbol: string,
    decimals: number
  ) => Promise<boolean>
  onSwitchNetwork?: (chainId: ChainId) => Promise<boolean>
  testnetTokenBalances?: Balance[]
  testnetTokenTicketsRemaining?: number[]
  testnetTokenClaimsMade?: number[]
  mintingAmounts?: { [symbol: string]: FixedNumber }
}) => {
  const config = useConfig()

  const { library: provider } = useEthers()

  const activeChainId = provider?.network && provider.network.chainId
  const termToast = useTermToast()

  const { storage } = useStorage()
  const [isClaiming, setIsClaiming] = useState(false)

  const [selectedTokens, setSelectedTokens] = useState<Set<Address>>(
    new Set(testnetTokenBalances?.map((token) => token.address))
  )

  const toggleTokenSelection = (tokenAddress?: Address) => {
    // If no specific token address is provided, select/deselect all
    if (!tokenAddress) {
      if (selectedTokens.size === testnetTokenBalances?.length) {
        setSelectedTokens(new Set()) // Deselect all
      } else {
        setSelectedTokens(
          new Set(testnetTokenBalances?.map((token) => token.address))
        ) // Select all
      }
    } else {
      const newSelectedTokens = new Set(selectedTokens)
      if (newSelectedTokens.has(tokenAddress)) {
        newSelectedTokens.delete(tokenAddress)
      } else {
        newSelectedTokens.add(tokenAddress)
      }
      setSelectedTokens(newSelectedTokens)
    }
  }

  const claim = useCallback(async () => {
    if (onClaimTestnetTokens && testnetTokenBalances) {
      termToast.pending(termToastMessages.faucetClaim.pending)
      setIsClaiming(true)
      try {
        for (const [index, balance] of testnetTokenBalances.entries()) {
          if (!selectedTokens.has(balance.address)) continue

          if (
            activeChainId === ChainId.Sepolia &&
            testnetTokenClaimsMade &&
            testnetTokenClaimsMade[index] >= config.allowedTestnetTokenClaims
          ) {
            termToast.warning(termToastMessages.faucetClaim.exceededLimit)
            continue
          }

          if (
            activeChainId === ChainId.Mumbai &&
            testnetTokenTicketsRemaining &&
            testnetTokenTicketsRemaining[index] === 0
          ) {
            termToast.warning(termToastMessages.faucetClaim.exceededLimit)
            continue
          }

          try {
            if (activeChainId) {
              await onClaimTestnetTokens(activeChainId, balance.address)
              termToast.success(
                termToastMessages.faucetClaim.success(balance.symbol)
              )
            } else {
              console.error('No active chain id: %o', activeChainId)
            }
          } catch (error) {
            console.error('Error claiming tokens: %o', error)
            if (isRejectedTxn(error)) {
              termToast.dismissed()
            } else {
              termToast.failure(
                termToastMessages.faucetClaim.failure(balance.symbol)
              )
              captureException(error)
            }
          }
        }
        storage.setItem(alreadyClaimedStorageKey, alreadyClaimedStorageValue)
      } finally {
        setIsClaiming(false)
      }
    }
  }, [
    activeChainId,
    config.allowedTestnetTokenClaims,
    onClaimTestnetTokens,
    storage,
    termToast,
    testnetTokenBalances,
    testnetTokenClaimsMade,
    testnetTokenTicketsRemaining,
    selectedTokens,
  ])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <FaucetHeader>
          <FaucetHeading>Claim Testnet Tokens</FaucetHeading>
          <FaucetSubHeading>Faucet</FaucetSubHeading>
          <Box display="inline-block">
            <Select
              variant="unstyled"
              size="sm"
              value={activeChainId}
              onChange={(e) =>
                !!onSwitchNetwork &&
                onSwitchNetwork(
                  Number(e.target.value) as ChainId.Mumbai | ChainId.Sepolia
                )
              }
              // [Dospore] Only way I could think of to remove the whitespace between icon and name since Select sets width to largest option content
              maxWidth={activeChainId === ChainId.Mumbai ? '85px' : undefined}
            >
              <option value={ChainId.Mumbai}>Mumbai</option>
              <option value={ChainId.Sepolia}>Sepolia</option>
            </Select>
          </Box>
        </FaucetHeader>
        <ModalBody px="28px">
          <Text>
            Claim the test tokens below so that you can participate in the
            upcoming auctions.
          </Text>
          <br />
          <TestNetTokensOverview
            testnetTokenBalances={testnetTokenBalances}
            mintingAmounts={mintingAmounts}
            onImportTestnetToken={onImportTestnetToken}
            selectedTokens={selectedTokens}
            onTokenSelectionToggle={toggleTokenSelection}
            activeChainId={activeChainId}
          />
          <br />
          {activeChainId === ChainId.Sepolia ? (
            <InfoBox kind="info">
              You will need Sepolia ETH to claim the tokens. If you haven't
              already, visit the{' '}
              <Link href="https://faucetlink.to/sepolia" isExternal>
                Network Faucet
              </Link>{' '}
              to get Sepolia ETH. You can also claim on Polygon Mumbai.
            </InfoBox>
          ) : (
            <InfoBox kind="info">
              You will need MATIC to claim the tokens. If you haven't already,
              visit the{' '}
              <Link href="https://mumbaifaucet.com/" isExternal>
                Mumbai Faucet
              </Link>{' '}
              to get MATIC. You can also claim tokens on Sepolia.
            </InfoBox>
          )}
          <br />
          <Button
            leftIcon={isClaiming ? <Spinner size="xs" /> : undefined}
            variant="primary"
            size="md"
            height="40px"
            width="452px"
            borderRadius="md"
            onClick={() => {
              claim()
            }}
            justifyContent="center"
            fontWeight="600"
            textColor="white"
            isDisabled={isClaiming || selectedTokens.size === 0}
          >
            Claim test tokens
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default FaucetModal
