import { Box, Checkbox, Image, Text } from '@chakra-ui/react'
import { useRef } from 'react'
import settings_icon from '../../../../assets/icons/settings.svg'
import { useOutsideClick } from '../../../../data/hooks/helper-hooks'

export default function HistoricalDropdown({
  isOpen,
  isSelected,
  labelText,
  onOpen,
  onClose,
  onChange,
}: {
  isOpen: boolean
  isSelected: boolean
  labelText: string
  onOpen: () => void
  onClose: () => void
  onChange: (isSelected: boolean) => void
}) {
  const containerRef = useRef<HTMLDivElement>(null)
  useOutsideClick(containerRef, onClose)

  return (
    <Box ref={containerRef} position="relative" zIndex={10}>
      <Box onClick={isOpen ? onClose : onOpen} cursor="pointer">
        <Image src={settings_icon} w="18px" h="18px" />
      </Box>
      <Box
        opacity={isOpen ? 1 : 0}
        pointerEvents={isOpen ? 'all' : 'none'}
        transform={isOpen ? 'scale(1.0)' : 'scale(0.95)'}
        transition="all 0.2s ease"
        position="absolute"
        top="calc(100% + 10px)"
        right={0}
        borderRadius="6px"
        borderWidth="1px"
        borderColor="gray.2"
        bg="white"
        px={3}
        py={2.5}
      >
        <Checkbox
          isChecked={isSelected}
          onChange={(e) => {
            onClose()
            onChange(e.target.checked)
          }}
          spacing={2}
        >
          <Text
            as="span"
            variant="body-sm/medium"
            color="blue.9"
            whiteSpace="nowrap"
          >
            {labelText}
          </Text>
        </Checkbox>
      </Box>
    </Box>
  )
}
