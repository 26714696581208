import {
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import ContractAddressRow from './ContractAddressRow'
import { Address } from '../../../data/model'
import { socialLinks } from '../../../socials'
import { ChainId } from '@usedapp/core'

export default function ContractAddressesDropdown({
  chainId,
  auction,
  bidLocker,
  offerLocker,
  repoLocker,
  repoServicer,
  collateralManager,
  rolloverManager,
  termRepoToken,
}: {
  chainId: ChainId
  auction: Address
  bidLocker: Address
  offerLocker: Address
  repoLocker: Address
  repoServicer: Address
  collateralManager: Address
  rolloverManager: Address
  termRepoToken: Address
}) {
  return (
    <Menu
      computePositionOnMount={false}
      placement="bottom-end"
      strategy="fixed"
      isLazy
    >
      {({ isOpen }) => (
        <>
          <MenuButton as={Button} variant="link" px="0" display="inline">
            Contract Addresses{' '}
            {isOpen ? (
              <ChevronUpIcon color="gray.6" />
            ) : (
              <ChevronDownIcon color="gray.6" />
            )}
          </MenuButton>
          <MenuList minWidth={7} pb={0}>
            <ContractAddressRow
              title="Auction"
              chainId={chainId}
              address={auction}
            />
            <ContractAddressRow
              title="Bid Locker"
              chainId={chainId}
              address={bidLocker}
            />
            <ContractAddressRow
              title="Offer Locker"
              chainId={chainId}
              address={offerLocker}
            />
            <ContractAddressRow
              title="Repo Locker"
              chainId={chainId}
              address={repoLocker}
            />
            <ContractAddressRow
              title="Repo Servicer"
              chainId={chainId}
              address={repoServicer}
            />
            <ContractAddressRow
              title="Collateral Mgr"
              chainId={chainId}
              address={collateralManager}
            />
            <ContractAddressRow
              title="Rollover Mgr"
              chainId={chainId}
              address={rolloverManager}
            />
            <ContractAddressRow
              title="Repo Token"
              chainId={chainId}
              address={termRepoToken}
            />
            <MenuItem w="192px" bg="blue.0" px={0}>
              <Flex w="fit-content" flexWrap="wrap">
                <Text color="blue.9" align="center" variant="body-xs/normal">
                  Find contract details in the{' '}
                  <Link
                    href={socialLinks.deployedContracts}
                    isExternal
                    color="blue.5"
                    textDecor="none"
                  >
                    documentation
                  </Link>
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
