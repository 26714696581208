import { Flex, HStack, MenuItem, Text, VStack } from '@chakra-ui/react'
import NetworkIcon from '../NetworkIcon'
import TokenIcon from '../TokenIcon'
import TokenIconMultiple from '../TokenIconMultiple'

export default function AssetDropdownItem({
  purchaseToken,
  chainIds,
  isActive = false,
  showAllOption,
  symbols,
  handleClick,
}: {
  purchaseToken: string
  chainIds: number[]
  isActive?: boolean
  showAllOption?: boolean
  symbols?: string[]
  handleClick: (token: string) => void
}) {
  return (
    <MenuItem
      height="72px"
      px="4px"
      bg={isActive ? 'blue.0' : 'white'}
      _hover={{
        bg: 'gray.0',
      }}
      _active={{
        bg: 'blue.0',
      }}
      borderRadius="6px"
      onClick={() => handleClick(purchaseToken)}
    >
      <HStack w="full" justifyContent="space-between">
        <HStack>
          {showAllOption && symbols ? (
            <TokenIconMultiple symbols={symbols} boxSize={22} pr={2} />
          ) : (
            <TokenIcon
              boxSize="33px"
              key={purchaseToken}
              token={purchaseToken}
            />
          )}
          <VStack align="start" spacing={0} ml="5px" minW="75px">
            <Text variant="body-xl/bold" lineHeight={7}>
              {showAllOption ? 'All' : purchaseToken}
            </Text>
            <Text
              variant="body-sm/normal"
              lineHeight={5}
              color="gray.4"
              wordBreak="keep-all"
            >
              {showAllOption ? 'All assets' : purchaseToken}
            </Text>
          </VStack>
        </HStack>
        <HStack>
          <Flex align="center" justify="center" mb={'0.25rem!important'}>
            {chainIds.map((chainId) => (
              <NetworkIcon
                key={chainId}
                chainId={chainId}
                boxSize={'20px'}
                variant="faded"
              />
            ))}
          </Flex>
        </HStack>
      </HStack>
    </MenuItem>
  )
}
