import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Divider, Skeleton, Text } from '@chakra-ui/react'
import { HStack, VStack, Stack } from '../../../../components/elements/Stack'
import { ReactNode } from 'react'
import {
  CONTAINER_PADDING,
  MOBILE_CONTAINER_PADDING,
} from '../../../../helpers/constants'
import { Trans } from '@lingui/macro'

const OpenAuctionStatisticsLoading = () => {
  return (
    <HStack
      position="relative"
      py="14px"
      px={{
        base: MOBILE_CONTAINER_PADDING,
        xl: CONTAINER_PADDING,
      }}
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      spacing="84px"
    >
      <Divider
        borderColor="gray.2"
        pointerEvents="none"
        margin={0}
        position="absolute"
        bottom={0}
        left="50%"
        transform="translateX(-50%)"
        w={{
          base: 'calc(100% - 40px)',
          xl: 'calc(100% - 160px)',
        }}
      />
      <VStack spacing={0} alignItems="start" ml="8px">
        <HStack whiteSpace="nowrap" spacing={1} height={6} alignItems="center">
          <Text as="span" variant="body-xs/medium" color="blue.9">
            <Trans>All auctions</Trans>
          </Text>
          <ChevronRightIcon color="gray.6" />
          <Skeleton h={5} w="120px" />
        </HStack>
        <Text
          as="h2"
          variant={{
            base: 'body-md/bold',
            xl: 'body-xl/bold',
          }}
          color="blue.9"
        >
          <Skeleton h={10} w="240px" />
        </Text>
      </VStack>
      <Stack spacing={1}>
        <Text
          color="gray.6"
          whiteSpace="nowrap"
          variant="body-xs/normal"
          mb="0"
        >
          <Trans>Closes in</Trans>
        </Text>
        <Skeleton h={5} w="95px" />
      </Stack>
      <AuctionStatLoading name="Term" />
      <AuctionStatLoading name="Maturity" />
      <AuctionStatLoading name="Supply / Borrow" />
      <AuctionStatLoading name="Collateral" />
      <AuctionStatLoading
        name={
          <>
            <Box as="span" display={{ base: 'none', xl: 'inline' }}>
              Collateral Ratio (Initial • Maintenance)
            </Box>
            <Box as="span" display={{ base: 'inline', xl: 'none' }}>
              <Trans>Collateral Ratio (Init. • Main.)</Trans>
            </Box>
          </>
        }
      />
    </HStack>
  )
}

export default OpenAuctionStatisticsLoading

function AuctionStatLoading({ name }: { name: string | ReactNode }) {
  return (
    <VStack spacing={1} alignItems="start">
      <Text as="h2" color="gray.6" variant="body-xs/normal">
        {name}
      </Text>
      <Skeleton w="95px" h={5} />
    </VStack>
  )
}
