import { Link, Text, TextProps } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'

const TransactionHash = ({
  getBlockExplorerTransactionUrl,
  address,
  headChars = 4,
  tailChars = 2,
  variant = 'body-xs/normal',
  iconColor = 'gray.3',
  ...props
}: {
  getBlockExplorerTransactionUrl?: (address: string) => string
  address: string | undefined
  headChars?: number
  tailChars?: number
  iconColor?: string
} & Omit<TextProps, 'children'>) => {
  const head = useMemo(() => address?.slice(0, headChars), [address, headChars])
  const tail = useMemo(() => address?.slice(-tailChars), [address, tailChars])
  return (
    <Text variant={variant} {...props}>
      {address ? `${head}...${tail}` : '-'}
      {address && (
        <Link
          href={
            getBlockExplorerTransactionUrl
              ? getBlockExplorerTransactionUrl(address)
              : '#'
          }
          isExternal
          color={iconColor}
          ml="8px"
        >
          <FontAwesomeIcon icon={['far', 'arrow-up-right']} />
        </Link>
      )}
    </Text>
  )
}

export default TransactionHash
