import React from 'react'
import { WalletNetPosition } from './NetPosition'
import SummaryItem from './SummaryItem'
import { HStack } from '@chakra-ui/layout'
import { formatNumber } from '../../utils'
import ProfileButton from './ProfileButton'
import { FixedNumber } from 'ethers'
import { add, fixedCompare } from '../../../../helpers/math'

interface RewardsSummaryProps {
  walletPositions: WalletNetPosition[]
  hasProfile: boolean
  isLoggedIn: boolean
  profileName?: string
  isLoading?: boolean
  onSignUp?: () => void
  onSignIn?: () => void
}

const RewardsSummary: React.FC<RewardsSummaryProps> = ({
  walletPositions,
  hasProfile,
  isLoggedIn,
  profileName,
  isLoading,
  onSignUp,
  onSignIn,
}) => {
  const zero = FixedNumber.fromString('0')
  const walletPositionsTotal = walletPositions.reduce(
    (total: FixedNumber, wallet) => add(total, wallet.position),
    zero
  )

  return (
    <HStack gap={6}>
      <SummaryItem
        top={'Claimable'}
        link={'/'} // TODO claim link
        isDash={true}
        bottom={`—`}
        isLoading={isLoading}
      />
      <SummaryItem
        top={'Net Position'}
        link={'/portfolio'}
        isDash={
          walletPositionsTotal && fixedCompare(walletPositionsTotal, 'gt', zero)
            ? false
            : true
        }
        bottom={
          walletPositionsTotal && fixedCompare(walletPositionsTotal, 'gt', zero)
            ? `$${formatNumber(walletPositionsTotal.toUnsafeFloat(), true)}`
            : '—'
        }
        isLoading={isLoading}
      />
      {/*
        Note: [DOSPORE] wasnt sure if I should remove
        <NetPosition walletPositions={walletPositions} walletPositionsTotal={walletPositionsTotal} connectedWallet={connectedWallet} />
      */}
      {/*<ExternalRewards rewards={externalRewards} />*/}
      <ProfileButton
        hasProfile={hasProfile}
        isLoggedIn={isLoggedIn}
        profileName={profileName}
        isLoading={isLoading}
        onSignUp={onSignUp}
        onSignIn={onSignIn}
      />
    </HStack>
  )
}

export default RewardsSummary
