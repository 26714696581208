import { TransactionState, TransactionStatus } from '@usedapp/core'
import { BaseContract } from 'ethers'
import { fetchBlockNumber } from '../data/hooks/helper-hooks'

export const wait = async (milliSeconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliSeconds))

export const waitFor = async (
  fn: () => Promise<boolean>,
  retries: number,
  retryIntervalMs: number,
  suppressError: boolean = false
) => {
  for (let i = 0; i < retries; i++) {
    if (await fn()) {
      return
    }
    await new Promise((resolve) => setTimeout(resolve, retryIntervalMs))
  }
  if (!suppressError) {
    throw new Error('Timeout')
  }
}

export const waitForStatus = async (
  fn: () => Promise<TransactionStatus | undefined>,
  resetState: () => void,
  acceptedStatuses: TransactionState[],
  retries: number,
  retryIntervalMs: number,
  abi?: BaseContract
) => {
  let status: TransactionStatus | undefined
  await waitFor(
    async () => {
      status = await fn()
      console.log('Got status: ', status?.status)
      return acceptedStatuses.includes(status?.status ?? 'None')
    },
    retries,
    retryIntervalMs
  )

  // try to decode contract error using hash returned by usedapp
  const decodeContractError = (status: TransactionStatus) => {
    try {
      // @ts-ignore - usedapp incorrectly types errorHash as string
      const errorHash = status.errorHash?.data
      const errorMsg = abi?.interface.getError(errorHash) ?? undefined
      console.error(
        `Contract Request failed with: ${JSON.stringify(errorMsg?.name)}`
      )
    } catch (e) {
      console.error('Error decoding contract error: ', e)
    }
  }

  if (status?.status !== 'Success') {
    console.error(status)
    switch (status?.status) {
      case 'Mining':
        throw new Error('Transaction is still mining')
      case 'None':
        throw new Error('Transaction was not sent')
      case 'Fail':
        throw new Error(
          `Transaction failed during mining (${status?.errorCode}): ${status?.errorMessage}`
        )
      case 'Exception':
        abi &&
          !status?.errorMessage?.includes('user rejected transaction') &&
          decodeContractError(status)
        throw new Error(
          `Transaction failed during simulation (${status?.errorCode}): ${status?.errorMessage}`
        )
      case 'PendingSignature':
        throw new Error('Transaction is waiting for signature')
      case 'CollectingSignaturePool':
        throw new Error('Transaction is waiting for signature pool')
      default:
        throw new Error('Unknown transaction status')
    }
  }

  resetState()
}

export const waitForSubgraphIndexing = async (
  chainId: string,
  txBlockNumber: number,
  retries: number,
  retryIntervalMs: number
) => {
  await waitFor(
    async () => {
      const result = await fetchBlockNumber(chainId)
      console.log(
        `Subgraph block: ${result.blockNumber}, tx block: ${txBlockNumber}`
      )
      // check the subgraph block number is >= the block number of the tx
      if (result.blockNumber >= txBlockNumber && !result.hasError) {
        return true
      } else {
        return false
      }
    },
    retries,
    retryIntervalMs,
    true
  )
}
