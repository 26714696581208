import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { QueryParams, useCall } from '@usedapp/core'
import { Contract, BigNumber } from 'ethers'
import WrappableABI from '../../abi-external/Wrappable.json'
import { Currency } from '../model'
import { useMemo } from 'react'

export function useConvertedRebasingTokenBalance(
  wrappedCollateralCurrency: Currency | undefined,
  nakedCollateralTokenBalance: BigNumber | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  queryParams: QueryParams = {}
): BigNumber | undefined {
  const method = useMemo(() => {
    // only wired up for STBT now
    switch (wrappedCollateralCurrency?.symbol) {
      case 'STBT':
      default:
        return 'getWstbtByStbt'
    }
  }, [wrappedCollateralCurrency])

  const { value: rebasedBalance } =
    useCall(
      wrappedCollateralCurrency &&
        nakedCollateralTokenBalance && {
          contract: new Contract(
            wrappedCollateralCurrency.address,
            WrappableABI,
            provider
          ),
          method: method,
          args: [nakedCollateralTokenBalance],
        },
      queryParams
    ) ?? {}

  return useMemo(() => {
    if (rebasedBalance) {
      // handling rounding errors
      return BigNumber.from(rebasedBalance[0]).sub(BigNumber.from('2'))
    } else {
      return undefined
    }
  }, [rebasedBalance])
}
