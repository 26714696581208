import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import Tooltip from '../../../../components/elements/Tooltip'
import { PointsCardProps } from '../../utils'
import PointsCardTag from '../PointsCardTag'
import ProgressLine from '../ProgressLine'
import PointsCardStatus from './PointsCardStatus'

export default function PointsCard({
  complete = false,
  category,
  points,
  status,
  indicatorTotalSteps,
  indicatorCurrentStep,
  indicatorText,
  title,
  requiresProfile,
  buttonText,
  startedTotal,
  startedValue,
  multiplier,
  isDisabled,
  buttonAction,
  tooltipProps,
  hasProfile,
  isSignedIn,
}: PointsCardProps & {
  hasProfile: boolean
  isSignedIn: boolean
}) {
  const isEarlySupportedWithZeroPoints =
    title.toLowerCase().includes('public beta') && points === '0 points'

  const isShowGrayBg = (hasProfile && !isSignedIn) || !hasProfile

  const buttonProps = complete
    ? {
        color: 'gray.5',
        bg: 'gray.2',
        cursor: 'default',
        pointerEvents: 'none',
        disabled: true,
        text: 'Completed',
      }
    : !!requiresProfile && isShowGrayBg
      ? {
          color: 'blue.5',
          bg: 'white',
          _hover: { bg: 'blue.1' },
          text: buttonText,
          borderColor: 'blue.5',
        }
      : {
          color: 'blue.8',
          bg: 'blue.2',
          _hover: { bg: '#86BCFA' },
          text: buttonText,
        }

  const containerProps = complete
    ? {
        opacity: 0.6,
        bg: 'white',
        cursor: 'default',
      }
    : {
        bg: '#FBFCFE',
        cursor: 'pointer',
      }

  const isCardDisabled = isDisabled || complete || buttonProps.disabled

  const card = (
    <Flex
      direction="column"
      align="start"
      justify="space-between"
      minW="221px"
      height="259px"
      borderRadius="6px"
      padding={3}
      _hover={{ bg: 'white' }}
      {...containerProps}
      onClick={!complete ? buttonAction : undefined}
    >
      <VStack
        spacing={status === 'started' ? 2.5 : 4}
        justify="space-between"
        align="start"
        w="full"
      >
        <PointsCardTag variant={category} points={points || ''} />
        {indicatorTotalSteps && !complete ? (
          <ProgressLine
            variant="solid"
            totalSteps={indicatorTotalSteps}
            currentStep={indicatorCurrentStep}
            label={indicatorText || ''}
          />
        ) : null}
        {status ? (
          <PointsCardStatus
            variant={complete ? 'complete' : status}
            multiplier={multiplier}
            startedTotal={startedTotal}
            startedValue={startedValue}
          />
        ) : null}
        <Text variant="body-l/medium" color="blue.9" pr={1}>
          {title}
        </Text>
      </VStack>
      <Button
        as="a"
        onClick={() => {
          if (buttonAction) {
            buttonAction()
          }
        }}
        color={buttonProps.color}
        bg={buttonProps.bg}
        cursor={buttonProps.cursor}
        isDisabled={isCardDisabled}
        borderColor={buttonProps?.borderColor}
        borderWidth={buttonProps?.borderColor ? '1px' : '0'}
        h="24px"
        borderRadius="6px"
        _hover={buttonProps._hover}
      >
        <Text as="span" variant="body-xs/semibold">
          {buttonProps.text}
        </Text>
      </Button>
    </Flex>
  )

  if (tooltipProps) {
    return (
      <Tooltip noDelay {...tooltipProps}>
        {card}
      </Tooltip>
    )
  }

  return !isEarlySupportedWithZeroPoints ? card : null
}
