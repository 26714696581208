import { Flex, Skeleton, Text, VStack } from '@chakra-ui/react'
import { rgba } from 'polished'
import { useEffect, useRef } from 'react'
import Tooltip from '../../../../components/elements/Tooltip'
import { formatNumber } from '../../utils'
import HighlightedText from './HighlightedText'

interface EarningsCategoryProps {
  category: string
  value: number
  totalPoints: number
  borderColor: string
  minColWidth?: number
  handleColumnWidth?: (width: number) => void
  isLoading?: boolean
}

/**
 * Renders an earnings category component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.category - The category of earnings.
 * @param {string} props.value - The value of earnings.
 * @param {string} props.borderBottomColor - The color of the bottom border.
 * @returns {JSX.Element} The rendered component.
 */
export const EarningsCategory = ({
  category,
  value,
  totalPoints,
  borderColor,
  minColWidth,
  handleColumnWidth,
  isLoading,
}: EarningsCategoryProps) => {
  const percentageOfTotal = ((value / totalPoints) * 100).toFixed(2)
  const displayCategory = category === 'Invites' ? 'Invite' : category
  const formattedValue =
    value || value === 0 ? formatNumber(value, true, 1) : '-'
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const width = ref.current?.clientWidth || 0
    if (handleColumnWidth && width > (minColWidth ?? 0)) {
      handleColumnWidth(width)
    }
  }, [minColWidth])

  return (
    <Tooltip
      className="earn-points-breakdown-tooltip"
      aria-label="earn-points-breakdown-tooltip"
      bg="blue.900"
      placement="bottom-start"
      offset={[0, 39]}
      p={0}
      display={undefined}
      width="max-content"
      noDelay
      label={
        <VStack
          align="start"
          borderLeft="4px solid"
          borderLeftColor={borderColor}
          spacing={3.5}
          py={0.5}
          px={3}
          ml={1}
        >
          <Text
            variant="body-sm/medium"
            color="rgba(255, 255, 255, 0.7)"
            lineHeight={1}
          >
            {displayCategory}
          </Text>
          <Text variant="body-xl/medium" lineHeight={1}>
            {parseFloat(value.toFixed(2)).toLocaleString()}
          </Text>
          <Text variant="body-xs/medium" color="rgba(255, 255, 255, 0.7)">
            <Text
              as="span"
              color={borderColor}
              bg={rgba(borderColor, 0.1)}
              borderRadius="6px"
              p={1}
            >
              {percentageOfTotal}%
            </Text>{' '}
            of total
          </Text>
        </VStack>
      }
    >
      <Flex
        ref={ref}
        flexDirection="column"
        alignItems="left"
        justifyContent="space-between"
        opacity={undefined}
        display="inline-flex"
        width="max-content"
      >
        <Text variant="body-xs/medium" color="white">
          {displayCategory}
        </Text>
        <HighlightedText
          borderBottomColor={borderColor}
          minColWidth={minColWidth}
        >
          <Skeleton minHeight="32px" minWidth="74px" isLoaded={!isLoading}>
            {formattedValue}
          </Skeleton>
        </HighlightedText>
      </Flex>
    </Tooltip>
  )
}

export default EarningsCategory
