import { Box } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import SummaryFormulaTerm from '../SummaryFormulaTerm'
import SummaryFormulaOperator from '../SummaryFormulaOperator'
import OpenPositionsTile from '../SummarySection/OpenPositionsTile'
import { t } from '@lingui/macro'

const zeroBN = FixedNumber.from(0)

export default function SummaryHeaderLoading() {
  return (
    <Box
      p={{
        base: '8px',
        xl: '24px',
      }}
      display="flex"
      borderRadius="md"
      w="full"
      bg="white"
      position="relative"
      overflow="hidden"
    >
      <HStack
        spacing={4}
        w="full"
        justifyContent="space-between"
        position="relative"
        zIndex={1}
      >
        <OpenPositionsTile
          netPositionUsd={zeroBN}
          openPositions={0}
          openPositionNetworks={[]}
        />
        <SummaryFormulaOperator isDark={false}>=</SummaryFormulaOperator>
        <HStack spacing={4}>
          <SummaryFormulaTerm
            isDataLoading
            label={t`Total Lending`}
            usd={zeroBN}
            itemCount={0}
            itemText="loans"
            hasAddSign
            isIconColored
          />
          <SummaryFormulaTerm
            isDataLoading
            label={t`Interest Receivable`}
            usd={zeroBN}
            hasAddSign
            isIconColored
          />
        </HStack>
        <HStack spacing={4}>
          <SummaryFormulaTerm
            isDataLoading
            label={t`Total Borrowing`}
            usd={zeroBN}
            itemCount={0}
            itemText={t`borrows`}
            isIconColored
          />
          <SummaryFormulaTerm
            isDataLoading
            label={t`Interest Payable`}
            usd={zeroBN}
            isIconColored
          />
        </HStack>
      </HStack>
    </Box>
  )
}
