import {
  Box,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TableCellProps,
  TableContainer,
  Text,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import {
  Table,
  TableBody as Tbody,
  Td,
  TableFoot as Tfoot,
  Th,
  TableHead as Thead,
  Tr,
} from '../../../../components/elements/Table'
import { Currency, ListingsTransaction } from '../../../../data/model'
import { useChainConfig } from '../../../../providers/config'
import { FixedNumber } from 'ethers'
import { formatFixedToken } from '../../../../helpers/utils'
import Tooltip from '../../../../components/elements/Tooltip'

const TABLE_HEADERS = [
  { column: 'timestamp', label: 'Date' },
  { column: 'listingAmount', label: 'Listed' },
  { column: 'remainingAmount', label: 'Remaining' },
  { column: 'soldCostPurchaseCurrency', label: 'Proceeds' },
]

const TableCell = ({
  children,
  variant = 'body-sm/normal',
  ...props
}: TableCellProps & {
  children: React.ReactNode
  variant?: string
}) => (
  <Td w="142px" py="0" {...props}>
    <Text variant={variant} color="blue.9">
      {children}
    </Text>
  </Td>
)

const TableHeaderCell = ({
  column,
  sortColumn,
  sortDirection,
  children,
  handleClick,
}: {
  column: string
  sortColumn?: string | null
  sortDirection?: boolean
  children: React.ReactNode
  handleClick?: (column: any) => void
}) => (
  <Th
    onClick={() => handleClick && handleClick(column)}
    cursor="pointer"
    textAlign="right"
  >
    <HStack w="full" justify={column !== 'timestamp' ? 'right' : undefined}>
      <Text
        variant="body-sm/semibold"
        color={sortColumn === column ? 'blue.9' : 'gray.6'}
      >
        {children}
      </Text>
      <Box
        color={sortColumn === column ? 'gray.6' : 'gray.4'}
        as="span"
        transform={
          sortColumn === column
            ? sortDirection
              ? 'rotate(180deg)'
              : 'rotate(0deg)'
            : undefined
        }
        transition="transform 0.2s"
      >
        <FontAwesomeIcon
          icon={['far', 'chevron-down']}
          role="button"
          width="10px"
        />
      </Box>
    </HStack>
  </Th>
)

export default function ViewListingsModal({
  isOpen,
  onClose,
  chainId,
  listingPurchaseCurrency,
  listingTransactions,
  totalListed,
  totalOpenListings,
  totalProceedsPurchaseCurrency,
}: {
  isOpen: boolean
  onClose: () => void
  chainId: string
  listingPurchaseCurrency: Currency
  listingTransactions: ListingsTransaction[]
  totalListed: FixedNumber
  totalOpenListings: FixedNumber
  totalProceedsPurchaseCurrency: FixedNumber
}) {
  const [sortColumn, setSortColumn] =
    useState<keyof ListingsTransaction>('timestamp')
  const [sortDirection, setSortDirection] = useState<boolean>(true) // true for ascending, false for descending

  const chainConfig = useChainConfig(chainId)

  const sortedTableData = useMemo(() => {
    if (!sortColumn) {
      return listingTransactions
    }

    const sortedData = listingTransactions.sort((a, b) => {
      let aValue: number
      let bValue: number

      if (
        sortColumn === 'listingAmount' ||
        sortColumn === 'remainingAmount' ||
        sortColumn === 'soldCostPurchaseCurrency'
      ) {
        aValue = parseFloat(a[sortColumn].toString())
        bValue = parseFloat(b[sortColumn].toString())
      } else {
        aValue = a[sortColumn] as unknown as number
        bValue = b[sortColumn] as unknown as number
      }

      if (aValue < bValue) return sortDirection ? -1 : 1
      if (aValue > bValue) return sortDirection ? 1 : -1
      return 0
    })

    return sortedData
  }, [listingTransactions, sortColumn, sortDirection])

  const handleThClick = (column: keyof ListingsTransaction) => {
    setSortDirection((prevSortDirection) => !prevSortDirection)
    setSortColumn(column)
  }

  const onCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} variant="wide">
      <ModalOverlay />
      <ModalContent p={7}>
        <ModalHeader p={0} mb={5}>
          <Trans>Term Listings</Trans>
        </ModalHeader>
        <ModalCloseButton m="18px" />
        <ModalBody p={0}>
          <Box mb={2}>
            <Text variant="body-sm/normal" color="gray.5">
              Proceed amounts are denominated in the loan purchase currency (
              {listingPurchaseCurrency.symbol}).
            </Text>
          </Box>
          <TableContainer w="full" mt="0 !important">
            <Table isFixedLayout noPadding>
              <Thead>
                <Tr>
                  {TABLE_HEADERS.map(({ column, label }) => (
                    <TableHeaderCell
                      column={column}
                      handleClick={handleThClick}
                      sortColumn={sortColumn}
                      sortDirection={sortDirection}
                    >
                      {label}
                    </TableHeaderCell>
                  ))}
                </Tr>
              </Thead>
              {/* <Box maxH="250px" overflowY="scroll"> */}
              <Tbody w="full">
                {sortedTableData.length > 0
                  ? sortedTableData.map((transaction, index) => (
                      <Tr
                        noBorder
                        key={transaction.id}
                        bg={index % 2 !== 0 ? 'blue.0' : undefined}
                        opacity={transaction.cancelled ? 0.5 : 1}
                      >
                        <TableCell>
                          {transaction.cancelled ? (
                            <Tooltip
                              noDelay
                              leftAlign
                              label={
                                <Text>This listing has been cancelled</Text>
                              }
                            >
                              <Text as="span">
                                {dayjs
                                  .unix(Number(transaction.timestamp))
                                  .format('MMM DD hha')}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text as="span">
                              {dayjs
                                .unix(Number(transaction.timestamp))
                                .format('MMM DD hha')}
                            </Text>
                          )}
                          <Link
                            href={chainConfig?.getExplorerTransactionLink(
                              transaction.transactionHash
                            )}
                            isExternal
                            color="gray.3"
                            pl={2}
                          >
                            <FontAwesomeIcon
                              icon={['far', 'arrow-up-right']}
                              width="10px"
                              height="10px"
                            />
                          </Link>
                        </TableCell>
                        <TableCell textAlign="right">
                          {formatFixedToken(
                            transaction.listingAmount,
                            listingPurchaseCurrency.symbol,
                            true
                          )}
                        </TableCell>
                        <TableCell textAlign="right">
                          {formatFixedToken(
                            transaction.remainingAmount,
                            listingPurchaseCurrency.symbol,
                            true
                          )}
                        </TableCell>
                        <TableCell textAlign="right">
                          {formatFixedToken(
                            transaction.soldCostPurchaseCurrency,
                            listingPurchaseCurrency.symbol,
                            true
                          )}
                        </TableCell>
                      </Tr>
                    ))
                  : null}
              </Tbody>
              {/* </Box> */}
              <Tfoot>
                <Tr noBorder>
                  <TableCell variant="body-sm/bold">Total</TableCell>
                  {/* Total Listed */}
                  <TableCell variant="body-sm/bold" textAlign="right">
                    {totalListed &&
                      formatFixedToken(
                        totalListed,
                        listingPurchaseCurrency.symbol,
                        true
                      )}
                  </TableCell>
                  {/* Total Remaining Open Listings */}
                  <TableCell variant="body-sm/bold" textAlign="right">
                    {totalOpenListings &&
                      formatFixedToken(
                        totalOpenListings,
                        listingPurchaseCurrency.symbol,
                        true
                      )}
                  </TableCell>
                  {/* Total Proceeds Purchase Currency */}
                  <TableCell variant="body-sm/bold" textAlign="right">
                    {totalProceedsPurchaseCurrency &&
                      formatFixedToken(
                        totalProceedsPurchaseCurrency,
                        listingPurchaseCurrency.symbol,
                        true
                      )}
                  </TableCell>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
