import { Avatar, Box, Heading, Link, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { InfoIcon } from '../../../../components/elements/Icons'
import { SignUpValidation } from '../../../../models/rewards'
import { socialLinks } from '../../../../socials'
import { HStack } from '../../../../components/elements/Stack'

export const HeadingWithInfoIcon = ({ children }: { children: ReactNode }) => (
  <Heading
    variant="heading/h1"
    as="h1"
    color="blue.9"
    fontWeight={600}
    lineHeight="32px"
  >
    {children}
    <Link target="_blank" href={socialLinks.rewardsAnnouncement} isExternal>
      <InfoIcon
        boxSize="16px"
        color="gray.4"
        ml={2}
        style={{ verticalAlign: 'inherit' }}
      />
    </Link>
  </Heading>
)

export const HeaderPreseasonPoints = () => (
  <Box>
    <HeadingWithInfoIcon>Sign up to unlock your bonus</HeadingWithInfoIcon>
    <Text variant="body-md/normal" color="gray.5">
      You have
      {'  '}
      <Text color="green.5" as="span">
        <span
          style={{
            color: 'transparent',
            textShadow: '0 0 15px #38A169',
            fontWeight: 800,
          }}
        >
          ????
        </span>
      </Text>
      {'  '}bonus points, sign up to unlock
    </Text>
  </Box>
)

export const HeaderSignUpValidation = ({
  signUpValidation,
}: {
  signUpValidation: SignUpValidation
}) => (
  <>
    <Heading variant="heading/h1" as="h1" color="blue.9" mb={2}>
      <HStack wrap="wrap">
        <Avatar
          size="sm"
          name={signUpValidation.name}
          src={signUpValidation.image ?? ''}
        />
        <Text
          as="span"
          wordBreak="break-word"
          whiteSpace="normal"
          maxW={'364px'}
        >
          {signUpValidation.name ?? ''}
        </Text>
        <Text as="span"> invited you</Text>
      </HStack>
    </Heading>
    <Text variant="body-md/normal" color="blue.9">
      Just a few steps to onboard and{' '}
      <Text as="span" variant="body-md/semibold">
        claim your points.
      </Text>
    </Text>
  </>
)

export const HeaderDefaultView = ({
  heading,
  subHeading,
}: {
  heading: string
  subHeading: string
}) => (
  <Box>
    <HeadingWithInfoIcon>{heading}</HeadingWithInfoIcon>
    <Text variant="body-md/normal" color="gray.5">
      {subHeading}
    </Text>
  </Box>
)
