import { Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'

export default function RedeemRolloverWarning() {
  return (
    <HStack
      padding="8px"
      borderRadius="md"
      backgroundColor="orange.50"
      spacing={0}
    >
      <FontAwesomeIcon icon={['far', 'circle-exclamation']} />
      <Text
        ml="8px"
        color="orange.500"
        fontSize="sm"
        fontWeight="400"
        lineHeight="5"
      >
        <Trans>
          Any funds that have been scheduled to rollover cannot be redeemed.
        </Trans>
      </Text>
    </HStack>
  )
}
