import {
  Heading,
  ModalBody as CModalBody,
  Text,
  VStack,
} from '@chakra-ui/react'

import { Socials } from '../../../../models/rewards'
import { SignInBody } from '../SignIn'

export const ModalHeading = () => (
  <VStack align="left" mx={7} gap={0}>
    <Heading
      variant="heading/h1"
      fontWeight={600}
      lineHeight={'32px'}
      color="blue.9"
      as="h1"
    >
      Sign in
    </Heading>
    <Text variant="body-md/normal" color="gray.5">
      To access all features
    </Text>
  </VStack>
)

type BodyProps = {
  account?: string
  isOnConnectScreen?: boolean
  isSwitchProfileViewOnly?: boolean
  onSignIn: (type: 'twitter' | 'discord' | 'sign') => void
  onViewOnly?: () => void
  connectedSocials: Record<Socials, boolean>
}

export const ModalBody = ({
  account,
  isOnConnectScreen,
  isSwitchProfileViewOnly,
  onSignIn,
  onViewOnly,
  connectedSocials,
}: BodyProps) => {
  return (
    <CModalBody pt={0}>
      <SignInBody
        account={account}
        isOnConnectScreen={isOnConnectScreen}
        isSwitchProfileViewOnly={isSwitchProfileViewOnly}
        onSignIn={onSignIn}
        onViewOnly={onViewOnly}
        connectedSocials={connectedSocials}
      />
    </CModalBody>
  )
}
