// import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { postAnalyzeWallet } from '../../../helpers/swr'
import { components } from '../../../models/profile-api'
import { useCallback, useState } from 'react'
import { wait } from '../../../helpers/wait'
import { produce } from 'immer'

// duration of animation
const ANIMATION_DURATION = 10 * 1000

type MigrationPointsWithLoading = {
  points?: components['schemas']['WalletAnalyzer']
  isLoading: boolean
}

export function useMigrationPoints(): {
  allMigrationPoints: { [wallet: string]: MigrationPointsWithLoading }
  isLoading: boolean
  isAnimating: boolean
  analyzeWallet: (
    wallet: string,
    clearPrevious?: boolean,
    triggerAnimation?: boolean
  ) => Promise<void>
} {
  const {
    // data: migrationPoints,
    // error,
    isMutating,
    trigger,
  } = useSWRMutation('/wallet-analyzer', postAnalyzeWallet)

  const [allMigrationPoints, setAllMigrationPoints] = useState<{
    [wallet: string]: MigrationPointsWithLoading
  }>({})
  const [isAnimating, setIsAnimating] = useState(true)

  const analyzeWallet = useCallback(
    async (
      wallet: string,
      clearPrevious: boolean = false,
      triggerAnimation: boolean = true
    ) => {
      if (triggerAnimation) {
        setIsAnimating(true)
      } else {
        setIsAnimating(false)
      }

      setAllMigrationPoints(
        produce((draft) => {
          if (clearPrevious) {
            draft = {
              [wallet]: { isLoading: true },
            }
          } else {
            if (!draft[wallet]) {
              draft[wallet] = { isLoading: true }
            } else {
              draft[wallet].isLoading = true
            }
          }
        })
      )
      console.log('calling wallet analyzer')
      const migrationPoints = await trigger(wallet)
      console.log('Got wallet analyzer result', migrationPoints)
      setAllMigrationPoints(
        produce((draft) => {
          draft[wallet] = {
            isLoading: false,
            points: migrationPoints,
          }
        })
      )

      if (triggerAnimation) {
        // Set a timeout to finish the animation
        wait(ANIMATION_DURATION).then(() => {
          console.log('finishing animation')
          setIsAnimating(false)
        })
      }
    },
    [trigger]
  )

  return {
    allMigrationPoints,
    isLoading: isMutating,
    isAnimating,
    analyzeWallet,
  }
}
