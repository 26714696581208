import { Box } from '@chakra-ui/react'
import Tooltip from '../../../../components/elements/Tooltip'
import ClickCopy from '../../../../components/elements/ClickCopy'
import { DeleteIcon } from '../../../../components/elements/Icons'
import { referralCodeInfoTooltipText } from '../ConfirmDeleteReferralCodeModal'
import SummaryStat from './SummaryStat'
import SummaryHeader from './SummaryHeader'
import SummaryDetails from './SummaryDetails'
import { shortenAddress } from '../../../../helpers/utils'

export const SummaryReferralCode = ({
  referralCode,
  openConfirmDeleteReferralCodeModal,
}: {
  referralCode: string
  openConfirmDeleteReferralCodeModal: () => void
}) => {
  return (
    <SummaryStat>
      <SummaryHeader
        text="Active Referral Address"
        tooltipText={referralCodeInfoTooltipText}
      />
      <SummaryDetails
        text={
          <Box as="span" display="flex" justifyContent="end">
            <Box as="div">{shortenAddress(referralCode)}</Box>
            <Box as="div" ml={2} w={3}>
              <ClickCopy
                copyText={referralCode}
                helperText={'Copy address'}
                tooltipProps={{
                  placement: 'bottom-end',
                  offset: [8, 8],
                }}
              />
            </Box>
            <Box as="div" ml={2} w={3}>
              <Tooltip
                label={'Remove referral address and associated benefits'}
                placement="bottom-end"
                offset={[8, 8]}
              >
                <DeleteIcon
                  width={3}
                  height={3}
                  cursor="pointer"
                  color="gray.3"
                  onClick={openConfirmDeleteReferralCodeModal}
                />
              </Tooltip>
            </Box>
          </Box>
        }
      />
    </SummaryStat>
  )
}

export default SummaryReferralCode
