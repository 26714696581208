import {
  Table,
  TableContainer,
  Flex,
  Thead,
  Tr,
  Spinner,
} from '@chakra-ui/react'
import { InviteTableCols } from '../../utils'
import { TableHeaderCell } from './TableHeaderCell'
import { tableHeadings } from './'

export default function TrackInvitesTableLoading() {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            {tableHeadings.map(({ key, column, label }, index) => (
              <TableHeaderCell
                key={key}
                column={column as InviteTableCols}
                xsPadding={index >= tableHeadings.length - 2}
                textRight={index >= tableHeadings.length - 2}
              >
                {label}
              </TableHeaderCell>
            ))}
          </Tr>
        </Thead>
      </Table>
      <Flex h="223px">
        <Spinner m="auto" />
      </Flex>
    </TableContainer>
  )
}
