import { CloseIcon } from '@chakra-ui/icons'
import { Box, Spacer, Spinner, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useCurrentTime } from '../../../data/hooks/helper-hooks'
import { HStack } from '../Stack'

import dismissed_icon from '../../../assets/icons/toast/dismissed.svg'
import error_icon from '../../../assets/icons/toast/reject.svg'
import success_icon from '../../../assets/icons/toast/success.svg'
import warning_icon from '../../../assets/icons/toast/warning.svg'
import TermToastIcon from '../TermToastIcon'

export const SuccessIcon = () => (
  <TermToastIcon src={success_icon} w="24px" h="24px" />
)
export const ErrorIcon = () => (
  <TermToastIcon src={error_icon} w="24px" h="24px" />
)
export const DismissedIcon = () => (
  <TermToastIcon src={dismissed_icon} w="24px" h="24px" />
)
export const WarningIcon = () => (
  <TermToastIcon src={warning_icon} w="24px" h="24px" />
)
export const PendingIcon = () => (
  <Spinner color={'blue.500'} w="24px" h="24px" />
)

export type TermToastProps = {
  icon?: React.ReactNode
  title?: React.ReactNode
  children?: React.ReactNode
  actions?: React.ReactNode
  duration?: number | null
  eventTimestamp?: number
  iconMarginTop?: string
  onClose?: () => void
}

export default function TermToast({
  icon,
  title,
  children,
  actions,
  eventTimestamp,
  iconMarginTop,
  onClose,
}: TermToastProps) {
  const currentTime = useCurrentTime()
  const eventTime = useMemo(
    () => (eventTimestamp ? dayjs(eventTimestamp) : undefined),
    [eventTimestamp]
  )
  return (
    <Box
      backgroundColor="white"
      p="8px"
      borderLeft="3px solid"
      borderColor={'blue.500'}
    >
      <HStack spacing={0}>
        <Box marginTop={iconMarginTop || '8px'} marginBottom="auto">
          {icon}
        </Box>
        <Box ml={icon ? '8px' : undefined} width="400px">
          <HStack pb="4px">
            <Text
              fontSize="sm"
              fontWeight="600"
              lineHeight="5"
              color="blue.500"
            >
              {title}
            </Text>
            <Spacer />
            {eventTime && (
              <Text
                fontSize="xs"
                fontWeight="400"
                lineHeight="4"
                color="gray.600"
                sx={{ whiteSpace: 'nowrap' }}
                mb="auto!important"
                mt={'2px!important'}
              >
                {eventTime.from(currentTime)}
              </Text>
            )}
            {onClose && (
              <Box
                onClick={() => {
                  try {
                    onClose()
                  } catch (error) {
                    console.log(error)
                  }
                }}
                bg="transparent"
                width={5}
                height={5}
                _hover={{ bg: 'gray.200' }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb="auto!important"
              >
                <CloseIcon color="black" width={2} height={2} />
              </Box>
            )}
          </HStack>
          {typeof children === 'string' ? (
            <Text
              fontSize="sm"
              fontWeight="400"
              lineHeight="5"
              color="gray.600"
            >
              {children}
            </Text>
          ) : (
            children
          )}
        </Box>
      </HStack>
      {actions && (
        <Box mt="16px" pl="66px">
          {actions}
        </Box>
      )}
    </Box>
  )
}
