import { Text } from '@chakra-ui/react'
import { HStack, Stack } from '../Stack'
import { ReactNode } from 'react'

export default function StatsRow({
  title,
  tooltip,
  children,
}: {
  title: string | ReactNode
  tooltip?: ReactNode
  children: ReactNode
}) {
  return (
    <HStack
      alignItems="start"
      justifyContent="space-between"
      px={4}
      mt="4px !important"
      overflow="hidden"
    >
      <HStack>
        <Text
          as="span"
          variant="body-sm/medium"
          color="gray.6"
          display="inline-flex"
          alignItems="center"
        >
          {title}
        </Text>
        {tooltip}
      </HStack>
      <Stack spacing={1} textAlign="end">
        {children}
      </Stack>
    </HStack>
  )
}
