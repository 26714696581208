import { Box, Flex, Text } from '@chakra-ui/react'
import InviteOverview from '../InviteOverview'
import TrackInvitesTableLoading from '../TrackInvitesTable/TrackInvitesTableLoading'

export default function InviteSectionLoading() {
  return (
    <Flex direction="column" gap={12}>
      <Box>
        <Text color={'blue.9'} variant={'body-2xl/medium'} mb="12px">
          Invite people to earn points
        </Text>
        <InviteOverview
          usedInvites={0}
          totalInvites={0}
          invitesPoints={0}
          yourShare={0}
          isLoading={true}
        />
      </Box>
      <Box mb={3}>
        <Text color={'blue.9'} variant={'body-2xl/medium'} mb="16.5px">
          Track your invites
        </Text>
        <TrackInvitesTableLoading />
      </Box>
    </Flex>
  )
}
