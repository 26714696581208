import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'
import StatsBeforeAfter from '../../../../components/elements/StatsBeforeAfter'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import TokenChip from '../../../../components/elements/TokenChip'
import { formatFixedToken } from '../../../../helpers/utils'
import { Trans } from '@lingui/macro'

export default function BorrowerCancelRolloverOverview({
  endOfRepaymentTimestamp,
  outstandingDebt,
  rolloverAmount,

  purchaseTokenSymbol,
  title,
  auctionLabel,
}: {
  endOfRepaymentTimestamp: number
  outstandingDebt: FixedNumber
  rolloverAmount: FixedNumber

  purchaseTokenSymbol: string
  title: string
  auctionLabel: string
}) {
  return (
    <StatsCard>
      <StatsTitle value={auctionLabel}>{title}</StatsTitle>
      <StatsRow
        title={
          <>
            <Trans>Rollover Amount </Trans>&nbsp;
            <TokenChip token={purchaseTokenSymbol} />
          </>
        }
      >
        <StatsBeforeAfter
          before={formatFixedToken(
            rolloverAmount,
            purchaseTokenSymbol,
            true,
            true
          )}
          after={0}
          isBeforeBold
        />
      </StatsRow>
      <StatsRow
        title={
          <>
            <Trans>Outstanding Debt</Trans> &nbsp;
            <TokenChip token={purchaseTokenSymbol} />
          </>
        }
      >
        <Text variant="body-sm/normal" as="span" color="blue.9">
          {formatFixedToken(outstandingDebt, purchaseTokenSymbol, true)}
        </Text>
      </StatsRow>
      <StatsRow
        title={
          <>
            <Trans>Repay By</Trans> &nbsp;
          </>
        }
      >
        <Text as="span" variant="body-sm/normal" color="blue.9">
          {dayjs.unix(endOfRepaymentTimestamp).format('MMM D, ha')}
        </Text>
      </StatsRow>
    </StatsCard>
  )
}
