import {
  Button,
  Flex,
  FlexProps,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'

export default function SignUpStep({
  stepIndex,
  text,
  callToAction,
  action,
  sub,
  isActive,
  isComplete,
  isLater,
}: {
  stepIndex: number
  text: string
  callToAction: string
  action: () => void
  sub: string
  isActive: boolean
  isComplete: boolean
  isLater: boolean
}) {
  return (
    <VStack opacity={isLater || isComplete ? 0.5 : 1} gap={0}>
      {StepSub(stepIndex, sub, isComplete, isLater)}
      <Flex w="full" alignItems="center" style={{ marginTop: 0 }} p={0}>
        <HStack justify="center" align="center">
          <StepIndicator bg="blue.1" color="blue.500">
            {StepIcon(stepIndex + 1, isComplete)}
          </StepIndicator>
          {StepText(text, isActive, isComplete)}
        </HStack>
        <Spacer />
        {StepButton(callToAction, action, isActive, isComplete)}
      </Flex>
    </VStack>
  )
}

export const StepIcon = (initial: number, isComplete: boolean) => {
  switch (true) {
    case isComplete:
      return <FontAwesomeIcon icon={['far', 'check']} color="blue.5" />
    default:
      return initial
  }
}

export const StepText = (
  initial: string,
  isActive: boolean,
  isComplete: boolean
) => {
  const color = isComplete
    ? 'blue.9'
    : isActive && !isComplete
      ? 'blue.9'
      : 'rgba(0, 16, 39, 0.5)'
  return (
    <Text
      color={color}
      variant="body-md/normal"
      css={{ a: { color: '#0A5ED9', fontWeight: 600 } }}
    >
      {/* Using for review purposes. */}
      <span dangerouslySetInnerHTML={{ __html: initial }} />
      {/* remove when we know where text comes from. */}
    </Text>
  )
}

export const StepButton = (
  initial: string,
  action: () => void,
  isActive: boolean,
  isComplete: boolean
) => {
  const buttonPropsGray = {
    opacity: 0.5,
    bg: 'gray.3',
    color: 'blue.9',
    pointerEvents: 'none' as const,
  }

  const buttonPropsPrimary = {
    variant: 'primary',
    pointerEvents: 'auto' as const,
  }

  const buttonProps =
    isActive && !isComplete ? buttonPropsPrimary : buttonPropsGray

  return (
    <Button
      w="120px"
      fontSize="md"
      onClick={action}
      isDisabled={!isActive}
      {...buttonProps}
    >
      {initial}
    </Button>
  )
}

export const StepSub = (
  stepIndex: number,
  initial: string,
  isComplete: boolean,
  isLater: boolean
) => {
  const color = stepIndex === 0 || isComplete || isLater ? 'gray.6' : 'green.5'
  return (
    <Text
      fontSize="2xs"
      variant="body-xs/normal"
      ml="auto"
      color={color}
      lineHeight={'16px'}
    >
      {initial}
    </Text>
  )
}

export const StepIndicator = (props: FlexProps & { children: ReactNode }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      as="div"
      borderRadius="md"
      bg={props.bg ?? 'blue.0'}
      w={42}
      h={42}
      zIndex={1}
      {...props}
    >
      <Text as="span" variant="body-md/medium" color="blue.5">
        {props.children}
      </Text>
    </Flex>
  )
}
