import { Button, ButtonProps } from '@chakra-ui/react'
import { isConnectedToMetaMask } from '../../../helpers/eip6963'
import { PropsWithChildren } from 'react'
import Tooltip from '../Tooltip'

export const LinkWalletButton = (props: PropsWithChildren<ButtonProps>) => {
  // this wont trigger a rerender unless parent rerenders
  // but the app only allows for one primary injected provider so shouldnt change
  const isMetaMask = isConnectedToMetaMask()

  const button = (
    <Button
      {...props}
      isDisabled={props.isDisabled && !isMetaMask}
      disabled={!isMetaMask}
    />
  )

  if (!isMetaMask) {
    return (
      <Tooltip
        aria-label="switch-wallets-tooltip"
        placement="bottom-start"
        noDelay
        label={
          'Switch wallets via your wallet provider to link a new wallet to your profile.'
        }
      >
        {button}
      </Tooltip>
    )
  }

  return button
}
