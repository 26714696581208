import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { Contract, FixedNumber } from 'ethers'
import { IERC20MetadataUpgradeable } from '../../abi-generated'
import { Address, Balance, Currency } from '../model'
import ERC20ABI from '../../abi/v0.2.4/IERC20MetadataUpgradeable.json'
import { useEffect, useMemo } from 'react'
import { captureException } from '@sentry/react'
import { CallResult } from '@usedapp/core'

export function useBalances(
  account: Address | undefined,
  addressesByChain:
    | { [chainId: string]: { [address: string]: Currency } }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): { [chainId: string]: Balance[] } | null | undefined {
  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (addressesByChain && account) {
      Object.entries(addressesByChain).forEach(([chainId, addresses]) => {
        const contracts = Object.keys(addresses)?.map(
          (address) =>
            new Contract(
              address,
              ERC20ABI,
              provider
            ) as IERC20MetadataUpgradeable
        )

        if (contracts) {
          const balanceOfCalls = contracts.map((contract) => ({
            contract,
            method: 'balanceOf',
            args: [account],
          }))
          calls[chainId] = [...balanceOfCalls]
        }
      })
    }
    return calls
  }, [addressesByChain, account, provider])

  const blockchainResults = useMultichainCalls(contractCalls)

  const { balancesAndErrors } = useMemo(() => {
    const balancesResults: Record<string, CallResult<Contract, string>[]> = {}

    Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
      balancesResults[chainId] = chainResults
    })
    return {
      balancesAndErrors: balancesResults,
    }
  }, [blockchainResults])

  const balances = useMemo(() => {
    const result: Record<string, any> = {}
    if (balancesAndErrors) {
      Object.entries(balancesAndErrors).forEach(([chainId, chainBalances]) => {
        result[chainId] = chainBalances
          .filter(
            (balanceOrError) =>
              balanceOrError?.error === undefined &&
              balanceOrError?.value !== undefined
          )
          .map((balanceOrError) => balanceOrError?.value)
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [balancesAndErrors])

  const blockchainErrors = useMemo(() => {
    const result: Record<string, any> = {}
    if (blockchainResults) {
      Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
        const chainErrors = chainResults
          .filter(
            (valueOrError) => valueOrError && valueOrError?.error !== undefined
          )
          .map((valueOrError) => valueOrError?.error)

        if (chainErrors.length > 0) {
          result[chainId] = chainErrors
        }
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [blockchainResults])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  return useMemo(() => {
    const result: { [chainId: string]: Balance[] } = {}

    // We're still loading if any of these are not defined.
    if (!addressesByChain || !blockchainResults || !balances) {
      return undefined // still loading or missing data
    }

    let allDataLoaded = true

    Object.entries(addressesByChain).forEach(([chainId, currencies]) => {
      const chainAddresses = addressesByChain[chainId]
      const chainBalances = balances[chainId]

      // if any of the data is missing or the lengths don't match, we're still loading
      if (
        !chainAddresses ||
        !chainBalances ||
        chainBalances.length !== Object.keys(chainAddresses).length
      ) {
        result[chainId] = []
        allDataLoaded = false
        return
      }

      result[chainId] = Object.entries(currencies)
        .map(([address, currency], index) => {
          const balanceResult = chainBalances[index]

          if (balanceResult !== undefined) {
            const balance = FixedNumber.fromValue(
              balanceResult?.[0] || 0,
              currency.decimals,
              `fixed128x${currency.decimals}`
            )
            return {
              address,
              balance,
              decimals: currency.decimals,
              symbol: currency.symbol,
            }
          } else {
            return undefined
          }
        })
        .filter((bal): bal is Balance => bal !== undefined)
    })

    // return if all data is loaded + and there is at least one balance otherwise undefined
    const retVal = allDataLoaded
      ? Object.keys(result).length > 0
        ? result
        : undefined
      : undefined

    return retVal
  }, [addressesByChain, balances, blockchainResults])
}
