import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Accordion as CAccordion,
  Divider,
  Flex,
  Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import Chip from '../../../../components/elements/Chip'

export default function Accordion({
  heading,
  count,
  summary,
  children,
}: {
  heading: ReactNode
  count?: number
  summary?: ReactNode
  children?: ReactNode
}) {
  return (
    <CAccordion allowToggle bgColor="gray.50" rounded="md" overflow="hidden">
      <AccordionItem border={0}>
        <AccordionButton display="flex">
          <Flex flexGrow={1} textAlign="start" columnGap={2} px={2}>
            <Text as="h3" variant="body-sm/semibold" color="blue.9">
              {heading}
            </Text>
            {!!count && <Chip>{count}</Chip>}
          </Flex>
          <AccordionIcon />
        </AccordionButton>
        {summary && <Divider mx={2} borderColor="blue.100" />}
        {summary && <Box p={4}>{summary}</Box>}
        <AccordionPanel px={2} pb={2}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </CAccordion>
  )
}
