import { Box, Image, Skeleton, Text } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import add_icon_colored from '../../../../assets/icons/add-colored.svg'
import add_icon from '../../../../assets/icons/add.svg'
import subtract_icon_colored from '../../../../assets/icons/subtract-colored.svg'
import subtract_icon from '../../../../assets/icons/subtract.svg'
import { removeNegativeZero } from '../../../../helpers/utils'
import {
  formatFixedUsd,
  getTextColorFromAmount,
} from '../../../../helpers/utils'
import { Trans } from '@lingui/macro'

export default function SummaryFormulaTerm({
  isDataLoading,
  label,
  usd,
  itemCount,
  itemText,
  hasAddSign,
  isIconColored,
}: {
  isDataLoading?: boolean
  label: string
  usd: FixedNumber
  itemCount?: number
  itemText?: string
  hasAddSign?: boolean
  isIconColored: boolean
}) {
  const fixedTokenNumber = formatFixedUsd(usd, false, true)
  const displayValue = removeNegativeZero(fixedTokenNumber.toString())
  const [wholeAmount, decimals] = displayValue.split('.')

  return (
    <HStack spacing={0}>
      {label !== 'Net Position' && (
        <>
          {hasAddSign ? (
            <Image
              src={isIconColored ? add_icon_colored : add_icon}
              alt="add"
              w="15px"
              h="15px"
            />
          ) : (
            <Image
              src={isIconColored ? subtract_icon_colored : subtract_icon}
              alt="subtract"
              w="15px"
              h="15px"
            />
          )}
        </>
      )}
      <Box
        h="88px"
        display="flex"
        flexDirection="column"
        rowGap={3}
        ml="6px !important"
      >
        <Text variant="body-sm/normal" color="gray.6" whiteSpace="nowrap">
          {label}
        </Text>
        <VStack alignItems="flex-start">
          {isDataLoading ? (
            <Skeleton h={9} w="120px" />
          ) : (
            <>
              <HStack
                alignItems="flex-end"
                color={getTextColorFromAmount(usd)}
                spacing={0}
              >
                <Text
                  as="div"
                  fontSize={{
                    base: '18px',
                    xl: '20px',
                  }}
                  lineHeight="1"
                >
                  {wholeAmount}
                </Text>
                <Text
                  as="div"
                  fontSize="20px"
                  lineHeight="1"
                  ml="0 !important"
                  transform={{
                    base: 'translateY(0px)',
                    xl: 'translateY(-2px)',
                  }}
                >
                  .
                </Text>
                <Text
                  as="div"
                  fontSize="14px"
                  lineHeight="1"
                  ml="2px !important"
                  transform={{
                    base: 'translateY(-1px)',
                    xl: 'translateY(-2px)',
                  }}
                >
                  {decimals === '0' ? '00' : decimals}
                </Text>
              </HStack>
              {Boolean(itemCount && itemText) && (
                <Text as="span" variant="body-xs/normal" color="gray.4">
                  <Trans>
                    Across {itemCount} {itemText}
                  </Trans>
                </Text>
              )}
            </>
          )}
        </VStack>
      </Box>
    </HStack>
  )
}
