import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react'
import unlock from '../../../../assets/icons/unlock.svg'
import { formatNumber } from '../../utils'

export const TotalPointsBox = ({
  totalLockedPoints,
  isPending,
  isExistingAccount,
  topUserPercentage,
}: {
  totalLockedPoints: number
  isPending: boolean
  isExistingAccount: boolean
  topUserPercentage?: number
}) => (
  <VStack
    borderWidth="2px"
    borderColor="blue.5"
    borderRadius="32px"
    p={10}
    w="310px"
  >
    <Text fontFamily="plexMono" fontWeight={500} fontSize="19px" color="blue.5">
      {'You can unlock'}
    </Text>
    {!isPending ? (
      <Text
        fontFamily="plexMono"
        fontWeight={700}
        fontSize="56px"
        color="blue.5"
        lineHeight="78px"
        textAlign="center"
      >
        {formatNumber(totalLockedPoints, false, undefined, true)}
      </Text>
    ) : (
      <Box h="78px" w="236px" bg="gray.2" opacity={0.5} />
    )}
    <HStack>
      <Image src={unlock} alt="unlock bonus points" mr={2} />
      <Text
        fontFamily="plexMono"
        fontWeight={500}
        fontSize="25px"
        color="blue.5"
        textTransform={isExistingAccount ? 'uppercase' : 'none'}
      >
        Bonus Points
      </Text>
    </HStack>
  </VStack>
)
