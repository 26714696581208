import { Tooltip as CTooltip, TooltipProps } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export default function Tooltip(
  props: PartialBy<TooltipProps, 'children'> & {
    hasError?: boolean
    leftAlign?: boolean
    infoIcon?: boolean
    iconColor?: string
    noDelay?: boolean
    delayPeriod?: number
  }
) {
  const {
    noDelay,
    leftAlign,
    infoIcon,
    iconColor,
    hasError,
    delayPeriod,
    ...restProps
  } = props
  return (
    <>
      <style>
        {`
            /* Tooltip interactivity override */
            .chakra-portal [data-popper-placement] {
              pointer-events: auto;
            }
        `}
      </style>
      <CTooltip
        {...restProps}
        hasArrow
        bg={props.bg ? props.bg : hasError ? 'red.2' : 'blue.900'}
        borderRadius="4px"
        maxW="320px"
        padding="8px"
        textAlign={leftAlign ? 'left' : 'center'}
        closeDelay={noDelay ? undefined : delayPeriod ? delayPeriod : 1000}
        color={hasError ? 'red.5' : 'white'}
        fontWeight={hasError ? 'normal' : 'medium'}
      >
        {props.children ||
          (infoIcon ? (
            <FontAwesomeIcon
              fontSize={'16px'}
              icon={['far', 'info-circle']}
              color={iconColor ? iconColor : '#FFFF'}
            />
          ) : (
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              color={iconColor ? iconColor : '#CAD5E4'}
            />
          ))}
      </CTooltip>
    </>
  )
}
