import { TransactionStatus, useSendTransaction } from '@usedapp/core'
import { BigNumberish, Contract, Signer } from 'ethers'
import { useEffect, useMemo } from 'react'
import { IERC20MetadataUpgradeable } from '../../abi-generated'
import { ERC4626Upgradeable } from '../../abi-generated'
import { waitForStatus } from '../../helpers/wait'
import { Address, Currency } from '../model'
import IERC20MetadataUpgradeableABI from '../../abi/v0.2.4/IERC20MetadataUpgradeable.json'
import ERC4626UpgradeableABI from '../../abi-external/ERC4626Upgradeable.json'
import { convertChainId, isVaultToken } from '../../helpers/conversions'

export function useTokenApprove(
  signer: Signer | undefined,
  token: Currency | undefined,
  chainId: number
) {
  const { sendTransaction, state, resetState } = useSendTransaction()

  const transactionStates = useMemo(
    () =>
      ({}) as {
        approve: TransactionStatus | undefined
      },
    []
  )
  useEffect(() => {
    transactionStates.approve = state
  }, [state, transactionStates])

  return async (spender: Address, amount: BigNumberish) => {
    const parsedChainId = convertChainId(chainId)
    const network = await signer?.provider?.getNetwork()

    if (!token) {
      console.warn(`no token found for approval call`)
      return
    }

    if (network?.chainId !== parsedChainId) {
      throw new Error(
        `active network (${network?.chainId}) does not match desired chainId (${parsedChainId})`
      )
    }

    const from = await signer?.getAddress()
    console.log(
      `Approving ${amount} to ${spender} of token ${token.symbol} from ${from}`
    )

    let contract: IERC20MetadataUpgradeable | ERC4626Upgradeable
    let data: string

    if (isVaultToken(token.symbol)) {
      contract = new Contract(
        token.address,
        ERC4626UpgradeableABI
      ) as ERC4626Upgradeable

      data = (contract as ERC4626Upgradeable).interface.encodeFunctionData(
        'increaseAllowance',
        [spender, amount]
      )
    } else {
      contract = new Contract(
        token.address,
        IERC20MetadataUpgradeableABI
      ) as IERC20MetadataUpgradeable

      data = (
        contract as IERC20MetadataUpgradeable
      ).interface.encodeFunctionData('approve', [spender, amount])
    }

    await sendTransaction({
      chainId: parsedChainId,
      to: token.address,
      from,
      data,
    })
    await waitForStatus(
      async () => transactionStates.approve,
      resetState,
      ['Success', 'Fail', 'Exception'],
      100,
      1000
    )
  }
}

export function useTokensApprove(signer: Signer | undefined) {
  const { sendTransaction, state, resetState } = useSendTransaction()

  const transactionStates = useMemo(
    () =>
      ({}) as {
        approve: TransactionStatus | undefined
      },
    []
  )
  useEffect(() => {
    transactionStates.approve = state
  }, [state, transactionStates])

  return async (
    chainId: string,
    token: Address,
    spender: Address,
    amount: BigNumberish
  ) => {
    const parsedChainId = convertChainId(chainId)
    const network = await signer?.provider?.getNetwork()

    if (network?.chainId !== parsedChainId) {
      throw new Error(
        `active network (${network?.chainId}) does not match desired chainId (${parsedChainId})`
      )
    }

    const from = await signer?.getAddress()
    console.log(
      `Approving ${amount} to ${spender} of token ${token} from ${from}`
    )

    let contract: IERC20MetadataUpgradeable | ERC4626Upgradeable
    let data: string

    if (isVaultToken(token)) {
      contract = new Contract(
        token,
        ERC4626UpgradeableABI
      ) as ERC4626Upgradeable

      data = (contract as ERC4626Upgradeable).interface.encodeFunctionData(
        'increaseAllowance',
        [spender, amount]
      )
    } else {
      contract = new Contract(
        token,
        IERC20MetadataUpgradeableABI
      ) as IERC20MetadataUpgradeable

      data = (
        contract as IERC20MetadataUpgradeable
      ).interface.encodeFunctionData('approve', [spender, amount])
    }

    await sendTransaction({
      chainId: parsedChainId,
      to: token,
      from,
      data,
    })
    await waitForStatus(
      async () => transactionStates.approve,
      resetState,
      ['Success', 'Fail', 'Exception'],
      100,
      1000
    )
  }
}
