import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import lendSuccessIcon from '../../../../assets/icons/success.svg'
import lendSuccessGif from '../../../../assets/icons/lend-success.gif'
import { HStack, VStack } from '../../../../components/elements/Stack'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useConfig } from '../../../../providers/config'
import { useSafary } from '../../../../data/analytics/use-safary'

export default function LendSuccessModal({
  lendAmount,
  redemptionTimestamp,
  isOpen,
  onAddRepoTokenToWallet,
  onNavigateToPortfolio,
  onClose,
}: {
  lendAmount: React.ReactNode
  redemptionTimestamp: number
  isOpen: boolean
  onAddRepoTokenToWallet: () => Promise<void>
  onNavigateToPortfolio: () => void
  onClose: () => void
}) {
  const onCloseModal = () => {
    onClose()
  }
  const { trackSafaryEvent } = useSafary()
  const config = useConfig()
  return (
    <Modal isOpen={isOpen} isCentered onClose={onCloseModal} variant="standard">
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none">
        <Box
          bg="white"
          borderRadius="16px"
          w="480px"
          position="relative"
          overflow="hidden"
        >
          <ModalHeader
            borderBottomRightRadius="230px"
            borderBottomLeftRadius="230px"
            bg="#E9FDF980"
            backgroundImage={`url(${lendSuccessGif})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            overflow="hidden"
          >
            <Flex height="217px" justifyContent="center" alignItems="center">
              <Image src={lendSuccessIcon} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton m="8px" />
          <ModalBody pt={9} pb={2}>
            <VStack gap={5}>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Text variant="body-md/medium" color="gray.6">
                  Fantastic! You've received
                </Text>
                <Text variant="body-xl/bold">
                  <Text as="span" color="green.4">
                    {lendAmount}
                  </Text>{' '}
                  Repo Tokens
                </Text>
              </Flex>
              <Flex
                textAlign="center"
                alignItems="center"
                flexDirection="column"
              >
                <Text pb={4} variant="body-md/medium" color="gray.5.5">
                  Repo Tokens are receipt tokens that can be redeemed at
                  maturity on{' '}
                  <Text as="span" variant="body-md/bold">
                    {dayjs.unix(redemptionTimestamp).format('MMM DD, ha')}
                  </Text>
                  .
                </Text>
                <Text variant="body-md/medium" color="gray.5.5">
                  Manage your tokens via the portfolio.
                </Text>
              </Flex>
            </VStack>
          </ModalBody>
          <ModalFooter pb={5}>
            <HStack alignItems="center" justifyContent="center" width="100%">
              <Button
                variant="link"
                leftIcon={<FontAwesomeIcon icon={['far', 'plus']} />}
                onClick={() => {
                  onAddRepoTokenToWallet()
                  trackSafaryEvent(
                    config?.safary?.earn?.addRepoTokenToWallet?.type ?? 'click',
                    'add repo token',
                    {
                      amount: lendAmount?.toString() ?? '',
                      redemptionTimestamp: redemptionTimestamp.toString(),
                    }
                  )
                }}
              >
                <Text variant="body-sm/medium" color="gray.6">
                  Add to wallet
                </Text>
              </Button>
              <Button
                variant="link"
                rightIcon={<FontAwesomeIcon icon={['far', 'arrow-up-right']} />}
                onClick={() => {
                  trackSafaryEvent(
                    config?.safary?.earn?.goToPortfolio?.type ?? 'click',
                    'navigate to portfolio',
                    {}
                  )
                  onNavigateToPortfolio()
                }}
              >
                <Text variant="body-sm/medium" color="gray.6">
                  View in portfolio
                </Text>
              </Button>
            </HStack>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  )
}
