import { ChainId } from '@usedapp/core'
import mainnet from '../assets/icons/switchNetwork/switchNetworkEthereum.svg'
import avalanche from '../assets/icons/switchNetwork/switchNetworkAvalanche.svg'
import sepolia from '../assets/icons/switchNetwork/switchNetworkSepolia.svg'
import polygon from '../assets/icons/switchNetwork/switchNetworkPolygon.svg'

import unknown from '../assets/icons/error.svg'

const switchNetworkIcons: {
  [ChainId: number]: string
} = {
  // mainnet
  [ChainId.Mainnet]: mainnet,
  [ChainId.Avalanche]: avalanche,
  // [ChainId.Arbitrum]: arbitrum,
  // [ChainId.Base]: base,
  // [ChainId.BSC]: bsc,
  // [ChainId.Polygon]: polygon,

  // testnet
  [ChainId.Sepolia]: sepolia,
  [ChainId.Mumbai]: polygon,
  [-1]: unknown,
}

export default switchNetworkIcons
