import { Box, Button, Flex, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { captureException } from '@sentry/react'
import { useTermToast } from '../../../../hooks/toasts'
import {
  SignUpValidation,
  SignUpStatus,
  SocialActions,
} from '../../../../models/rewards'
import ConfirmSkipModal from '../ConfirmSkipModal'
import {
  HeaderDefaultView,
  HeaderPreseasonPoints,
  HeaderSignUpValidation,
} from './Header'
import SignUpStep from './SignUpStep'
import { SignUpStep as Step } from '../../../../models/rewards'
import { socialLinks } from '../../../../socials'

export default function SignUp({
  socialPoints,
  hasPreseasonPoints,
  currentActiveStepSignUp,
  signUpStatus,
  signUpValidation,
  heading,
  subHeading,
  isModal,
  onLinkWalletNewUser,
  onLinkTwitter,
  onLinkDiscord,
  onBack,
  onSignUpLater,
  onAnalyzeWallet,
  resetSignUpStepsSignal,
}: {
  socialPoints?: number
  hasPreseasonPoints: boolean
  currentActiveStepSignUp?: number
  signUpStatus?: SignUpStatus
  signUpValidation?: SignUpValidation
  heading?: string
  subHeading?: string
  isModal?: boolean
  onLinkWalletNewUser?: (
    referralType?: 'invite' | 'referral',
    code?: string
  ) => Promise<void>
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => void
  onBack?: () => void
  onSignUpLater: () => void
  onAnalyzeWallet: () => void
  resetSignUpStepsSignal?: {}
}) {
  const {
    isOpen: isSignUpLaterModalOpen,
    onOpen: onSignUpLaterModalOpen,
    onClose: onSignUpLaterModalClose,
  } = useDisclosure()

  const termToast = useTermToast()
  const [activeStep, setActiveStep] = useState(currentActiveStepSignUp ?? 0)
  const [hasSignedMessage, setHasSignedMessage] = useState(false)
  const [hasSkippedTwitter, setHasSkippedTwitter] = useState(false)

  useEffect(() => {
    if (!hasSignedMessage && activeStep > 0) {
      setHasSignedMessage(true)
    }
  }, [activeStep, hasSignedMessage])

  const handleSignUp = () => {
    if (onLinkWalletNewUser) {
      onLinkWalletNewUser(signUpValidation?.type, signUpValidation?.code ?? '')
        .then(() => {
          console.log('set next step active')
          handleComplete(0)
        })
        .catch((e: Error) => {
          if (e?.message.includes('User rejected the request.')) {
            captureException(e)
          }
          termToast.failure({
            title: 'Error creating profile',
            children: 'Please try again.',
          })
        })
    }
  }

  const handleLinkDiscord = useCallback(() => {
    if (onLinkDiscord) {
      const action = isModal
        ? SocialActions.SignUpLinkDiscordModal
        : SocialActions.SignUpLinkDiscord
      let path = `/rewards?action=${action}&skipTwitter=${hasSkippedTwitter ?? false}`
      if (signUpValidation) {
        path += `&type=${signUpValidation.type}&name=${signUpValidation.name}&image=${signUpValidation.image}`
      }
      console.log('clicked handleLinkDiscord: using this path - %o', path)
      onLinkDiscord(path)
    } else {
      termToast.failure({
        title: 'Error linking Discord account',
        children: 'Please try again.',
      })
    }
  }, [isModal, hasSkippedTwitter, onLinkDiscord, signUpValidation, termToast])

  const initialSteps: Step[] = [
    {
      text: 'Create profile',
      callToAction: 'Sign message',
      sub: 'Signing is free!',
      action: handleSignUp,
      isComplete: false,
      isLater: false,
    },
    {
      text: `Follow <a color="blue.5" href="${socialLinks.twitter}" target="_blank">@Term_Labs</a> on X`,
      callToAction: 'Link X',
      sub: `+ ${(socialPoints ?? 0).toLocaleString()} points`,
      action: () => {
        const action = isModal
          ? SocialActions.SignUpLinkTwitterModal
          : SocialActions.SignUpLinkTwitter
        let path = `/rewards?action=${action}`
        if (signUpValidation) {
          path += `&type=${signUpValidation.type}&name=${signUpValidation.name}&image=${signUpValidation.image}`
        }
        if (onLinkTwitter) {
          onLinkTwitter(path)
        } else {
          termToast.failure({
            title: 'Error linking X account',
            children: 'Please try again.',
          })
        }
      },
      isComplete: false,
      isLater: true,
    },
    {
      text: `Join <a href="${socialLinks.discord}" target="_blank">Term</a> on Discord`,
      callToAction: 'Link Discord',
      sub: `+ ${(socialPoints ?? 0).toLocaleString()} points`,
      action: handleLinkDiscord,
      isComplete: false,
      isLater: true,
    },
  ]

  // Reset the modal if a new object is passed to resetModalSignal
  useEffect(() => {
    if (resetSignUpStepsSignal) {
      setHasSignedMessage(false)
      setSteps(initialSteps)
      setActiveStep(0)
    }
  }, [resetSignUpStepsSignal])

  const [steps, setSteps] = useState<Step[]>(initialSteps)

  useEffect(() => {
    console.log('has skipped twitter has changed: %o', hasSkippedTwitter)
    setSteps((prevSteps) => {
      return prevSteps.map((step, index) => {
        if (index === 2) {
          return {
            ...step,
            action: handleLinkDiscord,
          }
        }
        return step
      })
    })
  }, [handleLinkDiscord, hasSkippedTwitter])

  const handleBack = () => {
    if (activeStep === 2) {
      setHasSkippedTwitter(false)
    }
    if (!hasPreseasonPoints && !hasSignedMessage) {
      console.log("going back to invite screen as user hasn't signed up yet")
      if (onBack) {
        onBack()
      }
    } else {
      setSteps((prevSteps) => {
        if (activeStep === 2) {
          const updatedSteps = [...prevSteps]
          updatedSteps[1].isLater = false
          updatedSteps[2].isLater = true
          return updatedSteps
        }
        return prevSteps
      })
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleLater = () => {
    // track if user has clicked later on twitter step
    if (activeStep === 1) {
      setHasSkippedTwitter(true)
    }
    if (activeStep === steps.length - 1) {
      console.log('last later btn clicked, triggering wallet analyzer')
      onAnalyzeWallet()
    } else {
      setSteps((prevSteps) => {
        return prevSteps.map((step, index) => {
          if (index === activeStep) {
            return {
              ...step,
              isComplete: false,
              isLater: true,
            }
          } else if (index === activeStep + 1) {
            return {
              ...step,
              isLater: false,
            }
          }
          return step
        })
      })
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleComplete = useCallback(
    (stepIndex: number) => {
      if (stepIndex === 1 || stepIndex === 2) {
        setSteps((prevSteps) => {
          return prevSteps.map((step, index) => {
            if (index === 0) {
              return {
                ...step,
                isComplete: true,
              }
            }
            return step
          })
        })
      }

      setSteps((prevSteps) => {
        return prevSteps.map((step, index) => {
          if (index === stepIndex) {
            return {
              ...step,
              isComplete: true,
            }
          } else if (index === stepIndex + 1) {
            return {
              ...step,
              isLater: false,
            }
          }
          return step
        })
      })
      if (stepIndex < steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    },
    [steps.length]
  )

  /**
   * If user comes back from Twitter redirect:
   *
   * On success:
   * - mark the first step as complete
   * - mark the second step as complete
   * - mark the active step as discord
   *
   * On failure:
   * - mark the first step as complete
   * - mark the second step as incomplete
   * - mark the active step as twitter
   *  */
  const handleTwitterRedirect = useCallback((twitterSuccess: boolean) => {
    setSteps((prevSteps) => {
      return prevSteps.map((step, index) => {
        if (index === 0) {
          return {
            ...step,
            isComplete: true,
          }
        } else if (index === 1) {
          return {
            ...step,
            isComplete: twitterSuccess,
            isLater: false,
          }
        } else if (index === 2) {
          return {
            ...step,
            isComplete: false,
            isLater: false,
          }
        }
        return step
      })
    })
    setActiveStep(twitterSuccess ? 2 : 1)
  }, [])

  /**
   * If user comes back from Discord redirect:
   *
   * On success:
   * - mark the first step as complete
   * - mark the second step as complete
   * - mark the active step as discord
   *
   * On failure:
   * - mark the first step as complete
   * - mark the second step as complete / later
   * - mark the active step as discord
   *  */
  const handleDiscordRedirect = useCallback(
    (discordSuccess: boolean, hasSkippedTwitter: boolean) => {
      setSteps((prevSteps) => {
        return prevSteps.map((step, index) => {
          if (index === 0) {
            return {
              ...step,
              isComplete: true,
            }
          } else if (index === 1) {
            return {
              ...step,
              isComplete: !hasSkippedTwitter,
              isLater: hasSkippedTwitter,
            }
          } else if (index === 2) {
            return {
              ...step,
              isComplete: discordSuccess,
              isLater: false,
            }
          }
          return step
        })
      })
      setActiveStep(2)
    },
    []
  )

  /**
   * If user fails to sign up with wallet, mark step 0 as incomplete and reset the active step to 0
   */
  const handleWalletSignUpFailure = useCallback(() => {
    setSteps((prevSteps) => {
      return prevSteps.map((step, index) => {
        if (index === 0) {
          return {
            ...step,
            isComplete: false,
          }
        }
        return step
      })
    })
    setActiveStep(0)
  }, [])

  useEffect(() => {
    if (signUpStatus?.wallet && signUpStatus.wallet === 'failure') {
      handleWalletSignUpFailure()
    }
  }, [handleWalletSignUpFailure, signUpStatus?.wallet])

  useEffect(() => {
    if (signUpStatus?.twitter) {
      handleTwitterRedirect(signUpStatus.twitter === 'success' ? true : false)
    }
  }, [handleTwitterRedirect, signUpStatus?.twitter])

  useEffect(() => {
    if (signUpStatus?.discord) {
      handleDiscordRedirect(
        signUpStatus.discord === 'success' ? true : false,
        !!signUpStatus.hasSkippedTwitter
      )
    }
  }, [
    handleDiscordRedirect,
    signUpStatus?.discord,
    signUpStatus?.hasSkippedTwitter,
  ])

  const onSignUpFromSkipModal = () => {
    onSignUpLaterModalClose()
    handleSignUp()
  }

  // the back button only appears under certain conditions
  const showBackButton = useMemo(() => {
    if (isModal) {
      return false
    }
    return (
      (!hasPreseasonPoints && !hasSignedMessage) ||
      (activeStep > 0 && !steps[activeStep - 1]?.isComplete)
    )
  }, [hasPreseasonPoints, hasSignedMessage, activeStep, steps, isModal])

  const backButtonProps = {
    size: 'xs',
    variant: 'unstyled',
    color: 'gray.4',
    height: '20px',
    minW: 'unset',
    _focus: { boxShadow: 'none' },
    onClick: handleBack,
  }

  const laterButtonProps = {
    fontSize: '10px',
    variant: 'unstyled',
    color: 'gray.4',
    height: '20px',
    minW: 'unset',
    onClick: handleLater,
    ml: 'auto',
  }

  return (
    <>
      <ConfirmSkipModal
        isOpen={isSignUpLaterModalOpen}
        onClose={onSignUpLaterModalClose}
        onSignUp={onSignUpFromSkipModal}
        onContinue={onSignUpLater}
      />
      <Flex
        mt={isModal ? undefined : { base: 10, xl: 20, '1xl': 32, '2xl': 40 }}
        justify="center"
      >
        <Box
          w="480px"
          pt={isModal ? 7 : 5}
          pb={7}
          px={7}
          borderRadius="6px"
          bg="white"
          boxShadow="0px 4px 4px 0px rgba(49, 130, 206, 0.03)"
        >
          <Box mb={5}>
            {!isModal && (
              <Flex w="full" justifyContent="space-between" pb={2}>
                {/* Back Button */}
                {showBackButton ? (
                  <Button {...backButtonProps}>
                    <FontAwesomeIcon
                      icon={['far', 'chevron-left']}
                      role="button"
                      width="12px"
                    />
                  </Button>
                ) : (
                  <Box height="20px" />
                )}
                {/* Later Button */}
                {hasSignedMessage ? (
                  <Button {...laterButtonProps}>Later</Button>
                ) : (
                  <Box height="20px" />
                )}
              </Flex>
            )}
            {heading && subHeading ? (
              <HeaderDefaultView heading={heading} subHeading={subHeading} />
            ) : hasPreseasonPoints ? (
              <HeaderPreseasonPoints />
            ) : signUpValidation ? (
              <HeaderSignUpValidation signUpValidation={signUpValidation} />
            ) : null}
          </Box>
          <SimpleGrid columns={1} spacing={26}>
            {steps.map((step: Step, index: number) => (
              <SignUpStep
                key={step.text}
                stepIndex={index}
                text={step.text}
                callToAction={step.callToAction}
                action={step.action}
                sub={step.sub}
                isActive={activeStep === index}
                isComplete={step.isComplete}
                isLater={step.isLater}
              />
            ))}
          </SimpleGrid>
          {!isModal && (
            <Box display="flex" justifyContent="flex-end" mt={7}>
              {hasPreseasonPoints && !hasSignedMessage ? (
                <Button
                  py={0}
                  pr={0}
                  isDisabled={hasSignedMessage}
                  maxW="120px"
                  h="auto"
                  lineHeight="16px"
                  fontSize="xs"
                  fontWeight="500"
                  variant="tertiary"
                  border={0}
                  textAlign={'right'}
                  onClick={onSignUpLaterModalOpen}
                >
                  Sign up later
                </Button>
              ) : (
                <Box w="100%" height="16px" />
              )}
            </Box>
          )}
        </Box>
      </Flex>
    </>
  )
}
