import { ChainId } from '@usedapp/core'

type Networkish = number | string | ChainId | undefined

export function isValidNetwork(
  isMainnet: boolean,
  chainId: Networkish
): boolean {
  return (
    (isMainnet && isSupportedMainnet(chainId)) ||
    (!isMainnet && isSupportedTestnet(chainId))
  )
}

export function isSupportedMainnet(chainId: Networkish) {
  return (
    !!chainId && (chainId === ChainId.Mainnet || chainId === ChainId.Avalanche)
  )
}

export function isSupportedTestnet(chainId: Networkish) {
  return (
    !!chainId && (chainId === ChainId.Mumbai || chainId === ChainId.Sepolia)
  )
}
