import { Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'

export const ApyTooltipLabel = (
  fixedApy: FixedNumber,
  auctionClearingRate: FixedNumber,
  externalProjectLabel?: string,
  externalProjectRewards?: number,
  termPoints?: number
) => {
  return (
    <>
      <VStack alignItems="flex-start" w="full" color="white" gap={0}>
        <HStack
          justifyContent="space-between"
          w="full"
          mb={4}
          mt="0 !important"
        >
          <Text as="span" variant="body-md/bold" color="white">
            Fixed APY
          </Text>
          <Text as="span" variant="body-md/bold" color="white">
            {fixedToFormattedPercentage(fixedApy, 3).formattedPercentage}
          </Text>
        </HStack>
        <HStack
          justifyContent="space-between"
          w="full"
          mb={0}
          mt="0 !important"
        >
          <Text as="span" variant="body-sm/normal" color="gray.4">
            Clearing rate:
          </Text>
          <Text as="span" variant="body-sm/normal" color="white">
            {
              fixedToFormattedPercentage(auctionClearingRate, 3)
                .formattedPercentage
            }
          </Text>
        </HStack>
        {termPoints && (
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/normal" color="gray.4">
              Term points:
            </Text>
            <Text as="span" variant="body-sm/normal" color="white">
              {termPoints}x
            </Text>
          </HStack>
        )}
        {externalProjectLabel && externalProjectRewards && (
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/normal" color="gray.4">
              {externalProjectLabel} rewards:
            </Text>
            <Text as="span" variant="body-sm/normal" color="white">
              {externalProjectRewards}x
            </Text>
          </HStack>
        )}
      </VStack>
    </>
  )
}
