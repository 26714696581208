import {
  Button,
  ButtonProps,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  Image,
  Text,
} from '@chakra-ui/react'
import { VStack } from '../Stack'
import { ChainId } from '@usedapp/core'
import { networkIcons } from '../../../helpers/networkIcons'
import { ChainSpecificConfig } from '../../../config'

import tickIcon from '../../../assets/icons/tick_blue.svg'

const SelectedTick = () => <Image src={tickIcon} alt="tick" />

const SelectNetworkButton = ({
  size = 'md',
  height = '40px',
  width = '424px',
  borderRadius = 'md',
  backgroundColor = 'white',
  _hover = { bg: 'gray.200' },
  justifyContent = 'left',
  borderWidth = '1px',
  borderColor = 'blue.500',
  fontWeight = '600',
  textColor = 'blue.500',
  lineHeight = '24px',
  fontStyle = 'normal',
  fontFamily = 'Inter',
  fontSize = '16px',
  padding = '10px 16px',
  ...props
}: ButtonProps) => {
  return (
    <Button
      size={size}
      height={height}
      width={width}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      _hover={_hover}
      justifyContent={justifyContent}
      borderWidth={borderWidth}
      borderColor={borderColor}
      fontWeight={fontWeight}
      textColor={textColor}
      lineHeight={lineHeight}
      fontStyle={fontStyle}
      fontFamily={fontFamily}
      fontSize={fontSize}
      padding={padding}
      {...props}
    />
  )
}

const selectedStyles = {
  pointerEvents: 'none',
} as const

const SwitchNetworkModal = ({
  isOpen,
  onSwitchNetwork,
  onClose,
  networks,
  activeNetwork,
}: {
  isOpen: boolean
  onSwitchNetwork?: (chainId?: ChainId) => Promise<boolean>
  onClose: (chainId?: ChainId) => void
  networks: ChainSpecificConfig[]
  activeNetwork: ChainId | undefined
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent borderRadius="md" p="28px" width="480px">
        <ModalHeader pt={1} px={1} mb="2px" mt="-4px">
          Select Network
        </ModalHeader>
        <ModalCloseButton size="sm" mr="18px" mt="24px" color="blue.900" />
        <VStack spacing="12px" ml="28px" mr="28px">
          {networks.map((network: ChainSpecificConfig) => {
            const isSelected = network.chainId === activeNetwork
            return (
              <SelectNetworkButton
                key={network.chainId}
                leftIcon={
                  <img
                    src={networkIcons[network.chainId]}
                    alt={network.chainName}
                    width="16px"
                    height="16px"
                  />
                }
                rightIcon={isSelected ? <SelectedTick /> : undefined}
                style={isSelected ? selectedStyles : undefined}
                onClick={() => {
                  console.info('Switching networks to', network.chainName)
                  if (onSwitchNetwork) {
                    onSwitchNetwork(network.chainId).then((res) => {
                      // close modal on successful network switch
                      if (res) {
                        onClose(network.chainId)
                      }
                    })
                  }
                }}
              >
                <Text w="full" align="left">
                  {network.chainName}
                </Text>
              </SelectNetworkButton>
            )
          })}
        </VStack>
      </ModalContent>
    </Modal>
  )
}

export default SwitchNetworkModal
