import { graphql } from '../../gql/listings'

// All active listings for a given token list
export const PortfolioListingsDocument = graphql(`
  query GetPortfolioListings($wallet: Bytes!) {
    listings(where: { seller: $wallet }) {
      id
      seller
      token
      listingAmount
      remainingAmount
      cancelled
      transactionHash
      timestamp
    }
    purchases(where: { buyer: $wallet }) {
      id
      buyer
      seller
      termRepoToken
      purchaseToken
      amount
      cost
      timestamp
      transactionHash
    }
    listingPurchases: purchases(where: { seller: $wallet }) {
      id
      buyer
      seller
      termRepoToken
      purchaseToken
      amount
      cost
      timestamp
      transactionHash
      listingId {
        id
      }
    }
  }
`)
