import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { TransactionStatus, useSendTransaction } from '@usedapp/core'
import { BigNumber, Contract, FixedNumber, Signer, utils } from 'ethers'
import {
  Address,
  Auction,
  Currency,
  ElectRollover,
  RevealTenderRequest,
  RolloverAuctionInfo,
  SubmittedRollover,
} from '../model'
import { useCallback, useEffect, useMemo } from 'react'
import { PagePortfolioQuery, TermRepo } from '../../gql/graphql'
import {
  convertChainId,
  getABIVersion,
  graphResultToAuction,
  graphResultToRolloverAuction,
} from '../../helpers/conversions'
import { fixedToBigNumber } from '../../helpers/conversions'
import { generateTermAuth } from '../auth'

import { TermRepoRolloverManager as TermRepoRolloverManager_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermRepoRolloverManager'
import { TermRepoRolloverManager as TermRepoRolloverManager_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermRepoRolloverManager'
import { TermRepoRolloverManager as TermRepoRolloverManager_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermRepoRolloverManager'
import { TermRepoRolloverElectionSubmissionStruct as TermRepoRolloverElectionSubmissionStruct_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermRepoRolloverManager'
import { TermRepoRolloverElectionSubmissionStruct as TermRepoRolloverElectionSubmissionStruct_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermRepoRolloverManager'
import { TermRepoRolloverElectionSubmissionStruct as TermRepoRolloverElectionSubmissionStruct_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermRepoRolloverManager'
import TermRolloverManagerABI_0_2_4 from '../../abi/v0.2.4/TermRepoRolloverManager.json'
import TermRolloverManagerABI_0_4_1 from '../../abi/v0.4.1/TermRepoRolloverManager.json'
import TermRolloverManagerABI_0_6_0 from '../../abi/v0.6.0/TermRepoRolloverManager.json'

import { useCurrentTime, useCurrentTimeSlow } from './helper-hooks'
import { waitForStatus, waitForSubgraphIndexing } from '../../helpers/wait'
import { retryUntil } from '../../helpers/retry'
import {
  createOrUpdatePrivateRollover,
  removePrivateRollover,
} from '../../services/private-rollovers'
import { useStorage } from '../../providers/storage'
import { useConfig } from '../../providers/config'

export type TermRepoRolloverManager =
  | TermRepoRolloverManager_0_2_4
  | TermRepoRolloverManager_0_4_1
  | TermRepoRolloverManager_0_6_0

export type RolloverManagerAddress = {
  repoRolloverManager: Address
  version: string
}

export function useManageRollovers(
  rolloverManagers:
    | { [chainId: string]: RolloverManagerAddress[] | undefined }
    | undefined,
  currencies:
    | { [chainId: string]: { [address: Address]: Currency } | undefined }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  data: Record<string, PagePortfolioQuery> | undefined,
  readFromSubgraph: () => void
): {
  // get rollover auctions
  rolloverDetails:
    | { [rolloverManagerAddress: Address]: RolloverAuctionInfo[] }
    | undefined
  // get assigned rollovers
  rolloverAssignments:
    | {
        [termId: string]: Auction & {
          rolloverManagerAddress: string
          purchaseCurrencySymbol: string
          collateralCurrencySymbol: string
        }
      }
    | undefined
  // create new rollover
  electRollover?: (
    chainId: string,
    rolloverManagerAddress: Address,
    electedRollover: ElectRollover
  ) => Promise<void>
  // cancel
  cancelRollover?: (
    chainId: number,
    rolloverManagerAddress: Address,
    termId: string
  ) => Promise<void>
} {
  const transactionStates = useMemo(
    () =>
      ({}) as {
        electRollovers: TransactionStatus | undefined
        cancelRollover: TransactionStatus | undefined
      },
    []
  )
  const { storage: localStorage } = useStorage()

  const config = useConfig()
  const chainConfig = useMemo(() => config.chains, [config])

  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return (
        activeNetwork?.chainId &&
        activeNetwork.chainId.toString() === chainId.toString()
      )
    },
    [signer]
  )

  const {
    sendTransaction: cancelRolloverTransaction,
    state: cancelRolloverState,
    resetState: resetCancelRolloverState,
  } = useSendTransaction()

  const {
    sendTransaction: electRolloversTransaction,
    state: electRolloversState,
    resetState: resetElectRolloversState,
  } = useSendTransaction()

  useEffect(() => {
    transactionStates.electRollovers = electRolloversState
  }, [electRolloversState, transactionStates])
  useEffect(() => {
    transactionStates.cancelRollover = cancelRolloverState
  }, [cancelRolloverState, transactionStates])

  const currentTime = useCurrentTime()
  const currentTimeSlow = useCurrentTimeSlow()

  const rolloverAuctionsMapped = useMemo(() => {
    const allRolloverAuctions: (RolloverAuctionInfo | undefined)[] = []

    if (data) {
      Object.entries(data).forEach(([chainId, pagePortfolioQuery]) => {
        const termRepos = pagePortfolioQuery.termRepos

        const rolloverAuctions = termRepos?.flatMap((result) =>
          graphResultToRolloverAuction(
            chainId,
            result as Partial<TermRepo>,
            currencies?.[chainId],
            currentTimeSlow
          )
        )

        if (rolloverAuctions) {
          allRolloverAuctions.push(...rolloverAuctions)
        }
      })
    }
    return allRolloverAuctions
  }, [data, currencies, currentTimeSlow])

  const rolloverDetails = useMemo(() => {
    if (!rolloverAuctionsMapped) {
      return undefined
    }
    const auctions = {} as {
      [rolloverManagerAddress: Address]: RolloverAuctionInfo[]
    }
    for (const rolloverAuction of rolloverAuctionsMapped) {
      if (rolloverAuction) {
        const rolloverManagerAddress = rolloverAuction.rolloverManagerAddress
        if (!auctions[rolloverManagerAddress]) {
          auctions[rolloverManagerAddress] = [] // Initialize the array if it doesn't exist
        }
        auctions[rolloverManagerAddress].push(rolloverAuction)
      }
    }
    return auctions
  }, [rolloverAuctionsMapped])

  const rolloverAuctionsByBidLocker = useMemo(() => {
    if (!data || !data?.pagePortfolioQuery?.termRepos) {
      return undefined
    }
    const auctions = {} as {
      [chainId: string]: {
        [bidLocker: string]: Auction & {
          rolloverManagerAddress: string
          purchaseCurrencySymbol: string
          collateralCurrencySymbol: string
        }
      }
    }

    Object.entries(data).forEach(([chainId, pagePortfolioQuery]) => {
      for (const termRepo of pagePortfolioQuery?.termRepos) {
        if (!termRepo || !termRepo?.approvedRolloverTermAuctions) {
          continue
        }
        for (const termAuction of termRepo?.approvedRolloverTermAuctions) {
          if (termAuction.auctionCancelled) {
            continue
          }
          const auctionResult = graphResultToAuction(chainId, termAuction)

          if (!auctionResult) {
            continue
          }
          if (
            !currencies?.[chainId]?.[termRepo.purchaseToken]?.symbol ||
            !currencies?.[chainId]?.[termRepo.collateralTokens?.[0]]?.symbol
          ) {
            continue
          }
          auctions[chainId][auctionResult.bidLockerAddress] = {
            ...auctionResult,
            rolloverManagerAddress: termRepo.termRepoRolloverManager,
            purchaseCurrencySymbol:
              currencies?.[chainId]?.[termRepo.purchaseToken]?.symbol,
            collateralCurrencySymbol:
              currencies?.[chainId]?.[termRepo.collateralTokens?.[0]]?.symbol,
          }
        }
      }
    })

    return auctions
  }, [currencies, data])

  const rolloverAssignments = useMemo(() => {
    const assignments = {} as {
      [termId: string]: Auction & {
        rolloverManagerAddress: string
        purchaseCurrencySymbol: string
        collateralCurrencySymbol: string
      }
    }

    if (!data) {
      return undefined
    }

    Object.entries(data).forEach(([chainId, pagePortfolioQuery]) => {
      for (const instruction of pagePortfolioQuery.termRolloverInstructions) {
        if (!instruction) {
          continue
        }
        const auction =
          rolloverAuctionsByBidLocker?.[chainId][instruction.rolloverAuction]
        if (!auction) {
          continue
        }
        assignments[instruction.oldTerm.id] = auction
      }
    })
    return assignments
  }, [data, rolloverAuctionsByBidLocker])

  const rolloverManagerContracts = useMemo(() => {
    const contracts: {
      [chainId: string]: {
        [rolloverManagerAddress: Address]: {
          manager: TermRepoRolloverManager
          version: string
        }
      }
    } = {}

    if (rolloverManagers) {
      for (const [chainId, managers] of Object.entries(rolloverManagers)) {
        if (!managers) continue

        contracts[chainId] = {}

        for (const rolloverManager of managers) {
          const rolloverManagerAddress = rolloverManager.repoRolloverManager

          if (!rolloverManager || contracts[chainId][rolloverManagerAddress]) {
            continue
          }

          const tmpContractData = {} as {
            manager: TermRepoRolloverManager
            version: string
          }

          const version = getABIVersion(rolloverManager.version)

          tmpContractData.version = version

          switch (version) {
            case '0.2.4':
              tmpContractData.version = '0.2.4'
              tmpContractData.manager = new Contract(
                rolloverManagerAddress,
                TermRolloverManagerABI_0_2_4,
                provider
              ) as TermRepoRolloverManager_0_2_4
              break
            case '0.4.1':
              tmpContractData.manager = new Contract(
                rolloverManagerAddress,
                TermRolloverManagerABI_0_4_1,
                provider
              ) as TermRepoRolloverManager_0_4_1
              break
            case '0.6.0':
            default:
              tmpContractData.manager = new Contract(
                rolloverManagerAddress,
                TermRolloverManagerABI_0_6_0,
                provider
              ) as TermRepoRolloverManager_0_6_0
              break
          }

          contracts[chainId][rolloverManagerAddress] = tmpContractData
        }
      }
    }

    return contracts
  }, [rolloverManagers, provider])

  const revealTender = useCallback(
    async (
      chainId: string,
      termId: string,
      auctionId: string,
      id: string,
      amount: FixedNumber,
      nonce: BigNumber
    ) => {
      const revealServerUrl = chainConfig?.[chainId]?.revealServerUrl

      if (!revealServerUrl) {
        return
      }
      await fetch(`${revealServerUrl}/reveal-bid`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: JSON.stringify({
          id,
          termId,
          auctionId,
          price: fixedToBigNumber(amount).div(100).toString(),
          nonce: nonce.toString(),
        } as RevealTenderRequest),
      })
    },
    [chainConfig]
  )

  const electRollover = useCallback(
    async (
      chainId: string,
      rolloverManagerAddress: Address,
      electedRollover: ElectRollover
    ) => {
      if (!signer) {
        return
      }

      const parsedChainId = convertChainId(chainId)
      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const rolloverManager =
        rolloverManagerContracts?.[chainId]?.[rolloverManagerAddress]?.manager
      const contractVersion =
        rolloverManagerContracts?.[chainId]?.[rolloverManagerAddress]?.version

      let termRepoRolloverElectionStruct: any
      let data: string

      switch (contractVersion) {
        case '0.6.0':
          console.info('electing rollover using 0.6.0 abi')
          termRepoRolloverElectionStruct = {
            rolloverAmount: electedRollover.rolloverAmount,
            rolloverBidPriceHash: electedRollover.rolloverBidPriceHash,
            rolloverAuctionBidLocker: electedRollover.rolloverAuction,
          } as TermRepoRolloverElectionSubmissionStruct_0_6_0

          data = (
            rolloverManager as TermRepoRolloverManager_0_6_0
          ).interface.encodeFunctionData('electRollover', [
            termRepoRolloverElectionStruct,
          ])

          break
        case '0.4.1':
          console.info('electing rollover using 0.4.1 abi')
          termRepoRolloverElectionStruct = {
            rolloverAmount: electedRollover.rolloverAmount,
            rolloverBidPriceHash: electedRollover.rolloverBidPriceHash,
            rolloverAuction: electedRollover.rolloverAuction,
          } as TermRepoRolloverElectionSubmissionStruct_0_4_1

          data = (
            rolloverManager as TermRepoRolloverManager_0_4_1
          ).interface.encodeFunctionData('electRollover', [
            termRepoRolloverElectionStruct,
          ])

          break
        case '0.2.4':
        default:
          console.info('electing rollover using 0.2.4 abi')
          termRepoRolloverElectionStruct = {
            rolloverAmount: electedRollover.rolloverAmount,
            rolloverBidPriceHash: electedRollover.rolloverBidPriceHash,
            rolloverAuction: electedRollover.rolloverAuction,
          } as TermRepoRolloverElectionSubmissionStruct_0_2_4

          data = (
            rolloverManager as TermRepoRolloverManager_0_2_4
          ).interface.encodeFunctionData('electRollover', [
            termRepoRolloverElectionStruct,
            await generateTermAuth(
              signer,
              rolloverManager as TermRepoRolloverManager_0_2_4,
              'electRollover',
              [termRepoRolloverElectionStruct],
              currentTime.unix()
            ),
          ])
      }

      const id = utils.solidityKeccak256(
        ['address', 'address'],
        [rolloverManagerAddress, await signer.getAddress()]
      )

      await retryUntil(
        () =>
          revealTender(
            electedRollover.chainId,
            electedRollover.termId,
            electedRollover.auctionId,
            id,
            electedRollover.revealedPrice,
            electedRollover.nonce
          ),
        100,
        1000
      )

      await electRolloversTransaction({
        chainId: parsedChainId,
        to: rolloverManagerAddress,
        from: await signer.getAddress(),
        data,
      })

      // Wait for state of lock transaction to complete.
      await waitForStatus(
        async () => transactionStates.electRollovers,
        resetElectRolloversState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        rolloverManager
      )

      const submitted: SubmittedRollover = {
        chainId,
        id: electedRollover.termId,
        borrower: await signer.getAddress(),
        interestRate: electedRollover.revealedPrice,
      }

      await createOrUpdatePrivateRollover(submitted, localStorage)

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.electRollovers?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(chainId, minedBlockNumber, 5, 1000)
      }
      readFromSubgraph()
    },
    [
      signer,
      validateActiveNetwork,
      rolloverManagerContracts,
      electRolloversTransaction,
      resetElectRolloversState,
      localStorage,
      readFromSubgraph,
      currentTime,
      revealTender,
      transactionStates.electRollovers,
    ]
  )

  const cancelRollover = useCallback(
    async (
      chainId: number,
      rolloverManagerAddress: Address,
      termId: string
    ) => {
      if (!signer) {
        return
      }

      if (!(await validateActiveNetwork(chainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const rolloverManager =
        rolloverManagerContracts?.[chainId.toString()]?.[rolloverManagerAddress]
          ?.manager
      const version =
        rolloverManagerContracts?.[chainId.toString()]?.[rolloverManagerAddress]
          ?.version
      let data: string

      switch (version) {
        case '0.6.0':
          console.info('cancelling rollover using 0.6.0 abi')
          data = (
            rolloverManager as TermRepoRolloverManager_0_6_0
          ).interface.encodeFunctionData('cancelRollover')
          break
        case '0.4.1':
          console.info('cancelling rollover using 0.4.1 abi')
          data = (
            rolloverManager as TermRepoRolloverManager_0_4_1
          ).interface.encodeFunctionData('cancelRollover')
          break
        case '0.2.4':
        default:
          console.info('cancelling rollover using 0.2.4 abi')
          data = (
            rolloverManager as TermRepoRolloverManager_0_2_4
          ).interface.encodeFunctionData('cancelRollover', [
            await generateTermAuth(
              signer,
              rolloverManager as TermRepoRolloverManager_0_2_4,
              'cancelRollover',
              [],
              currentTime.unix()
            ),
          ])
      }

      await cancelRolloverTransaction({
        chainId,
        to: rolloverManagerAddress,
        from: await signer.getAddress(),
        data,
      })

      // Wait for state of lock transaction to complete.
      await waitForStatus(
        async () => transactionStates.cancelRollover,
        resetCancelRolloverState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        rolloverManager
      )

      const result = transactionStates?.cancelRollover?.status ?? 'None'
      // catch transaction failed
      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }

      await removePrivateRollover(termId, localStorage)

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.cancelRollover?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(
          chainId.toString(),
          minedBlockNumber,
          5,
          1000
        )
      }
      readFromSubgraph()
    },
    [
      signer,
      validateActiveNetwork,
      rolloverManagerContracts,
      cancelRolloverTransaction,
      resetCancelRolloverState,
      transactionStates.cancelRollover,
      readFromSubgraph,
      currentTime,
      localStorage,
    ]
  )

  return {
    rolloverDetails,
    rolloverAssignments,
    electRollover,
    cancelRollover,
  }
}
