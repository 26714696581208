import { Box, HStack, Text, Th } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InviteTableCols } from '../../utils'
export const TableHeaderCell = ({
  column,
  sortColumn,
  sortDirection,
  children,
  xsPadding,
  textRight,
  width,
  handleClick,
}: {
  column: InviteTableCols
  sortColumn?: string | null
  sortDirection?: boolean
  children: React.ReactNode
  xsPadding?: boolean
  textRight?: boolean
  width?: string | number
  handleClick?: (column: InviteTableCols) => void
}) => (
  <Th
    onClick={() => handleClick && handleClick(column)}
    textTransform="capitalize"
    paddingLeft={2}
    paddingRight={xsPadding ? 2.5 : 16}
    py={1}
    borderBottom="solid 1px"
    borderColor="rgba(0, 16, 39, 0.10)"
    w={width}
  >
    <HStack justify={textRight ? 'flex-end' : undefined}>
      <Text variant="body-xs/medium" color="blue.9">
        {children}
      </Text>
      {column !== 'code' && (
        <Box
          color={sortColumn === column ? 'blue.9' : 'gray.4'}
          as="span"
          transform={
            sortColumn === column
              ? sortDirection
                ? 'rotate(180deg)'
                : 'rotate(0deg)'
              : undefined
          }
          transition="transform 0.2s"
        >
          <FontAwesomeIcon
            icon={['far', 'chevron-down']}
            role="button"
            width="10px"
          />
        </Box>
      )}
    </HStack>
  </Th>
)
