import {
  Text,
  FormControlProps,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverAnchor,
  Grid,
  GridItem,
  Divider,
  Image,
  Link,
} from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RolloverAuctionInfo } from '../../../../data/model'
import high_ltv_icon from '../../../../assets/icons/high-ltv.svg'
import {
  formatMaturityDate,
  getAuctionDisplayId,
  hasHighLTVLabel,
} from '../../../../helpers/utils'
import Tooltip from '../../../../components/elements/Tooltip'
import AuctionTermBadge from '../../../../components/elements/AuctionTermBadge'
import { divide, multiply } from '../../../../helpers/math'
import { FixedNumber } from 'ethers'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'
import { Fragment } from 'react'
import { Trans, t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

function HighLTVBadge({
  ltvRatio,
  maintenanceCollateralRatio,
}: {
  ltvRatio: FixedNumber
  maintenanceCollateralRatio: FixedNumber
}) {
  const ltvPercentageValue = ltvRatio
    ? multiply(ltvRatio, FixedNumber.fromString('100', ltvRatio.format))
    : FixedNumber.fromString('0', 18)
  const { formattedPercentage: formattedLTVPercentage } =
    fixedToFormattedPercentage(ltvPercentageValue, 0, true)

  const collatPercentageValue = maintenanceCollateralRatio
    ? multiply(
        maintenanceCollateralRatio,
        FixedNumber.fromString('100', maintenanceCollateralRatio.format)
      )
    : FixedNumber.fromString('0', 18)
  const { formattedPercentage: formattedCollatPercentage } =
    fixedToFormattedPercentage(collatPercentageValue, 0, true)

  return (
    <Tooltip
      label={
        <VStack alignItems="flex-start" w="full" color="white">
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/medium" color="white">
              <Trans>LTV (Loan-To-Value) Ratio:</Trans>
            </Text>
            <Text as="span" variant="body-sm/medium" color="white">
              {formattedLTVPercentage}%
            </Text>
          </HStack>
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/medium" color="white">
              <Trans>Maintenance Collateral Ratio:</Trans>
            </Text>
            <Text as="span" variant="body-sm/medium" color="white">
              ~{formattedCollatPercentage}%
            </Text>
          </HStack>
        </VStack>
      }
    >
      <Image src={high_ltv_icon} w={3} h={3} />
    </Tooltip>
  )
}

export default function BorrowerRolloverAuctionField({
  value,
  onChange,
  disabled,
  w,
  purchaseCurrencySymbol,
  collateralCurrencySymbol,

  auctions,
}: {
  value?: RolloverAuctionInfo
  onChange: (value: RolloverAuctionInfo) => void
  disabled?: boolean
  w?: FormControlProps['w']
  purchaseCurrencySymbol: string
  collateralCurrencySymbol: string

  auctions: RolloverAuctionInfo[]
}) {
  const { i18n } = useLingui()

  const headings = [
    i18n._(t`New Maturity`),
    i18n._(t`New Term`),
    i18n._(t`Auction ID`),
  ]

  return (
    <Popover placement="bottom-end">
      {({ onClose }) => (
        <>
          <PopoverAnchor>
            <FormControl as="fieldset" w={w} flexShrink={0} mb={5} mt={5}>
              <VStack spacing={2} align="start">
                <HStack justify="space-between" alignSelf="stretch">
                  <FormLabel
                    as="legend"
                    variant="create-tender"
                    mb={0}
                    color="gray.700"
                    fontSize="sm"
                    fontWeight="500"
                    lineHeight="5"
                  >
                    <Trans>Rollover Auction</Trans>{' '}
                    <Tooltip
                      label={
                        <Text>
                          <Trans>
                            A list of auctions that are eligible for rollover.
                            All of the auctions make loans in{' '}
                            {purchaseCurrencySymbol} and accept{' '}
                            {collateralCurrencySymbol} as collateral.
                          </Trans>
                        </Text>
                      }
                      aria-label={t`rollover-tooltip`}
                    />
                  </FormLabel>
                </HStack>
                {auctions.length !== 0 ? (
                  <PopoverTrigger>
                    <InputGroup w="100%">
                      <Input
                        value={
                          value
                            ? getAuctionDisplayId({
                                auctionEndTimestamp: value.auctionEndTimestamp,
                                maturityTimestamp: value.maturityTimestamp,
                                termSymbol: value.purchaseCurrencySymbol,
                                collateralSymbol:
                                  value.collateralCurrencySymbol,
                              })
                            : undefined
                        }
                        type="text"
                        variant="flushed"
                        isDisabled={disabled}
                        placeholder={t`Select an auction`}
                        color="blue.9"
                        _placeholder={{
                          color: 'blue.9',
                        }}
                        fontWeight="400"
                        lineHeight="5"
                        pl={1}
                        cursor="pointer"
                      />
                      <InputRightElement
                        width="auto"
                        color="blue.9"
                        fontSize="sm"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'chevron-down']}
                          role="button"
                          style={{
                            marginRight: '4px',
                          }}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </PopoverTrigger>
                ) : (
                  <InputGroup w="100%">
                    <Input
                      value={undefined}
                      type="text"
                      variant="flushed"
                      isDisabled={true}
                      placeholder={t`No eligible auctions available`}
                      color="blue.9"
                      _placeholder={{
                        color: 'blue.9',
                      }}
                      fontWeight="400"
                      lineHeight="5"
                      pl={1}
                      cursor="pointer"
                    />
                  </InputGroup>
                )}
              </VStack>
            </FormControl>
          </PopoverAnchor>
          <PopoverContent
            minW={{
              base: '100%',
              md: '508px',
            }}
          >
            <Grid templateColumns="1fr 1fr 1fr">
              {headings.map((heading) => (
                <GridItem color="blue.9" p="8px" key={heading}>
                  <Text as="span" variant="body-xs/medium" color="blue.9">
                    {heading}
                  </Text>
                </GridItem>
              ))}
              {auctions.map((auction) => (
                <Fragment key={auction.id}>
                  <GridItem colSpan={3}>
                    <Divider />
                  </GridItem>
                  <GridItem
                    cursor="pointer"
                    onClick={() => {
                      onClose()
                      onChange(auction)
                    }}
                  >
                    <HStack height="100%" ml="8px">
                      <Text
                        as="span"
                        variant="body-xs/semibold"
                        color="blue.9"
                        whiteSpace="nowrap"
                      >
                        {formatMaturityDate(auction.maturityTimestamp)}
                      </Text>
                    </HStack>
                  </GridItem>
                  <GridItem
                    p={2}
                    cursor="pointer"
                    onClick={() => {
                      onClose()
                      onChange(auction)
                    }}
                  >
                    <HStack>
                      <AuctionTermBadge auction={auction} isSmall />
                      {auction.marginRequirement &&
                        hasHighLTVLabel(
                          auction.purchaseCurrencySymbol,
                          auction.collateralCurrencySymbol,
                          auction.marginRequirement
                        ) && (
                          <HighLTVBadge
                            ltvRatio={divide(
                              FixedNumber.fromString(
                                '1',
                                auction.marginRequirement?.format
                              ),
                              auction.marginRequirement
                            )}
                            maintenanceCollateralRatio={
                              auction.marginRequirement
                            }
                          />
                        )}
                    </HStack>
                  </GridItem>
                  <GridItem p={2}>
                    <HStack height="100%" mr={16}>
                      <Text
                        as="span"
                        variant="body-xs/normal"
                        color="blue.9"
                        whiteSpace="nowrap"
                      >
                        {auction
                          ? getAuctionDisplayId({
                              auctionEndTimestamp: auction.auctionEndTimestamp,
                              maturityTimestamp: auction.maturityTimestamp,
                              termSymbol: auction.purchaseCurrencySymbol,
                              collateralSymbol:
                                auction.collateralCurrencySymbol,
                            })
                          : undefined}
                      </Text>
                      <Link
                        href={`/auctions/${auction.auction}/${auction.chainId}`}
                        isExternal
                        color="gray.3"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'arrow-up-right']}
                          width="8px"
                          height="8px"
                        />
                      </Link>
                    </HStack>
                  </GridItem>
                </Fragment>
              ))}
            </Grid>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
