import { Button, Image, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import IconButton from '../../../../components/elements/IconButton'
import { ImageTextMenuItem } from '../OpenPositionsSection/BorrowMorePopout'
import link from '../../../../assets/icons/link.svg'
import { t } from '@lingui/macro'

export default function HistoryPopout({
  onContractAddress,
}: {
  onContractAddress?: () => void
}) {
  return (
    <Menu
      computePositionOnMount={false}
      placement="bottom-end"
      strategy="fixed"
    >
      <MenuButton as={Button} variant="link" px="0" display="inline">
        <IconButton hasShadow />
      </MenuButton>
      <MenuList mr={4}>
        <ImageTextMenuItem
          icon={<Image src={link} mr="6px" boxSize="16px" />}
          text={t`View Contract Addresses`}
          onClick={onContractAddress}
          isDisabled={!onContractAddress}
        />
      </MenuList>
    </Menu>
  )
}
