import { Tag, TagLabel } from '@chakra-ui/react'
import Tooltip from '../Tooltip'

export default function Chip({
  children,
  size = 'sm',
  colorScheme = 'gray.6',
  variant = 'solid',
  fontWeight = '500',
  bg = 'gray.2',
  tooltip = undefined,
}: {
  children: React.ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg'
  colorScheme?: string
  variant?: string
  fontWeight?: string
  bg?: string
  tooltip?: string
}) {
  return (
    <Tooltip
      leftAlign
      noDelay
      label={tooltip}
      isDisabled={!tooltip}
      placement="bottom"
    >
      <Tag
        size={size}
        bg={bg}
        color={colorScheme}
        variant={variant}
        w="max-content"
        minWidth="auto"
      >
        <TagLabel fontWeight={fontWeight}>{children}</TagLabel>
      </Tag>
    </Tooltip>
  )
}
