import { Badge, Text } from '@chakra-ui/react'
import { HStack } from '../../../components/elements/Stack'

export default function OpenTendersBadge({ count = 0 }: { count: number }) {
  return (
    <HStack mt={4} mb={3}>
      <Text as="span" color="blue.9" variant="body-l/semibold">
        Open Tenders
      </Text>
      <Badge
        borderWidth={0}
        bg="gray.2"
        borderRadius="md"
        px={1.5}
        py={0.5}
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text as="span" variant="body-xs/medium" color="gray.6">
          {count}
        </Text>
      </Badge>
    </HStack>
  )
}
