import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CheckboxGroup,
  Flex,
  Heading,
  Image,
  Text,
  useCheckbox,
  useCheckboxGroup,
} from '@chakra-ui/react'
import { Stack } from '../../../elements/Stack'
import { filter, fromPairs, map, mapValues, toPairs } from 'lodash'
import { useMemo } from 'react'
import {
  AuctionFilter,
  AuctionFilterGroup,
  AuctionFilterGroupName,
} from '../../../../providers/filters'
import tick_icon from '../../../../assets/icons/tick.svg'

export interface CheckboxOption<G extends AuctionFilterGroupName> {
  value: AuctionFilter<G>
  label: string
  icon: JSX.Element
  resultCount: number
}

export default function Filter<G extends AuctionFilterGroupName>({
  title,
  options,
  value,
  disabled,
  onChange,
}: // ,
{
  title: string
  options: CheckboxOption<G>[]
  value: AuctionFilterGroup<G>
  disabled: boolean
  onChange: (value: AuctionFilterGroup<G>) => void
}) {
  if (title === 'Loan Term') {
    options.sort((a, b) => {
      const termToNumber: Record<string, number> = {
        '1 week': 1,
        '2 week': 2,
        '4 week': 4,
        '8 week': 8,
        '13 week': 13,
        '26 week': 26,
        '52 week': 52,
      }
      const termA = termToNumber[a.label]
      const termB = termToNumber[b.label]

      return termA - termB
    })
  }

  if (title === 'Supply / Borrow' || title === 'Collateral') {
    options.sort((a, b) => {
      const auctionsComparison = b.resultCount - a.resultCount

      if (auctionsComparison === 0) {
        const labelA = a.label || ''
        const labelB = b.label || ''
        return labelA.localeCompare(labelB)
      }

      return auctionsComparison
    })
  }

  if (title === 'Auction Status') {
    const statusOrder = ['Clearing', 'Live', 'Upcoming', 'Closed']

    options.sort((a, b) => {
      const statusA = a.label
      const statusB = b.label

      return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB)
    })
  }

  const checkboxGroupValue = useMemo(
    () => map(filter(toPairs(value), 1), 0),
    [value]
  )
  const { getCheckboxProps } = useCheckboxGroup({
    // defaultValue: value,
    value: checkboxGroupValue,
    isDisabled: disabled,
    onChange: (arr) => {
      const updated: AuctionFilterGroup<G> = {
        ...mapValues(value, (v, k) => arr.includes(k)),
        ...fromPairs(arr.map((f) => [f, true])),
      } as AuctionFilterGroup<G>
      onChange(updated)
    },
  })

  function CustomCheckbox(props: any) {
    const { state, getInputProps, getLabelProps } = useCheckbox(props)
    const { option } = props

    return (
      <Flex
        as="label"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        data-cy={`filter-${option.value}`}
      >
        <input {...getInputProps()} disabled={disabled} hidden />
        <Flex alignItems="center" {...getLabelProps()}>
          {option.icon}
          <Text ml="8px" variant="body-sm/medium" color="gray.6">
            {option.label}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Text color="gray.5" variant="body-sm/normal" mr="8px">
            {option.resultCount}
          </Text>
          <Flex
            alignItems="center"
            justifyContent="center"
            border="2px solid"
            borderColor={
              disabled ? 'gray.2' : state.isChecked ? 'blue.2' : 'gray.2'
            }
            borderRadius="2px"
            bg={disabled ? 'gray.2' : state.isChecked ? 'blue.2' : 'white'}
            transition="all 0.3s ease"
            w={4}
            h={4}
            {...props}
          >
            <Image
              w={2.5}
              h={2.5}
              src={tick_icon}
              alt="tick"
              opacity={state.isChecked ? 1 : 0}
              transition="opacity 0.3s ease"
            />
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <AccordionItem
      border="0px"
      data-cy={`filters-${title.split(' ').join('-').toLowerCase()}`}
    >
      <Heading as="h5">
        <AccordionButton
          data-cy={`filters-${title.split(' ').join('-').toLowerCase()}`}
          px="0px"
          _hover={{
            background: 'transparent',
          }}
          py={0}
        >
          <Box flex="1" textAlign="left">
            <Text as="span" variant="body-sm/semibold" color="blue.9">
              {title}
            </Text>
          </Box>
          <Text as="span" color="gray.5" variant="body-sm/bold">
            {options?.length ?? '0'}
          </Text>
          <AccordionIcon w="16px" h="16px" ml="4px" />
        </AccordionButton>
      </Heading>
      <AccordionPanel px="0px">
        <CheckboxGroup>
          <Stack>
            {options
              .filter((option) => !!option.value)
              .map((option) => (
                <CustomCheckbox
                  key={option.value}
                  option={option}
                  {...getCheckboxProps({ value: option.value })}
                />
              ))}
          </Stack>
        </CheckboxGroup>
      </AccordionPanel>
    </AccordionItem>
  )
}
