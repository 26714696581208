import { Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import Chip from '../../../../components/elements/Chip'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import Tooltip from '../../../../components/elements/Tooltip'
import { formatFixedToken } from '../../../../helpers/utils'
import { Trans, t } from '@lingui/macro'

export default function RepayLoanOverview({
  maxRepayment,

  outstandingDebt,
  rolloverAmount,
  rolloverFailed,
  purchaseSymbol,
  auctionLabel,
}: {
  outstandingDebt: FixedNumber
  maxRepayment: FixedNumber
  rolloverAmount?: FixedNumber
  rolloverFailed?: boolean
  purchaseSymbol: string

  auctionLabel: string
}) {
  return (
    <StatsCard>
      <StatsTitle value={auctionLabel}>Loan Details</StatsTitle>

      <StatsRow title={t`Outstanding Debt`}>
        <Text variant="body-sm/normal" as="span" color="blue.9">
          {formatFixedToken(outstandingDebt, purchaseSymbol, true)}
        </Text>
      </StatsRow>
      <StatsRow title={`Rollover Amount`}>
        <Text
          variant="body-sm/normal"
          as="span"
          color="blue.9"
          textDecoration={rolloverFailed ? 'line-through!important' : undefined}
        >
          {formatFixedToken(
            rolloverAmount ||
              FixedNumber.fromString('0', outstandingDebt.format),
            purchaseSymbol,
            true,
            true
          )}
        </Text>
      </StatsRow>
      {/* <StatsRow title="Repay Using">
        <Select
          size="sm"
          h={5}
          py={0.5}
          pb={1}
          borderColor="transparent"
          fontWeight="semibold"
          borderRadius="md"
          color="gray.6"
          bg="gray.2"
          fontSize="12px"
        >
          <option value={purchaseSymbol}>{purchaseSymbol}</option>
          <option value="termRepo" disabled>
            Term Repo Tokens
          </option>
        </Select>
      </StatsRow> */}
      <StatsRow
        title={
          <>
            <b>
              <Trans>Due Now</Trans>
            </b>{' '}
            &nbsp;
            <Chip>{purchaseSymbol}</Chip>
            &nbsp;
            <Tooltip
              label={
                <Text>
                  <Trans>
                    Please note, any debt that is scheduled to rollover cannot
                    be repaid.
                  </Trans>
                </Text>
              }
              aria-label="rollover-tooltip"
            />
          </>
        }
      >
        <Text variant="body-sm/semibold" as="span" color="blue.9">
          {rolloverAmount ? (
            <>{formatFixedToken(maxRepayment, purchaseSymbol, true)}</>
          ) : (
            formatFixedToken(outstandingDebt, purchaseSymbol, true)
          )}
        </Text>
      </StatsRow>
    </StatsCard>
  )
}
