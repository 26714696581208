import useSWRMutation from 'swr/mutation'
import {
  deleteProfileData,
  deleteProfileTwitter,
  deleteProfileDiscord,
  deleteProfileWallet,
} from '../../../helpers/swr'

export function useDeleteProfileData(onSuccessCallback?: () => void): {
  isLoading: boolean
  trigger: (token: string) => void
  error?: any
} {
  const { error, isMutating, trigger } = useSWRMutation(
    `/profile`,
    deleteProfileData,
    {
      throwOnError: false,
      onSuccess: () => {
        onSuccessCallback && onSuccessCallback()
      },
    }
  )

  return {
    isLoading: isMutating,
    trigger,
    error,
  }
}

export function useRemoveTwitterFromProfile(onSuccessCallback?: () => void): {
  isValid: boolean
  isLoading: boolean
  trigger: (token: string) => void
  error?: any
} {
  const { error, isMutating, trigger } = useSWRMutation(
    `/profile/twitter`,
    deleteProfileTwitter,
    {
      throwOnError: false,
      onSuccess: () => {
        onSuccessCallback && onSuccessCallback()
      },
    }
  )

  return {
    isValid: !error,
    isLoading: isMutating,
    trigger,
    error,
  }
}

export function useRemoveDiscordFromProfile(onSuccessCallback?: () => void): {
  isValid: boolean
  isLoading: boolean
  trigger: (token: string) => void
  error?: any
} {
  const { error, isMutating, trigger } = useSWRMutation(
    `/profile/discord`,
    deleteProfileDiscord,
    {
      throwOnError: false,
      onSuccess: () => {
        onSuccessCallback && onSuccessCallback()
      },
    }
  )

  return {
    isValid: !error,
    isLoading: isMutating,
    trigger,
    error,
  }
}

export function useRemoveWalletFromProfile(onSuccessCallback?: () => void): {
  isValid: boolean
  isLoading: boolean
  trigger: (token: string, walletAddress: string) => void
  error?: any
} {
  const {
    error,
    isMutating,
    trigger: rawTrigger,
  } = useSWRMutation(`/profile/wallet`, deleteProfileWallet, {
    throwOnError: false,
    onSuccess: () => {
      onSuccessCallback && onSuccessCallback()
    },
  })

  const trigger = (token: string, walletAddress: string) => {
    rawTrigger({ token, walletAddress })
  }

  return {
    isValid: !error,
    isLoading: isMutating,
    trigger,
    error,
  }
}
