import {
  Box,
  Th as CTh,
  StackProps,
  TableColumnHeaderProps,
  Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Stack } from '../../../../components/elements/Stack'

export function PortfolioTableHead({
  compact,
  sub,
  children,
  leftBorder,
  rightBorder,
  justifyChildren = 'flex-start',
  addSubTextMargin,
  spacing = '8px',
  ...props
}: {
  compact?: boolean
  sub?: ReactNode
  leftBorder?: boolean
  rightBorder?: boolean
  justifyChildren?: TableColumnHeaderProps['justifyContent']
  addSubTextMargin?: boolean
  spacing?: StackProps['spacing']
} & TableColumnHeaderProps) {
  return (
    <CTh
      color="blue.9"
      fontWeight="500"
      fontFamily="Inter"
      textTransform="none"
      lineHeight="4"
      letterSpacing="normal"
      verticalAlign="top"
      borderBottom="none"
      borderLeft={leftBorder && !sub ? '1px solid' : 'none'}
      borderRight={rightBorder && !sub ? '1px solid' : 'none'}
      borderLeftColor="gray.2"
      borderRightColor="gray.2"
      p={0}
      {...props}
    >
      {/* 32px - 1rem is because of the p={2} above */}
      <Stack justifyContent="stretch" spacing={spacing} mx="-.5px" mb="-2px">
        {!compact && (
          <Box
            flexGrow={1}
            h="full"
            display="flex"
            alignItems="center"
            whiteSpace="nowrap"
            justifyContent={justifyChildren}
          >
            {children ?? <>&nbsp;</>}
          </Box>
        )}
        {sub && (
          <Text
            as="span"
            variant="body-xs/medium"
            color="blue.9"
            whiteSpace="nowrap"
            marginTop={addSubTextMargin ? '4px !important' : '0px'}
            borderLeft={leftBorder ? '1px solid' : 'none'}
            borderRight={rightBorder ? '1px solid' : 'none'}
            borderLeftColor="gray.2"
            borderRightColor="gray.2"
            p={2}
          >
            {sub}
          </Text>
        )}
      </Stack>
    </CTh>
  )
}
