import { Flex, Text } from '@chakra-ui/react'
import { ChainId } from '@usedapp/core'
import { BigNumber, FixedNumber } from 'ethers'
import AssetDropdown from '../../../../components/elements/AssetDropdown'
import { HStack } from '../../../../components/elements/Stack'
import LendBox from '../LendBox'
import LendTable from '../LendTable'
import Subheading from '../Subheading'

export default function LendPageLoading({
  selectedPurchaseToken,
  onConnect,
}: {
  selectedPurchaseToken: string | undefined
  onConnect: () => void
}) {
  return (
    <Flex
      flexDir="row"
      pt="30px"
      width={{
        base: '984px',
        '1xl': '1293px',
        '3xl': '1520px',
      }}
      justify="space-between"
      mx="auto"
    >
      <Flex
        flexDir="column"
        width={{
          base: '622px',
          '1xl': '816px',
          '3xl': '1023px',
        }}
      >
        <Flex flexDir="column" pl={3}>
          <HStack spacing={3}>
            <Text fontSize="40px" variant="body-3xl/bold" mb={3} opacity={0.8}>
              Earn
            </Text>
            <AssetDropdown
              isDataLoading
              currencies={{}}
              selectedPurchaseToken={selectedPurchaseToken}
              onChangePurchaseToken={() => {}}
            />
          </HStack>
          <HStack mt={1} mb={6} h="44px">
            <Subheading />
          </HStack>
        </Flex>
        <LendTable
          selectedPurchaseToken=""
          isDataLoading
          lendData={[]}
          selectedChainId={ChainId.Mainnet.toString()}
          setActiveLendItem={() => {}}
        />
      </Flex>
      <Flex
        flexDir="column"
        pt="52px"
        width={{
          base: '330px',
          '1xl': '397px',
        }}
      >
        <LendBox
          isDataLoading
          key={''}
          tokenSymbol={''}
          tokenDecimals={6}
          connected={false}
          selectedApy={false}
          auctionClearingRate={FixedNumber.from('0')}
          onTriggerHoverEffect={() => {}}
          apy={FixedNumber.from('0')}
          discountRateMarkup={FixedNumber.from('0')}
          lendAmountUsd={FixedNumber.from('0')}
          amountToReceive={FixedNumber.from('0')}
          availableLiquidity={FixedNumber.from('0')}
          walletBalance={FixedNumber.from('0')}
          redemptionTimestamp={0}
          value={''}
          onChange={() => {}}
          onConnectModalOpen={onConnect}
          onLend={async () => {}}
          onMax={() => {}}
          isPurchasingMax={false}
          onKytCheck={async () => {
            return true
          }}
          onApprovePurchaseToken={async () => {}}
          purchaseTokenAllowance={BigNumber.from(0)}
        />
      </Flex>
    </Flex>
  )
}
