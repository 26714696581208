import { Link, Text } from '@chakra-ui/layout'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react/jsx-runtime'
import { Trans } from '@lingui/macro'

export default function FAQBox({
  content,
  learnMoreLink,
}: {
  content: {
    question: string
    link: string
  }[]
  learnMoreLink: string
}) {
  return (
    <VStack
      minW="324px"
      spacing="4px"
      pl={2}
      borderLeftWidth="1px"
      borderColor="gray.2"
      alignItems="start"
    >
      <HStack w="full" justifyContent="space-between">
        <Text variant="body-sm/medium" color="gray.5">
          <Trans>FAQ</Trans>
        </Text>
        <Link isExternal href={learnMoreLink} textDecoration="none">
          <Text variant="body-xs/semibold" color="blue.5">
            <Trans>View more</Trans>
          </Text>
        </Link>
      </HStack>
      {content.map(({ question, link }) => (
        <Fragment key={question}>
          <HStack spacing="12px" mt="0 !important" cursor="pointer">
            <Text variant="body-xs/medium" color="gray.5">
              {question}
            </Text>
            <Link isExternal href={link}>
              <Text fontSize="xs" color="gray.3">
                <FontAwesomeIcon icon={['far', 'arrow-up-right-from-square']} />
              </Text>
            </Link>
          </HStack>
        </Fragment>
      ))}
    </VStack>
  )
}
