import { Box, Flex, Image, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk'
import { ChainId } from '@usedapp/core'
import copyIcon from '../../../assets/icons/copy.svg'
import etherscanIcon from '../../../assets/icons/etherscan.svg'
import settingsIcon from '../../../assets/icons/settings_black.svg'
import invalidSwitchIcon from '../../../assets/icons/switchNetwork/switchInvalid.svg'
import testIcon from '../../../assets/icons/test.svg'
import switchNetworkIcons from '../../../helpers/switchNetworkIcons'
import { useStorage } from '../../../providers/storage'
import { InfoIcon } from '../Icons'
import { HStack } from '../Stack'
import Tooltip from '../Tooltip'

export default function ProfileMenuPopout({
  chainId,
  walletAddress,
  hasNotification,

  onClaimTestTokens,
  onDisconnect,
  onLogoutProfile,
  onSwitchNetwork,
  onManageProfile,
  getExplorerAddressLink,

  isMainnet,
  isValidNetwork,
}: {
  chainId: ChainId | undefined
  walletAddress: string | undefined
  hasNotification?: boolean

  onClaimTestTokens?: () => void
  onDisconnect?: () => void
  onLogoutProfile?: () => void
  onSwitchNetwork: () => void
  onManageProfile: () => void
  getExplorerAddressLink?: (address: string) => string

  isMainnet: boolean
  isValidNetwork: boolean
}) {
  const { connected: isSafeConnected } = useSafeAppsSDK()

  const { storage } = useStorage()
  const isProfileLoggedIn = storage.getItem('profile-token') !== null

  return (
    <MenuList>
      {!isMainnet && (
        <MenuItem
          onClick={onClaimTestTokens}
          isDisabled={!onClaimTestTokens || !walletAddress}
        >
          <HStack w="full" justifyContent="space-between">
            <Flex alignItems="center">
              <Image boxSize="16px" src={testIcon} mr="6px" />
              <Text as="span" ml="6px">
                Claim test tokens
              </Text>
            </Flex>
            {hasNotification && (
              <Box as="span" borderRadius="9999px" w={2} h={2} bg="blue.3" />
            )}
          </HStack>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          if (!walletAddress) {
            return
          }
          navigator.clipboard.writeText(walletAddress)
        }}
        isDisabled={!walletAddress}
      >
        <Flex alignItems="center">
          <Image boxSize="16px" src={copyIcon} mr="6px" />
          <Text as="span" ml="6px">
            Copy address
          </Text>
        </Flex>
      </MenuItem>
      <MenuItem
        isDisabled={!walletAddress}
        as="a"
        href={
          walletAddress && !!getExplorerAddressLink
            ? getExplorerAddressLink(walletAddress)
            : '#'
        }
        target="_blank"
      >
        <Flex alignItems="center">
          <Image boxSize="16px" src={etherscanIcon} mr="6px" />
          <Text as="span" ml="6px">
            View on Etherscan
          </Text>
        </Flex>
      </MenuItem>
      {/* Only allow single chain view via Safe App */}
      {!isSafeConnected && (
        <MenuItem
          onClick={() => {
            if (!onSwitchNetwork) {
              return
            }
            onSwitchNetwork()
          }}
          isDisabled={!onSwitchNetwork}
        >
          <Flex alignItems="center">
            {chainId && isValidNetwork ? (
              <Image
                boxSize="16px"
                src={switchNetworkIcons[chainId]}
                mr="6px"
              />
            ) : (
              <Image boxSize="16px" src={invalidSwitchIcon} mr="6px" />
            )}
            <Text as="span" ml="6px">
              Switch network
            </Text>
          </Flex>
        </MenuItem>
      )}
      {isProfileLoggedIn && (
        <MenuItem
          onClick={() => {
            if (!onManageProfile) {
              return
            }
            onManageProfile()
          }}
          isDisabled={!onManageProfile}
        >
          <Flex alignItems="center">
            <Image boxSize="16px" src={settingsIcon} mr="6px" />
            <Text as="span" ml="6px">
              Manage profile
            </Text>
          </Flex>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          if (isProfileLoggedIn) {
            if (!onLogoutProfile) {
              return
            }

            onLogoutProfile()
          } else {
            if (!onDisconnect) {
              return
            }
            onDisconnect()
          }
        }}
        isDisabled={!onDisconnect}
      >
        <HStack m="auto" spacing={0}>
          <Text
            as="span"
            color="blue.5"
            variant="body-sm/semibold"
            textAlign="center"
          >
            {isProfileLoggedIn ? 'Logout' : 'Disconnect'}
          </Text>
          <Tooltip label="To disconnect an account managed through MetaMask, please disconnect via MetaMask">
            <InfoIcon width="16px" height="16px" ml="8px" color="gray.3" />
          </Tooltip>
        </HStack>
      </MenuItem>
    </MenuList>
  )
}
