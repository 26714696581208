import { HStack } from '../../../../components/elements/Stack'

import { ReactNode } from 'react'

export default function SummaryStat({ children }: { children: ReactNode }) {
  return (
    <HStack
      justifyContent="space-between"
      w="full"
      mt="0 !important"
      lineHeight={1.5}
    >
      {children}
    </HStack>
  )
}
