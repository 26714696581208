import {
  Avatar,
  Box,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Td,
  Text,
  Tr,
  useClipboard,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { CopyIcon } from '../../../../components/elements/Icons'
import Tooltip from '../../../../components/elements/Tooltip'
import { components } from '../../../../models/profile-api'
import ProgressLine from '../ProgressLine'
import dayjs from 'dayjs'

type ExtendedInviteCodeMeta = components['schemas']['InviteCodeMeta'] & {
  progress: {
    currentStep: number
    label: string
    tooltip?: string
  }
}

interface InviteDetailsProps {
  inviteDetails: components['schemas']['InviteCode']
}

const TableCell = ({
  opacity,
  children,
  textAlign,
  xsPadding,
  width,
}: {
  opacity?: number
  children: ReactNode
  textAlign?: 'left' | 'center' | 'right'
  xsPadding?: boolean
  width?: number
}) => (
  <Td
    opacity={opacity}
    paddingLeft={2}
    paddingRight={xsPadding ? 2 : 16}
    textAlign={textAlign}
    border="none"
    w={width && `${width}px`}
  >
    {children}
  </Td>
)

export const TableRow = ({ inviteDetails }: InviteDetailsProps) => {
  const fullInviteURL = `${window.location.origin}/rewards?invite=${inviteDetails.code}`
  const { hasCopied, onCopy } = useClipboard(fullInviteURL)
  const inviteCodeMeta = inviteDetails.meta as ExtendedInviteCodeMeta

  return (
    <Tr h="80px" _odd={{ bg: 'blue.0' }} _even={{ bg: 'white' }}>
      <TableCell>
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem display="flex" alignItems="center" minW="198px">
            <Text variant="body-sm/normal" color="rgb(0, 16, 39, 0.6)">
              app.term.finance/...
              <Text as="span" variant="body-sm/medium" color="blue.5">
                {inviteDetails.code}
              </Text>
            </Text>
          </GridItem>
          <GridItem>
            <IconButton
              variant="secondary"
              isDisabled={inviteDetails.consumedTimestamp ? true : false}
              icon={
                <Tooltip noDelay label={hasCopied ? 'Copied' : 'Click to copy'}>
                  <CopyIcon
                    width="15px"
                    height="15px"
                    cursor="pointer"
                    color={
                      inviteDetails.consumedTimestamp ? 'gray.5' : 'blue.8'
                    }
                  />
                </Tooltip>
              }
              aria-label="Copy to clipboard"
              size="sm"
              onClick={onCopy}
            />
          </GridItem>
        </Grid>
      </TableCell>
      <TableCell opacity={inviteCodeMeta.progress.earnedPoints ? 0.5 : 1}>
        <Box width="200px">
          <ProgressLine
            variant="solid"
            totalSteps={3}
            label={inviteCodeMeta.progress.label}
            tooltip={inviteCodeMeta.progress.tooltip}
            currentStep={inviteCodeMeta.progress.currentStep}
          />
        </Box>
      </TableCell>
      <TableCell opacity={inviteDetails.consumedTimestamp ? 1 : 0.5}>
        <HStack>
          {inviteDetails.meta.inviteePhoto ? (
            <Avatar
              style={{ transition: 'all 2s' }}
              src={inviteCodeMeta.inviteePhoto}
              name={inviteCodeMeta.inviteeName}
              bg={'blue.5'}
              size="sm"
            />
          ) : (
            <Avatar bg={'blue.5'} size="sm" />
          )}
          <Text as="span" variant="body-sm/normal" px={1} color="gray.6">
            {inviteDetails.meta.inviteeName
              ? inviteDetails.meta.inviteeName
              : 'No one (yet!)'}
          </Text>
        </HStack>
      </TableCell>
      <TableCell textAlign="left">
        <Text as="span" variant="body-sm/normal" color="gray.6">
          {inviteDetails.consumedTimestamp
            ? dayjs(inviteDetails.consumedTimestamp as string).format('MMMM D')
            : ''}
        </Text>
      </TableCell>
      <TableCell textAlign="right" xsPadding>
        <Text as="span" variant="body-sm/normal" px={5} color="gray.6">
          {inviteDetails.meta.appPoints
            ? Math.floor(inviteDetails.meta.appPoints).toLocaleString()
            : ''}
        </Text>
      </TableCell>
      <TableCell textAlign="right" xsPadding>
        <Text as="span" variant="body-sm/medium" px={5} color="green.5">
          {inviteDetails.meta.appPoints
            ? Math.floor(inviteDetails.meta.appPoints * 0.1).toLocaleString()
            : ''}
        </Text>
      </TableCell>
    </Tr>
  )
}
