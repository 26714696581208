import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import unlock from '../../../../assets/icons/unlock.svg'

export const UnlockPointsRow = ({
  points,
  address,
  maxColWidth = 100,
  handleColumnWidth,
}: {
  points: number
  address: string
  maxColWidth?: number
  handleColumnWidth?: (width: number) => void
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [maxW, setMaxW] = useState(maxColWidth)

  useEffect(() => {
    const width = ref.current?.clientWidth || 0
    if (handleColumnWidth && width > maxColWidth) {
      handleColumnWidth(width)
    }
  }, [maxColWidth])

  useEffect(() => {
    if (maxColWidth !== undefined) {
      setMaxW(maxColWidth)
    }
  }, [maxColWidth])

  return (
    <Flex
      h="56px"
      align="center"
      py={5}
      px={4}
      w="full"
      opacity={0.9}
      bg="rgba(0, 0, 0, 0.03);"
      borderRadius="5px"
      backdropFilter="blur(3.5px)"
    >
      <Image src={unlock} alt="unlock bonus points" h="12px" mr={3} />
      {points !== undefined ? (
        <Text ref={ref} variant="body-xl/medium" color="blue.5">
          {points.toLocaleString()}
        </Text>
      ) : (
        <Box h="28px" w={maxW} bg="gray.2" opacity={0.5} />
      )}
      <Text
        variant="body-xl/medium"
        bg="rgba(228, 231, 235, 0.66);"
        color="gray.5"
        borderRadius="10px"
        py={1}
        px={1.5}
        ml="auto"
      >
        {address}
      </Text>
    </Flex>
  )
}
