import { Flex, HStack, IconButton, Text, useClipboard } from '@chakra-ui/react'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CopyIcon } from '../../../../components/elements/Icons'
import Tooltip from '../../../../components/elements/Tooltip'
import { InviteURLButtonType } from '../../utils'

export const InviteURL = ({
  referralURL,
  referralCode,
}: {
  referralURL: string
  referralCode: string
}) => {
  const fullReferralURL = `https://${referralURL}?ref=${referralCode}`
  const { hasCopied, onCopy } = useClipboard(fullReferralURL)

  const InviteURLButton: InviteURLButtonType[] = [
    {
      icon: (
        <Tooltip noDelay label={hasCopied ? 'Copied' : 'Click to copy'}>
          <CopyIcon
            width="18px"
            height="18px"
            cursor="pointer"
            color="blue.5"
          />
        </Tooltip>
      ),
      ariaLabel: 'Copy referral URL',
      bg: 'transparent',
      hoverBg: 'transparent',
      onClick: onCopy,
    },
    {
      icon: <FontAwesomeIcon icon={faXTwitter} size="xl" />,
      ariaLabel: '',
      onClick: () => {
        const encodedTweetText = encodeURIComponent(
          `\n\n\nReady to dive in? Use my referral code: \n ${fullReferralURL}`
        )
        window.open(
          `https://twitter.com/intent/tweet?text=${encodedTweetText}`,
          '_blank',
          'noopener,noreferrer'
        )
      },
    },
  ]

  const renderIconButton = ({
    icon,
    ariaLabel,
    bg,
    hoverBg,
    onClick,
  }: InviteURLButtonType) => (
    <IconButton
      variant="secondary"
      icon={icon}
      aria-label={ariaLabel}
      size="sm"
      bg={bg}
      onClick={onClick}
    />
  )
  return (
    <Flex
      ml="auto"
      borderRadius="8px"
      border="1px"
      borderColor="gray.2"
      h="64px"
      align="center"
      py={5}
      px={4}
    >
      <Text variant="body-xl/normal" color="rgb(0, 16, 39, 0.6)" mr={2.5}>
        {referralURL}
        <Text as="span" variant="body-xl/bold" color="blue.5">
          ?ref={referralCode && `${referralCode}`}
        </Text>
      </Text>
      <HStack>
        {renderIconButton(InviteURLButton[0])}
        {renderIconButton(InviteURLButton[1])}
      </HStack>
    </Flex>
  )
}

export default InviteURL
