import { Box, Progress } from '@chakra-ui/react'

export default function ProgressBar({
  value,
  // eslint-disable-next-line unused-imports/no-unused-vars
  trackColor = 'red',
  // eslint-disable-next-line unused-imports/no-unused-vars
  lineColor = 'green',
  ...props
}: {
  value: number
  trackColor: string
  lineColor: string
  [key: string]: any
}) {
  return (
    <Box w="full" ml="auto" transform="scaleX(-1)">
      <Progress
        sx={{
          '&': {
            backgroundColor: '#CAD5E4',
          },
          '& > div': {
            backgroundColor: '#679FF2',
          },
        }}
        height="8px"
        value={value}
        borderRadius="20px"
        {...props}
      />
    </Box>
  )
}
