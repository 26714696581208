import { Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import dayjs from 'dayjs'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import TokenChip from '../../../../components/elements/TokenChip'
import TransactionHash from '../../../../components/elements/TransactionHash'
import { formatFixedToken } from '../../../../helpers/utils'
import { LiquidationForDisplay } from '../../../../data/model'
import { Trans, t } from '@lingui/macro'

export default function LoanLiquidationOverview({
  getBlockExplorerTransactionUrl,
  liquidation,
  auctionLabel,
  partial,
}: {
  liquidation: LiquidationForDisplay
  auctionLabel: string
  partial?: boolean
  getBlockExplorerTransactionUrl?: (address: string) => string
}) {
  return (
    <StatsCard>
      <StatsTitle value={auctionLabel}>
        <Trans>Overview</Trans>
      </StatsTitle>
      <StatsRow title={t`Date`}>
        <Text variant="body-sm/normal" color="blue.9">
          {dayjs.unix(liquidation.timestamp).format('LLL')}
        </Text>
      </StatsRow>
      <StatsRow
        title={
          <>
            <Trans>Debt Covered</Trans>&nbsp;
            <TokenChip token={liquidation.borrowTokenSymbol} />
          </>
        }
      >
        <Text variant="body-sm/normal" color="blue.9">
          {formatFixedToken(
            liquidation.loanCovered,
            liquidation.borrowTokenSymbol,
            true
          )}
        </Text>
      </StatsRow>
      {partial && (
        <StatsRow title={t`Debt Remaining`}>
          <Text variant="body-sm/normal" color="blue.9">
            {formatFixedToken(
              liquidation.loanRemaining,
              liquidation.borrowTokenSymbol,
              true
            )}
          </Text>
        </StatsRow>
      )}
      <StatsRow
        title={
          <>
            <Trans>Collateral Liquidated</Trans>&nbsp;
            <TokenChip token={liquidation.collateralTokenSymbol} />
          </>
        }
      >
        <HStack>
          <Text variant="body-sm/normal">
            {formatFixedToken(
              liquidation.collateralTokensLiquidated,
              liquidation.collateralTokenSymbol,
              true
            )}
          </Text>
        </HStack>
      </StatsRow>
      <StatsRow title={t`Transaction`}>
        <HStack>
          <TransactionHash
            variant="body-sm/medium"
            color="blue.9"
            getBlockExplorerTransactionUrl={getBlockExplorerTransactionUrl}
            address={liquidation.transactionHash}
            headChars={6}
            tailChars={4}
          />
        </HStack>
      </StatsRow>
    </StatsCard>
  )
}
