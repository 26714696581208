import { Box, BoxProps } from '@chakra-ui/react'

export default function OverflowBox(props: BoxProps) {
  return (
    <Box
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      {...props}
    >
      {props.children}
    </Box>
  )
}
