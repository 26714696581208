import { TableCellProps, Td } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { TableCellSize } from '../../../../helpers/types'

const CELL_SIZE: Record<
  TableCellSize,
  {
    [key: string]: string
  }
> = {
  sm: {
    px: '8px',
    py: '9px',
  },
  md: {
    px: '8px',
    py: '14px',
  },
}

export default function TableCell({
  paddingInline,
  n,
  size = 'sm',
  textAlign,
  children,
  ...props
}: {
  paddingInline?: TableCellProps['paddingInline']
  n: number
  size?: TableCellSize
  textAlign?: TableCellProps['textAlign']
  children: ReactNode
}) {
  return (
    <Td
      w={(100 / n).toFixed() + '%'}
      fontFamily="Inter"
      fontSize="xs"
      lineHeight="4"
      color="blue.9"
      textAlign={textAlign}
      paddingInline={paddingInline}
      borderBottom={'none'}
      {...CELL_SIZE[size]}
      {...props}
    >
      {children}
    </Td>
  )
}
