import { Box, BoxProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

export default function Container({
  children,
  ...props
}: { children: ReactNode } & BoxProps) {
  return (
    <Box
      bg="white"
      p="16px"
      borderRadius="md"
      boxShadow="0px 4px 4px rgba(49, 130, 206, 0.03)"
      {...props}
    >
      {children}
    </Box>
  )
}
