import { FixedNumber } from 'ethers'
import { formatFixedToken } from '../../../helpers/utils'
import StatsBeforeAfter from '../StatsBeforeAfter'
import StatsCard from '../StatsCard'
import StatsRow from '../StatsCard/StatsRow'
import StatsTitle from '../StatsCardTitle'
import { evaluate } from '../../../helpers/math'

export default function StatsWalletBeforeAfter({
  symbol,

  afterRepaymentDebt,
  tokenBalance,
  tokenDelta,
  excessCollateralBefore,
  excessCollateralAfter,
  mt = 0,
}: {
  symbol: string

  afterRepaymentDebt: FixedNumber
  tokenBalance: FixedNumber
  tokenDelta: FixedNumber

  excessCollateralBefore: FixedNumber
  excessCollateralAfter: FixedNumber
  mt?: number
}) {
  const afterToken = evaluate(
    {
      nodeKind: 'add',
      args: [
        {
          nodeKind: 'value',
          value: tokenBalance,
        },
        {
          nodeKind: 'value',
          value: tokenDelta,
        },
      ],
    },
    tokenBalance.format.decimals
  )

  return (
    <StatsCard mt={mt}>
      <StatsTitle>
        {afterRepaymentDebt.isZero() ? 'Wallet Balance' : 'Excess Collateral'}
      </StatsTitle>
      <StatsRow title={symbol}>
        <StatsBeforeAfter
          maxW="320px"
          before={
            afterRepaymentDebt.isZero()
              ? formatFixedToken(tokenBalance, symbol, true, true)
              : formatFixedToken(excessCollateralBefore, symbol, true, true)
          }
          after={
            afterRepaymentDebt.isZero()
              ? formatFixedToken(afterToken, symbol, true)
              : formatFixedToken(excessCollateralAfter, symbol, true, true)
          }
        />
      </StatsRow>
    </StatsCard>
  )
}
