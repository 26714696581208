import { Box, Image, Link, Text } from '@chakra-ui/react'
import { ChainId, TransactionState } from '@usedapp/core'
import { BigNumber, BigNumberish, FixedNumber } from 'ethers'
import { ReactNode, useEffect, useMemo } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import arrow_up_right_dark from '../../assets/icons/arrow-up-right-dark.svg'
import Chip from '../../components/elements/Chip'
import { HStack, VStack } from '../../components/elements/Stack'
import { Trans, t } from '@lingui/macro'

import {
  Address,
  Auction,
  BombPotReward,
  Currency,
  ElectRollover,
  RolloverAuctionInfo,
  SubmittedBorrowTender,
  SubmittedLoanTender,
  TenderId,
  TermBorrow,
  TermLoan,
  TermPeriod,
  TransformedListingsData,
} from '../../data/model'
import { add, divide, multiply, subtract } from '../../helpers/math'
import { formatFixedPercentage, getAuctionDisplayId } from '../../helpers/utils'
import { PortfolioPageParams } from '../../models/portfolio'
import HistorySection from './elements/HistorySection'
import OpenOffersSection from './elements/OpenOffersSection'
import OpenPositionsSection from './elements/OpenPositionsSection'
import PortfolioPageLoading from './elements/PortfolioPageLoading'
import Sidebar from './elements/Sidebar'
import SummarySection from './elements/SummarySection'
import { OpenBorrow, groupTenders, summarizeLoans } from './utils'

export default function PortfolioPage({
  allDataLoaded = false,

  chainConfigs,

  terms,

  listingDiscountRateMarkups,
  listingMinimumListingAmounts,
  listings,

  borrowTenders,
  loanTenders,

  borrows,
  loans,

  repoExposures,

  historicalBorrows,
  historicalLoans,

  reloadBorrowTenders,
  reloadLoanTenders,

  lockCollateral,
  unlockCollateral,

  deleteBorrowTenders,
  deleteLoanTenders,

  redeemTermTokens,
  repayTermTokens,
  collapseBorrow,

  onApprovePurchaseTokens,
  onApproveCollateralTokens,
  onApproveTermRepoTokens,

  onAddTermToken,

  borrowerElectRollover,
  borrowerCancelRollover,

  onCheckActiveNetwork,

  onKytCheck,

  onCreateListing,
  onCancelListings,

  loadMissingTenderRates,
  isLoadingMissingRates,

  auctions,

  rolloverAuctions,
  rolloverAuctionOptions,

  currencies,
  prices,
  balances,
  purchaseTokenApprovals,
  collateralTokenApprovals,
  termRepoTokenApprovals,

  userBombPots,

  pathPrefix = '/portfolio',
}: PortfolioPageParams) {
  if (
    allDataLoaded === false ||
    terms === undefined ||
    listingDiscountRateMarkups === undefined ||
    listingMinimumListingAmounts === undefined ||
    listings === undefined ||
    auctions === undefined ||
    currencies === undefined ||
    prices === undefined ||
    balances === undefined ||
    purchaseTokenApprovals === undefined ||
    collateralTokenApprovals === undefined ||
    termRepoTokenApprovals === undefined ||
    loans === undefined ||
    borrows === undefined ||
    repoExposures === undefined ||
    historicalLoans === undefined ||
    historicalBorrows === undefined ||
    loanTenders === undefined ||
    borrowTenders === undefined ||
    rolloverAuctions === undefined ||
    rolloverAuctionOptions === undefined ||
    reloadBorrowTenders === undefined ||
    reloadLoanTenders === undefined ||
    lockCollateral === undefined ||
    unlockCollateral === undefined ||
    deleteBorrowTenders === undefined ||
    deleteLoanTenders === undefined ||
    borrowerElectRollover === undefined ||
    borrowerCancelRollover === undefined ||
    redeemTermTokens === undefined ||
    repayTermTokens === undefined ||
    collapseBorrow === undefined ||
    onAddTermToken === undefined ||
    onApprovePurchaseTokens === undefined ||
    onApproveCollateralTokens === undefined ||
    onApproveTermRepoTokens === undefined ||
    onCheckActiveNetwork === undefined ||
    onKytCheck === undefined ||
    onCreateListing === undefined ||
    onCancelListings === undefined ||
    loadMissingTenderRates === undefined
  ) {
    // Return empty pages with skeleton animations if data is still loading
    return <PortfolioPageLoading pathPrefix={pathPrefix} />
  }

  return (
    <ParameterizedPortfolioPage
      terms={terms}
      listingDiscountRateMarkups={listingDiscountRateMarkups}
      listingMinimumListingAmounts={listingMinimumListingAmounts}
      listings={listings}
      borrowTenders={borrowTenders}
      loanTenders={loanTenders}
      borrows={borrows}
      loans={loans}
      repoExposures={repoExposures}
      historicalBorrows={historicalBorrows}
      historicalLoans={historicalLoans}
      auctions={auctions}
      rolloverAuctionOptions={rolloverAuctionOptions}
      currencies={currencies}
      prices={prices}
      balances={balances}
      purchaseTokenApprovals={purchaseTokenApprovals}
      collateralTokenApprovals={collateralTokenApprovals}
      termRepoTokenApprovals={termRepoTokenApprovals}
      pathPrefix={pathPrefix}
      onApprovePurchaseTokens={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return onApprovePurchaseTokens(...args)
      }}
      onApproveCollateralTokens={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return onApproveCollateralTokens(...args)
      }}
      onApproveTermRepoTokens={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return onApproveTermRepoTokens(...args)
      }}
      onAddTermToken={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return onAddTermToken(...args)
      }}
      redeemTermTokens={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return redeemTermTokens(...args)
      }}
      collapseBorrow={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return collapseBorrow(...args)
      }}
      repayTermTokens={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return repayTermTokens(...args)
      }}
      lockCollateral={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return lockCollateral(...args)
      }}
      unlockCollateral={async (...args) => {
        const [chainId] = args
        await await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return unlockCollateral(...args)
      }}
      deleteBorrowTenders={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return deleteBorrowTenders(...args)
      }}
      deleteLoanTenders={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return deleteLoanTenders(...args)
      }}
      onElectRollover={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return borrowerElectRollover(...args)
      }}
      onCancelRollover={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return borrowerCancelRollover(...args)
      }}
      onKytCheck={onKytCheck}
      onCreateListing={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return onCreateListing(...args)
      }}
      onCancelListings={async (...args) => {
        const [chainId] = args
        await onCheckActiveNetwork(
          chainConfigs[chainId].chainId,
          chainConfigs[chainId].chainName
        )
        return onCancelListings(...args)
      }}
      onLoadMissingTenderRates={async (
        chainId: string,
        bids: string[] | undefined,
        offers: string[] | undefined,
        isRollover: boolean = false
      ) => {
        await loadMissingTenderRates(chainId, bids, offers, isRollover)
        reloadBorrowTenders()
        reloadLoanTenders()
      }}
      isLoadingMissingRates={isLoadingMissingRates}
      userBombPots={userBombPots}
    />
  )
}

export function ParameterizedPortfolioPage({
  terms,

  listingDiscountRateMarkups,
  listingMinimumListingAmounts,
  listings,

  borrowTenders,
  loanTenders,

  borrows,
  loans,

  repoExposures,

  historicalBorrows,
  historicalLoans,

  auctions,

  rolloverAuctionOptions,

  currencies,
  prices,
  balances,
  purchaseTokenApprovals,
  collateralTokenApprovals,
  termRepoTokenApprovals,

  pathPrefix,

  isLoadingMissingRates,

  onApprovePurchaseTokens,
  onApproveCollateralTokens,
  onApproveTermRepoTokens,
  onAddTermToken,
  redeemTermTokens,
  collapseBorrow,
  repayTermTokens,
  lockCollateral,
  unlockCollateral,
  deleteBorrowTenders,
  deleteLoanTenders,
  onElectRollover,
  onCancelRollover,
  onLoadMissingTenderRates,
  onKytCheck,
  onCreateListing,
  onCancelListings,

  userBombPots,
}: {
  terms: { [chainId: string]: { [repoTokenAddress: Address]: TermPeriod } }
  listingDiscountRateMarkups: { [chainId: string]: BigNumber }
  listingMinimumListingAmounts: {
    [chainId: string]: { [termRepoToken: Address]: FixedNumber }
  }
  listings: {
    [chainId: string]: { [termRepoToken: Address]: TransformedListingsData }
  }
  borrowTenders: SubmittedBorrowTender[]
  loanTenders: SubmittedLoanTender[]

  borrows: TermBorrow[]
  loans: TermLoan[]

  repoExposures: { [termId: Address]: BigNumber }

  historicalBorrows: TermBorrow[]
  historicalLoans: TermLoan[]

  auctions: { [id: string]: Auction }

  rolloverAuctionOptions: RolloverAuctionInfo[]

  currencies: { [chainId: string]: { [address: string]: Currency } }
  prices: { [chainId: string]: { [address: string]: FixedNumber } }
  balances: { [chainId: string]: { [address: string]: FixedNumber } }
  purchaseTokenApprovals: {
    [chainId: string]: { [address: string]: FixedNumber }
  }
  collateralTokenApprovals: {
    [chainId: string]: { [address: string]: FixedNumber }
  }
  termRepoTokenApprovals: {
    [chainId: string]: { [address: string]: FixedNumber }
  }

  pathPrefix?: string

  isLoadingMissingRates: boolean

  onApprovePurchaseTokens: (
    chainId: string,
    token: string,
    spender: string,
    amount: BigNumberish
  ) => Promise<void>

  onApproveCollateralTokens: (
    chainId: string,
    token: string,
    spender: string,
    amount: BigNumberish
  ) => Promise<void>

  onApproveTermRepoTokens: (
    chainId: string,
    token: string,
    spender: string,
    amount: BigNumberish
  ) => Promise<void>

  onAddTermToken: (
    chainId: string,
    tokenAddress: string,
    tokenSymbol: string,
    tokenDecimals: number
  ) => Promise<boolean>

  redeemTermTokens: (
    chainId: string,
    loanManagerAddress: Address,
    termTokensToBurn: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  repayTermTokens: (
    chainId: string,
    loanManagerAddress: Address,
    repayAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  collapseBorrow: (
    chainId: string,
    loanManagerAddress: Address,
    collapseBorrowAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>

  lockCollateral: (
    chainId: string,
    collateralManagerAddress: string,
    collateralTokenAddress: string,
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>
  unlockCollateral: (
    chainId: string,
    collateralManagerAddress: string,
    collateralTokenAddress: string,
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>

  deleteBorrowTenders?: (
    chainId: string,
    tenders: TenderId[],
    auction: Address
  ) => Promise<TransactionState>
  deleteLoanTenders?: (
    chainId: string,
    tenders: TenderId[],
    auction: Address
  ) => Promise<TransactionState>

  onElectRollover: (
    chainId: string,
    rolloverManagerAddress: Address,
    electedRollover: ElectRollover
  ) => Promise<void>
  onCancelRollover: (
    chainId: string,
    rolloverManagerAddress: Address,
    termId: string
  ) => Promise<void>
  onLoadMissingTenderRates: (
    chainId: string,
    bids: string[] | undefined,
    offers: string[] | undefined,
    isRollover: boolean
  ) => Promise<void>
  onKytCheck: () => Promise<boolean>
  onCreateListing: (
    chainId: string,
    termRepoToken: Address,
    listingAmount: FixedNumber
  ) => Promise<void>
  onCancelListings: (
    chainId: string,
    listingIds: number[],
    skipRedeem?: boolean
  ) => Promise<void>

  userBombPots: Record<string, BombPotReward[]> | undefined
}) {
  const navigate = useNavigate()

  const [openBorrows, openLoans] = useMemo(
    () =>
      summarizeLoans(
        terms,
        loans,
        borrows,
        currencies,
        prices,
        balances,
        purchaseTokenApprovals,
        collateralTokenApprovals,
        termRepoTokenApprovals,
        repoExposures,
        rolloverAuctionOptions,
        userBombPots,
        listings
      ),
    [
      terms,
      loans,
      borrows,
      currencies,
      prices,
      balances,
      purchaseTokenApprovals,
      collateralTokenApprovals,
      termRepoTokenApprovals,
      repoExposures,
      rolloverAuctionOptions,
      userBombPots,
      listings,
    ]
  )
  const [openBorrowTenders, openLoanTenders] = useMemo(
    () =>
      groupTenders(borrowTenders, loanTenders, auctions, currencies, prices),
    [auctions, borrowTenders, currencies, loanTenders, prices]
  )
  const leastCollateralized = useMemo(() => {
    let least = undefined as
      | {
          borrow: OpenBorrow
          currentRatio: FixedNumber
          maintenanceRatio: FixedNumber
          liquidationDistance: FixedNumber
        }
      | undefined
    for (const borrow of Object.values(openBorrows.borrows).flat()) {
      const collateralDepositedUsd = multiply(
        borrow.collateralDeposited,
        borrow.collateralPrice,
        18
      )
      const outstandingDebtUsd = multiply(
        borrow.outstandingDebt,
        borrow.purchasePrice,
        18
      )

      if (outstandingDebtUsd.isZero()) {
        continue
      }

      const currentRatio = divide(collateralDepositedUsd, outstandingDebtUsd)
      const liquidationDistance = subtract(
        currentRatio,
        borrow.maintenanceMarginRatio
      )

      if (
        least === undefined ||
        subtract(liquidationDistance, least.liquidationDistance).isNegative()
      ) {
        least = {
          borrow,
          currentRatio,
          maintenanceRatio: borrow.maintenanceMarginRatio,
          liquidationDistance,
        }
      }
    }
    return least
  }, [openBorrows.borrows])

  const collateralData = useMemo(() => {
    if (
      openBorrows.totals.collateralRequiredUsd.isZero() &&
      openBorrows.totals.collateralDepositedUsd.isZero()
    ) {
      return undefined
    }
    const results: {
      label: string
      value?: FixedNumber
      node?: ReactNode
    }[] = [
      {
        label: t`Required Collateral`,
        value: openBorrows.totals.collateralRequiredUsd,
      },
      {
        label: t`Deposited Collateral`,
        value: openBorrows.totals.collateralDepositedUsd,
      },
      {
        label: t`Excess Collateral`,
        value: subtract(
          openBorrows.totals.collateralDepositedUsd,
          openBorrows.totals.collateralRequiredUsd
        ),
      },
    ]
    if (!openBorrows.totals.outstandingDebtUsd.isZero()) {
      results.push({
        label: t`Least Collateral Ratio / Requirement Ratio`,
        node: (
          <VStack alignItems="flex-end">
            <Chip>
              <HStack>
                <Text as="span">
                  {leastCollateralized?.borrow?.maturityTimestamp
                    ? getAuctionDisplayId({
                        maturityTimestamp:
                          leastCollateralized?.borrow?.maturityTimestamp,
                        auctionEndTimestamp:
                          leastCollateralized?.borrow?.maturityTimestamp,
                        collateralSymbol:
                          leastCollateralized?.borrow?.collateralTokenSymbol,
                        termSymbol:
                          leastCollateralized?.borrow?.purchaseTokenSymbol,
                      })
                    : '-'}
                </Text>
                <Link
                  variant="unstyled"
                  onClick={() => {
                    navigate('/portfolio/positions')
                  }}
                >
                  <Image boxSize="16px" src={arrow_up_right_dark} alt="arrow" />
                </Link>
              </HStack>
            </Chip>
            <Text textColor="gray.4" variant="body-md/medium">
              <Text as="span" textColor="blue.9" variant="body-md/normal">
                {formatFixedPercentage(leastCollateralized?.currentRatio)}
              </Text>{' '}
              /{' '}
              {formatFixedPercentage(leastCollateralized?.maintenanceRatio, 1)}{' '}
              <Trans>required</Trans>
            </Text>
          </VStack>
        ),
      })
    }
    return results
  }, [
    leastCollateralized?.borrow?.collateralTokenSymbol,
    leastCollateralized?.borrow?.maturityTimestamp,
    leastCollateralized?.borrow?.purchaseTokenSymbol,
    leastCollateralized?.currentRatio,
    leastCollateralized?.maintenanceRatio,
    openBorrows.totals.collateralDepositedUsd,
    openBorrows.totals.collateralRequiredUsd,
    openBorrows.totals.outstandingDebtUsd,
    navigate,
  ])

  const openTenderData = useMemo(() => {
    if (
      openBorrowTenders.groups.length === 0 &&
      openLoanTenders.groups.length === 0
    ) {
      return undefined
    }
    return [
      {
        label: t`Total Bids`,
        value: openBorrowTenders.groups.reduce((a, b) => {
          let totalAmountUsd: FixedNumber
          // exclude tenders on closed auctions from the total
          if (b.closed) {
            totalAmountUsd = FixedNumber.fromString('0')
          } else {
            totalAmountUsd = b.tenders.reduce(
              (c, d) => add(c, d.amountUsd, d.amountUsd.format.decimals),
              FixedNumber.fromString('0')
            )
          }
          return add(a, totalAmountUsd, totalAmountUsd.format.decimals)
        }, FixedNumber.fromString('0')),
      },
      {
        label: t`Total Initial Collateral Required`,
        value: openBorrowTenders.groups.reduce((a, b) => {
          let groupRequiredCollateral: FixedNumber
          // exclude tenders on closed auctions from the total
          if (b.closed) {
            groupRequiredCollateral = FixedNumber.fromString('0')
          } else {
            groupRequiredCollateral = b.tenders.reduce(
              (c, d) =>
                add(
                  c,
                  d.requiredMarginUsd,
                  d.requiredMarginUsd.format.decimals
                ),
              FixedNumber.fromString('0')
            )
          }
          return add(
            a,
            groupRequiredCollateral,
            groupRequiredCollateral.format.decimals
          )
        }, FixedNumber.fromString('0')),
      },
      {
        label: t`Total Collateral Deposited`,
        value: openBorrowTenders.groups.reduce((a, b) => {
          let groupDepositedCollateral: FixedNumber
          // exclude tenders on closed auctions from the total
          if (b.closed) {
            groupDepositedCollateral = FixedNumber.fromString('0')
          } else {
            groupDepositedCollateral = b.tenders.reduce(
              (c, d) =>
                add(
                  c,
                  d.depositedMarginUsd,
                  d.depositedMarginUsd.format.decimals
                ),
              FixedNumber.fromString('0')
            )
          }
          return add(
            a,
            groupDepositedCollateral,
            groupDepositedCollateral.format.decimals
          )
        }, FixedNumber.fromString('0')),
      },
      {
        label: t`Total Offers`,
        value: openLoanTenders.groups.reduce((a, b) => {
          let totalAmountUsd: FixedNumber
          // exclude tenders on closed auctions from the total
          if (b.closed) {
            totalAmountUsd = FixedNumber.fromString('0')
          } else {
            totalAmountUsd = b.tenders.reduce(
              (c, d) => add(c, d.amountUsd, d.amountUsd.format.decimals),
              FixedNumber.fromString('0')
            )
          }
          return add(a, totalAmountUsd, totalAmountUsd.format.decimals)
        }, FixedNumber.fromString('0')),
      },
    ]
  }, [openBorrowTenders.groups, openLoanTenders.groups])

  const openPositionNetworks = useMemo(() => {
    const chainIds: ChainId[] = []
    chainIds.push(
      ...Object.keys(openLoans.chainTotals).map((key) => parseInt(key, 10))
    )
    chainIds.push(
      ...Object.keys(openBorrows.chainTotals).map((key) => parseInt(key, 10))
    )
    return Array.from(new Set(chainIds))
  }, [openLoans, openBorrows])

  useEffect(() => {
    // console.log('portfolio open loans: %o', openLoans)
  }, [openLoans])

  const summarySection = (
    <SummarySection
      totalLoanAmountUsd={openLoans.totals.loanPrincipalUsd}
      totalReceivedRepoTokensUsd={openLoans.totals.receivedRepoTokensUsd}
      totalOpenListingsUsd={openLoans.totals.openListingsUsd}
      interestToReceiveUsd={openLoans.totals.outstandingInterestCreditUsd}
      totalBorrowAmountUsd={openBorrows.totals.principalDebtUsd}
      interestToPayUsd={openBorrows.totals.interestDebtUsd}
      openLoanCount={openLoans.totals.count}
      openBorrowCount={openBorrows.totals.count}
      openPositionCount={openLoans.totals.count + openBorrows.totals.count}
      openPositionNetworks={openPositionNetworks}
      collateralData={collateralData}
      openTenderData={openTenderData}
    />
  )
  const openPositionsSection = (
    <OpenPositionsSection
      openBorrows={openBorrows}
      openLoans={openLoans}
      listingDiscountRateMarkups={listingDiscountRateMarkups}
      listingMinimumListingAmounts={listingMinimumListingAmounts}
      rolloverAuctions={rolloverAuctionOptions}
      prices={prices}
      onApprovePurchaseTokens={onApprovePurchaseTokens}
      onApproveCollateralTokens={onApproveCollateralTokens}
      onApproveTermRepoTokens={onApproveTermRepoTokens}
      onAddTermToken={onAddTermToken}
      redeemTermTokens={redeemTermTokens}
      repayTermTokens={repayTermTokens}
      collapseBorrow={collapseBorrow}
      onLockCollateral={lockCollateral}
      onUnlockCollateral={unlockCollateral}
      onElectRollover={onElectRollover}
      onEditRollover={onElectRollover}
      onCancelRollover={onCancelRollover}
      onCreateListing={onCreateListing}
      onCancelListings={onCancelListings}
      onKytCheck={onKytCheck}
      onLoadMissingTenderRates={onLoadMissingTenderRates}
      isLoadingMissingRates={isLoadingMissingRates}
    />
  )
  const openOffersSection = (
    <OpenOffersSection
      openBorrowTenders={openBorrowTenders}
      openLoanTenders={openLoanTenders}
      onDeleteBorrows={deleteBorrowTenders}
      onDeleteLoans={deleteLoanTenders}
      onLoadMissingTenderRates={onLoadMissingTenderRates}
      isLoadingMissingRates={isLoadingMissingRates}
    />
  )
  return (
    <HStack alignItems="stretch" minH="calc(100vh - 60px - 60px)">
      <Sidebar
        pathPrefix={pathPrefix}
        openOffersCount={
          (openLoanTenders?.groups
            ?.filter((g) => g.status !== 'closed')
            ?.reduce((a, b) => a + b?.tenders?.length ?? 0, 0) ?? 0) +
          (openBorrowTenders?.groups
            ?.filter((g) => g.status !== 'closed')
            ?.reduce((a, b) => a + b?.tenders?.length ?? 0, 0) ?? 0)
        }
        openPositionsCount={
          (openLoans?.totals?.count ?? 0) + (openBorrows?.totals?.count ?? 0)
        }
      />
      <Box
        w="full"
        overflow="hidden"
        ml="0 !important"
        pb="80px"
        mb="0 !important"
      >
        <Routes>
          <Route index element={summarySection} />
          <Route path="positions" element={openPositionsSection} />
          <Route path="offers" element={openOffersSection} />
          <Route
            path="history"
            element={
              <HistorySection
                borrows={historicalBorrows}
                loans={historicalLoans}
                currencies={currencies}
                prices={prices}
                terms={terms}
              />
            }
          />
        </Routes>
      </Box>
    </HStack>
  )
}
