import { HStack, Link, Text } from '@chakra-ui/react'
import ValueTooltip from '../../../../components/elements/ValueTooltip'
import RepoCardText from '../RepoCardText'

const BoldText = ({ children }: { children?: React.ReactNode }) => (
  <Text
    lineHeight={5}
    fontWeight="medium"
    color="blue.9"
    fontSize={{
      base: '11px',
      '1xl': 'sm',
    }}
  >
    {children}
  </Text>
)

const RepoCardDetails = ({
  text,
  link,
  emboldened,
  tooltipLabel,
  isFirst = false,
  // eslint-disable-next-line unused-imports/no-unused-vars
  isLast = false,
}: {
  text: string
  link?: string
  emboldened?: string | JSX.Element
  tooltipLabel?: string
  isFirst?: boolean
  isLast?: boolean
}) => {
  return (
    <HStack
      px={{
        base: isFirst ? undefined : 1.5,
        '1xl': isFirst ? undefined : 3,
        '3xl': isFirst ? undefined : '26px',
      }}
      pr={{
        base: isFirst ? 1.5 : undefined,
        '1xl': isFirst ? 3 : undefined,
        '3xl': isFirst ? '26px' : undefined,
      }}
      display="flex"
      justifyContent={isFirst ? '' : 'space-between'}
      alignItems="flex-start"
      flexWrap={isFirst ? 'wrap' : undefined}
    >
      <RepoCardText>{text}</RepoCardText>
      {link ? (
        <Link
          href={link}
          isExternal
          color="blue.9"
          textDecoration="none"
          _hover={{
            textDecoration: 'underline',
          }}
        >
          <BoldText>{emboldened}</BoldText>
        </Link>
      ) : typeof emboldened === 'string' ? (
        <ValueTooltip
          value={`${tooltipLabel ? tooltipLabel : emboldened?.toString()}`}
        >
          <Text
            lineHeight={5}
            fontWeight="medium"
            color="blue.9"
            fontSize={{
              base: '11px',
              '1xl': 'sm',
            }}
          >
            {emboldened}
          </Text>
        </ValueTooltip>
      ) : (
        <BoldText>{emboldened}</BoldText>
      )}
    </HStack>
  )
}

export default RepoCardDetails
