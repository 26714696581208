import { Box, Link, Text, useDisclosure } from '@chakra-ui/react'
import { ChainId, useEthers } from '@usedapp/core'
import { BigNumber, FixedNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { HStack } from '../../components/elements/Stack'
import { ChainSpecificConfig } from '../../config'
import {
  Auction,
  AuctionActivityData,
  Currency,
  NativeCurrency,
  SubmittedBorrowTender,
  SubmittedLoanTender,
  SuggestedRatesByPlatform,
  TenderId,
  TermPeriod,
  ValidatedBorrowTender,
  ValidatedLoanTender,
} from '../../data/model'
import {
  CONTAINER_PADDING,
  MOBILE_CONTAINER_PADDING,
} from '../../helpers/constants'
import { AuctionPageParams } from '../../models/auction'
import { useConfig } from '../../providers/config'
import c from '../Auction/content.json'
import { AuctionPageEmpty } from '../Auction/elements/AuctionPageEmpty'
import CreateTendersBox from '../Auction/elements/CreateTendersBox'
import FAQBox from '../Auction/elements/FAQBox'
import OpenAuctionStatistics from '../Auction/elements/OpenAuctionStatistics'
import OpenTenders from '../Auction/elements/OpenTenders'
import OpenTendersBadge from '../Auction/elements/OpenTendersBadge'
import PageDescription from '../Auction/elements/PageDescription'
import PageTitle from '../Auction/elements/PageTitle'
import SwitchToAuctionNetworkModal from '../Auction/elements/SwitchToAuctionNetworkModal'

const zero = BigNumber.from(0)

export default function VaultPage({
  account,
  auction,
  auctionActivityData,
  term,

  purchaseCurrency,
  purchaseTokenBalance,
  purchaseTokenAllowance,

  collateralCurrency,
  collateralTokenBalance,
  collateralTokenAllowance,

  nakedCollateralCurrency,
  nakedCollateralTokenBalance,
  nakedCollateralTokenAllowance,

  convertedNakedCollateralTokenBalance,

  gasTokenCurrency,
  gasTokenBalance,

  rateSuggestions,

  loanTenders,
  reloadLoanTenders,
  deleteLoanTenders,
  deleteVaultLoanTenders,
  lockLoanTenders,
  lockVaultLoanTenders,

  borrowTenders,
  reloadBorrowTenders,
  deleteBorrowTenders,
  lockBorrowTenders,

  loadMissingTenderRates,
  isLoadingMissingRates,

  purchaseTokenApprove,
  collateralTokenApprove,
  nakedCollateralTokenApprove,

  referralCode,
  onDeleteReferralCode,

  onConnect,
  onKytCheck,

  onCheckActiveNetwork,

  wrapGasTokenCall,
  wrapTokenCall,
  unwrapGasTokenCall,
  unwrapTokenCall,
}: AuctionPageParams) {
  const { auctionAddress, chainId } = useParams()

  const [searchParams] = useSearchParams()
  const isUsingVault = searchParams.get('vault')

  useEffect(() => {
    if (!!isUsingVault) {
      console.log('vault page activated...')
    }
  }, [isUsingVault])

  const {
    isOpen: isSwitchToAuctionNetworkModalOpen,
    onOpen: onSwitchToAuctionNetworkModalOpen,
    onClose: onSwitchToAuctionNetworkModalClose,
  } = useDisclosure()

  if (auctionAddress === undefined) {
    throw new Error(
      'Auction component may only be used within a route with id param'
    )
  }

  if (chainId === undefined) {
    throw new Error(
      'Auction component may only be used within a route with chain param'
    )
  }

  const parsedChainId = parseInt(chainId, 10)
  if (isNaN(parsedChainId)) {
    throw new Error(`Invalid value provided for ${chainId}`)
  }
  const config = useConfig()
  const chainData = config.chains[parsedChainId]

  const { library: provider } = useEthers()

  const [userDeclinedNetworkSwitch, setUserDeclinedNetworkSwitch] =
    useState(false)

  useEffect(() => {
    if (provider && !userDeclinedNetworkSwitch) {
      provider.getNetwork().then((network) => {
        if (network.chainId !== chainData.chainId) {
          onSwitchToAuctionNetworkModalOpen()
        } else {
          onSwitchToAuctionNetworkModalClose()
        }
      })
    }
  }, [
    provider,
    chainData.chainId,
    userDeclinedNetworkSwitch,
    onSwitchToAuctionNetworkModalOpen,
    onSwitchToAuctionNetworkModalClose,
  ])

  const handleUserDeclinedNetworkSwitch = () => {
    setUserDeclinedNetworkSwitch(true)
    onSwitchToAuctionNetworkModalClose()
  }

  if (
    auction === null ||
    term === null ||
    purchaseCurrency === null ||
    collateralCurrency === null
  ) {
    // TODO: What should we show if there is an error?
    return null
  }

  if (
    auction === undefined ||
    auctionActivityData === undefined ||
    term === undefined ||
    purchaseCurrency === undefined ||
    collateralCurrency === undefined ||
    purchaseTokenBalance === undefined ||
    collateralTokenBalance === undefined ||
    gasTokenCurrency === undefined ||
    gasTokenBalance === undefined ||
    loanTenders === undefined ||
    borrowTenders === undefined ||
    deleteBorrowTenders === undefined ||
    lockBorrowTenders === undefined ||
    deleteLoanTenders === undefined ||
    deleteVaultLoanTenders === undefined ||
    lockLoanTenders === undefined ||
    lockVaultLoanTenders === undefined ||
    loadMissingTenderRates === undefined ||
    onDeleteReferralCode === undefined ||
    onConnect === undefined ||
    onKytCheck === undefined ||
    onCheckActiveNetwork === undefined ||
    wrapGasTokenCall === undefined ||
    wrapTokenCall === undefined ||
    unwrapGasTokenCall === undefined ||
    unwrapTokenCall === undefined
  ) {
    return <AuctionPageEmpty onConnect={onConnect} />
  }

  // Check if the collateral currency is a wrapped token and make sure we have naked collateral info
  if (
    chainData.wrappedTokenMapping &&
    collateralCurrency.address in chainData.wrappedTokenMapping &&
    (nakedCollateralCurrency === undefined ||
      nakedCollateralTokenBalance === undefined ||
      convertedNakedCollateralTokenBalance === undefined)
  ) {
    return <AuctionPageEmpty onConnect={onConnect} />
  }

  return (
    <ParameterizedVaultPage
      chain={chainData}
      auction={auction}
      auctionActivityData={auctionActivityData}
      term={term}
      purchaseCurrency={purchaseCurrency}
      purchaseTokenBalance={purchaseTokenBalance}
      purchaseTokenAllowance={purchaseTokenAllowance ?? zero}
      collateralCurrency={collateralCurrency}
      collateralTokenBalance={collateralTokenBalance}
      collateralTokenAllowance={collateralTokenAllowance ?? zero}
      nakedCollateralCurrency={nakedCollateralCurrency}
      nakedCollateralTokenBalance={nakedCollateralTokenBalance}
      nakedCollateralTokenAllowance={nakedCollateralTokenAllowance ?? zero}
      convertedNakedCollateralTokenBalance={
        convertedNakedCollateralTokenBalance
      }
      gasTokenCurrency={gasTokenCurrency}
      gasTokenBalance={gasTokenBalance}
      rateSuggestions={rateSuggestions}
      loanTenders={loanTenders}
      borrowTenders={borrowTenders}
      isLoadingMissingRates={isLoadingMissingRates}
      referralCode={referralCode}
      onApproveCollateralToken={() => {
        onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        return collateralTokenApprove(
          auction.repoLockerAddress,
          config.approveAmount
        )
      }}
      onApprovePurchaseToken={async (amount?: string) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        return purchaseTokenApprove(
          auction.repoLockerAddress,
          amount || config.approveAmount
        )
      }}
      onApproveNakedCollateralToken={(amount: string) => {
        onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        return nakedCollateralTokenApprove(collateralCurrency.address, amount)
      }}
      onCreateLoanTenders={async (tenders) => {
        if (!account) {
          // We shouldn't be able to get here. The button that calls this should be disabled if
          throw new Error('Account is undefined')
        }
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        console.log('submitting loan tender through vault...')
        await lockVaultLoanTenders(tenders, account)

        // This is only because we're using our localstorage as a mock
        // db of tenders for now.
        reloadLoanTenders()
      }}
      onCreateBorrowTenders={async (tenders) => {
        if (!account) {
          // We shouldn't be able to get here. The button that calls this should be disabled if
          throw new Error('Account is undefined')
        }
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        await lockBorrowTenders(tenders, account)

        // This is only because we're using our localstorage as a mock
        // db of tenders for now.
        reloadBorrowTenders()
      }}
      onDeleteTenders={async (type, ids) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        switch (type) {
          case 'loan': {
            console.log('deleting loan tenders through vault...')
            await deleteVaultLoanTenders(ids)
            reloadLoanTenders()
            break
          }
          case 'borrow': {
            await deleteBorrowTenders(ids)
            reloadBorrowTenders()
            break
          }
        }
      }}
      onEditBorrowTenders={async (tenders) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        if (!account) {
          // We shouldn't be able to get here. The button that calls this should be disabled if
          throw new Error('Account is undefined')
        }

        await lockBorrowTenders(tenders, account)

        // This is only because we're using our localstorage as a mock
        // db of tenders for now.
        reloadBorrowTenders()
      }}
      onEditLoanTenders={async (tenders) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        if (!account) {
          // We shouldn't be able to get here. The button that calls this should be disabled if
          throw new Error('Account is undefined')
        }

        console.log('editing loan tender through vault...')
        await lockVaultLoanTenders(tenders, account)

        // This is only because we're using our localstorage as a mock
        // db of tenders for now.
        reloadLoanTenders()
      }}
      onLoadMissingTenderRates={async (
        bids: string[] | undefined,
        offers: string[] | undefined
      ) => {
        await loadMissingTenderRates(chainData.chainId.toString(), bids, offers)

        // This is only because we're using our localstorage as a mock
        // db of tenders for now.
        reloadLoanTenders()
        reloadBorrowTenders()
      }}
      onDeleteReferralCode={onDeleteReferralCode}
      onConnect={onConnect}
      onKytCheck={onKytCheck}
      onWrapGasTokenCall={async (amount: BigNumber) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        await wrapGasTokenCall(amount)
      }}
      onWrapTokenCall={async (
        address: string,
        symbol: string,
        amount: BigNumber,
        balance?: BigNumber
      ) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        await wrapTokenCall(address, symbol, amount, balance)
      }}
      onUnwrapGasTokenCall={async (amount: BigNumber) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        await unwrapGasTokenCall(amount)
      }}
      onUnwrapTokenCall={async (address: string, amount: BigNumber) => {
        await onCheckActiveNetwork(chainData.chainId, chainData.chainName)
        await unwrapTokenCall(address, amount)
      }}
      isSwitchToAuctionNetworkModalOpen={isSwitchToAuctionNetworkModalOpen}
      onSwitchNetwork={onCheckActiveNetwork}
      userDeclinedNetworkSwitch={handleUserDeclinedNetworkSwitch}
    />
  )
}

export function ParameterizedVaultPage({
  chain,
  auction,
  auctionActivityData,
  term,
  purchaseCurrency,
  purchaseTokenBalance,
  purchaseTokenAllowance,
  collateralCurrency,
  collateralTokenBalance,
  collateralTokenAllowance,
  nakedCollateralCurrency,
  nakedCollateralTokenBalance,
  nakedCollateralTokenAllowance,
  convertedNakedCollateralTokenBalance,
  gasTokenCurrency,
  gasTokenBalance,
  rateSuggestions,
  loanTenders,
  borrowTenders,
  isLoadingMissingRates,
  referralCode,
  onApprovePurchaseToken,
  onApproveCollateralToken,
  onApproveNakedCollateralToken,
  onCreateLoanTenders,
  onCreateBorrowTenders,
  onDeleteTenders,
  onEditLoanTenders,
  onEditBorrowTenders,
  onLoadMissingTenderRates,
  onDeleteReferralCode,
  onConnect,
  onKytCheck,

  onWrapGasTokenCall,
  onWrapTokenCall,
  onUnwrapGasTokenCall,
  onUnwrapTokenCall,
  isSwitchToAuctionNetworkModalOpen,
  onSwitchNetwork,
  userDeclinedNetworkSwitch,
}: {
  chain: ChainSpecificConfig
  auction: Auction
  auctionActivityData: AuctionActivityData
  term: Pick<
    TermPeriod,
    | 'repoServicerAddress'
    | 'rolloverManagerAddress'
    | 'collateralManagerAddress'
    | 'termRepoLockerAddress'
    | 'termRepoTokenAddress'
  >
  purchaseCurrency: Currency
  purchaseTokenBalance: BigNumber
  purchaseTokenAllowance: BigNumber
  collateralCurrency: Currency
  collateralTokenBalance: BigNumber
  collateralTokenAllowance: BigNumber
  nakedCollateralCurrency: Currency | undefined
  nakedCollateralTokenBalance: BigNumber | undefined
  nakedCollateralTokenAllowance: BigNumber | undefined
  convertedNakedCollateralTokenBalance: BigNumber | undefined
  gasTokenCurrency: NativeCurrency
  gasTokenBalance: FixedNumber
  rateSuggestions?: SuggestedRatesByPlatform
  loanTenders: SubmittedLoanTender[]
  borrowTenders: SubmittedBorrowTender[]
  isLoadingMissingRates: boolean
  referralCode: string

  onApprovePurchaseToken: () => Promise<void>
  onApproveCollateralToken: () => Promise<void>
  onApproveNakedCollateralToken: (amount: string) => Promise<void>
  onCreateLoanTenders: (tenders: ValidatedLoanTender[]) => Promise<void>
  onCreateBorrowTenders: (tenders: ValidatedBorrowTender[]) => Promise<void>
  onDeleteTenders: (
    type: 'loan' | 'borrow',
    tenders: TenderId[]
  ) => Promise<void>
  onEditLoanTenders: (tenders: ValidatedLoanTender[]) => Promise<void>
  onEditBorrowTenders: (tenders: ValidatedBorrowTender[]) => Promise<void>
  onLoadMissingTenderRates: (
    bids: string[] | undefined,
    offers: string[] | undefined
  ) => Promise<void>
  onDeleteReferralCode: () => void
  onConnect: () => void
  onKytCheck: () => Promise<boolean>
  onWrapGasTokenCall: (value: BigNumber) => Promise<void>
  onWrapTokenCall: (
    address: string,
    symbol: string,
    value: BigNumber,
    balance?: BigNumber
  ) => Promise<void>
  onUnwrapGasTokenCall: (value: BigNumber) => Promise<void>
  onUnwrapTokenCall: (address: string, value: BigNumber) => Promise<void>
  isSwitchToAuctionNetworkModalOpen: boolean
  onSwitchNetwork: (chainId: ChainId) => Promise<boolean>
  userDeclinedNetworkSwitch: () => void
}) {
  return (
    <>
      <Box maxW="1440px" mx="auto">
        <OpenAuctionStatistics
          chainId={chain.chainId}
          auction={auction}
          term={term}
          purchaseSymbol={purchaseCurrency.symbol}
          collateralSymbol={collateralCurrency.symbol}
        />
        <Box
          px={{
            base: MOBILE_CONTAINER_PADDING,
            xl: CONTAINER_PADDING,
          }}
          py={4}
        >
          <HStack
            justifyContent="space-between"
            w="full"
            alignItems="flex-end"
            spacing="62px"
          >
            <Box>
              <PageTitle title={c.title} />
              <PageDescription>
                <Text>
                  {c.description1}
                  {c.descriptionHead}
                  <Link
                    textDecoration="none"
                    href={c.singleInterestRateLink}
                    isExternal
                  >
                    {c.singleInterestRate}
                  </Link>
                  {c.descriptionTail}
                </Text>
              </PageDescription>
            </Box>
            <FAQBox content={c.faqContent} learnMoreLink={c.learnMoreLink} />
          </HStack>
          <CreateTendersBox
            auction={auction}
            auctionActivityData={auctionActivityData}
            purchaseCurrency={purchaseCurrency}
            purchaseTokenBalance={purchaseTokenBalance}
            purchaseTokenAllowance={purchaseTokenAllowance}
            collateralCurrency={collateralCurrency}
            collateralTokenBalance={collateralTokenBalance}
            collateralTokenAllowance={collateralTokenAllowance}
            nakedCollateralCurrency={nakedCollateralCurrency}
            nakedCollateralTokenBalance={nakedCollateralTokenBalance}
            nakedCollateralTokenAllowance={nakedCollateralTokenAllowance}
            convertedNakedCollateralTokenBalance={
              convertedNakedCollateralTokenBalance
            }
            gasTokenCurrency={gasTokenCurrency}
            gasTokenBalance={gasTokenBalance}
            rateSuggestions={rateSuggestions}
            referralCode={referralCode}
            onCreateLoanTenders={onCreateLoanTenders}
            onCreateBorrowTenders={onCreateBorrowTenders}
            onApproveCollateralToken={onApproveCollateralToken}
            onApprovePurchaseToken={onApprovePurchaseToken}
            onApproveNakedCollateralToken={onApproveNakedCollateralToken}
            onConnect={onConnect}
            onKytCheck={onKytCheck}
            onDeleteReferralCode={onDeleteReferralCode}
            onWrapGasTokenCall={onWrapGasTokenCall}
            onWrapTokenCall={onWrapTokenCall}
            onUnwrapGasTokenCall={onUnwrapGasTokenCall}
            onUnwrapTokenCall={onUnwrapTokenCall}
          />
          <OpenTendersBadge count={loanTenders.length + borrowTenders.length} />
          <OpenTenders
            auction={auction}
            loanTenders={loanTenders}
            borrowTenders={borrowTenders}
            purchaseCurrency={purchaseCurrency}
            collateralCurrency={collateralCurrency}
            onDelete={onDeleteTenders}
            onEditLoanTenders={onEditLoanTenders}
            onEditBorrowTenders={onEditBorrowTenders}
            purchaseTokenBalance={purchaseTokenBalance}
            collateralTokenBalance={collateralTokenBalance}
            purchaseTokenAllowance={purchaseTokenAllowance}
            onApprovePurchaseToken={onApprovePurchaseToken}
            collateralTokenAllowance={collateralTokenAllowance}
            onApproveCollateralToken={onApproveCollateralToken}
            onKytCheck={onKytCheck}
            gasTokenCurrency={gasTokenCurrency}
            gasTokenBalance={gasTokenBalance}
            onLoadMissingTenderRates={onLoadMissingTenderRates}
            isLoadingMissingRates={isLoadingMissingRates}
            onWrapGasTokenCall={onWrapGasTokenCall}
            onUnwrapGasTokenCall={onUnwrapGasTokenCall}
          />
        </Box>
      </Box>
      <SwitchToAuctionNetworkModal
        isOpen={isSwitchToAuctionNetworkModalOpen}
        onClose={userDeclinedNetworkSwitch}
        onSwitchNetwork={onSwitchNetwork}
        chainId={chain.chainId}
      />
    </>
  )
}
