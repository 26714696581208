import { Text, Box, HStack, Link } from '@chakra-ui/react'
import BombPot from '../BombPotGif'
import { bombPotLinks } from '../../../socials'

const infoStyles = {
  py: '12px',
  px: '16px',
  w: '321px',
  borderRadius: '6px',
  border: '1px',
  borderColor: 'rgba(0, 153, 255, 0.40)',
  background:
    'linear-gradient(0deg, rgba(0, 77, 189, 0.06) 16.44%, rgba(0, 153, 255, 0.06) 103.04%), #FFF',
  boxShadow: '0px 0px 20px 0px rgba(0, 153, 255, 0.10)',
}

export default function BombPotInfoCard({
  amount,
  symbol,
}: {
  amount: number
  symbol: string
}) {
  const bombPotLink = bombPotLinks?.[symbol.toLowerCase()] ?? '#'

  return (
    <Box sx={infoStyles}>
      <HStack gap={2}>
        <BombPot h="43px" opacity={0.7} />
        <Box>
          <Text variant="body-sm/medium" color="blue.9" mb={1}>
            {amount}{' '}
            <Text textTransform="uppercase" as="span">
              {symbol}
            </Text>{' '}
            Bomb Pot{' '}
          </Text>
          <Text variant="body-xs/normal" color="blue.9" opacity={0.5}>
            Supply & earn your portion of the Bomb Pot
          </Text>
          <Text variant="body-xs/medium">
            <Link isExternal textDecoration="none" href={bombPotLink}>
              Read the article
            </Link>
          </Text>
        </Box>
      </HStack>
    </Box>
  )
}
