import dayjs from 'dayjs'
import { BigNumber, constants, FixedNumber } from 'ethers'
import {
  Address,
  Auction,
  AuctionStatus,
  ContractAddressesGrouping,
  Currency,
  LiquidationForDisplay,
  RolloverAuctionInfo,
  SubmittedBorrowTender,
  SubmittedLoanTender,
  TermBorrow,
  TermLoan,
  TermPeriod,
  BombPotReward,
  TransformedListingsData,
  MappedPurchases,
  MappedListings,
} from '../../data/model'
import {
  getAuctionDisplayId,
  getAuctionStatus,
  getAuctionTermString,
  hasStableCoinInPair,
} from '../../helpers/utils'
import {
  bigToFixedNumber,
  convertChainId,
  isTermRepoCurrency,
} from '../../helpers/conversions'
import { add, divide, multiply, subtract } from '../../helpers/math'
import { ChainId } from '@usedapp/core'

export function calculateExcess(
  loanAmountUsd: FixedNumber,
  balance: FixedNumber,
  tokenPrice: FixedNumber,
  requiredMarginRatio: FixedNumber,
  transaction: FixedNumber
) {
  const balanceUsd = multiply(balance, tokenPrice, balance.format.decimals)

  let balanceRatio: FixedNumber
  if (loanAmountUsd.isZero()) {
    balanceRatio = FixedNumber.fromString('0', loanAmountUsd.format)
  } else {
    balanceRatio = divide(balanceUsd, loanAmountUsd, balanceUsd.format.decimals)
  }

  const requiredUsd = multiply(
    loanAmountUsd,
    requiredMarginRatio,
    loanAmountUsd.format.decimals
  )
  const required = divide(requiredUsd, tokenPrice, requiredUsd.format.decimals)
  const excessUsd = subtract(
    balanceUsd,
    requiredUsd,
    balanceUsd.format.decimals
  )
  const excess = subtract(balance, required, balance.format.decimals)

  const balanceUsdAfter = add(
    balanceUsd,
    multiply(transaction, tokenPrice, balanceUsd.format.decimals),
    balanceUsd.format.decimals
  )

  const balanceAfter = add(balance, transaction, balance.format.decimals)

  let balanceRatioAfter: FixedNumber

  if (loanAmountUsd.isZero()) {
    balanceRatioAfter = FixedNumber.fromString('0', loanAmountUsd.format)
  } else {
    balanceRatioAfter = divide(
      balanceUsdAfter,
      loanAmountUsd,
      balanceUsdAfter.format.decimals
    )
  }

  const excessUsdAfter = subtract(
    balanceUsdAfter,
    requiredUsd,
    balanceUsdAfter.format.decimals
  )
  const excessAfter = add(excess, transaction, excess.format.decimals)

  return {
    balanceUsd,
    balanceRatio,
    requiredUsd,
    required,
    excessUsd,
    excess,
    balanceUsdAfter,
    balanceAfter,
    balanceRatioAfter,
    excessUsdAfter,
    excessAfter,
  }
}

export type OpenBorrow = {
  chainId: string
  maturityTimestamp: number
  endOfRepaymentTimestamp: number
  redemptionTimestamp: number

  repoCollateralManager: Address
  repoServicer: Address
  repoRolloverManager: Address
  repoLocker: Address

  purchaseTokenSymbol: string
  collateralTokenSymbol: string

  // Unit: purchase token
  outstandingDebt: FixedNumber
  amountBorrowLiquidated: FixedNumber
  lastLiquidationTx?: string
  rolloverAmount: FixedNumber | undefined
  rolloverElected: boolean | undefined
  rolloverFulfilledAmount: FixedNumber | undefined
  rolloverFulfilled: boolean | undefined
  rolloverAuctionBidLocker: Address | undefined
  rolloverInterestRate: FixedNumber | undefined
  rolloverLocked: boolean | undefined
  rolloverCancelled: boolean | undefined
  rolloverFailed: boolean | undefined
  rolloverPartiallyFailed: boolean | undefined
  principalDebt: FixedNumber
  interestDebt: FixedNumber
  repurchasePrice: FixedNumber
  amountCollected: FixedNumber

  // Unit: collateral token
  collateralRequired: FixedNumber
  collateralRequiredUsd: FixedNumber
  collateralRequiredRatio: FixedNumber
  collateralDeposited: FixedNumber
  collateralDepositedUsd: FixedNumber
  collateralDepositedRatio: FixedNumber

  termTokenCurrency: Currency
  termTokenBalance: FixedNumber
  termTokenPrice: FixedNumber
  collateralCurrency: Currency
  collateralBalance: FixedNumber
  collateralPrice: FixedNumber
  purchaseCurrency: Currency
  purchaseBalance: FixedNumber
  purchasePrice: FixedNumber
  purchaseTokensApproved: FixedNumber
  collateralTokensApproved: FixedNumber

  liquidations?: LiquidationForDisplay[]
  liquidationPrice: FixedNumber

  maintenanceMarginRatio: FixedNumber

  termId: string
  termVersion: string
  termPeriodLength: string
  lastAuctionId?: string
  lastAuctionEndTimestamp?: number
  lastAuctionClearingRate?: FixedNumber

  contractAddresses: ContractAddressesGrouping
}

type OpenBorrowTotals = {
  count: number
  outstandingDebtUsd: FixedNumber
  principalDebtUsd: FixedNumber
  interestDebtUsd: FixedNumber
  originalPrincipalUsd: FixedNumber
  originalInterestUsd: FixedNumber
  collateralRequiredUsd: FixedNumber
  collateralDepositedUsd: FixedNumber
}

export type OpenBorrows = {
  chainTotals: { [chainId: string]: OpenBorrowTotals }
  totals: OpenBorrowTotals
  borrows: {
    [chainId: string]: OpenBorrow[]
  }
}

export type PartialAuctionInfo = {
  loanPrincipal: FixedNumber
  loanInterest: FixedNumber
  auctionId?: string
  auctionEndTimestamp?: number
  auctionClearingRate?: FixedNumber
  // bombPotAuction:
}

export type OpenLoan = {
  chainId: string
  maturityTimestamp: number
  redemptionTimestamp: number
  termPeriodLength: string
  termId: string

  repoServicerAddress: Address

  purchaseTokenSymbol: string

  // Unit: purchase token
  outstandingCredit: FixedNumber

  // TODO: legacy loan side rollover, speak with Dion before removing this
  rolloverAmount?: FixedNumber

  auctionInfo: PartialAuctionInfo[]

  collateralCurrency: Currency
  purchaseCurrency: Currency
  termTokenCurrency: Currency

  collateralBalance: FixedNumber
  purchaseBalance: FixedNumber
  termTokenBalance: FixedNumber

  collateralPrice: FixedNumber
  purchaseTokenPrice: FixedNumber
  termTokenPrice: FixedNumber

  isRepoTokenTransfer: boolean
  hasOutstandingRepoExposure: boolean

  contractAddresses: ContractAddressesGrouping

  termRepoTokensApproved: FixedNumber

  bombPotApy?: FixedNumber
  bombPotReward?: FixedNumber

  openListings: FixedNumber
  openListingsPurchaseCurrency: FixedNumber
  openListingsUsd: FixedNumber
  soldListings: FixedNumber
  soldListingsUsd: FixedNumber

  listings?: {
    listings?: MappedListings
    purchases?: MappedPurchases
  }
}

type OpenLoanTotals = {
  count: number
  outstandingCreditUsd: FixedNumber
  outstandingPrincipalCreditUsd: FixedNumber
  outstandingInterestCreditUsd: FixedNumber
  receivedRepoTokensUsd: FixedNumber
  loanPrincipalUsd: FixedNumber
  loanInterestUsd: FixedNumber
  openListings: FixedNumber
  openListingsUsd: FixedNumber
  soldListings: FixedNumber
  soldListingsUsd: FixedNumber
}

export type OpenLoans = {
  chainTotals: { [chainId: string]: OpenLoanTotals }
  totals: OpenLoanTotals
  loans: {
    [chainId: string]: OpenLoan[]
  }
}

export type OpenListingProceeds = {
  purchaseCurrency: Currency
  termCurrency: Currency
  term: string
  listedTokensBalance: FixedNumber
}

const zero = FixedNumber.fromString('0')
const one = FixedNumber.fromString('1')

export function summarizeLoans(
  terms: Record<string, { [termRepoTokenAddress: Address]: TermPeriod }>,
  loans: TermLoan[],
  borrows: TermBorrow[],
  currencies: Record<string, { [address: Address]: Currency }>,
  prices: Record<string, { [address: Address]: FixedNumber }>,
  balances: Record<string, { [address: Address]: FixedNumber }>,
  purchaseTokenApprovals: Record<string, { [address: Address]: FixedNumber }>,
  collateralTokenApprovals: Record<string, { [address: Address]: FixedNumber }>,
  termRepoTokenApprovals: Record<string, { [address: Address]: FixedNumber }>,
  repoExposures: { [termId: Address]: BigNumber },
  rolloverAuctionOptions: RolloverAuctionInfo[],
  userBombPots: Record<string, BombPotReward[]> = {},
  listings: {
    [chainId: string]: { [termRepoToken: Address]: TransformedListingsData }
  }
): [OpenBorrows, OpenLoans] {
  const totalFormat = 'fixed128x18'
  const openBorrowsByChain: { [chainId: string]: OpenBorrow[] } = {}

  const borrowTotals = {} as OpenBorrowTotals
  borrowTotals.count = 0
  const chainBorrowTotals = {} as { [chainId: string]: OpenBorrowTotals }

  const loanTotals = {} as OpenLoanTotals
  loanTotals.count = 0
  const chainLoanTotals = {} as { [chainId: string]: OpenLoanTotals }

  let totalOutstandingDebtUsd = FixedNumber.fromString('0', totalFormat)
  let totalPrincipalDebtUsd = FixedNumber.fromString('0', totalFormat)
  let totalInterestDebtUsd = FixedNumber.fromString('0', totalFormat)
  let totalOriginalPrincipalUsd = FixedNumber.fromString('0', totalFormat)
  let totalOriginalInterestUsd = FixedNumber.fromString('0', totalFormat)
  let totalCollateralRequiredUsd = FixedNumber.fromString('0', totalFormat)
  let totalCollateralDepositedUsd = FixedNumber.fromString('0', totalFormat)
  for (const borrow of borrows) {
    if (!openBorrowsByChain[borrow.chainId]) {
      openBorrowsByChain[borrow.chainId] = []
    }

    const chainTotal = chainBorrowTotals[borrow.chainId] || {
      count: 0,
      outstandingDebtUsd: FixedNumber.fromString('0', totalFormat),
      principalDebtUsd: FixedNumber.fromString('0', totalFormat),
      interestDebtUsd: FixedNumber.fromString('0', totalFormat),
      originalPrincipalUsd: FixedNumber.fromString('0', totalFormat),
      originalInterestUsd: FixedNumber.fromString('0', totalFormat),
      collateralRequiredUsd: FixedNumber.fromString('0', totalFormat),
      collateralDepositedUsd: FixedNumber.fromString('0', totalFormat),
    }

    const collateralCurrency =
      currencies[borrow.chainId][borrow.collateralCurrency]
    const collateralFormat = `fixed128x${collateralCurrency.decimals}`
    const collateralPrice = prices[borrow.chainId][borrow.collateralCurrency]
    const purchaseCurrency = currencies[borrow.chainId][borrow.purchaseCurrency]
    const purchaseFormat = `fixed128x${purchaseCurrency.decimals}`
    const purchasePrice = prices[borrow.chainId][borrow.purchaseCurrency]
    const termTokenCurrency =
      currencies[borrow.chainId][borrow.termTokenCurrency]
    const purchaseTokensApproved =
      purchaseTokenApprovals[borrow.chainId][borrow.termId]
    const collateralTokensApproved =
      collateralTokenApprovals[borrow.chainId][borrow.termId]

    const purchaseAmountFn = borrow.purchasePrice
    const repurchaseAmountFn = borrow.repurchasePrice

    const purchaseAmountUsd = multiply(
      purchaseAmountFn,
      purchasePrice,
      purchaseAmountFn.format.decimals
    )
    const repurchaseAmountUsd = multiply(
      repurchaseAmountFn,
      purchasePrice,
      repurchaseAmountFn.format.decimals
    )
    const loanInterestFn = subtract(
      repurchaseAmountFn,
      purchaseAmountFn,
      repurchaseAmountFn.format.decimals
    )
    const loanInterestUsd = subtract(
      repurchaseAmountUsd,
      purchaseAmountUsd,
      repurchaseAmountFn.format.decimals
    )

    // Collateral Deposited: collateral_balance (has wrong number of decimal places) * collateral_price_usd (multiplier not included if we want to show USD)
    const collateralDeposited = (
      borrow.collateralBalances[collateralCurrency.address] ?? zero
    ).toFormat(collateralFormat)

    const collateralDepositedUsd = multiply(
      collateralDeposited,
      collateralPrice,
      collateralCurrency.decimals
    )

    // Collateral Required: mmr * outstanding_debt_purchase_tokens * purchase_price_usd / collateral_price_usd (divisor not included if we want to show USD)
    const outstandingDebt = borrow.borrowBalance.toFormat(purchaseFormat)

    const outstandingDebtUsd = multiply(
      outstandingDebt,
      purchasePrice,
      purchaseCurrency.decimals
    )

    const collateralRequiredPt = multiply(
      outstandingDebt,
      borrow.maintenanceMarginRatio,
      collateralCurrency.decimals
    )

    const collateralRequired = divide(
      multiply(collateralRequiredPt, purchasePrice),
      collateralPrice.isZero()
        ? one.toFormat(collateralFormat)
        : collateralPrice,
      collateralCurrency.decimals
    )

    const collateralRequiredUsd = multiply(
      collateralRequired,
      collateralPrice,
      collateralCurrency.decimals
    )

    let liquidationPrice = divide(
      multiply(outstandingDebtUsd, borrow.maintenanceMarginRatio),
      collateralDeposited.isZero()
        ? one.toFormat(outstandingDebtUsd.format)
        : collateralDeposited,
      outstandingDebtUsd.format.decimals
    )

    // if no stable coins in pair represent liquidation price in purchseCurrency
    if (
      !hasStableCoinInPair(purchaseCurrency.symbol, collateralCurrency.symbol)
    ) {
      liquidationPrice = divide(
        liquidationPrice,
        purchasePrice,
        liquidationPrice.format.decimals
      )
    }

    const collateralDepositedRatio = !outstandingDebtUsd?.isZero()
      ? divide(collateralDepositedUsd, outstandingDebtUsd, 18)
      : bigToFixedNumber(constants.MaxUint256, 18) // If there is no outstanding debt, the collateral ratio is infinite

    const lastAuctionEndTimestamp =
      terms[borrow.chainId][borrow.termTokenCurrency].completedAuctions?.[0]
        ?.auctionEndTimestamp
    const maturityTimestamp =
      terms[borrow.chainId][borrow.termTokenCurrency].maturityTimestamp

    const lastAuctionClearingRateBN =
      terms[borrow.chainId][borrow.termTokenCurrency]?.completedAuctions?.[0]
        ?.auctionClearingRate

    const electedRolloverAuctionInfo = rolloverAuctionOptions.filter(
      (a) => a.auctionBidLocker === borrow.rolloverAuctionBidLocker
    )

    const rolloverFailed =
      !!electedRolloverAuctionInfo[0] &&
      electedRolloverAuctionInfo[0].auctionComplete &&
      !borrow.rolloverLocked &&
      borrow.rolloverFulfilled &&
      borrow.rolloverAmount &&
      borrow.rolloverFulfilledAmount &&
      borrow.rolloverFulfilledAmount.isZero()

    const rolloverPartiallyFailed =
      borrow.rolloverFulfilled &&
      borrow.rolloverAmount &&
      borrow.rolloverFulfilledAmount &&
      !borrow.rolloverFulfilledAmount.isZero() &&
      subtract(
        borrow.rolloverFulfilledAmount,
        borrow.rolloverAmount
      ).isNegative()

    const contractAddresses = {
      auction:
        terms[borrow.chainId][borrow.termTokenCurrency].completedAuctions?.[0]
          .address,
      auctionBidLocker:
        terms[borrow.chainId][borrow.termTokenCurrency].completedAuctions?.[0]
          .bidLockerAddress,
      auctionOfferLocker:
        terms[borrow.chainId][borrow.termTokenCurrency].completedAuctions?.[0]
          .offerLockerAddress,
      termRepoLocker: borrow.repoLocker,
      termRepoServicer: borrow.repoServicer,
      termCollateralManager: borrow.repoCollateralManager,
      termRolloverManager: borrow.repoRolloverManager,
      termRepoToken: borrow.termTokenCurrency,
    } as ContractAddressesGrouping

    const openBorrow: OpenBorrow = {
      chainId: borrow.chainId,
      maturityTimestamp: borrow.maturityTimestamp,
      endOfRepaymentTimestamp: borrow.repaymentTimestamp,
      redemptionTimestamp: borrow.redemptionTimestamp,

      repoCollateralManager: borrow.repoCollateralManager,
      repoRolloverManager: borrow.repoRolloverManager,
      repoServicer: borrow.repoServicer,
      repoLocker: borrow.repoLocker,

      purchaseTokenSymbol: purchaseCurrency.symbol,
      collateralTokenSymbol: collateralCurrency.symbol,

      // Unit: purchase token
      outstandingDebt,
      amountBorrowLiquidated: borrow.amountLiquidated,
      lastLiquidationTx: borrow.lastLiquidationTxHash,
      rolloverAmount: borrow.rolloverAmount,
      rolloverElected:
        borrow.rolloverAmount &&
        !borrow.rolloverFulfilled &&
        !borrow.rolloverCancelled,
      rolloverFulfilled: borrow.rolloverFulfilled,
      rolloverFulfilledAmount: borrow.rolloverFulfilledAmount,
      rolloverLocked: borrow.rolloverLocked,
      rolloverCancelled: borrow.rolloverCancelled,
      rolloverFailed,
      rolloverPartiallyFailed,
      rolloverInterestRate: borrow.rolloverInterestRate,
      rolloverAuctionBidLocker: borrow.rolloverAuctionBidLocker,
      principalDebt: purchaseAmountFn,
      interestDebt: loanInterestFn,
      repurchasePrice: repurchaseAmountFn,
      amountCollected: borrow.amountCollected,

      // Unit: collateral token
      collateralRequired,
      collateralRequiredUsd,
      collateralRequiredRatio: borrow.maintenanceMarginRatio,
      collateralDeposited,
      collateralDepositedUsd,
      collateralDepositedRatio: collateralDepositedRatio,

      termTokenCurrency,
      termTokenPrice: prices[borrow.chainId][borrow.termTokenCurrency],
      termTokenBalance: balances[borrow.chainId][borrow.termTokenCurrency],

      collateralCurrency,
      collateralPrice,
      collateralBalance: balances[borrow.chainId][borrow.collateralCurrency],

      purchaseCurrency,
      purchasePrice,
      purchaseBalance: balances[borrow.chainId][borrow.purchaseCurrency],
      purchaseTokensApproved,
      collateralTokensApproved,

      maintenanceMarginRatio: borrow.maintenanceMarginRatio,

      liquidations: borrow.liquidations,
      liquidationPrice,

      termId: borrow.termId,
      termVersion: terms[borrow.chainId][borrow.termTokenCurrency].version,
      termPeriodLength:
        lastAuctionEndTimestamp && maturityTimestamp
          ? getAuctionTermString(lastAuctionEndTimestamp, maturityTimestamp)
          : '',

      lastAuctionId:
        terms[borrow.chainId][borrow.termTokenCurrency].completedAuctions?.[0]
          ?.auctionId,
      lastAuctionEndTimestamp: lastAuctionEndTimestamp,
      lastAuctionClearingRate: lastAuctionClearingRateBN
        ? bigToFixedNumber(lastAuctionClearingRateBN, 18)
        : undefined,

      contractAddresses,
    }

    openBorrowsByChain[borrow.chainId].push(openBorrow)

    chainTotal.outstandingDebtUsd = add(
      chainTotal.outstandingDebtUsd,
      outstandingDebtUsd,
      18
    )
    totalOutstandingDebtUsd = add(
      totalOutstandingDebtUsd,
      outstandingDebtUsd,
      18
    )

    if (!subtract(outstandingDebtUsd, loanInterestUsd, 18).isNegative()) {
      chainTotal.principalDebtUsd = subtract(
        add(chainTotal.principalDebtUsd, outstandingDebtUsd),
        loanInterestUsd,
        18
      )
      totalPrincipalDebtUsd = subtract(
        add(totalPrincipalDebtUsd, outstandingDebtUsd),
        loanInterestUsd,
        18
      )
      chainTotal.interestDebtUsd = add(
        chainTotal.interestDebtUsd,
        loanInterestUsd,
        18
      )
      totalInterestDebtUsd = add(totalInterestDebtUsd, loanInterestUsd, 18)
    } else {
      chainTotal.interestDebtUsd = add(
        chainTotal.interestDebtUsd,
        outstandingDebtUsd,
        18
      )
      totalInterestDebtUsd = add(totalInterestDebtUsd, outstandingDebtUsd, 18)
    }

    totalOriginalPrincipalUsd = add(
      totalOriginalPrincipalUsd,
      purchaseAmountUsd,
      18
    )
    totalOriginalInterestUsd = add(
      totalOriginalInterestUsd,
      loanInterestUsd,
      18
    )
    totalCollateralRequiredUsd = add(
      totalCollateralRequiredUsd,
      collateralRequiredUsd,
      18
    )
    totalCollateralDepositedUsd = add(
      totalCollateralDepositedUsd,
      collateralDepositedUsd,
      18
    )

    chainTotal.originalPrincipalUsd = add(
      chainTotal.originalPrincipalUsd,
      purchaseAmountUsd,
      18
    )
    chainTotal.originalInterestUsd = add(
      chainTotal.originalInterestUsd,
      loanInterestUsd,
      18
    )
    chainTotal.collateralRequiredUsd = add(
      chainTotal.collateralRequiredUsd,
      collateralRequiredUsd,
      18
    )
    chainTotal.collateralDepositedUsd = add(
      chainTotal.collateralDepositedUsd,
      collateralDepositedUsd,
      18
    )

    // increment count of borrows
    chainTotal.count++
    borrowTotals.count++
    chainBorrowTotals[borrow.chainId] = chainTotal
  }

  let totalOutstandingCreditUsd = FixedNumber.fromString('0', totalFormat)
  let totalRepoTokensReceivedUsd = FixedNumber.fromString('0', totalFormat)
  let totalPrincipalCreditUsd = FixedNumber.fromString('0', totalFormat)
  let totalInterestCreditUsd = FixedNumber.fromString('0', totalFormat)
  let totalClaimablePrincipleCreditUsd = FixedNumber.fromString(
    '0',
    totalFormat
  )
  let totalClaimableInterestCreditUsd = FixedNumber.fromString('0', totalFormat)

  let totalNumberOpenListings = FixedNumber.fromString('0', totalFormat)
  let totalOpenListingsUsd = FixedNumber.fromString('0', totalFormat)
  let totalNumberSoldListings = FixedNumber.fromString('0', totalFormat)
  let totalSoldListingsUsd = FixedNumber.fromString('0', totalFormat)

  const openLoansByChain: {
    [chainId: string]: { [termRepoTokenAddress: string]: OpenLoan }
  } = {}
  for (const loan of loans) {
    if (!openLoansByChain[loan.chainId]) {
      openLoansByChain[loan.chainId] = {}
    }

    const termRepoToken = loan.termTokenCurrency
    const bombPotRewards = userBombPots?.[termRepoToken]?.[0]

    const chainTotal = chainLoanTotals[loan.chainId] || {
      count: 0,
      outstandingCreditUsd: FixedNumber.fromString('0', totalFormat),
      outstandingPrincipalCreditUsd: FixedNumber.fromString('0', totalFormat),
      outstandingInterestCreditUsd: FixedNumber.fromString('0', totalFormat),
      loanPrincipalUsd: FixedNumber.fromString('0', totalFormat),
      receivedRepoTokensUsd: FixedNumber.fromString('0', totalFormat),
      loanInterestUsd: FixedNumber.fromString('0', totalFormat),
      openListings: FixedNumber.fromString('0', totalFormat),
      openListingsUsd: FixedNumber.fromString('0', totalFormat),
      soldListings: FixedNumber.fromString('0', totalFormat),
      soldListingsUsd: FixedNumber.fromString('0', totalFormat),
    }

    const purchaseCurrency = currencies[loan.chainId][loan.purchaseCurrency]

    const purchaseFormat = `fixed128x${purchaseCurrency.decimals}`
    const purchasePrice = prices[loan.chainId][loan.purchaseCurrency]
    const collateralCurrency = currencies[loan.chainId][loan.collateralCurrency]
    const termTokenCurrency = currencies[loan.chainId][loan.termTokenCurrency]

    const openListings =
      listings?.[loan.chainId]?.[loan.termTokenCurrency]?.listings
        ?.totalRemainingAmount ??
      FixedNumber.fromString('0', `fixed128x${termTokenCurrency.decimals}`)
    const soldListings =
      listings?.[loan.chainId]?.[loan.termTokenCurrency]?.listings
        ?.totalAmountSold ??
      FixedNumber.fromString('0', `fixed128x${termTokenCurrency.decimals}`)

    // include any unsold listings in the outstanding credit
    const outstandingCredit = add(
      balances[loan.chainId][loan.termTokenCurrency],
      openListings,
      balances[loan.chainId][loan.termTokenCurrency].format.decimals
    )

    const termRepoTokensApproved =
      termRepoTokenApprovals[loan.chainId][loan.termId]

    const outstandingCreditPurchaseCurrency = multiply(
      outstandingCredit,
      prices[loan.chainId][loan.termTokenCurrency],
      18
    )

    const openListingsPurchaseCurrency = multiply(
      openListings,
      prices[loan.chainId][loan.termTokenCurrency],
      purchaseCurrency.decimals
    )

    const outstandingCreditUsd = multiply(
      outstandingCreditPurchaseCurrency,
      purchasePrice,
      18
    )

    const openListingsUsd = multiply(
      listings?.[loan.chainId]?.[loan.termTokenCurrency]?.listings
        ?.totalRemainingAmount ?? FixedNumber.fromString('0'),
      purchasePrice,
      purchaseCurrency.decimals
    )

    const soldListingsUsd = multiply(
      listings?.[loan.chainId]?.[loan.termTokenCurrency]?.listings?.totalCost ??
        FixedNumber.fromString('0'),
      purchasePrice,
      purchaseCurrency.decimals
    )

    const initialLoan = FixedNumber.fromValue(
      loan.purchasePrice,
      purchaseCurrency.decimals,
      purchaseFormat
    )

    const initialLoanUsd = multiply(
      initialLoan,
      purchasePrice,
      purchaseCurrency.decimals
    )

    const repurchase = FixedNumber.fromValue(
      loan.repurchasePrice,
      purchaseCurrency.decimals,
      purchaseFormat
    )

    const repurchaseUsd = multiply(
      repurchase,
      purchasePrice,
      purchaseCurrency.decimals
    )

    const interest = subtract(repurchase, initialLoan)
    const interestUsd = subtract(repurchaseUsd, initialLoanUsd)

    const contractAddresses = {
      auction: loan.auction,
      auctionBidLocker: loan.auctionBidLocker,
      auctionOfferLocker: loan.auctionOfferLocker,
      termRepoLocker:
        terms[loan.chainId][loan.termTokenCurrency].termRepoLockerAddress,
      termRepoServicer: loan.repoServicerAddress,
      termCollateralManager:
        terms[loan.chainId][loan.termTokenCurrency].collateralManagerAddress,
      termRolloverManager:
        terms[loan.chainId][loan.termTokenCurrency].rolloverManagerAddress,
      termRepoToken: loan.termTokenCurrency,
    } as ContractAddressesGrouping

    const auctionInfo = {
      loanPrincipal: initialLoan,
      loanInterest: interest,
      auctionId: loan.auctionId,
      auctionEndTimestamp: loan.auctionEndTimestamp,
      auctionClearingRate: bigToFixedNumber(loan.auctionClearingRate, 18),
    } as PartialAuctionInfo

    // show a unique row per term
    if (!openLoansByChain[loan.chainId][termRepoToken]) {
      openLoansByChain[loan.chainId][termRepoToken] = {
        chainId: loan.chainId,
        maturityTimestamp: loan.maturityTimestamp,
        redemptionTimestamp: loan.redemptionTimestamp,
        termPeriodLength: getAuctionTermString(
          loan.auctionEndTimestamp,
          loan.maturityTimestamp
        ),
        termId: loan.termId,
        repoServicerAddress: loan.repoServicerAddress,
        purchaseTokenSymbol: purchaseCurrency.symbol,

        outstandingCredit,
        rolloverAmount: loan.rolloverAmount
          ? FixedNumber.fromValue(
              loan.rolloverAmount,
              purchaseCurrency.decimals,
              purchaseFormat
            )
          : undefined,

        auctionInfo: [auctionInfo],

        collateralCurrency,
        purchaseCurrency,
        termTokenCurrency,

        collateralBalance: balances[loan.chainId][loan.collateralCurrency],
        purchaseBalance: balances[loan.chainId][loan.purchaseCurrency],
        termTokenBalance: balances[loan.chainId][loan.termTokenCurrency],
        termRepoTokensApproved,

        collateralPrice: prices[loan.chainId][loan.collateralCurrency],
        purchaseTokenPrice: purchasePrice,
        termTokenPrice: prices[loan.chainId][loan.termTokenCurrency],

        isRepoTokenTransfer: false,
        hasOutstandingRepoExposure: repoExposures[
          terms[loan.chainId][loan.termTokenCurrency]?.id
        ]
          ? !repoExposures[
              terms[loan.chainId][loan.termTokenCurrency].id
            ].isZero()
          : false,

        contractAddresses,

        bombPotApy: bombPotRewards?.impliedApy
          ? FixedNumber.fromString(bombPotRewards.impliedApy.toString())
          : undefined,
        bombPotReward: bombPotRewards?.userReward
          ? FixedNumber.fromString(bombPotRewards.userReward.toString())
          : undefined,

        openListings,
        openListingsPurchaseCurrency,
        openListingsUsd,
        soldListings,
        soldListingsUsd,

        listings: {
          listings:
            listings?.[loan.chainId]?.[loan.termTokenCurrency]?.listings ??
            undefined,
          purchases:
            listings?.[loan.chainId]?.[loan.termTokenCurrency]?.purchases ??
            undefined,
        },
      }
    } else {
      openLoansByChain[loan.chainId][termRepoToken].auctionInfo.push(
        auctionInfo
      )
      continue
    }

    const formattedOutstandingCreditUsd =
      outstandingCreditUsd.toFormat(totalFormat)
    const formattedInterestCreditUsd = interestUsd.toFormat(totalFormat)

    if (!subtract(outstandingCreditUsd, interestUsd, 18).isNegative()) {
      chainTotal.loanPrincipalUsd = add(
        chainTotal.loanPrincipalUsd,
        subtract(formattedOutstandingCreditUsd, formattedInterestCreditUsd),
        18
      )
      totalClaimablePrincipleCreditUsd = add(
        totalClaimablePrincipleCreditUsd,
        subtract(formattedOutstandingCreditUsd, formattedInterestCreditUsd),
        18
      )
      chainTotal.loanInterestUsd = add(
        chainTotal.loanInterestUsd,
        formattedInterestCreditUsd,
        18
      )
      totalClaimableInterestCreditUsd = add(
        totalClaimableInterestCreditUsd,
        formattedInterestCreditUsd
      )
    } else {
      chainTotal.loanInterestUsd = add(
        chainTotal.loanInterestUsd,
        formattedOutstandingCreditUsd,
        18
      )
      totalClaimableInterestCreditUsd = add(
        totalClaimableInterestCreditUsd,
        formattedOutstandingCreditUsd
      )
    }

    totalOutstandingCreditUsd = add(
      totalOutstandingCreditUsd,
      outstandingCreditUsd,
      18
    )
    totalRepoTokensReceivedUsd = add(
      totalRepoTokensReceivedUsd,
      FixedNumber.fromString('0', totalFormat),
      18
    )

    totalPrincipalCreditUsd = add(totalPrincipalCreditUsd, initialLoanUsd, 18)
    totalInterestCreditUsd = add(totalInterestCreditUsd, interestUsd, 18)

    chainTotal.outstandingCreditUsd = add(
      chainTotal.outstandingCreditUsd,
      outstandingCreditUsd,
      18
    )
    chainTotal.outstandingPrincipalCreditUsd = add(
      chainTotal.outstandingPrincipalCreditUsd,
      initialLoanUsd,
      18
    )
    chainTotal.outstandingInterestCreditUsd = add(
      chainTotal.outstandingInterestCreditUsd,
      interestUsd,
      18
    )

    chainTotal.receivedRepoTokensUsd = add(
      chainTotal.receivedRepoTokensUsd,
      FixedNumber.fromString('0', totalFormat),
      18
    )

    totalNumberOpenListings = add(totalNumberOpenListings, openListings)
    totalOpenListingsUsd = add(totalOpenListingsUsd, soldListingsUsd)
    totalNumberSoldListings = add(totalNumberSoldListings, soldListings)
    totalSoldListingsUsd = add(totalSoldListingsUsd, soldListingsUsd)

    chainTotal.openListings = add(chainTotal.openListings, openListings)
    chainTotal.openListingsUsd = add(
      chainTotal.openListingsUsd,
      openListingsUsd
    )
    chainTotal.soldListings = add(chainTotal.soldListings, soldListings)
    chainTotal.soldListingsUsd = add(chainTotal.soldListingsUsd, soldListings)

    // increment count of loans
    chainTotal.count++
    loanTotals.count++
    chainLoanTotals[loan.chainId] = chainTotal
  }

  // map any term tokens receieved via token transfer to an empty open loan
  // that has outstanding credit but 0 loan/interest values
  for (const [chainId, chainBalances] of Object.entries(balances)) {
    for (const termTokenAddress of Object.keys(chainBalances)) {
      if (
        !loans.find(
          (loan) =>
            loan.chainId === chainId &&
            loan.termTokenCurrency === termTokenAddress
        )
      ) {
        if (!openLoansByChain[chainId]) {
          openLoansByChain[chainId] = {}
        }

        const openListings =
          listings?.[chainId]?.[termTokenAddress]?.listings
            ?.totalRemainingAmount ??
          FixedNumber.fromString('0', chainBalances[termTokenAddress]?.format)
        const soldListings =
          listings?.[chainId]?.[termTokenAddress]?.listings?.totalAmountSold ??
          FixedNumber.fromString('0', chainBalances[termTokenAddress]?.format)

        // include any unsold listings in the outstanding credit
        const outstandingCredit = add(
          chainBalances[termTokenAddress],
          openListings,
          chainBalances[termTokenAddress]?.format?.decimals
        )

        if (outstandingCredit.isZero()) {
          continue
        }

        // get currency object
        const termTokenCurrency = currencies[chainId][termTokenAddress]

        if (!termTokenCurrency || !isTermRepoCurrency(termTokenCurrency)) {
          continue
        }

        const chainTotal = chainLoanTotals[chainId] || {
          count: 0,
          outstandingCreditUsd: FixedNumber.fromString('0', totalFormat),
          outstandingPrincipalCreditUsd: FixedNumber.fromString(
            '0',
            totalFormat
          ),
          outstandingInterestCreditUsd: FixedNumber.fromString(
            '0',
            totalFormat
          ),
          loanPrincipalUsd: FixedNumber.fromString('0', totalFormat),
          loanInterestUsd: FixedNumber.fromString('0', totalFormat),
          receivedRepoTokensUsd: FixedNumber.fromString('0', totalFormat),
          openListings: FixedNumber.fromString('0', totalFormat),
          openListingsUsd: FixedNumber.fromString('0', totalFormat),
          soldListings: FixedNumber.fromString('0', totalFormat),
          soldListingsUsd: FixedNumber.fromString('0', totalFormat),
        }

        const assocPurchaseCurrency =
          currencies[chainId][termTokenCurrency.purchaseToken]
        const purchasePrice = prices[chainId][termTokenCurrency.purchaseToken]

        const outstandingCreditUsd = multiply(
          outstandingCredit,
          purchasePrice,
          18
        )

        const openListingsPurchaseCurrency = multiply(
          openListings,
          purchasePrice,
          assocPurchaseCurrency.decimals
        )

        const openListingsUsd = multiply(
          listings?.[chainId]?.[termTokenAddress]?.listings
            ?.totalRemainingAmount ?? FixedNumber.fromString('0'),
          purchasePrice,
          assocPurchaseCurrency.decimals
        )

        const soldListingsUsd = multiply(
          listings?.[chainId]?.[termTokenAddress]?.listings?.totalCost ??
            FixedNumber.fromString('0'),
          purchasePrice,
          assocPurchaseCurrency.decimals
        )

        const currentTerm = terms[chainId][termTokenAddress]
        const termRepoTokensApproved =
          termRepoTokenApprovals[chainId][currentTerm.id]

        const initialLoan = FixedNumber.from('0', purchasePrice.format.decimals)
        const interest = FixedNumber.from('0', purchasePrice.format.decimals)

        const lastAuctionId = currentTerm.completedAuctions?.[0]?.auctionId

        const lastAuctionEndTimestamp =
          currentTerm.completedAuctions?.[0]?.auctionEndTimestamp
        const maturityTimestamp = currentTerm.maturityTimestamp

        const lastAuctionClearingRateBN =
          currentTerm?.completedAuctions?.[0]?.auctionClearingRate

        const auctionInfo = {
          loanPrincipal: initialLoan,
          loanInterest: interest,
          auctionId: lastAuctionId,
          auctionEndTimestamp: lastAuctionEndTimestamp,
          auctionClearingRate: lastAuctionClearingRateBN
            ? bigToFixedNumber(lastAuctionClearingRateBN, 18)
            : undefined,
        } as PartialAuctionInfo

        const contractAddresses = {
          auction: currentTerm.completedAuctions?.[0]?.address,
          auctionBidLocker:
            currentTerm.completedAuctions?.[0]?.bidLockerAddress,
          auctionOfferLocker:
            currentTerm.completedAuctions?.[0]?.offerLockerAddress,
          termRepoLocker: currentTerm.termRepoLockerAddress,
          termRepoServicer: currentTerm.repoServicerAddress,
          termCollateralManager: currentTerm.collateralManagerAddress,
          termRolloverManager: currentTerm.rolloverManagerAddress,
          termRepoToken: termTokenAddress,
        } as ContractAddressesGrouping

        if (!openLoansByChain[chainId][termTokenAddress]) {
          openLoansByChain[chainId][termTokenAddress] = {
            chainId,
            maturityTimestamp,
            redemptionTimestamp: termTokenCurrency.redemptionTimestamp
              ? termTokenCurrency.redemptionTimestamp
              : 0,
            termPeriodLength:
              lastAuctionEndTimestamp && maturityTimestamp
                ? getAuctionTermString(
                    lastAuctionEndTimestamp,
                    maturityTimestamp
                  )
                : '',
            termId: currentTerm.id,

            repoServicerAddress: currentTerm.repoServicerAddress,
            purchaseTokenSymbol: assocPurchaseCurrency.symbol,

            outstandingCredit: outstandingCredit,
            rolloverAmount: undefined,

            auctionInfo: [auctionInfo],

            collateralCurrency: currentTerm.collateralCurrency,
            purchaseCurrency: assocPurchaseCurrency,
            termTokenCurrency,

            collateralBalance: FixedNumber.from('0', 18),
            purchaseBalance: FixedNumber.from('0', 18),
            termTokenBalance: chainBalances[termTokenAddress],

            termRepoTokensApproved,

            collateralPrice: prices[chainId][termTokenAddress],
            purchaseTokenPrice: purchasePrice,
            termTokenPrice: prices[chainId][termTokenAddress],

            isRepoTokenTransfer: true,
            hasOutstandingRepoExposure: repoExposures[currentTerm?.id]
              ? !repoExposures[currentTerm.id].isZero()
              : false,

            contractAddresses,

            openListings,
            openListingsPurchaseCurrency,
            openListingsUsd,
            soldListings,
            soldListingsUsd,

            listings: {
              listings:
                listings?.[chainId]?.[termTokenAddress]?.listings ?? undefined,
              purchases:
                listings?.[chainId]?.[termTokenAddress]?.purchases ?? undefined,
            },
          }
        } else {
          openLoansByChain[chainId][termTokenAddress].auctionInfo.push(
            auctionInfo
          )
          openLoansByChain[chainId][termTokenAddress].isRepoTokenTransfer = true
        }

        totalOutstandingCreditUsd = add(
          totalOutstandingCreditUsd,
          outstandingCreditUsd,
          18
        )
        chainTotal.outstandingCreditUsd = add(
          chainTotal.outstandingCreditUsd,
          outstandingCreditUsd,
          18
        )

        totalRepoTokensReceivedUsd = add(
          totalRepoTokensReceivedUsd,
          outstandingCreditUsd,
          18
        )
        chainTotal.receivedRepoTokensUsd = add(
          chainTotal.receivedRepoTokensUsd,
          outstandingCreditUsd,
          18
        )

        totalNumberOpenListings = add(totalNumberOpenListings, openListings)
        totalOpenListingsUsd = add(totalOpenListingsUsd, openListingsUsd)
        totalNumberSoldListings = add(totalNumberSoldListings, soldListings)
        totalSoldListingsUsd = add(totalSoldListingsUsd, soldListingsUsd)

        chainTotal.openListings = add(chainTotal.openListings, openListings)
        chainTotal.openListingsUsd = add(
          chainTotal.openListingsUsd,
          openListingsUsd
        )
        chainTotal.soldListings = add(chainTotal.soldListings, soldListings)
        chainTotal.soldListingsUsd = add(
          chainTotal.soldListingsUsd,
          soldListingsUsd
        )

        // increment count of loans
        chainTotal.count++
        loanTotals.count++
      }
    }
  }

  borrowTotals.outstandingDebtUsd = totalOutstandingDebtUsd
  borrowTotals.principalDebtUsd = totalPrincipalDebtUsd
  borrowTotals.interestDebtUsd = totalInterestDebtUsd
  borrowTotals.originalPrincipalUsd = totalOriginalPrincipalUsd
  borrowTotals.originalInterestUsd = totalOriginalInterestUsd
  borrowTotals.collateralRequiredUsd = totalCollateralRequiredUsd
  borrowTotals.collateralDepositedUsd = totalCollateralDepositedUsd

  loanTotals.outstandingCreditUsd = totalOutstandingCreditUsd
  loanTotals.outstandingPrincipalCreditUsd = totalPrincipalCreditUsd
  loanTotals.outstandingInterestCreditUsd = totalInterestCreditUsd
  loanTotals.loanPrincipalUsd = totalClaimablePrincipleCreditUsd
  loanTotals.loanInterestUsd = totalClaimableInterestCreditUsd
  loanTotals.receivedRepoTokensUsd = totalRepoTokensReceivedUsd
  loanTotals.openListings = totalNumberOpenListings
  loanTotals.openListingsUsd = totalOpenListingsUsd
  loanTotals.soldListings = totalNumberSoldListings
  loanTotals.soldListingsUsd = totalSoldListingsUsd

  const mappedLoans: { [chainId: string]: OpenLoan[] } = {}
  Object.keys(openLoansByChain).forEach((chainId) => {
    const termRepoTokenAddresses = openLoansByChain[chainId]
    mappedLoans[chainId] = Object.values(termRepoTokenAddresses) // Converts the object into an array of OpenLoan
  })

  return [
    {
      chainTotals: chainBorrowTotals,
      totals: borrowTotals,
      borrows: openBorrowsByChain,
    },
    {
      chainTotals: chainLoanTotals,
      totals: loanTotals,
      loans: mappedLoans,
    },
  ]
}

export type OpenBorrowTender = {
  id: string
  interestRate?: FixedNumber

  submittedDate: number

  amount: FixedNumber
  amountUsd: FixedNumber

  requiredMargin: FixedNumber // Required margin in collateral tokens.
  requiredMarginUsd: FixedNumber // Required margin in USD.
  requiredMarginRatio: FixedNumber // Required margin ratio.
  depositedMargin: FixedNumber // Deposited margin in collateral tokens.
  depositedMarginUsd: FixedNumber // Deposited margin in USD.
  depositedMarginRatio: FixedNumber // Deposited margin ratio.

  transaction: string
}

export type OpenBorrowTenderGroup = {
  chainId: ChainId
  auctionTitle: string
  auctionLink: string
  auctionId: string
  auctionRevealTimestamp: number
  auctionEndTimestamp: number

  purchaseTokenSymbol: string
  collateralTokenSymbol: string

  amount: FixedNumber
  amountUsd: FixedNumber

  requiredMargin: FixedNumber // Required margin in collateral tokens.
  requiredMarginUsd: FixedNumber // Required margin in USD.
  depositedMargin: FixedNumber // Deposited margin in collateral tokens.
  depositedMarginUsd: FixedNumber // Deposited margin in USD.

  tenders: OpenBorrowTender[]
  status?: AuctionStatus
  closed?: boolean
  cancelledForWithdrawal?: boolean
}

export type OpenTenders = OpenBorrowTenders | OpenLoanTenders
export type OpenTenderGroup = OpenBorrowTenderGroup | OpenLoanTenderGroup

export type OpenBorrowTenders = {
  amount: FixedNumber

  requiredMarginUsd: FixedNumber // Required margin in USD.
  depositedMarginUsd: FixedNumber // Deposited margin in USD.

  groups: OpenBorrowTenderGroup[]
}

export type OpenLoanTender = {
  id: string

  interestRate?: FixedNumber

  submittedDate: number

  amount: FixedNumber // Amount in purchase tokens.
  amountUsd: FixedNumber // Amount in USD.

  transaction: string
}

export type OpenLoanTenderGroup = {
  chainId: ChainId
  auctionTitle: string
  auctionLink: string
  auctionId: string
  auctionRevealTimestamp: number
  auctionEndTimestamp: number

  purchaseTokenSymbol: string

  amount: FixedNumber // Amount in purchase tokens.
  amountUsd: FixedNumber // Amount in USD.

  tenders: OpenLoanTender[]
  status?: AuctionStatus
  closed?: boolean
  cancelledForWithdrawal?: boolean
}

export type OpenLoanTenders = {
  amountUsd: FixedNumber

  groups: OpenLoanTenderGroup[]
}

export function groupTenders(
  borrowTenders: SubmittedBorrowTender[],
  loanTenders: SubmittedLoanTender[],

  auctions: { [id: string]: Auction },
  currencies: { [chainId: string]: { [address: string]: Currency } },
  prices: { [chainId: string]: { [address: string]: FixedNumber } }
): [OpenBorrowTenders, OpenLoanTenders] {
  // Sort tenders by auction.
  const sortedBorrowTenders = borrowTenders.sort((a, b) =>
    a.auction.localeCompare(b.auction)
  )
  const sortedOfferTenders = loanTenders.sort((a, b) =>
    a.auction.localeCompare(b.auction)
  )

  // Process tenders list.
  const borrowGroups: OpenBorrowTenderGroup[] = []
  let previousAuction = ''
  const totalFormat = 'fixed128x18'
  let totalBorrowAmount = FixedNumber.fromString('0', totalFormat)
  let totalRequiredMargin = FixedNumber.fromString('0', totalFormat)
  let totalDepositedMargin = FixedNumber.fromString('0', totalFormat)
  const now = dayjs()

  for (const borrowTender of sortedBorrowTenders) {
    if (!auctions[borrowTender.auction]) {
      console.warn('Auction not found', borrowTender.auction)
      console.debug('Borrow tender', borrowTender)
      continue
    }

    if (
      auctions[borrowTender.auction].cancelled &&
      !auctions[borrowTender.auction].cancelledForWithdrawal
    ) {
      continue
    }

    const purchaseCurrency =
      currencies[borrowTender.chainId]?.[
        auctions[borrowTender.auction]?.purchaseCurrency
      ]

    if (!purchaseCurrency) {
      console.warn('Purchase currency not found', borrowTender.auction)
      console.debug('Borrow tender', borrowTender)
      continue
    }

    const purchasePrice =
      prices[borrowTender.chainId]?.[purchaseCurrency.address]
    const purchaseFormat = `fixed128x${purchaseCurrency.decimals}`

    const collateralCurrency =
      currencies[borrowTender.chainId]?.[
        auctions[borrowTender.auction]?.collateralCurrency
      ]

    if (!collateralCurrency) {
      console.warn('Collateral currency not found', borrowTender.auction)
      console.debug('Borrow tender', borrowTender)
      continue
    }

    const collateralPrice =
      prices[borrowTender.chainId]?.[collateralCurrency.address]
    const collateralFormat = `fixed128x${collateralCurrency.decimals}`

    const auction = auctions[borrowTender.auction]

    // Units: purchase token
    const amount = FixedNumber.fromValue(
      borrowTender.amount.shiftedValue,
      purchaseCurrency.decimals,
      purchaseFormat
    )

    if (
      !collateralPrice ||
      !auction.initialMarginRatio ||
      !purchasePrice ||
      !amount
    ) {
      console.warn('Auction has undefined variables', borrowTender.auction)
      console.debug('Borrow tender', borrowTender)
      if (!collateralPrice) {
        console.debug('Collateral price undefined')
      }
      if (!auction.initialMarginRatio) {
        console.debug('Initial margin ratio undefined')
      }
      if (!purchasePrice) {
        console.debug('Purchase price undefined')
      }
      if (!amount) {
        console.debug('Amount undefined')
      }
      continue
    }

    const amountUsd = multiply(purchasePrice, amount, purchaseCurrency.decimals)

    // Units: collateralToken
    // Collateral Deposited: collateral_amount * collateral_price_usd (multiplier not included if we want to show USD)
    const depositedMargin = FixedNumber.fromValue(
      borrowTender.collateral?.shiftedValue ?? BigNumber.from('0'),
      collateralCurrency.decimals,
      collateralFormat
    )

    const depositedMarginUsd = multiply(
      collateralPrice,
      depositedMargin,
      collateralCurrency.decimals
    )

    // Units: collateralToken
    // Collateral Required: imr * borrow_amount_purchase_tokens * purchase_price_usd / collateral_price_usd (divisor not included if we want to show USD)
    const requiredMarginUsd = multiply(
      auction.initialMarginRatio,
      multiply(amount, purchasePrice),
      purchaseCurrency.decimals
    )

    const requiredMargin = divide(
      requiredMarginUsd,
      collateralPrice ?? FixedNumber.fromString('1', collateralFormat),
      collateralCurrency.decimals
    )

    // Ratios
    const requiredMarginRatio = !requiredMarginUsd.isZero()
      ? divide(
          requiredMarginUsd,
          amountUsd ?? FixedNumber.fromString('1', requiredMarginUsd.format),
          requiredMarginUsd.format.decimals
        )
      : FixedNumber.fromString('0', requiredMarginUsd.format)

    const depositedMarginRatio = !depositedMarginUsd.isZero()
      ? divide(
          depositedMarginUsd,
          amountUsd ?? FixedNumber.fromString('1', depositedMarginUsd.format),
          depositedMarginUsd.format.decimals
        )
      : FixedNumber.fromString('0', depositedMarginUsd.format)

    const status = getAuctionStatus(
      auction.auctionStartTimestamp,
      auction.auctionRevealTimestamp,
      auction.auctionEndTimestamp,
      now,
      auction.closed || auction.cancelledForWithdrawal
    ).status

    const tender: OpenBorrowTender = {
      id: borrowTender.id,
      interestRate: borrowTender.interestRate,
      submittedDate: borrowTender.submittedDate,

      amount,
      amountUsd,

      requiredMargin,
      requiredMarginUsd,
      requiredMarginRatio,
      depositedMargin,
      depositedMarginUsd,
      depositedMarginRatio,

      transaction: borrowTender.transaction,
    }

    if (previousAuction === borrowTender.auction) {
      // Add to previous group.
      const group = borrowGroups[borrowGroups.length - 1]
      group.amount = add(group.amount, amount, group.amount.format.decimals)
      group.amountUsd = add(
        group.amountUsd,
        amountUsd,
        group.amountUsd.format.decimals
      )

      group.requiredMargin = add(
        group.requiredMargin,
        requiredMargin,
        group.requiredMargin.format.decimals
      )
      group.requiredMarginUsd = add(
        group.requiredMarginUsd,
        requiredMarginUsd,
        group.requiredMarginUsd.format.decimals
      )

      group.depositedMargin = add(
        group.depositedMargin,
        depositedMargin,
        group.depositedMargin.format.decimals
      )
      group.depositedMarginUsd = add(
        group.depositedMarginUsd,
        depositedMarginUsd,
        group.depositedMarginUsd.format.decimals
      )

      group.tenders.push(tender)
    } else {
      borrowGroups.push({
        chainId: convertChainId(borrowTender.chainId),
        auctionTitle: getAuctionDisplayId({
          auctionEndTimestamp:
            auctions[borrowTender.auction]?.auctionEndTimestamp,
          maturityTimestamp: auctions[borrowTender.auction]?.maturityTimestamp,
          termSymbol: purchaseCurrency.symbol,
          collateralSymbol: collateralCurrency.symbol,
        }),
        auctionId: borrowTender.auction,
        auctionLink: `/auctions/${borrowTender.auction}/${borrowTender.chainId}`,
        auctionRevealTimestamp:
          auctions[borrowTender.auction]?.auctionRevealTimestamp,
        auctionEndTimestamp:
          auctions[borrowTender.auction]?.auctionEndTimestamp,
        purchaseTokenSymbol: purchaseCurrency.symbol,
        collateralTokenSymbol: collateralCurrency.symbol,
        amount,
        amountUsd,
        requiredMargin,
        requiredMarginUsd,
        depositedMargin,
        depositedMarginUsd,
        tenders: [tender],
        status,
        closed:
          auctions[borrowTender.auction]?.closed &&
          !auctions[borrowTender.auction]?.cancelledForWithdrawal,
        cancelledForWithdrawal:
          auctions[borrowTender.auction]?.cancelledForWithdrawal,
      })

      previousAuction = borrowTender.auction
    }

    totalBorrowAmount = add(
      totalBorrowAmount,
      amountUsd,
      totalBorrowAmount.format.decimals
    )
    totalRequiredMargin = add(
      totalRequiredMargin,
      requiredMarginUsd,
      totalRequiredMargin.format.decimals
    )
    totalDepositedMargin = add(
      totalDepositedMargin,
      depositedMarginUsd,
      totalDepositedMargin.format.decimals
    )
  }

  const loanGroups: OpenLoanTenderGroup[] = []
  previousAuction = ''
  let totalLoanAmount = FixedNumber.fromString('0', totalFormat)

  for (const loanTender of sortedOfferTenders) {
    if (!auctions[loanTender.auction]) {
      console.warn('Auction not found', loanTender.auction)
      console.debug('Loan tender', loanTender)
      continue
    }

    if (
      auctions[loanTender.auction].cancelled &&
      !auctions[loanTender.auction].cancelledForWithdrawal
    ) {
      continue
    }

    const purchaseCurrency =
      currencies[loanTender.chainId]?.[
        auctions[loanTender.auction]?.purchaseCurrency
      ]

    if (!purchaseCurrency) {
      console.warn('Purchase currency not found', loanTender.auction)
      console.debug('Loan tender', loanTender)
      continue
    }

    const collateralCurrency =
      currencies[loanTender.chainId]?.[
        auctions[loanTender.auction]?.collateralCurrency
      ]

    if (!collateralCurrency) {
      console.warn('Collateral currency not found', loanTender.auction)
      console.debug('Loan tender', loanTender)
      continue
    }

    const purchasePrice = prices[loanTender.chainId]?.[purchaseCurrency.address]
    const purchaseFormat = `fixed128x${purchaseCurrency.decimals}`

    // Units: purchase token
    const amount = FixedNumber.fromValue(
      loanTender.amount.shiftedValue,
      purchaseCurrency.decimals,
      purchaseFormat
    )

    if (!purchasePrice || !amount) {
      console.warn('Auction has undefined variables', loanTender.auction)
      console.debug('Loan tender', loanTender)
      if (!purchasePrice) {
        console.debug('Purchase price undefined')
      }
      if (!!amount) {
        console.debug('Amount undefined')
      }
      continue
    }

    const amountUsd = multiply(purchasePrice, amount, purchaseCurrency.decimals)

    const auction = auctions[loanTender.auction]

    const status = getAuctionStatus(
      auction.auctionStartTimestamp,
      auction.auctionRevealTimestamp,
      auction.auctionEndTimestamp,
      now,
      auction.closed || auction.cancelledForWithdrawal
    ).status

    const tender: OpenLoanTender = {
      id: loanTender.id,
      interestRate: loanTender.interestRate,
      submittedDate: loanTender.submittedDate,

      amount,
      amountUsd,

      transaction: loanTender.transaction,
    }

    if (previousAuction === loanTender.auction) {
      // Add to previous group.
      const group = loanGroups[loanGroups.length - 1]

      group.amount = add(group.amount, amount, group.amount.format.decimals)
      group.amountUsd = add(
        group.amountUsd,
        amountUsd,
        group.amountUsd.format.decimals
      )

      group.tenders.push(tender)
    } else {
      loanGroups.push({
        chainId: convertChainId(loanTender.chainId),
        auctionTitle: getAuctionDisplayId({
          auctionEndTimestamp:
            auctions[loanTender.auction]?.auctionEndTimestamp,
          maturityTimestamp: auctions[loanTender.auction]?.maturityTimestamp,
          termSymbol: purchaseCurrency?.symbol ?? '',
          collateralSymbol: collateralCurrency?.symbol ?? '',
        }),
        auctionLink: `/auctions/${loanTender.auction}`,
        auctionId: loanTender.auction,
        auctionRevealTimestamp:
          auctions[loanTender.auction]?.auctionRevealTimestamp,
        auctionEndTimestamp: auctions[loanTender.auction]?.auctionEndTimestamp,
        purchaseTokenSymbol: purchaseCurrency?.symbol ?? '',
        amount,
        amountUsd,
        tenders: [tender],
        status,
        closed:
          auctions[loanTender.auction]?.closed &&
          !auctions[loanTender.auction]?.cancelledForWithdrawal,
        cancelledForWithdrawal:
          auctions[loanTender.auction]?.cancelledForWithdrawal,
      })

      previousAuction = loanTender.auction
    }

    totalLoanAmount = add(
      totalLoanAmount,
      amountUsd,
      totalLoanAmount.format.decimals
    )
  }

  return [
    {
      amount: totalBorrowAmount,
      requiredMarginUsd: totalRequiredMargin,
      depositedMarginUsd: totalDepositedMargin,

      groups: borrowGroups,
    },
    {
      amountUsd: totalLoanAmount,

      groups: loanGroups,
    },
  ]
}
