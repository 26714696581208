import { Flex, Grid, Text } from '@chakra-ui/react'
import { formatNumber } from '../../utils'
import InviteStats from './InviteStats'
import InviteSteps from './InviteSteps'
import InviteURL from './InviteURL'

export default function InviteOverview({
  usedInvites = 0,
  totalInvites = 0,
  invitesPoints = 0,
  yourShare = 0,
  referralCode,
  isLoading,
}: {
  usedInvites: number
  totalInvites: number
  invitesPoints: number
  yourShare: number
  referralCode?: string
  isLoading?: boolean
}) {
  const referralURL = `app.term.finance/rewards`
  return (
    <Flex
      bg="white"
      p={6}
      borderRadius="8px"
      border="1px"
      borderColor="gray.2"
      direction="column"
    >
      {/* first row */}
      <Flex
        borderBottom="1px"
        borderColor="#D9D9D9"
        pb={5}
        mb={5}
        align="center"
      >
        <Grid templateColumns="repeat(3, 1fr)" gap={12}>
          <InviteStats
            title="Used Invites"
            value={`${usedInvites} / ${totalInvites}`}
            isLoading={isLoading}
          />
          <InviteStats
            title="Invitee Points"
            value={formatNumber(invitesPoints, true, undefined, true)}
            isLoading={isLoading}
          />
          <InviteStats
            title="Your Share"
            value={formatNumber(yourShare ?? 0, true, undefined, true)}
            isLoading={isLoading}
          />
        </Grid>
        {referralCode ? (
          <InviteURL referralCode={referralCode} referralURL={referralURL} />
        ) : null}
      </Flex>
      {/* second row */}
      <Flex direction="column">
        <Text variant="body-l/medium" color="blue.9" mb={6}>
          Earn bonus points equal to{' '}
          <Text as="span" color="green.5">
            10%
          </Text>{' '}
          of the points that they earn.
        </Text>
        <InviteSteps />
      </Flex>
    </Flex>
  )
}
