import React, { useEffect, ReactNode } from 'react'
import {
  init,
  setTags,
  browserTracingIntegration,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  browserProfilingIntegration,
  feedbackIntegration,
} from '@sentry/react'
import { useConfig } from './config'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

export const SentryContext = React.createContext<undefined>(undefined)

export const SentryProvider = ({ children }: { children: ReactNode }) => {
  const config = useConfig()

  useEffect(() => {
    if (!config.sentry.dsn) {
      return
    }
    init({
      dsn: config.sentry.dsn,
      integrations: [
        browserTracingIntegration({
          tracePropagationTargets: [
            /[A-Za-z0-9-_.]+\.term\.finance/,
            /^(?!graph-node)[A-Za-z0-9-_.]+\.termfinance\.io/,
            /[A-Za-z0-9-_.]+\.g\.alchemy\.com/,
            /^\//,
          ],
        }),
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        browserProfilingIntegration(),
        replayIntegration(),
        feedbackIntegration(),
      ],
      environment: config.environment,
      tracesSampleRate: config.sentry.tracesSampleRate,
      replaysSessionSampleRate: config.sentry.replaySampleRate,
      replaysOnErrorSampleRate: config.sentry.replayErrorSampleRate,
      profilesSampleRate: config.sentry.profileSampleRate,

      release: `term-finance-ui@${config.version}`,
    })
  }, [config])

  const tags: { [tag: string]: string } = {
    guardianServerUrl: config.guardianServerUrl,
    provider: JSON.stringify(config.provider),
    approveAmount: config.approveAmount,
    version: config.version,
    environment: config.environment,
    allowedTestnetTokenClaims: config.allowedTestnetTokenClaims.toString(),
    isMainnet: config.isMainnet.toString(),
    isTestnet: config.isTestnet.toString(),
    isInternalEnvironment: config.isInternalEnvironment.toString(),
  }
  if (config.resultsBucket) {
    tags.resultsBucket = config.resultsBucket
  }
  for (const [
    chainId,
    {
      chainName,
      contracts,
      multicallAddress,
      multicall2Address,
      subgraphUrl,
      subgraphPageLimit,
      revealServerUrl,
      blockExplorerUrl,
      testnetTokens,
      auctionsToFilterOut,
      termsToFilterOut,
      nativeCurrency,
      gradient,
      verticalGradient,
      opacity,
      wrappedTokenMapping,
    },
  ] of Object.entries(config.chains)) {
    tags[`chain-${chainId}.chainName`] = chainName
    tags[`chain-${chainId}.contracts`] = JSON.stringify(contracts)
    tags[`chain-${chainId}.multicallAddress`] = multicallAddress
    if (multicall2Address) {
      tags[`chain-${chainId}.multicall2Address`] = multicall2Address
    }
    tags[`chain-${chainId}.subgraphUrl`] = subgraphUrl
    tags[`chain-${chainId}.subgraphPageLimit`] = subgraphPageLimit.toString()
    tags[`chain-${chainId}.revealServerUrl`] = revealServerUrl
    tags[`chain-${chainId}.blockExplorerUrl`] = blockExplorerUrl
    tags[`chain-${chainId}.testnetTokens`] = JSON.stringify(testnetTokens)
    tags[`chain-${chainId}.auctionsToFilterOut`] =
      JSON.stringify(auctionsToFilterOut)
    tags[`chain-${chainId}.termsToFilterOut`] = JSON.stringify(termsToFilterOut)
    tags[`chain-${chainId}.nativeCurrency`] = JSON.stringify(nativeCurrency)
    tags[`chain-${chainId}.gradient`] = gradient
    tags[`chain-${chainId}.verticalGradient`] = verticalGradient
    tags[`chain-${chainId}.opacity`] = opacity.toString()
    tags[`chain-${chainId}.wrappedTokenMapping`] =
      JSON.stringify(wrappedTokenMapping)
  }
  setTags(tags)

  return (
    <SentryContext.Provider value={undefined}>
      {children}
    </SentryContext.Provider>
  )
}
