import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import {
  useSendTransaction,
  TransactionState,
  TransactionStatus,
} from '@usedapp/core'
import { Contract, FixedNumber, Signer } from 'ethers'
import { Address, Currency } from '../model'
import { TermRepoServicer as TermRepoServicer_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermRepoServicer'
import { TermRepoServicer as TermRepoServicer_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermRepoServicer'
import { TermRepoServicer as TermRepoServicer_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermRepoServicer'
import TermRepoServicerABI_0_2_4 from '../../abi/v0.2.4/TermRepoServicer.json'
import TermRepoServicerABI_0_4_1 from '../../abi/v0.4.1/TermRepoServicer.json'
import TermRepoServicerABI_0_6_0 from '../../abi/v0.6.0/TermRepoServicer.json'
import {
  convertChainId,
  fixedToBigNumber,
  getABIVersion,
} from '../../helpers/conversions'
import { waitForStatus, waitForSubgraphIndexing } from '../../helpers/wait'
import { generateTermAuth } from '../auth'
import {
  MultichainCalls,
  useCurrentTime,
  useMultichainCalls,
  useMultiSends,
  useWrappedGasTokens,
} from './helper-hooks'
import { encodeMultisend } from '../multisend'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type ManageLoanTokens = {
  purchaseCurrency: Currency
  repoServicer: Address
  version: string
}

export type TermRepoServicer =
  | TermRepoServicer_0_2_4
  | TermRepoServicer_0_4_1
  | TermRepoServicer_0_6_0

export function useManageLoans(
  tokens: { [chainId: string]: ManageLoanTokens[] | undefined } | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  readFromSubgraph: () => void
): {
  loanBalances?: { [chainId: string]: FixedNumber[] | undefined }
  redeemTermTokens?: (
    chainId: string,
    loanManagerAddress: Address,
    termTokensToBurn: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  repayTermTokens?: (
    chainId: string,
    loanManagerAddress: Address,
    repayAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
  collapseBorrow?: (
    chainId: string,
    loanManagerAddress: Address,
    collapseAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
} {
  const {
    sendTransaction: sendRepayTransaction,
    state: repayState,
    resetState: resetRepayState,
  } = useSendTransaction()
  const {
    sendTransaction: sendRedeemTransaction,
    state: redeemState,
    resetState: resetRedeemState,
  } = useSendTransaction()
  const {
    sendTransaction: sendCollapseTransaction,
    state: collapseState,
    resetState: resetCollapseState,
  } = useSendTransaction()

  const multisends = useMultiSends(provider)
  const wrappedGasTokens = useWrappedGasTokens(provider)
  const currentTime = useCurrentTime()

  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return activeNetwork?.chainId === chainId
    },
    [signer]
  )

  const loanManagerContracts = useMemo(() => {
    const contracts: {
      [chainId: string]: {
        [loanManager: Address]: {
          manager: TermRepoServicer
          version: string
        }
      }
    } = {}

    if (tokens) {
      for (const [chainId, tokenList] of Object.entries(tokens)) {
        if (!tokenList) continue

        contracts[chainId] = {}

        for (const token of tokenList) {
          if (!token.repoServicer || contracts[chainId][token.repoServicer]) {
            continue
          }

          const tmpContractData = {} as {
            manager: TermRepoServicer
            version: string
          }

          const version = getABIVersion(token.version)
          tmpContractData.version = version

          switch (version) {
            case '0.6.0':
              tmpContractData.manager = new Contract(
                token.repoServicer,
                TermRepoServicerABI_0_6_0,
                provider
              ) as TermRepoServicer_0_6_0
              break
            case '0.4.1':
              tmpContractData.manager = new Contract(
                token.repoServicer,
                TermRepoServicerABI_0_4_1,
                provider
              ) as TermRepoServicer_0_4_1
              break
            case '0.2.4':
            default:
              tmpContractData.manager = new Contract(
                token.repoServicer,
                TermRepoServicerABI_0_2_4,
                provider
              ) as TermRepoServicer_0_2_4
          }

          contracts[chainId][token.repoServicer] = tmpContractData
        }
      }
    }

    return contracts
  }, [provider, tokens])

  const [account, setAccount] = useState<string | undefined>()
  useEffect(() => {
    signer?.getAddress()?.then(setAccount)
  }, [signer])

  const transactionStates = useMemo(
    () =>
      ({}) as {
        redeemTokens: TransactionStatus | undefined
        repayTokens: TransactionStatus | undefined
        collapseBorrow: TransactionStatus | undefined
      },
    []
  )
  // WARNING: These are used to update in-place so that the lock/delete
  //          methods always have access to the latest transaction state.
  useEffect(() => {
    transactionStates.redeemTokens = redeemState
  }, [redeemState, transactionStates])
  useEffect(() => {
    transactionStates.repayTokens = repayState
  }, [repayState, transactionStates])
  useEffect(() => {
    transactionStates.collapseBorrow = collapseState
  }, [collapseState, transactionStates])

  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (account && tokens) {
      Object.entries(tokens).forEach(([chainId, tokenList]) => {
        if (!tokenList) return

        calls[chainId] = tokenList.map((token) => ({
          contract:
            loanManagerContracts[chainId]?.[token.repoServicer]?.manager,
          method: 'getBorrowerRepurchaseObligation',
          args: [account],
        }))
      })
    }
    return calls
  }, [tokens, account, loanManagerContracts])

  const blockchainResults = useMultichainCalls(contractCalls)

  const loanBalances = useMemo(() => {
    if (!tokens || !blockchainResults) return undefined

    const balances: {
      [chainId: string]: FixedNumber[] | undefined
    } = {}

    Object.entries(tokens).forEach(([chainId, tokenList]) => {
      if (!tokenList) return

      balances[chainId] = tokenList.map((token, index) => {
        const balance = blockchainResults[chainId]?.[index]?.value?.[0] ?? 0
        const decimals = token?.purchaseCurrency?.decimals ?? 0
        return FixedNumber.fromValue(balance, decimals)
      })
    })

    return balances
  }, [tokens, blockchainResults])

  const redeemTermTokens = useCallback(
    async (
      chainId: string,
      loanManagerAddress: Address,
      termTokensToBurn: FixedNumber,
      isGasToken: boolean
    ) => {
      if (!account || !signer) {
        return 'None'
      }
      console.log('redeeming ' + fixedToBigNumber(termTokensToBurn).toString())

      const multisend = multisends[chainId]
      const wrappedGasToken = wrappedGasTokens[chainId]
      const parsedChainId = convertChainId(chainId)

      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const loanManager =
        loanManagerContracts?.[chainId]?.[loanManagerAddress]?.manager
      const contractVersion =
        loanManagerContracts?.[chainId]?.[loanManagerAddress]?.version

      let data: string

      switch (contractVersion) {
        case '0.6.0':
        case '0.4.1':
        case '0.2.4':
        default:
          console.info(`redeeming term tokens from ${contractVersion} contract`)
          data = loanManager.interface.encodeFunctionData(
            'redeemTermRepoTokens',
            [await signer.getAddress(), fixedToBigNumber(termTokensToBurn)]
          )
      }

      if (isGasToken) {
        // Unwrap WETH if isGasToken is true.
        const unwrapData = wrappedGasToken.interface.encodeFunctionData(
          'withdraw',
          [fixedToBigNumber(termTokensToBurn)]
        )
        const multisendData = encodeMultisend([
          {
            to: loanManagerAddress,
            data,
            value: '0',
          },
          {
            to: wrappedGasToken.address,
            data: unwrapData,
            value: '0',
          },
        ])
        await sendRedeemTransaction({
          chainId: parsedChainId,
          to: multisend.address,
          from: account,
          data: multisendData,
        })
      } else {
        await sendRedeemTransaction({
          chainId: parsedChainId,
          to: loanManagerAddress,
          from: account,
          data,
        })
      }
      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.redeemTokens,
        resetRedeemState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        loanManager
      )

      const result = transactionStates?.redeemTokens?.status ?? 'None'

      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }
      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.redeemTokens?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(chainId, minedBlockNumber, 5, 1000)
      }
      readFromSubgraph()
      return result
    },
    [
      account,
      signer,
      multisends,
      wrappedGasTokens,
      validateActiveNetwork,
      loanManagerContracts,
      resetRedeemState,
      transactionStates.redeemTokens,
      sendRedeemTransaction,
      readFromSubgraph,
    ]
  )

  const repayTermTokens = useCallback(
    async (
      chainId: string,
      loanManagerAddress: Address,
      repayAmount: FixedNumber,
      isGasToken: boolean
    ) => {
      if (!account || !signer) {
        return 'None'
      }

      const multisend = multisends[chainId]
      const wrappedGasToken = wrappedGasTokens[chainId]
      const parsedChainId = convertChainId(chainId)

      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const loanManager =
        loanManagerContracts?.[chainId]?.[loanManagerAddress]?.manager
      const contractVersion =
        loanManagerContracts?.[chainId]?.[loanManagerAddress]?.version

      const repayAmountBn = fixedToBigNumber(repayAmount)
      let data: string
      let abi:
        | TermRepoServicer_0_4_1
        | TermRepoServicer_0_2_4
        | TermRepoServicer_0_6_0
      switch (contractVersion) {
        case '0.6.0':
          console.info('repaying loan using 0.6.0 abi')
          abi = loanManager as TermRepoServicer_0_6_0
          data = abi.interface.encodeFunctionData('submitRepurchasePayment', [
            repayAmountBn,
          ])
          break
        case '0.4.1':
          console.info('repaying loan using 0.4.1 abi')
          abi = loanManager as TermRepoServicer_0_4_1
          data = abi.interface.encodeFunctionData('submitRepurchasePayment', [
            repayAmountBn,
          ])
          break
        case '0.2.4':
        default:
          console.info('repaying loan using 0.2.4 abi')
          abi = loanManager as TermRepoServicer_0_2_4
          data = abi.interface.encodeFunctionData('submitRepurchasePayment', [
            repayAmountBn,
            await generateTermAuth(
              signer,
              loanManager as TermRepoServicer_0_2_4,
              'submitRepurchasePayment',
              [repayAmountBn],
              currentTime.unix()
            ),
          ])
      }

      if (isGasToken) {
        // Unwrap WETH if isGasToken is true.
        const unwrapData = wrappedGasToken.interface.encodeFunctionData(
          'withdraw',
          [fixedToBigNumber(repayAmount)]
        )
        const multisendData = encodeMultisend([
          {
            to: loanManagerAddress,
            data,
            value: '0',
          },
          {
            to: wrappedGasToken.address,
            data: unwrapData,
            value: '0',
          },
        ])
        await sendRepayTransaction({
          chainId: parsedChainId,
          to: multisend.address,
          from: account,
          data: multisendData,
        })
      } else {
        await sendRepayTransaction({
          chainId: parsedChainId,
          to: loanManagerAddress,
          from: account,
          data,
        })
      }

      // Wait for state of repay transaction to complete.
      await waitForStatus(
        async () => transactionStates.repayTokens,
        resetRepayState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        abi
      )

      const result = transactionStates?.repayTokens?.status ?? 'None'

      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }
      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.repayTokens?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(chainId, minedBlockNumber, 5, 1000)
      }
      readFromSubgraph()
      return result
    },
    [
      account,
      signer,
      multisends,
      wrappedGasTokens,
      validateActiveNetwork,
      loanManagerContracts,
      resetRepayState,
      transactionStates.repayTokens,
      currentTime,
      sendRepayTransaction,
      readFromSubgraph,
    ]
  )

  const collapseBorrow = useCallback(
    async (
      chainId: string,
      loanManagerAddress: Address,
      collapseBorrowAmount: FixedNumber,
      isGasToken: boolean
    ) => {
      if (!account || !signer) {
        return 'None'
      }

      const multisend = multisends[chainId]
      const wrappedGasToken = wrappedGasTokens[chainId]
      const parsedChainId = convertChainId(chainId)

      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const loanManager =
        loanManagerContracts?.[chainId]?.[loanManagerAddress]?.manager
      const contractVersion =
        loanManagerContracts?.[chainId]?.[loanManagerAddress]?.version

      const collapseBorrowAmountBn = fixedToBigNumber(collapseBorrowAmount)
      let data: string
      let abi:
        | TermRepoServicer_0_2_4
        | TermRepoServicer_0_4_1
        | TermRepoServicer_0_6_0

      switch (contractVersion) {
        case '0.6.0':
          console.info('collapsing loan using 0.6.0 abi')
          abi = loanManager as TermRepoServicer_0_6_0
          data = abi.interface.encodeFunctionData('burnCollapseExposure', [
            collapseBorrowAmountBn,
          ])
          break
        case '0.4.1':
          console.info('collapsing loan using 0.4.1 abi')
          abi = loanManager as TermRepoServicer_0_4_1
          data = abi.interface.encodeFunctionData('burnCollapseExposure', [
            collapseBorrowAmountBn,
          ])
          break
        case '0.2.4':
        default:
          console.info('collapsing loan using 0.2.4 contract')
          abi = loanManager as TermRepoServicer_0_2_4
          data = abi.interface.encodeFunctionData('burnCollapseExposure', [
            fixedToBigNumber(collapseBorrowAmount),
            await generateTermAuth(
              signer,
              loanManager as TermRepoServicer_0_2_4,
              'burnCollapseExposure',
              [collapseBorrowAmountBn],
              currentTime.unix()
            ),
          ])
      }

      if (isGasToken) {
        // Unwrap WETH if isGasToken is true.
        const unwrapData = wrappedGasToken.interface.encodeFunctionData(
          'withdraw',
          [fixedToBigNumber(collapseBorrowAmount)]
        )
        const multisendData = encodeMultisend([
          {
            to: loanManagerAddress,
            data,
            value: '0',
          },
          {
            to: wrappedGasToken.address,
            data: unwrapData,
            value: '0',
          },
        ])
        await sendCollapseTransaction({
          chainId: parsedChainId,
          to: multisend.address,
          from: account,
          data: multisendData,
        })
      } else {
        await sendCollapseTransaction({
          chainId: parsedChainId,
          to: loanManagerAddress,
          from: account,
          data,
        })
      }

      // Wait for state of delete transaction to complete.
      await waitForStatus(
        async () => transactionStates.collapseBorrow,
        resetCollapseState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        abi
      )

      const result = transactionStates?.collapseBorrow?.status ?? 'None'

      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }
      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.collapseBorrow?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(chainId, minedBlockNumber, 5, 1000)
      }
      readFromSubgraph()
      return result
    },
    [
      account,
      signer,
      multisends,
      wrappedGasTokens,
      validateActiveNetwork,
      loanManagerContracts,
      resetCollapseState,
      transactionStates.collapseBorrow,
      currentTime,
      sendCollapseTransaction,
      readFromSubgraph,
    ]
  )

  return {
    loanBalances,
    redeemTermTokens,
    repayTermTokens,
    collapseBorrow,
  }
}
