import { FixedNumber } from 'ethers'
import StatsBeforeAfter from '../../../../components/elements/StatsBeforeAfter'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import { formatFixedToken } from '../../../../helpers/utils'
import { Trans, t } from '@lingui/macro'

export default function RedeemSummary({
  purchaseBalanceUsd,
  purchaseNewBalanceUsd,
  termTokenBalance,
  termTokenBalanceNew,
  termTokenSymbol,
  purchaseTokenSymbol,
}: {
  purchaseBalanceUsd: FixedNumber
  purchaseNewBalanceUsd: FixedNumber

  termTokenBalance: FixedNumber
  termTokenBalanceNew: FixedNumber
  termTokenSymbol: string
  purchaseTokenSymbol: string
}) {
  return (
    <StatsCard>
      <StatsTitle>
        <Trans>Wallet Balance</Trans>
      </StatsTitle>
      <StatsRow title={purchaseTokenSymbol}>
        <StatsBeforeAfter
          isAfterBold={false}
          before={formatFixedToken(
            purchaseBalanceUsd,
            purchaseTokenSymbol,
            true,
            true
          )}
          after={formatFixedToken(
            purchaseNewBalanceUsd,
            purchaseTokenSymbol,
            true,
            true
          )}
          maxW="300px"
        />
      </StatsRow>
      <StatsRow title={t`Repo Tokens`}>
        <StatsBeforeAfter
          isAfterBold={false}
          before={formatFixedToken(
            termTokenBalance,
            purchaseTokenSymbol,
            true,
            true
          )}
          after={formatFixedToken(
            termTokenBalanceNew,
            purchaseTokenSymbol,
            true,
            true,
            termTokenSymbol
          )}
          maxW="260px"
        />
      </StatsRow>
    </StatsCard>
  )
}
