import { Box, Button, ButtonProps, Flex, Spinner, Text } from '@chakra-ui/react'
import { VStack } from '../Stack'
import { useEthers } from '@usedapp/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BigNumber } from 'ethers'
import { useMemo, useState } from 'react'
import { useTermToast } from '../../../hooks/toasts'
import { isRejectedTxn, termToastMessages } from '../../../helpers/toasts'
import ApproveStepIndicator from '../ApproveStepIndicator'
import { APPROVAL_BID_BUFFER_PERCENT } from '../../../helpers/constants'
import { useChainConfig } from '../../../providers/config'

export default function SubmitApproveButton({
  onSubmit,
  onApprove,
  variant = 'primary',
  size = 'sm',
  w = 'full',
  isDisabled,
  approvalAmount,
  submitApprovalAmount,
  approvedAmount,
  submitLabel,
  approvingCurrencySymbol,
  onKytCheck,
  submitButtonRef,
  isSkipApproval,
  step = 1,
  forcefullyShowStep,
  submitTooltip,
}: {
  onSubmit: () => Promise<void>
  onApprove: (amount?: string) => Promise<void>
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
  w?: ButtonProps['w']
  isDisabled?: ButtonProps['isDisabled']
  approvalAmount: BigNumber
  submitApprovalAmount?: boolean
  approvedAmount: BigNumber
  submitLabel: string
  approvingCurrencySymbol: string
  onKytCheck: () => Promise<boolean>
  submitButtonRef?: any
  isSkipApproval?: boolean
  step?: number
  // @dev used to show step even when approve is not required (e.g. CancelRolloverRepayButton)
  forcefullyShowStep?: boolean
  submitTooltip?: React.ReactNode
}) {
  const { library: provider } = useEthers()
  const chainConfig = useChainConfig(provider?.network?.chainId)

  const termToast = useTermToast()

  const needsApproval = useMemo(
    () => !isSkipApproval && approvalAmount.gt(approvedAmount),
    [approvalAmount, approvedAmount, isSkipApproval]
  )
  const [approving, setApproving] = useState(false)
  const [hasApproved, setHasApproved] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const working = useMemo(
    () => approving || submitting,
    [approving, submitting]
  )

  const buttonIcon = useMemo(() => {
    switch (true) {
      case needsApproval && approving:
        return <Spinner size="sm" />
      case hasApproved:
        return (
          <FontAwesomeIcon
            icon={['far', 'check']}
            color={variant === 'secondary' ? 'blue.5' : 'white'}
          />
        )
    }
  }, [needsApproval, approving, variant, hasApproved])

  const isApproveButtonDisabled =
    isDisabled || !needsApproval || working || hasApproved

  const isSubmitButtonDisabled = isDisabled || working || needsApproval

  const approve = async () => {
    if (isApproveButtonDisabled) return
    setApproving(true)
    termToast.pending(
      termToastMessages.approving.pending(approvingCurrencySymbol)
    )
    try {
      const hasKytCheckPassed = await onKytCheck()

      if (!hasKytCheckPassed) {
        setApproving(false)
        return
      }

      let _approvalAmount
      if (submitApprovalAmount) {
        // use approvalAmount + 5% buffer
        _approvalAmount = approvalAmount
          .add(approvalAmount.mul(APPROVAL_BID_BUFFER_PERCENT).div(100))
          .toString()
      }
      await onApprove(_approvalAmount)
      termToast.success(
        termToastMessages.approving.success(approvingCurrencySymbol)
      )
      // Use hasApproved for keeping Approve button
      // visible until refresh on first time approval
      setHasApproved(true)
    } catch (e) {
      console.error(e)
      if (isRejectedTxn(e)) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.approving.failure)
      }
    } finally {
      setApproving(false)
    }
  }

  const submit = async () => {
    setSubmitting(true)
    try {
      const hasKytCheckPassed = await onKytCheck()

      if (!hasKytCheckPassed) {
        setSubmitting(false)
        return
      }

      await onSubmit()
    } catch (e) {
      console.error(e)
      if (isRejectedTxn(e)) {
        termToast.dismissed()
      } else {
        termToast.failure({
          title: `Your request was not submitted to the ${
            chainConfig?.chainName ?? 'Unknown'
          } network. Please try again.`,
        })
      }
    } finally {
      setSubmitting(false)
      // Hide approve button after successful submit
      setHasApproved(false)
    }
  }

  if (submitButtonRef) {
    // allows submit function to be called from parent
    submitButtonRef.current = submit
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <VStack spacing="8px" w="full">
        {!isSkipApproval && (needsApproval || approving || hasApproved) && (
          <Box position="relative" w="full">
            <ApproveStepIndicator>{step}</ApproveStepIndicator>
            <Button
              variant={variant}
              size={size}
              w={w}
              opacity={isApproveButtonDisabled ? 0.5 : 1}
              isDisabled={isApproveButtonDisabled}
              onClick={approve}
            >
              <Flex as="span" alignItems="center" columnGap={1.5}>
                <Text as="span" variant="body-sm/semibold">
                  Approve {approvingCurrencySymbol || 'Asset'}{' '}
                </Text>
                {buttonIcon}
              </Flex>
            </Button>
          </Box>
        )}
        <Box position="relative" w="full" mt="0 !important">
          {((!isSkipApproval && (needsApproval || approving || hasApproved)) ||
            forcefullyShowStep) && (
            <ApproveStepIndicator>{step + 1}</ApproveStepIndicator>
          )}
          <Button
            variant={variant}
            size={size}
            w={w}
            isDisabled={isSubmitButtonDisabled}
            onClick={submit}
          >
            <Text as="span" variant="body-sm/semibold">
              {submitLabel}
            </Text>
            {submitting && (
              <>
                <Spinner size="sm" ml="2" />{' '}
              </>
            )}
          </Button>
          {submitTooltip}
        </Box>
      </VStack>
    </VStack>
  )
}
