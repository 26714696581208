import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { BigNumber, FixedNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { useMemo, useState } from 'react'
import OverflowBox from '../../../../components/elements/OverflowBox'
import { HStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import {
  Auction,
  Currency,
  DraftLoanTender,
  NativeCurrency,
  SuggestedRatesByPlatform,
} from '../../../../data/model'
import { parseLoanTender } from '../../../../data/parse'
import { INTEREST_RATE_DECIMALS } from '../../../../helpers/constants'
import { bigToFixedNumber } from '../../../../helpers/conversions'
import { evaluate } from '../../../../helpers/math'
import {
  formatBigPercentage,
  formatBigToken,
  formatFixedUsd,
} from '../../../../helpers/utils'
import CreateTenderField from '../CreateTenderField'
import WrappingTooltip from '../WrappingTooltip'
import { Trans, t } from '@lingui/macro'

export default function LoanTenderForm({
  auction,
  purchaseCurrency,
  gasTokenCurrency,
  index,
  isLastIndex,
  tendersCount,
  availPurchaseTokensToSupply,
  purchaseTokenBalance,
  gasTokenBalance,
  rateSuggestions,
  isSubmitting,
  value,
  onChange,
  onRemove,
}: {
  auction: Pick<
    Auction,
    | 'minOfferAmount'
    | 'maxOfferPrice'
    | 'purchaseCurrencyOraclePriceUSDC'
    | 'purchaseCurrencyOraclePriceDecimals'
  >
  purchaseCurrency: Currency
  collateralCurrency: Currency
  gasTokenCurrency: NativeCurrency
  index: number
  isLastIndex: Boolean
  tendersCount: number
  availPurchaseTokensToSupply: BigNumber
  purchaseTokenBalance: BigNumber
  gasTokenBalance: FixedNumber
  rateSuggestions?: SuggestedRatesByPlatform
  isSubmitting: boolean
  value: DraftLoanTender
  onChange: (value: DraftLoanTender) => void
  onRemove: () => void
}) {
  const [hasEditedAmount, setHasEditedAmount] = useState(false)
  const [hasEditedRate, setHasEditedRate] = useState(false)

  const [isAmountFieldChangedToEmpty, setIsAmountFieldChangedToEmpty] =
    useState(false)
  const [isRateFieldChangedToEmpty, setIsRateFieldChangedToEmpty] =
    useState(false)

  const parsed = useMemo(
    () =>
      parseLoanTender(
        value,
        purchaseCurrency.decimals,
        auction.minOfferAmount,
        availPurchaseTokensToSupply,
        FixedNumber.fromString('0', 18),
        auction.maxOfferPrice
          ? FixedNumber.fromValue(auction.maxOfferPrice.mul(100), 18)
          : FixedNumber.fromString('0', 18)
      ),
    [
      auction.maxOfferPrice,
      auction.minOfferAmount,
      purchaseCurrency.decimals,
      availPurchaseTokensToSupply,
      value,
    ]
  )

  const onAmountChange = (amount: string) => {
    onChange({ ...value, amount })
    if (amount === '') {
      setIsAmountFieldChangedToEmpty(true)
    } else {
      setIsAmountFieldChangedToEmpty(false)
    }

    if (!hasEditedAmount) {
      setHasEditedAmount(true)
    }
  }

  const calculateValueAsUsd = (
    amount: BigNumber,
    amountDecimals: number,
    oraclePrice: BigNumber,
    purchasePriceDecimals: number
  ) => {
    const amountUsd = evaluate(
      {
        nodeKind: 'mul',
        args: [
          {
            nodeKind: 'value',
            value: bigToFixedNumber(amount, amountDecimals),
          },
          {
            nodeKind: 'value',
            value: bigToFixedNumber(oraclePrice, purchasePriceDecimals),
          },
        ],
      },
      purchasePriceDecimals
    )

    return amountUsd
  }

  return (
    <Box>
      {tendersCount > 1 && (
        <Flex alignItems="center" mb="8px" columnGap="8px">
          <Box px="4px" borderRadius="6px" bg="gray.2">
            <Text whiteSpace="nowrap" color="gray.6" variant="body-xs/medium">
              <Trans>Tender {index + 1}</Trans>
            </Text>
          </Box>
          <Divider />
          <Box
            onClick={onRemove}
            aria-label={`delete-tender-${index}`}
            cursor="pointer"
          >
            <Text as="span" variant="body-xs/semibold" color="blue.5">
              <Trans>Clear</Trans>
            </Text>
          </Box>
        </Flex>
      )}
      <HStack mb="16px" alignItems="start" spacing={4}>
        <CreateTenderField
          label="Supply Amount"
          placeholder="0.00"
          rightElement={purchaseCurrency.symbol}
          helperText={
            isLastIndex ? (
              <OverflowBox
                maxW="167px"
                textAlign="right"
                cursor="pointer"
                _hover={{
                  textDecoration: 'underline',
                }}
                onClick={() =>
                  onAmountChange(
                    formatUnits(
                      availPurchaseTokensToSupply,
                      purchaseCurrency.decimals
                    )
                  )
                }
                textColor={
                  hasEditedAmount && parsed?.amount?.error === 'too-large'
                    ? 'red.5'
                    : undefined
                }
              >
                {purchaseCurrency.symbol ===
                gasTokenCurrency.wrappedGasSymbol ? (
                  <WrappingTooltip
                    unwrappedTokenSymbol={gasTokenCurrency.symbol}
                    unwrappedTokenBalance={gasTokenBalance}
                    wrappedCurrency={purchaseCurrency}
                    wrappedTokenBalance={purchaseTokenBalance}
                    availTokensToSupply={availPurchaseTokensToSupply}
                  >
                    <div>
                      <Trans>Available:</Trans>{' '}
                      {formatBigToken(
                        availPurchaseTokensToSupply,
                        purchaseCurrency.decimals,
                        purchaseCurrency.symbol,
                        true,
                        true
                      )}
                    </div>
                  </WrappingTooltip>
                ) : (
                  <div>
                    <Trans>Available:</Trans>{' '}
                    {formatBigToken(
                      availPurchaseTokensToSupply,
                      purchaseCurrency.decimals,
                      purchaseCurrency.symbol,
                      true,
                      true
                    )}
                  </div>
                )}
              </OverflowBox>
            ) : undefined
          }
          value={value.amount}
          tokenDecimals={purchaseCurrency.decimals}
          onChange={onAmountChange}
          w="280px"
          superElement={
            <HStack color="blue.5" spacing={1} lineHeight="4">
              <button
                onClick={() => {
                  const maxAmount = formatUnits(
                    availPurchaseTokensToSupply,
                    purchaseCurrency.decimals
                  )
                  onAmountChange(maxAmount)
                }}
              >
                <Text variant="body-xs/semibold" color="blue.5">
                  <Trans>MAX</Trans>
                </Text>
              </button>
            </HStack>
          }
          errorElement={
            // Do not show error if isSubmitting === true
            // (balance may reduce to lower than input value during transaction)
            !isSubmitting && hasEditedAmount ? (
              isAmountFieldChangedToEmpty &&
              parsed?.amount?.error === 'empty' ? (
                <>
                  <Trans>Specify a supply amount</Trans>
                </>
              ) : parsed?.amount?.error === 'invalid' ? (
                <>
                  <Trans>Invalid supply amount</Trans>
                </>
              ) : parsed?.amount?.error === 'too-large' ? (
                <>
                  <Trans>Insufficient funds in connected wallet</Trans>
                </>
              ) : parsed?.amount?.error === 'too-many-decimals' ? (
                <>
                  <Trans>
                    Maximum number of decimal places allowed for this currency
                    are
                  </Trans>{' '}
                  {' ' + purchaseCurrency.decimals}
                </>
              ) : parsed?.amount?.error === 'too-small' ? (
                <>
                  <Trans>Minimum supply amount is</Trans>{' '}
                  {formatBigToken(
                    auction.minOfferAmount,
                    purchaseCurrency.decimals,
                    purchaseCurrency.symbol
                  )}
                </>
              ) : undefined
            ) : undefined
          }
          infoElement={
            hasEditedAmount && parsed?.amount?.isValid ? (
              <>
                <OverflowBox maxW="240px">
                  {formatFixedUsd(
                    calculateValueAsUsd(
                      parsed?.amount?.value,
                      purchaseCurrency.decimals,
                      auction.purchaseCurrencyOraclePriceUSDC,
                      auction.purchaseCurrencyOraclePriceDecimals
                    ),
                    false,
                    true
                  )}
                </OverflowBox>
              </>
            ) : undefined
          }
        />
        <CreateTenderField
          isInterestRate
          variant="supply"
          tooltip={<MinInterestRateTooltip />}
          label={t`Minimum Interest Rate`}
          placeholder={'0.000%'}
          value={value.interestRate}
          tokenDecimals={INTEREST_RATE_DECIMALS}
          onChange={(rate) => {
            onChange({ ...value, interestRate: rate })
            if (rate === '') {
              setIsRateFieldChangedToEmpty(true)
            } else {
              setIsRateFieldChangedToEmpty(false)
            }
            if (!hasEditedRate) {
              setHasEditedRate(true)
            }
          }}
          w={{
            base: '240px',
            xl: '180px',
          }}
          rightElement={t`APR %`}
          rightElementTooltip={t`The interest rate (%) is expressed as an Annual Percentage Rate (APR).`}
          warningElement={
            hasEditedRate && parsed?.interestRate?.error === 'too-large' ? (
              <>
                <Trans>Maximum interest rate is</Trans>{' '}
                {formatBigPercentage(auction.maxOfferPrice)}
              </>
            ) : undefined
          }
          errorElement={
            hasEditedRate ? (
              parsed?.interestRate?.error === 'too-small' ? (
                <>
                  <Trans>Minimum interest rate is 0.0%</Trans>
                </>
              ) : isRateFieldChangedToEmpty &&
                parsed?.interestRate?.error === 'empty' ? (
                <>
                  <Trans>Specify a minimum interest rate</Trans>
                </>
              ) : parsed?.interestRate?.error === 'invalid' ? (
                <>
                  <Trans>Invalid minimum interest rate</Trans>
                </>
              ) : undefined
            ) : undefined
          }
          rateSuggestions={rateSuggestions}
        />
      </HStack>
    </Box>
  )
}

function MinInterestRateTooltip() {
  return (
    <Tooltip
      noDelay
      aria-label="min-interest-rate-tooltip"
      label={t`If your offer is successful, the interest that you receive for your loan will be no less than what you input here.`}
    />
  )
}
