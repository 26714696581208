import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  ButtonProps,
  Text,
  Image,
  VStack,
} from '@chakra-ui/react'
import { useChainConfig } from '../../../../providers/config'
import { networkIcons } from '../../../../helpers/networkIcons'
import { ChainId } from '@usedapp/core'

const SelectNetworkButton = ({
  size = 'md',
  height = '40px',
  width = 'full',
  borderRadius = 'md',
  backgroundColor = 'white',
  _hover = { bg: 'gray.200' },
  justifyContent = 'center',
  borderWidth = '1px',
  borderColor = 'blue.500',
  fontWeight = '600',
  textColor = 'blue.500',
  lineHeight = '24px',
  fontStyle = 'normal',
  fontFamily = 'Inter',
  fontSize = '16px',
  padding = '10px 16px',
  children,
  leftIcon,
  onClick,
}: ButtonProps) => {
  return (
    <Button
      size={size}
      height={height}
      width={width}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      _hover={_hover}
      justifyContent={justifyContent}
      borderWidth={borderWidth}
      borderColor={borderColor}
      fontWeight={fontWeight}
      textColor={textColor}
      lineHeight={lineHeight}
      fontStyle={fontStyle}
      fontFamily={fontFamily}
      fontSize={fontSize}
      padding={padding}
      leftIcon={leftIcon}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

const SwitchToAuctionNetworkModal = ({
  isOpen,
  onClose,
  onSwitchNetwork,
  chainId,
}: {
  isOpen: boolean
  onClose: () => void
  onSwitchNetwork: (chainId: ChainId) => Promise<boolean>
  chainId: number
}) => {
  const chainConfig = useChainConfig(chainId)
  const networkName = chainConfig?.chainName ?? 'Unknown Network'

  const networkIcon = networkIcons[chainId] ?? networkIcons[-1]

  const handleSwitchNetworkClick = async () => {
    const success = await onSwitchNetwork(chainId)
    if (success) {
      onClose()
    } else {
      console.error('Failed to switch network')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant="standard"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="md" p="28px" width="480px">
        <ModalHeader pt={1} px={1} mb="2px" mt="-4px">
          Switch Network
        </ModalHeader>
        <ModalCloseButton size="sm" mr="18px" mt="24px" />
        <VStack spacing="20px" align="stretch" mb={2}>
          <Text fontSize="16px" color="blue.900" textAlign="left">
            This auction is being held on {networkName}. To participate, switch
            your network to {networkName}.
          </Text>
          <SelectNetworkButton
            onClick={handleSwitchNetworkClick}
            leftIcon={
              networkIcon ? (
                <Image
                  src={networkIcon}
                  alt={networkName}
                  width="16px"
                  height="16px"
                />
              ) : undefined
            }
          >
            Switch to {networkName}
          </SelectNetworkButton>
        </VStack>
        <Button
          textColor="blue.500"
          fontWeight="600"
          fontSize="16px"
          variant="ghost"
          onClick={onClose}
          width="fit-content"
          alignSelf="center"
        >
          Ignore
        </Button>
      </ModalContent>
    </Modal>
  )
}

export default SwitchToAuctionNetworkModal
