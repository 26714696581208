import { FallbackProvider, JsonRpcProvider } from '@ethersproject/providers'
import { ChainId, useSendTransaction } from '@usedapp/core'
import dayjs from 'dayjs'
import { BigNumber, FixedNumber, Signer, Contract } from 'ethers'
import { isEqual } from 'lodash'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { useCurrentTimeSlow, useGraphQueries } from '../data/hooks/helper-hooks'
import { useAllBorrowTenders } from '../data/hooks/use-all-borrow-tenders'
import { useAllBorrows } from '../data/hooks/use-all-borrows'
import { useAllLoanTenders } from '../data/hooks/use-all-loan-tenders'
import { useAllLoans } from '../data/hooks/use-all-loans'
import { useGraphAllRepoExposures } from '../data/hooks/use-all-repo-exposures'
import { TokenAllowance, useAllowances } from '../data/hooks/use-allowances'
import { useGraphAuctions } from '../data/hooks/use-auctions'
import { useUsersBombPots } from '../data/hooks/use-users-bomb-pots'
import { useBalances } from '../data/hooks/use-balances'
import { useDeleteTenders } from '../data/hooks/use-delete-tenders'
import {
  ManageCollateralToken,
  useManageCollateral,
} from '../data/hooks/use-manage-collateral'
import {
  ManageLoanTokens,
  useManageLoans,
} from '../data/hooks/use-manage-loans'
import {
  RolloverManagerAddress,
  useManageRollovers,
} from '../data/hooks/use-manage-rollovers'
import { useMissingTenderRates } from '../data/hooks/use-missing-tender-rates'
import { usePrices } from '../data/hooks/use-prices'
import { useGraphTerms } from '../data/hooks/use-terms'
import { useTokensApprove } from '../data/hooks/use-token-approve'
import {
  Address,
  Auction,
  Currency,
  TenderId,
  TermBorrow,
  TermLoan,
} from '../data/model'
import { bigToFixedNumber } from '../helpers/conversions'
import { watchAsset } from '../helpers/eip747'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../managers/subgraphManager'
import { PortfolioPageParams } from '../models/portfolio'
import { useChainConfigs, useConfig } from '../providers/config'
import { useGlobalRefresher } from '../providers/refresher'
import { PagePortfolioQuery, PagePortfolioQueryVariables } from '../gql/graphql'
import { useListings } from '../data/hooks/use-listings'
import {
  GetPortfolioListingsQuery,
  GetPortfolioListingsQueryVariables,
} from '../gql/listings/graphql'
import { useGraphListings } from '../data/hooks/use-graph-listings'
import { EvmError, decodeError } from '../helpers/evm'
import { generateTermAuth } from '../data/auth'
import { waitForStatus } from '../helpers/wait'
import { getABIVersion } from '../helpers/conversions'
import TermRepoServicerABI_0_2_4 from '../abi/v0.2.4/TermRepoServicer.json'
import TermRepoServicerABI_0_4_1 from '../abi/v0.4.1/TermRepoServicer.json'
import TermRepoServicerABI_0_6_0 from '../abi/v0.6.0/TermRepoServicer.json'
import { captureException } from '@sentry/react'
import { useClock } from '../providers/time'

type QueryIds = {
  lastAuctionsId: string
  lastReposId: string
  lastPurchasesId: string
  lastRepoExposuresId: string
  lastRepoCollateralsId: string
  lastBidsId: string
  lastOffersId: string
  lastRepoTokenRedemptionsId: string
  lastRolloverInstructionsId: string
}

interface ExtendedTokenAllowance extends TokenAllowance {
  decimals: number
  termId: string
}

const queryParamsInitialState = {
  lastIds: {} as Record<string, QueryIds>,
}

export function usePortfolioPage(
  account: string | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  onCheckActiveNetwork: (
    chainId?: ChainId,
    chainName?: string
  ) => Promise<boolean>,
  onKytCheck: () => Promise<boolean>
) {
  const currentTime = useCurrentTimeSlow()
  const clock = useClock()
  const config = useConfig()
  const { slow: autoRefresher } = useGlobalRefresher()

  const chainConfigs = useChainConfigs()
  const userBombPots = useUsersBombPots(account)

  const listingContractsByChain = useMemo(
    () =>
      chainConfigs.reduce<{ [chainId: string]: Address }>((acc, chain) => {
        acc[chain.chainId.toString()] = chain.listingsContractAddress
        return acc
      }, {}),
    [chainConfigs]
  )

  // set initial state for id fetching
  const [currentQueryIdParams, updateQueryIdParams] = useReducer(
    queryArgReducer,
    queryParamsInitialState
  )

  const [allData, handleNewData] = useReducer(dataReducer, undefined)

  // if data is still being fetched then this persists the portfolio loading page
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false)

  // if account changes, reset query params to original state
  useEffect(() => {
    handleNewData(undefined)
    setAllDataLoaded(false)
    updateQueryIdParams(queryParamsInitialState)
  }, [account])

  const queries = useMemo(() => {
    if (!account) return []
    return chainConfigs.map((chainConfig) => {
      const lastIds = currentQueryIdParams.lastIds[chainConfig.chainId] || {
        lastAuctionsId: '',
        lastReposId: '',
        lastPurchasesId: '',
        lastRepoExposuresId: '',
        lastRepoCollateralsId: '',
        lastBidsId: '',
        lastOffersId: '',
        lastRepoTokenRedemptionsId: '',
        lastRolloverInstructionsId: '',
      }
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.PAGE_PORTFOLIO
      )
      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.PAGE_PORTFOLIO,
        variables: {
          wallet: account,
          recordsToFetch: chainConfig.subgraphPageLimit,
          ...lastIds,
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.subgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [account, chainConfigs, currentQueryIdParams])

  const {
    results: pagedData,
    // fetching,
    error: subgraphError,
    refresh: readFromSubgraph,
  } = useGraphQueries<PagePortfolioQuery, PagePortfolioQueryVariables>(queries)

  const listingsQueries = useMemo(() => {
    if (!account) return []
    return chainConfigs.map((chainConfig) => {
      const subgraphVersion = chainConfig.getSubgraphVersion()
      const queryDoc = getQueryDocument(
        subgraphVersion,
        DocumentType.PORTFOLIO_LISTINGS
      )
      const queryVariables = getQueryVariables({
        subgraphVersion,
        docType: DocumentType.PORTFOLIO_LISTINGS,
        variables: {
          wallet: account,
        },
      })
      return {
        chainId: chainConfig.chainId,
        url: chainConfig.listingsSubgraphUrl,
        query: queryDoc,
        variables: queryVariables,
      }
    })
  }, [account, chainConfigs])

  const {
    results: listingsData,
    // fetching,
    error: listingsSubgraphError,
    refresh: readFromListingsSubgraph,
  } = useGraphQueries<
    GetPortfolioListingsQuery,
    GetPortfolioListingsQueryVariables
  >(listingsQueries)

  useEffect(() => {
    readFromSubgraph()
    readFromListingsSubgraph()
  }, [readFromSubgraph, readFromListingsSubgraph, autoRefresher])

  // handles data changes from subgraph
  useEffect(() => {
    if (!pagedData || subgraphError) return
    handleNewData(pagedData)
  }, [subgraphError, pagedData])

  // handles subsequent page fetches from subgraph
  useEffect(() => {
    if (!pagedData) return

    if (subgraphError) {
      console.warn('error fetching subgraph data: ', subgraphError)
      return
    }

    let updatesNeeded = false
    const newQueryParams: Record<string, any> = {}

    Object.entries(pagedData).forEach(([chainId, data]) => {
      // if any entries match the page limit then fetch more data
      const shouldFetchMore =
        data &&
        [
          data.termAuctions,
          data.termRepos,
          data.termPurchases,
          data.termRepoExposures,
          data.termRepoCollaterals,
          data.termBids,
          data.termOffers,
          data.repoTokenRedemptions,
          data.termRolloverInstructions,
        ].some((arr) => arr.length === config.chains[chainId].subgraphPageLimit)

      if (shouldFetchMore) {
        updatesNeeded = true
        // update query params to fetch next page if needed
        newQueryParams[chainId] = {
          lastAuctionsId:
            data.termAuctions.length > 0
              ? data.termAuctions[data.termAuctions.length - 1].id
              : '',
          lastReposId:
            data.termRepos.length > 0
              ? data.termRepos[data.termRepos.length - 1].id
              : '',
          lastPurchasesId:
            data.termPurchases.length > 0
              ? data.termPurchases[data.termPurchases.length - 1].id
              : '',
          lastRepoExposuresId:
            data.termRepoExposures.length > 0
              ? data.termRepoExposures[data.termRepoExposures.length - 1].id
              : '',
          lastRepoCollateralsId:
            data.termRepoCollaterals.length > 0
              ? data.termRepoCollaterals[data.termRepoCollaterals.length - 1].id
              : '',
          lastBidsId:
            data.termBids.length > 0
              ? data.termBids[data.termBids.length - 1].id
              : '',
          lastOffersId:
            data.termOffers.length > 0
              ? data.termOffers[data.termOffers.length - 1].id
              : '',
          lastRepoTokenRedemptionsId:
            data.repoTokenRedemptions.length > 0
              ? data.repoTokenRedemptions[data.repoTokenRedemptions.length - 1]
                  .id
              : '',
          lastRolloverInstructionsId:
            data.termRolloverInstructions.length > 0
              ? data.termRolloverInstructions[
                  data.termRolloverInstructions.length - 1
                ].id
              : '',
        }
      }
    })

    if (updatesNeeded) {
      updateQueryIdParams((prevParams: Record<string, QueryIds>) => ({
        ...prevParams,
        lastIds: {
          ...prevParams.lastIds,
          ...newQueryParams,
        },
      }))
    } else {
      setAllDataLoaded(true)
    }
  }, [subgraphError, config.chains, pagedData])

  // handles refresh triggerred by global refresher
  useEffect(() => {
    // if refresh, then reset query params to original state
    updateQueryIdParams(queryParamsInitialState)
    readFromSubgraph()
  }, [autoRefresher, readFromSubgraph])

  // Load blockchain data
  const terms = useGraphTerms(allData)

  const repoExposures = useGraphAllRepoExposures(allData)

  const [auctionsData] = useGraphAuctions(provider, allData, account)
  const auctions = useMemo(
    () =>
      auctionsData
        ?.filter((x) => !!x)
        ?.reduce(
          (acc, cur) => {
            acc[cur.address] = cur
            return acc
          },
          {} as { [address: string]: Auction }
        ),
    [auctionsData]
  )

  const currenciesByChain = useMemo(() => {
    const chainCurrencies: {
      [chainId: string]: { [address: Address]: Currency }
    } = {}

    Object.entries(terms ?? []).forEach(([chainId, termsByChainId]) => {
      chainCurrencies[chainId] = chainCurrencies[chainId] || {}

      Object.values(termsByChainId).forEach((term) => {
        ;[
          term.purchaseCurrency,
          term.collateralCurrency,
          term.termRepoTokenCurrency,
        ]
          .filter((currency) => currency && currency.address)
          .forEach((currency) => {
            chainCurrencies[chainId][currency.address] = currency
          })
      })
    })

    return chainCurrencies
  }, [terms])

  const [purchaseTokensByChain, collateralTokensByChain, termTokensByChain] =
    useMemo(() => {
      const purchaseTokensByChainId: {
        [chainId: string]: ExtendedTokenAllowance[]
      } = {}
      const collateralTokensByChainId: {
        [chainId: string]: ExtendedTokenAllowance[]
      } = {}
      const termRepoTokensByChainId: {
        [chainId: string]: ExtendedTokenAllowance[]
      } = {}

      Object.entries(terms ?? []).forEach(([chainId, termsByChainId]) => {
        purchaseTokensByChainId[chainId] =
          purchaseTokensByChainId[chainId] || []
        collateralTokensByChainId[chainId] =
          collateralTokensByChainId[chainId] || []
        termRepoTokensByChainId[chainId] =
          termRepoTokensByChainId[chainId] || []

        Object.values(termsByChainId).forEach((term) => {
          if (
            term?.purchaseCurrency?.address &&
            term.termRepoLockerAddress &&
            dayjs.unix(term.endOfRepaymentWindowTimestamp).isAfter(currentTime)
          ) {
            purchaseTokensByChainId[chainId].push({
              token: term.purchaseCurrency.address,
              owner: account,
              spender: term.termRepoLockerAddress,
              decimals: term.purchaseCurrency.decimals,
              termId: term.id,
            } as ExtendedTokenAllowance)
          }

          if (
            term &&
            term.collateralCurrency &&
            term.collateralCurrency.address &&
            term.termRepoLockerAddress &&
            dayjs.unix(term.endOfRepaymentWindowTimestamp).isAfter(currentTime)
          ) {
            collateralTokensByChainId[chainId].push({
              token: term.collateralCurrency.address,
              owner: account,
              spender: term.termRepoLockerAddress,
              decimals: term.collateralCurrency.decimals,
              termId: term.id,
            } as ExtendedTokenAllowance)
          }

          if (
            term &&
            term.termRepoTokenCurrency &&
            listingContractsByChain &&
            dayjs.unix(term.endOfRepaymentWindowTimestamp).isAfter(currentTime)
          ) {
            termRepoTokensByChainId[chainId].push({
              token: term.termRepoTokenCurrency.address,
              owner: account,
              spender: listingContractsByChain[term.chainId],
              decimals: term.termRepoTokenCurrency.decimals,
              termId: term.id,
            } as ExtendedTokenAllowance)
          }
        })
      })

      return [
        purchaseTokensByChainId,
        collateralTokensByChainId,
        termRepoTokensByChainId,
      ]
    }, [account, listingContractsByChain, currentTime, terms])

  const purchaseTokenAllowances = useAllowances(purchaseTokensByChain, provider)
  const collateralTokenAllowances = useAllowances(
    collateralTokensByChain,
    provider
  )
  const termRepoTokenAllowances = useAllowances(termTokensByChain, provider)

  const allowedPurchaseTokens = useMemo(() => {
    if (!purchaseTokenAllowances || !purchaseTokensByChain) {
      return undefined
    }

    const result: { [chainId: string]: { [termId: string]: FixedNumber } } = {}

    // Initialize each term with a default value of 0
    Object.entries(terms ?? []).forEach(([chainId, termsByChainId]) => {
      result[chainId] = {}
      Object.values(termsByChainId).forEach((term) => {
        result[chainId][term.id] = FixedNumber.fromString(
          '0',
          `fixed128x${term.purchaseCurrency?.decimals}`
        )
      })
    })

    // Overwrite with actual allowance values if available
    Object.entries(purchaseTokenAllowances).forEach(
      ([chainId, tokenAllowances]) => {
        Object.entries(tokenAllowances).forEach(([termId, allowance]) => {
          if (result[chainId][termId]) {
            result[chainId][termId] = bigToFixedNumber(
              allowance[0],
              purchaseTokensByChain[chainId]?.find((t) => t.termId === termId)
                ?.decimals || 0
            )
          }
        })
      }
    )

    return result
  }, [purchaseTokenAllowances, purchaseTokensByChain, terms])

  const allowedCollateralTokens = useMemo(() => {
    if (!collateralTokenAllowances || !collateralTokensByChain) {
      return undefined
    }

    const result: { [chainId: string]: { [termId: string]: FixedNumber } } = {}

    // Initialize each term with a default value of 0
    Object.entries(terms ?? []).forEach(([chainId, termsByChainId]) => {
      result[chainId] = {}
      Object.values(termsByChainId).forEach((term) => {
        result[chainId][term.id] = FixedNumber.fromString(
          '0',
          `fixed128x${term.collateralCurrency?.decimals}`
        )
      })
    })

    // Overwrite with actual allowance values if available
    Object.entries(collateralTokenAllowances ?? {}).forEach(
      ([chainId, allowances]) => {
        Object.entries(allowances).forEach(([termId, allowance]) => {
          if (result[chainId][termId]) {
            result[chainId][termId] = bigToFixedNumber(
              allowance[0],
              collateralTokensByChain[chainId]?.find((t) => t.termId === termId)
                ?.decimals || 0
            )
          }
        })
      }
    )

    return result
  }, [collateralTokenAllowances, collateralTokensByChain, terms])

  const allowedTermRepoTokens = useMemo(() => {
    if (!termRepoTokenAllowances || !termTokensByChain) {
      return undefined
    }

    const result: { [chainId: string]: { [termId: string]: FixedNumber } } = {}

    // Initialize each term with a default value of 0
    Object.entries(terms ?? []).forEach(([chainId, termsByChainId]) => {
      result[chainId] = {}
      Object.values(termsByChainId).forEach((term) => {
        result[chainId][term.id] = FixedNumber.fromString(
          '0',
          `fixed128x${term.purchaseCurrency?.decimals}`
        )
      })
    })

    // Overwrite with actual allowance values if available
    Object.entries(termRepoTokenAllowances).forEach(
      ([chainId, tokenAllowances]) => {
        Object.entries(tokenAllowances).forEach(([termId, allowance]) => {
          if (result[chainId][termId]) {
            result[chainId][termId] = bigToFixedNumber(
              allowance[0],
              termTokensByChain[chainId]?.find((t) => t.termId === termId)
                ?.decimals || 0
            )
          }
        })
      }
    )

    return result
  }, [termRepoTokenAllowances, termTokensByChain, terms])

  const approveToken = useTokensApprove(signer)

  const [loanTenders, reloadLoanTenders] = useAllLoanTenders(account, allData)
  const [borrowTenders, reloadBorrowTenders] = useAllBorrowTenders(
    account,
    allData
  )

  const [loadMissingTenderRates, isLoadingMissingRates] = useMissingTenderRates(
    account,
    loanTenders,
    borrowTenders,
    signer
  )

  const balancesData = useBalances(account, currenciesByChain, provider)

  const balances = useMemo(() => {
    if (!balancesData) return undefined

    const result: { [chainId: string]: { [address: Address]: FixedNumber } } =
      {}

    Object.entries(balancesData).forEach(([chainId, chainBalances]) => {
      if (!result[chainId]) {
        result[chainId] = {}
      }
      chainBalances.forEach((balanceObj) => {
        result[chainId][balanceObj.address] = balanceObj.balance
      })
    })

    return result
  }, [balancesData])

  const loans = useAllLoans(allData, balances)
  const borrows = useAllBorrows(account, currenciesByChain, allData)

  const tokenPairings = useMemo(() => {
    if (!terms) return undefined

    const tokens: {
      managedCollateralTokens: {
        [chainId: string]: ManageCollateralToken[] | undefined
      }
      managedLoanTokens: { [chainId: string]: ManageLoanTokens[] | undefined }
      rolloverManagerAddresses: {
        [chainId: string]: RolloverManagerAddress[] | undefined
      }
    } = {
      managedCollateralTokens: {},
      managedLoanTokens: {},
      rolloverManagerAddresses: {},
    }

    Object.entries(terms).forEach(([chainId, termsByChainId]) => {
      tokens.managedCollateralTokens[chainId] = []
      tokens.managedLoanTokens[chainId] = []
      tokens.rolloverManagerAddresses[chainId] = []

      Object.values(termsByChainId).forEach((term) => {
        tokens.managedCollateralTokens[chainId]?.push({
          collateralManager: term.collateralManagerAddress,
          collateralCurrency: term.collateralCurrency,
          version: term.version,
        })

        tokens.managedLoanTokens[chainId]?.push({
          purchaseCurrency: term.purchaseCurrency,
          repoServicer: term.repoServicerAddress,
          version: term.version,
        })

        tokens.rolloverManagerAddresses[chainId]?.push({
          repoRolloverManager: term.rolloverManagerAddress,
          version: term.version,
        })
      })
    })

    return tokens
  }, [terms])

  const {
    managedCollateralTokens,
    managedLoanTokens,
    rolloverManagerAddresses,
  } = tokenPairings || {}

  const {
    erc20TokensByChain,
    termRepoTokensByChain,
    mappedPurchaseTokensByChain,
  } = useMemo(() => {
    const tokensByChain: {
      [chainId: string]: { address: string; decimals: number }[]
    } = {}
    const repoTokensByChain: { [chainId: string]: string[] } = {}
    const purchaseTokensByChain: {
      [chainId: string]: { purchaseToken: Currency; termRepoToken: Currency }[]
    } = {}

    Object.values(terms ?? []).forEach((termsByChainId) => {
      Object.values(termsByChainId).forEach((term) => {
        const chainId = term.chainId

        // For ERC20 tokens (purchase and collateral currencies)
        const erc20Addresses = [
          term.purchaseCurrency?.address,
          term.collateralCurrency?.address,
        ].filter((x) => !!x)

        erc20Addresses.forEach((address) => {
          if (!tokensByChain[chainId]) {
            tokensByChain[chainId] = []
          }

          if (
            !tokensByChain[chainId].some((token) => token.address === address)
          ) {
            tokensByChain[chainId].push({
              address,
              decimals: currenciesByChain[chainId][address].decimals,
            })
          }
        })

        // For Repo tokens
        if (term.termRepoTokenCurrency?.address) {
          const repoAddress = term.termRepoTokenCurrency.address
          if (repoTokensByChain[chainId]) {
            repoTokensByChain[chainId].push(repoAddress)
          } else {
            repoTokensByChain[chainId] = [repoAddress]
          }
        }

        // For Purchase Tokens
        if (term.purchaseCurrency?.address) {
          if (purchaseTokensByChain[chainId]) {
            purchaseTokensByChain[chainId].push({
              purchaseToken: term.purchaseCurrency,
              termRepoToken: term.termRepoTokenCurrency,
            })
          } else {
            purchaseTokensByChain[chainId] = [
              {
                purchaseToken: term.purchaseCurrency,
                termRepoToken: term.termRepoTokenCurrency,
              },
            ]
          }
        }
      })
    })

    // Removing duplicates in repoTokensByChain
    Object.keys(repoTokensByChain).forEach((chainId) => {
      repoTokensByChain[chainId] = [...new Set(repoTokensByChain[chainId])]
    })

    // Removing duplicates in purchaseTokensByChain
    Object.keys(purchaseTokensByChain).forEach((chainId) => {
      const uniqueTokens = new Map<
        string,
        { purchaseToken: Currency; termRepoToken: Currency }
      >()

      purchaseTokensByChain[chainId].forEach((token) => {
        uniqueTokens.set(token.termRepoToken.address, token) // Overwrites if address is duplicated
      })

      purchaseTokensByChain[chainId] = Array.from(uniqueTokens.values())
    })

    return {
      erc20TokensByChain: tokensByChain,
      termRepoTokensByChain: repoTokensByChain,
      mappedPurchaseTokensByChain: purchaseTokensByChain,
    }
  }, [currenciesByChain, terms])

  const {
    discountRateMarkups,
    minimumListingAmounts,
    createListing,
    cancelListings,
  } = useListings(
    provider,
    signer,
    mappedPurchaseTokensByChain,
    readFromListingsSubgraph
  )

  const pricesData = usePrices(
    erc20TokensByChain,
    termRepoTokensByChain,
    provider
  )

  const prices = useMemo(() => {
    if (!pricesData) return undefined

    const result: Record<string, { [token: Address]: FixedNumber }> = {}

    pricesData &&
      Object.entries(pricesData).forEach(([chainId, priceArray]) => {
        result[chainId] =
          priceArray.reduce(
            (acc, price) => {
              acc[price.token] = FixedNumber.fromValue(
                price.price ?? BigNumber.from(0),
                price.decimals,
                `fixed128x${price.decimals}`
              )
              return acc
            },
            {} as { [token: Address]: FixedNumber }
          ) ?? {}
      })

    return result
  }, [pricesData])

  const mappedListingsByChain = useGraphListings(
    listingsData,
    currenciesByChain
  )

  const onAddTermToken = useCallback(
    async (
      chainId: string,
      address: string,
      symbol: string,
      decimals: number
    ) => {
      if (!provider) {
        return false
      }
      return await watchAsset(
        chainId,
        provider as JsonRpcProvider,
        address,
        symbol,
        decimals
      )
    },
    [provider]
  )

  const [deleteBorrowTenders, deleteLoanTenders] = useDeleteTenders(
    account,
    provider,
    signer,
    // TODO (Aidan) these were removed from useAllLoans and useAllBorrows
    () => {
      console.debug('TODO: Deleted tenders reloading borrows')
    },
    () => {
      console.debug('TODO: Deleted tenders reloading loans')
    }
  )

  const { lockCollateral, unlockCollateral } = useManageCollateral(
    managedCollateralTokens,
    provider,
    signer,
    readFromSubgraph
  )
  const { redeemTermTokens, repayTermTokens, collapseBorrow } = useManageLoans(
    managedLoanTokens,
    provider,
    signer,
    readFromSubgraph
  )
  const {
    rolloverDetails,
    rolloverAssignments,
    electRollover,
    cancelRollover,
  } = useManageRollovers(
    rolloverManagerAddresses,
    currenciesByChain,
    provider,
    signer,
    allData,
    readFromSubgraph
  )

  const { historicalLoans, currentLoans } = useMemo(() => {
    const historicalLoans: TermLoan[] = []
    const currentLoans: TermLoan[] = []
    for (const loan of loans ?? []) {
      if (
        loan.termTokenBalance?.isZero() ||
        loan.termTokenBalance?.isNegative()
      ) {
        historicalLoans.push(loan)
      } else {
        currentLoans.push(loan)
      }
    }
    return {
      historicalLoans,
      currentLoans,
    }
  }, [loans])

  const { historicalBorrows, currentBorrows } = useMemo(() => {
    const historicalBorrows: TermBorrow[] = []
    const currentBorrows: TermBorrow[] = []
    for (const borrow of borrows ?? []) {
      if (
        (borrow.borrowBalance?.isZero() ||
          borrow.borrowBalance?.isNegative()) &&
        borrow.collateralBalances[borrow.collateralCurrency].isZero()
      ) {
        historicalBorrows.push(borrow)
      } else {
        currentBorrows.push(borrow)
      }
    }
    return {
      historicalBorrows,
      currentBorrows,
    }
  }, [borrows])

  const rolloverAuctionOptions = useMemo(() => {
    return rolloverDetails
      ? Object.values(rolloverDetails).flatMap((x) => x)
      : undefined
  }, [rolloverDetails])

  //Transaction state hooks for mintOpenExposure
  const {
    sendTransaction: mintOpenExposureTransaction,
    state: mintOpenExposureState,
    resetState: resetMintOpenExposureState,
  } = useSendTransaction()

  // callback for mintOpenExposure on TermRepoServicer
  const mintOpenExposure = useCallback(
    async (
      chainId: ChainId,
      repoServicerAddress: Address,
      version: string,
      purchaseToken: Address,
      amount: BigNumber,
      collateralAmounts: BigNumber[]
    ): Promise<string | undefined> => {
      let termRepoServicer: Contract | undefined

      try {
        if (!signer || !account) {
          throw new Error('Signer or account not available')
        }

        //validate active network
        const activeNetwork = await signer?.provider?.getNetwork()
        if (activeNetwork?.chainId !== chainId) {
          throw new Error('Active network does not match desired chain id')
        }

        //get abi version
        const abiVersion = getABIVersion(version)

        switch (abiVersion) {
          case '0.6.0':
            termRepoServicer = new Contract(
              repoServicerAddress,
              TermRepoServicerABI_0_6_0,
              provider
            )
            break
          case '0.4.1':
            termRepoServicer = new Contract(
              repoServicerAddress,
              TermRepoServicerABI_0_4_1,
              provider
            )
            break
          case '0.2.4':
          default:
            termRepoServicer = new Contract(
              repoServicerAddress,
              TermRepoServicerABI_0_2_4,
              provider
            )
        }

        //encode data based on abi version
        const data =
          abiVersion === '0.2.4'
            ? termRepoServicer.interface.encodeFunctionData(
                'mintOpenExposure',
                [
                  amount,
                  collateralAmounts,
                  await generateTermAuth(
                    signer,
                    termRepoServicer,
                    'mintOpenExposure',
                    [amount, collateralAmounts],
                    clock.now().unix()
                  ),
                ]
              )
            : termRepoServicer.interface.encodeFunctionData(
                'mintOpenExposure',
                [amount, collateralAmounts]
              )

        //send transaction
        const tx = await mintOpenExposureTransaction({
          chainId,
          to: repoServicerAddress,
          from: account,
          data,
        })

        //wait for transaction status
        await waitForStatus(
          async () => mintOpenExposureState,
          resetMintOpenExposureState,
          ['Success', 'Exception', 'Fail'],
          100,
          1000
        )

        readFromSubgraph() // refresh data
        return tx?.transactionHash
      } catch (err) {
        if (err instanceof Error) {
          try {
            if (termRepoServicer) {
              const decodedErr = decodeError(err, termRepoServicer.interface)
              captureException(new EvmError(err, decodedErr))
            } else {
              captureException(err)
            }
          } catch (e) {
            captureException(e)
            captureException(err)
            console.error(`Failed to decode error: ${JSON.stringify(e)}`)
          }
        } else {
          captureException(err)
        }
        throw err
      }
    },
    [
      account,
      signer,
      provider,
      mintOpenExposureTransaction,
      mintOpenExposureState,
      clock,
      resetMintOpenExposureState,
      readFromSubgraph,
    ]
  )

  const params = useMemo(
    () =>
      ({
        allDataLoaded,
        chainConfigs: config.chains,
        terms: terms,
        listingDiscountRateMarkups: discountRateMarkups,
        listingMinimumListingAmounts: minimumListingAmounts,
        listings: mappedListingsByChain,
        borrowTenders: borrowTenders,
        loanTenders: loanTenders,
        borrows: currentBorrows,
        reloadBorrowTenders: reloadBorrowTenders,
        reloadLoanTenders: reloadLoanTenders,
        loans: currentLoans,
        repoExposures: repoExposures,
        historicalBorrows: historicalBorrows,
        historicalLoans: historicalLoans,
        lockCollateral: lockCollateral,
        unlockCollateral: unlockCollateral,
        deleteLoanTenders: deleteLoanTenders
          ? (chainId: string, tenders: TenderId[], auctionId: Address) =>
              deleteLoanTenders(chainId, tenders, auctions?.[auctionId])
          : undefined,
        deleteBorrowTenders: deleteBorrowTenders
          ? (chainId: string, tenders: TenderId[], auctionId: Address) =>
              deleteBorrowTenders(chainId, tenders, auctions?.[auctionId])
          : undefined,
        redeemTermTokens: redeemTermTokens,
        collapseBorrow: collapseBorrow,
        repayTermTokens: repayTermTokens,
        borrowerElectRollover: electRollover,
        borrowerCancelRollover: cancelRollover,
        auctions: auctions,
        rolloverAuctions: rolloverAssignments,
        rolloverAuctionOptions,
        currencies: currenciesByChain,
        prices: prices,
        balances: balances,
        purchaseTokenApprovals: allowedPurchaseTokens,
        collateralTokenApprovals: allowedCollateralTokens,
        termRepoTokenApprovals: allowedTermRepoTokens,
        onCheckActiveNetwork,
        onApprovePurchaseTokens: approveToken,
        onApproveCollateralTokens: approveToken,
        onApproveTermRepoTokens: approveToken,
        onAddTermToken: onAddTermToken,
        onKytCheck: onKytCheck,
        onCreateListing: createListing,
        onCancelListings: cancelListings,
        loadMissingTenderRates,
        isLoadingMissingRates,
        userBombPots,
        mintOpenExposure,
      }) as PortfolioPageParams,
    [
      allDataLoaded,
      config.chains,
      allowedCollateralTokens,
      allowedPurchaseTokens,
      allowedTermRepoTokens,
      approveToken,
      auctions,
      balances,
      terms,
      discountRateMarkups,
      minimumListingAmounts,
      mappedListingsByChain,
      borrowTenders,
      cancelRollover,
      collapseBorrow,
      currenciesByChain,
      currentBorrows,
      currentLoans,
      repoExposures,
      electRollover,
      historicalBorrows,
      historicalLoans,
      loanTenders,
      lockCollateral,
      onAddTermToken,
      onCheckActiveNetwork,
      onKytCheck,
      prices,
      redeemTermTokens,
      reloadBorrowTenders,
      reloadLoanTenders,
      repayTermTokens,
      rolloverAssignments,
      rolloverAuctionOptions,
      unlockCollateral,
      deleteBorrowTenders,
      deleteLoanTenders,
      loadMissingTenderRates,
      createListing,
      cancelListings,
      isLoadingMissingRates,
      userBombPots,
      mintOpenExposure,
    ]
  )
  return params
}

function dataReducer(
  existingData: { [chainId: string]: PagePortfolioQuery } | undefined,
  incomingData: { [chainId: string]: PagePortfolioQuery } | undefined
): { [chainId: string]: PagePortfolioQuery } | undefined {
  if (!incomingData) {
    return undefined
  }

  const mergeUnique = <T extends { id: string }>(
    oldArr: T[] | undefined,
    newArr: T[] | undefined
  ): T[] => [
    ...(newArr || []),
    ...(oldArr || []).filter(
      (oldItem) => !(newArr || []).some((newItem) => oldItem.id === newItem.id)
    ),
  ]

  // merge new data with existing data, unique records only
  // Iterate over each chain ID in the incoming data
  const newData = Object.entries(incomingData).reduce(
    (acc: { [chainId: string]: PagePortfolioQuery }, [chainId, data]) => {
      const existingChainData = existingData?.[chainId]

      // Merge new data with existing data, unique records only, for each chain
      acc[chainId] = {
        termAuctions: mergeUnique(
          existingChainData?.termAuctions,
          data.termAuctions
        ),
        termRepos: mergeUnique(existingChainData?.termRepos, data.termRepos),
        termPurchases: mergeUnique(
          existingChainData?.termPurchases,
          data.termPurchases
        ),
        termRepoExposures: mergeUnique(
          existingChainData?.termRepoExposures,
          data.termRepoExposures
        ),
        termRepoCollaterals: mergeUnique(
          existingChainData?.termRepoCollaterals,
          data.termRepoCollaterals
        ),
        termBids: mergeUnique(existingChainData?.termBids, data.termBids),
        termOffers: mergeUnique(existingChainData?.termOffers, data.termOffers),
        repoTokenRedemptions: mergeUnique(
          existingChainData?.repoTokenRedemptions,
          data.repoTokenRedemptions
        ),
        termRolloverInstructions: mergeUnique(
          existingChainData?.termRolloverInstructions,
          data.termRolloverInstructions
        ),
      }

      return acc
    },
    {} as { [chainId: string]: PagePortfolioQuery }
  )

  // only update data if there are any changes
  if (!isEqual(newData, existingData)) {
    return newData
  } else {
    return existingData
  }
}

function queryArgReducer(existingQueryArgs: any, newQueryArgs: any) {
  if (!isEqual(existingQueryArgs, newQueryArgs)) {
    return newQueryArgs
  } else {
    return existingQueryArgs
  }
}

interface ColumnWidths {
  [key: string]: number
}

/**
 * This custom hook calculates the column widths for the tables passed it.
 * It adds padding for the widths and then if there is extra space available, distributes it evenly across the columns.
 *
 * @param tableRefs tables to calculate the column widths for
 * @param initialColumnWidths minimum column widths
 * @param excludedColumnsFromEmptySpaceCalculation columns to exclude from the empty space calculation
 * @returns dynamically calculated column widths
 */
export const useColumnWidths = (
  tableRefs: React.RefObject<HTMLTableElement>[],
  initialColumnWidths: ColumnWidths,
  excludedColumnsFromEmptySpaceCalculation: Set<string> = new Set(),
  tablesContainData: boolean
): ColumnWidths => {
  const [columnWidths, setColumnWidths] =
    useState<ColumnWidths>(initialColumnWidths)

  useLayoutEffect(() => {
    // Handle offsets here
    const networkOffset = tablesContainData ? 32 : 0
    const statusOffset = tablesContainData ? 8 : 0
    const emptyStateOffset = tablesContainData ? 0 : 8

    const maxColumnWidths: ColumnWidths = { ...initialColumnWidths }

    tableRefs?.forEach((tableRef) => {
      const canvas = document.createElement('canvas')
      // Header
      const headerRow = tableRef.current?.querySelector('thead tr')
      processRow(headerRow, maxColumnWidths, canvas)

      // Footer
      const footerRow = tableRef.current?.querySelector('tfoot tr')
      processRow(footerRow, maxColumnWidths, canvas)

      // Body
      const bodyRows = tableRef.current?.querySelectorAll('tbody tr')
      bodyRows?.forEach((bodyRow) => {
        processRow(bodyRow, maxColumnWidths, canvas)
      })
    })

    // Add some extra padding to the columns
    Object.keys(maxColumnWidths).forEach((key) => {
      maxColumnWidths[key] += 16
      if (key !== 'asset') {
        maxColumnWidths[key] += emptyStateOffset
      }
    })

    // Compare table width to column widths
    const tableWidth = tableRefs[0].current?.offsetWidth

    const totalWidth = Object.values(maxColumnWidths).reduce(
      (acc, currentValue) => acc + currentValue,
      0
    )

    const tableToColsRatio =
      tableWidth && totalWidth ? tableWidth / totalWidth : 0

    // Distribute the whitespace evenly
    if (tableToColsRatio > 0) {
      Object.keys(maxColumnWidths).forEach((key) => {
        if (!excludedColumnsFromEmptySpaceCalculation.has(key)) {
          maxColumnWidths[key] *= tableToColsRatio
        }
      })
    }

    const columnData: ColumnWidths = { ...maxColumnWidths }

    columnData.network += networkOffset
    columnData.status += statusOffset

    setColumnWidths(columnData)
  }, [
    excludedColumnsFromEmptySpaceCalculation,
    initialColumnWidths,
    tableRefs,
    tablesContainData,
  ])

  return columnWidths
}

/**
 * Processes a row of a table to calculate the maximum width of each column.
 */
const processRow = (
  row: Element | null | undefined,
  maxColumnWidths: ColumnWidths,
  canvas: HTMLCanvasElement
) => {
  if (row) {
    Array.from(row.children).forEach((cell) => {
      const cellWidth = getTrimmedTextWidth(cell as HTMLElement, canvas)
      const columnName = cell.getAttribute('data-column')
      if (columnName && columnName === 'emptyColumn') {
        maxColumnWidths[columnName] = Math.max(
          maxColumnWidths[columnName] || 0,
          cellWidth as number
        )
      }
    })
  }
}

/**
 * Calculates the width of the trimmed text content of an HTML element.
 *
 * @param element - The HTML element to calculate the width of.
 * @returns The width of the trimmed text content of the element.
 */
function getTrimmedTextWidth(element: HTMLElement, canvas: HTMLCanvasElement) {
  const text = element.textContent?.trim() || ''
  const context = canvas.getContext('2d')
  return context?.measureText(text).width ?? 0
}
