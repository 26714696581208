import {
  Button,
  ButtonProps,
  ModalContent,
  ModalContentProps,
} from '@chakra-ui/react'
import { Metamask, Walletconnect } from '../../../assets/icons/CreatedIcons'
import {
  ToSHeader,
  ToSHeading,
  ToSSubHeading,
  ToSBody,
  ToSPanelId,
} from './modal'

export const SelectWalletButton = ({
  size = 'md',
  height = '40px',
  width = '452px',
  borderRadius = 'md',
  backgroundColor = 'white',
  _hover = { bg: 'gray.200' },
  justifyContent = 'left',
  borderWidth = '1px',
  borderColor = 'blue.500',
  fontWeight = '600',
  textColor = 'blue.500',
  ...props
}: ButtonProps) => {
  return (
    <Button
      size={size}
      height={height}
      width={width}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      _hover={_hover}
      justifyContent={justifyContent}
      borderWidth={borderWidth}
      borderColor={borderColor}
      fontWeight={fontWeight}
      textColor={textColor}
      {...props}
    />
  )
}

export const SelectWalletProvider = ({
  panelId = 2,
  onConnectMetamask,
  onConnectWalletconnect,
  onNextPanel,
  ...props
}: {
  panelId?: ToSPanelId
  onConnectMetamask?: () => void
  onConnectWalletconnect?: () => void
  onNextPanel: () => void
} & ModalContentProps) => {
  return (
    <ModalContent {...props}>
      <ToSHeader>
        <ToSHeading>Connect Wallet</ToSHeading>
        <ToSSubHeading>Select wallet provider</ToSSubHeading>
      </ToSHeader>
      <ToSBody panel={panelId}>
        <SelectWalletButton
          leftIcon={<Metamask />}
          isDisabled={!onConnectMetamask}
          onClick={async () => {
            if (onConnectMetamask) {
              onNextPanel()
              onConnectMetamask()
            } else {
              console.log('onConnectMetamask is undefined')
            }
          }}
        >
          MetaMask
        </SelectWalletButton>
        <SelectWalletButton
          leftIcon={<Walletconnect />}
          isDisabled={!onConnectWalletconnect}
          onClick={async () => {
            if (onConnectWalletconnect) {
              onNextPanel()
              onConnectWalletconnect()
            } else {
              console.log('onConnectWalletconnect is undefined')
            }
          }}
          mt="10px"
        >
          WalletConnect
        </SelectWalletButton>
      </ToSBody>
    </ModalContent>
  )
}
