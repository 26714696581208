import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Contract, FixedNumber } from 'ethers'
import { Address } from '../model'
import TermRepoServicerABI_0_6_0 from '../../abi/v0.6.0/TermRepoServicer.json'
import { TermRepoServicer as TermRepoServicer_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermRepoServicer'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { useEffect, useMemo } from 'react'
import { captureException } from '@sentry/react'

// TODO: Wire up decimals from purchase currency

export function useTermRepoExposures(
  termRepoServicerAddressesByChain:
    | { [chainId: string]: Address[] | undefined }
    | undefined,
  purchaseTokenDecimalsByChain:
    | { [chainId: string]: number[] | undefined }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
):
  | { [chainId: string]: { [repoServicerAddress: Address]: FixedNumber } }
  | undefined {
  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (termRepoServicerAddressesByChain) {
      Object.entries(termRepoServicerAddressesByChain).forEach(
        ([chainId, addresses]) => {
          const contracts = addresses?.map(
            (address) =>
              new Contract(
                address,
                TermRepoServicerABI_0_6_0,
                provider
              ) as TermRepoServicer_0_6_0
          )

          if (contracts) {
            const repurchaseExposureCalls = contracts.map((contract) => ({
              contract,
              method: 'totalOutstandingRepurchaseExposure',
              args: [],
            }))
            calls[chainId] = [...repurchaseExposureCalls]
          }
        }
      )
    }
    return calls
  }, [termRepoServicerAddressesByChain, provider])

  const blockchainResultsAndErrorsGrouped = useMultichainCalls(contractCalls)

  const blockchainResults = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    blockchainResultsAndErrorsGrouped &&
      Object.entries(blockchainResultsAndErrorsGrouped).forEach(
        ([chainId, pricesAndErrors]) => {
          result[chainId] = pricesAndErrors
            .filter((priceOrError) => !priceOrError?.error)
            .map((priceOrError) => priceOrError?.value)
        }
      )
    return result
  }, [blockchainResultsAndErrorsGrouped])

  const repoExposureErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (blockchainResultsAndErrorsGrouped) {
      Object.entries(blockchainResultsAndErrorsGrouped).forEach(
        ([chainId, pricesAndErrors]) => {
          const chainErrors = pricesAndErrors
            .filter((priceOrError) => !!priceOrError?.error)
            .map((priceOrError) => priceOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [blockchainResultsAndErrorsGrouped])

  useEffect(() => {
    Object.entries(repoExposureErrors).forEach(([chainId, errors]) => {
      errors.forEach((err: Error) => {
        console.error(`Error on chain ${chainId} retrieving prices:`, err)
        captureException(err)
      })
    })
  }, [repoExposureErrors])

  return useMemo(() => {
    // We're still loading if any of these are not defined.
    if (
      !blockchainResults ||
      !termRepoServicerAddressesByChain ||
      !purchaseTokenDecimalsByChain
    ) {
      return undefined
    }

    let allDataLoaded = true

    Object.values(blockchainResults).forEach((priceValues) => {
      if (priceValues.some((price) => price === undefined)) {
        allDataLoaded = false
      }
    })

    const repoExposures: {
      [chainId: string]: { [repoServicerAddress: Address]: FixedNumber }
    } = {}

    Object.entries(termRepoServicerAddressesByChain).forEach(
      ([chainId, addressList]) => {
        if (!addressList) return

        repoExposures[chainId] = {}

        addressList.forEach((address, index) => {
          const exposureValue = blockchainResults[chainId]?.[index]?.[0] ?? 0
          repoExposures[chainId][address] = FixedNumber.fromValue(
            exposureValue,
            purchaseTokenDecimalsByChain[chainId]?.[index]
          )
        })
      }
    )

    return allDataLoaded ? repoExposures : undefined
  }, [
    termRepoServicerAddressesByChain,
    purchaseTokenDecimalsByChain,
    blockchainResults,
  ])
}
