import dayjs from 'dayjs'
import { BigNumber, FixedNumber, utils } from 'ethers'
import { parseEther, parseUnits } from 'ethers/lib/utils'
import {
  SubmittedLoanTender,
  SubmittedBorrowTender,
  Auction,
  Currency,
  TermBorrow,
  TermLoan,
  RolloverAuctionInfo,
  Address,
  TermPeriod,
  NativeCurrency,
} from '../data/model'
import { ChainId } from '@usedapp/core'

export const loanTenders: SubmittedLoanTender[] = [
  {
    chainId: ChainId.Mainnet.toString(),
    type: 'loan',
    submittedDate: dayjs('2023-01-20 08:23').unix(),
    auction: '6',
    lender: '0x126',
    id: '2afcd389',
    amount: {
      shiftedValue: parseUnits('1350', 8),
      currency: '0x123',
    },
    interestRate: FixedNumber.fromString('2.5'),
    transaction: '0x87r34uhlk3jrhsod87fyskjdho8e74ryksdjfhdkj',
    nonce: BigNumber.from('1'),
  },
  {
    chainId: ChainId.Mainnet.toString(),
    type: 'loan',
    submittedDate: dayjs('2023-01-22 19:41').unix(),
    auction: '5',
    lender: '0x126',
    id: '1a23ef03',
    amount: {
      shiftedValue: parseUnits('1350', 8),
      currency: '0x123',
    },
    interestRate: FixedNumber.fromString('2.3'),
    transaction: '0xo8273yrlkjwhfo8s7ydfkjhseor87ykejfhdfkjhdf',
    nonce: BigNumber.from('2'),
  },
]
export const borrowTenders: SubmittedBorrowTender[] = [
  {
    chainId: ChainId.Mainnet.toString(),
    type: 'borrow',
    submittedDate: dayjs('2023-01-21 09:13').unix(),
    auction: '3',
    borrower: '0x126',
    id: '1abcef03',
    amount: {
      shiftedValue: parseUnits('1350', 8),
      currency: '0x123',
    },
    collateral: {
      shiftedValue: parseUnits('.12', 6),
      currency: '0x125',
    },
    interestRate: FixedNumber.fromString('2.1'),
    transaction: '0x87r34uhlk3jrhsod87fyskjdho8e74ryksdjfhdkj',
    nonce: BigNumber.from('3'),
  },
  {
    chainId: '8001',
    type: 'borrow',
    submittedDate: dayjs('2023-01-22 12:42').unix(),
    auction: '4',
    borrower: '0x126',
    id: '6e78cd51',
    amount: {
      shiftedValue: parseUnits('1200', 8),
      currency: '0x123',
    },
    interestRate: FixedNumber.fromString('2.3'),
    collateral: {
      shiftedValue: parseUnits('.14', 6),
      currency: '0x125',
    },
    transaction: '0xo8273yrlkjwhfo8s7ydfkjhseor87ykejfhdfkjhdf',
    nonce: BigNumber.from('4'),
  },
]

export const collateralPrice = FixedNumber.fromString('2.1', 'fixed128x9')
export const purchasePrice = FixedNumber.fromString('1', 'fixed128x9')
export const termTokenPrice = FixedNumber.fromString('2', 'fixed128x9')
export const gasTokenCurrency: NativeCurrency = {
  symbol: 'ETH',
  decimals: 18,
  wrappedGasSymbol: 'WETH',
  wrapConversionFactor: 1,
}
export const collateralCurrency: Currency = {
  address: '0x0000002',
  symbol: 'WBTC',
  decimals: 6,
  isRepoToken: false,
}
export const purchaseCurrency: Currency = {
  address: '0x0000001',
  symbol: 'DAI',
  decimals: 8,
  isRepoToken: false,
}
export const termTokenCurrency: Currency = {
  address: '0x0000003',
  symbol: 'TT',
  decimals: 18,
  isRepoToken: true,
  redemptionTimestamp: 1689775200,
  purchaseToken: '0x0000001',
}

export const currencies = {
  '1': {
    [collateralCurrency.address]: collateralCurrency,
    [purchaseCurrency.address]: purchaseCurrency,
    [termTokenCurrency.address]: termTokenCurrency,
  },
}
export const prices = {
  '1': {
    [collateralCurrency.address]: collateralPrice,
    [purchaseCurrency.address]: purchasePrice,
    [termTokenCurrency.address]: termTokenPrice,
  },
}
export const balances = {
  '1': {
    [collateralCurrency.address]: FixedNumber.fromString(
      '1000000',
      `fixed128x${collateralCurrency.decimals}`
    ),
    [purchaseCurrency.address]: FixedNumber.fromString(
      '1000000',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    [termTokenCurrency.address]: FixedNumber.fromString(
      '1000000',
      `fixed128x${termTokenCurrency.decimals}`
    ),
  },
}

export const auction3: Auction = {
  chainId: ChainId.Mainnet.toString(),
  address: '3',
  version: '0.2.4',
  bidLockerAddress: '0x0000000000000000000000000000000000001117',
  offerLockerAddress: '0x0000000000000000000000000000000000001118',
  termRepoTokenAddress: '0x0000000000000000000000000000000000001119',
  auctionStartTimestamp: dayjs('2023-01-01').unix(),
  auctionRevealTimestamp: dayjs('2023-01-01').add(0.8, 'day').unix(),
  auctionEndTimestamp: dayjs('2023-01-01').add(1, 'day').unix(),
  maturityTimestamp: dayjs('2023-01-01').add(1, 'month').unix(),
  collateralCurrency: collateralCurrency.address,
  collateralCurrencyDecimals: collateralCurrency.decimals,
  collateralCurrencyOraclePriceUSDC: BigNumber.from('1000100000'),
  collateralCurrencyOraclePriceDecimals: 6,
  collateralLiquidatedDamages: parseUnits('0.1', 18),
  purchaseCurrency: purchaseCurrency.address,
  purchaseCurrencyDecimals: purchaseCurrency.decimals,
  purchaseCurrencyOraclePriceUSDC: BigNumber.from('1234567800000'),
  purchaseCurrencyOraclePriceDecimals: 6,
  collateralManagerAddress: '0x0000000000000000000000000000000000001114',
  loanManagerAddress: '0x0000000000000000000000000000000000001115',
  repoLockerAddress: '0x0000000000000000000000000000000000001116',
  initialMarginRatio: FixedNumber.fromString('1.5'),
  maintenanceMarginRatio: FixedNumber.fromString('1.2'),
  termId: '3',
  auctionId: '3',
  closed: false,
  cancelled: false,
  cancelledForWithdrawal: false,
  maxBidPrice: parseEther('1000.00'),
  maxOfferPrice: parseEther('1000.00'),
  minBidAmount: parseUnits('1', 6),
  minOfferAmount: parseUnits('1', 6),
  servicingFee: parseUnits('1', 17),
  auctionClearingRate: BigNumber.from('0'),
}
export const auction4: Auction = {
  chainId: ChainId.Sepolia.toString(),
  address: '4',
  version: '0.2.4',
  bidLockerAddress: '0x0000000000000000000000000000000000001117',
  offerLockerAddress: '0x0000000000000000000000000000000000001118',
  termRepoTokenAddress: '0x0000000000000000000000000000000000001119',
  auctionStartTimestamp: dayjs('2023-01-07').unix(),
  auctionRevealTimestamp: dayjs('2023-01-07').add(0.8, 'day').unix(),
  auctionEndTimestamp: dayjs('2023-01-07').add(1, 'day').unix(),
  maturityTimestamp: dayjs('2023-01-07').add(1, 'month').unix(),
  collateralCurrency: collateralCurrency.address,
  collateralCurrencyDecimals: collateralCurrency.decimals,
  collateralCurrencyOraclePriceUSDC: BigNumber.from('1000100000'),
  collateralCurrencyOraclePriceDecimals: 6,
  collateralLiquidatedDamages: parseUnits('0.1', 18),
  purchaseCurrency: purchaseCurrency.address,
  purchaseCurrencyDecimals: purchaseCurrency.decimals,
  purchaseCurrencyOraclePriceUSDC: BigNumber.from('1234567800000'),
  purchaseCurrencyOraclePriceDecimals: 6,
  collateralManagerAddress: '0x0000000000000000000000000000000000001114',
  loanManagerAddress: '0x0000000000000000000000000000000000001115',
  repoLockerAddress: '0x0000000000000000000000000000000000001116',
  initialMarginRatio: FixedNumber.fromString('1.4'),
  maintenanceMarginRatio: FixedNumber.fromString('1.2'),
  termId: '4',
  auctionId: '4',
  closed: false,
  cancelled: false,
  cancelledForWithdrawal: false,
  maxBidPrice: parseEther('1000.00'),
  maxOfferPrice: parseEther('1000.00'),
  minBidAmount: parseUnits('1', 6),
  minOfferAmount: parseUnits('1', 6),
  servicingFee: parseUnits('1', 17),
  auctionClearingRate: BigNumber.from('0'),
}
export const auction5: Auction = {
  chainId: ChainId.Mainnet.toString(),
  address: '5',
  version: '0.2.4',
  bidLockerAddress: '0x0000000000000000000000000000000000001117',
  offerLockerAddress: '0x0000000000000000000000000000000000001118',
  termRepoTokenAddress: '0x0000000000000000000000000000000000001119',
  auctionStartTimestamp: dayjs('2023-01-14').unix(),
  auctionRevealTimestamp: dayjs('2023-01-14').add(0.8, 'day').unix(),
  auctionEndTimestamp: dayjs('2023-01-14').add(1, 'day').unix(),
  maturityTimestamp: dayjs('2023-01-14').add(1, 'month').unix(),
  collateralCurrency: collateralCurrency.address,
  collateralCurrencyDecimals: collateralCurrency.decimals,
  collateralCurrencyOraclePriceUSDC: BigNumber.from('1000100000'),
  collateralCurrencyOraclePriceDecimals: 6,
  collateralLiquidatedDamages: parseUnits('0.1', 18),
  purchaseCurrency: purchaseCurrency.address,
  purchaseCurrencyDecimals: purchaseCurrency.decimals,
  purchaseCurrencyOraclePriceUSDC: BigNumber.from('1234567800000'),
  purchaseCurrencyOraclePriceDecimals: 6,
  collateralManagerAddress: '0x0000000000000000000000000000000000001114',
  loanManagerAddress: '0x0000000000000000000000000000000000001115',
  repoLockerAddress: '0x0000000000000000000000000000000000001116',
  initialMarginRatio: FixedNumber.fromString('1.4'),
  maintenanceMarginRatio: FixedNumber.fromString('1.1'),
  termId: '5',
  auctionId: '5',
  closed: false,
  cancelled: false,
  cancelledForWithdrawal: false,
  maxBidPrice: parseEther('1000.00'),
  maxOfferPrice: parseEther('1000.00'),
  minBidAmount: parseUnits('1', 6),
  minOfferAmount: parseUnits('1', 6),
  servicingFee: parseUnits('1', 17),
  auctionClearingRate: BigNumber.from('0'),
}
export const auction6: Auction = {
  chainId: ChainId.Mumbai.toString(),
  address: '6',
  version: '0.2.4',
  bidLockerAddress: '0x0000000000000000000000000000000000001117',
  offerLockerAddress: '0x0000000000000000000000000000000000001118',
  termRepoTokenAddress: '0x0000000000000000000000000000000000001119',
  auctionStartTimestamp: dayjs('2023-01-21').unix(),
  auctionRevealTimestamp: dayjs('2023-01-21').add(0.8, 'day').unix(),
  auctionEndTimestamp: dayjs('2023-01-21').add(1, 'day').unix(),
  maturityTimestamp: dayjs('2023-01-21').add(1, 'month').unix(),
  collateralCurrency: collateralCurrency.address,
  collateralCurrencyDecimals: collateralCurrency.decimals,
  collateralCurrencyOraclePriceUSDC: BigNumber.from('1000100000'),
  collateralCurrencyOraclePriceDecimals: 6,
  purchaseCurrency: purchaseCurrency.address,
  purchaseCurrencyDecimals: purchaseCurrency.decimals,
  purchaseCurrencyOraclePriceUSDC: BigNumber.from('1234567800000'),
  purchaseCurrencyOraclePriceDecimals: 6,
  collateralLiquidatedDamages: parseUnits('0.1', 18),
  collateralManagerAddress: '0x0000000000000000000000000000000000001114',
  loanManagerAddress: '0x0000000000000000000000000000000000001115',
  repoLockerAddress: '0x0000000000000000000000000000000000001116',
  initialMarginRatio: FixedNumber.fromString('1.3'),
  maintenanceMarginRatio: FixedNumber.fromString('1.1'),
  termId: '6',
  auctionId: '6',
  closed: false,
  cancelled: false,
  cancelledForWithdrawal: false,
  maxBidPrice: parseEther('1000.00'),
  maxOfferPrice: parseEther('1000.00'),
  minBidAmount: parseUnits('1', 6),
  minOfferAmount: parseUnits('1', 6),
  servicingFee: parseUnits('1', 17),
  auctionClearingRate: BigNumber.from('0'),
}

export const loans: TermLoan[] = [
  {
    id: '1',
    chainId: ChainId.Mainnet.toString(),
    version: '0.2.4',
    redemptionAmount: BigNumber.from('100'),
    repoServicerAddress: '0x123',
    redemptionTimestamp: 1620000000,
    rolloverAmount: BigNumber.from('100'),
    auctionId: auction4.auctionId,
    termId: auction4.termId,
    auction: auction4.address,
    auctionBidLocker: auction4.bidLockerAddress,
    auctionOfferLocker: auction4.offerLockerAddress,
    auctionEndTimestamp: auction4.auctionEndTimestamp,
    auctionClearingRate: auction4.auctionClearingRate,
    maturityTimestamp: auction4.maturityTimestamp,
    purchasePrice: BigNumber.from('100'),
    repurchasePrice: BigNumber.from('100'),

    collateralCurrency: collateralCurrency.address,
    purchaseCurrency: purchaseCurrency.address,
    termTokenCurrency: termTokenCurrency.address,

    termTokenBalance: FixedNumber.from(utils.parseEther('100'), 8),
  },
]

export const borrows: TermBorrow[] = [
  {
    id: '1',
    chainId: ChainId.Mainnet.toString(),
    version: '0.2.4',
    maturityTimestamp: auction3.maturityTimestamp,
    repoCollateralManager: '0x124',
    repoRolloverManager: '0x125',
    repoServicer: '0x123',
    repoLocker: '0x126',
    purchasePrice: FixedNumber.fromString(
      '100',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    repaymentTimestamp: 1620000000,
    redemptionTimestamp: 1630000000,
    repurchasePrice: FixedNumber.fromString(
      '100',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    rolloverAmount: FixedNumber.fromString(
      '100',
      `fixed128x${purchaseCurrency.decimals}`
    ),

    borrowBalance: FixedNumber.fromString(
      '1',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    amountCollected: FixedNumber.fromString(
      '0',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    amountLiquidated: FixedNumber.fromString(
      '0',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    collateralBalances: {
      [collateralCurrency.address]: FixedNumber.fromString(
        '100',
        `fixed128x${collateralCurrency.decimals}`
      ),
    },

    collateralCurrency: collateralCurrency.address,
    purchaseCurrency: purchaseCurrency.address,
    termTokenCurrency: termTokenCurrency.address,

    maintenanceMarginRatio: FixedNumber.fromString(
      auction3.maintenanceMarginRatio.toString()
    ),

    termId: auction3.termId,
  },
]

export const purchaseTokenApprovals = {
  '1': {
    [auction3.termId]: FixedNumber.fromString(
      '10000',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    [auction4.termId]: FixedNumber.fromString(
      '0',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    [auction5.termId]: FixedNumber.fromString(
      '0',
      `fixed128x${purchaseCurrency.decimals}`
    ),
    [auction6.termId]: FixedNumber.fromString(
      '0',
      `fixed128x${purchaseCurrency.decimals}`
    ),
  },
}

export const collateralTokenApprovals = {
  '1': {
    [auction3.termId]: FixedNumber.fromString(
      '10000',
      `fixed128x${collateralCurrency.decimals}`
    ),
    [auction4.termId]: FixedNumber.fromString(
      '0',
      `fixed128x${collateralCurrency.decimals}`
    ),
    [auction5.termId]: FixedNumber.fromString(
      '0',
      `fixed128x${collateralCurrency.decimals}`
    ),
    [auction6.termId]: FixedNumber.fromString(
      '0',
      `fixed128x${collateralCurrency.decimals}`
    ),
  },
}

export const terms: {
  [chainId: string]: {
    [termRepoTokenAddress: Address]: TermPeriod
  }
} = {
  '1': {
    [termTokenCurrency.address]: {
      id: '3',
      chainId: ChainId.Mainnet.toString(),
      version: '0.2.4',
      repoServicerAddress: '0x0000000000000000000000000000000000001111',
      rolloverManagerAddress: '0x0000000000000000000000000000000000001112',
      collateralManagerAddress: '0x0000000000000000000000000000000000001113',
      termRepoLockerAddress: '0x0000000000000000000000000000000000001114',
      termRepoTokenAddress: termTokenCurrency.address,
      maturityTimestamp: dayjs('2023-01-01').unix(),
      endOfRepaymentWindowTimestamp: dayjs('2023-01-02').unix(),
      purchaseCurrency: purchaseCurrency,
      collateralCurrency: collateralCurrency,
      termRepoTokenCurrency: termTokenCurrency,
    },
  },
}

export const repoExposures: { [termId: string]: BigNumber } = {
  '3': BigNumber.from('0'),
  '4': BigNumber.from('0'),
}

export const auctions: { [id: string]: Auction } = {
  '3': auction3,
  '4': auction4,
  '5': auction5,
  '6': auction6,
}
export const revealedPrices: { [tenderId: string]: FixedNumber } = {
  '2afcd389': FixedNumber.fromString('0.0378'),
  '1a23ef03': FixedNumber.fromString('0.0147'),
  '1abcef03': FixedNumber.fromString('0.0287'),
  '6e78cd51': FixedNumber.fromString('0.0234'),
}
export const rolloverAuctions: {
  [tenderId: string]: Auction & {
    rolloverManagerAddress: Address
    purchaseCurrencySymbol: string
    collateralCurrencySymbol: string
  }
} = {
  '1abcef03': {
    ...auction6,
    rolloverManagerAddress: '0x1234',
    purchaseCurrencySymbol: purchaseCurrency.symbol,
    collateralCurrencySymbol: collateralCurrency.symbol,
  },
}
export const rolloverAuctionOptions: RolloverAuctionInfo[] = Object.values(
  auctions
).map((auction) => ({
  chainId: ChainId.Mainnet.toString(),
  version: '0.2.4',
  id: auction.address,
  newTermId: auction.termId,
  oldTermId: auction.termId,
  auction: '0x1266',
  auctionBidLocker: '0x1255',
  auctionOfferLocker: '0x1244',
  newTermRepoServicer: '0x1422',
  rolloverManagerAddress: '0x1234',
  auctionEndTimestamp: auction.auctionEndTimestamp,
  maturityTimestamp: auction.maturityTimestamp,
  endOfRepurchaseWindowTimestamp: dayjs(auction.maturityTimestamp)
    .add(1, 'day')
    .unix(),
  auctionComplete: false,
  marginRequirement: FixedNumber.fromString(
    auction.initialMarginRatio.toString()
  ),
  collateralCurrencySymbol: currencies['1'][auction.collateralCurrency].symbol,
  purchaseCurrencySymbol: currencies['1'][auction.purchaseCurrency].symbol,
  servicingFee: BigNumber.from(0),
}))

// TODO [DOSPORE]
export const userBombPots = undefined
