import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { Contract, FixedNumber } from 'ethers'
import { IERC20MetadataUpgradeable } from '../../abi-generated'
import { Address, Balance, Currency } from '../model'
import ERC20ABI from '../../abi/v0.2.4/IERC20MetadataUpgradeable.json'
import { useMemo } from 'react'
import { captureException } from '@sentry/react'

export function useRepoLockerBalances(
  repoLockerCollateralsByChain:
    | { [chainId: string]: { [termRepoLocker: Address]: Currency[] } }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
):
  | {
      [chainId: string]: {
        [termRepoLocker: string]: {
          [collateralCurrencyAddress: string]: Balance
        }
      }
    }
  | undefined {
  const { contractCalls, callMeta } = useMemo(() => {
    const calls: MultichainCalls = {}
    const callMeta: {
      [chainId: string]: {
        termRepoLockerAddress: string
        collateralCurrencyAddress: string
      }[]
    } = {}

    if (repoLockerCollateralsByChain) {
      Object.entries(repoLockerCollateralsByChain).forEach(
        ([chainId, termRepoLockers]) => {
          calls[chainId] = []
          callMeta[chainId] = []

          Object.entries(termRepoLockers).forEach(
            ([termRepoLockerAddress, collateralCurrencies]) => {
              collateralCurrencies.forEach((currency) => {
                const contract = new Contract(
                  currency.address,
                  ERC20ABI,
                  provider
                ) as IERC20MetadataUpgradeable
                calls[chainId].push({
                  contract,
                  method: 'balanceOf',
                  args: [termRepoLockerAddress],
                })
                callMeta[chainId].push({
                  termRepoLockerAddress,
                  collateralCurrencyAddress: currency.address,
                })
              })
            }
          )
        }
      )
    }

    return { contractCalls: calls, callMeta }
  }, [repoLockerCollateralsByChain, provider])

  const blockchainResults = useMultichainCalls(contractCalls)

  const balances = useMemo(() => {
    if (!blockchainResults || !callMeta || !repoLockerCollateralsByChain) {
      return undefined // Data is still loading or missing
    }

    const result: {
      [chainId: string]: {
        [termRepoLocker: string]: {
          [collateralCurrencyAddress: string]: Balance
        }
      }
    } = {}

    let allDataLoaded = true

    Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
      const metaList = callMeta[chainId]
      if (!metaList) {
        allDataLoaded = false
        return
      }

      chainResults.forEach((resultItem, index) => {
        const meta = metaList[index]
        if (!meta) {
          allDataLoaded = false
          return
        }

        const { termRepoLockerAddress, collateralCurrencyAddress } = meta

        if (resultItem?.error) {
          // Handle error
          console.error(
            `Error fetching balance for chainId ${chainId}, termRepoLocker ${termRepoLockerAddress}, collateral ${collateralCurrencyAddress}:`,
            resultItem.error
          )
          captureException(resultItem.error)
          allDataLoaded = false
          return
        }

        const value = resultItem?.value
        if (value === undefined || value.length === 0) {
          allDataLoaded = false
          return
        }

        // Get the Currency object
        const currencies =
          repoLockerCollateralsByChain[chainId]?.[termRepoLockerAddress]
        if (!currencies) {
          console.warn(
            `Currencies not found for chainId ${chainId}, termRepoLocker ${termRepoLockerAddress}`
          )
          allDataLoaded = false
          return
        }

        const currency = currencies.find(
          (c) =>
            c.address.toLowerCase() === collateralCurrencyAddress.toLowerCase()
        )
        if (!currency) {
          console.warn(
            `Currency not found for chainId ${chainId}, termRepoLocker ${termRepoLockerAddress}, collateral ${collateralCurrencyAddress}`
          )
          allDataLoaded = false
          return
        }

        const balanceValue = value[0] // Assuming value is an array with the balance at index 0

        const balance: Balance = {
          address: collateralCurrencyAddress,
          balance: FixedNumber.fromValue(balanceValue, currency.decimals),
          decimals: currency.decimals,
          symbol: currency.symbol,
        }

        if (!result[chainId]) {
          result[chainId] = {}
        }
        if (!result[chainId][termRepoLockerAddress]) {
          result[chainId][termRepoLockerAddress] = {}
        }
        result[chainId][termRepoLockerAddress][collateralCurrencyAddress] =
          balance
      })
    })

    return allDataLoaded ? result : undefined
  }, [blockchainResults, callMeta, repoLockerCollateralsByChain])

  return balances

  // const { balancesAndErrors } = useMemo(() => {
  //   const balancesResults: Record<string, CallResult<Contract, string>[]> = {}

  //   Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
  //     balancesResults[chainId] = chainResults
  //   })
  //   return {
  //     balancesAndErrors: balancesResults,
  //   }
  // }, [blockchainResults])

  // const balances = useMemo(() => {
  //   const result: Record<string, any> = {}
  //   if (balancesAndErrors) {
  //     Object.entries(balancesAndErrors).forEach(([chainId, chainBalances]) => {
  //       result[chainId] = chainBalances
  //         .filter(
  //           (balanceOrError) =>
  //             balanceOrError?.error === undefined &&
  //             balanceOrError?.value !== undefined
  //         )
  //         .map((balanceOrError) => balanceOrError?.value)
  //     })
  //   }
  //   return Object.keys(result).length > 0 ? result : undefined
  // }, [balancesAndErrors])

  // const blockchainErrors = useMemo(() => {
  //   const result: Record<string, any> = {}
  //   if (blockchainResults) {
  //     Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
  //       const chainErrors = chainResults
  //         .filter(
  //           (valueOrError) => valueOrError && valueOrError?.error !== undefined
  //         )
  //         .map((valueOrError) => valueOrError?.error)

  //       if (chainErrors.length > 0) {
  //         result[chainId] = chainErrors
  //       }
  //     })
  //   }
  //   return Object.keys(result).length > 0 ? result : undefined
  // }, [blockchainResults])

  // useEffect(() => {
  //   if (blockchainErrors) {
  //     Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
  //       console.error(`Error on ${chainId}: ${chainError}`)
  //       captureException(chainError)
  //     })
  //   }
  // }, [blockchainErrors])

  // return useMemo(() => {

  //   // We're still loading if any of these are not defined.
  //   if (!repoLockerCollateralsByChain || !blockchainResults || !balances) {
  //     return undefined // still loading or missing data
  //   }

  //   const result: {
  //     [chainId: string]: {
  //       [termRepoLocker: string]: {
  //         [collateralCurrencyAddress: string]: Balance;
  //       };
  //     };
  //   } = {};

  //   let allDataLoaded = true

  //   Object.entries(repoLockerCollateralsByChain).forEach(([chainId, addressPair]) => {
  //     const currencies = addressPair.repoLockerAddress
  //     const chainBalances = balances[chainId]

  //     // if any of the data is missing or the lengths don't match, we're still loading
  //     if (
  //       !repoLockerCollateralsByChain ||
  //       !chainBalances ||
  //       chainBalances.length !== Object.keys(repoLockerCollateralsByChain).length
  //     ) {
  //       result[chainId] = []
  //       allDataLoaded = false
  //       return
  //     }

  //     result[chainId] = Object.entries(currencies)
  //       .map(([address, currency], index) => {
  //         const balanceResult = chainBalances[index]

  //         if (balanceResult !== undefined) {
  //           const balance = FixedNumber.fromValue(
  //             balanceResult?.[0] || 0,
  //             currency.decimals,
  //             `fixed128x${currency.decimals}`
  //           )
  //           return {
  //             address,
  //             balance,
  //             decimals: currency.decimals,
  //             symbol: currency.symbol,
  //           }
  //         } else {
  //           return undefined
  //         }
  //       })
  //       .filter((bal): bal is Balance => bal !== undefined)
  //   })

  //   // return if all data is loaded + and there is at least one balance otherwise undefined
  //   const retVal = allDataLoaded
  //     ? Object.keys(result).length > 0
  //       ? result
  //       : undefined
  //     : undefined

  //   return retVal
  // }, [repoLockerCollateralsByChain, balances, blockchainResults])
}
