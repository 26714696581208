import {
  Accordion,
  Box,
  BoxProps,
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useContext, useMemo } from 'react'
import { VStack } from '../../elements/Stack'
import Filter, { CheckboxOption } from './Filter'

import {
  MOBILE_SIDEBAR_MARGIN,
  SIDEBAR_EXPANDED_WIDTH,
  SIDEBAR_MARGIN,
} from '../../../helpers/constants'
import {
  AuctionFilterGroup,
  AuctionFilterGroupName,
  FiltersContext,
} from '../../../providers/filters'
import FilterLoading from './FilterLoading'

export default function Sidebar({
  isDataLoading,
  loanTermOptions,
  lendingOptions,
  collateralOptions,
  statusOptions,
  chainOptions,
}: {
  isDataLoading: boolean
  loanTermOptions: CheckboxOption<'term'>[]
  lendingOptions: CheckboxOption<'lending'>[]
  collateralOptions: CheckboxOption<'collateral'>[]
  statusOptions: CheckboxOption<'status'>[]
  chainOptions: CheckboxOption<'chain'>[]
}) {
  const { filters, updateFilters, clearFilters } = useContext(FiltersContext)
  const { onClose } = useDisclosure()
  return (
    <Box h="auto" position="sticky">
      <SidebarContent
        isDataLoading={isDataLoading}
        onClose={() => onClose}
        display={{ base: 'none', xl: 'block' }}
        filters={filters}
        updateFilters={updateFilters}
        clearFilters={clearFilters}
        loanTermOptions={loanTermOptions}
        lendingOptions={lendingOptions}
        collateralOptions={collateralOptions}
        statusOptions={statusOptions}
        chainOptions={chainOptions}
      />
      {/* <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            filters={filters}
            updateFilters={updateFilters}
            clearFilters={clearFilters}
            loanTermOptions={loanTermOptions}
            lendingOptions={lendingOptions}
            collateralOptions={collateralOptions}
            statusOptions={statusOptions}
          />
        </DrawerContent>
      </Drawer> */}
      {/* mobilenav */}
      {/* <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} /> */}
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  isDataLoading: boolean
  onClose: () => void
  filters: any
  updateFilters: <G extends AuctionFilterGroupName>(
    filter: G,
    val: AuctionFilterGroup<G>
  ) => void
  clearFilters: () => void
  loanTermOptions: CheckboxOption<'term'>[]
  lendingOptions: CheckboxOption<'lending'>[]
  collateralOptions: CheckboxOption<'collateral'>[]
  statusOptions: CheckboxOption<'status'>[]
  chainOptions: CheckboxOption<'chain'>[]
}

const SidebarContent = ({
  isDataLoading,
  onClose,
  filters,
  updateFilters,
  clearFilters,
  loanTermOptions,
  lendingOptions,
  collateralOptions,
  statusOptions,
  chainOptions,
  ...rest
}: SidebarProps) => {
  const termFilters = useMemo(() => {
    if (!filters || !filters.term) {
      return undefined
    }
    const termFilters = { ...filters.term }
    for (const key of loanTermOptions) {
      if (termFilters[key.value] === undefined) {
        termFilters[key.value] = false
      }
    }
    return termFilters
  }, [filters, loanTermOptions])

  const lendingFilters = useMemo(() => {
    if (!filters || !filters.lending) {
      return undefined
    }
    const lendingFilters = { ...filters.lending }
    for (const key of lendingOptions) {
      if (lendingFilters[key.value] === undefined) {
        lendingFilters[key.value] = false
      }
    }
    return lendingFilters
  }, [filters, lendingOptions])

  const collateralFilters = useMemo(() => {
    if (!filters || !filters.collateral) {
      return undefined
    }
    const collateralFilters = { ...filters.collateral }
    for (const key of collateralOptions) {
      if (collateralFilters[key.value] === undefined) {
        collateralFilters[key.value] = false
      }
    }
    return collateralFilters
  }, [filters, collateralOptions])

  const statusFilters = useMemo(() => {
    if (!filters || !filters.status) {
      return undefined
    }
    const statusFilters = { ...filters.status }
    for (const key of statusOptions) {
      if (statusFilters[key.value] === undefined) {
        statusFilters[key.value] = false
      }
    }
    return statusFilters
  }, [filters, statusOptions])

  const chainFilters = useMemo(() => {
    if (!filters || !filters.chain) {
      return undefined
    }
    const chainFilters = { ...filters.chain }
    for (const key of chainOptions) {
      if (chainFilters[key.value] === undefined) {
        chainFilters[key.value] = false
      }
    }
    return chainFilters
  }, [filters, chainOptions])

  return (
    <Box
      w={SIDEBAR_EXPANDED_WIDTH}
      mr={{
        base: MOBILE_SIDEBAR_MARGIN,
        '1xl': SIDEBAR_MARGIN,
      }}
      // w={{ base: "full", md: 60 }}
      // pos="fixed"
      pos="sticky"
      top="20px"
      {...rest}
    >
      <Flex mb="22px" alignItems="center" justifyContent="space-between">
        <Text variant="body-sm/extra-bold">Filter</Text>
        <Button
          data-cy="filters-clear-all"
          variant="link"
          onClick={clearFilters}
        >
          <Text as="span" variant="body-xs/semibold" color="blue.5">
            Clear all
          </Text>
        </Button>
        {/* <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} /> */}
      </Flex>
      <Accordion allowMultiple defaultIndex={[0, 1, 2, 3, 4]}>
        <VStack spacing="8px" align="stretch">
          {isDataLoading ? (
            <>
              {[
                'Loan Term',
                'Supply / Borrow',
                'Collateral',
                'Auction Status',
                'Network',
              ].map((title, index) => (
                <FilterLoading title={title} key={index} />
              ))}
            </>
          ) : (
            <>
              <Filter
                title="Loan Term"
                value={termFilters}
                disabled={loanTermOptions.length > 1 ? false : true}
                onChange={(values) => updateFilters('term', values)}
                options={loanTermOptions}
              />
              <Filter
                title="Supply / Borrow"
                value={lendingFilters}
                disabled={lendingOptions.length > 1 ? false : true}
                onChange={(values) => updateFilters('lending', values)}
                options={lendingOptions}
              />
              <Filter
                title="Collateral"
                value={collateralFilters}
                disabled={collateralOptions.length > 1 ? false : true}
                onChange={(values) => updateFilters('collateral', values)}
                options={collateralOptions}
              />
              <Filter
                title="Auction Status"
                value={statusFilters}
                disabled={statusOptions.length > 1 ? false : true}
                onChange={(values) => updateFilters('status', values)}
                options={statusOptions}
              />
              <Filter
                title="Network"
                value={chainFilters}
                disabled={chainOptions.length > 1 ? false : true}
                onChange={(values) => updateFilters('chain', values)}
                options={chainOptions}
              />
            </>
          )}
        </VStack>
      </Accordion>
    </Box>
  )
}

// interface MobileProps extends FlexProps {
//   onOpen: () => void
// }
// const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
//   return (
//     <Flex
//       ml={{ base: 0, md: 60 }}
//       px={{ base: 4, md: 24 }}
//       height="20"
//       alignItems="center"
//       bg={useColorModeValue('white', 'gray.900')}
//       borderBottomWidth="1px"
//       borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
//       justifyContent="flex-start"
//       {...rest}
//     >
//       <IconButton
//         variant="outline"
//         onClick={onOpen}
//         aria-label="open menu"
//         // icon={<FiMenu />}
//       />

//       <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
//         Filter
//       </Text>
//     </Flex>
//   )
// }
