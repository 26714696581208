import {
  Box,
  Button,
  ModalBody as CModalBody,
  HStack,
  Image,
  Link,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import closeIcon from '../../../../assets/icons/close.svg'
import { LockIcon } from '../../../../components/elements/Icons'
import ProgressBarWithStepper from '../../../../components/elements/ProgressBarWithStepper'
import ProjectIcon, {
  Project,
} from '../../../../components/elements/ProjectIcon'
import { UNLOCK_BONUS_POSITIONS_TARGET } from '../../../../helpers/constants'

type HeaderProps = {
  lockedPoints: number
  activeSince: number
  activeOn: Project[]
  numTransactions: number
  onClose: () => void
}

export const ModalHeader = ({
  lockedPoints,
  activeSince,
  activeOn,
  numTransactions,
  onClose,
}: HeaderProps) => {
  const formattedPoints = lockedPoints.toLocaleString()
  return (
    <VStack align="left" gap={'0'}>
      <Box position="relative" mb={'25px'}>
        <HStack gap={1.5}>
          <LockIcon w="11px" />
          <Text
            variant="body-xs/medium"
            fontStyle="normal"
            lineHeight="20px"
            color="gray.5"
          >
            Unlock your {formattedPoints} migration bonus
          </Text>
        </HStack>
        <Text
          variant="body-2xl/medium"
          fontStyle="normal"
          lineHeight="24px"
          color="blue.9"
          mt="5px"
        >
          Unlock your migration bonus
        </Text>
        <Box position="absolute" right={'4px'} bottom={'2px'}>
          <Image
            src={closeIcon}
            boxSize="16px"
            cursor="pointer"
            onClick={onClose}
          />
        </Box>
      </Box>
      <Text
        variant="body-sm/normal"
        fontStyle="normal"
        fontWeight={400}
        color="blue.9"
        opacity={0.7}
        mb={'24px'}
      >
        Unlock your bonus for being an active lender and borrower:
      </Text>
      <HStack justify="space-between">
        <VStack align="left">
          <Text variant="body-sm/medium" color="gray.6" opacity={0.5}>
            Your Bonus
          </Text>
          <Text variant="body-sm/bold" color="green.5">
            {formattedPoints} points
          </Text>
        </VStack>
        <VStack align="left">
          <Text variant="body-sm/medium" color="gray.6" opacity={0.5}>
            Active On
          </Text>
          <HStack spacing={'4px'} h="20px">
            {activeOn.map((project) => (
              <ProjectIcon project={project} />
            ))}
          </HStack>
        </VStack>
        <VStack align="left">
          <Text variant="body-sm/medium" color="gray.6" opacity={0.5}>
            Active Since
          </Text>
          <Text variant="body-sm/medium" color="blue.9">
            {dayjs.unix(activeSince).format('MMMM D, YYYY')}
          </Text>
        </VStack>
        <VStack align="left">
          <Text variant="body-sm/medium" color="gray.6" opacity={0.5}>
            Transactions
          </Text>
          <Text variant="body-sm/medium" color="blue.9">
            {numTransactions}
          </Text>
        </VStack>
      </HStack>
    </VStack>
  )
}

type BodyProps = {
  positionsFilled: number
  hasProfile: boolean
  onUnlockMigrationSignUp: () => void
}

const STEPS = [
  'View upcoming auctions',
  'Submit an offer to lend, or bid to borrow',
  'When the auction ends, check to see if your position was opened',
]

export const ModalBody = ({
  positionsFilled,
  hasProfile,
  onUnlockMigrationSignUp,
}: BodyProps) => {
  return (
    <CModalBody p={0}>
      <Text variant="body-xl/medium" color="blue.9" lineHeight={'28px'} mb={2}>
        How to unlock
      </Text>
      <Box mb={'25px'}>
        <Text
          variant="body-sm/medium"
          fontWeight={600}
          opacity={0.7}
          color="blue.9"
        >
          Open 3 positions to unlock:
        </Text>
        <Text
          variant="body-sm/normal"
          fontWeight={400}
          opacity={0.7}
          color="blue.9"
        >
          <OrderedList px={2}>
            {STEPS.map((step) => (
              <ListItem key={step}>{step}</ListItem>
            ))}
          </OrderedList>
        </Text>
      </Box>
      <Box mb={'25px'}>
        {!hasProfile ? (
          <Text mt={1} variant="body-sm/bold" color="blue.9">
            Note:{' '}
            <Text as="span" variant="body-sm/normal" color="blue.9">
              You must have a profile to unlock.{' '}
            </Text>
            <Button variant="link" onClick={onUnlockMigrationSignUp} px={0}>
              <Text as="span" variant="body-sm/bold" color="blue.5">
                Sign up
              </Text>
            </Button>
          </Text>
        ) : undefined}
      </Box>
      <VStack spacing={2} align="left" w="full">
        <Text variant="body-xs/normal" color="blue.9" opacity={0.5}>
          {positionsFilled}/{UNLOCK_BONUS_POSITIONS_TARGET} positions opened
        </Text>
        <Box>
          <ProgressBarWithStepper
            currentStep={positionsFilled}
            totalSteps={UNLOCK_BONUS_POSITIONS_TARGET}
          />
        </Box>
      </VStack>
    </CModalBody>
  )
}

export const ModalFooter = () => (
  <Box w="full" mt={'37px'}>
    <Link href={window.location.origin}>
      <Button w="full" variant="secondary">
        View auctions
      </Button>
    </Link>
  </Box>
)
