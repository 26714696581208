import { useState, useEffect } from 'react'
import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { useCurrencies, TokenInfo } from './use-currencies'
import { ChainId } from '@usedapp/core'

// only bomb pots on mainnet
const BOMB_POT_URL: string =
  'https://term-finance-auction-bomb-pots.s3.us-west-1.amazonaws.com/mainnet/rewardMapping.json'

type BombPots = Record<
  string,
  {
    repoTokenAddress: string
    reward: string
    rewardToken: string
    rewardTokenSymbol: string
    rewardTokenDecimals: number
    auctionId: string
  }
>

export function useBombPots(
  provider: JsonRpcProvider | FallbackProvider | undefined
): BombPots | undefined {
  const { data: bombPotInfo } = useJsonRestCalls<
    { '#call': RequestInfo },
    BombPots
  >({
    '#call': {
      url: BOMB_POT_URL,
      method: 'GET',
    } as RequestInfo,
  })

  const [tokens, setTokens] = useState<
    Partial<Record<ChainId, TokenInfo[]>> | undefined
  >()
  const [bombPots, setBombPots] = useState<BombPots | undefined>()

  const currencies = useCurrencies(tokens, provider)

  useEffect(() => {
    if (bombPotInfo) {
      // TODO [DOSPORE] API bug where there is a space at the end of the string
      const rewardTokens = Object.values(bombPotInfo).map((info) =>
        info.rewardToken.replace(' ', '')
      )
      const uniqueTokens = new Set(rewardTokens)
      const arrUniqueTokens = Array.from(uniqueTokens).map((address) => ({
        address,
        isRepoToken: false,
        version: '',
      }))

      setTokens({
        [ChainId.Mainnet]: arrUniqueTokens,
      })
    }
  }, [bombPotInfo])

  useEffect(() => {
    const bombPotTokens = currencies?.[ChainId.Mainnet]
    if (bombPotInfo && bombPotTokens) {
      const bombPotTokenMap = bombPotTokens.reduce(
        (o, k) => {
          o[k.address] = k
          return o
        },
        {} as Record<
          string,
          { symbol: string; address: string; decimals: number }
        >
      )

      const bombPots_: BombPots = {}

      for (const auctionId of Object.keys(bombPotInfo)) {
        // TODO [DOSPORE] bug in api return
        const rewardToken = bombPotInfo[auctionId].rewardToken.replace(' ', '')
        const rewardTokenInfo = bombPotTokenMap[rewardToken]
        if (!rewardTokenInfo) {
          console.error(
            `Could not find rewardToken (${rewardToken}) info in`,
            bombPotTokenMap
          )
          return
        }

        const bombPot = {
          ...bombPotInfo[auctionId],
          rewardTokenSymbol: rewardTokenInfo.symbol,
          rewardTokenDecimals: rewardTokenInfo.decimals,
        }

        bombPots_[auctionId] = bombPot
      }

      setBombPots(bombPots_)
    }
  }, [currencies?.[ChainId.Mainnet], bombPotInfo])

  return bombPots
}
