import {
  Button,
  Link,
  ModalContent,
  ModalContentProps,
  Text,
} from '@chakra-ui/react'
import {
  ToSBody,
  ToSHeader,
  ToSHeading,
  ToSPanelId,
  ToSSubHeading,
} from './modal'

export const UnableToConnect = ({
  panelId = 3,
  ...props
}: {
  panelId?: ToSPanelId
} & ModalContentProps) => {
  return (
    <ModalContent {...props}>
      <ToSHeader>
        <ToSHeading>Unable to Connect</ToSHeading>
        <ToSSubHeading>Troubleshooting</ToSSubHeading>
      </ToSHeader>
      <ToSBody panel={panelId}>
        <Text as="span">
          We’re sorry, we’re currently unable to connect your wallet.
          <br />
          <br />
          If you’re using a hardware wallet, ensure that both MetaMask and your
          browser are up-to-date, and that blind signing is enabled.{' '}
          <Link
            href="https://support.ledger.com/hc/en-us/articles/360020871157-Solving-a-MetaMask-connection-issue-?support=true"
            isExternal
            textDecoration="none"
            fontWeight="medium"
          >
            Troubleshoot
          </Link>
          <br />
          <br />
          Otherwise, check your wallet provider to ensure that your wallet is
          properly connected and unlocked.
          <br />
          <br />
          Finally,{' '}
          <Button
            onClick={() => window.location.reload()}
            variant="link"
            px={0}
          >
            <Text as="span" variant="body-md/medium" color="blue.5">
              hard refresh
            </Text>
          </Button>{' '}
          the page or access the application from a different browser.
        </Text>
      </ToSBody>
    </ModalContent>
  )
}
