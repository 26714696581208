import { Button, Heading, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import page_not_found_graphic from '../../../assets/page-not-found-graphic.svg'
import { socialLinks } from '../../../socials'
import { VStack } from '../Stack'

export default function PageNotFound() {
  return (
    <VStack py="60px" alignItems="center" w="full" overflow="hidden">
      <Image
        src={page_not_found_graphic}
        alt="Page not found"
        w="575px"
        h="272px"
        mb={4}
      />
      <VStack spacing={2} alignItems="center" color="blue.9" maxW="529px">
        <Text as="h1" variant="body-3xl/bold">
          Oops!
        </Text>
        <Heading as="h2" variant="heading/h2">
          We can’t find the page you’re looking for.
        </Heading>
        <Text
          variant="body-sm/normal"
          textAlign="center"
          display="block"
          mb="12px !important"
        >
          Don’t let this get in the way of achieving your financial goals.
          Browse our auctions to initiate your journey of securing fixed-rate
          loans.
        </Text>
        <Link to="/">
          <Button variant="primary">Go to auctions</Button>
        </Link>
        <a href={socialLinks.discord} target="_blank" rel="noopener noreferrer">
          <Button variant="link" mb="36px !important">
            <Text as="span" variant="body-sm/normal" color="blue.5">
              Contact us on Discord
            </Text>
          </Button>
        </a>
      </VStack>
    </VStack>
  )
}
