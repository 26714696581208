import { Box, Text, Grid, GridItem } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import {
  PointsCardLoadingSmall,
  PointsCardLoadingLarge,
} from '../PointsCardLoading'
type Props = {}

const cards = new Array(10).fill(1)

export default function EarnSectionLoading({}: Props) {
  return (
    <>
      <Box mb={6}>
        <Text color={'blue.9'} variant={'body-2xl/medium'}>
          Take action to earn more points
        </Text>
      </Box>
      <HStack spacing={4} mb={4} w="full">
        <PointsCardLoadingLarge />
        <PointsCardLoadingLarge />
        <PointsCardLoadingLarge />
      </HStack>
      <Grid templateColumns="repeat(5, auto)" gap={4}>
        {cards.map((card, index) => (
          <GridItem key={index}>
            <PointsCardLoadingSmall />
          </GridItem>
        ))}
      </Grid>
    </>
  )
}
