import { Box, Link, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import {
  MOBILE_CONTAINER_PADDING,
  CONTAINER_PADDING,
} from '../../../../helpers/constants'
import {
  purchaseCurrency,
  collateralCurrency,
  gasTokenCurrency,
} from '../../../../test-helpers/data-portfolio'
import CreateTendersBox from '../CreateTendersBox'
import FAQBox from '../FAQBox'
import OpenAuctionStatisticsLoading from '../OpenAuctionStatisticsLoading'
import OpenTenders from '../OpenTenders'
import OpenTendersBadge from '../OpenTendersBadge'
import PageDescription from '../PageDescription'
import c from '../../content.json'
import PageTitle from '../PageTitle'
import {
  ValidatedBorrowTender,
  ValidatedLoanTender,
} from '../../../../data/model'
import { BigNumber, FixedNumber } from 'ethers'

const zeroBN = BigNumber.from(0)
const zeroFN = FixedNumber.fromString('0')

const mockAuction = {
  chainId: '1',
  maturityTimestamp: 1634870400,
  purchaseCurrencyOraclePriceUSDC: zeroBN,
  purchaseCurrencyOraclePriceDecimals: 0,
  collateralCurrencyOraclePriceUSDC: zeroBN,
  collateralCurrencyOraclePriceDecimals: 0,
  collateralLiquidatedDamages: zeroBN,
  initialMarginRatio: zeroFN,
  auctionStartTimestamp: 1634870400,
  auctionEndTimestamp: 1634870400,
  servicingFee: zeroBN,
  minOfferAmount: zeroBN,
  maxOfferPrice: zeroBN,
  minBidAmount: zeroBN,
  maxBidPrice: zeroBN,
}

const mockAuctionActivityData = {
  offersCount: 0,
  offersAmount: BigNumber.from(0),
  bidsCount: 0,
  bidsAmount: BigNumber.from(0),
}

export function AuctionPageEmpty({ onConnect }: { onConnect: () => void }) {
  return (
    <Box maxW="1440px" mx="auto">
      <OpenAuctionStatisticsLoading />
      <Box
        px={{
          base: MOBILE_CONTAINER_PADDING,
          xl: CONTAINER_PADDING,
        }}
        py={4}
      >
        <HStack
          justifyContent="space-between"
          w="full"
          alignItems="flex-end"
          spacing="62px"
        >
          <Box>
            <PageTitle title={c.title} />
            <PageDescription>
              <Text>
                {c.description1}
                {c.descriptionHead}
                <Link
                  textDecoration="none"
                  href={c.singleInterestRateLink}
                  isExternal
                >
                  {c.singleInterestRate}
                </Link>
                {c.descriptionTail}
              </Text>
            </PageDescription>
          </Box>
          <FAQBox content={c.faqContent} learnMoreLink={c.learnMoreLink} />
        </HStack>
        <CreateTendersBox
          isDataLoading
          auction={mockAuction}
          auctionActivityData={mockAuctionActivityData}
          purchaseCurrency={purchaseCurrency}
          purchaseTokenAllowance={zeroBN}
          purchaseTokenBalance={zeroBN}
          collateralCurrency={collateralCurrency}
          collateralTokenBalance={zeroBN}
          collateralTokenAllowance={zeroBN}
          nakedCollateralCurrency={collateralCurrency}
          nakedCollateralTokenBalance={zeroBN}
          nakedCollateralTokenAllowance={zeroBN}
          convertedNakedCollateralTokenBalance={zeroBN}
          gasTokenBalance={zeroFN}
          gasTokenCurrency={gasTokenCurrency}
          referralCode={''}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onCreateLoanTenders={async (tenders: ValidatedLoanTender[]) => {}}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onCreateBorrowTenders={async (tenders: ValidatedBorrowTender[]) => {}}
          onApprovePurchaseToken={async () => {}}
          onApproveCollateralToken={async () => {}}
          onApproveNakedCollateralToken={async () => {}}
          onDeleteReferralCode={() => {}}
          onConnect={onConnect}
          onKytCheck={async () => true}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onWrapGasTokenCall={async (value: BigNumber) => {}}
          onWrapTokenCall={async (
            // eslint-disable-next-line unused-imports/no-unused-vars
            address: string,
            // eslint-disable-next-line unused-imports/no-unused-vars
            symbol: string,
            // eslint-disable-next-line unused-imports/no-unused-vars
            value: BigNumber
          ) => {}}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onUnwrapGasTokenCall={async (value: BigNumber) => {}}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onUnwrapTokenCall={async (address: string, value: BigNumber) => {}}
        />
        <OpenTendersBadge count={0} />
        <OpenTenders
          isDataLoading
          auction={mockAuction}
          loanTenders={[]}
          borrowTenders={[]}
          purchaseCurrency={purchaseCurrency}
          collateralCurrency={collateralCurrency}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onDelete={async (type: 'loan' | 'borrow', ids: string[]) => {}}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onEditLoanTenders={async (edits: ValidatedLoanTender[]) => {}}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onEditBorrowTenders={async (edits: ValidatedBorrowTender[]) => {}}
          onLoadMissingTenderRates={async () => {}}
          purchaseTokenBalance={zeroBN}
          collateralTokenBalance={zeroBN}
          purchaseTokenAllowance={zeroBN}
          onApprovePurchaseToken={async () => {}}
          collateralTokenAllowance={zeroBN}
          onApproveCollateralToken={async () => {}}
          onKytCheck={async () => true}
          gasTokenBalance={zeroFN}
          gasTokenCurrency={gasTokenCurrency}
          isLoadingMissingRates={false}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onWrapGasTokenCall={async (value: BigNumber) => {}}
          // eslint-disable-next-line unused-imports/no-unused-vars
          onUnwrapGasTokenCall={async (value: BigNumber) => {}}
        />
      </Box>
    </Box>
  )
}
