import { Box, Spinner, TableContainer } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'

import {
  Table,
  TableFoot as Tfoot,
  TableHead as Thead,
  Tr,
} from '../../../../components/elements/Table'
import TableHeaderCell from './TableHeaderCell'

export default function LoanTendersTableLoading() {
  return (
    <TableContainer>
      <Table isFixedLayout hasTopBorderRadius>
        <Thead>
          <Tr>
            <TableHeaderCell w="180px">
              <HStack spacing={4}>
                <Box>Submitted</Box>
              </HStack>
            </TableHeaderCell>
            <TableHeaderCell textAlign="right" w="180px">
              Supply Amount
            </TableHeaderCell>
            <TableHeaderCell textAlign="right" w="188px">
              Min. Rate
            </TableHeaderCell>
            <TableHeaderCell textAlign="right" w="230px">
              Transaction
            </TableHeaderCell>
            <TableHeaderCell
              w="full"
              p={{
                base: '0 397px 0 0',
                xl: '0 522px 0 0',
              }}
            />
          </Tr>
        </Thead>
        <Tfoot>
          <Tr noBorder>
            <TableHeaderCell textAlign="center" colSpan={5} p="14px 0">
              <HStack w="full" justifyContent="center">
                <Spinner
                  color="blue.500"
                  w="42px"
                  h="42px"
                  className="chromatic-ignore"
                />
              </HStack>
            </TableHeaderCell>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}
