import {
  AspectRatio,
  Box,
  Fade,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import walletAnalyzerAnimation from '../../../../assets/video/wallet-analyzer-animation.webm'
import { HStack } from '../../../../components/elements/Stack'
import { WalletAnalyzerProps } from '../../utils'
import { ResultsSummary } from './ResultsSummary'

const NotificationBar = ({
  showAnimation,
  showCaption,
  isOpen,
}: {
  showAnimation: boolean
  showCaption: boolean
  isOpen: boolean
}) => (
  <Fade
    in={showAnimation && showCaption && isOpen}
    style={{ transitionDuration: showCaption ? '0.5s' : '5s' }}
  >
    <HStack
      position="absolute"
      top="15px"
      left="50%"
      transform="translateX(-50%)"
      zIndex={100}
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
      borderRadius="6px"
      align="center"
      justify="center"
      p={3}
    >
      <Text variant="body-xs/medium" color="gray.6" opacity={0.7}>
        Press{' '}
        <Text as="span" border="1px solid" borderRadius="6px" p={1.5} mx={2}>
          ESC
        </Text>{' '}
        to exit animation
      </Text>
    </HStack>
  </Fade>
)

const AnimatedVideoBackground = ({
  children,
  showAnimation,
}: {
  children: React.ReactNode
  showAnimation: boolean
}) => (
  <Fade in={showAnimation} style={{ transition: 'opacity 3s' }}>
    <AspectRatio
      position="fixed"
      top={0}
      left={0}
      zIndex={100}
      width="100vw"
      height="100vh"
    >
      <>
        {children}
        <video width="100%" height="auto" autoPlay loop muted>
          <source src={walletAnalyzerAnimation} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </>
    </AspectRatio>
  </Fade>
)

export default function WalletAnalyzer({
  isAnimating,
  walletUserType,
  title,
  comments,
  bonusPoints,
  onCloseWalletAnalyzer,
  onAddAnotherWallet,
}: WalletAnalyzerProps) {
  const [escPressed, setEscPressed] = useState(false)
  const [showCaption, setShowCaption] = useState(true)
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  useEffect(() => {
    const timeoutId = setTimeout(() => setShowCaption(false), 5000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: { key: string }) => {
      if (event.key === 'Escape') {
        setEscPressed(true)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  if (!isOpen) {
    return null
  }

  const showAnimation = isAnimating && !escPressed

  return (
    <>
      {showAnimation && (
        <Box position="absolute" top="0" zIndex="100" left="0" width="100%">
          <AnimatedVideoBackground showAnimation={showAnimation}>
            <NotificationBar
              showAnimation={showAnimation}
              showCaption={showCaption}
              isOpen={isOpen}
            />
          </AnimatedVideoBackground>
        </Box>
      )}
      <Fade in={!showAnimation} style={{ transition: 'opacity 3s' }}>
        <Flex align="center" justify="center" h="100vh" overflow="hidden">
          <ResultsSummary
            walletUserType={walletUserType}
            title={title}
            comments={comments}
            bonusPoints={bonusPoints}
            onCloseWalletAnalyzer={onCloseWalletAnalyzer}
            onAddAnotherWallet={onAddAnotherWallet}
          />
        </Flex>
      </Fade>
    </>
  )
}
