import { graphql } from '../../gql/listings'

// All active listings for a given token list
export const ActiveListingsDocument = graphql(`
  query GetActiveListings($tokenList: [Bytes!]!) {
    listings(
      where: { token_in: $tokenList, cancelled: false, remainingAmount_gte: 0 }
    ) {
      id
      seller
      token
      listingAmount
      remainingAmount
      cancelled
    }
  }
`)
