import { useState, useEffect, useMemo } from 'react'

// only bomb pots on mainnet
const BOMB_POT_URL =
  'https://term-finance-auction-bomb-pots.s3.us-west-1.amazonaws.com/mainnet/userRewards.json'

export type BombPotReward = {
  totalAssignedAmount: number
  totalAmountCleared: number
  termInDays: number
  purchaseToken: string
  termRepoToken: string
  rewardToken: string
  purchaseTokenPrice: number
  rewardTokenPrice: number
  impliedApy: number
  totalReward: number
  userReward: number
  userRewardUsd: number
}

type BombPotsAPIReturn = Record<string, Record<string, BombPotReward[]>>

export function useUsersBombPots(
  account: string | undefined
): Record<string, BombPotReward[]> | undefined {
  const [allBombPots, setAllBombPots] = useState<
    BombPotsAPIReturn | undefined
  >()

  useEffect(() => {
    fetch(BOMB_POT_URL)
      .then((res) => res.json())
      .then((allBombPots) => {
        setAllBombPots(allBombPots)
      })
      .catch((err) => {
        // TODO could retry if failed
        console.error('Failed to fetch bomb pots', err)
      })
  }, [])

  const usersBombPots: Record<string, BombPotReward[]> | undefined =
    useMemo(() => {
      if (!account || !allBombPots) {
        return undefined
      }
      return allBombPots[account.toLocaleLowerCase()]
    }, [allBombPots, account])

  return usersBombPots
}
