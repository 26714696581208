import {
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Td,
} from '@chakra-ui/react'
import warning_icon from '../../../../assets/icons/warning.svg'
import Tooltip from '../../../../components/elements/Tooltip'

export default function EditableCell(
  props: InputProps & {
    editingError?: string
  }
) {
  const editingError = props.editingError
  const isError = !!editingError

  return (
    <Td p={0}>
      <InputGroup w="100%">
        {isError && (
          <Tooltip
            boxShadow="none"
            infoIcon={false}
            hasError={true}
            placement="bottom-start"
            label={editingError}
          >
            <InputLeftElement
              cursor="pointer"
              w={5}
              h={7}
              ml={1}
              children={<Image src={warning_icon} h={5} w={5} />}
            />
          </Tooltip>
        )}
        <Input
          bgColor="white"
          variant="flushed"
          fontSize="xs"
          lineHeight="4"
          pt="6px"
          pb="5px"
          px="9px"
          pl={isError ? '28px' : '9px'}
          pr="2px"
          border={isError ? '1px solid' : undefined}
          borderColor={isError ? 'red.5' : undefined}
          _focus={{
            border: isError ? '1px solid' : undefined,
            borderColor: isError ? 'red.5' : 'blue.5',
          }}
          height="auto"
          fontFamily="Inter"
          letterSpacing="0.025em"
          w="full"
          {...props}
        />
      </InputGroup>
    </Td>
  )
}
