import { ChainId } from '@usedapp/core'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Button, Heading, Text } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from '../../../../components/layout/Container'
import SummaryBreakdown from './SummaryBreakdown'
import SummaryHeader from './SummaryHeader'
import { add, subtract } from '../../../../helpers/math'
import { Trans, t } from '@lingui/macro'

export default function SummarySection({
  totalLoanAmountUsd,
  totalReceivedRepoTokensUsd,
  totalOpenListingsUsd,
  interestToReceiveUsd,
  totalBorrowAmountUsd,
  interestToPayUsd,
  openLoanCount,
  openBorrowCount,
  openPositionCount,
  openPositionNetworks,
  collateralData,
  openTenderData,
}: {
  totalLoanAmountUsd: FixedNumber
  totalReceivedRepoTokensUsd: FixedNumber
  totalOpenListingsUsd: FixedNumber
  interestToReceiveUsd: FixedNumber
  totalBorrowAmountUsd: FixedNumber
  interestToPayUsd: FixedNumber
  openLoanCount: number
  openBorrowCount: number
  openPositionCount: number
  openPositionNetworks: ChainId[]
  collateralData?: { label: string; value?: FixedNumber; node?: ReactNode }[]
  openTenderData?: { label: string; value: FixedNumber }[]
}) {
  const navigate = useNavigate()

  const netPositionUsd = subtract(
    add(
      totalLoanAmountUsd,
      add(totalReceivedRepoTokensUsd, interestToReceiveUsd)
    ),
    add(totalBorrowAmountUsd, interestToPayUsd),
    18
  )

  const summaryData = [
    {
      label: t`Collateral Overview`,
      values: collateralData,
      noValuesText: t`You have no collateral deposited.`,
    },
    {
      label: t`Open Tenders`,
      values: openTenderData,
      noValuesText: t`You have no open tenders.`,
    },
  ]

  return (
    <Container maxW="1043px">
      <Heading as="h1" my={5} variant="heading/h3" pl={5}>
        <Trans>Open Positions</Trans>
      </Heading>
      <SummaryHeader
        totalLoanAmountUsd={add(totalLoanAmountUsd, totalReceivedRepoTokensUsd)}
        totalOpenListingsUsd={totalOpenListingsUsd}
        interestToReceiveUsd={interestToReceiveUsd}
        totalBorrowAmountUsd={totalBorrowAmountUsd}
        interestToPayUsd={interestToPayUsd}
        netPositionUsd={netPositionUsd}
        openLoanCount={openLoanCount}
        openBorrowCount={openBorrowCount}
        openPositionCount={openPositionCount}
        openPositionNetworks={openPositionNetworks}
      />
      <VStack w="full" spacing="48px" mt={12}>
        {summaryData.map(({ label, values, noValuesText }) => (
          <VStack key={label} w="full" rowGap={4}>
            <SummaryBreakdown
              key={label}
              title={label}
              values={values}
              noValuesText={noValuesText}
            />
            {label === t`Collateral Overview` && openPositionCount > 0 && (
              <ManagePositionsButton
                onClick={() => {
                  navigate('/portfolio/positions')
                }}
              />
            )}
            {label === t`Open Tenders` && openTenderData && (
              <ManageOpenTendersButton
                onClick={() => {
                  navigate('/portfolio/offers')
                }}
              />
            )}
          </VStack>
        ))}
      </VStack>
    </Container>
  )
}

function ManagePositionsButton({ onClick }: { onClick: () => void }) {
  return (
    <HStack justifyContent="flex-end" w="full" mt="0 !important">
      <Button variant="link" color="blue.5" onClick={onClick}>
        <HStack as="span" alignItems="center" spacing={0}>
          <Text as="span" variant="body-sm/semibold" color="blue.5">
            <Trans>Manage Open Positions</Trans>
          </Text>
          <ChevronRightIcon color="blue.5" ml="0 !important" />
        </HStack>
      </Button>
    </HStack>
  )
}

function ManageOpenTendersButton({ onClick }: { onClick: () => void }) {
  return (
    <HStack justifyContent="flex-end" w="full" mt="8px">
      <Button variant="link" color="blue.5" onClick={onClick}>
        <HStack as="span" alignItems="center" spacing={0}>
          <Text as="span" variant="body-sm/semibold" color="blue.5">
            <Trans>Manage Open Tenders</Trans>
          </Text>
          <ChevronRightIcon color="blue.5" ml="0 !important" />
        </HStack>
      </Button>
    </HStack>
  )
}
