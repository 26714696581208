import {
  Box,
  Button,
  Checkbox,
  Heading,
  Select,
  Skeleton,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, t } from '@lingui/macro'
import { ChainId, TransactionState } from '@usedapp/core'
import dayjs from 'dayjs'
import { FixedNumber, constants } from 'ethers'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Chip from '../../../../components/elements/Chip'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { HStack } from '../../../../components/elements/Stack'
import {
  Table,
  TableBody,
  TableFoot,
  TableHead,
  Td,
  Tr,
} from '../../../../components/elements/Table'
import Tooltip from '../../../../components/elements/Tooltip'
import TransactionHash from '../../../../components/elements/TransactionHash'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { Address, AuctionStatus, TenderId } from '../../../../data/model'
import { fixedToBigNumber } from '../../../../helpers/conversions'
import { add } from '../../../../helpers/math'
import {
  formatFixedPercentage,
  formatFixedToken,
  formatFixedUsd,
} from '../../../../helpers/utils'
import { useColumnWidths } from '../../../../hooks/portfolio'
import { useChainConfig } from '../../../../providers/config'
import LoadInterestRateModal from '../../../Auction/elements/OpenTenders/LoadInterestRateModal'
import {
  OpenBorrowTender,
  OpenBorrowTenderGroup,
  OpenBorrowTenders,
  OpenLoanTender,
  OpenLoanTenderGroup,
  OpenLoanTenders,
  OpenTenderGroup,
} from '../../utils'
import { PortfolioTableHead as Th } from '../PortfolioTableHead'
import HistoricalDropdown from './HistoricalDropdown'
import TokenChip from '../../../../components/elements/TokenChip'

interface ColumnWidths {
  [key: string]: number
}

const COLUMN_WIDTHS: ColumnWidths = {
  auctionId: 200,
  closesIn: 85,
  asset: 80,
  submitted: 95,
  amount: 104,
  rate: 75,
  disp: 70,
  required: 69,
  deposited: 69,
  transaction: 91,
  emptyColumn: 132,
}

function DeleteTendersButton({
  onDeleteTenders,
  tenderCount = 1,
}: {
  onDeleteTenders: () => Promise<TransactionState>
  tenderCount?: number
}) {
  const handleDelete = async () => {
    // TODO (Aidan) hande notifications
    const res = await onDeleteTenders()
    console.log('Deleted tenders', res)
  }

  return (
    <Tooltip
      label={t`Cancelled auction: Delete your submitted tenders to unlock your funds.`}
      placement={'bottom-end'}
    >
      <Button variant="primary" onClick={handleDelete} size={'xs'}>
        <Text as="span" variant="body-xs/semibold">
          <Trans>Delete {tenderCount}</Trans>{' '}
          {tenderCount > 1 ? t`tenders` : t`tender`}
        </Text>
      </Button>
    </Tooltip>
  )
}

export function OpenLoanTenderHeader({
  hasAuctions,
  columnWidths,
}: {
  hasAuctions: boolean
  columnWidths?: ColumnWidths
}) {
  return (
    <Tr noBorder>
      {/* Auction ID */}
      <Th
        data-column="auctionId"
        width={columnWidths ? `${columnWidths.auctionId}px` : ''}
        pl={9}
        sub={
          <>
            <Trans>Auction ID</Trans>
          </>
        }
      />
      {/* Closes In */}
      <Th
        data-column="closesIn"
        width={columnWidths ? `${columnWidths.closesIn}px` : ''}
        sub={t`Closes In`}
      />
      {/* Asset */}
      <Th
        data-column="asset"
        width={columnWidths ? `${columnWidths.asset}px` : ''}
        sub={t`Asset`}
        rightBorder
      />
      {/* Submitted */}
      <Th
        data-column="submitted"
        width={columnWidths ? `${columnWidths.submitted}px` : ''}
        sub={
          <HStack alignItems="center" justifyContent="flex-end">
            <Text as="span" variant="body-xs/medium" color="blue.9">
              <Trans>Submitted</Trans>
            </Text>
          </HStack>
        }
        textAlign="right"
      />
      {/* Loan Amount */}
      <Th
        data-column="amount"
        width={columnWidths ? `${columnWidths.amount}px` : ''}
        sub={t`Loan Amount`}
        textAlign="right"
      />
      {/* Min. Rate */}
      <Th
        data-column="rate"
        width={columnWidths ? `${columnWidths.rate}px` : ''}
        sub={t`Min. Rate`}
        textAlign="right"
      />
      {/* Empty */}
      <Th data-column="empty_column" width="96px" />
      {/* Empty */}
      <Th
        data-column="empty_column"
        width={columnWidths ? `${columnWidths.required}px` : ''}
      />
      {/* Empty */}
      <Th
        data-column="empty_column"
        width={columnWidths ? `${columnWidths.deposited}px` : ''}
      />
      {/* Transaction */}
      <Th
        data-column="transaction"
        sub={t`Transaction`}
        width={columnWidths ? `${columnWidths.transaction}px` : ''}
        textAlign="right"
      />
      <Th data-column="empty_column" width={hasAuctions ? `46px` : '76px'} />
    </Tr>
  )
}

function OpenLoanTenderFooter({
  totalSymbol,
  totalAmount,
}: {
  totalSymbol: string
  totalAmount: FixedNumber
}) {
  return (
    <Tr bg="gray.1" noBorder>
      {/* Total in USD */}
      <Th py="14px">
        <HStack>
          <Text as="span" variant="body-xs/bold" color="blue.9">
            <Trans>Total</Trans>
          </Text>
          <Chip>{totalSymbol}</Chip>
        </HStack>
      </Th>
      <Th py="14px" colSpan={2} />
      <Th py="14px" textAlign="right" />
      {/* Loan Amount Total */}
      <Th py="14px">
        <HStack justifyContent="flex-end" w="full">
          <Text as="span" variant="body-xs/bold" color="blue.9">
            {formatFixedUsd(totalAmount)}
          </Text>
        </HStack>
      </Th>
      <Th py="14px" colSpan={6} />
    </Tr>
  )
}

// second table second row
function OpenLoanTenderRow({
  chainId,
  tender,
  isClearingOrClosed,
  isOtherAuctionSelected,
  auctionTitle,
  purchaseTokenSymbol,
  hasAllTendersWithMissingRates,
  selected,
  isLoadingMissingRates,
  onOpenLoadInterestRate,
  onChangeSelected,
  selectedLoans,
}: {
  chainId: ChainId
  tender: OpenLoanTender
  isClearingOrClosed: boolean
  isOtherAuctionSelected: boolean
  auctionTitle: string
  purchaseTokenSymbol: string
  hasAllTendersWithMissingRates: boolean
  selected?: boolean
  isLoadingMissingRates: boolean
  onOpenLoadInterestRate: () => void
  onChangeSelected?: (auctionTitle: string, selected: boolean) => void
  selectedLoans: { auctionTitle: string; selected: boolean }[]
}) {
  const chainConfig = useChainConfig(chainId)
  return (
    <Tr
      noBorder
      display={
        selectedLoans.find((loan) => loan.auctionTitle === auctionTitle)
          ?.selected
          ? 'table-row'
          : 'none'
      }
    >
      {/* Auction Checkbox */}
      <Td compact>
        <Checkbox
          onChange={(e) =>
            onChangeSelected && onChangeSelected(auctionTitle, e.target.checked)
          }
          checked={selected}
          disabled={isClearingOrClosed || isOtherAuctionSelected}
          hidden // Adding as part of termlabs-31
        />
      </Td>
      {/* Auction Title */}
      <Td compact />
      {/* Closes In */}
      <Td compact rightBorder />
      {/* Submitted time */}
      <Td compact textAlign="right">
        {tender.submittedDate
          ? dayjs.unix(tender.submittedDate).format('h:mm A')
          : '-'}
      </Td>
      {/* Loan Amount */}
      <Td compact textAlign="right">
        {formatFixedToken(tender.amount, purchaseTokenSymbol, true)}
      </Td>
      {/* Min. Rate */}
      <Td compact textAlign="right">
        <Skeleton isLoaded={!isLoadingMissingRates || !!tender.interestRate}>
          {tender.interestRate ? (
            formatFixedPercentage(
              tender.interestRate,
              undefined,
              undefined,
              FixedNumber.fromString('1.0', 'fixed128x18')
            )
          ) : !hasAllTendersWithMissingRates ? (
            <Button
              variant="link"
              onClick={onOpenLoadInterestRate}
              px={0}
              isDisabled={isLoadingMissingRates}
            >
              <Text as="span" variant="body-xs/semibold" color="blue.5">
                <Trans>Load rate</Trans>
              </Text>
            </Button>
          ) : (
            `-`
          )}
        </Skeleton>
      </Td>
      <Td compact />
      <Td compact />
      <Td compact />
      {/* Transaction */}
      <Td compact textAlign="left" pl={10}>
        <TransactionHash
          getBlockExplorerTransactionUrl={
            chainConfig && chainConfig.getExplorerTransactionLink
          }
          address={tender.transaction}
        />
      </Td>
      {/* Delete tenders button */}
      <Td compact />
    </Tr>
  )
}

const getGroupRowColor = (chainId: ChainId) =>
  (
    ({
      [ChainId.Mainnet]: 'gray.50',
      [ChainId.Base]: 'blue.0',
    }) as Partial<Record<ChainId, string>>
  )[chainId] ?? 'gray.50'

// second table first row
function OpenLoanTenderRowGroup({
  group,
  chainId,
  onDeleteLoans,
  selected,
  selectedLoans,
  isLoadingMissingRates,
  onOpenLoadInterestRate,
  onChangeSelected,
}: {
  group: OpenLoanTenderGroup
  chainId: ChainId
  onDeleteLoans?: (
    chainId: string,
    tenders: TenderId[],
    auction: Address
  ) => Promise<TransactionState>
  selected?: boolean
  selectedLoans: { auctionTitle: string; selected: boolean }[]
  isLoadingMissingRates: boolean
  onOpenLoadInterestRate: () => void
  onChangeSelected?: (auctionTitle: string, selected: boolean) => void
}) {
  const now = useCurrentTime()

  const chainConfig = useChainConfig(chainId)

  const [isSelected, setIsSelected] = useState(true)

  const isClearingOrClosed =
    !!group?.status &&
    (['clearing', 'closed'] as AuctionStatus[]).includes(group.status)
  const isOtherAuctionSelected = selectedLoans.some(
    (row) => row.auctionTitle !== group.auctionTitle && row.selected
  )

  const hasAllTendersWithMissingRates = group.tenders.every(
    (tender) => !tender.interestRate
  )

  const bgColor = getGroupRowColor(chainId)

  const showGroupedLoans = () => {
    if (group.tenders.length > 1) {
      setIsSelected(!isSelected)
    }
    return onChangeSelected && onChangeSelected(group.auctionTitle, isSelected)
  }

  useEffect(() => {
    if (group.tenders.length === 1) {
      showGroupedLoans()
    }
  }, [group.tenders.length])

  return (
    <>
      <Tr
        backgroundColor={bgColor}
        noBorder
        onClick={showGroupedLoans}
        cursor="pointer"
      >
        <Td fontWeight="600">
          <HStack>
            {/* Auction Checkbox */}
            <Checkbox
              mr="4px"
              onChange={(e) =>
                onChangeSelected &&
                onChangeSelected(group.auctionTitle, e.target.checked)
              }
              checked={selected}
              disabled={isClearingOrClosed || isOtherAuctionSelected}
              hidden // Adding as part of termlabs-31
            />
            {/* Auction Title */}
            <Text as="span" variant="body-xs/bold" color="blue.9" ml="8px">
              {group.auctionTitle}
            </Text>
            {/* Auction Link */}
            {chainConfig && (
              <NetworkIcon
                chainId={chainConfig.chainId}
                variant="faded"
                withTooltip
              />
            )}
          </HStack>
        </Td>
        {/* Closes In */}
        <Td>
          <Text as="span" variant="body-xs/bold" color="blue.9">
            {group.auctionRevealTimestamp
              ? dayjs.unix(group.auctionRevealTimestamp).from(now)
              : '-'}
          </Text>
        </Td>
        {/* Asset */}
        <Td rightBorder>
          <TokenChip token={group.purchaseTokenSymbol} size="sm" />
        </Td>
        {/* Submitted time*/}
        <Td fontWeight="600" textAlign="right" />
        {/* Loan Amount */}
        <Td fontWeight="600" textAlign="right">
          {formatFixedToken(group.amount, group.purchaseTokenSymbol, true)}
        </Td>
        {/* Min. Rate */}
        <Td fontWeight="600" textAlign="right">
          {hasAllTendersWithMissingRates && !group.closed && (
            <Button
              variant="link"
              onClick={onOpenLoadInterestRate}
              px={0}
              isDisabled={isLoadingMissingRates}
            >
              <Text as="span" variant="body-xs/semibold" color="blue.5">
                <Trans>Load rates</Trans>
              </Text>
            </Button>
          )}
        </Td>
        <Th width="96px" />
        <Th />
        <Th />
        {/* Transaction */}
        <Td compact fontWeight="600" />
        {/* Delete tenders button */}
        <Td large verticalAlign="top">
          <HStack py={1}>
            {group.tenders.length > 1 && (
              <Box
                color="blue.9"
                as="span"
                transform={isSelected ? 'rotate(0deg)' : 'rotate(180deg)'}
                transition="transform 0.2s"
              >
                <FontAwesomeIcon
                  icon={['far', 'chevron-down']}
                  role="button"
                  width="20px"
                />
              </Box>
            )}
            <HStack right={12} position="absolute">
              {group.cancelledForWithdrawal && (
                <DeleteTendersButton
                  onDeleteTenders={async () =>
                    onDeleteLoans
                      ? onDeleteLoans(
                          group.chainId.toString(),
                          group.tenders.map((t) => t.id),
                          group.auctionId
                        )
                      : 'None'
                  }
                />
              )}
            </HStack>
          </HStack>
        </Td>
      </Tr>
      {group.tenders.map((tender) => (
        <OpenLoanTenderRow
          key={tender.id}
          chainId={chainId}
          isClearingOrClosed={isClearingOrClosed}
          isOtherAuctionSelected={isOtherAuctionSelected}
          auctionTitle={group.auctionTitle}
          tender={tender}
          purchaseTokenSymbol={group.purchaseTokenSymbol}
          hasAllTendersWithMissingRates={hasAllTendersWithMissingRates}
          isLoadingMissingRates={isLoadingMissingRates}
          onChangeSelected={onChangeSelected}
          onOpenLoadInterestRate={onOpenLoadInterestRate}
          selectedLoans={selectedLoans}
        />
      ))}
    </>
  )
}

export function OpenBorrowTenderHeader({
  hasAuctions,
  asset,
  onChangeAsset,
  columnWidths,
}: {
  hasAuctions: boolean
  asset: '' | 'ratio' | 'usd'
  onChangeAsset?: (asset: '' | 'ratio' | 'usd') => void
  columnWidths?: ColumnWidths
}) {
  return (
    <Tr noBorder>
      {/* Auction ID */}
      <Th
        data-column={'auctionId'}
        width={columnWidths ? `${columnWidths.auctionId}px` : ''}
        pl={9}
        sub={
          <>
            <Trans>Auction ID</Trans>
          </>
        }
      />
      {/* Closes In */}
      <Th
        data-column={'closesIn'}
        width={columnWidths ? `${columnWidths.closesIn}px` : ''}
        sub={t`Closes In`}
      />
      {/* Asset */}
      <Th
        data-column={'asset'}
        width={columnWidths ? `${columnWidths.asset}px` : ''}
        sub={t`Asset`}
        rightBorder
      />
      {/* Submitted */}
      <Th
        data-column={'submitted'}
        width={columnWidths ? `${columnWidths.submitted}px` : ''}
        sub={
          <HStack alignItems="center" justifyContent="flex-end">
            <Text as="span" variant="body-xs/medium" color="blue.9">
              <Trans>Submitted</Trans>
            </Text>
          </HStack>
        }
        textAlign="right"
      />
      {/* Borrow Amount */}
      <Th
        data-column={'amount'}
        width={columnWidths ? `${columnWidths.amount}px` : ''}
        sub={t`Borrow Amount`}
        textAlign="right"
      />
      {/* Max. Rate */}
      <Th
        data-column={'rate'}
        width={columnWidths ? `${columnWidths.rate}px` : ''}
        sub={t`Max. Rate`}
        textAlign="right"
      />
      {hasAuctions && onChangeAsset ? (
        // Disp.
        <Th
          data-column={'disp'}
          width="96px"
          sub={
            <Select
              size="sm"
              h={5}
              mt={-0.5}
              w="max-content"
              borderColor="transparent"
              fontWeight="semibold"
              borderRadius="md"
              color="gray.6"
              bg="gray.2"
              fontSize="12px"
              value={asset}
              onChange={(e) =>
                onChangeAsset(e.target.value as '' | 'ratio' | 'usd')
              }
            >
              <option value="">
                <Trans>Asset</Trans>
              </option>
              <option value="ratio">
                <Trans>Ratio</Trans>
              </option>
              <option value="usd">
                <Trans>USD</Trans>
              </option>
            </Select>
          }
        />
      ) : (
        <Th data-column={'disp'} width="96px" />
      )}
      {/* Collateral Required */}
      <Th
        data-column={'required'}
        sub={t`Required`}
        textAlign="right"
        width={columnWidths ? `${columnWidths.required}px` : ''}
      >
        <Text
          as="span"
          color="blue.9"
          variant="body-xs/semibold"
          w="full"
          textAlign="right"
        >
          <Trans>Collateral</Trans>
        </Text>
      </Th>
      {/* Collateral Deposited */}
      <Th
        data-column={'deposited'}
        sub={t`Deposited`}
        textAlign="right"
        width={columnWidths ? `${columnWidths.deposited}px` : ''}
      />
      {/* Transaction */}
      <Th
        data-column={'transaction'}
        sub={t`Transaction`}
        width={columnWidths ? `${columnWidths.transaction}px` : ''}
        textAlign="right"
        boxSizing="border-box"
      />
      {/* Delete button */}
      <Th data-coloumn={'emptyColumn'} width={hasAuctions ? `46px` : '76px'} />
    </Tr>
  )
}

function OpenBorrowTenderFooter({
  totalSymbol,
  totalAmount,
  totalRequired,
  totalDeposited,
}: {
  totalSymbol: string
  totalAmount: FixedNumber
  totalRequired: FixedNumber
  totalDeposited: FixedNumber
}) {
  return (
    <Tr bg="gray.1" noBorder>
      {/* Total in USD */}
      <Th py="14px">
        <HStack>
          <Text as="span" variant="body-xs/bold" color="blue.9">
            <Trans>Total</Trans>
          </Text>
          <Chip>{totalSymbol}</Chip>
        </HStack>
      </Th>
      <Th py="14px" colSpan={3} textAlign="right" />
      {/* Total Borrow Amount */}
      <Th py="14px">
        <HStack justifyContent="flex-end" w="full">
          <Text as="span" variant="body-xs/bold" color="blue.9">
            {formatFixedUsd(totalAmount)}
          </Text>
        </HStack>
      </Th>
      <Th py="14px" textAlign="right" />
      <Th py="14px" />
      {/* Total Collateral Required */}
      <Th py="14px">
        <HStack justifyContent="flex-end" w="full">
          <Text as="span" variant="body-xs/bold" color="blue.9">
            {formatFixedUsd(totalRequired)}
          </Text>
        </HStack>
      </Th>
      {/* Total Collateral Deposited */}
      <Th py="14px">
        <HStack justifyContent="flex-end" w="full">
          <Text as="span" variant="body-xs/bold" color="blue.9">
            {formatFixedUsd(totalDeposited)}
          </Text>
        </HStack>
      </Th>
      <Th textAlign="right" pl={10} boxSizing="border-box" />
      <Th />
    </Tr>
  )
}

// first table second row
function OpenBorrowTenderRow({
  tender,
  chainId,
  asset,
  auctionTitle,
  purchaseTokenSymbol,
  collateralTokenSymbol,
  selected,
  isClearingOrClosed,
  isOtherAuctionSelected,
  hasAllTendersWithMissingRates,
  isLoadingMissingRates,
  onOpenLoadInterestRate,
  onChangeSelected,
  selectedBorrows,
}: {
  tender: OpenBorrowTender
  chainId: ChainId
  asset: '' | 'ratio' | 'usd'
  auctionTitle: string
  purchaseTokenSymbol: string
  collateralTokenSymbol: string
  selected?: boolean
  isClearingOrClosed?: boolean
  isOtherAuctionSelected?: boolean
  hasAllTendersWithMissingRates: boolean
  isLoadingMissingRates: boolean
  onOpenLoadInterestRate: () => void
  onChangeSelected?: (auctionTitle: string, selected: boolean) => void
  selectedBorrows: { auctionTitle: string; selected: boolean }[]
}) {
  const chainConfig = useChainConfig(chainId)
  return (
    <Tr
      noBorder
      display={
        selectedBorrows.find((borrow) => borrow.auctionTitle === auctionTitle)
          ?.selected
          ? 'table-row'
          : 'none'
      }
    >
      {/* Checkbox + Auction ID */}
      <Td compact>
        <Checkbox
          onChange={(e) =>
            onChangeSelected && onChangeSelected(auctionTitle, e.target.checked)
          }
          checked={selected}
          disabled={isClearingOrClosed || isOtherAuctionSelected}
          mr="8px"
          hidden // Adding as part of termlabs-31
        />
      </Td>
      {/* Closes In */}
      <Td compact />
      {/* Asset */}
      <Td compact rightBorder />
      {/* Submitted time  */}
      <Td compact textAlign="right">
        {tender.submittedDate
          ? dayjs.unix(tender.submittedDate).format('h:mm A')
          : '-'}
      </Td>
      {/* Borrow Amount */}
      <Td compact textAlign="right">
        {formatFixedToken(tender.amount, purchaseTokenSymbol, true)}
      </Td>
      {/* Max. Rate */}
      <Td compact textAlign="right">
        <Skeleton isLoaded={!isLoadingMissingRates || !!tender.interestRate}>
          {tender.interestRate ? (
            formatFixedPercentage(
              tender.interestRate,
              undefined,
              undefined,
              FixedNumber.fromString('1.0', 'fixed128x18')
            )
          ) : !hasAllTendersWithMissingRates ? (
            <Button
              variant="link"
              onClick={onOpenLoadInterestRate}
              px={0}
              isDisabled={isLoadingMissingRates}
            >
              <Text as="span" variant="body-xs/semibold" color="blue.5">
                <Trans>Load rate</Trans>
              </Text>
            </Button>
          ) : (
            `-`
          )}
        </Skeleton>
      </Td>
      {/* Asset Token Chip */}
      <Td compact />
      {/* Collateral Required */}
      <Td compact textAlign="right">
        {asset === 'usd'
          ? formatFixedUsd(tender.requiredMarginUsd)
          : asset === 'ratio'
            ? !fixedToBigNumber(tender.requiredMarginRatio).eq(
                constants.MaxUint256
              )
              ? formatFixedPercentage(
                  tender.requiredMarginRatio,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              : '∞'
            : formatFixedToken(
                tender.requiredMargin,
                collateralTokenSymbol,
                true
              )}
      </Td>
      {/* Collateral Deposited */}
      <Td compact textAlign="right">
        {asset === 'usd'
          ? formatFixedUsd(tender.depositedMarginUsd)
          : asset === 'ratio'
            ? !fixedToBigNumber(tender.depositedMarginRatio).eq(
                constants.MaxUint256
              )
              ? formatFixedPercentage(
                  tender.depositedMarginRatio,
                  undefined,
                  undefined,
                  undefined,
                  true
                )
              : '∞'
            : formatFixedToken(
                tender.depositedMargin,
                collateralTokenSymbol,
                true
              )}
      </Td>
      {/* Transaction */}
      <Td compact textAlign="left" pl={10} boxSizing="border-box">
        <TransactionHash
          getBlockExplorerTransactionUrl={
            chainConfig && chainConfig.getExplorerTransactionLink
          }
          address={tender.transaction}
        />
      </Td>
      {/* Delete tenders button */}
      <Td compact />
    </Tr>
  )
}

// first table first row
function OpenBorrowTenderRowGroup({
  group,
  chainId,
  onDeleteBorrows,
  asset,
  selected,
  selectedBorrows,
  isLoadingMissingRates,
  onOpenLoadInterestRate,
  onChangeSelected,
}: {
  group: OpenBorrowTenderGroup
  chainId: ChainId
  onDeleteBorrows?: (
    chainId: string,
    tenders: TenderId[],
    auction: Address
  ) => Promise<TransactionState>
  asset: '' | 'ratio' | 'usd'
  selected?: boolean
  selectedBorrows: { auctionTitle: string; selected: boolean }[]
  isLoadingMissingRates: boolean
  onOpenLoadInterestRate: () => void
  onChangeSelected?: (auctionTitle: string, selected: boolean) => void
}) {
  const now = useCurrentTime()

  const chainConfig = useChainConfig(chainId)

  const [isSelected, setIsSelected] = useState(true)

  const isClearingOrClosed =
    !!group?.status &&
    (['clearing', 'closed'] as AuctionStatus[]).includes(group.status)
  const isOtherAuctionSelected = selectedBorrows.some(
    (row) => row.auctionTitle !== group.auctionTitle && row.selected
  )

  const hasAllTendersWithMissingRates = group.tenders.every(
    (tender) => !tender.interestRate
  )

  const bgColor = getGroupRowColor(chainId)

  const showGroupedBorrows = () => {
    if (group.tenders.length > 1) {
      setIsSelected(!isSelected)
    }
    return onChangeSelected && onChangeSelected(group.auctionTitle, isSelected)
  }

  useEffect(() => {
    if (group.tenders.length === 1) {
      showGroupedBorrows()
    }
  }, [group.tenders.length])

  return (
    <>
      <Tr
        backgroundColor={bgColor}
        noBorder
        onClick={showGroupedBorrows}
        cursor="pointer"
      >
        <Td fontWeight="600">
          <HStack>
            {/* Auction Checkbox */}
            <Checkbox
              mr="8px"
              onChange={(e) =>
                onChangeSelected &&
                onChangeSelected(group.auctionTitle, e.target.checked)
              }
              checked={selected}
              disabled={isClearingOrClosed || isOtherAuctionSelected}
              hidden // Adding as part of termlabs-31
            />
            {/* Auction Title */}
            <Text as="span" variant="body-xs/bold" ml="8px">
              {group.auctionTitle}
            </Text>
            {/* Auction Link */}
            {chainConfig && (
              <NetworkIcon
                chainId={chainConfig.chainId}
                variant="faded"
                withTooltip
              />
            )}
          </HStack>
        </Td>
        {/* Closes In */}
        <Td compact fontWeight="600">
          <Text as="span" variant="body-xs/bold" color="blue.9">
            {group.auctionRevealTimestamp
              ? dayjs.unix(group.auctionRevealTimestamp).from(now)
              : '-'}
          </Text>
        </Td>
        {/* Asset */}
        <Td compact rightBorder>
          <TokenChip token={group.purchaseTokenSymbol} size="sm" />
        </Td>
        {/* Submitted time */}
        <Td compact fontWeight="600" textAlign="right" />
        {/* Borrow Amount */}
        <Td compact fontWeight="600" textAlign="right">
          {formatFixedToken(group.amount, group.purchaseTokenSymbol, true)}
        </Td>
        {/* Max. Rate */}
        <Td compact fontWeight="600" textAlign="right">
          {hasAllTendersWithMissingRates && !group.closed && (
            <Button
              variant="link"
              onClick={onOpenLoadInterestRate}
              px={0}
              isDisabled={isLoadingMissingRates}
            >
              <Text as="span" variant="body-xs/semibold" color="blue.5">
                <Trans>Load rates</Trans>
              </Text>
            </Button>
          )}
        </Td>
        {/* Token Symbol Chip */}
        <Td compact>
          {asset === 'usd' || asset === 'ratio' ? (
            <></>
          ) : (
            <TokenChip token={group.collateralTokenSymbol} size="sm" />
          )}
        </Td>
        {/*  Collateral Required */}
        <Td compact fontWeight="600" textAlign="right">
          {asset === 'usd'
            ? formatFixedUsd(group.requiredMarginUsd)
            : asset === 'ratio'
              ? '-'
              : formatFixedToken(
                  group.requiredMargin,
                  group.collateralTokenSymbol,
                  true
                )}
        </Td>
        {/* Collateral Deposited */}
        <Td compact fontWeight="600" textAlign="right">
          {asset === 'usd'
            ? formatFixedUsd(group.depositedMarginUsd)
            : asset === 'ratio'
              ? '-'
              : formatFixedToken(
                  group.depositedMargin,
                  group.collateralTokenSymbol,
                  true
                )}
        </Td>
        {/* Transaction */}
        <Td compact fontWeight="600" />
        {/* Delete tenders button */}
        <Td large verticalAlign="top">
          <HStack py={1}>
            {group.tenders.length > 1 && (
              <Box
                color="blue.9"
                as="span"
                transform={isSelected ? 'rotate(0deg)' : 'rotate(180deg)'}
                transition="transform 0.2s"
              >
                <FontAwesomeIcon
                  icon={['far', 'chevron-down']}
                  role="button"
                  width="20px"
                />
              </Box>
            )}
            <HStack right={12} position="absolute">
              {group.cancelledForWithdrawal && (
                <DeleteTendersButton
                  tenderCount={group.tenders.length}
                  onDeleteTenders={async () =>
                    onDeleteBorrows
                      ? onDeleteBorrows(
                          group.chainId.toString(),
                          group.tenders.map((t) => t.id),
                          group.auctionId
                        )
                      : 'None'
                  }
                />
              )}
            </HStack>
          </HStack>
        </Td>
      </Tr>
      {group.tenders.map((tender) => (
        <OpenBorrowTenderRow
          asset={asset}
          key={tender.id}
          chainId={chainId}
          isClearingOrClosed={isClearingOrClosed}
          isOtherAuctionSelected={isOtherAuctionSelected}
          auctionTitle={group.auctionTitle}
          tender={tender}
          purchaseTokenSymbol={group.purchaseTokenSymbol}
          collateralTokenSymbol={group.collateralTokenSymbol}
          hasAllTendersWithMissingRates={hasAllTendersWithMissingRates}
          onChangeSelected={onChangeSelected}
          isLoadingMissingRates={isLoadingMissingRates}
          onOpenLoadInterestRate={onOpenLoadInterestRate}
          selectedBorrows={selectedBorrows}
        />
      ))}
    </>
  )
}

function EmptyTableFooter({
  emptyText,
  colSpan,
}: {
  emptyText: string
  colSpan?: number
}) {
  const navigate = useNavigate()
  return (
    <Tr noBorder>
      <Td colSpan={colSpan ?? 11} py="14px" bg="gray.1">
        <HStack w="full" justifyContent="space-between">
          <Text as="span" variant="body-xs/medium">
            {emptyText}
          </Text>
          <Button
            variant="primary"
            size="xs"
            onClick={() => navigate('/')}
            textDecoration="none"
            position="absolute"
            right={0}
            transform="translateX(-5px)"
          >
            <Trans>View auctions</Trans>
          </Button>
        </HStack>
      </Td>
    </Tr>
  )
}

export default function OpenOffersSection({
  openBorrowTenders,
  openLoanTenders,

  onDeleteBorrows,
  onDeleteLoans,

  onLoadMissingTenderRates,
  isLoadingMissingRates,
}: {
  openBorrowTenders: OpenBorrowTenders
  openLoanTenders: OpenLoanTenders

  onDeleteBorrows?: (
    chainId: string,
    tenders: TenderId[],
    auction: Address
  ) => Promise<TransactionState>
  onDeleteLoans?: (
    chainId: string,
    tenders: TenderId[],
    auction: Address
  ) => Promise<TransactionState>

  onLoadMissingTenderRates: (
    chainId: string,
    bids: string[] | undefined,
    offers: string[] | undefined,
    isRollover: boolean
  ) => Promise<void>
  isLoadingMissingRates: boolean
}) {
  const [isHistoricalBorrowsDropdownOpen, setIsHistoricalBorrowsDropdownOpen] =
    useState(false)
  const [showHistoricalBids, setShowHistoricalBids] = useState(false)

  const [isHistoricalOffersDropdownOpen, setIsHistoricalOffersDropdownOpen] =
    useState(false)
  const [showHistoricalOffers, setShowHistoricalOffers] = useState(false)

  const [asset, setAsset] = useState<'' | 'ratio' | 'usd'>('')

  const handleHistoricalBorrowsDropdownOpen = () =>
    setIsHistoricalBorrowsDropdownOpen(true)
  const handleHistoricalBorrowsDropdownClose = () =>
    setIsHistoricalBorrowsDropdownOpen(false)

  const handleHistoricalOffersDropdownOpen = () =>
    setIsHistoricalOffersDropdownOpen(true)
  const handleHistoricalOffersDropdownClose = () =>
    setIsHistoricalOffersDropdownOpen(false)
  const {
    isOpen: isLoadInterestRateOpen,
    onClose: onLoadInterestRateClose,
    onOpen: onOpenLoadInterestRate,
  } = useDisclosure()

  const filteredBorrows = useMemo(
    () =>
      openBorrowTenders.groups
        .filter((group) => (showHistoricalBids ? true : !group.closed))
        .sort((a, b) => {
          if (a.status === 'closed' && b.status === 'closed') {
            return b.auctionEndTimestamp - a.auctionEndTimestamp
          }
          return a.auctionEndTimestamp - b.auctionEndTimestamp
        })
        .sort((a, b) => {
          if (a.status === b.status) return 0
          if (a.status === 'live') return -1
          if (b.status === 'live') return 1
          if (a.status === 'clearing') return -1
          if (b.status === 'clearing') return 1
          return 0
        }),
    [openBorrowTenders, showHistoricalBids]
  )

  const filteredBorrowsTotalUSD = useMemo(
    () =>
      openBorrowTenders.groups
        .filter((group) => (showHistoricalBids ? true : !group.closed))
        .reduce(
          (sum, group) =>
            (sum = add(
              sum,
              group.amountUsd,
              openBorrowTenders.amount.format.decimals
            )),
          FixedNumber.fromString('0', openBorrowTenders.amount.format)
        ),
    [openBorrowTenders, showHistoricalBids]
  )

  const filteredBorrowsTotalReqMarginUSD = useMemo(
    () =>
      openBorrowTenders.groups
        .filter((group) => (showHistoricalBids ? true : !group.closed))
        .reduce(
          (sum, group) =>
            (sum = add(
              sum,
              group.requiredMarginUsd,
              openBorrowTenders.requiredMarginUsd.format.decimals
            )),
          FixedNumber.fromString(
            '0',
            openBorrowTenders.requiredMarginUsd.format
          )
        ),
    [openBorrowTenders, showHistoricalBids]
  )

  const filteredBorrowsTotalDepMarginUSD = useMemo(
    () =>
      openBorrowTenders.groups
        .filter((group) => (showHistoricalBids ? true : !group.closed))
        .reduce(
          (sum, group) =>
            (sum = add(
              sum,
              group.depositedMarginUsd,
              openBorrowTenders.depositedMarginUsd.format.decimals
            )),
          FixedNumber.fromString(
            '0',
            openBorrowTenders.depositedMarginUsd.format
          )
        ),
    [openBorrowTenders, showHistoricalBids]
  )

  const filteredOffers = useMemo(
    () =>
      openLoanTenders.groups
        .filter((group) => (showHistoricalOffers ? true : !group.closed))
        .sort((a, b) => {
          if (a.status === 'closed' && b.status === 'closed') {
            return b.auctionEndTimestamp - a.auctionEndTimestamp
          }
          return a.auctionEndTimestamp - b.auctionEndTimestamp
        })
        .sort((a, b) => {
          if (a.status === b.status) return 0
          if (a.status === 'live') return -1
          if (b.status === 'live') return 1
          if (a.status === 'clearing') return -1
          if (b.status === 'clearing') return 1
          return 0
        }),
    [openLoanTenders, showHistoricalOffers]
  )

  const filteredOffersTotalUSD = useMemo(
    () =>
      openLoanTenders.groups
        .filter((group) => (showHistoricalOffers ? true : !group.closed))
        .reduce(
          (sum, group) =>
            (sum = add(
              sum,
              group.amountUsd,
              openLoanTenders.amountUsd.format.decimals
            )),
          FixedNumber.fromString('0', openLoanTenders.amountUsd.format)
        ),
    [openLoanTenders, showHistoricalOffers]
  )

  // const isTendersCheckboxDisabled = useMemo(
  //   () => filteredBorrows.every((group) => group.status === 'clearing'),
  //   [filteredBorrows]
  // )

  // const isOffersCheckboxDisabled = useMemo(
  //   () => filteredOffers.every((group) => group.status === 'clearing'),
  //   [filteredOffers]
  // )

  // Replace auctionTitle with proper Auction identifier in production
  const [selectedBorrows, setSelectedBorrows] = useState<
    { auctionTitle: string; selected: boolean }[]
  >(
    openBorrowTenders.groups.map((group) => ({
      auctionTitle: group.auctionTitle,
      selected: false,
    }))
  )

  const [selectedLoans, setSelectedLoans] = useState<
    { auctionTitle: string; selected: boolean }[]
  >(
    openBorrowTenders.groups.map((group) => ({
      auctionTitle: group.auctionTitle,
      selected: false,
    }))
  )

  const onChangeBorrowSelected = useCallback(
    (auctionTitle: string, selected: boolean) => {
      setSelectedBorrows((prev) =>
        prev.map((row) => {
          if (row.auctionTitle === auctionTitle) {
            return { ...row, selected }
          }
          return row
        })
      )
    },
    []
  )

  const onChangeLoanSelected = useCallback(
    (auctionTitle: string, selected: boolean) => {
      setSelectedLoans((prev) =>
        prev.map((row) => {
          if (row.auctionTitle === auctionTitle) {
            return { ...row, selected }
          }
          return row
        })
      )
    },
    []
  )

  const [chainId, setChainId] = useState<string>('')

  const groupTendersByChainId = (tenderGroups: OpenTenderGroup[]) => {
    return tenderGroups.reduce<{ [chainId: string]: string[] }>(
      (acc, group) => {
        group.tenders
          .filter((tender) => tender.interestRate === undefined)
          .forEach((tender) => {
            if (!acc[group.chainId]) {
              acc[group.chainId] = []
            }
            acc[group.chainId].push(tender.id)
          })
        return acc
      },
      {}
    )
  }

  // Refs to tables to calculate column widths
  const bidsTableRef = useRef(null)
  const offersTableRef = useRef(null)

  const [bidIdsGroupedByChainId, offerIdsGroupedByChainId] = useMemo(() => {
    const bidIds = groupTendersByChainId(openBorrowTenders.groups)
    const offerIds = groupTendersByChainId(openLoanTenders.groups)
    return [bidIds, offerIds]
  }, [openBorrowTenders, openLoanTenders])

  const tableRefs = useMemo(
    () => [bidsTableRef, offersTableRef],
    [bidsTableRef, offersTableRef]
  )

  const excludedColumnsFromEmptySpaceCalculation = useMemo(
    () => new Set([asset]),
    [asset]
  )

  const columnWidths = useColumnWidths(
    tableRefs,
    COLUMN_WIDTHS,
    excludedColumnsFromEmptySpaceCalculation,
    true
  )

  return (
    <Box my={5} mr="20px !important">
      <HStack mb="8px">
        <Heading variant="heading/h3" color="gray.900">
          <Trans>Bids</Trans>
        </Heading>
        <Chip>
          {filteredBorrows?.reduce(
            (sum, borrowGroup) => sum + borrowGroup.tenders.length,
            0
          ) ?? 0}
        </Chip>
        <Spacer />
        <HStack>
          <HStack opacity={0.5}>
            {/* Commenting out as part of termlabs-31
            <Button size="xs" variant="secondary">
              Edit
            </Button>
            <Button size="xs" variant="link" px={0.5}>
              <Text as="span" color="blue.5" variant="body-xs/semibold">
                Delete
              </Text>
            </Button> */}
          </HStack>
          <HistoricalDropdown
            isOpen={isHistoricalBorrowsDropdownOpen}
            isSelected={showHistoricalBids}
            labelText={t`Show historical bids`}
            onChange={setShowHistoricalBids}
            onOpen={handleHistoricalBorrowsDropdownOpen}
            onClose={handleHistoricalBorrowsDropdownClose}
          />
        </HStack>
      </HStack>
      <Table tableRef={bidsTableRef} isFixedLayout noPadding>
        <TableHead>
          <OpenBorrowTenderHeader
            hasAuctions={filteredBorrows.length > 0}
            asset={asset}
            onChangeAsset={
              openBorrowTenders?.groups?.length > 0 ? setAsset : undefined
            }
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableBody>
          {filteredBorrows.map((group) => (
            <OpenBorrowTenderRowGroup
              key={group.auctionLink}
              chainId={group.chainId}
              asset={asset}
              group={group}
              selectedBorrows={selectedBorrows}
              onDeleteBorrows={onDeleteBorrows}
              onOpenLoadInterestRate={() => {
                setChainId(group.chainId.toString())
                return onOpenLoadInterestRate()
              }}
              isLoadingMissingRates={isLoadingMissingRates}
              onChangeSelected={onChangeBorrowSelected}
            />
          ))}
        </TableBody>
        <TableFoot noTopBorder>
          {filteredBorrows.length === 0 ? (
            <EmptyTableFooter
              emptyText={t`You have no open bids.`}
              colSpan={11}
            />
          ) : (
            <OpenBorrowTenderFooter
              totalSymbol="USD"
              totalAmount={filteredBorrowsTotalUSD}
              totalDeposited={filteredBorrowsTotalDepMarginUSD}
              totalRequired={filteredBorrowsTotalReqMarginUSD}
            />
          )}
        </TableFoot>
      </Table>
      <HStack mb="8px" mt="40px">
        <Heading variant="heading/h3" color="gray.900">
          <Trans>Offers</Trans>
        </Heading>
        <Chip>
          {filteredOffers?.reduce(
            (sum, offerGroup) => sum + offerGroup.tenders.length,
            0
          ) ?? 0}
        </Chip>
        <Spacer />
        <HStack>
          {/*
          Commenting out as part of termlabs-31 
          <HStack opacity={0.5}>
            <Button size="xs" variant="secondary">
              Edit
            </Button>
            <Button size="xs" variant="link" px={0.5}>
              <Text as="span" color="blue.5" variant="body-xs/semibold">
                Delete
              </Text>
            </Button>
          </HStack> */}
          <HistoricalDropdown
            isOpen={isHistoricalOffersDropdownOpen}
            isSelected={showHistoricalOffers}
            labelText={t`Show historical offers`}
            onChange={setShowHistoricalOffers}
            onOpen={handleHistoricalOffersDropdownOpen}
            onClose={handleHistoricalOffersDropdownClose}
          />
        </HStack>
      </HStack>
      <Table tableRef={offersTableRef} isFixedLayout noPadding>
        <TableHead>
          <OpenLoanTenderHeader
            hasAuctions={filteredOffers.length > 0}
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableBody>
          {filteredOffers.map((group) => (
            <OpenLoanTenderRowGroup
              key={group.auctionLink}
              chainId={group.chainId}
              group={group}
              selectedLoans={selectedLoans}
              onDeleteLoans={onDeleteLoans}
              onChangeSelected={onChangeLoanSelected}
              onOpenLoadInterestRate={() => {
                setChainId(group.chainId.toString())
                return onOpenLoadInterestRate()
              }}
              isLoadingMissingRates={isLoadingMissingRates}
            />
          ))}
        </TableBody>
        <TableFoot noTopBorder>
          {filteredOffers.length === 0 ? (
            <EmptyTableFooter
              emptyText={t`You have no open offers.`}
              colSpan={11}
            />
          ) : (
            <OpenLoanTenderFooter
              totalSymbol="USD"
              totalAmount={filteredOffersTotalUSD}
            />
          )}
        </TableFoot>
      </Table>
      <LoadInterestRateModal
        isOpen={isLoadInterestRateOpen}
        onClose={onLoadInterestRateClose}
        onLoadRates={async () => {
          onLoadMissingTenderRates(
            chainId,
            bidIdsGroupedByChainId?.[chainId],
            offerIdsGroupedByChainId?.[chainId],
            false
          )
          onLoadInterestRateClose()
        }}
      />
    </Box>
  )
}
