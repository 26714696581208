import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { useMemo, useState } from 'react'
import re7labs from '../../../../assets/icons/re7labs.svg'
import sparkle from '../../../../assets/icons/sparkle.svg'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { HStack } from '../../../../components/elements/Stack'
import TokenIcon from '../../../../components/elements/TokenIcon'
import TokenIconMultiple from '../../../../components/elements/TokenIconMultiple'
import Tooltip from '../../../../components/elements/Tooltip'
import { VaultData } from '../../../../data/model'
import {
  fixedToFormattedPercentage,
  formatFixed,
} from '../../../../helpers/conversions'
import { multiply } from '../../../../helpers/math'
import { useConfig } from '../../../../providers/config'
import RepoCardButton from '../../../Lend/elements/RepoCardButton'
import RepoCardHeading from '../../../Lend/elements/RepoCardHeading'

export default function VaultCard({
  vault,
  activeVaultId,
  setActiveVaultId,
}: {
  vault: VaultData
  activeVaultId?: string | undefined
  setActiveVaultId: (itemId: string | undefined) => void
}) {
  const config = useConfig()
  const chainConfig = config.chains[vault.chainId]
  const isCardDisabled = false
  const [focus, setFocus] = useState(false)

  const setActiveState = (itemId: string) => {
    console.log(itemId)
    setActiveVaultId(activeVaultId === itemId ? undefined : itemId)
  }

  const fixedYieldPercentage = useMemo(() => {
    return vault.currentYield
      ? multiply(
          vault.currentYield,
          FixedNumber.fromString('100', vault.currentYield.format)
        )
      : FixedNumber.fromString('0', 18)
  }, [vault.currentYield])

  const { formattedPercentage: formattedYieldPercentage } =
    fixedToFormattedPercentage(fixedYieldPercentage, 2, true)

  return (
    <>
      <Flex
        key={vault.id}
        flexDir="column"
        minW={{
          base: '922px',
        }}
        position="relative"
        borderRadius="6px"
        overflow="visible"
        boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
        cursor={isCardDisabled ? 'not-allowed' : 'pointer'}
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        onClick={() => {
          if (!isCardDisabled) {
            setActiveState(vault.id)
          }
        }}
      >
        {/* First Row */}
        <Flex
          justifyContent="space-between"
          flexDir={{
            base: 'row',
          }}
          p={3}
          background={
            vault.id === activeVaultId || focus ? 'white' : chainConfig.gradient
          }
          opacity={vault.id !== activeVaultId && activeVaultId ? 0.5 : 1}
          transition="all .25s ease-in-out"
          _hover={{
            transform: vault.id !== activeVaultId && 'scale(1.01)',
          }}
        >
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
            flexDirection={{
              base: 'row',
            }}
            py={2.5}
            mr="auto"
          >
            {/* Current Yield */}
            <Box
              h="100%"
              w="130px"
              borderRight={{
                base: '1px solid',
              }}
              borderRightColor={{
                base: 'gray.3',
              }}
              pr={2}
            >
              <RepoCardHeading title="Current Yield" mb={2} />
              <HStack w="100%">
                <Text variant="body-2xl/bold" color="black">
                  {formattedYieldPercentage}%
                </Text>
                <Tooltip noDelay placement="bottom" label="Current Yield">
                  <Image boxSize="24px" src={sparkle} alt={'Fixed APY'} />
                </Tooltip>
              </HStack>
            </Box>
            <Grid
              gridTemplateColumns={{
                base: '100px 120px 150px',
              }}
              gap={{
                base: '38px',
                '1xl': '89px',
                '3xl': 36,
              }}
            >
              {/* Collateral */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Collateral" mb={2} />
                <Tooltip
                  noDelay
                  placement="bottom"
                  label={vault.collateral.join(', ')}
                >
                  <Box>
                    <TokenIconMultiple symbols={vault.collateral} />
                  </Box>
                </Tooltip>
              </Flex>
              {/* Curator */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Curator" mb={2} />
                <HStack>
                  <Image boxSize="24px" src={re7labs} />
                  <Text
                    variant="body-l/normal"
                    color="blue.9"
                    whiteSpace="nowrap"
                  >
                    {vault.curator}
                  </Text>
                </HStack>
              </Flex>
              {/* Vault */}
              <Flex
                flexDirection="column"
                align="start"
                verticalAlign="top"
                px={0}
              >
                <RepoCardHeading title="Vault" mb={2} />
                <HStack>
                  <TokenIcon token="USDC" />
                  <Text
                    variant="body-l/normal"
                    color="blue.9"
                    whiteSpace="nowrap"
                  >
                    {vault.vaultName}
                  </Text>
                </HStack>
              </Flex>
            </Grid>
          </Flex>
          <Flex
            flexDirection="column"
            align="end"
            verticalAlign="top"
            mr={10}
            py={2.5}
          >
            <RepoCardHeading title="Total Asset Value" mb={2} />
            <Text variant="body-l/normal" color="blue.9" whiteSpace="nowrap">
              {
                formatFixed(vault.totalAssetValue, {
                  displayDecimals: 2,
                })[0]
              }
            </Text>
          </Flex>
          {/* Button */}
          <Flex
            flexDirection="column"
            textAlign="right"
            justifyContent="center"
            minW={{
              base: 'max-content',
            }}
            px={{
              base: 0,
            }}
            gap={1}
          >
            <HStack spacing={0.5} justifyContent="right" h="16px">
              <Text
                variant="body-xs/normal"
                color="gray.5.5"
                textAlign={{
                  base: 'right',
                }}
                whiteSpace="nowrap"
                ml="auto"
              >
                {chainConfig?.chainName}
              </Text>
              <NetworkIcon
                key={vault.chainId}
                chainId={vault.chainId}
                boxSize={'16px'}
                variant="faded"
                withTooltip
              />
            </HStack>
            <RepoCardButton
              isActive={vault.id === activeVaultId}
              isDisabled={isCardDisabled}
              isHovered={focus}
              handleClick={() => {
                if (!isCardDisabled) {
                  setActiveState(vault.id)
                }
              }}
            >
              View
            </RepoCardButton>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
