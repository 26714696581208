import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  VStack,
} from '@chakra-ui/react'
import { ModalHeading, ModalBody } from './modal'
import closeIcon from '../../../../assets/icons/close.svg'
import { useTermToast } from '../../../../hooks/toasts'
import { SocialActions, Socials } from '../../../../models/rewards'

export default function SignInModal({
  account,
  connectedSocials,
  isOnConnectScreen,
  isSwitchProfileViewOnly,
  isOpen,
  onClose,
  onLinkWallet,
  onLinkTwitter,
  onLinkDiscord,
}: {
  account?: string
  connectedSocials: Record<Socials, boolean>
  isOnConnectScreen?: boolean
  isSwitchProfileViewOnly?: boolean
  isOpen: boolean
  onClose: (token?: string) => void
  onLinkWallet?: () => Promise<void>
  onLinkTwitter?: (redirPath: string) => void
  onLinkDiscord?: (redirPath: string) => void
}) {
  const termToast = useTermToast()

  const onSignIn = async (type: 'twitter' | 'sign' | 'discord') => {
    switch (type) {
      case 'sign':
        if (onLinkWallet) {
          await onLinkWallet()
        } else {
          termToast.failure({
            title: 'Error signing in with wallet',
            children: 'Please try again.',
          })
        }
        break
      case 'twitter':
        if (onLinkTwitter) {
          await onLinkTwitter(`/rewards?action=${SocialActions.SignInTwitter}`)
        } else {
          termToast.failure({
            title: 'Error signing in X',
            children: 'Please try again.',
          })
        }
        break
      case 'discord':
        if (onLinkDiscord) {
          await onLinkDiscord(`/rewards?action=${SocialActions.SignInDiscord}`)
        } else {
          termToast.failure({
            title: 'Error signing in via Discord',
            children: 'Please try again.',
          })
        }
        break
    }
  }

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none">
        <Box
          bg="white"
          pt={7}
          borderRadius="16px"
          w="480px"
          position="relative"
        >
          <Image
            position="absolute"
            cursor="pointer"
            top={7}
            right={7}
            w="24px"
            p={'4px'}
            src={closeIcon}
            onClick={() => onClose()}
          />
          <VStack align="left" gap={5}>
            <ModalHeading />
            <ModalBody
              isSwitchProfileViewOnly={isSwitchProfileViewOnly}
              isOnConnectScreen={isOnConnectScreen}
              account={account}
              connectedSocials={connectedSocials}
              onSignIn={onSignIn}
              onViewOnly={onClose}
            />
          </VStack>
        </Box>
      </ModalContent>
    </Modal>
  )
}
