import { FormControlProps } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { ReactNode } from 'react'
import TokenAmountField from '../../../../components/elements/TokenAmountField'

const ManageMarginField = ({
  type = 'string',
  value,
  onChange,
  onChangeToken,
  isDisabled,
  isError,
  isMaxable,
  w,

  label,
  maxAmount,
  helperText,
  rightLabel,
  termTokenSymbol,
  collateralDecimals,
}: {
  type?: string
  value: string
  label: string
  onChange: (value: string) => void
  onChangeToken?: (symbol: string) => void
  isDisabled?: boolean
  isError?: boolean
  isMaxable?: boolean
  w?: FormControlProps['w']

  maxAmount: FixedNumber
  helperText?: ReactNode
  rightLabel?: string | ReactNode
  termTokenSymbol: string
  collateralDecimals: number
}) => {
  return (
    <TokenAmountField
      label={label}
      placeholder="0.00"
      helperText={value.length && parseFloat(value) !== 0 && helperText}
      rightLabel={rightLabel}
      type={type}
      value={value}
      onChange={onChange}
      isDisabled={isDisabled}
      isError={isError}
      w={w}
      onMax={isMaxable ? () => onChange(maxAmount.toString()) : undefined}
      tokenSymbol={termTokenSymbol}
      tokenDecimals={collateralDecimals}
      isTokenSelectionDisabled
      onChangeToken={onChangeToken}
    />
  )
}

export default ManageMarginField
