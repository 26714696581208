import { Button, Flex, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { HStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ManageProfileNav({
  redirectPath,
  children,
}: {
  redirectPath: string
  children: React.ReactNode
}) {
  return (
    <Flex
      width="100%"
      height="100%"
      pb="60px"
      bg={'blue.0'}
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      gap={10}
    >
      <Flex
        alignSelf="stretch"
        px="140px"
        py="14px"
        bg="gray.0"
        justifyContent="space-between"
      >
        <HStack gap={3}>
          <Link to={redirectPath}>
            <Button variant="ghost">
              <FontAwesomeIcon
                icon={['far', 'chevron-left']}
                role="button"
                width="12px"
              />
            </Button>
          </Link>
          <Text variant="body-l/bold" color={'blue.9'}>
            Manage Profile
          </Text>
        </HStack>
      </Flex>
      {children}
    </Flex>
  )
}
