import { Box, Center, Flex } from '@chakra-ui/react'
import { AuctionStatus as AuctionStatusType } from '../../../data/model'

const statusColors = {
  live: 'green',
  upcoming: 'orange',
  clearing: 'orange',
  closed: 'red',
}

const SPACER = 8

export default function StatusIndicator({
  status,
  header,
}: {
  status: AuctionStatusType
  header?: boolean
}) {
  return (
    <Center w={6} h={6}>
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        mr={header ? `${SPACER}px` : '0px'}
      >
        <Box
          w={header ? `${SPACER * 3}px` : `${SPACER * 2}px`}
          h={header ? `${SPACER * 3}px` : `${SPACER * 2}px`}
          bg={`${statusColors[status]}.100`}
          borderRadius="50%"
          p={header ? '4px' : '2px'}
        >
          <Box
            w={header ? `${SPACER * 2}px` : `${SPACER + 4}px`}
            h={header ? `${SPACER * 2}px` : `${SPACER + 4}px`}
            bg={`${statusColors[status]}.200`}
            borderRadius="50%"
            p={header ? '4px' : '2px'}
          >
            <Box
              w={`${SPACER}px`}
              h={`${SPACER}px`}
              bg={`${statusColors[status]}.500`}
              borderRadius="50%"
            />
          </Box>
        </Box>
      </Flex>
    </Center>
  )
}
