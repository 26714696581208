import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import cancelListing from '../../../../assets/icons/cancel-listing.svg'
import etherescanIcon from '../../../../assets/icons/etherscan.svg'
import info from '../../../../assets/icons/info.svg'
import link from '../../../../assets/icons/link.svg'
import listTokens from '../../../../assets/icons/list-tokens.svg'
import metamaskIcon from '../../../../assets/icons/metamask.svg'
import polygonIcon from '../../../../assets/icons/polygon.svg'
import IconButton from '../../../../components/elements/IconButton'
import Tooltip from '../../../../components/elements/Tooltip'
import { useConfig } from '../../../../providers/config'

export default function LoanMorePopout({
  isPastRedemption,
  hasListings,
  hasOpenListings,
  isFullyListed,
  termTokenAddress,
  listingContractAddress,
  etherscanUrl,
  auctionId = '',
  onAddTermToken,
  onContractAddress,
  onCancelListing,
  onListTokens,
  onViewListings,
}: {
  isPastRedemption: boolean
  hasListings: boolean
  hasOpenListings: boolean
  isFullyListed: boolean
  termTokenAddress?: string
  listingContractAddress?: string
  etherscanUrl: string
  auctionId?: string
  onAddTermToken?: () => void
  onContractAddress?: () => void
  onCancelListing?: () => void
  onListTokens?: () => void
  onViewListings?: () => void
}) {
  const config = useConfig()

  const detailsLink =
    config.isMainnet && config.resultsBucket
      ? `https://${config.resultsBucket}.s3.amazonaws.com/mainnet/${auctionId}.pdf`
      : undefined

  return (
    <Menu
      computePositionOnMount={false}
      placement="bottom-end"
      strategy="fixed"
    >
      <MenuButton as={Button} variant="link" px="0">
        <IconButton hasShadow />
      </MenuButton>
      <MenuList mr={4}>
        {onListTokens && (
          <MenuItem
            isDisabled={isPastRedemption || isFullyListed}
            onClick={onListTokens}
          >
            <Flex alignItems="center">
              {<Image boxSize="24px" src={listTokens} mr="6px" />}{' '}
              <Text as="span">List Tokens</Text>
            </Flex>
          </MenuItem>
        )}
        {hasOpenListings && onCancelListing && (
          <MenuItem
            isDisabled={isPastRedemption && !hasOpenListings}
            onClick={onCancelListing}
          >
            <Flex alignItems="center">
              {<Image boxSize="24px" src={cancelListing} mr="6px" />}{' '}
              <Text as="span">Cancel Listing</Text>
            </Flex>
          </MenuItem>
        )}
        {hasListings && onViewListings && (
          <MenuItem onClick={onViewListings}>
            <Flex alignItems="center">
              {<Image boxSize="24px" src={listTokens} mr="6px" />}{' '}
              <Text as="span">View Listings</Text>
            </Flex>
          </MenuItem>
        )}
        {listingContractAddress && (
          <MenuItem
            as="a"
            href={`${etherscanUrl}/address/${listingContractAddress}`}
            target="_blank"
            isDisabled={!listingContractAddress}
          >
            <Flex alignItems="center">
              {
                <Image
                  boxSize="16px"
                  src={config.isMainnet ? etherescanIcon : polygonIcon}
                  mr="6px"
                />
              }{' '}
              <Text as="span">
                <Trans>View Listing Contract</Trans>
              </Text>
            </Flex>
          </MenuItem>
        )}
        <MenuItem
          as="a"
          href={`${etherscanUrl}/address/${termTokenAddress}`}
          target="_blank"
          isDisabled={!termTokenAddress}
        >
          <Flex alignItems="center">
            {
              <Image
                boxSize="16px"
                src={config.isMainnet ? etherescanIcon : polygonIcon}
                mr="6px"
              />
            }{' '}
            <Text as="span">
              <Trans>View Repo Token</Trans>
            </Text>
          </Flex>
        </MenuItem>
        {/* TODO: re-enable when we are finished with testnet */}
        {/* <MenuItem onClick={onAddTermToken} isDisabled={!onAddTermToken}> */}
        <MenuItem
          onClick={onAddTermToken}
          isDisabled={!onAddTermToken || !config.isMainnet}
        >
          <Flex alignItems="center">
            {<Image boxSize="16px" src={metamaskIcon} mr="6px" />}{' '}
            {
              <Text as="span">
                <Trans>Add Repo Token</Trans>
              </Text>
            }
            {!config.isMainnet && (
              <Tooltip
                label={t`Add Repo Tokens Feature Coming Soon!`}
              ></Tooltip>
            )}
          </Flex>
        </MenuItem>
        <MenuItem
          as="a"
          href={detailsLink}
          isDisabled={!detailsLink}
          target="_blank"
        >
          <Flex alignItems="center">
            {<Image boxSize="16px" src={info} mr="6px" />}{' '}
            {
              <Text as="span">
                <Trans>View Auction Results</Trans>
              </Text>
            }
          </Flex>
        </MenuItem>
        {onContractAddress && (
          <MenuItem onClick={onContractAddress}>
            <Flex alignItems="center">
              {<Image boxSize="16px" src={link} mr="6px" />}{' '}
              <Text as="span">
                <Trans>View Contract Addresses</Trans>
              </Text>
            </Flex>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
