import {
  Box,
  Button,
  Divider,
  Link,
  Skeleton,
  useDisclosure,
  Flex,
  Text,
} from '@chakra-ui/react'
import { VStack, Stack } from '../../../../components/elements/Stack'

import { useEthers } from '@usedapp/core'
import dayjs from 'dayjs'
import { BigNumber, FixedNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { every, isEqual } from 'lodash'
import { useMemo, useState, useRef } from 'react'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import Tooltip from '../../../../components/elements/Tooltip'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import {
  Auction,
  Currency,
  DraftBorrowTender,
  ParsedBorrowTender,
  Wallet,
  NativeCurrency,
} from '../../../../data/model'
import { parseBorrowTender } from '../../../../data/parse'
import {
  DEFAULT_LOAN_TENDER,
  LOW_LIQUIDITY_ASSETS,
} from '../../../../helpers/constants'
import {
  bigToFixedNumber,
  fixedToBigNumber,
} from '../../../../helpers/conversions'
import { add, evaluate, subtract } from '../../../../helpers/math'
import {
  formatFixedPercentage,
  formatFixedToken,
  formatMaturityDate,
  getAuctionTermString,
  getDisplayToken,
  getMarginRatio,
} from '../../../../helpers/utils'
import { useStorage } from '../../../../providers/storage'
import ClearButton from '../CreateTendersBox/ClearButton'
import LiquidationAcknowledgementModal from '../LiqudationAcknowledgementModal'
import SummaryBody from './SummaryBody'
import SummaryContainer from './SummaryContainer'
import SummaryDetails from './SummaryDetails'
import SummaryFootnote from './SummaryFootnote'
import SummaryHeader from './SummaryHeader'
import SummaryStat from './SummaryStat'
import SummaryTitle from './SummaryTitle'
import SummaryReferralCode from './SummaryReferralCode'
import TenderNumberHeading from './TenderNumberHeading'
import WrapApproveModal from '../../../../components/elements/WrapApproveModal'
import InfoBox from '../../../../components/elements/InfoBox'
import { useChainConfig } from '../../../../providers/config'

const servicingFeeTooltip = (
  <>
    If your bid is accepted, a{' '}
    <Link
      textDecoration="none"
      href="https://docs.term.finance/protocol/fees-and-penalties/servicing-fee"
      isExternal
    >
      servicing fee
    </Link>{' '}
    will be deducted from your borrow amount.
  </>
)

export default function BorrowTenderSummary({
  isDataLoading,
  auction,
  auctionStartTimestamp,
  auctionEndTimestamp,
  auctionServicingFee,
  wallet,
  tenders,
  maturityDate,
  gasTokenCurrency,
  purchaseCurrency,
  purchaseTokenPrice,
  purchaseTokenPriceDecimals,
  collateralBalance,
  collateralCurrency,
  collateralTokenPrice,
  collateralTokenPriceDecimals,
  collateralTokenAllowance,
  nakedCollateralCurrency,
  nakedCollateralTokenBalance,
  nakedCollateralTokenAllowance,
  convertedNakedCollateralTokenBalance,
  referralCode,
  referralCodeIsConnectedAddress,
  openConfirmDeleteReferralCodeModal,
  onApproveCollateralToken,
  onApproveNakedCollateralToken,
  submitTenders,
  clearTenders,
  onDeleteReferralCode,
  onConnect,
  onKytCheck,
  onWrapGasTokenCall,
  onWrapTokenCall,
  onUnwrapGasTokenCall,
  onUnwrapTokenCall,
}: {
  isDataLoading?: boolean
  auction: Pick<
    Auction,
    | 'chainId'
    | 'maxBidPrice'
    | 'minBidAmount'
    | 'initialMarginRatio'
    | 'collateralLiquidatedDamages'
  >
  auctionStartTimestamp: number
  auctionEndTimestamp: number
  auctionServicingFee: BigNumber
  wallet: Wallet
  tenders: DraftBorrowTender[]
  maturityDate: number
  gasTokenCurrency: NativeCurrency
  purchaseCurrency: Currency
  purchaseTokenPrice: BigNumber
  purchaseTokenPriceDecimals: number
  collateralBalance: BigNumber
  collateralCurrency: Currency
  collateralTokenPrice: BigNumber
  collateralTokenPriceDecimals: number
  collateralTokenAllowance: BigNumber
  nakedCollateralCurrency: Currency | undefined
  nakedCollateralTokenBalance: BigNumber | undefined
  nakedCollateralTokenAllowance: BigNumber | undefined
  convertedNakedCollateralTokenBalance: BigNumber | undefined
  referralCode: string
  referralCodeIsConnectedAddress: boolean
  openConfirmDeleteReferralCodeModal: () => void

  onApproveCollateralToken: () => Promise<void>
  onApproveNakedCollateralToken: (amount: string) => Promise<void>
  submitTenders: () => Promise<void>
  clearTenders: () => void
  onDeleteReferralCode: () => void
  onConnect: () => void
  onKytCheck: () => Promise<boolean>
  onWrapGasTokenCall: (value: BigNumber) => Promise<void>
  onWrapTokenCall: (
    address: string,
    symbol: string,
    value: BigNumber,
    balance?: BigNumber
  ) => Promise<void>
  onUnwrapGasTokenCall: (value: BigNumber) => Promise<void>
  onUnwrapTokenCall: (address: string, value: BigNumber) => Promise<void>
}) {
  const termToast = useTermToast()
  const currentTime = useCurrentTime()
  const startTime = dayjs.unix(auctionStartTimestamp)
  const closeTime = dayjs.unix(auctionEndTimestamp)
  const chainConfig = useChainConfig(auction.chainId)
  const duration = dayjs.duration(closeTime.diff(currentTime))
  const auctionOpen = currentTime < closeTime && currentTime >= startTime
  const diffFormatted = `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`

  const parsedTenders = useMemo(
    () =>
      tenders.map((draft) => {
        const loanAmountUsd = evaluate(
          {
            nodeKind: 'mul',
            args: [
              {
                nodeKind: 'value',
                value: FixedNumber.fromString(
                  /^\d+(\.\d+)?$/.test(draft.amount ?? '') ? draft.amount : '0',
                  `fixed128x${purchaseCurrency.decimals}`
                ),
              },
              {
                nodeKind: 'value',
                value: bigToFixedNumber(
                  purchaseTokenPrice,
                  purchaseTokenPriceDecimals
                ),
              },
            ],
          },
          collateralCurrency.decimals
        )

        const requiredCollateralUsd = evaluate(
          {
            nodeKind: 'mul',
            args: [
              {
                nodeKind: 'value',
                value: loanAmountUsd,
              },
              {
                nodeKind: 'value',
                value: auction.initialMarginRatio,
              },
            ],
          },
          collateralCurrency.decimals
        )

        const requiredCollateral = evaluate(
          {
            nodeKind: 'div',
            args: [
              {
                nodeKind: 'value',
                value: requiredCollateralUsd,
              },
              {
                nodeKind: 'value',
                value: bigToFixedNumber(
                  collateralTokenPrice,
                  collateralTokenPriceDecimals
                ),
              },
            ],
          },
          collateralCurrency.decimals
        )

        return parseBorrowTender(
          draft,
          purchaseCurrency.decimals,
          collateralCurrency.decimals,
          requiredCollateral,
          auction.minBidAmount,
          collateralBalance,
          FixedNumber.fromString('0', 18),
          FixedNumber.fromValue(auction.maxBidPrice.mul(100), 18)
        )
      }),
    [
      auction.initialMarginRatio,
      auction.maxBidPrice,
      auction.minBidAmount,
      collateralBalance,
      collateralCurrency.decimals,
      collateralTokenPrice,
      collateralTokenPriceDecimals,
      purchaseCurrency.decimals,
      purchaseTokenPrice,
      purchaseTokenPriceDecimals,
      tenders,
    ]
  )
  const hasInput = useMemo(
    () => every(parsedTenders, (tender) => tender.collateral.value.gt(0)),
    [parsedTenders]
  )

  const servicingFees = useMemo(
    () =>
      parsedTenders.reduce(
        (acc, tender, idx) => {
          const servicingFeeAmount = auctionServicingFee
            .mul(tender.amount.value)
            .div(parseUnits('1', 18))

          acc[idx] = bigToFixedNumber(
            servicingFeeAmount,
            purchaseCurrency.decimals
          )
            .round(purchaseCurrency.decimals)
            .toFormat(`fixed128x${purchaseCurrency.decimals}`)

          return acc
        },
        {} as { [tenderAmount: string]: FixedNumber }
      ),
    [parsedTenders, purchaseCurrency.decimals, auctionServicingFee]
  )

  const totalServicingFees = useMemo(
    () =>
      parsedTenders.reduce(
        (sum, tender) => {
          const servicingFeeAmount = auctionServicingFee
            .mul(tender.amount.value)
            .div(parseUnits('1', 18))

          const fees = bigToFixedNumber(
            servicingFeeAmount,
            purchaseCurrency.decimals
          )

          return evaluate({
            nodeKind: 'add',
            args: [
              {
                nodeKind: 'value',
                value: sum,
              },
              {
                nodeKind: 'value',
                value: fees,
              },
            ],
          })
        },
        FixedNumber.fromString('0', `fixed128x${purchaseCurrency.decimals}`)
      ),
    [parsedTenders, purchaseCurrency.decimals, auctionServicingFee]
  )

  const totalBorrows = useMemo(
    () =>
      evaluate({
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: FixedNumber.fromValue(
              parsedTenders.reduce(
                (sum, tender) => sum.add(tender.amount.value),
                BigNumber.from(0)
              ),
              purchaseCurrency.decimals,
              `fixed128x${purchaseCurrency.decimals}`
            ),
          },
          {
            nodeKind: 'value',
            value: totalServicingFees,
          },
        ],
      }),
    [parsedTenders, purchaseCurrency.decimals, totalServicingFees]
  )
  const totalMargin = useMemo(
    () =>
      FixedNumber.fromValue(
        parsedTenders.reduce(
          (sum, tender) => sum.add(tender.collateral.value),
          BigNumber.from(0)
        ),
        collateralCurrency.decimals,
        `fixed128x${collateralCurrency.decimals}`
      ),
    [collateralCurrency.decimals, parsedTenders]
  )
  // const liquidationPrice = useMemo(
  //   () =>
  //     FixedNumber.fromValue(collateralTokenPrice, 18).mulUnsafe(
  //       FixedNumber.fromValue(
  //         parsedTenders.reduce(
  //           (sum, tender) => sum.add(tender.collateral.value),
  //           BigNumber.from(0)
  //         ),
  //         collateralCurrency.decimals
  //       )
  //     ),
  //   [collateralCurrency.decimals, collateralTokenPrice, parsedTenders]
  // )
  const approvalAmount = useMemo(
    () =>
      fixedToBigNumber(
        tenders
          ?.filter((x) => !!x)
          ?.reduce(
            (acc, cur) => {
              try {
                return add(
                  acc,
                  FixedNumber.fromString(
                    cur.collateral.replace(',', '') || '0',
                    `fixed128x${collateralCurrency?.decimals ?? 18}`
                  )
                )
              } catch (e) {
                return acc
              }
            },
            FixedNumber.fromString(
              '0',
              `fixed128x${collateralCurrency?.decimals ?? 18}`
            )
          )
      ),
    [collateralCurrency.decimals, tenders]
  )

  const [requiresWrapping, setRequiresWrapping] = useState(false)

  const amountToWrap: BigNumber = useMemo(() => {
    const totalMarginBN = fixedToBigNumber(
      totalMargin,
      totalMargin.format.decimals
    )
    if (
      chainConfig?.wrappedTokenMapping &&
      collateralCurrency.address in chainConfig.wrappedTokenMapping &&
      totalMarginBN.gt(collateralBalance)
    ) {
      setRequiresWrapping(true)
      return totalMarginBN.sub(collateralBalance)
    } else {
      setRequiresWrapping(false)
      return BigNumber.from(0)
    }
  }, [
    totalMargin,
    collateralCurrency.address,
    chainConfig?.wrappedTokenMapping,
    collateralBalance,
  ])

  const amountToApproveForWrapping = useMemo(() => {
    let amount: BigNumber
    if (
      nakedCollateralTokenAllowance !== undefined &&
      amountToWrap.gt(nakedCollateralTokenAllowance)
    ) {
      amount = amountToWrap
    } else {
      amount = BigNumber.from(0)
    }
    return amount
  }, [nakedCollateralTokenAllowance, amountToWrap])

  const [submitting, setSubmitting] = useState(false)

  const { account } = useEthers()
  const { storage } = useStorage()

  const [isLiquidationRiskModalOpen, setIsLiquidationRiskModalOpen] =
    useState<boolean>(false)
  const { onClose: onCloseLiquidationModal } = useDisclosure()

  const onLiquidationRiskModalClose = () => {
    setIsLiquidationRiskModalOpen(false)
    onCloseLiquidationModal()
  }

  const {
    isOpen: isWrapApproveModalOpen,
    onOpen: onOpenWrapApproveModal,
    onClose: onCloseWrapApproveModal,
  } = useDisclosure()

  const onWrapApproveModalClose = (wrappingCompleted: boolean) => {
    onCloseWrapApproveModal()
    if (wrappingCompleted) {
      setRequiresWrapping(false)
    }
  }

  const submitButtonRef = useRef<null | (() => Promise<void>)>(null)

  const onBidSubmit = async () => {
    if (storage.getItem(`liquidationRiskAccepted-${account}`)) {
      setIsLiquidationRiskModalOpen(false)
    } else {
      setIsLiquidationRiskModalOpen(true)
      return
    }

    setSubmitting(true)
    termToast.pending(
      termToastMessages.bidOrOffer.pending('bid', tenders.length)
    )
    try {
      await submitTenders()
      termToast.success(
        termToastMessages.bidOrOffer.success(
          'bid',
          tenders.length,
          diffFormatted
        )
      )
    } catch (error) {
      if ((error as Error).message.includes('code=ACTION_REJECTED')) {
        termToast.dismissed()
      } else {
        termToast.failure(
          termToastMessages.bidOrOffer.failure('bid', tenders.length)
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  const {
    displayToken: collateralSymbol,
    isTruncated: isCollateralSymbolTruncated,
  } = getDisplayToken(collateralCurrency.symbol, { skipEllipsis: true })

  return (
    <SummaryContainer
      w={{
        base: '340px',
        xl: '380px',
      }}
    >
      <SummaryTitle>Bid Summary</SummaryTitle>
      <SummaryBody>
        <Stack spacing="4px" w="full">
          <SummaryStat>
            <SummaryHeader
              text={`${
                parsedTenders.length > 1
                  ? 'Total Borrow Amount'
                  : 'Borrow Amount'
              }`}
              rightElement={purchaseCurrency.symbol}
              tooltipText={
                <>
                  Your borrow amount reflects the deducted{' '}
                  <Link
                    textDecoration="none"
                    href="https://docs.term.finance/protocol/fees-and-penalties/servicing-fee"
                    isExternal
                  >
                    servicing fee
                  </Link>{' '}
                  which will occur if your bid is accepted.
                </>
              }
            />
            <SummaryDetails
              text={
                hasInput
                  ? formatFixedToken(
                      totalBorrows,
                      purchaseCurrency.symbol,
                      true,
                      true
                    )
                  : '-'
              }
            />
          </SummaryStat>
          {parsedTenders.length > 1 && (
            <SummaryStat>
              <SummaryHeader
                text="Total Servicing Fees"
                rightElement={
                  purchaseCurrency.symbol !== 'USDC'
                    ? purchaseCurrency.symbol
                    : ''
                }
                tooltipText={servicingFeeTooltip}
              />
              <SummaryDetails
                text={
                  hasInput
                    ? formatFixedToken(
                        totalServicingFees,
                        purchaseCurrency.symbol,
                        true,
                        true
                      )
                    : '-'
                }
              />
            </SummaryStat>
          )}
          <SummaryStat>
            <SummaryHeader
              text={`${
                parsedTenders.length > 1
                  ? 'Total Collateral / Ratio'
                  : 'Collateral / Ratio'
              }`}
              rightElement={collateralSymbol}
            />
            <SummaryDetails
              text={
                hasInput
                  ? `${formatFixedToken(
                      totalMargin,
                      collateralCurrency.symbol,
                      false,
                      true
                    )} / ${(
                      100 *
                      getMarginRatio(
                        {
                          amount: parsedTenders.reduce(
                            (sum, tender) => sum.add(tender.collateral.value),
                            BigNumber.from(0)
                          ),
                          tokenDecimals: collateralCurrency.decimals,
                          price: collateralTokenPrice,
                          priceDecimals: collateralTokenPriceDecimals,
                        },
                        {
                          amount: parsedTenders.reduce(
                            (sum, tender) => sum.add(tender.amount.value),
                            BigNumber.from(0)
                          ),
                          tokenDecimals: purchaseCurrency.decimals,
                          price: purchaseTokenPrice,
                          priceDecimals: purchaseTokenPriceDecimals,
                        }
                      )
                    )
                      .toFixed(1)
                      .replace(/\.0$/, '')}%`
                  : '-'
              }
            />
          </SummaryStat>
          <SummaryStat>
            <SummaryHeader text="Maturity" />
            <SummaryDetails
              text={
                isDataLoading ? (
                  <Skeleton w="100px" h={4} />
                ) : (
                  formatMaturityDate(maturityDate)
                )
              }
            />
          </SummaryStat>
          <SummaryStat>
            <SummaryHeader text="Term" />
            <SummaryDetails
              text={
                isDataLoading ? (
                  <Skeleton w="100px" h={4} />
                ) : (
                  getAuctionTermString(auctionEndTimestamp, maturityDate)
                )
              }
            />
          </SummaryStat>
        </Stack>
        {parsedTenders.length > 1 &&
          parsedTenders.map((tender, index) => (
            <Stack key={index} spacing={1} w="full">
              <TenderNumberHeading tenderNumber={index + 1} />
              <SummaryStat>
                <SummaryHeader
                  text="Borrow Amount"
                  rightElement={purchaseCurrency.symbol}
                />
                <SummaryDetails
                  text={
                    tender.amount.isValid
                      ? formatFixedToken(
                          evaluate({
                            nodeKind: 'sub',
                            args: [
                              {
                                nodeKind: 'value',
                                value: FixedNumber.fromValue(
                                  tender.amount.value,
                                  purchaseCurrency.decimals,
                                  `fixed128x${purchaseCurrency.decimals}`
                                ),
                              },
                              {
                                nodeKind: 'value',
                                value: servicingFees[index],
                              },
                            ],
                          }),
                          purchaseCurrency.symbol,
                          true,
                          true
                        )
                      : '-'
                  }
                />
              </SummaryStat>
              <SummaryStat>
                <SummaryHeader text={'Servicing Fee'} />
                <SummaryDetails
                  text={formatFixedToken(
                    servicingFees[index],
                    purchaseCurrency.symbol,
                    true,
                    false
                  )}
                />
              </SummaryStat>
            </Stack>
          ))}
        {hasInput && (
          <Stack spacing={1} w="full">
            {parsedTenders.length === 1 && (
              <SummaryStat>
                <SummaryHeader
                  text="Servicing Fee"
                  rightElement={purchaseCurrency.symbol}
                  tooltipText={servicingFeeTooltip}
                />
                <SummaryDetails
                  text={formatFixedToken(
                    servicingFees[0],
                    purchaseCurrency.symbol,
                    true,
                    false
                  )}
                />
              </SummaryStat>
            )}
            <SummaryStat>
              <SummaryHeader
                text="Liquidated Damages Penalty"
                rightElement={collateralSymbol}
                tooltipText={
                  <>
                    If your bid is accepted you will receive a loan. If your
                    loan{' '}
                    <Link
                      textDecoration="none"
                      href="https://docs.term.finance/protocol/term-repo/defaults"
                      isExternal
                    >
                      defaults
                    </Link>
                    , you will be charged a{' '}
                    <Link
                      textDecoration="none"
                      href="https://docs.term.finance/protocol/fees-and-penalties/liquidated-damages"
                      isExternal
                    >
                      liquidation damages penalty
                    </Link>
                    .
                  </>
                }
              />
              <SummaryDetails
                text={formatFixedPercentage(
                  bigToFixedNumber(auction.collateralLiquidatedDamages, 18)
                )}
              />
            </SummaryStat>
          </Stack>
        )}
        <Divider borderColor="gray.2" mt="8px" mb="8px" />
        {/* TODO: add wallet connect function */}
        {wallet.status === 'not-connected' ? (
          <>
            <SummaryFootnote text="Connect your wallet to participate in this auction." />
            <Button
              variant="primary"
              size="sm"
              w="full"
              mt="8px"
              onClick={onConnect}
            >
              Connect wallet
            </Button>
          </>
        ) : (
          <>
            <SummaryFootnote
              text={
                approvalAmount.gt(collateralTokenAllowance)
                  ? `Approve ${collateralCurrency.symbol} to start submitting bids.`
                  : `Your ${collateralCurrency.symbol} will be locked until the auction clears. To unlock, delete your bid from the table below.`
              }
            />
            {chainConfig &&
              LOW_LIQUIDITY_ASSETS.includes(
                collateralCurrency.address.toLowerCase()
              ) && (
                <InfoBox kind="warning">
                  <Text color="gray.6">
                    This{' '}
                    <Link
                      color="blue.4"
                      textDecoration="none"
                      href={
                        chainConfig.blockExplorerUrl +
                        '/token/' +
                        collateralCurrency.address
                      }
                      isExternal
                    >
                      collateral asset
                    </Link>{' '}
                    may have limited liquidity. Do your own research before
                    participating.
                  </Text>
                </InfoBox>
              )}
            {!!referralCode && (
              <SummaryReferralCode
                referralCode={referralCode}
                openConfirmDeleteReferralCodeModal={
                  openConfirmDeleteReferralCodeModal
                }
              />
            )}
            <VStack w="full">
              {requiresWrapping &&
                nakedCollateralCurrency &&
                nakedCollateralTokenBalance &&
                convertedNakedCollateralTokenBalance && (
                  <Button
                    variant="primary"
                    size="sm"
                    w="full"
                    isDisabled={
                      !auctionOpen ||
                      subtract(
                        bigToFixedNumber(
                          collateralBalance.add(
                            convertedNakedCollateralTokenBalance
                          ),
                          totalMargin.format.decimals
                        ),
                        totalMargin
                      ).isNegative()
                    }
                    onClick={onOpenWrapApproveModal}
                  >
                    <Flex as="span" alignItems="center" columnGap={1.5}>
                      <Text as="span" variant="body-sm/semibold">
                        {`Wrap ${nakedCollateralCurrency.symbol}`}
                      </Text>
                      {/* {buttonIcon} */}
                    </Flex>
                  </Button>
                )}
              <SubmitApproveButton
                approvingCurrencySymbol={collateralCurrency.symbol}
                onApprove={onApproveCollateralToken}
                onSubmit={onBidSubmit}
                isSkipApproval={requiresWrapping}
                isDisabled={
                  requiresWrapping ||
                  !auctionOpen ||
                  !every(
                    parsedTenders,
                    (tender: ParsedBorrowTender) => tender.isValid
                  )
                }
                approvalAmount={approvalAmount}
                approvedAmount={collateralTokenAllowance}
                submitLabel={tenders?.length > 1 ? 'Submit bids' : 'Submit bid'}
                onKytCheck={onKytCheck}
                submitButtonRef={submitButtonRef}
                submitTooltip={
                  referralCodeIsConnectedAddress && (
                    <Tooltip
                      label={
                        'Using your connected wallet’s address as a referral is not possible.'
                      }
                    >
                      <Box pos="absolute" top="0" left="0" w="100%" h="100%" />
                    </Tooltip>
                  )
                }
              />
              {!approvalAmount.gt(collateralTokenAllowance) && (
                <ClearButton
                  onClick={clearTenders}
                  isDisabled={
                    submitting || isEqual(tenders, [DEFAULT_LOAN_TENDER])
                  }
                  label={tenders?.length > 1 ? 'Clear all' : 'Clear'}
                />
              )}
              <LiquidationAcknowledgementModal
                isOpen={isLiquidationRiskModalOpen}
                account={account}
                onClose={onLiquidationRiskModalClose}
                onAcceptance={() => {
                  submitButtonRef.current && submitButtonRef.current()
                }}
              />
              {nakedCollateralCurrency &&
                nakedCollateralTokenAllowance &&
                requiresWrapping && (
                  <WrapApproveModal
                    unwrappedBalance={nakedCollateralTokenBalance}
                    unwrappedCurrency={nakedCollateralCurrency}
                    wrappedCurrency={collateralCurrency}
                    wrappingApprovedAmount={nakedCollateralTokenAllowance}
                    amountToApproveForWrapping={amountToApproveForWrapping}
                    amountToWrap={amountToWrap}
                    termContractApprovedAmount={collateralTokenAllowance}
                    amountToApproveForTermContract={approvalAmount}
                    isNativeCurrency={false}
                    isOpen={isWrapApproveModalOpen}
                    onWrapNativeToken={onWrapGasTokenCall}
                    onApproveNakedToken={onApproveNakedCollateralToken}
                    onWrapNakedToken={onWrapTokenCall}
                    onApproveWrappedToken={onApproveCollateralToken}
                    onKytCheck={onKytCheck}
                    onClose={onWrapApproveModalClose}
                  />
                )}
            </VStack>
          </>
        )}
      </SummaryBody>
    </SummaryContainer>
  )
}
