import {
  Box,
  Button,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import term_logo from '../../../../assets/term-logo-short.svg'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { externalLinks } from '../../../../links'
import { useConfig } from '../../../../providers/config'
import { useSafary } from '../../../../data/analytics/use-safary'

export default function WhatIsEarnModal({
  isWalletConnected,
  isOpen,
  onConnect,
  onClose,
}: {
  isWalletConnected: boolean
  isOpen: boolean
  onConnect: () => void
  onClose: () => void
}) {
  const { trackSafaryEvent } = useSafary()
  const config = useConfig()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount={false}
      variant="wide"
      autoFocus={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={7} px={7}>
          <HStack>
            <Image src={term_logo} w={6} h={6} />
            <Text variant="body-md/medium" as="span">
              Introducing the Term Finance Earn Product
            </Text>
          </HStack>
          <ModalCloseButton size="sm" mr="12px" mt="16px" color="blue.900" />
        </ModalHeader>
        <ModalBody px={7} py={5}>
          <VStack width="100%" spacing={6} alignItems="flex-start">
            {/* Section Header */}
            <Box w="100%" mb={2}>
              <Text variant="body-xl/bold" color="blue.9">
                What are{' '}
                <Text as="span" color="green.5">
                  Blue Sheets
                </Text>
                ?
              </Text>
            </Box>

            {/* FAQ Section */}
            <VStack alignItems="flex-start" spacing={5} w="100%">
              <Box>
                <Text variant="body-sm/bold" color="blue.9" mb={1}>
                  What are the Blue Sheets?
                </Text>
                <Text variant="body-sm/normal" color="blue.9" opacity={0.7}>
                  The Blue Sheets are designed for users who want access to
                  fixed rates but don't have time to monitor Term auctions.
                  Through the Blue Sheets, users can filter, search, and supply
                  funds to earn fixed rates from past auctions with just a
                  single click. There are no minimums, no slippage, and no
                  hidden fees.
                </Text>
              </Box>

              <Box>
                <Text variant="body-sm/bold" color="blue.9" mb={1}>
                  Where does the yield come from?
                </Text>
                <Text variant="body-sm/normal" color="blue.9" opacity={0.7}>
                  Yields come from Term auctions, where borrowers compete to
                  take out fixed-rate loans by bidding interest rates they're
                  willing to pay. These borrower-paid interest rates become the
                  fixed yields that lenders can earn through the Blue Sheets.
                  All loans are over-collateralized, with borrowers' collateral
                  tokens held in non-custodial smart contracts as security.
                </Text>
              </Box>

              <Box>
                <Text variant="body-sm/bold" color="blue.9" mb={1}>
                  Are there any fees?
                </Text>
                <Text variant="body-sm/normal" color="blue.9" opacity={0.7}>
                  No hidden fees - the rate you see is the rate you'll receive.
                  Published rates are transparent and final (set at 0.25% below
                  auction rates), with users responsible only for network gas
                  fees.
                </Text>
              </Box>

              <Box>
                <Text variant="body-sm/bold" color="blue.9" mb={1}>
                  What are the risks?
                </Text>
                <Text variant="body-sm/normal" color="blue.9" opacity={0.7}>
                  Like all DeFi activities, lending through the Blue Sheets
                  carries risks including smart contract vulnerabilities, oracle
                  failures, network issues, and liquidation risks. During market
                  volatility, there may not be enough liquidity to fully cover
                  repayment through liquidations. Please review our detailed{' '}
                  <Link
                    textDecoration="none"
                    isExternal
                    href={externalLinks.risk}
                  >
                    Risk Disclosures
                  </Link>{' '}
                  before participating.
                </Text>
              </Box>
            </VStack>

            {/* Button Section */}
            <Box w="100%" pt={2}>
              <Button
                size="md"
                variant="tertiary"
                border={0}
                w="100%"
                onClick={() => {
                  trackSafaryEvent(
                    config?.safary?.earn?.blueSheetsDocsLink?.type ?? 'click',
                    'blue sheets doc',
                    {}
                  )
                  window.open(
                    externalLinks.lending,
                    '_blank',
                    'noopener,noreferrer'
                  )
                  onClose()
                }}
              >
                Learn how Blue Sheets (Simple-Earn) works
              </Button>

              {!isWalletConnected && (
                <Button
                  variant="primary"
                  w="100%"
                  mt={3}
                  onClick={() => {
                    onClose()
                    onConnect()
                  }}
                >
                  Connect wallet
                </Button>
              )}
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
