// This file contains utilities for handling oauth2 authorization and token exchange.
//
// Reference:
//  - https://developer.twitter.com/en/docs/authentication/oauth-2-0/user-access-token
//  - https://discord.com/developers/docs/topics/oauth2

import { ReactNode, useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

export const encodeState = (state: Record<string, string>) =>
  btoa(JSON.stringify(state))

export const decodeState = <T extends Record<string, string>>(
  state: string
): T => JSON.parse(atob(state))

export const useEffectOnce = (effect: () => any) => {
  const destroyFunc = useRef()
  const effectCalled = useRef(false)
  const renderAfterCalled = useRef(false)
  const [val, setVal] = useState(0)

  if (effectCalled.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect()
      effectCalled.current = true
    }

    // this forces one render after the effect is run
    // setVal((val) => val + 1)
    setVal(val + 1)

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return
      }
      if (destroyFunc.current) {
        ;(destroyFunc.current as any)()
      }
    }
  }, [])
}

const parseStatePath = (
  state: string
): { path: string; queryParams: { [key: string]: string } } => {
  const statePath = decodeState(state).path
  const [path, queryString] = statePath.split('?')
  const queryParams: { [key: string]: string } = {}

  if (queryString) {
    const params = new URLSearchParams(queryString)
    params.forEach((value, key) => {
      queryParams[key] = value
    })
  }

  return {
    path,
    queryParams,
  }
}

// oauth2Authorize constructs an oauth2 authorize url and navigates the browser to it
// immediately. Use this as a onClick handler for a button or link to start an oauth2
// auth code flow.
//
// Example usage:
//
// ```tsx
// <button
//   onClick={() => {
//     oauth2Authorize({
//       navigate,
//       authorizeUrl: 'https://example.com/authorize',
//       responseType: 'code',
//       clientId: 'client-id',
//       redirectUri: 'http://localhost:3000/callback',
//       scope: 'scope1 scope2',
//       state: 'state',
//       codeChallenge: 'challenge',
//       codeChallengeMethod: 'plain',
//     })
//   }}
// >
//   Authorize
// </button>
// ```
export const oauth2Authorize = ({
  navigate,
  authorizeUrl,
  responseType = 'code',
  clientId,
  redirectUri,
  scope,
  state,
  codeChallenge = 'challenge',
  codeChallengeMethod = 'plain',
}: {
  navigate: (to: string) => void
  authorizeUrl: string
  responseType?: string
  clientId: string
  redirectUri: string
  scope: string
  state: string
  codeChallenge?: string
  codeChallengeMethod?: string
}) => {
  navigate(
    authorizeUrl +
      `?response_type=${encodeURIComponent(responseType)}` +
      `&client_id=${encodeURIComponent(clientId)}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&scope=${encodeURIComponent(scope)}` +
      `&state=${encodeURIComponent(state)}` +
      `&code_challenge=${encodeURIComponent(codeChallenge)}` +
      `&code_challenge_method=${encodeURIComponent(codeChallengeMethod)}`
  )
}

// <OAuth2CallbackPage /> provides a page that handles an oauth2 callback
//
// Some notes on usage:
//  - `onSuccess` and `onFailure` are called when the oauth2 callback is
//    successful or fails, respectively. These handlers should be used to store
//    or otherwise handle the received token before this component renders its
//    `success` or `failure` components.
//  - `loading`, `success`, and `failure` are the components that are rendered
//    while the callback is processing, after it is successful, and after it
//    fails, respectively. They can be either components or functions that
//    return components.
//
// To redirect the user on success, set the `success` component to `<Navigate to="/path" />`.
// This will navigate the user to the specified path after the `onSuccess` is completed.
//
// To redirect the user on failure, set the `failure` component to `<Navigate to="/path" />`.
// This will navigate the user to the specified path after the `onFailure` is completed.
//
// Example usage:
//
// ```tsx
// <OAuth2CallbackPage
//   onSuccess={(token) => {
//     localStorage
//       .setItem('token', token)
//   }}
//   onFailure={(error) => {
//     console.error(error)
//   }}
//   loading={<p>Loading...</p>}
//   success={<Navigate to="/path" />}
//   failure={<Navigate to="/error" />}
//   tokenUrl="https://example.com/token"
//   clientId="client-id"
//   codeVerifier="challenge"
//   redirectUri="http://localhost:3000/callback"
// />
// ```
export function OAuth2CallbackPage({
  type,
  onSuccess,
  onFailure,
  loading,
  success,
  failure,

  tokenUrl,
  clientId,
  codeVerifier,
  redirectUri,
}: {
  type: 'discord' | 'twitter'
  onSuccess: (
    token: string,
    action?: string,
    skipTwitter?: boolean,
    refName?: string,
    refImage?: string
  ) => void
  onFailure: (
    error: any,
    action?: string,
    skipTwitter?: boolean,
    refName?: string,
    refImage?: string
  ) => void

  loading?: ReactNode
  success?: ReactNode | ((token: string, path?: string) => ReactNode)
  failure?: ReactNode | ((error: any, path?: string) => ReactNode)

  tokenUrl: string
  clientId: string
  codeVerifier?: string
  redirectUri: string
}) {
  // Component should read oauth parameters from callback url, store them in the provided handlers, and then navigate to the provided url
  const [{ processing, result }, setProcessing] = useState({
    processing: true,
    result: undefined,
  } as { processing: boolean; result: ReactNode })
  const [searchParams] = useSearchParams()

  useEffectOnce(() => {
    ;(async () => {
      const code = searchParams?.get('code') ?? undefined
      const state = searchParams?.get('state') ?? ''

      const { path, queryParams } = parseStatePath(state)

      // Check to see that we got a code
      if (!code) {
        const error = new Error('No code provided')
        onFailure(
          error,
          queryParams?.action,
          queryParams?.skipTwitter === 'true',
          queryParams?.name,
          queryParams?.image
        )
        setProcessing({
          processing: false,
          result:
            typeof failure === 'function' ? failure(error, path) : failure,
        })
        return
      }

      // Exchange the code for a token
      try {
        if (type === 'discord') {
          const body = new URLSearchParams({
            code,
            grant_type: 'authorization_code',
            client_id: clientId,
            redirect_uri: redirectUri,
            code_verifier:
              codeVerifier ??
              window.sessionStorage.getItem('discord-code-verifier') ??
              'challenge',
          })
          const tokenResponse = await fetch(tokenUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body,
          })
          const tokenData = await tokenResponse.json()
          if (tokenData.error) {
            throw new Error(tokenData.error)
          }
          // window.sessionStorage.removeItem('discord-code-verifier')
          // Call the onSuccess handler
          onSuccess(
            tokenData.access_token,
            queryParams?.action,
            queryParams?.skipTwitter === 'true',
            queryParams?.name,
            queryParams?.image
          )
        } else {
          onSuccess(
            code,
            queryParams?.action,
            false,
            queryParams?.name,
            queryParams?.image
          )
        }
      } catch (error) {
        onFailure(
          error,
          queryParams?.action,
          queryParams?.skipTwitter === 'true',
          queryParams?.name,
          queryParams?.image
        )
        setProcessing({
          processing: false,
          result:
            typeof failure === 'function' ? failure(error, path) : failure,
        })
        return
      }

      // Set the result
      setProcessing({
        processing: false,
        result: typeof success === 'function' ? success(code, path) : success,
      })
    })()
  })

  return <>{processing ? loading : result}</>
}
