import { Stack } from '../../../../components/elements/Stack'
import { ReactNode } from 'react'

export default function Container({ children }: { children: ReactNode }) {
  return (
    <Stack p={4} borderRadius={6} spacing={4} bgColor="white">
      {children}
    </Stack>
  )
}
