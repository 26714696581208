import { useState, useEffect } from 'react'
import { PageRewardsQuery } from '../../gql/graphql'
import { captureException } from '@sentry/react'
import { useConfig } from '../../providers/config'
import { Address, Currency } from '../model'
import { FixedNumber } from 'ethers'
import { bigToFixedNumber } from '../../helpers/conversions'
import { add, multiply, subtract } from '../../helpers/math'
import { useCurrentTime } from './helper-hooks'
import { WalletNetPosition } from '../../pages/Rewards/elements/RewardsSummary/NetPosition'

export function useGraphRewards(
  rewardsResult: Record<string, PageRewardsQuery> | undefined,
  prices: Record<string, { [token: Address]: FixedNumber }> | undefined,
  currencies: Record<string, { [token: Address]: Currency }> | undefined
): [
  // count of auctions
  number,
  FixedNumber, // sum of Loans
  FixedNumber, // sum of collaterals
  FixedNumber, // sum of Borrows
  WalletNetPosition[], // walletNetPositions,
  number, // numOfActiveLoans
] {
  const config = useConfig()
  const [countRewards, setCountRewards] = useState<number>(0)
  const [totalLoans, setTotalLoans] = useState<FixedNumber>(
    FixedNumber.fromString('0')
  )
  const [totalCollateral, setTotalCollateral] = useState<FixedNumber>(
    FixedNumber.fromString('0')
  )
  const [totalBorrows, setTotalBorrows] = useState<FixedNumber>(
    FixedNumber.fromString('0')
  )
  const [walletNetPositions, setWalletNetPositions] = useState<
    WalletNetPosition[]
  >([])
  const [numOfActiveLoans, setNumOfActiveLoans] = useState<number>(0)

  const currentTimestamp = useCurrentTime().unix()

  useEffect(() => {
    let totalCount = 0
    let totalNumOfActiveLoans = 0
    let totalLoans = FixedNumber.fromString('0').toFormat('fixed128x18')
    let totalCollateral = FixedNumber.fromString('0').toFormat('fixed128x18')
    let totalBorrows = FixedNumber.fromString('0').toFormat('fixed128x18')
    const walletNetPositions: { [key: string]: FixedNumber } = {}
    if (rewardsResult) {
      Object.entries(rewardsResult).forEach(([chainId, result]) => {
        if (!config.chains[chainId]) {
          captureException(
            new Error(
              `No chain config found for provided chain id: ${chainId}!`
            )
          )
        }

        totalCount += result.termAuctions.length

        if (prices && currencies) {
          const futureTermPurchases = result.termPurchases.filter(
            (t) => t.auction.term.redemptionTimestamp > currentTimestamp
          )
          totalNumOfActiveLoans += futureTermPurchases.length
          const totalLoansFN = futureTermPurchases.reduce((sum, item) => {
            const priceFN = prices[chainId][item.auction.term.purchaseToken]
            const currencyDecimals =
              currencies[chainId][item.auction.term.purchaseToken]?.decimals
            const loanBalanceFN = multiply(
              priceFN,
              bigToFixedNumber(item.repurchasePrice, currencyDecimals)
            )
            // console.debug('loan balance' + loanBalanceFN.toString())
            walletNetPositions[item.lender] =
              walletNetPositions[item.lender] ??
              FixedNumber.fromString('0').toFormat('fixed128x18')
            walletNetPositions[item.lender] = add(
              walletNetPositions[item.lender],
              loanBalanceFN,
              18
            )
            return add(sum, loanBalanceFN, 18)
          }, FixedNumber.fromString('0').toFormat('fixed128x18'))
          totalLoans = add(totalLoans, totalLoansFN, 18)
          // console.debug('totalLoans' + totalLoans.toString())

          const totalCollateralFN = result.termRepoCollaterals.reduce(
            (sum, item) => {
              const priceFN = prices[chainId][item.collateralToken]
              const currencyDecimals =
                currencies[chainId][item.collateralToken]?.decimals
              const collateralLockedFN = multiply(
                priceFN,
                bigToFixedNumber(item.amountLocked, currencyDecimals)
              )
              // console.debug('collateral locked' + collateralLockedFN.toString())
              walletNetPositions[item.repoExposure?.borrower] =
                walletNetPositions[item.repoExposure?.borrower] ??
                FixedNumber.fromString('0').toFormat('fixed128x18')
              walletNetPositions[item.repoExposure?.borrower] = add(
                walletNetPositions[item.repoExposure?.borrower],
                collateralLockedFN,
                18
              )

              return add(sum, collateralLockedFN, 18)
            },
            FixedNumber.fromString('0').toFormat('fixed128x18')
          )
          totalCollateral = add(totalCollateral, totalCollateralFN, 18)
          // console.debug('totalCollateral' + totalCollateral.toString())

          const totalBorrowsFN = result.termRepoExposures.reduce(
            (sum, item) => {
              const priceFN = prices[chainId][item.term.purchaseToken]
              const currencyDecimals =
                currencies[chainId][item.term.purchaseToken].decimals
              const borrowBalanceFN = multiply(
                priceFN,
                bigToFixedNumber(item.repoExposure, currencyDecimals)
              )
              // console.debug('borrow balance' + borrowBalanceFN.toString())
              walletNetPositions[item.borrower] =
                walletNetPositions[item.borrower] ??
                FixedNumber.fromString('0').toFormat('fixed128x18')

              walletNetPositions[item.borrower] = subtract(
                walletNetPositions[item.borrower],
                borrowBalanceFN,
                18
              )

              return add(sum, borrowBalanceFN, 18)
            },
            FixedNumber.fromString('0').toFormat('fixed128x18')
          )

          totalBorrows = add(totalBorrows, totalBorrowsFN, 18)
        }
      })
    }

    setCountRewards(totalCount)
    setTotalLoans(totalLoans)
    setTotalCollateral(totalCollateral)
    setTotalBorrows(totalBorrows)
    setWalletNetPositions(
      Object.entries(walletNetPositions).map(([key, value]) => {
        return {
          wallet: key,
          position: value,
        }
      })
    )
    setNumOfActiveLoans(totalNumOfActiveLoans)
  }, [
    config.chains,
    rewardsResult,
    prices,
    currencies,
    currentTimestamp,
    countRewards,
  ])

  return [
    countRewards,
    totalLoans,
    totalCollateral,
    totalBorrows,
    walletNetPositions,
    numOfActiveLoans,
  ]
}
