import { Flex, Text } from '@chakra-ui/react'

export default function SectionHeader({
  heading,
  description,
}: {
  heading: string
  description: string
}) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="8px"
    >
      <Text color="blue.9" variant="body-md/bold" wordBreak="break-word">
        {heading}
      </Text>
      <Text
        width="480px"
        color="gray.6"
        variant="body-sm/normal"
        wordBreak="break-word"
      >
        {description}
      </Text>
    </Flex>
  )
}
