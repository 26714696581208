import { Text, TextProps } from '@chakra-ui/react'

const RepoCardHeading = ({
  title,
  mb = 3,
}: {
  title: string
  mb?: TextProps['mb']
}) => {
  return (
    <Text
      as="h2"
      variant="body-sm/normal"
      whiteSpace="nowrap"
      color="gray.5.5"
      mb={mb}
    >
      {title}
    </Text>
  )
}

export default RepoCardHeading
