import { BigNumber, FixedNumber, utils } from 'ethers'
import { fixedToBigNumber } from '../helpers/conversions'

export function obfuscatePrice(price: FixedNumber, nonce: BigNumber) {
  const rawPrice = fixedToBigNumber(price).div(100)
  // Price uses 18 decimal places and has a unit of percent.
  console.log(
    `Obfuscating raw price ${rawPrice.toString()} with nonce ${nonce.toString()}`
  )
  const priceHash = utils.solidityKeccak256(
    ['uint256', 'uint256'],
    [rawPrice, nonce]
  )
  console.log(`price hash of ${priceHash.toString()}`)
  return priceHash
}
