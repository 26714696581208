import { hexDataLength } from 'ethers/lib/utils'
import { pack } from '@ethersproject/solidity'

export function encodeMultisendTx({
  to,
  value,
  data,
  operation,
}: {
  to: string
  value: string
  data: string
  operation?: 0 | 1
}) {
  return pack(
    ['uint8', 'address', 'uint256', 'uint256', 'bytes'],
    [operation || 0, to, value, hexDataLength(data), data]
  )
}

export function encodeMultisend(
  transactions: { to: string; value: string; data: string; operation?: 0 | 1 }[]
) {
  const remove0x = (hexString: string) => hexString.substring(2)
  return '0x' + transactions.map(encodeMultisendTx).map(remove0x).join('')
}
