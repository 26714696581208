import { Button, ButtonGroup as CButtonGroup, Text } from '@chakra-ui/react'

export default function TabGroup<T extends string>({
  onChange,
  value,
  flexGrow,
  isDisabled,
  ...buttons
}: {
  value: T
  flexGrow?: string | number
  isDisabled?: boolean
  onChange: (value: T) => void
  left: {
    label: string
    value: T
  }
  right: {
    label: string
    value: T
  }
}) {
  const activeStyles = {
    active: {
      color: 'blue.5',
      background: 'white',
      borderRadius: '6px 6px 0px 0px',
    },
    hover: {
      color: 'gray.5',
      background: 'gray.2',
    },
    inactive: {
      color: 'gray.5',
      background: 'gray.300',
      borderRadius: '0px',
    },
    focus: {
      borderRadius: '6px 6px 0px 0px!important',
      borderWidth: '2px 2px 0px 2px',
      borderStyle: 'solid',
      borderColor: '#008FF169',
    },
    focusVisible: {
      boxShadow: 'none',
    },
  }

  return (
    <CButtonGroup
      isAttached
      flexGrow={flexGrow}
      borderTopLeftRadius="md"
      borderTopRightRadius="md"
      overflow="hidden"
      pointerEvents={isDisabled ? 'none' : undefined}
    >
      {['left' as const, 'right' as const].map((btn) => (
        <Button
          key={btn}
          onClick={() => onChange(buttons[btn].value)}
          isActive={value === buttons[btn].value}
          onMouseDown={(e) => e.preventDefault()}
          px={3}
          flexGrow={flexGrow}
          {...activeStyles.inactive}
          _active={{
            ...activeStyles.active,
          }}
          // Selected tabs do not have a hover state
          _hover={
            value !== buttons[btn].value
              ? {
                  ...activeStyles.hover,
                }
              : undefined
          }
          _focus={{
            ...activeStyles.focus,
          }}
          _focusVisible={{
            ...activeStyles.focusVisible,
          }}
        >
          <Text variant="body-sm/semibold">{buttons[btn].label}</Text>
        </Button>
      ))}
    </CButtonGroup>
  )
}
