import { Box, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'

import { ReactNode } from 'react'
import Tooltip from '../../../../components/elements/Tooltip'

export default function SummaryHeader({
  text,
  rightElement,
  tooltipText,
}: {
  text: string
  rightElement?: string
  tooltipText?: string | ReactNode
}) {
  return (
    <HStack w="100%" alignItems="center" flexWrap="nowrap">
      <Text color="gray.6" variant="body-xs/medium" whiteSpace="nowrap">
        {text}
      </Text>
      {rightElement && (
        <Box
          borderRadius="md"
          bg="gray.2"
          px={1}
          py={0}
          display="flex"
          flexShrink={0}
        >
          <Text
            as="span"
            color="gray.6"
            fontSize="8px"
            fontWeight={500}
            lineHeight="100%"
            py={1}
            fontStyle="normal"
          >
            {rightElement}
          </Text>
        </Box>
      )}
      {tooltipText && (
        <Tooltip
          label={tooltipText}
          aria-label={`${text
            .toLowerCase()
            .replaceAll(' ', '-')}-ratio-tooltip`}
        />
      )}
    </HStack>
  )
}
