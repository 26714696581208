import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  VStack,
  HStack,
  Text,
  Heading,
  Button,
} from '@chakra-ui/react'
import { SIGN_UP_BENEFITS } from '../../../../helpers/constants'
import closeIcon from '../../../../assets/icons/close.svg'

export default function ConfirmSkipModal({
  isOpen,
  onClose,
  onSignUp,
  onContinue,
}: {
  isOpen: boolean
  onClose: () => void
  onSignUp: () => void
  onContinue: () => void
}) {
  return (
    <Modal isOpen={isOpen} isCentered onClose={() => {}} autoFocus={false}>
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent bg="transparent" boxShadow="none">
        <Box bg="white" p={7} borderRadius="16px" w="480px" position="relative">
          <Image
            position="absolute"
            cursor="pointer"
            top={7}
            right={7}
            w="24px"
            p={'4px'}
            src={closeIcon}
            onClick={onClose}
          />
          <VStack align="left" gap={5}>
            {/* Heading */}
            <Heading
              variant="heading/h1"
              color="blue.9"
              lineHeight="32px"
              fontWeight={600}
              as="h1"
            >
              Are you sure?
            </Heading>
            {/* Body */}
            <VStack gap={4} align="left">
              <Text>Don’t miss out on the benefits of creating a profile:</Text>
              <VStack gap={2} align="left">
                {SIGN_UP_BENEFITS.map((n, index) => (
                  <HStack key={index} align="start" gap={2}>
                    <Text style={{ textWrap: 'nowrap' }}>{'->'}</Text>
                    <Text>{n}</Text>
                  </HStack>
                ))}
              </VStack>
            </VStack>
            {/* Footer */}
            <HStack gap={5}>
              <Button w="270px" variant="primary" onClick={onSignUp}>
                Create profile
              </Button>
              <Button
                w={'134px'}
                variant="unstyled"
                color="blue.5"
                fontWeight={600}
                onClick={onContinue}
              >
                Continue without
              </Button>
            </HStack>
          </VStack>
        </Box>
      </ModalContent>
    </Modal>
  )
}
