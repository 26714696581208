import { Image, ImageProps } from '@chakra-ui/react'
import aave_icon from '../../../assets/icons/projects/aave.svg'
import morpho_icon from '../../../assets/icons/projects/morpho.svg'
import compound_icon from '../../../assets/icons/projects/compound.svg'
import spark_icon from '../../../assets/icons/projects/spark.svg'

export type Project = 'aave' | 'compound' | 'spark' | 'morpho'

const icons: Record<Project, any> = {
  aave: aave_icon,
  morpho: morpho_icon,
  compound: compound_icon,
  spark: spark_icon,
}

export default function ProjectIcon(props: ImageProps & { project: Project }) {
  return (
    <Image
      w="20px"
      h="20px"
      alt={`${props.project}_icon`}
      src={icons[props.project]}
      {...props}
    />
  )
}
