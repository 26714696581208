import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import SectionHeader from './SectionHeader'

export default function PersonalInformation({
  username,
  profileImageUrl,
}: {
  username?: string
  profileImageUrl?: string
}) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="20px"
    >
      <SectionHeader
        heading={'Personal Information'}
        description={
          'Your username and avatar are only visible to you, and are pulled from your linked social account.'
        }
      />
      <Flex
        width="480px"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={6}
      >
        <Flex flex="1" direction="column" justify="space-between" h="74px">
          <Text variant="body-sm/medium" color="blue.9" wordBreak="break-word">
            Username
          </Text>
          <Flex
            alignSelf="stretch"
            height="40px"
            px="4px"
            py="5px"
            borderBottom="1px solid #0A5ED9"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              variant="body-md/normal"
              color="blue.9"
              wordBreak="break-word"
            >
              {username}
            </Text>
          </Flex>
        </Flex>
        <VStack gap="6px">
          <Text color="blue.9" variant="body-sm/medium" wordBreak="break-word">
            Avatar
          </Text>
          <Box>
            {profileImageUrl ? (
              <Avatar size="md" src={profileImageUrl} bg={'gray.2'} />
            ) : (
              <Tooltip
                noDelay
                placement="bottom-end"
                label="To have your avatar displayed, please link a social account."
              >
                <Avatar size="md" bg={'gray.2'} />
              </Tooltip>
            )}
          </Box>
        </VStack>
      </Flex>
    </Flex>
  )
}
