import { Button, ButtonProps, Spinner } from '@chakra-ui/react'

export default function WrapApproveButton({
  isSubmitting,
  fontSize = 'sm',
  size = 'sm',
  variant = 'secondary',
  w = 'full',
  label,
  ...props
}: { isSubmitting: boolean; label: string } & ButtonProps) {
  return (
    <Button fontSize={fontSize} size={size} variant={variant} w={w} {...props}>
      {isSubmitting && (
        <>
          <Spinner size="sm" mr="2" />{' '}
        </>
      )}
      {label}
    </Button>
  )
}
