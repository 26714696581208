import { Signer } from 'ethers'
// import { SiweMessage } from 'siwe'

// const domain = window.location.host
// const origin = window.location.origin

export const getSignInSignature = async (signer: Signer, challenge: string) => {
  // TODO if the backend verified the message using SIWE use the below code
  //   currently just matches a challenge with an account signature I believe vulnerable to a MITM

  // const siweMessage = new SiweMessage({
  // domain,
  // address: account,
  // statement: 'Verify wallet ownership by signing in with Ethereum.',
  // uri: origin,
  // version: '1',
  // chainId: 1,
  // nonce: challenge
  // })
  // const message = siweMessage.prepareMessage()
  // const signature = await signer.signMessage(message)
  // console.log('Retrived signature', signature)

  const signature = await signer.signMessage(challenge)

  return signature
}
