import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {
  ToSBody,
  ToSHeader,
  ToSHeading,
  ToSSubHeading,
} from '../ToSModal/modal'

function KytDeniedModal({
  isOpen,
  account,
  onClose,
}: {
  isOpen: boolean
  account: string | undefined
  onClose: () => void
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ToSHeader>
          <ToSHeading>Unable to Connect</ToSHeading>
          <ToSSubHeading>Wallet flagged by AML KYC filter</ToSSubHeading>
        </ToSHeader>
        <ToSBody panel={3}>
          <Text as="span">
            Your wallet '{account}' is not permitted to connect because it has
            been identified as high risk by our vendor.
            <br />
            <br />
            Please check your wallet provider to ensure that you are using the
            right address. You might need to{' '}
            <Button
              onClick={() => window.location.reload()}
              variant="link"
              px={0}
            >
              <Text as="span" variant="body-md/medium" color="blue.5">
                hard refresh
              </Text>
            </Button>{' '}
            the browser.
            <br />
            <br />
            <br />
          </Text>
        </ToSBody>
      </ModalContent>
    </Modal>
  )
}

export default KytDeniedModal
