import { Divider, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'

export default function TenderNumberHeading({
  tenderNumber,
}: {
  tenderNumber: number
}) {
  return (
    <HStack>
      <Text
        color="gray.6"
        variant="body-xs/semibold"
        mr="8px"
        whiteSpace="nowrap"
      >
        Tender {tenderNumber}
      </Text>
      <Divider />
    </HStack>
  )
}
