import {
  Icon,
  IconProps,
  Box,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  Progress,
} from '@chakra-ui/react'

const LeftProgressIcon = (props: IconProps) => (
  <Icon viewBox="0 0 12 12" boxSize="12px" {...props}>
    <circle cx="6" cy="6" r="6" fill="#D9D9D9" />
    <circle cx="6" cy="6" r="3" fill="#0A5ED9" />
  </Icon>
)

const RightProgressIcon = (props: IconProps) => (
  <Icon viewBox="0 0 12 12" boxSize="12px" {...props}>
    <circle cx="6" cy="6" r="6" fill="#D9D9D9" />
  </Icon>
)

type Props = {
  currentStep: number
  progressPercent?: number
  totalSteps: number
}

export default function ProgressBarWithStepper({
  currentStep: currentStep_,
  progressPercent: progressPercent_,
  totalSteps,
}: Props) {
  const currentStep = Math.max(currentStep_ - 1, 0)
  const progressPercent = progressPercent_
    ? progressPercent_
    : (currentStep / (totalSteps - 1)) * 100

  const steps = new Array(totalSteps).fill(0)

  return (
    <Box position="relative">
      {/* @ts-ignore */}
      <Stepper size="sm" index={currentStep} gap="0" variant="progress">
        {steps.map((step, index) => (
          // @ts-ignore
          <Step key={index} gap="0">
            <StepIndicator>
              <StepStatus
                complete={<LeftProgressIcon />}
                incomplete={<RightProgressIcon />}
                active={
                  currentStep_ <= 0 ? (
                    <RightProgressIcon />
                  ) : (
                    <LeftProgressIcon />
                  )
                }
              />
            </StepIndicator>
          </Step>
        ))}
      </Stepper>
      <Progress
        variant="progress"
        value={progressPercent}
        position="absolute"
        height="2px"
        w="full"
        top="10px"
        zIndex={-1}
      />
    </Box>
  )
}
