import {
  Heading,
  ModalBody as CModalBody,
  Text,
  VStack,
  Button,
  Flex,
  Divider,
} from '@chakra-ui/react'

export const ModalHeading = () => (
  <VStack align="left" mx={7} gap={0}>
    <Heading
      variant="heading/h1"
      fontWeight={600}
      lineHeight={'32px'}
      color="blue.9"
      as="h1"
    >
      Switch profile?
    </Heading>
  </VStack>
)

type BodyProps = {
  accountName: string
  onSwitchProfile: () => void
  onClose: () => void
}

export const ModalBody = ({
  accountName,
  onSwitchProfile,
  onClose,
}: BodyProps) => {
  return (
    <CModalBody pt={0}>
      <VStack gap={2}>
        <Text variant="body-md/normal" color="blue.9" fontWeight={400}>
          A profile exists for this wallet. Would you like to switch?
        </Text>
        <Button w="full" variant="secondary" onClick={onSwitchProfile}>
          Yes, switch profile
        </Button>
      </VStack>
      <OrDivider />
      <Button w="full" variant="tertiary" onClick={onClose}>
        Stay logged in as {accountName}
      </Button>
    </CModalBody>
  )
}

const OrDivider = () => (
  <Flex align="center" my={4} style={{ color: '#667085' }}>
    <Divider style={{ borderColor: '#BCBCBC' }} />
    <Text variant="body-sm/normal" fontWeight={400} padding="2">
      OR
    </Text>
    <Divider style={{ borderColor: '#BCBCBC' }} />
  </Flex>
)
