import { Box, Button, HStack, Image, Text, VStack } from '@chakra-ui/react'
import closeIcon from '../../../../assets/icons/close.svg'
import { LockIcon } from '../../../../components/elements/Icons'
import { shortenAddress } from '../../../../helpers/utils'

type HeaderProps = {
  connectedWallet?: string
  onClose: () => void
}

export const ModalHeader = ({ connectedWallet, onClose }: HeaderProps) => {
  return (
    <VStack align="left" gap={'0'}>
      <Box position="relative" mb={6}>
        <HStack gap={1.5}>
          <LockIcon w="11px" />
          <Text
            variant="body-xs/medium"
            fontStyle="normal"
            lineHeight="20px"
            color="gray.5"
          >
            No eligible bonus
          </Text>
        </HStack>
        <Text
          variant="body-2xl/medium"
          fontStyle="normal"
          lineHeight="24px"
          color="blue.9"
          mt="5px"
        >
          Your wallet is not eligible
        </Text>
        <Box position="absolute" right={'4px'} bottom={'2px'}>
          <Image
            src={closeIcon}
            boxSize="16px"
            cursor="pointer"
            onClick={onClose}
          />
        </Box>
      </Box>
      <Text
        variant="body-sm/normal"
        fontStyle="normal"
        fontWeight={400}
        color="blue.9"
        opacity={0.7}
        mb={6}
      >
        It looks like your connected wallet{' '}
        <Text as="span" fontWeight={700}>
          {connectedWallet ? shortenAddress(connectedWallet) : ''}
        </Text>{' '}
        has not been active on other DeFi lending protocols.
      </Text>
      <Text
        variant="body-sm/normal"
        fontStyle="normal"
        fontWeight={400}
        color="blue.9"
        opacity={0.7}
      >
        If you have another active wallet, you can try that instead.
      </Text>
    </VStack>
  )
}

export const ModalFooter = ({ onClose }: { onClose: () => void }) => (
  <Box w="full" mt={6}>
    <Button w="full" variant="secondary" onClick={onClose}>
      Close
    </Button>
  </Box>
)
