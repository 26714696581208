import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import CollapseBorrowDetail from './CollapseBorrowDetail'
import CollapseBorrowButton from './CollapseBorrowButton'
import BurnTokenField from './BurnTokenField'
import { FixedNumber } from 'ethers'
import { useCallback, useMemo, useState } from 'react'
import { Currency } from '../../../../data/model'
import { formatFixedToken } from '../../../../helpers/utils'
import { GAS_TOKEN_SYMBOL } from '../../../../helpers/constants'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { evaluate } from '../../../../helpers/math'
import { multiply, divide, fixedCompare } from '../../../../helpers/math'
import { Trans } from '@lingui/macro'

const zero = FixedNumber.fromString('0')

function CollapseBorrowHelperText({
  isMoreThanAllowed,
  amountInPurchaseCurrency,
  purchaseTokenSymbol,
  newDebtAfterCollapse,
  termTokenBalanceAfter,
}: {
  isMoreThanAllowed: boolean
  amountInPurchaseCurrency: FixedNumber
  purchaseTokenSymbol: string
  newDebtAfterCollapse: FixedNumber
  termTokenBalanceAfter: FixedNumber
}) {
  if (isMoreThanAllowed) {
    return (
      <Text color="red">
        <Trans>Amount input exceeds your collapsible debt</Trans>
      </Text>
    )
  }

  if (termTokenBalanceAfter.isNegative()) {
    return (
      <Text color="red">
        <Trans>Insufficient Repo Tokens in connected wallet</Trans>
      </Text>
    )
  }

  if (newDebtAfterCollapse.isNegative()) {
    return (
      <Text color="red">
        <Trans>Cannot collapse beyond remaining debt</Trans>
      </Text>
    )
  }

  return (
    <p>
      {!amountInPurchaseCurrency.isZero()
        ? formatFixedToken(
            amountInPurchaseCurrency,
            purchaseTokenSymbol,
            false,
            true
          )
        : ''}
    </p>
  )
}

function CollapseBorrowModal({
  outstandingDebt,
  rolloverAmount,
  maturityDate,
  purchaseCurrency,
  purchasePrice,
  termTokenBalance,
  termTokenPrice,
  termTokenCurrency,
  defaultBurnAmountRaw,
  isOpen,
  onCollapse,
  onClose,
  auctionLabel,
}: {
  outstandingDebt: FixedNumber
  rolloverAmount: FixedNumber | undefined
  maturityDate: number
  purchaseCurrency: Currency
  purchasePrice: FixedNumber
  termTokenBalance: FixedNumber
  termTokenPrice: FixedNumber
  termTokenCurrency: Currency
  defaultBurnAmountRaw?: string
  isOpen: boolean
  onCollapse: (
    termTokensToCollapse: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>
  onClose: () => void
  auctionLabel: string
}) {
  const termToast = useTermToast()

  const [tokensToBurnRaw, setTokensToBurnRaw] = useState(
    defaultBurnAmountRaw || ''
  )
  const [tokenSymbol, setTokenSymbol] = useState(termTokenCurrency?.symbol)
  const [isCollapsing, setIsCollapsing] = useState(false)

  // convert repo token input to FixedNumber
  const tokensToBurnFn = FixedNumber.fromString(
    tokensToBurnRaw || '0',
    termTokenBalance.format
  )

  // get USD value of repo tokens
  const tokensToBurnUsd = termTokenPrice
    ? evaluate(
        {
          nodeKind: 'mul',
          args: [
            {
              nodeKind: 'value',
              value: tokensToBurnFn,
            },
            {
              nodeKind: 'value',
              value: termTokenPrice,
            },
          ],
        },
        termTokenPrice.format.decimals
      )
    : zero

  // convert USD value of repo tokens to purchase currency
  const tokensToBurnPurchaseCurrency =
    termTokenPrice && purchasePrice
      ? evaluate(
          {
            nodeKind: 'div',
            args: [
              {
                nodeKind: 'value',
                value: tokensToBurnUsd,
              },
              {
                nodeKind: 'value',
                value: purchasePrice,
              },
            ],
          },
          purchaseCurrency.decimals
        )
      : zero

  // maximum amount collapsible in purchase currency
  const maxCollapseAmount = useMemo(
    () =>
      evaluate({
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: outstandingDebt,
          },
          {
            nodeKind: 'value',
            value: rolloverAmount || zero,
          },
        ],
      }),
    [outstandingDebt, rolloverAmount]
  )

  const isMoreThanAllowed = useMemo(
    () =>
      evaluate({
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: maxCollapseAmount,
          },
          {
            nodeKind: 'value',
            value: tokensToBurnPurchaseCurrency,
          },
        ],
      }).isNegative(),
    [maxCollapseAmount, tokensToBurnPurchaseCurrency]
  )

  const maxInputAmount: FixedNumber = useMemo(() => {
    const maxCollapseUsd = multiply(maxCollapseAmount, purchasePrice)
    const maxCollapseRepoTokens = divide(
      maxCollapseUsd,
      termTokenPrice,
      termTokenBalance.format.decimals
    )

    if (fixedCompare(maxCollapseRepoTokens, 'gte', termTokenBalance)) {
      return termTokenBalance
    } else {
      return maxCollapseRepoTokens
    }
  }, [
    maxCollapseAmount,
    termTokenPrice,
    termTokenBalance,
    termTokenBalance?.format?.decimals,
  ])

  const termTokenBalanceAfterBurnFn = evaluate(
    {
      nodeKind: 'sub',
      args: [
        {
          nodeKind: 'value',
          value: termTokenBalance,
        },
        {
          nodeKind: 'value',
          value: tokensToBurnFn,
        },
      ],
    },
    termTokenBalance.format.decimals
  )

  const newDebtAfterCollapse = evaluate(
    {
      nodeKind: 'sub',
      args: [
        {
          nodeKind: 'value',
          value: outstandingDebt,
        },
        {
          nodeKind: 'value',
          value: tokensToBurnPurchaseCurrency,
        },
      ],
    },
    outstandingDebt.format.decimals
  )

  const hasError = useMemo(() => {
    return (
      isMoreThanAllowed ||
      termTokenBalanceAfterBurnFn.isNegative() ||
      newDebtAfterCollapse.isNegative()
    )
  }, [isMoreThanAllowed, termTokenBalanceAfterBurnFn, newDebtAfterCollapse])

  const onCloseModal = useCallback(() => {
    setTokensToBurnRaw('')
    setTokenSymbol(termTokenCurrency?.symbol)
    setIsCollapsing(false)
    onClose()
  }, [onClose, termTokenCurrency?.symbol])

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} variant="standard">
      <ModalOverlay />
      <ModalContent p="4px">
        <ModalHeader
          color="gray.900"
          fontSize="lg"
          fontWeight="700"
          lineHeight="7"
        >
          <Trans>Collapse Borrow</Trans>
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody px={7} pb={7}>
          <VStack spacing="12px" alignItems="stretch" mb={5}>
            <Flex>
              <Box>
                <Flex alignItems="center">
                  <Text
                    mb="10px"
                    color="gray.700"
                    fontSize="sm"
                    fontWeight="400"
                    lineHeight="5"
                  >
                    <Trans>
                      Looking to shorten your term? Collapse your outstanding
                      debt before maturity by burning the associated Repo
                      Tokens.
                    </Trans>
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <CollapseBorrowDetail
              outstandingDebt={outstandingDebt}
              collapseAmount={tokensToBurnPurchaseCurrency ?? zero}
              rolloverAmount={rolloverAmount ?? zero}
              maturityDate={maturityDate}
              auctionLabel={auctionLabel}
              purchaseTokenSymbol={purchaseCurrency.symbol}
              hasError={hasError}
            />
            <Flex>
              <BurnTokenField
                // label="Repo Tokens (to burn)"
                value={tokensToBurnRaw}
                onChange={setTokensToBurnRaw}
                onChangeToken={setTokenSymbol}
                termTokenBalance={termTokenBalance}
                purchaseTokenSymbol={purchaseCurrency.symbol}
                termTokenSymbol={tokenSymbol}
                termTokenDecimals={termTokenCurrency?.decimals}
                maxInputAmount={maxInputAmount}
                isDisabled={
                  rolloverAmount
                    ? evaluate({
                        nodeKind: 'sub',
                        args: [
                          {
                            nodeKind: 'value',
                            value: outstandingDebt,
                          },
                          {
                            nodeKind: 'value',
                            value: rolloverAmount,
                          },
                        ],
                      }).isNegative()
                    : false
                }
                isError={isMoreThanAllowed}
                helperText={
                  <CollapseBorrowHelperText
                    isMoreThanAllowed={isMoreThanAllowed}
                    amountInPurchaseCurrency={tokensToBurnPurchaseCurrency}
                    purchaseTokenSymbol={purchaseCurrency.symbol}
                    newDebtAfterCollapse={newDebtAfterCollapse ?? zero}
                    termTokenBalanceAfter={termTokenBalanceAfterBurnFn}
                  />
                }
              />
            </Flex>
            {/* Take me to Balancer box */}
            {/* <Flex>
              <AlertSection
                alertStatus={alertInfo.status}
                alertDescription={alertInfo.description}
              />
            </Flex> */}
          </VStack>
          <CollapseBorrowButton
            isDisabled={
              isCollapsing ||
              tokensToBurnFn.isZero() ||
              evaluate({
                nodeKind: 'sub',
                args: [
                  {
                    nodeKind: 'value',
                    value: termTokenBalance,
                  },
                  {
                    nodeKind: 'value',
                    value: tokensToBurnFn,
                  },
                ],
              }).isNegative()
            }
            onClick={async () => {
              console.info(
                `Attempting to collapse debt with ${tokensToBurnFn.toString()} repo token(s)...`
              )
              const collapsingPromise = onCollapse(
                tokensToBurnFn,
                tokenSymbol === GAS_TOKEN_SYMBOL
              )
              setIsCollapsing(true)
              termToast.pending(termToastMessages.collapse.pending)
              try {
                await collapsingPromise
                termToast.success(termToastMessages.collapse.success)
                console.info(
                  `Collapsed ${tokensToBurnFn.toString()} repo token(s)`
                )
                onCloseModal()
              } catch (error) {
                setIsCollapsing(false)
                console.error(
                  `Unable to collapse ${tokensToBurnFn.toString()} repo token(s)`
                )
                if ((error as Error).message.includes('code=ACTION_REJECTED')) {
                  termToast.dismissed()
                } else {
                  termToast.failure(termToastMessages.collapse.failure)
                }
              }
            }}
            isLoading={isCollapsing}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CollapseBorrowModal
