import { FixedNumber } from 'ethers'
import { fixedToBigNumber } from './conversions'

export type ExpressionAdd = {
  nodeKind: 'add'
  args: Expression[]
}

export type ExpressionSub = {
  nodeKind: 'sub'
  args: Expression[]
}

export type ExpressionMul = {
  nodeKind: 'mul'
  args: Expression[]
}

export type ExpressionDiv = {
  nodeKind: 'div'
  args: Expression[]
}

export type ExpressionValue = {
  nodeKind: 'value'
  value: FixedNumber
}

export type Expression =
  | ExpressionAdd
  | ExpressionSub
  | ExpressionMul
  | ExpressionDiv
  | ExpressionValue

export type Comparison = 'gt' | 'gte' | 'lt' | 'lte' | 'eq'

export const fixedCompare = (
  a: FixedNumber,
  t: Comparison,
  b: FixedNumber
): boolean => {
  // typescript catches this but is not enforced
  if (!a || !b) {
    return false
  }
  const aBn = fixedToBigNumber(a)
  const bBn = fixedToBigNumber(b)
  return aBn[t](bBn)
}

export const add = (
  a: FixedNumber,
  b: FixedNumber,
  decimals?: number
): FixedNumber => {
  return evaluate(
    {
      nodeKind: 'add',
      args: [
        { nodeKind: 'value', value: a },
        { nodeKind: 'value', value: b },
      ],
    },
    decimals
  )
}

export const subtract = (
  a: FixedNumber,
  b: FixedNumber,
  decimals?: number
): FixedNumber => {
  return evaluate(
    {
      nodeKind: 'sub',
      args: [
        { nodeKind: 'value', value: a },
        { nodeKind: 'value', value: b },
      ],
    },
    decimals
  )
}

export const multiply = (
  a: FixedNumber,
  b: FixedNumber,
  decimals?: number
): FixedNumber => {
  return evaluate(
    {
      nodeKind: 'mul',
      args: [
        { nodeKind: 'value', value: a },
        { nodeKind: 'value', value: b },
      ],
    },
    decimals
  )
}

export const divide = (
  dividend: FixedNumber,
  divisor: FixedNumber,
  decimals?: number
): FixedNumber => {
  return evaluate(
    {
      nodeKind: 'div',
      args: [
        { nodeKind: 'value', value: dividend },
        { nodeKind: 'value', value: divisor },
      ],
    },
    decimals
  )
}

/**
 * This function evaluates an expression recursively and returns the result as a FixedNumber
 * @param expression input expression to be evaluated
 * @param desiredOutputDecimals optional, specify the desired number of output decimals - will round if value is less than decimals in expression result
 * @returns
 */
export const evaluate = (
  expression: Expression,
  desiredOutputDecimals?: number
) => {
  const decimals = internalDecimals(expression)
  let result = evaluateHelper(expression, decimals, desiredOutputDecimals)
  if (desiredOutputDecimals && desiredOutputDecimals < decimals) {
    result = result
      .round(desiredOutputDecimals)
      .toFormat(`fixed128x${desiredOutputDecimals}`)
  }
  return result
}

/**
 * Generates a human-readable representation of an expression for debugging purposes
 * @param expression input expression to be formatted
 * @param desiredOutputDecimals desired number of output decimals - will round if value is less than decimals in expression
 * @returns
 */
export const formatHelper = (
  expression: Expression,
  desiredOutputDecimals?: number
): string => {
  switch (expression.nodeKind) {
    case 'value': {
      if (
        desiredOutputDecimals &&
        expression.value.format.decimals < desiredOutputDecimals
      ) {
        return expression.value
          .toFormat(`fixed128x${desiredOutputDecimals}`)
          .toString()
      }
      return expression.value.toString()
    }
    case 'add': {
      const childValues = expression.args.map((e) => {
        return formatHelper(e, desiredOutputDecimals)
      })
      return `(${childValues.join(' + ')})`
    }
    case 'sub': {
      const childValues = expression.args.map((e) => {
        return formatHelper(e, desiredOutputDecimals)
      })
      return `(${childValues.join(' - ')})`
    }
    case 'mul': {
      const childValues = expression.args.map((e) => {
        return formatHelper(e, desiredOutputDecimals)
      })
      return `(${childValues.join(' * ')})`
    }
    case 'div': {
      const childValues = expression.args.map((e) => {
        return formatHelper(e, desiredOutputDecimals)
      })
      return `(${childValues.join(' / ')})`
    }
    default: {
      throw new Error(
        `Unsupported expression node kind: ${JSON.stringify(
          expression,
          null,
          2
        )}`
      )
    }
  }
}

/**
 * Upscales decimal places of every element in the expression to the maximum number of decimal places for calculation purposes
 * @param expression input expression to be evaluated
 * @returns largest decimal value in the expression
 */
const internalDecimals = (expression: Expression): number => {
  switch (expression.nodeKind) {
    case 'value': {
      return expression.value.format.decimals
    }
    case 'add':
    case 'sub':
    case 'mul':
    case 'div': {
      const childValues = expression.args.map((e) => {
        return internalDecimals(e)
      })
      return Math.max(...childValues)
    }
    default: {
      throw new Error(
        `Unsupported expression node kind: ${JSON.stringify(
          expression,
          null,
          2
        )}`
      )
    }
  }
}

/**
 * Evaluates an expression recursively based on operation type (nodeKind) and returns the result as a FixedNumber
 * @param expression input expression to be evaluated
 * @param decimals upscaled decimals
 * @param desiredOutputDecimals desired output, ignored if decimals > desiredOutputDecimals
 * @returns expression calculation result
 */
const evaluateHelper = (
  expression: Expression,
  decimals: number,
  desiredOutputDecimals?: number
): FixedNumber => {
  switch (expression.nodeKind) {
    case 'value':
      let formatDecimals = decimals
      if (desiredOutputDecimals) {
        formatDecimals = Math.max(desiredOutputDecimals, decimals)
      }
      if (expression.value.format.decimals < formatDecimals) {
        return expression.value.toFormat(`fixed128x${formatDecimals}`)
      } else {
        return expression.value
      }
    case 'add':
      return expression.args.reduce(
        (fn, e) => {
          return fn.addUnsafe(
            evaluateHelper(e, decimals, desiredOutputDecimals)
          )
        },
        FixedNumber.from(0, Math.max(desiredOutputDecimals || 0, decimals))
      )
    case 'sub':
      const [head, ...tail] = expression.args
      return tail.reduce(
        (fn, e) => {
          return fn.subUnsafe(
            evaluateHelper(e, decimals, desiredOutputDecimals)
          )
        },
        evaluateHelper(head, decimals, desiredOutputDecimals)
      )
    case 'mul':
      return expression.args.reduce(
        (fn, e) => {
          return fn.mulUnsafe(
            evaluateHelper(e, decimals, desiredOutputDecimals)
          )
        },
        FixedNumber.from(1, Math.max(desiredOutputDecimals || 0, decimals))
      )
    case 'div':
      const [h, ...t] = expression.args
      return t.reduce(
        (fn, e) => {
          return fn.divUnsafe(
            evaluateHelper(e, decimals, desiredOutputDecimals)
          )
        },
        evaluateHelper(h, decimals, desiredOutputDecimals)
      )
    default: {
      throw new Error(
        `Unsupported expression node kind: ${JSON.stringify(
          expression,
          null,
          2
        )}`
      )
    }
  }
}
