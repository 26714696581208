import { Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

const AuctionBoxText = ({ children }: { children: ReactNode }) => {
  return (
    <Text variant="body-md/normal" color="blue.9" whiteSpace="nowrap">
      {children}
    </Text>
  )
}

export default AuctionBoxText
