import { graphql } from '../gql'

export const PageAuctionsDocument = graphql(`
  query PageAuctions($currentTime: BigInt!, $lastAuctionCutOff: BigInt!) {
    termAuctions(
      first: 1000
      where: { delisted: false, auctionEndTime_gt: $lastAuctionCutOff }
    ) {
      id
      term {
        id
        repurchaseTimestamp
        servicingFee

        purchaseToken
        purchaseTokenMeta {
          id
          decimals
          symbol
          name
        }
        collateralTokens
        collateralTokensMeta {
          id
          decimals
          symbol
          name
        }

        collateralRatios {
          maintenanceRatio
          initialRatio
        }

        liquidatedDamagesSchedule {
          collateralToken
          liquidatedDamages
        }

        termRepoServicer
        termRepoCollateralManager
        termRepoLocker
        termRepoRolloverManager
        termRepoToken
      }
      auction
      auctionBidLocker
      auctionOfferLocker
      auctionCancelled
      auctionCancelledForWithdrawal
      auctionComplete
      auctionClearingPrice
      auctionStartTime
      revealTime
      auctionEndTime
      dayCountFractionMantissa
      auctionMaxBidPrice
      auctionMinBidAmount
      auctionMaxOfferPrice
      auctionMinOfferAmount
      version
    }
    termBids(
      where: {
        auction_: {
          delisted: false
          auctionCancelled: false
          auctionCancelledForWithdrawal: false
          auctionComplete: false
          auctionStartTime_lt: $currentTime
          auctionEndTime_gt: $currentTime
        }
        locked: true
      }
    ) {
      id
      amount
      bidder
      auction {
        id
        auction
      }
    }
    termOffers(
      where: {
        auction_: {
          delisted: false
          auctionCancelled: false
          auctionCancelledForWithdrawal: false
          auctionComplete: false
          auctionStartTime_lt: $currentTime
          auctionEndTime_gt: $currentTime
        }
        locked: true
      }
    ) {
      id
      amount
      offeror
      auction {
        id
        auction
      }
    }
  }
`)
