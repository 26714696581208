import { Button, ButtonProps, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export default function CancelListingButton({
  isCancelAndRedeem,
  onClick,
  isDisabled,
  isLoading,
}: ButtonProps & { isCancelAndRedeem: boolean }) {
  return (
    <Button
      mt={5}
      onClick={onClick}
      isDisabled={isDisabled}
      variant="secondary"
      isLoading={isLoading}
      size="sm"
      w="full"
    >
      <Text as="span" variant="body-sm/semibold">
        {isCancelAndRedeem ? (
          <Text>Cancel and redeem</Text>
        ) : (
          <Trans>Cancel</Trans>
        )}
      </Text>
    </Button>
  )
}
