import { Box, Text } from '@chakra-ui/react'

export default function BorrowerRolloverInfoBox({
  kind,
  children,
}: {
  kind: 'info' | 'warning' | 'error'
  children?: React.ReactNode
}) {
  const backgroundColor = (() => {
    switch (kind) {
      case 'info':
        return 'gray.1'
      case 'error':
        return 'red.2'
    }
  })()
  const borderColor = (() => {
    switch (kind) {
      case 'info':
        return 'green.5'
      case 'error':
        return 'red.5'
    }
  })()

  return (
    <Box
      padding="8px"
      pl="16px"
      borderTopRightRadius="md"
      borderBottomRightRadius="md"
      borderLeftWidth="2px"
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <Text color="blue.9" variant="body-sm/normal">
        {children}
      </Text>
    </Box>
  )
}
