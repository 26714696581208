import { Text } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import dayjs from 'dayjs'
import { formatFixedToken } from '../../../../helpers/utils'
import { PartialAuctionInfo } from '../../utils'
import Tooltip from '../../../../components/elements/Tooltip'
import { fixedToFormattedPercentage } from '../../../../helpers/conversions'
import { FixedNumber } from 'ethers'
import { multiply } from '../../../../helpers/math'
import { Fragment } from 'react'
import { Trans } from '@lingui/macro'

export default function MultipleAuctionsTooltip({
  auctionInfo,
  purchaseTokenSymbol,
  position,
  children,
}: {
  auctionInfo: PartialAuctionInfo[]
  purchaseTokenSymbol: string
  position: 'borrow' | 'supply'
  children?: React.ReactNode
}) {
  return (
    <Tooltip
      noDelay
      hasArrow
      bg="blue.900"
      color="white"
      minW="160px"
      aria-label="wrapped-unwrapped-gas-token-balances"
      label={
        <VStack alignItems="flex-start" w="full" color="white">
          {/* Iterate over number of auctions */}
          {auctionInfo.map((auction, i) => (
            <Fragment key={auction.auctionId}>
              <HStack
                key={i + '-label'}
                justifyContent="space-between"
                w="full"
                mb={0}
                // mt="0 !important"
              >
                {auction?.auctionEndTimestamp && (
                  <Text as="span" variant="body-sm/bold" color="white">
                    <Trans> Closed </Trans>{' '}
                    {dayjs.unix(auction.auctionEndTimestamp).format('MMM D')}:
                  </Text>
                )}
              </HStack>
              <HStack
                key={i + '-initial'}
                justifyContent="space-between"
                w="full"
                mb={0}
                mt="0 !important"
              >
                <Text as="span" variant="body-sm/normal" color="gray.5">
                  <Trans>
                    Initial {position === 'borrow' ? 'Borrow' : 'Loan'}:
                  </Trans>
                </Text>
                <Text as="span" variant="body-sm/medium" color="white">
                  {formatFixedToken(
                    auction.loanPrincipal,
                    purchaseTokenSymbol,
                    true,
                    true
                  )}
                </Text>
              </HStack>
              <HStack
                key={i + '-interest'}
                justifyContent="space-between"
                w="full"
                mb={0}
                mt="0 !important"
              >
                <Text as="span" variant="body-sm/medium" color="gray.5">
                  <Trans>Interest:</Trans>
                </Text>
                <Text as="span" variant="body-sm/medium" color="white">
                  {fixedToFormattedPercentage(
                    multiply(
                      auction.auctionClearingRate ??
                        FixedNumber.fromString('0', 18),
                      FixedNumber.fromString('100', 18),
                      auction.auctionClearingRate?.format.decimals ?? 18
                    )
                  ).formattedPercentage +
                    ' / ' +
                    formatFixedToken(
                      auction.loanInterest,
                      purchaseTokenSymbol,
                      true,
                      true
                    )}
                </Text>
              </HStack>
              {i < auctionInfo.length - 1 && <hr />}
            </Fragment>
          ))}
        </VStack>
      }
    >
      {children}
    </Tooltip>
  )
}
