import dayjs, { Dayjs } from 'dayjs'
import { createContext, ReactNode, useContext } from 'react'

export interface Clock {
  now: () => Dayjs
}

const defaultClock: Clock = { now: () => dayjs() }

export const TimeContext = createContext<Clock>(defaultClock)

export function TimeProvider({
  clock,
  children,
}: {
  clock?: Clock
  children: ReactNode
}) {
  return (
    <TimeContext.Provider value={clock ?? defaultClock}>
      {children}
    </TimeContext.Provider>
  )
}

export function useClock(): Clock {
  return useContext(TimeContext)
}
