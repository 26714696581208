import { Box, Flex, Image } from '@chakra-ui/react'
import { ChainId } from '@usedapp/core'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { NavLinks } from './NavLinks'
import { RightNav } from './RightNav'
import { Wallet } from '../../../data/model'
import term_logo from '../../../assets/termlogo.svg'
import { HStack } from '../../elements/Stack'

export default function Navbar({
  wallet,
  account,
  onConnect,
  onSwitchNetworkModalOpen,
  onClaim,
  onDisconnect,
  onLogoutProfile,
  onManageProfile,
  hasNotification,
  chainId,
}: {
  wallet: Wallet
  chainId: ChainId | undefined
  account: string | undefined
  onConnect: () => void
  onSwitchNetworkModalOpen: () => void
  onClaim: () => void
  onDisconnect: () => void
  onLogoutProfile?: () => void
  onManageProfile: () => void
  hasNotification?: boolean
}) {
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  return (
    <>
      <Box
        bg="gray.0"
        px={'20px'}
        borderBottom={{ md: '1px solid' }}
        borderColor={{ md: 'gray.2' }}
        position="relative"
        zIndex={50}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <HStack spacing={8} alignItems="center">
            <Box color="white" fontWeight="700" fontSize="2xl" py="5px">
              <RouterLink to={`/?${search.toString()}`}>
                <Image src={term_logo} w="109px" h="50px" />
              </RouterLink>
            </Box>
          </HStack>
          <Flex alignItems="center">
            <Box display={{ base: 'none', lg: 'block' }}>
              <NavLinks location={location} search={search} />
            </Box>
            <RightNav
              wallet={wallet}
              chainId={chainId}
              onConnectModalOpen={onConnect}
              onSwitchNetworkModalOpen={onSwitchNetworkModalOpen}
              account={account}
              onClaimTestTokens={onClaim}
              onDisconnect={onDisconnect}
              onLogoutProfile={onLogoutProfile}
              onManageProfile={onManageProfile}
              hasNotification={hasNotification}
            />
          </Flex>
        </Flex>
      </Box>
      <Box
        display={{ base: 'flex', lg: 'none' }}
        alignItems="center"
        height="60px"
        bg="gray.0"
        px="20px"
        borderBottom="1px solid"
        borderColor="gray.2"
      >
        <NavLinks location={location} search={search} />
      </Box>
    </>
  )
}
