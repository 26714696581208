import { Box, Text } from '@chakra-ui/react'

export default function SummaryTitle({ children }: { children: string }) {
  return (
    <Box px={4} py={2} borderBottomWidth="1px" borderColor="gray.2" w="full">
      <Text as="h3" variant="body-sm/semibold" color="gray.6">
        {children}
      </Text>
    </Box>
  )
}
