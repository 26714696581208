import { Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

const RepoCardText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      color="gray.6"
      lineHeight={5}
      whiteSpace="nowrap"
      fontSize={{
        base: '11px',
        '1xl': 'sm',
      }}
    >
      {children}
    </Text>
  )
}

export default RepoCardText
