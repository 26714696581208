import { Button, Image, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import dayjs from 'dayjs'
import error_icon from '../../../../assets/icons/error.svg'
import Tooltip from '../../../../components/elements/Tooltip'
import {
  LoanStatusEnum,
  LoanStatus as LoanStatusType,
} from '../../../../data/model'
import { Trans, t } from '@lingui/macro'

export default function LoanStatus({
  status,
  repaymentTimestamp,
  onViewLiquidationDetailsClick,
  addPaddingBottom,
  defaultLiquidation,
}: {
  status: LoanStatusType
  repaymentTimestamp?: number
  onViewLiquidationDetailsClick?: () => void
  addPaddingBottom?: boolean
  defaultLiquidation?: boolean
}) {
  const STYLES: Record<
    LoanStatusType,
    {
      color: string
      bgColor: string
    }
  > = {
    open: {
      color: 'blue.5',
      bgColor: 'blue.1',
    },
    matured: {
      color: 'orange.500',
      bgColor: 'orange.100',
    },
    liquidatedAndClosed: {
      color: 'red.5',
      bgColor: 'red.2',
    },
    liquidatedAndOpen: {
      color: 'blue.5',
      bgColor: 'blue.1',
    },
    partLiquidatedAndMatured: {
      color: 'orange.500',
      bgColor: 'orange.100',
    },
  }

  const STATUS_TEXT: Record<LoanStatusType, string> = {
    open: t`Open`,
    matured: t`Matured`,
    liquidatedAndClosed: t`Liquidated`,
    liquidatedAndOpen: t`Open`,
    partLiquidatedAndMatured: t`Matured`,
  }

  return (
    <Tooltip
      shouldWrapChildren
      hidden={
        status === LoanStatusEnum.Open || status === LoanStatusEnum.Matured
      }
      label={
        <>
          {status === LoanStatusEnum.LiquidatedAndClosed && (
            <>
              {defaultLiquidation
                ? t`Your loan was liquidated because it was not repaid before the repayment window closed on ${dayjs
                    .unix(repaymentTimestamp ?? 0)
                    .format('MMM D, ha')}.`
                : t`Your collateral fell below the minimum requirement.`}
              <Button
                variant="link"
                onClick={onViewLiquidationDetailsClick}
                color="blue.5"
              >
                <Trans>View details</Trans>
              </Button>
            </>
          )}
          {(status === LoanStatusEnum.LiquidatedAndOpen ||
            status === LoanStatusEnum.PartLiquidatedAndMatured) && (
            <>
              <Trans>
                Your loan was liquidated because your deposited collateral fell
                below the minimum requirement.
              </Trans>{' '}
              <Button
                variant="link"
                onClick={onViewLiquidationDetailsClick}
                color="blue.5"
              >
                <Trans>View details</Trans>
              </Button>
            </>
          )}
        </>
      }
      aria-label="liquidation-tooltip"
    >
      <HStack pb={addPaddingBottom ? '4px' : '0'}>
        <Text
          as="span"
          variant="body-xs/medium"
          color={STYLES[status].color}
          textTransform="capitalize"
        >
          {STATUS_TEXT[status]}
        </Text>
        {(status === LoanStatusEnum.LiquidatedAndOpen ||
          status === LoanStatusEnum.PartLiquidatedAndMatured) && (
          <Image w={4} h={4} src={error_icon} alt="error" />
        )}
      </HStack>
    </Tooltip>
  )
}
