import { BoxProps, Text } from '@chakra-ui/react'
import OverflowBox from '../OverflowBox'

export default function StatsBeforeAfter({
  isRed,
  isBeforeBold = false,
  isAfterBold = true,
  before,
  after,
  ...props
}: BoxProps & {
  isRed?: boolean
  isBeforeBold?: boolean
  isAfterBold?: boolean
  before?: React.ReactNode
  after?: React.ReactNode
}) {
  const color = isRed ? 'red.5' : 'blue.9'
  const beforeColor = isRed ? 'red.5' : isBeforeBold ? 'blue.9' : 'gray.4'
  return (
    <OverflowBox
      color={color}
      whiteSpace="nowrap"
      columnGap={1}
      maxW={props.maxW ?? '240px'}
      {...props}
      mt="0 !important"
    >
      <Text
        as="span"
        color={beforeColor}
        variant={isBeforeBold ? 'body-sm/semibold' : 'body-sm/normal'}
      >
        {before} -&gt;{' '}
      </Text>
      <Text
        as="span"
        color={color}
        variant={isAfterBold ? 'body-sm/semibold' : 'body-sm/normal'}
      >
        {after}
      </Text>
    </OverflowBox>
  )
}
