import { Box, keyframes, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { formatFixedUsd } from '../../../../helpers/utils'
import { FixedNumber } from 'ethers'
import { Trans } from '@lingui/macro'

export default function AuctionActivityBox({
  offersCount,
  offersAmount,
  bidsCount,
  bidsAmount,
}: {
  offersCount: number
  offersAmount: FixedNumber
  bidsCount: number
  bidsAmount: FixedNumber
}) {
  return (
    <HStack
      bg="blue.0"
      p={2.5}
      borderRadius="md"
      justifyContent="space-between"
      w="100%"
      mt={2}
    >
      <Text variant="body-xs/medium">
        <Text color="gray.6" as="span" fontWeight={400}>
          <Trans>Offers</Trans> ({offersCount}):
        </Text>
        {'  '}
        <Box
          animation={`${pulse} 2400ms infinite`}
          _hover={{
            cursor: 'pointer',
          }}
          as="span"
        >
          <Trans>{formatFixedUsd(offersAmount)}</Trans>
        </Box>
      </Text>
      <Text variant="body-xs/medium">
        <Text color="gray.6" as="span" fontWeight={400}>
          <Trans>Bids</Trans> ({bidsCount}):
        </Text>
        {'  '}
        <Box
          animation={`${pulse} 2400ms infinite`}
          _hover={{
            cursor: 'pointer',
          }}
          as="span"
        >
          <Trans>{formatFixedUsd(bidsAmount)}</Trans>
        </Box>
      </Text>
    </HStack>
  )
}

const pulse = keyframes({
  '0%': {
    opacity: 0.4,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.4,
  },
})
