import { Button, HStack, Text } from '@chakra-ui/react'
import ThemedIcon from '../../../../components/elements/ThemedIcon'

const RepoCardButton = ({
  isActive = false,
  isHovered = false,
  isDisabled = false,
  children,
  handleClick,
}: {
  isActive?: boolean
  isHovered?: boolean
  isDisabled?: boolean
  children?: React.ReactNode
  handleClick: () => void
}) => {
  return (
    <Button
      size="md"
      alignSelf="flex-end"
      w={{
        base: '113px',
      }}
      isDisabled={isDisabled}
      fontSize="16px"
      outline="none"
      onClick={() => handleClick()}
      variant={isActive ? 'tertiary' : 'secondary'}
      color={isActive ? 'blue.5' : isHovered ? 'white' : 'blue.8'}
      bg={isActive ? 'transparent' : isHovered ? 'blue.5' : 'blue.2'}
      _hover={{
        bg: 'blue.5',
        color: 'white',
      }}
    >
      <HStack>
        {isActive ? (
          <ThemedIcon color="currentColor" icon={['far', 'xmark']} />
        ) : null}
        <Text as="span">{children}</Text>
      </HStack>
    </Button>
  )
}

export default RepoCardButton
