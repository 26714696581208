import { Button, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { PointsCardProps, pointsCategoryFactory } from '../../utils'
import PointsCardStatus from '../PointsCard/PointsCardStatus'
import PointsCardTag from '../PointsCardTag'

export default function PointsCardLarge({
  children,
  category,
  points,
  title,
  thumbnail,
  buttonText,
  buttonAction,
  complete,
}: PointsCardProps) {
  const buttonProps = complete
    ? {
        color: 'gray.5',
        bg: 'gray.2',
        cursor: 'default',
        pointerEvents: 'none',
        disabled: true,
        text: 'Complete',
      }
    : {
        color: 'blue.8',
        bg: 'blue.2',
        _hover: { bg: '#86BCFA' },
        text: buttonText,
        cursor: 'pointer',
      }

  const containerProps = complete
    ? {
        opacity: 0.6,
        cursor: 'default',
      }
    : {
        cursor: 'pointer',
      }

  return (
    <Flex
      direction="column"
      align="start"
      w="100%"
      h="259px"
      borderRadius="6px"
      bg="#FBFCFE"
      _hover={{ bg: 'white' }}
      {...containerProps}
      onClick={!complete ? buttonAction : undefined}
    >
      <HStack
        bg={pointsCategoryFactory(category)}
        bgGradient={`radial-gradient(50% 50% at 50% 50%, #E4E7EB 0%, rgba(255, 255, 255, 0.50) 100%)`}
        align="center"
        justify="center"
        w="full"
        h="100px"
        borderTopRadius="6px"
      >
        <Image src={thumbnail} />
      </HStack>
      <VStack align="start" justify="space-between" w="full" p={4} flexGrow={1}>
        <Text variant="body-l/medium" color="blue.9">
          {title}
        </Text>
        <HStack justify="space-between" align="center" w="full">
          {complete ? (
            <PointsCardStatus variant={'complete'} />
          ) : (
            <HStack>
              {children ? (
                children
              ) : (
                <PointsCardTag variant={category} points={points || ''} />
              )}
            </HStack>
          )}
          <Button
            as="a"
            color={buttonProps.color}
            bg={buttonProps.bg}
            cursor={buttonProps.cursor}
            h="40px"
            px={4}
            borderRadius="6px"
            _hover={{ bg: buttonProps.bg }}
            // for some reason disabled is still firing the event
            isDisabled={complete}
            onClick={!complete ? buttonAction : undefined}
          >
            <Text as="span" variant="body-md/semibold">
              {buttonProps.text}
            </Text>
          </Button>
        </HStack>
      </VStack>
    </Flex>
  )
}
