import { graphql } from '../gql'

// For token dropdown
export const LendTokensDropdownDocument = graphql(`
  query LendTokensDropdown($currentTime: BigInt!) {
    termRepos(where: { repurchaseTimestamp_gte: $currentTime }) {
      termRepoToken
      purchaseToken
      purchaseTokenMeta {
        id
        name
        symbol
        decimals
      }
      repurchaseTimestamp
    }
  }
`)

// Valid repo tokens
export const PageLendDocument = graphql(`
  query PageLend($purchaseToken: Bytes!, $currentTime: BigInt!) {
    termRepos(
      where: {
        purchaseToken: $purchaseToken
        repurchaseTimestamp_gte: $currentTime
      }
    ) {
      termRepoToken
      purchaseToken
      purchaseTokenMeta {
        id
        name
        symbol
        decimals
      }
      redemptionTimestamp
      repurchaseTimestamp
      termRepoLocker
      termRepoServicer
      collateralTokens
      collateralTokensMeta {
        id
        name
        symbol
        decimals
      }
      collateralRatios {
        collateralToken
        maintenanceRatio
      }
      completedAuctions(
        first: 1
        orderBy: auctionEndTime
        orderDirection: desc
      ) {
        auctionClearingPrice
        auctionEndTime
      }
    }
  }
`)
