import { Button, Flex } from '@chakra-ui/react'
import SectionHeader from './SectionHeader'

export default function DeleteProfile({
  onDeleteProfile,
}: {
  onDeleteProfile: () => void
}) {
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="20px"
      >
        <SectionHeader
          heading="Delete Profile"
          description="Deleting your profile will remove linked wallets and social accounts. Points and multipliers can be reclaimed by creating a new profile with the same wallet."
        />
        <Flex borderRadius="6px" justifyContent="center" alignItems="center">
          <Button
            variant="link"
            onClick={onDeleteProfile}
            textColor="red.5"
            pl={0}
          >
            Delete profile
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
