export type SeasonMeta = {
  // TODO: expand as needed
}

export type Season = {
  id?: number
  start: Date
  end: Date
  name: string
  isLocked: boolean
  meta: SeasonMeta
}

export type WalletPoints = {
  preseasonPoints: number // testnet participation
}

// WalletAnalyzer
export type CountMigrationPoints = {
  count: number
}

export type MigrationPointsPoints = {
  earnedPoints: number
  eligiblePoints: number
}

export type MigrationPointsLTV = {
  ltvSupply: number
  ltvBorrow: number
  ltvTotal: number
}

export type MigrationPointsBuckets = {
  btcNetAveragePositiveBucket: string
  btcNetPositiveDaysBucket: string
  btcNetPositivePct: string
  btcNetAverageNegativeBucket: string
  btcNetNegativeDaysBucket: string
  btcNetNegativePct: string
  btcTxChurn: string
  ethNetAveragePositiveBucket: string
  ethNetPositiveDaysBucket: string
  ethNetPositivePct: string
  ethNetAverageNegativeBucket: string
  ethNetNegativeDaysBucket: string
  ethNetNegativePct: string
  ethTxChurn: string
  stablesNetAveragePositiveBucket: string
  stablesNetPositiveDaysBucket: string
  stablesNetPositivePct: string
  stablesNetAverageNegativeBucket: string
  stablesNetNegativeDaysBucket: string
  stablesNetNegativePct: string
  stablesTxChurn: string
  totalDaysBucket: string
}

export type MigrationPointsMeta = {
  firstDate: string
  lastDate: string
  daysBetween: number
  a3NumTransactions: number
  a2NumTransactions: number
  c2NumTransactions: number
  c3NumTransactions: number
  m1NumTransactions: number
  s1NumTransactions: number
  a3HasChurned: boolean
  a2HasChurned: boolean
  c3HasChurned: boolean
  c2HasChurned: boolean
  m1HasChurned: boolean
  s1HasChurned: boolean
  ethLTV: MigrationPointsLTV
  btcLTV?: MigrationPointsLTV
  stablesLTV: MigrationPointsLTV
  buckets: MigrationPointsBuckets
}

export type MigrationPoints = {
  walletAddress: string
  hasClaimed: boolean
  points: MigrationPointsPoints
  meta: MigrationPointsMeta
}

// Invite code
export enum InviteCodeError {
  USED = 'Invite code already in use', // code has already been used
  FORBIDDEN = 'FORBIDDEN', // code is not owned by logged in user
  NOT_FOUND = 'Invalid invite code', // code does not exist
}

export type InviteCodeMeta = {
  progress: {
    usedInvite: boolean
    signedUp: boolean
    earnedPoints: boolean
  }
  // TODO: add more fields - invite code point informational data
}

export type InviteCodePoints = {
  referralPoints: number
}

export type InviteCode = {
  code: string
  profileId?: number
  refereeId?: number
  meta: InviteCodeMeta
  points: InviteCodePoints
  consumedTimestamp?: Date
}
