import { Badge, Box, Flex, Image, Skeleton, Text, Link } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import info from '../../../../assets/icons/info.svg'
import { Table, TableBody, Td, Tr } from '../../../../components/elements/Table'
import { components } from '../../../../models/profile-api'
import TableBorder from './TableBorder'
import TableHeader from './TableHeader'
import { InfoIcon } from '../../../../components/elements/Icons'
import Tooltip from '../../../../components/elements/Tooltip'
import arrow_up_right from '../../../../assets/icons/arrow-up-right.svg'
import { HStack } from '../../../../components/elements/Stack'

const TitleRow = ({ children }: { children: React.ReactNode }) => (
  <Tr noBorder _hover={{}}>
    <Td w="400px" h="auto"></Td>
    <Td colSpan={2} pb={0} px={0} h="auto">
      <Text
        color="blue.9"
        variant="body-md/semibold"
        borderBottom="1px"
        borderColor="gray.4"
        w="full"
        pb={1}
        mb={3}
        mt={8}
      >
        {children}
      </Text>
    </Td>
  </Tr>
)

const OtherTable = ({
  profileData,
  profileBadges,
  otherPoints,
  migrationPoints,
  preseasonPoints,
  totalPoints,
  isDisabled = false,
  collapsed = false,
  maxColWidth = 100,
  handleColumnWidth,
  isLoading,
}: {
  profileData?: components['schemas']['Profile']
  profileBadges?: components['schemas']['Badge'][]
  otherPoints?: components['schemas']['OtherPoints']
  migrationPoints?: components['schemas']['MigrationPoints']
  preseasonPoints?: components['schemas']['PreseasonPoints']
  totalPoints: number
  isDisabled?: boolean
  collapsed: boolean
  maxColWidth: number
  handleColumnWidth?: (width: number) => void
  isLoading?: boolean
}) => {
  const [isTableBodyVisible, setTableBodyVisible] = useState(true)

  const toggleTableBody = () => {
    setTableBodyVisible(!isTableBodyVisible)
  }

  const BadgeComponent = ({ label }: { label: string }) => (
    <Badge border="1px" borderColor="blue.2" bg="transparent" cursor="pointer">
      <Text color="blue.8" variant="body-xs/medium">
        {label}
      </Text>
    </Badge>
  )

  const { otherPointsSubtotal, preseasonDistribution } = useMemo(() => {
    if (!otherPoints && !preseasonPoints) {
      return {
        otherPointsSubtotal: 0,
        preseasonDistribution: { earlySupporter: 0, testnet: 0, publicBeta: 0 },
      }
    }
    const otherPointsSubtotal =
      (otherPoints?._subtotal ?? 0) + (preseasonPoints?._subtotal ?? 0)

    const preseasonMeta = preseasonPoints?.meta
    const preseasonDistribution = {
      earlySupporter:
        (preseasonMeta?.debank || 0) +
        (preseasonMeta?.discord || 0) +
        (preseasonMeta?.twitter || 0) +
        (preseasonMeta?.supporter || 0),
      testnet: preseasonMeta?.testnet || 0,
      publicBeta: preseasonMeta?.mainnet || 0,
    }
    return {
      otherPointsSubtotal,
      preseasonDistribution,
    }
  }, [otherPoints, preseasonPoints])

  const socialPoints = useMemo(() => {
    let socialPoints = 0
    if (otherPoints?.meta?.discord) {
      socialPoints += otherPoints.meta.discord
    }
    if (otherPoints?.meta?.twitter) {
      socialPoints += otherPoints.meta.twitter
    }
    return socialPoints
  }, [otherPoints])

  const badgePoints = useMemo(() => {
    let badgePoints = 0
    if (profileBadges) {
      badgePoints = profileBadges.reduce(
        (acc, badge) => acc + badge.pointsEarned,
        0
      )
    }
    return badgePoints
  }, [profileBadges])

  const migrationBonusPoints = migrationPoints?._subtotal ?? 0

  const totalOtherPoints =
    otherPointsSubtotal + badgePoints + migrationBonusPoints
  const percentTotalPoints = totalPoints
    ? (totalOtherPoints / totalPoints) * 100
    : undefined

  useEffect(() => {
    setTableBodyVisible(!collapsed)
  }, [collapsed])

  const noAuctionsFilled = migrationPoints?.meta?.noAuctionsFilled ?? 0
  const migrationPointsLocked = noAuctionsFilled < 3

  return (
    <TableBorder isDisabled={isDisabled}>
      <Table noPadding>
        <TableHeader
          category="other"
          title="Participate in campaigns throughout the season to earn points. "
          totalPoints={totalOtherPoints}
          handleClick={toggleTableBody}
          isTableBodyVisible={isDisabled ? false : isTableBodyVisible}
          percentOfTotalPointsEarned={percentTotalPoints}
          handleColumnWidth={handleColumnWidth}
          maxColWidth={maxColWidth}
          isLoading={isLoading}
        />
        <TableBody
          style={{
            display: !isTableBodyVisible || isDisabled ? 'none' : 'table',
          }}
          w="100%"
          mt={8}
        >
          <TitleRow>Pre-Season Points</TitleRow>
          <>
            <Tr noBorder _hover={{}}>
              <Td w="400px"></Td>
              <Td colSpan={2} px={0}>
                <Tr noBorder _hover={{}}>
                  <Td pb={0}>
                    <HStack>
                      <Text color="gray.6" variant="body-md/semibold">
                        Early Supporter
                      </Text>
                      <Tooltip
                        iconColor="gray.4"
                        aria-label="early-supporter-tooltip"
                        label={
                          'Rewards for early supporters such as DegenScore participants and DeBank upvoters.'
                        }
                        noDelay
                      >
                        <Image src={info} boxSize="16px" opacity="0.5" />
                      </Tooltip>
                    </HStack>
                  </Td>
                  <Td textAlign="right" w="full" pb={0}>
                    <Box w="fit-content" ml="auto" px={2}>
                      {isLoading ? (
                        <Skeleton h="24px" w="80px" />
                      ) : (
                        <Text color="blue.9" variant="body-md/semibold">
                          {preseasonDistribution.earlySupporter ? (
                            `${preseasonDistribution.earlySupporter.toLocaleString()} points`
                          ) : (
                            <Text
                              color="gray.5"
                              as="span"
                              variant="body-md/normal"
                            >
                              &mdash;
                            </Text>
                          )}
                        </Text>
                      )}
                    </Box>
                  </Td>
                </Tr>
              </Td>
            </Tr>
            <Tr noBorder _hover={{}}>
              <Td w="400px"></Td>
              <Td colSpan={2} px={0}>
                <Tr noBorder _hover={{}} bg="blue.0">
                  <Td pb={0}>
                    <Text color="gray.6" variant="body-md/semibold">
                      Testnet User
                    </Text>
                  </Td>
                  <Td textAlign="right" w="full" pb={0}>
                    <Box w="fit-content" ml="auto" px={2}>
                      {isLoading ? (
                        <Skeleton h="24px" w="80px" />
                      ) : (
                        <Text color="blue.9" variant="body-md/semibold">
                          {preseasonDistribution.testnet ? (
                            `${preseasonDistribution.testnet.toLocaleString()} points`
                          ) : (
                            <Text
                              color="gray.5"
                              as="span"
                              variant="body-md/normal"
                            >
                              &mdash;
                            </Text>
                          )}
                        </Text>
                      )}
                    </Box>
                  </Td>
                </Tr>
              </Td>
            </Tr>
            <Tr noBorder _hover={{}}>
              <Td w="400px"></Td>
              <Td colSpan={2} px={0}>
                <Tr noBorder _hover={{}}>
                  <Td pb={0}>
                    <Text color="gray.6" variant="body-md/semibold">
                      Public Beta
                    </Text>
                  </Td>
                  <Td textAlign="right" w="full" pb={0}>
                    <Box w="fit-content" ml="auto" px={2}>
                      {isLoading ? (
                        <Skeleton h="24px" w="80px" />
                      ) : (
                        <Text color="blue.9" variant="body-md/semibold">
                          {preseasonDistribution.publicBeta ? (
                            `${preseasonDistribution.publicBeta.toLocaleString()} points`
                          ) : (
                            <Text
                              color="gray.5"
                              as="span"
                              variant="body-md/normal"
                            >
                              &mdash;
                            </Text>
                          )}
                        </Text>
                      )}
                    </Box>
                  </Td>
                </Tr>
              </Td>
            </Tr>
          </>
          <>
            <TitleRow>Socials</TitleRow>
            <>
              <Tr noBorder _hover={{}}>
                <Td w="400px"></Td>
                <Td colSpan={2} px={0}>
                  <Tr noBorder _hover={{}}>
                    <Td pb={0}>
                      <Text color="gray.6" variant="body-md/semibold">
                        Linking Socials
                      </Text>
                    </Td>
                    <Td textAlign="right" w="full" pb={0}>
                      <Box w="fit-content" ml="auto" px={2}>
                        {isLoading ? (
                          <Skeleton h="24px" w="80px" />
                        ) : (
                          <Text color="blue.9" variant="body-md/semibold">
                            {socialPoints ? (
                              `${socialPoints.toLocaleString()} points`
                            ) : (
                              <Text
                                color="gray.5"
                                as="span"
                                variant="body-md/normal"
                              >
                                &mdash;
                              </Text>
                            )}
                          </Text>
                        )}
                      </Box>
                    </Td>
                  </Tr>
                </Td>
              </Tr>
              <Tr noBorder _hover={{}}>
                <Td w="400px"></Td>
                <Td colSpan={2} px={0}>
                  <Tr noBorder _hover={{}} bg="blue.0">
                    <Td pb={0}>
                      <Text color="gray.6" variant="body-md/semibold">
                        Announcement Retweet
                      </Text>
                    </Td>
                    <Td textAlign="right" w="full" pb={0}>
                      <Box w="fit-content" ml="auto" px={2}>
                        {isLoading ? (
                          <Skeleton h="24px" w="80px" />
                        ) : (
                          <Text color="blue.9" variant="body-md/semibold">
                            {otherPoints?.meta?.uniqueTasks
                              ?.hasRetweetedRewardsAnnouncement ? (
                              `${otherPoints.meta.uniqueTasks.hasRetweetedRewardsAnnouncement.toLocaleString()} points`
                            ) : (
                              <Text
                                color="gray.5"
                                as="span"
                                variant="body-md/normal"
                              >
                                &mdash;
                              </Text>
                            )}
                          </Text>
                        )}
                      </Box>
                    </Td>
                  </Tr>
                </Td>
              </Tr>
              <Tr noBorder _hover={{}}>
                <Td w="400px"></Td>
                <Td colSpan={2} px={0}>
                  <Tr noBorder _hover={{}}>
                    <Td py={3}>
                      <Text color="gray.6" variant="body-md/semibold" mb={3}>
                        Badges
                      </Text>
                      <Flex w="250px" wrap="wrap" gap={2}>
                        {profileBadges?.map((profileBadge, index) => (
                          <BadgeComponent
                            key={index}
                            label={profileBadge.meta.name}
                          />
                        ))}
                      </Flex>
                    </Td>
                    <Td textAlign="right" w="full" pb={0}>
                      <Box w="fit-content" ml="auto" px={2}>
                        {isLoading ? (
                          <Skeleton h="24px" w="80px" />
                        ) : (
                          <Text color="blue.9" variant="body-md/semibold">
                            {badgePoints ? (
                              `${badgePoints.toLocaleString()} points`
                            ) : (
                              <Text
                                color="gray.5"
                                as="span"
                                variant="body-md/normal"
                              >
                                &mdash;
                              </Text>
                            )}
                          </Text>
                        )}
                      </Box>
                    </Td>
                  </Tr>
                </Td>
              </Tr>
              {migrationPoints ? (
                <>
                  <TitleRow>Migration Points</TitleRow>
                  <Tr noBorder _hover={{}}>
                    <Td w="400px"></Td>
                    <Td colSpan={2} px={0}>
                      <Tr noBorder _hover={{}}>
                        <Td pb={0}>
                          <Text color="gray.6" variant="body-md/semibold">
                            <Text as="span" mr={2}>
                              Total Unlocked
                            </Text>
                            {migrationPointsLocked ? (
                              <Tooltip
                                delayPeriod={2000}
                                label={
                                  <HStack gap={0}>
                                    <Text variant="body-sm/normal">
                                      Open 3 positions via auctions to unlock
                                    </Text>
                                    <Link
                                      textDecoration="none"
                                      href={window.location.origin}
                                    >
                                      <Image
                                        src={arrow_up_right}
                                        w="20px"
                                        h="20px"
                                      />
                                    </Link>
                                  </HStack>
                                }
                              >
                                <InfoIcon color="blue.9" opacity={0.5} />
                              </Tooltip>
                            ) : null}
                          </Text>
                        </Td>
                        <Td textAlign="right" w="full" pb={0}>
                          <Box w="fit-content" ml="auto" px={2}>
                            {isLoading ? (
                              <Skeleton h="24px" w="80px" />
                            ) : (
                              <Text color="blue.9" variant="body-md/semibold">
                                {!migrationPoints?._subtotal && (
                                  <Text
                                    color="gray.5"
                                    as="span"
                                    variant="body-md/normal"
                                  >
                                    &mdash;
                                  </Text>
                                )}

                                {!!migrationPoints?._subtotal && (
                                  <>
                                    {migrationPointsLocked ? (
                                      <Text color="gray.5">
                                        {`0 / ${migrationPoints?._subtotal.toLocaleString()} points`}
                                      </Text>
                                    ) : (
                                      <Text>
                                        {`${migrationPoints?._subtotal.toLocaleString()} points`}
                                      </Text>
                                    )}
                                  </>
                                )}
                              </Text>
                            )}
                          </Box>
                        </Td>
                      </Tr>
                    </Td>
                  </Tr>
                </>
              ) : null}
            </>
          </>
        </TableBody>
      </Table>
    </TableBorder>
  )
}
export default OtherTable
