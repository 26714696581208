import {
  Button,
  Heading,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
} from '@chakra-ui/react'
import { HStack, Stack } from '../../../../../components/elements/Stack'
import { useState } from 'react'
import InfoBox from '../../../../../components/elements/InfoBox'
import { Trans } from '@lingui/macro'

export default function LoadInterestRateModal({
  isOpen,
  onClose,
  onLoadRates,
}: {
  isOpen: boolean
  onClose: () => void
  onLoadRates: () => Promise<void>
}) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <Stack p="28px" spacing={'20px'}>
            <HStack justify="space-between">
              <Heading
                variant="heading/h3"
                fontWeight="700"
                mt="0px"
                mb="0px"
                textAlign="start"
                color="blue.9"
              >
                <Trans>Load Interest Rates</Trans>
              </Heading>
              <ModalCloseButton position="static" color="blue.9" />
            </HStack>
            <Text color="blue.9" variant="body-sm/normal">
              <Trans>
                If you switched browsers or devices, your interest rate(s) may
                not be showing.
              </Trans>
            </Text>
            <Text color="blue.9" variant="body-sm/normal">
              <Trans>
                Our Auctions use a sealed-bid system where the interest rates of
                all tenders remain hidden, off-chain, until the auction clears.
              </Trans>
            </Text>
            <Text color="blue.9" variant="body-sm/normal">
              <Trans>
                To load your missing interest rate(s), we need to confirm that
                you own the wallet associated with them.
              </Trans>
            </Text>
            <InfoBox kind="info">
              <Trans>
                You will receive a signature request that is free and will not
                send a transaction.
              </Trans>
            </InfoBox>
            <Button
              variant="secondary"
              size="sm"
              w="full"
              onClick={async () => {
                setIsLoading(true)
                onLoadRates()
                setIsLoading(false)
              }}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              <Trans>Verify wallet ownership</Trans>
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
