import { useCallback, useMemo } from 'react'
import { Address, Currency, TransformedListingsData } from '../model'
import { bigToFixedNumber } from '../../helpers/conversions'
import {
  GetPortfolioListingsQuery,
  Listing,
  Purchase,
} from '../../gql/listings/graphql'
import { FixedNumber } from 'ethers'
import { add, subtract } from '../../helpers/math'

export function useGraphListings(
  listingsData: { [chainId: string]: GetPortfolioListingsQuery } | undefined,
  currencies:
    | {
        [chainId: string]: {
          [address: string]: Currency
        }
      }
    | undefined
):
  | { [chainId: string]: { [termRepoToken: Address]: TransformedListingsData } }
  | undefined {
  const transformListingsData = useCallback(
    (
      listingsData: { [chainId: string]: GetPortfolioListingsQuery } | undefined
    ) => {
      if (!listingsData || !currencies || Object.keys(currencies).length === 0)
        return undefined

      const result: {
        [chainId: string]: { [termRepoToken: Address]: TransformedListingsData }
      } = {}

      Object.entries(listingsData).forEach(([chainId, data]) => {
        if (!result[chainId]) {
          result[chainId] = {}
        }

        data.listings.forEach((listing: Listing) => {
          const token = listing.token
          if (!result[chainId][token]) {
            result[chainId][token] = {
              listings: {
                totalAmountSold: FixedNumber.fromString('0', 'fixed128x8'),
                totalRemainingAmount: FixedNumber.fromString('0', 'fixed128x8'),
                purchaseToken: '',
                totalCost: FixedNumber.fromString('0', 'fixed128x18'),
                transactions: [],
              },
            }
          }

          const repoTokenCurrency = currencies?.[chainId]?.[token]

          // Update listing aggregates and transactions
          const listingData = result[chainId][token]?.listings
          if (listingData && repoTokenCurrency) {
            const listingAmountFn = bigToFixedNumber(
              listing.listingAmount,
              repoTokenCurrency.decimals
            )
            const remainingAmountFn = bigToFixedNumber(
              listing.remainingAmount,
              repoTokenCurrency.decimals
            )
            const soldAmountFn = subtract(listingAmountFn, remainingAmountFn)

            listingData.totalAmountSold = add(
              listingData.totalAmountSold,
              soldAmountFn
            )
            if (!listing.cancelled) {
              listingData.totalRemainingAmount = add(
                listingData.totalRemainingAmount,
                remainingAmountFn
              )
            }
            listingData.transactions.push({
              id: listing.id,
              listingAmount: listingAmountFn,
              remainingAmount: remainingAmountFn,
              soldAmount: soldAmountFn,
              soldCostPurchaseCurrency: FixedNumber.fromString(
                '0',
                `fixed128x18`
              ),
              cancelled: listing.cancelled,
              transactionHash: listing.transactionHash,
              timestamp: listing.timestamp,
            })
          }
        })

        data.purchases.forEach((purchase: Partial<Purchase>) => {
          const token = purchase.termRepoToken

          const purchaseCurrency =
            currencies?.[chainId]?.[purchase.purchaseToken]
          const repoTokenCurrency = currencies?.[chainId]?.[token]

          if (!result[chainId][token]) {
            result[chainId][token] = {
              purchases: {
                totalAmountPurchased: FixedNumber.fromString(
                  '0',
                  `fixed128x${repoTokenCurrency.decimals}`
                ),
                totalCost: FixedNumber.fromString(
                  '0',
                  `fixed128x${purchaseCurrency.decimals}`
                ),
                purchaseToken: '',
                transactions: [],
              },
            }
          }

          const purchaseData = result[chainId][token]?.purchases

          if (purchaseData && purchaseCurrency && repoTokenCurrency) {
            purchaseData.totalAmountPurchased = add(
              purchaseData.totalAmountPurchased,
              bigToFixedNumber(purchase.amount, repoTokenCurrency.decimals)
            )
            purchaseData.totalCost = add(
              purchaseData.totalCost,
              bigToFixedNumber(purchase.cost, purchaseCurrency.decimals),
              purchaseCurrency.decimals
            )
            purchaseData.purchaseToken = purchase.purchaseToken
            purchaseData.transactions.push({
              id: purchase.id ?? '',
              seller: purchase.seller,
              amount: bigToFixedNumber(
                purchase.amount,
                repoTokenCurrency.decimals
              ),
              cost: bigToFixedNumber(purchase.cost, purchaseCurrency.decimals),
              transactionHash: purchase.transactionHash,
              timestamp: purchase.timestamp,
            })
          }
        })

        // Get purchase side info for the sold listings:
        data.listingPurchases.forEach((purchase) => {
          const token = purchase.termRepoToken
          const purchaseCurrency =
            currencies?.[chainId]?.[purchase.purchaseToken]
          const repoTokenDecimals = currencies?.[chainId]?.[token]?.decimals
          if (
            !result[chainId][token] &&
            repoTokenDecimals &&
            purchaseCurrency
          ) {
            result[chainId][token] = {
              listings: {
                totalAmountSold: FixedNumber.fromString(
                  '0',
                  `fixed128x${repoTokenDecimals}`
                ),
                totalRemainingAmount: FixedNumber.fromString(
                  '0',
                  `fixed128x${repoTokenDecimals}`
                ),
                purchaseToken: '',
                totalCost: FixedNumber.fromString(
                  '0',
                  `fixed128x${purchaseCurrency.decimals}`
                ),
                transactions: [],
              },
            }
          }

          // Update listing aggregates and transactions (seller is the wallet)
          const listingData = result[chainId][token]?.listings
          if (listingData && purchaseCurrency) {
            listingData.totalCost = add(
              listingData.totalCost,
              bigToFixedNumber(purchase.cost, purchaseCurrency.decimals),
              purchaseCurrency.decimals
            )
            listingData.purchaseToken = purchase.purchaseToken

            const listingTransaction = listingData.transactions.find(
              (tx) => tx.id === purchase.listingId?.id
            )

            if (listingTransaction) {
              listingTransaction.soldCostPurchaseCurrency = add(
                listingTransaction.soldCostPurchaseCurrency,
                bigToFixedNumber(purchase.cost, purchaseCurrency.decimals)
              )
            }
          }
        })
      })

      return result
    },
    [currencies]
  )

  return useMemo(
    () => transformListingsData(listingsData),
    [transformListingsData, listingsData]
  )
}
