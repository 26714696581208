import dayjs from 'dayjs'
import { BigNumber, Contract, Signer, utils } from 'ethers'
import { TermAuthStruct as TermAuthStruct_0_2_4 } from '../abi-generated/abi/v0.2.4/Authenticator'

type Head<T extends any[]> = T extends [...infer Head, any] ? Head : any[]

export async function generateTermAuth<T extends Contract, F extends string>(
  signer: Signer,
  contract: T,
  method: F,
  args: Head<Parameters<T[F]>>,
  currentTimestamp: number
) {
  const user = await signer.getAddress()
  const nonce = BigNumber.from(utils.randomBytes(32))
  const expirationTimestamp = BigNumber.from(
    dayjs.unix(currentTimestamp).add(30, 'minutes').unix().toString()
  )
  const emptyAuth: TermAuthStruct_0_2_4 = {
    nonce,
    user,
    expirationTimestamp,
    signature: new Uint8Array(65),
  }
  const txnData = contract.interface.encodeFunctionData(method, [
    ...args,
    emptyAuth,
  ])
  const txnDataWithoutSig = txnData.slice(0, txnData.length - 96 * 2)
  const chain = await contract.provider.getNetwork()
  const messageHash = utils.solidityKeccak256(
    ['uint', 'address', 'bytes'],
    [chain.chainId, contract.address.toLowerCase(), txnDataWithoutSig]
  )
  const signature = await signer.signMessage(utils.arrayify(messageHash))

  return {
    user,
    nonce,
    expirationTimestamp,
    signature,
  } as TermAuthStruct_0_2_4
}
