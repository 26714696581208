import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { Contract, BigNumber } from 'ethers'
import { Address, Price } from '../model'
import TermRepoTokenABI_0_4_1 from '../../abi/v0.4.1/TermRepoToken.json'
import { TermRepoToken as TermRepoToken_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermRepoToken'
import {
  MultichainCalls,
  useMultichainCalls,
  usePriceOracles,
} from './helper-hooks'
import { useEffect, useMemo } from 'react'
import { captureException } from '@sentry/react'

export type TokenInfoPair = {
  address: Address
  decimals: number
}

export function usePrices(
  erc20TokensDecimalPairsByChain:
    | { [chainId: string]: TokenInfoPair[] | undefined }
    | undefined,
  termTokenAddresses: { [chainId: string]: Address[] | undefined } | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): { [chainId: string]: Price[] } | null | undefined {
  // Get decimals from the erc20 token
  // Get redemption value from the term token
  // Get usdValueOfTokens from the price oracle using the decimals from the erc20 token

  // Oracles per chain id
  const oracles = usePriceOracles(provider)

  const pricesCalls: MultichainCalls | undefined = useMemo(
    () =>
      erc20TokensDecimalPairsByChain &&
      Object.entries(erc20TokensDecimalPairsByChain).reduce<MultichainCalls>(
        (acc, [chainId, tokenDecimalPairs]) => {
          const oracle = oracles[chainId]
          if (!oracle || !tokenDecimalPairs) return acc

          const calls = tokenDecimalPairs.map((tokenDecimalPair) => ({
            contract: oracle,
            method: 'usdValueOfTokens',
            args: [
              tokenDecimalPair.address,
              '1' + '0'.repeat(tokenDecimalPair.decimals ?? 0),
            ],
          }))

          acc[chainId] = calls
          return acc
        },
        {}
      ),
    [erc20TokensDecimalPairsByChain, oracles]
  )

  const pricesAndErrorsGrouped = useMultichainCalls(pricesCalls)

  const prices = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    pricesAndErrorsGrouped &&
      Object.entries(pricesAndErrorsGrouped).forEach(
        ([chainId, pricesAndErrors]) => {
          result[chainId] = pricesAndErrors
            .filter((priceOrError) => !priceOrError?.error)
            .map((priceOrError) => priceOrError?.value)
        }
      )
    return result
  }, [pricesAndErrorsGrouped])

  const priceErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (pricesAndErrorsGrouped) {
      Object.entries(pricesAndErrorsGrouped).forEach(
        ([chainId, pricesAndErrors]) => {
          const chainErrors = pricesAndErrors
            .filter((priceOrError) => !!priceOrError?.error)
            .map((priceOrError) => priceOrError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [pricesAndErrorsGrouped])

  const termTokenContracts = useMemo(() => {
    const contractGroups: { [chainId: string]: TermRepoToken_0_4_1[] } = {}

    if (termTokenAddresses) {
      Object.entries(termTokenAddresses).forEach(([chainId, addresses]) => {
        if (addresses && addresses.length > 0) {
          contractGroups[chainId] = addresses.map(
            (address) =>
              new Contract(
                address,
                TermRepoTokenABI_0_4_1,
                provider
              ) as TermRepoToken_0_4_1
          )
        }
      })
    }

    return contractGroups
  }, [provider, termTokenAddresses])

  const termTokenPriceCalls: MultichainCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    Object.entries(termTokenContracts).forEach(([chainId, contracts]) => {
      calls[chainId] = contracts.map((contract) => ({
        contract,
        method: 'redemptionValue',
        args: [],
      }))
    })

    return calls
  }, [termTokenContracts])

  const termTokenPricesAndErrorsGrouped =
    useMultichainCalls(termTokenPriceCalls)

  const termTokenPrices = useMemo(() => {
    const result: { [chainId: string]: any[] } = {}
    termTokenPricesAndErrorsGrouped &&
      Object.entries(termTokenPricesAndErrorsGrouped).forEach(
        ([chainId, pricesAndErrors]) => {
          result[chainId] = pricesAndErrors
            .filter((priceOrError) => !priceOrError?.error)
            .map((priceOrError) => priceOrError?.value)
        }
      )
    return result
  }, [termTokenPricesAndErrorsGrouped])

  const termTokenErrors = useMemo(() => {
    const errors: { [chainId: string]: any[] } = {}

    if (termTokenPricesAndErrorsGrouped) {
      Object.entries(termTokenPricesAndErrorsGrouped).forEach(
        ([chainId, pricesAndErrors]) => {
          const chainErrors = pricesAndErrors
            .filter((priceAndError) => !!priceAndError?.error)
            .map((priceAndError) => priceAndError?.error)

          if (chainErrors.length > 0) {
            errors[chainId] = chainErrors
          }
        }
      )
    }

    return errors
  }, [termTokenPricesAndErrorsGrouped])

  useEffect(() => {
    Object.entries(priceErrors).forEach(([chainId, errors]) => {
      errors.forEach((err: Error) => {
        console.error(`Error on chain ${chainId} retrieving prices:`, err)
        captureException(err)
      })
    })
  }, [priceErrors])

  useEffect(() => {
    Object.entries(termTokenErrors).forEach(([chainId, errors]) => {
      errors.forEach((err: Error) => {
        console.error(
          `Error on chain ${chainId} retrieving term token prices:`,
          err
        )
        captureException(err)
      })
    })
  }, [termTokenErrors])

  return useMemo(() => {
    // We're still loading if any of these are not defined.
    if (!prices || !termTokenPrices) {
      return undefined
    }

    let allDataLoaded = true

    Object.values(prices).forEach((priceValues) => {
      if (priceValues.some((price) => price === undefined)) {
        allDataLoaded = false
      }
    })

    Object.values(termTokenPrices).forEach((priceValues) => {
      if (priceValues.some((price) => price === undefined)) {
        allDataLoaded = false
      }
    })

    const result: { [chainId: string]: Price[] } | null | undefined = {}

    Object.entries(prices).forEach(([chainId, priceValues]) => {
      const tokenDecimalPairs = erc20TokensDecimalPairsByChain?.[chainId]
      if (
        tokenDecimalPairs &&
        priceValues.length === tokenDecimalPairs.length
      ) {
        result[chainId] = tokenDecimalPairs
          .map((tokenDecimal, index) => {
            // TODO: check this is accurate index lookup!!
            const priceValue = priceValues[index]
            const price = priceValue?.[0]?.mantissa
              ? BigNumber.from(priceValue[0].mantissa)
              : undefined
            return {
              token: tokenDecimal.address,
              price,
              decimals: 18,
            } as Price
          })
          .filter((price): price is Price => price !== undefined)
      }
    })

    Object.entries(termTokenPrices).forEach(([chainId, priceValues]) => {
      const tokenAddresses = termTokenAddresses?.[chainId]
      if (tokenAddresses && priceValues.length === tokenAddresses.length) {
        const termPrices = tokenAddresses
          .map((token, index) => {
            // TODO: check this is accurate index lookup!!
            const priceValue = priceValues[index]
            const price = priceValue?.[0]
              ? BigNumber.from(priceValue[0])
              : undefined
            return {
              token,
              price,
              decimals: 18,
            } as Price
          })
          .filter((price): price is Price => price !== undefined)

        result[chainId] = [...(result[chainId] || []), ...termPrices]
      }
    })

    // return if all data is loaded + and there is at least one balance otherwise undefined
    const retVal = allDataLoaded
      ? Object.keys(result).length > 0
        ? result
        : undefined
      : undefined

    return retVal
  }, [
    erc20TokensDecimalPairsByChain,
    termTokenAddresses,
    prices,
    termTokenPrices,
  ])
}
