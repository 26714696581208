import {
  Box,
  Divider,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { HStack, VStack, Stack } from '../../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BigNumber, FixedNumber } from 'ethers'
import { useMemo } from 'react'
import arrow_up_right_dark from '../../../../../assets/icons/arrow-up-right-dark.svg'
import { TenderEdit, TenderDiff } from '../../../../../data/parse'
import {
  formatFixedPercentage,
  formatFixedToken,
} from '../../../../../helpers/utils'
import SubmitApproveButton from '../../../../../components/elements/SubmitApproveButton'
import { Currency } from '../../../../../data/model'
import { subtract, add, fixedCompare } from '../../../../../helpers/math'
import { fixedToBigNumber } from '../../../../../helpers/conversions'
import Chip from '../../../../../components/elements/Chip'
import { Trans, t } from '@lingui/macro'

export default function ConfirmEditModal({
  tenderEdits,
  tenderKind,
  isOpen,
  onClose,
  onConfirm,
  purchaseCurrency,
  onKytCheck,
  purchaseTokenAllowance,
  onApprovePurchaseToken,
  collateralCurrency,
  collateralTokenAllowance,
  onApproveCollateralToken,
  getBlockExplorerTransactionUrl,
}: {
  tenderEdits: TenderEdit[]
  tenderKind: 'offer' | 'bid'
  isOpen: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
  purchaseCurrency: Currency
  collateralCurrency: Currency
  onKytCheck: () => Promise<boolean>
  purchaseTokenAllowance: BigNumber
  onApprovePurchaseToken: () => Promise<void>
  collateralTokenAllowance: BigNumber
  onApproveCollateralToken: () => Promise<void>
  getBlockExplorerTransactionUrl?: (address: string) => string
}) {
  const { tenderKindPluralLabel, tenderKindLabel } =
    tenderKind === 'offer'
      ? {
          tenderKindPluralLabel: 'Offers',
          tenderKindLabel: 'Offer',
        }
      : tenderKind === 'bid'
        ? {
            tenderKindPluralLabel: 'Bids',
            tenderKindLabel: 'Bid',
          }
        : {
            // default if the above cases dont match
            tenderKindPluralLabel: 'Tenders',
            tenderKindLabel: 'Tender',
          }

  let { onApprove, allowance, matchingLabel, tokenSymbol, decimals } =
    tenderKind === 'offer'
      ? {
          onApprove: onApprovePurchaseToken,
          decimals: purchaseCurrency?.decimals,
          // for offers only an increase in the supplied amount is relevant for token approvals
          matchingLabel: 'Supply Amount',
          allowance: purchaseTokenAllowance,
          tokenSymbol: purchaseCurrency.symbol,
        }
      : {
          onApprove: onApproveCollateralToken,
          decimals: collateralCurrency?.decimals,
          // for bids only an increase in collateral is relevant for token approvals
          matchingLabel: 'Collateral',
          allowance: collateralTokenAllowance,
          tokenSymbol: collateralCurrency.symbol,
        }

  // Calculates the potential increase in allowance based on each of the TenderEdit differences
  const approvalAmount = useMemo(() => {
    // Finds the relevant differences from each of the respective TenderEdits
    const relevantChanges: TenderDiff[] = tenderEdits
      .map((edit) => {
        const relevantEdit = edit.differences.find(
          (diff) =>
            diff.label === matchingLabel &&
            fixedCompare(diff.oldValue, 'lt', diff.newValue)
        )
        return relevantEdit
      })
      .filter((e) => !!e) as TenderDiff[]

    const tokenDelta = relevantChanges.reduce(
      (sum, edit) => {
        const difference = subtract(edit.newValue, edit.oldValue)
        return add(sum, difference)
      },
      FixedNumber.fromString('0', `fixed128x${decimals}`)
    )

    return fixedToBigNumber(tokenDelta)
  }, [tenderEdits, decimals, matchingLabel])

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <Stack p="28px" spacing={'20px'}>
            <HStack justify="space-between">
              <Heading
                variant="heading/h3"
                fontWeight="700"
                mt="0px"
                mb="0px"
                textAlign="start"
                color="blue.9"
              >
                <Trans>
                  {' '}
                  Edit{' '}
                  {tenderEdits?.length > 1
                    ? tenderKindPluralLabel
                    : tenderKindLabel}
                  ?
                </Trans>
              </Heading>
              <ModalCloseButton position="static" color="blue.9" />
            </HStack>
            <Text color="blue.9" variant="body-sm/normal">
              <Trans>
                Are you sure you want to edit the following tender? If you
                proceed, you will be prompted to confirm a wallet transaction.
              </Trans>
            </Text>
            <VStack w="full" spacing="28px">
              {tenderEdits
                .filter((edit) => edit.differences.length > 0)
                .map((edit) => (
                  <VStack
                    alignItems="center"
                    borderRadius="md"
                    bg="blue.0"
                    w="full"
                    borderWidth="1px"
                    borderColor="gray.2"
                  >
                    <HStack
                      justifyContent="space-between"
                      pt="8px"
                      px="16px"
                      w="full"
                    >
                      <Text as="span" color="gray.6" variant="body-sm/semibold">
                        <Trans>{tenderKindLabel} Overview</Trans>
                      </Text>
                      <Box
                        borderRadius="md"
                        py="2px"
                        px="6px"
                        bg="gray.2"
                        display="flex"
                        alignItems="center"
                        columnGap="2px"
                      >
                        <Text
                          as="span"
                          color="gray.6"
                          variant="body-sm/semibold"
                        >
                          <Trans>
                            {edit.transaction.slice(0, 6)}...
                            {edit.transaction.slice(-4)}
                          </Trans>
                        </Text>
                        <Link
                          href={
                            getBlockExplorerTransactionUrl
                              ? getBlockExplorerTransactionUrl(edit.transaction)
                              : '#'
                          }
                          isExternal
                          color="gray.3"
                          ml="8px"
                        >
                          <Image
                            w={3}
                            h={3}
                            src={arrow_up_right_dark}
                            ml="2px"
                          />
                        </Link>
                      </Box>
                    </HStack>
                    <Divider borderColor="gray.2" margin={0} />
                    {edit.differences.map((diff) => (
                      <HStack
                        justifyContent="space-between"
                        pb="8px"
                        px="16px"
                        w="full"
                        key={diff.label}
                      >
                        <HStack justifyContent="space-between">
                          <Text
                            as="span"
                            color="gray.6"
                            variant="body-sm/medium"
                          >
                            <Trans>{diff.label}</Trans>
                          </Text>
                          <Chip size="xs">{diff.symbol}</Chip>
                        </HStack>
                        <HStack>
                          <Text
                            as="span"
                            color="gray.4"
                            variant="body-sm/normal"
                          >
                            {diff.symbol === '%'
                              ? formatFixedPercentage(
                                  diff.oldValue,
                                  undefined,
                                  true,
                                  FixedNumber.fromString('1.0', 'fixed128x18')
                                )
                              : formatFixedToken(
                                  diff.oldValue,
                                  diff.symbol,
                                  true
                                )}{' '}
                            →
                          </Text>
                          <Text
                            as="span"
                            color="blue.9"
                            variant="body-sm/normal"
                          >
                            <Trans>
                              {diff.symbol === '%'
                                ? formatFixedPercentage(
                                    diff.newValue,
                                    undefined,
                                    true,
                                    FixedNumber.fromString('1.0', 'fixed128x18')
                                  )
                                : formatFixedToken(
                                    diff.newValue,
                                    diff.symbol,
                                    true
                                  )}
                            </Trans>
                          </Text>
                        </HStack>
                      </HStack>
                    ))}
                  </VStack>
                ))}
            </VStack>
            {tenderEdits.length === 1 && (
              <HStack
                bg="blue.50"
                p="8px"
                fontSize="14px"
                lineHeight="150%"
                alignItems="center"
                borderRadius="md"
              >
                <Box fontSize="20px" color="blue.500">
                  <FontAwesomeIcon icon={['fal', 'circle-info']} />
                </Box>
                <Text>
                  <Trans>
                    Please note, you can edit multiple tenders at once to save
                    on gas fees.
                  </Trans>
                </Text>
              </HStack>
            )}
            <SubmitApproveButton
              approvingCurrencySymbol={tokenSymbol}
              onApprove={onApprove}
              onSubmit={async () => {
                try {
                  await onConfirm()
                } catch (error) {
                  console.log(error)
                }
              }}
              approvalAmount={approvalAmount}
              approvedAmount={allowance}
              submitLabel={
                tenderEdits.length > 1 ? t`Confirm edits` : t`Confirm edit`
              }
              onKytCheck={onKytCheck}
              variant="secondary"
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
