import { FormControlProps } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { ReactNode } from 'react'
import TokenAmountField from '../../../../components/elements/TokenAmountField'
import { formatFixedToken } from '../../../../helpers/utils'
import OverflowBox from '../../../../components/elements/OverflowBox'
import { Currency } from '../../../../data/model'
import { Trans, t } from '@lingui/macro'

export default function ListTokensField({
  type = 'text',
  value,
  onChange,
  disabled,
  w,

  purchaseCurrency,
  termTokenBalanceAfter,
  termTokenSymbol,
  termTokenBalance,
  helperText,
}: {
  type?: string
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  w?: FormControlProps['w']

  purchaseCurrency: Currency
  termTokenBalanceAfter: FixedNumber
  termTokenSymbol: string
  termTokenBalance: FixedNumber
  helperText?: ReactNode
}) {
  return (
    <TokenAmountField
      label={t`Tokens to list`}
      rightLabel={
        <>
          <OverflowBox
            onClick={() => onChange(termTokenBalance.toString())}
            cursor="pointer"
            _hover={{
              textDecoration: 'underline',
            }}
          >
            <Trans>Available:</Trans>{' '}
            {formatFixedToken(
              termTokenBalance,
              purchaseCurrency.symbol,
              true,
              true
            )}
          </OverflowBox>
        </>
      }
      type={type}
      value={value}
      onChange={onChange}
      onMax={() => onChange(termTokenBalance.toString())}
      isDisabled={disabled}
      w={w}
      placeholder="0.00"
      isError={termTokenBalanceAfter.isNegative()}
      tokenSymbol={termTokenSymbol}
      tokenDecimals={termTokenBalance.format.decimals}
      helperText={helperText}
    />
  )
}
