import { Image, ImageProps, Text } from '@chakra-ui/react'
import { useNetworkIcon } from '../../../data/hooks/use-network-icons'
import { ChainId } from '@usedapp/core'
import Tooltip from '../Tooltip'
import { useMemo, useEffect, useState } from 'react'
import { useChainConfig } from '../../../providers/config'

const getOpacity = (variant: 'full' | 'faded', opacity?: number): number => {
  if (variant === 'full' || opacity === undefined) {
    return 1
  } else {
    return opacity
  }
}

export default function NetworkIcon({
  chainId,
  boxSize = '16px',
  imageProps,
  withTooltip,
  variant = 'full',
}: {
  chainId: ChainId | string
  boxSize?: string
  imageProps?: ImageProps
  withTooltip?: boolean
  variant?: 'faded' | 'full'
}) {
  // [Dospore] bit of a hacky way but this makes sure the component is mounted before loading the tooltip
  const [loadedWithTooltip, setLoadedWithTooltip] = useState(false)
  useEffect(() => {
    setLoadedWithTooltip(!!withTooltip)
  }, [])

  const src = useNetworkIcon(chainId)
  const chainConfig = useChainConfig(chainId)

  const icon = useMemo(
    () =>
      src && (
        <Image
          boxSize={boxSize}
          src={src}
          alt={chainConfig?.chainName ?? 'Unknown'}
          opacity={getOpacity(variant, chainConfig?.opacity)}
          {...imageProps}
          key={chainConfig?.chainId ?? chainId}
        />
      ),
    [
      chainId,
      chainConfig?.chainName,
      chainConfig?.opacity,
      chainConfig?.chainId,
      src,
      boxSize,
      imageProps,
      variant,
    ]
  )

  if (chainConfig && loadedWithTooltip) {
    return (
      <Tooltip
        noDelay
        label={
          <>
            <Text as="span">Auction held on </Text>
            <Text as="span" variant="body-sm/semibold">
              {chainConfig.chainName}
            </Text>
          </>
        }
        placement="bottom-end"
        offset={[6, 6]}
        aria-label="auction-network-tooltip"
        shouldWrapChildren
      >
        {icon}
      </Tooltip>
    )
  } else {
    return <>{icon}</>
  }
}
