import { Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { formatFixedUsd } from '../../../../helpers/utils'
import LivePulse from '../../../Auctions/elements/LivePulse'
export default function DepositedPulse({
  total,
  label,
}: {
  total: FixedNumber
  label: string
}) {
  return (
    <VStack align="end" gap={1.5}>
      <HStack>
        <LivePulse />
        <Text variant="body-sm/medium" color="gray.6">
          {label}
        </Text>
      </HStack>
      <Text variant="body-xl/bold" color="blue.9">
        {formatFixedUsd(total)}
      </Text>
    </VStack>
  )
}
