import { Divider, Flex, Text } from '@chakra-ui/react'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import TokenChip from '../../../../components/elements/TokenChip'
import TransactionHash from '../../../../components/elements/TransactionHash'
import { LiquidationForDisplay } from '../../../../data/model'
import { formatFixedToken, formatMaturityDate } from '../../../../helpers/utils'
import Accordion from '../Accordion'
import { Trans, t } from '@lingui/macro'

function LoanLiquidationPreviousRow({
  liquidation,
  getBlockExplorerTransactionUrl,
}: {
  liquidation: LiquidationForDisplay
  getBlockExplorerTransactionUrl?: (address: string) => string
}) {
  return (
    <StatsCard hideBorder>
      <Flex columnGap={2} alignItems="center" px={4} mb={2}>
        <Text
          as="span"
          variant="body-xs/semibold"
          color="gray.6"
          whiteSpace="nowrap"
        >
          {formatMaturityDate(liquidation.timestamp, 'MMMM D, h:mma')}
        </Text>
        <Divider />
      </Flex>
      <StatsRow
        title={
          <>
            <Trans>Debt Covered</Trans>&nbsp;
            <TokenChip token={liquidation.borrowTokenSymbol} />
          </>
        }
      >
        <Text variant="body-sm/normal" as="span" color="blue.9">
          {formatFixedToken(
            liquidation.loanCovered,
            liquidation.borrowTokenSymbol,
            true
          )}
        </Text>
      </StatsRow>
      <StatsRow title={t`Debt Remaining`}>
        <Text variant="body-sm/normal" as="span" color="blue.9">
          {formatFixedToken(
            liquidation.loanRemaining,
            liquidation.borrowTokenSymbol,
            true
          )}
        </Text>
      </StatsRow>
      <StatsRow
        title={
          <>
            <Trans>Collateral Liquidated</Trans>&nbsp;
            <TokenChip token={liquidation.collateralTokenSymbol} />
          </>
        }
      >
        <Flex columnGap={1}>
          <Text variant="body-sm/normal" as="span">
            {formatFixedToken(
              liquidation.collateralTokensLiquidated,
              liquidation.collateralTokenSymbol,
              true
            )}
          </Text>
        </Flex>
      </StatsRow>
      <StatsRow title={t`Transaction`}>
        <TransactionHash
          variant="body-sm/medium"
          color="blue.9"
          getBlockExplorerTransactionUrl={getBlockExplorerTransactionUrl}
          address={liquidation.transactionHash}
          headChars={6}
          tailChars={4}
        />
      </StatsRow>
    </StatsCard>
  )
}

export default function LoanLiquidationPrevious({
  liquidations,
  getBlockExplorerTransactionUrl,
}: {
  liquidations: LiquidationForDisplay[]
  getBlockExplorerTransactionUrl?: (address: string) => string
}) {
  return (
    <Accordion heading={t`Previous Liquidations`} count={liquidations.length}>
      {liquidations.map((liquidation) => (
        <LoanLiquidationPreviousRow
          getBlockExplorerTransactionUrl={getBlockExplorerTransactionUrl}
          key={liquidation.timestamp}
          liquidation={liquidation}
        />
      ))}
    </Accordion>
  )
}
