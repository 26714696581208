import { Modal, ModalOverlay, ModalContent, Divider } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { ModalHeader, ModalBody, ModalFooter } from './modal'
import { components } from '../../../../models/profile-api'

type Props = {
  isOpen: boolean
  onClose: () => void
  averageLoanBalance: FixedNumber
  activeLoanBalance: FixedNumber
  numPositions: number
  season: components['schemas']['Season'] | undefined
}

export default function LendOnTermModal({
  isOpen,
  onClose,
  averageLoanBalance,
  activeLoanBalance,
  numPositions,
  season,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered={true}>
      <ModalOverlay />
      <ModalContent p={5} w="520px">
        <ModalHeader onClose={onClose} />
        <Divider my={6} bg="#0000001a" opacity={1} />
        <ModalBody
          averageLoanBalance={averageLoanBalance}
          activeLoanBalance={activeLoanBalance}
          numPositions={numPositions}
          season={season}
        />
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
