import {
  Box,
  Text,
  Button,
  Heading,
  Flex,
  PinInput,
  PinInputField,
  Link,
} from '@chakra-ui/react'
import { socialLinks } from '../../../../socials'
import { HStack } from '../../../../components/elements/Stack'
import { useCallback, useEffect, useRef, useState } from 'react'

export default function InviteCode({
  onValidateInviteCode,
  validatedInviteCode,
}: {
  onValidateInviteCode: (code: string) => void
  validatedInviteCode?: string
}) {
  const [pinValues, setPinValues] = useState<string[]>(
    validatedInviteCode ? validatedInviteCode.split('') : Array(5).fill('')
  )
  const [isButtonActive, setIsButtonActive] = useState(false)
  const refs = useRef<Array<HTMLInputElement | null>>([])

  useEffect(() => {
    const pinInputValues = refs.current.map((ref) => ref?.value ?? '')
    const isActive =
      pinInputValues.every((value) => value.length > 0) &&
      pinInputValues.reduce((total, value) => total + value.length, 0) === 5
    setIsButtonActive(isActive)
  }, [pinValues])

  // set is active if a validatedInvite code exists
  useEffect(() => {
    if (validatedInviteCode && validatedInviteCode === pinValues.join('')) {
      setIsButtonActive(true)
    }
  }, [])

  // TODO: pasting input code does not work if user starts at later input
  const handleInputChange = (index: number, value: string) => {
    if (/^[a-z0-9]*$/i.test(value)) {
      const newPinValues = [...pinValues]
      if (value === '') {
        newPinValues[index] = ''
      } else {
        const characters = value.split('').slice(0, 5)
        characters.forEach((char, i) => {
          if (index + i < 5) {
            newPinValues[index + i] = char
          }
        })
      }
      setPinValues(newPinValues)
    }
  }

  // allow pressing return key to submit
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isButtonActive) {
        onValidateInviteCode(pinValues.join(''))
      }
    },
    [isButtonActive, onValidateInviteCode, pinValues]
  )

  // cleanup listener on unmount
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, isButtonActive, pinValues])

  return (
    <Flex
      mt={{ base: 10, md: 10, lg: 20, xl: 32, '1xl': 32 }}
      align="center"
      justify="center"
    >
      <Box
        w="540px"
        p={7}
        borderRadius="16px"
        bg="white"
        boxShadow="0px 4px 4px 0px rgba(49, 130, 206, 0.03)"
      >
        <Box mb={10}>
          <Heading variant="heading/h1" as="h1" color="blue.9" mb={2}>
            Earn as you lend
          </Heading>
          <Text variant="body-md/semibold" color="blue.9">
            Enter your invite code{' '}
            <Text as="span" variant="body-md/normal">
              to join the{' '}
              <Link
                href={socialLinks.rewardsAnnouncement}
                textDecoration="none"
                color="blue.5"
                isExternal
              >
                points program
              </Link>{' '}
              and
            </Text>{' '}
            earn as you lend
          </Text>
        </Box>
        <Flex direction="column" align="center" justify="center">
          <HStack justify="space-between" alignSelf="stretch">
            <PinInput
              type="alphanumeric"
              placeholder={''}
              value={pinValues.join('')}
            >
              {/* @dev setting value on PinInputField does not render the validatedInviteCode */}
              {pinValues.map((_, index) => (
                <PinInputField
                  ref={(el) => (refs.current[index] = el)}
                  key={index}
                  border={0}
                  borderRadius={0}
                  borderBottom={'solid 1px'}
                  borderColor="blue.5"
                  color="blue.9"
                  textTransform="uppercase"
                  w="60px"
                  height="40px"
                  _focus={{ boxShadow: 'none' }}
                  _hover={{ borderBottom: 'solid 1px', borderColor: 'blue.5' }}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              ))}
            </PinInput>
          </HStack>
          <Button
            variant="primary"
            w="full"
            mt={10}
            height="40px"
            pointerEvents={isButtonActive ? 'auto' : 'none'}
            opacity={isButtonActive ? 1 : 0.5}
            onClick={() => {
              onValidateInviteCode(pinValues.join(''))
            }}
          >
            Join
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}
