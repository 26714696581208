import discord_icon from './assets/icons/discord.svg'
import gitbook_icon from './assets/icons/gitbook.svg'
import linkedin_icon from './assets/icons/linkedin.svg'
import telegram_icon from './assets/icons/telegram.svg'
import twitter_icon from './assets/icons/twitter-x.svg'

export const socialLinks = {
  gitbook: 'https://docs.term.finance',
  twitter: 'https://twitter.com/term_labs',
  announcementsTelegram: 'https://t.me/+Q8nMs4zuM5tmMjAx',
  linkedin: 'https://www.linkedin.com/company/term-labs-inc/',
  youtube: 'https://www.youtube.com/@TermFinance',
  faq: 'https://docs.term.finance/protocol/faq',
  website: 'https://termfinance.io/',
  discord: 'https://discord.gg/cFVMQNHRsx',
  debankBaseUrl: 'https://debank.com/profile/',
  deployedContracts: 'https://developers.term.finance/deployed-contracts',
  bombPot: 'https://www.term.finance/post/bombpot',
  rewardsAnnouncement: 'https://www.term.finance/post/points',
  auctionCalendar: 'https://app.term.finance/',
}

export const bombPotLinks: { [key: string]: string } = {
  wsteth: 'https://www.term.finance/post/bombpot',
  btrfly: 'https://www.term.finance/post/bombpot-redacted',
}

// this allows you to add more tweets in the future
export enum RewardedTweet {
  launchTweet = '1810641244227285427',
  walletAnalyzerTweet = '1825631343318868286',
}

export const constructRetweetUrl = (tweet: RewardedTweet) => {
  return `https://x.com/intent/retweet?tweet_id=${tweet}`
}

// Footer Social Links

const socialLinkContent = [
  {
    alt: 'Gitbook',
    href: socialLinks.gitbook,
    icon: gitbook_icon,
  },
  {
    alt: 'X',
    href: socialLinks.twitter,
    icon: twitter_icon,
  },
  {
    alt: 'Telegram',
    href: socialLinks.announcementsTelegram,
    icon: telegram_icon,
  },
  {
    alt: 'LinkedIn',
    href: socialLinks.linkedin,
    icon: linkedin_icon,
  },
  {
    alt: 'Discord',
    href: socialLinks.discord,
    icon: discord_icon,
  },
]

export default socialLinkContent
