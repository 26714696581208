/**
 * Helper method to check if the app is loaded through Gnosis Safe App
 * @returns true if the app is viewed through Safe
 */
export function isRenderedInSafeApp(): boolean {
  return (
    window !== window.parent &&
    window.location.ancestorOrigins.length > 0 &&
    window.location.ancestorOrigins[0] === 'https://app.safe.global'
  )
}
