import { Box, Button, Center, Flex, Grid, Link, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { BigNumber, FixedNumber } from 'ethers'
import { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Term } from '../../'
import AuctionBoxText from '../../../../components/elements/AuctionBoxText'
import AuctionTermBadge from '../../../../components/elements/AuctionTermBadge'
import HighLTVLabel from '../../../../components/elements/HighLTVLabel'
import NetworkIcon from '../../../../components/elements/NetworkIcon'
import { HStack, VStack } from '../../../../components/elements/Stack'
import TokenIconLabel from '../../../../components/elements/TokenLabelIcon'
import Tooltip from '../../../../components/elements/Tooltip'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import {
  Auction,
  AuctionActivityData,
  AuctionStatus,
  Currency,
  TermPeriod,
} from '../../../../data/model'
import { formatFixed, bigToFixedNumber } from '../../../../helpers/conversions'
import { divide, multiply } from '../../../../helpers/math'
import {
  formatFixedPercentage,
  getAuctionStatus,
  getCurrency,
  hasEModeLabel,
  hasHighLTVLabel,
} from '../../../../helpers/utils'
import { useConfig } from '../../../../providers/config'
import LiveAuctionResults from '../LiveAuctionResults'
import AuctionBoxHeading from './AuctionBoxHeading'
import BombPotIcon from './BombPotIcon'
import ExternalProjectPoints from '../../../../components/elements/ExternalProjectPoints'

export default function AuctionBox({
  currencies,
  auction,
  term,
  auctionActivity,
  onViewAuction,
  onViewAuctionModal: onViewAuctionModal_,
}: {
  currencies: { [chainId: string]: Currency[] }
  auction: Auction
  term: Pick<
    TermPeriod,
    | 'id'
    | 'repoServicerAddress'
    | 'rolloverManagerAddress'
    | 'collateralManagerAddress'
    | 'termRepoLockerAddress'
    | 'termRepoTokenAddress'
  >
  auctionActivity?: AuctionActivityData
  onViewAuction: () => void
  onViewAuctionModal: (
    auctionStatus: AuctionStatus,
    auction: Auction,
    term: Term
  ) => void
}) {
  const now = useCurrentTime()

  const config = useConfig()
  const chainConfig = config.chains[auction.chainId]

  const auctionStatus = getAuctionStatus(
    auction.auctionStartTimestamp,
    auction.auctionRevealTimestamp,
    auction.auctionEndTimestamp,
    now,
    auction.closed
  )

  const purchaseCurrency = getCurrency(
    currencies?.[auction.chainId],
    auction.purchaseCurrency
  )
  const collateralCurrency = getCurrency(
    currencies?.[auction.chainId],
    auction.collateralCurrency
  )
  const isHighLTVAuction = hasHighLTVLabel(
    purchaseCurrency?.symbol ?? '',
    collateralCurrency?.symbol ?? '',
    auction.maintenanceMarginRatio
  )
  const isEModeAuction = hasEModeLabel(
    purchaseCurrency?.symbol ?? '',
    collateralCurrency?.symbol ?? '',
    auction.maintenanceMarginRatio
  )

  const [
    totalBidAmountUsd,
    totalOfferAmountUsd,

    totalAccountBidAmountUsd,
    totalAccountOffersAmountUsd,
  ] = useMemo(() => {
    const bidUsd = multiply(
      bigToFixedNumber(
        auctionActivity?.bidsAmount ?? BigNumber.from(0),
        getCurrency(currencies[auction.chainId], auction.purchaseCurrency)
          ?.decimals ?? 18
      ),
      bigToFixedNumber(
        auction.purchaseCurrencyOraclePriceUSDC,
        auction.purchaseCurrencyOraclePriceDecimals
      ),
      2
    )

    const accountBid = bigToFixedNumber(
      auctionActivity?.accountBidsAmount ?? BigNumber.from(0),
      getCurrency(currencies[auction.chainId], auction.purchaseCurrency)
        ?.decimals ?? 18
    )

    const offerUsd = multiply(
      bigToFixedNumber(
        auctionActivity?.offersAmount ?? BigNumber.from(0),
        getCurrency(currencies[auction.chainId], auction.purchaseCurrency)
          ?.decimals ?? 18
      ),
      bigToFixedNumber(
        auction.purchaseCurrencyOraclePriceUSDC,
        auction.purchaseCurrencyOraclePriceDecimals
      ),
      2
    )

    const accountOffer = bigToFixedNumber(
      auctionActivity?.accountOffersAmount ?? BigNumber.from(0),
      getCurrency(currencies[auction.chainId], auction.purchaseCurrency)
        ?.decimals ?? 18
    )

    return [bidUsd, offerUsd, accountBid, accountOffer]
  }, [
    auctionActivity?.bidsAmount,
    auctionActivity?.accountBidsAmount,
    auctionActivity?.offersAmount,
    auctionActivity?.accountOffersAmount,
    currencies,
    auction.chainId,
    auction.purchaseCurrency,
    auction.purchaseCurrencyOraclePriceUSDC,
    auction.purchaseCurrencyOraclePriceDecimals,
  ])

  const purchaseToken = useMemo(
    () =>
      getCurrency(currencies[auction.chainId], auction.purchaseCurrency)
        ?.symbol ?? '',
    [currencies, auction.chainId, auction.purchaseCurrency]
  )
  const collatToken = useMemo(
    () =>
      getCurrency(currencies[auction.chainId], auction.collateralCurrency)
        ?.symbol ?? '',
    [currencies, auction.chainId, auction.collateralCurrency]
  )

  const onViewAuctionModal = () => {
    onViewAuctionModal_(auctionStatus.status, auction, term)
  }

  const bombPotAuction =
    auction.bombPotAuction &&
    auction.bombPotAmount &&
    auction.bombPotRewardTokenSymbol
  const bombPotAuctionStyles = {
    border: '1px',
    borderColor: 'rgba(0, 163, 255, 0.40)',
    boxShadow: '0px 0px 20px 0px rgba(0, 153, 255, 0.16)!important',
    bg: chainConfig.gradient,
  }

  const closesText = dayjs.unix(auction.auctionRevealTimestamp).from(now)
  const addExtraSpace = closesText.includes('in a few seconds')

  return (
    <>
      <Flex
        key={auction.auctionId}
        w="full"
        borderRadius="6px"
        boxShadow={'0px 4px 4px rgba(49, 130, 206, 0.03)'}
        background={chainConfig.gradient}
        sx={bombPotAuction ? bombPotAuctionStyles : undefined}
      >
        <Flex
          w="full"
          justifyContent="space-between"
          pr={{
            base: 0,
            xl: 4,
          }}
          flexDir={{
            base: 'column',
            xl: 'row',
          }}
          position="relative"
        >
          {bombPotAuction && (
            <Box
              px={2}
              py={1}
              borderRadius="6px"
              border="1px"
              borderColor="blue.4"
              bg="cyan.50"
              w="40px"
              position="absolute"
              right={'-20px'}
              top={'-20px'}
            >
              <BombPotIcon
                auctionEndTimestamp={auction.auctionEndTimestamp}
                bombPotAmount={auction.bombPotAmount as number}
                bombPotRewardTokenSymbol={
                  auction.bombPotRewardTokenSymbol as string
                }
              />
            </Box>
          )}
          {auction.project && (
            <Box position="absolute" right={'-16px'} top={'-20px'}>
              <ExternalProjectPoints project={auction.project} />
            </Box>
          )}
          <Flex
            gap={{
              base: '0',
              xl: '30px',
            }}
            flexDirection={{
              base: 'column',
              xl: 'row',
            }}
          >
            <Box
              h="100%"
              pt={{
                base: '8px',
                xl: '22px',
              }}
              px={{
                base: '16px',
                xl: '22px',
              }}
              pb={{
                base: '8px',
                xl: '17px',
              }}
              borderRight={{
                base: 'none',
                xl: '1px solid',
              }}
              borderBottom={{
                base: '1px solid',
                xl: 'none',
              }}
              borderBottomColor={{
                base: 'gray.2',
                xl: 'none',
              }}
              borderRightColor={{
                base: 'none',
                xl: 'gray.2',
              }}
              w={{
                base: '100%',
                xl: '96px',
              }}
            >
              <Center
                flexDirection="column"
                display={{
                  base: 'none',
                  xl: 'flex',
                }}
              >
                <Text variant="body-xs/semibold" color="gray.6">
                  {auctionStatus.status === 'upcoming' ||
                  auctionStatus.status === 'live'
                    ? 'Closes'
                    : 'Closed'}
                </Text>
                <Text variant="body-2xl/bold" color="black">
                  {dayjs.unix(auction.auctionRevealTimestamp).format('DD')}
                </Text>
                <Text variant="body-xs/normal" color="black">
                  {dayjs
                    .unix(auction.auctionRevealTimestamp)
                    .format('MMM')
                    .toUpperCase()}
                </Text>
              </Center>
              <HStack
                display={{
                  base: 'flex',
                  xl: 'none',
                }}
                w="full"
                justifyContent="center"
              >
                <Text as="span" variant="body-md/normal" color="blue.9">
                  {auctionStatus.status === 'upcoming' ||
                  auctionStatus.status === 'live'
                    ? 'Closes'
                    : 'Closed'}
                </Text>
                <Text as="span" variant="body-md/semibold" color="blue.9">
                  {dayjs.unix(auction.auctionRevealTimestamp).format('DD MMM')}
                </Text>
              </HStack>
            </Box>
            <Grid
              flexGrow={1}
              gridTemplateColumns={{
                base: '1fr 1fr',
                xl: 'repeat(6, auto)',
              }}
              gap={{
                base: '16px',
                '1xl': '20px',
              }}
              w="100%"
              py={{
                base: '10px',
                xl: '20px',
              }}
              pl={{
                base: '10px',
                xl: '0',
              }}
            >
              {/* Loan Term */}
              <Flex
                flexDirection="column"
                alignItems="left"
                justifyContent="center"
                px={{
                  base: '20px',
                  xl: '0',
                }}
                width={{ xl: '120px' }}
              >
                <AuctionBoxHeading title="Loan Term" />
                <AuctionTermBadge auction={auction} />
              </Flex>
              {/* Maturity */}
              <Flex
                flexDirection="column"
                alignItems="left"
                justifyContent="center"
                px={{
                  base: '20px',
                  xl: '0',
                }}
                width={{ xl: '120px' }}
              >
                <AuctionBoxHeading title="Maturity" />
                <AuctionBoxText>
                  {dayjs.unix(auction.maturityTimestamp).format('MMM D, ha')}
                </AuctionBoxText>
              </Flex>
              {/* Supply / Borrow */}
              <Flex
                flexDirection="column"
                alignItems="left"
                justifyContent="center"
                px={{
                  base: '20px',
                  xl: '0',
                }}
                width={{ xl: '120px' }}
              >
                <AuctionBoxHeading title="Supply / Borrow" />
                <VStack justify="center" align="start">
                  <TokenIconLabel key={purchaseToken} token={purchaseToken} />
                </VStack>
              </Flex>
              {/* Collateral */}
              <Flex
                flexDirection="column"
                alignItems="left"
                justifyContent="center"
                px={{
                  base: '20px',
                  xl: '0',
                }}
                width={{ xl: '100px' }}
              >
                <AuctionBoxHeading title="Collateral" />
                <HStack justify="space-between" align="center">
                  <VStack justify="center" align="start">
                    <TokenIconLabel
                      key={collatToken}
                      token={collatToken}
                      isSkipTruncation
                    />
                  </VStack>
                  {auctionStatus.status !== 'closed' && isHighLTVAuction && (
                    <VStack w="64px" display={{ base: 'flex', xl: 'none' }}>
                      <HighLTVLabel
                        ltvRatio={divide(
                          FixedNumber.fromString(
                            '1',
                            auction.maintenanceMarginRatio?.format
                          ),
                          auction.maintenanceMarginRatio
                        )}
                        maintenanceCollateralRatio={
                          auction.maintenanceMarginRatio
                        }
                      />
                    </VStack>
                  )}
                </HStack>
              </Flex>
              {/* High LTV value label */}
              {auctionStatus.status !== 'closed' &&
                (isHighLTVAuction || isEModeAuction) && (
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-end"
                    display={{
                      base: 'none',
                      xl: 'flex',
                    }}
                    px={{
                      base: '20px',
                      xl: '0',
                    }}
                  >
                    <VStack w="64px" h="32px" justify="center" align="start">
                      <HighLTVLabel
                        ltvRatio={divide(
                          FixedNumber.fromString(
                            '1',
                            auction.maintenanceMarginRatio?.format
                          ),
                          auction.maintenanceMarginRatio
                        )}
                        maintenanceCollateralRatio={
                          auction.maintenanceMarginRatio
                        }
                      />
                    </VStack>
                  </Flex>
                )}
            </Grid>
          </Flex>
          {/* live auction metrics */}
          {auctionStatus.status === 'live' && (
            <Flex
              flex={1}
              justifyContent="flex-end"
              alignItems="baseline"
              display={{
                base: 'none',
                xl: 'flex',
              }}
              pt="40px"
              marginRight="-30px"
            >
              <LiveAuctionResults
                totalBids={auctionActivity?.bidsCount ?? 0}
                totalBidAmountUsd={totalBidAmountUsd}
                totalOffers={auctionActivity?.offersCount ?? 0}
                totalOfferAmountUsd={totalOfferAmountUsd}
                repoLockerAddress={auction.repoLockerAddress}
              />
            </Flex>
          )}
          {/* call to action */}
          <Flex
            flexDirection="column"
            textAlign="right"
            justifyContent="center"
            mt={{
              base: 5,
              xl: 0,
            }}
            px={{
              base: 4,
              xl: 0,
            }}
            pb={{
              base: 4,
              xl: 0,
            }}
          >
            <HStack
              spacing={1}
              justify={
                auctionStatus.status === 'live' ? 'space-between' : 'center'
              }
              px={{
                base: 4,
                xl: 0,
              }}
            >
              {/* small screen live auction metrics */}
              {auctionStatus.status === 'live' && (
                <Flex
                  width="66.66%"
                  display={{
                    base: 'flex',
                    xl: 'none',
                  }}
                >
                  <LiveAuctionResults
                    totalBids={auctionActivity?.bidsCount ?? 0}
                    totalBidAmountUsd={totalBidAmountUsd}
                    totalOffers={auctionActivity?.offersCount ?? 0}
                    totalOfferAmountUsd={totalOfferAmountUsd}
                    repoLockerAddress={auction.repoLockerAddress}
                  />
                </Flex>
              )}
              <HStack>
                {auctionStatus.status === 'live' ||
                auctionStatus.status === 'upcoming' ? (
                  <Tooltip
                    noDelay
                    label={
                      <>
                        <Text as="span">
                          {auctionStatus.status === 'live'
                            ? 'Closes '
                            : 'Opens '}
                        </Text>
                        <Text as="span" variant="body-sm/semibold">
                          {dayjs
                            .unix(
                              auctionStatus.status === 'live'
                                ? auction.auctionRevealTimestamp
                                : auction.auctionStartTimestamp
                            )
                            .format('MMM D, h:mma')}
                        </Text>
                      </>
                    }
                    placement="bottom-end"
                    offset={[6, 6]}
                    aria-label="auction-network-tooltip"
                    shouldWrapChildren
                  >
                    <Text
                      variant={{
                        base: 'body-xs/normal',
                        xl: 'body-sm/medium',
                      }}
                      color="gray.6"
                      mb={1}
                      mr={addExtraSpace ? 1 : 0}
                      w={{
                        base: '100%',
                        xl: '150px',
                      }}
                      textAlign={{
                        base: 'center',
                        xl: 'right',
                      }}
                      whiteSpace="nowrap"
                    >
                      {auctionStatus.status === 'live' && (
                        <>
                          Closes{' '}
                          {dayjs.unix(auction.auctionRevealTimestamp).from(now)}
                        </>
                      )}
                      {auctionStatus.status === 'upcoming' && (
                        <>
                          Opens{' '}
                          {dayjs.unix(auction.auctionStartTimestamp).from(now)}
                        </>
                      )}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text
                    variant={{
                      base: 'body-xs/normal',
                      xl: 'body-sm/medium',
                    }}
                    color="gray.6"
                    mb="4px"
                    mr={addExtraSpace ? 1 : 0}
                    w={{
                      base: '100%',
                      xl: '150px',
                    }}
                    textAlign={{
                      base: 'center',
                      xl: 'right',
                    }}
                    whiteSpace="nowrap"
                  >
                    {auctionStatus.status === 'closed' && (
                      <>
                        Cleared{' '}
                        {dayjs.unix(auction.auctionRevealTimestamp).from(now)}
                      </>
                    )}
                  </Text>
                )}
                <Flex
                  align="center"
                  justify="center"
                  mb={'0.25rem!important'}
                  mr={addExtraSpace ? 1 : 0}
                >
                  <NetworkIcon
                    key={chainConfig.chainId}
                    chainId={chainConfig.chainId}
                    boxSize={'20px'}
                    variant="faded"
                    withTooltip
                  />
                </Flex>
              </HStack>
            </HStack>
            {auctionStatus.status === 'clearing' ? (
              <Tooltip
                label={
                  <>
                    You can no longer participate as this auction is clearing.
                    View your tenders in{' '}
                    <Link as={RouterLink} to="/portfolio/offers">
                      your portfolio
                    </Link>
                  </>
                }
                aria-label="auction-clearing-tooltip"
                shouldWrapChildren
              >
                <Button
                  size="md"
                  variant="secondary"
                  alignSelf="flex-end"
                  w={{
                    base: '100%',
                    xl: '141px',
                  }}
                  rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
                  isDisabled
                >
                  Participate
                </Button>
              </Tooltip>
            ) : auctionStatus.status === 'live' ? (
              // Show edit tenders button if current wallet has placed tenders
              auctionActivity?.accountBidsCount ||
              auctionActivity?.accountOffersCount ? (
                <Tooltip
                  noDelay
                  label={
                    <VStack>
                      {auctionActivity?.accountBidsCount &&
                        !totalAccountBidAmountUsd.isZero() && (
                          <Text>
                            Bids ({auctionActivity?.accountBidsCount}){' '}
                            {formatFixed(totalAccountBidAmountUsd)}{' '}
                            {purchaseCurrency?.symbol}
                          </Text>
                        )}
                      {auctionActivity?.accountOffersCount &&
                        !totalAccountOffersAmountUsd.isZero() && (
                          <Text>
                            Offers ({auctionActivity?.accountOffersCount}){' '}
                            {formatFixed(totalAccountOffersAmountUsd)}{' '}
                            {purchaseCurrency?.symbol}
                          </Text>
                        )}
                    </VStack>
                  }
                  aria-label="auction-activity-tooltip"
                  shouldWrapChildren
                >
                  <Button
                    onClick={onViewAuction}
                    size="md"
                    variant="tertiary"
                    alignSelf="flex-end"
                    w={{
                      base: '100%',
                      xl: '141px',
                    }}
                    rightIcon={
                      <FontAwesomeIcon icon={['fal', 'angle-right']} />
                    }
                  >
                    Edit tenders
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={onViewAuction}
                  size="md"
                  variant="secondary"
                  alignSelf="flex-end"
                  w={{
                    base: '100%',
                    xl: '141px',
                  }}
                  rightIcon={<FontAwesomeIcon icon={['fal', 'angle-right']} />}
                >
                  Participate
                </Button>
              )
            ) : auctionStatus.status === 'closed' ? (
              <HStack
                gap="4.4px"
                w="100%"
                pr={{ base: 0, xl: '12px' }}
                justifyContent={{ base: 'center', xl: 'end' }}
                onClick={onViewAuctionModal}
              >
                <Text
                  variant="body-md/semibold"
                  color="blue.5"
                  cursor="pointer"
                >
                  {formatFixedPercentage(
                    bigToFixedNumber(auction.auctionClearingRate, 18),
                    3
                  )}
                </Text>
              </HStack>
            ) : (
              <Button
                onClick={onViewAuctionModal}
                size="md"
                variant="tertiary"
                alignSelf="flex-end"
                w={{
                  base: '100%',
                  xl: '141px',
                }}
              >
                View details
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
