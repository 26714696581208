import { HStack, Flex, Skeleton } from '@chakra-ui/react'

export const PointsCardLoadingLarge = () => (
  <Flex
    direction="column"
    align="start"
    justify="space-between"
    w="100%"
    h="259px"
    borderRadius="6px"
    padding={3}
    bg={'gray.0'}
  >
    <Skeleton h="24px" w="189px" mt="116px" />
    <HStack justifyContent="space-between" w="full">
      <Skeleton h="28px" w="119px" />
      <Skeleton h="40px" w="100px" />
    </HStack>
  </Flex>
)

export const PointsCardLoadingSmall = () => (
  <Flex
    direction="column"
    align="start"
    justify="space-between"
    minW="221px"
    height="259px"
    borderRadius="6px"
    padding={3}
    bg={'gray.0'}
  >
    <Skeleton h="28px" w="90px" />
    <Skeleton h="48px" w="189px" mb="auto" mt="75px" />
    <Skeleton h="24px" w="85px" />
  </Flex>
)
