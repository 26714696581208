import {
  Button,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { externalLinks } from '../../../../links'
import { useStorage } from '../../../../providers/storage'
import { Trans } from '@lingui/macro'

function AuctionModal({
  isOpen,
  account,
  onClose,
  onAcceptance,
}: {
  isOpen: boolean
  account: string | undefined
  onClose: () => void
  onAcceptance: () => void
}) {
  const { storage } = useStorage()

  const liquidationRiskAcknowledgement = () => {
    if (account) {
      const liquidationRiskAcceptedWalletKey = `liquidationRiskAccepted-${account}`
      storage.setItem(liquidationRiskAcceptedWalletKey, 'true')
      onAcceptance()
      onClose()
    } else {
      console.error('no account defined')
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent borderRadius="md" p="28px">
        <ModalHeader p="0px">
          <HStack justifyContent="space-between">
            <Heading variant="heading/h3" as="h3" color="blue.9">
              <Trans>Acknowledgement of Liquidation Process</Trans>
            </Heading>
            <ModalCloseButton
              position="relative"
              top="initial"
              right="initial"
            />
          </HStack>
        </ModalHeader>
        <ModalBody p="0px" mt="20px">
          <Text variant="body-sm/normal">
            <Trans>
              By signing this transaction & all future bid transactions, I
              represent that I understand that in the event I borrow any
              cryptoassets, my collateral that is locked in the relevant smart
              contract is subject to immediate and automatic liquidation,
              without notice, should the conditions for liquidation be met.
            </Trans>
            <br />
            <Link
              isExternal
              href={externalLinks.liquidation}
              textDecoration="none"
            >
              <Trans>Learn more about the liquidation process</Trans>
            </Link>
          </Text>
        </ModalBody>
        <ModalFooter p="0px" mt="20px" w="full">
          <Button
            variant="primary"
            size="sm"
            w="full"
            onClick={liquidationRiskAcknowledgement}
          >
            <Trans>Agree</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AuctionModal
