import { Button, Text } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'

import EditButton from './EditButton'

export default function SelectingButtons({
  selectedRows,
  onDelete,
  onEdit,
}: {
  selectedRows: string[]
  onDelete: () => void
  onEdit: () => void
}) {
  return (
    <HStack spacing={0}>
      <Button
        isDisabled={!selectedRows.length}
        variant="link"
        onClick={onDelete}
        size="xs"
        px={2}
      >
        <Text variant="body-xs/semibold" color="blue.5">
          Delete
        </Text>
      </Button>
      <EditButton
        isDisabled={selectedRows.length === 0}
        onClick={onEdit}
        size="xs"
      />
    </HStack>
  )
}
