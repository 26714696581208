import { useCallback } from 'react'
import {
  JsonRestQueryNode,
  JsonRestResponseNode,
  flattenQueryNode,
  normalizeFlatResults,
} from './rest-query'
import useSWR, { mutate } from 'swr'

async function multiJsonFetcher<
  Q extends JsonRestQueryNode = JsonRestQueryNode,
  R extends JsonRestResponseNode = JsonRestResponseNode,
>(query: Q): Promise<R | undefined> {
  const { calls, paths } = flattenQueryNode(query)
  const promises = calls.map((request: RequestInfo) => {
    // @ts-ignore [DOSPORE] RequestInfo does not have these types but this is the object passed in to useJsonRestCalls
    const { url, ...options } = request
    return fetch(url, options).then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(
            JSON.stringify({
              ...error,
              status: response.status,
            })
          )
        })
      }
      return response.json()
    })
  })
  const results: R[] = await Promise.all(promises)
  return normalizeFlatResults(results, paths)
}

/**
 * The useJsonRestCalls hook is a helper function that takes a JSON REST query document and
 * returns the results of the query. It uses the fetch API internally to make the necessary
 * calls to the REST API.
 *
 * This hook provides a simple way to batch up queries to a REST API and avoid having to deal
 * with manually serializing all calls to a single call array as required by fetch. Instead its
 * possible to define a query document that mirrors the structure of the data you want to retrieve.
 *
 * For example:
 *
 * ```typescript
 * const query = {
 *   example: {
 *     key: {
 *       "#call": {
 *         url: "https://api.example.com/token",
 *         method: "GET"
 *       }
 *     }
 *   }
 * }
 * const result = useJsonRestCalls(query)
 * ```
 *
 * should yield a result of the form:
 *
 * ```json
 * {
 *   "example": {
 *     "key": { "balance": 100 }
 *   }
 * }
 * ```
 *
 * @param query The JSON REST query document to execute. See the rest-query.ts file for more
 *              information on the query language.
 * @returns The results of the query.
 */
export function useJsonRestCalls<
  Q extends JsonRestQueryNode = JsonRestQueryNode,
  R extends JsonRestResponseNode = JsonRestResponseNode,
>(
  query: Q,
  options: {
    dependencies?: any[]
  } = {}
): {
  data: R | undefined
  error: any
  isLoading: boolean
  refresh: () => void
} {
  // @dev [DOSPORE] these are available but wanted to keep the return type the same as Robert
  const { data, error, isLoading } = useSWR<R | undefined>(
    query,
    multiJsonFetcher
  )

  const refresh = useCallback(() => {
    mutate(query)
  }, [query])

  return {
    data,
    error,
    isLoading,
    refresh,
  }
}
