import { Box, Flex, HStack, Skeleton, Text, VStack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { TableHead, Th, Tr } from '../../../../components/elements/Table'
import {
  PointsCategory,
  formatNumber,
  pointsCategoryFactory,
} from '../../utils'
import ProgressDoughnut from '../ProgressDoughnut'
export default function TableHeader({
  category,
  title,
  totalPoints,
  multiplier,
  isTableBodyVisible,
  percentOfTotalPointsEarned,
  maxColWidth = 100,
  handleClick,
  handleColumnWidth,
  isLoading,
}: {
  category: PointsCategory
  title: string
  totalPoints?: number
  multiplier?: number
  isTableBodyVisible: boolean
  percentOfTotalPointsEarned?: number
  maxColWidth?: number
  handleClick: () => void
  handleColumnWidth?: (width: number) => void
  isLoading?: boolean
}) {
  const ref = useRef<HTMLDivElement>(null)
  const [maxW, setMaxW] = useState(maxColWidth)

  useEffect(() => {
    const width = ref.current?.clientWidth || 0
    if (handleColumnWidth && width > maxColWidth) {
      handleColumnWidth(width)
    }
  }, [maxColWidth])

  useEffect(() => {
    if (maxColWidth !== undefined) {
      setMaxW(maxColWidth)
    }
  }, [maxColWidth])
  return (
    <TableHead onClick={handleClick} cursor="pointer">
      <Tr noBorder _hover={{}}>
        <Th colSpan={3} textTransform="none" p={0}>
          <Flex align="start" justifyContent="space-between">
            {/* first column */}
            <VStack align="start" height="full" w="400px" px={4}>
              <HStack>
                <Box
                  color="blue.9"
                  as="span"
                  transform={
                    isTableBodyVisible ? 'rotate(0deg)' : 'rotate(180deg)'
                  }
                  transition="transform 0.2s"
                >
                  <FontAwesomeIcon
                    icon={['far', 'chevron-up']}
                    role="button"
                    width="20px"
                  />
                </Box>
                <Text color="blue.9" variant="body-l/semibold">
                  <Text as="span" textTransform="capitalize">
                    {category === 'invites' ? 'Invite' : category}
                  </Text>{' '}
                  points
                </Text>
              </HStack>
              <Text
                color="gray.6"
                variant="body-md/normal"
                whiteSpace="normal"
                w="350px"
              >
                {title}
              </Text>
            </VStack>
            {/* second column */}
            <HStack align="start" height="full" flex="1">
              <VStack align="start" height="full" w={maxW} mr={12}>
                {isLoading ? (
                  <Skeleton isLoaded={!isLoading} w="42px" h="36px" />
                ) : (
                  <Text
                    color="gray.6"
                    variant="body-3xl/semibold"
                    fontSize="32px"
                    ref={ref}
                  >
                    {totalPoints || totalPoints === 0
                      ? formatNumber(totalPoints, true, undefined, true)
                      : '-'}
                  </Text>
                )}
                <Text color="gray.6" variant="body-sm/normal">
                  Points earned <br /> from {category}
                </Text>
              </VStack>
              {!isLoading && totalPoints !== 0 && (
                <>
                  <VStack align="start" height="full" w="150px">
                    <Text
                      color="gray.6"
                      variant="body-3xl/semibold"
                      fontSize="32px"
                    >
                      {percentOfTotalPointsEarned
                        ? percentOfTotalPointsEarned > 0 &&
                          percentOfTotalPointsEarned < 1
                          ? '< 1%'
                          : Math.round(percentOfTotalPointsEarned) + '%'
                        : '-'}
                    </Text>
                    <Text
                      color="gray.6"
                      variant="body-sm/normal"
                      flex="1"
                      w="150px"
                      whiteSpace="normal"
                    >
                      Of your total points
                      <br />
                      earned from {category}
                    </Text>
                  </VStack>
                  <ProgressDoughnut
                    color={pointsCategoryFactory(category)}
                    totalValue={100}
                    value={percentOfTotalPointsEarned || 0}
                  />
                </>
              )}
            </HStack>
          </Flex>
          {/* shown if auction table + has multiplier */}
          {category === 'auctions' && multiplier && (
            <Flex ml="auto">
              <VStack align="end" height="full" w={'fit-content'}>
                {isLoading ? (
                  <Skeleton isLoaded={!isLoading} w="42px" h="36px" />
                ) : (
                  <Text
                    align="right"
                    color="gray.6"
                    variant="body-3xl/semibold"
                    fontSize="32px"
                  >
                    {multiplier}x
                  </Text>
                )}
                <Text align="right" color="gray.6" variant="body-sm/normal">
                  Multiplier applied
                  <br />
                  to this category
                </Text>
              </VStack>
            </Flex>
          )}
        </Th>
      </Tr>
    </TableHead>
  )
}
