import { BigNumber, FixedNumber } from 'ethers'
import { Currency } from '../../../../data/model'
import { ReactNode } from 'react'
import { bigToFixedNumber } from '../../../../helpers/conversions'
import { evaluate } from '../../../../helpers/math'
import { Divider, Text, Tooltip } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { formatFixedToken } from '../../../../helpers/utils'

export default function WrappingTooltip({
  unwrappedTokenSymbol,
  unwrappedTokenBalance,
  wrappedCurrency,
  wrappedTokenBalance,
  availTokensToSupply,
  children,
}: {
  unwrappedTokenSymbol: string
  unwrappedTokenBalance: FixedNumber
  wrappedCurrency: Currency
  wrappedTokenBalance: BigNumber
  availTokensToSupply: BigNumber
  children?: ReactNode
}) {
  const wrappedTokenBalanceFN = bigToFixedNumber(
    wrappedTokenBalance,
    wrappedCurrency.decimals
  )
  const wrappedBalanceFN: FixedNumber = wrappedTokenBalance.gt(0)
    ? wrappedTokenBalanceFN
    : FixedNumber.fromString('0', wrappedCurrency.decimals)

  const unwrappedBalanceFN: FixedNumber = wrappedTokenBalance.gte(0)
    ? unwrappedTokenBalance
    : evaluate({
        nodeKind: 'add', // adding due to negative value
        args: [
          {
            nodeKind: 'value',
            value: unwrappedTokenBalance,
          },
          {
            nodeKind: 'value',
            value: wrappedTokenBalanceFN,
          },
        ],
      })

  return (
    <Tooltip
      hasArrow
      bg="blue.900"
      color="white"
      minW="160px"
      aria-label="wrapped-unwrapped-gas-token-balances"
      label={
        <VStack alignItems="flex-start" w="full" color="white">
          {/* Unwrapped */}
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/medium" color="gray.5">
              {unwrappedTokenSymbol}:{' '}
            </Text>
            <Text as="span" variant="body-sm/bold" color="white">
              {formatFixedToken(
                unwrappedBalanceFN,
                unwrappedTokenSymbol,
                true,
                true
              )}
            </Text>
          </HStack>
          {/* Wrapped */}
          <HStack
            justifyContent="space-between"
            w="full"
            mb={0}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/medium" color="gray.5">
              {wrappedCurrency.symbol}:{' '}
            </Text>
            <Text as="span" variant="body-sm/bold" color="white">
              {formatFixedToken(
                wrappedBalanceFN,
                wrappedCurrency.symbol,
                true,
                true
              )}
            </Text>
          </HStack>
          <Divider color="white" />
          {/* Total */}
          <HStack
            justifyContent="space-between"
            w="full"
            mb="0 !important"
            mt={4}
          >
            <Text as="span" variant="body-sm/medium" color="gray.5">
              Total:{' '}
            </Text>
            <Text as="span" variant="body-sm/bold" color="white">
              {formatFixedToken(
                bigToFixedNumber(availTokensToSupply, wrappedCurrency.decimals),
                wrappedCurrency.symbol,
                true,
                true
              )}
            </Text>
          </HStack>
        </VStack>
      }
    >
      {children}
    </Tooltip>
  )
}
