import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export type RefreshingDependency = {}
export type RefreshingDependencies = {
  slow: RefreshingDependency
  fast: RefreshingDependency
  refreshNow: () => void
}

const defaultDependency = {}
const defaultRefreshingDependencies: RefreshingDependencies = {
  slow: {},
  fast: {},
  refreshNow: () => {},
}

export function useAutoRefresher(
  interval: number = 30000,
  defaultDep: RefreshingDependency = defaultDependency
) {
  const [update, setUpdate] = useState<RefreshingDependency>(defaultDep)
  const forceUpdate = useCallback(() => {
    setUpdate({})
  }, [setUpdate])
  useEffect(() => {
    const timer = setInterval(() => forceUpdate(), interval)
    return () => clearInterval(timer)
  }, [interval, forceUpdate])
  return [update, forceUpdate] as [RefreshingDependency, () => void]
}

export const RefreshingContext = createContext<RefreshingDependencies>(
  defaultRefreshingDependencies
)

export function RefreshingProvider({
  slowInterval = 30000,
  fastInterval = 5000,
  children,
}: {
  slowInterval?: number
  fastInterval?: number
  children: ReactNode
}) {
  const [slowRefresher, forceSlowUpdate] = useAutoRefresher(
    slowInterval,
    defaultRefreshingDependencies
  )
  const [fastRefresher, forceFastUpdate] = useAutoRefresher(
    fastInterval,
    defaultRefreshingDependencies
  )

  const value = useMemo(
    () =>
      ({
        slow: slowRefresher,
        fast: fastRefresher,
        refreshNow: () => {
          forceSlowUpdate()
          forceFastUpdate()
        },
      }) as RefreshingDependencies,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slowRefresher, fastRefresher]
  )

  return (
    <RefreshingContext.Provider value={value}>
      {children}
    </RefreshingContext.Provider>
  )
}

export function useGlobalRefresher(): RefreshingDependencies {
  return useContext(RefreshingContext)
}
