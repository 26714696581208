import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  InputGroup,
  Text,
  InputRightElement,
} from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import Tooltip from '../../../../components/elements/Tooltip'
import { INTEREST_RATE_DECIMALS } from '../../../../helpers/constants'
import { parseUserInput } from '../../../../helpers/utils'
import { Trans, t } from '@lingui/macro'

export default function BorrowerRolloverInterestField({
  value,
  onChange,
  disabled,
  w,
}: {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  w?: FormControlProps['w']
}) {
  return (
    <FormControl as="fieldset" w={w} flexShrink={0}>
      <VStack spacing={2} align="start">
        <HStack justify="space-between" alignSelf="stretch">
          <FormLabel
            as="legend"
            variant="create-tender"
            mb={0}
            color="gray.700"
            fontSize="sm"
            fontWeight="500"
            lineHeight="5"
          >
            <Trans>Rollover Bid Rate</Trans>{' '}
            <Tooltip
              label={
                <Text>
                  <Trans>
                    If your rollover is successful, the interest that you pay
                    for your rollover loan will be no more than what you input
                    here.
                  </Trans>
                </Text>
              }
              aria-label={t`rollover-tooltip`}
            />
          </FormLabel>
        </HStack>
        <InputGroup w="100%">
          <Input
            value={value}
            type="text"
            variant="flushed"
            isDisabled={disabled}
            onChange={(ev) =>
              onChange(
                parseUserInput(ev.target.value, true, INTEREST_RATE_DECIMALS)
              )
            }
            placeholder="0.000"
            color="blue.9"
            fontWeight="400"
            lineHeight="5"
            pl={1}
          />
          <InputRightElement
            top="50%"
            transform="translateY(-50%) translateX(-2px)"
            pointerEvents="none"
            color="gray.6"
            bg="gray.2"
            height="20px"
            borderRadius="6px"
            children="%"
            fontWeight={500}
            fontSize="xs"
          />
        </InputGroup>
      </VStack>
    </FormControl>
  )
}
