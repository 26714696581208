import { BoxProps, Button, Heading, Image, Text } from '@chakra-ui/react'
import term_logo from '../../../assets/termlogo.svg'
import access_via_desktop_graphic from '../../../assets/access-via-desktop-graphic.svg'
import { socialLinks } from '../../../socials'
import { VStack } from '../Stack'

const defaultOpacityStyles = {
  base: 1,
  lg: 0,
}

export default function AccessViaDesktopOverlay({
  opacity = defaultOpacityStyles,
}: {
  opacity?: BoxProps['opacity']
}) {
  return (
    <VStack
      py={{
        base: '60px',
        sm: '20px',
      }}
      pointerEvents={{
        base: 'auto',
        lg: 'none',
      }}
      alignItems="center"
      w="full"
      overflow="hidden"
      opacity={opacity}
      transition="opacity 0.3s ease"
      pos="fixed"
      top="0"
      left="0"
      zIndex="999"
      bg="blue.0"
      minH="100vh"
      spacing={0}
    >
      <Image src={term_logo} alt="Term logo" w="109px" h="50px" mb={20} />
      <VStack
        alignItems="center"
        color="blue.9"
        maxW="373px"
        textAlign="center"
        mb="8px"
        marginTop="0 !important"
      >
        <Text
          as="span"
          variant={{
            base: 'body-2xl/bold',
            sm: 'body-3xl/bold',
          }}
        >
          Access via Desktop
        </Text>
        <Heading as="h2" variant="heading/h2">
          Enjoy the Full Experience
        </Heading>
        <Text variant="body-sm/normal">
          We’re currently optimising the mobile experience. Please use a desktop
          to access the testnet.
        </Text>
      </VStack>
      <a href={socialLinks.discord} target="_blank" rel="noopener noreferrer">
        <Button variant="link" mb="36px !important">
          <Text as="span" variant="body-md/semibold" color="blue.5">
            Contact us on Discord
          </Text>
        </Button>
      </a>
      <Image
        src={access_via_desktop_graphic}
        alt="Access via desktop"
        minW={{
          base: '453px',
          sm: '662px',
        }}
        maxW={{
          base: '453px',
          sm: '662px',
        }}
        minH={{
          base: '220px',
          sm: '322px',
        }}
        maxH={{
          base: '220px',
          sm: '322px',
        }}
        mt="8px"
      />
    </VStack>
  )
}
