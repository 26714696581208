import { Box, Flex, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { components } from '../../../../models/profile-api'
import { Socials } from '../../../../models/rewards'
import InviteOverview from '../InviteOverview'
import TrackInvitesTable from '../TrackInvitesTable'

export default function InviteSection({
  signedIn,
  account,
  connectedSocials,
  inviteCodes,
  usedInvites,
  totalInvites,
  referralCode,
  onLinkWallet,
  onLinkTwitter,
  onLinkDiscord,
}: {
  signedIn: boolean
  account: string
  connectedSocials: Record<Socials, boolean>
  inviteCodes?: components['schemas']['InviteCode'][]
  usedInvites?: number
  totalInvites?: number
  referralCode?: string
  onLinkWallet?: () => Promise<void>
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => void
}) {
  const [invitesPoints, yourShare] = useMemo(() => {
    const invitesPoints = inviteCodes?.reduce((acc, code) => {
      return acc + (code?.meta?.appPoints ?? 0)
    }, 0)
    const yourShare = invitesPoints ? invitesPoints * 0.1 : 0
    return [invitesPoints, yourShare]
  }, [inviteCodes])

  return (
    <Flex direction="column" gap={6}>
      <Text color={'blue.9'} variant={'body-2xl/medium'}>
        Invite people to earn points
      </Text>
      <InviteOverview
        usedInvites={usedInvites ?? 0}
        totalInvites={totalInvites ?? 5}
        invitesPoints={invitesPoints ?? 0}
        yourShare={yourShare ?? 0}
        referralCode={referralCode}
      />
      <Box mt={8} mb={3}>
        <Text color={'blue.9'} variant={'body-2xl/medium'}>
          Track your invites
        </Text>
      </Box>
      <TrackInvitesTable
        account={account}
        connectedSocials={connectedSocials}
        inviteCodes={inviteCodes}
        signedIn={signedIn}
        onLinkWallet={onLinkWallet}
        onLinkTwitter={onLinkTwitter}
        onLinkDiscord={onLinkDiscord}
      />
    </Flex>
  )
}
