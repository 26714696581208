import { Box, Image, useDisclosure, Text, Link } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import BombPotInfoCard from '../../../../components/elements/BombPotInfoCard'
import { useEthers } from '@usedapp/core'
import { BigNumber, FixedNumber, constants } from 'ethers'
import { useMemo, useReducer, useState } from 'react'
import {
  Auction,
  AuctionActivityData,
  Currency,
  DraftBorrowTender,
  DraftLoanTender,
  NativeCurrency,
  SuggestedRatesByPlatform,
  ValidatedBorrowTender,
  ValidatedLoanTender,
  Wallet,
} from '../../../../data/model'
import { parseBorrowTender, parseLoanTender } from '../../../../data/parse'
import {
  DEFAULT_BORROW_TENDER,
  DEFAULT_LOAN_TENDER,
} from '../../../../helpers/constants'
import {
  bigToFixedNumber,
  fixedToBigNumber,
} from '../../../../helpers/conversions'
import { add, evaluate, multiply } from '../../../../helpers/math'
import nexusIcon from '../../../../assets/icons/projects/nexus.svg'
import nexusText from '../../../../assets/icons/projects/nexus-text.svg'
import {
  parseUserInputCurrencyAmount,
  shortenAddress,
} from '../../../../helpers/utils'
import BorrowTenderForm from '../BorrowTenderForm'
import LoanTenderForm from '../LoanTenderForm'
import BorrowTenderSummary from '../TenderSummary/BorrowTenderSummary'
import LoanTenderSummary from '../TenderSummary/LoanTenderSummary'
import ConfirmDeleteReferralCodeModal from '../ConfirmDeleteReferralCodeModal'
import AddBidButton from './AddBidButton'
import AddOfferButton from './AddOfferButton'
import Container from './Container'
import Controls from './Controls'
import TabButtons from './TabButtons'
import AuctionActivityBox from './AuctionActivityBox'
import { useChainConfig } from '../../../../providers/config'
import { externalLinks } from '../../../../links'
import { Trans } from '@lingui/macro'

export default function CreateTendersBox(props: {
  isDataLoading?: boolean
  auction: Pick<
    Auction,
    | 'chainId'
    | 'maturityTimestamp'
    | 'purchaseCurrencyOraclePriceUSDC'
    | 'purchaseCurrencyOraclePriceDecimals'
    | 'collateralCurrencyOraclePriceUSDC'
    | 'collateralCurrencyOraclePriceDecimals'
    | 'collateralLiquidatedDamages'
    | 'initialMarginRatio'
    | 'auctionStartTimestamp'
    | 'auctionEndTimestamp'
    | 'servicingFee'
    | 'minOfferAmount'
    | 'maxOfferPrice'
    | 'minBidAmount'
    | 'maxBidPrice'
    | 'bombPotAuction'
    | 'bombPotAmount'
    | 'bombPotRewardTokenSymbol'
  >
  auctionActivityData: AuctionActivityData
  purchaseCurrency: Currency
  purchaseTokenBalance: BigNumber
  purchaseTokenAllowance: BigNumber
  collateralCurrency: Currency
  collateralTokenBalance: BigNumber
  collateralTokenAllowance: BigNumber
  nakedCollateralCurrency: Currency | undefined
  nakedCollateralTokenBalance: BigNumber | undefined
  nakedCollateralTokenAllowance: BigNumber | undefined
  convertedNakedCollateralTokenBalance: BigNumber | undefined
  gasTokenCurrency: NativeCurrency
  gasTokenBalance: FixedNumber
  rateSuggestions?: SuggestedRatesByPlatform
  referralCode: string

  onApprovePurchaseToken: (amount?: string) => Promise<void>
  onApproveCollateralToken: () => Promise<void>
  onApproveNakedCollateralToken: (amount: string) => Promise<void>
  onCreateLoanTenders: (tenders: ValidatedLoanTender[]) => Promise<void>
  onCreateBorrowTenders: (tenders: ValidatedBorrowTender[]) => Promise<void>
  onDeleteReferralCode: () => void
  onConnect: () => void
  onKytCheck: () => Promise<boolean>
  onWrapGasTokenCall: (value: BigNumber) => Promise<void>
  onWrapTokenCall: (
    address: string,
    symbol: string,
    value: BigNumber,
    balance?: BigNumber
  ) => Promise<void>
  onUnwrapGasTokenCall: (value: BigNumber) => Promise<void>
  onUnwrapTokenCall: (address: string, value: BigNumber) => Promise<void>
}) {
  const {
    isDataLoading,
    auction,
    auctionActivityData,
    purchaseCurrency,
    purchaseTokenBalance,
    purchaseTokenAllowance,
    collateralCurrency,
    // collateralTokenBalance,
    collateralTokenAllowance,
    nakedCollateralCurrency,
    nakedCollateralTokenBalance,
    nakedCollateralTokenAllowance,
    convertedNakedCollateralTokenBalance,
    gasTokenCurrency,
    gasTokenBalance,
    referralCode,

    onApproveCollateralToken,
    onApprovePurchaseToken,
    onApproveNakedCollateralToken,
    onCreateLoanTenders,
    onCreateBorrowTenders,
    onDeleteReferralCode,
    onConnect,
    onKytCheck,
    onWrapGasTokenCall,
    onWrapTokenCall,
    onUnwrapGasTokenCall,
    onUnwrapTokenCall,
  } = props

  const chainConfig = useChainConfig(auction.chainId)

  const [state, dispatch] = useReducer(reducer, initialState)
  const { tab, tenders } = state
  const [isSubmitting, setIsSubmitting] = useState(false) // Required to prevent input errors showing while transaction executing

  const { activateBrowserWallet, account, library: provider } = useEthers()

  const {
    isOpen: isConfirmDeleteReferralCodeModalOpen,
    onOpen: onConfirmDeleteReferralCodeModalOpen,
    onClose: onConfirmDeleteReferralCodeModalClose,
  } = useDisclosure()

  const onConfirmDeleteReferralCode = async () => {
    // do delete stuff
    onDeleteReferralCode()
    // might be a toast somewhere in here

    // close the modal
    onConfirmDeleteReferralCodeModalClose()
  }

  const wallet: Wallet =
    account === undefined || provider === undefined
      ? {
          status: 'not-connected',
          onConnectMetamask: () => activateBrowserWallet({ type: 'metamask' }),
          onConnectWalletconnect: () =>
            activateBrowserWallet({ type: 'walletConnectV2' }),
          onConnectCoinbase: () => activateBrowserWallet({ type: 'coinbase' }),
        }
      : {
          status: 'connected',
          accountShort: shortenAddress(account),
        }

  const submitTenders = async () => {
    setIsSubmitting(true)
    switch (tab) {
      case 'loan': {
        const parsedTenders = tenders.map((tender) =>
          parseLoanTender(
            tender,
            purchaseCurrency.decimals,
            auction.minOfferAmount,
            purchaseTokenBalance,
            FixedNumber.fromString('0', 18),
            FixedNumber.fromValue(auction.maxOfferPrice, 18)
          )
        )
        const validatedTenders: ValidatedLoanTender[] = parsedTenders.map(
          (parsed) => ({
            type: 'loan',
            amount: {
              currency: purchaseCurrency.address,
              shiftedValue: parsed.amount.value,
            },
            interestRate: parsed.interestRate.value,
          })
        )
        await onCreateLoanTenders(validatedTenders)
        dispatch({ name: 'clear-tenders' })
        setIsSubmitting(false)
        return
      }
      case 'borrow': {
        const parsedTenders = tenders.map((tender) => {
          const loanAmountUsdc = evaluate(
            {
              nodeKind: 'mul',
              args: [
                {
                  nodeKind: 'value',
                  value: FixedNumber.fromString(
                    tender.amount !== '' ? tender.amount : '0',
                    `fixed128x${purchaseCurrency.decimals}`
                  ),
                },
                {
                  nodeKind: 'value',
                  value: bigToFixedNumber(
                    auction.purchaseCurrencyOraclePriceUSDC,
                    auction.purchaseCurrencyOraclePriceDecimals
                  ),
                },
              ],
            },
            collateralCurrency.decimals
          )

          const requiredCollateralUsdc = evaluate(
            {
              nodeKind: 'mul',
              args: [
                {
                  nodeKind: 'value',
                  value: loanAmountUsdc,
                },
                {
                  nodeKind: 'value',
                  value: auction.initialMarginRatio,
                },
              ],
            },
            collateralCurrency.decimals
          )

          const requiredCollateral = evaluate(
            {
              nodeKind: 'div',
              args: [
                {
                  nodeKind: 'value',
                  value: requiredCollateralUsdc,
                },
                {
                  nodeKind: 'value',
                  value: bigToFixedNumber(
                    auction.collateralCurrencyOraclePriceUSDC,
                    auction.collateralCurrencyOraclePriceDecimals
                  ),
                },
              ],
            },
            collateralCurrency.decimals
          )

          return parseBorrowTender(
            tender,
            purchaseCurrency.decimals,
            collateralCurrency.decimals,
            requiredCollateral,
            auction.minBidAmount,
            constants.MaxUint256.div(32),
            FixedNumber.fromString('0', 18),
            FixedNumber.fromValue(auction.maxBidPrice, 18)
          )
        })
        const validatedTenders: ValidatedBorrowTender[] = parsedTenders.map(
          (parsed) => ({
            type: 'borrow',
            amount: {
              currency: purchaseCurrency.address,
              shiftedValue: parsed.amount.value,
            },
            interestRate: parsed.interestRate.value,
            collateral: {
              currency: collateralCurrency.address,
              shiftedValue: parsed.collateral.value,
            },
          })
        )
        await onCreateBorrowTenders(validatedTenders)
        dispatch({ name: 'clear-tenders' })
        setIsSubmitting(false)
        return
      }
    }
  }

  const clearTenders = () => dispatch({ name: 'clear-tenders' })

  let isLoan = true
  for (const tender of tenders) {
    if (tender.type !== 'loan' && isLoan) {
      isLoan = false
    }
  }

  const referralCodeIsConnectedAddress =
    !!referralCode &&
    !!account &&
    referralCode.toLowerCase() === account.toLowerCase()

  const [totalBidAmountUsd, totalOfferAmountUsd] = useMemo(() => {
    const bidUsd = evaluate(
      {
        nodeKind: 'mul',
        args: [
          {
            nodeKind: 'value',
            value: bigToFixedNumber(
              auctionActivityData?.bidsAmount ?? BigNumber.from(0),
              purchaseCurrency.decimals
            ),
          },
          {
            nodeKind: 'value',
            value: bigToFixedNumber(
              auction.purchaseCurrencyOraclePriceUSDC,
              auction.purchaseCurrencyOraclePriceDecimals
            ),
          },
        ],
      },
      2
    )

    const offerUsd = evaluate(
      {
        nodeKind: 'mul',
        args: [
          {
            nodeKind: 'value',
            value: bigToFixedNumber(
              auctionActivityData?.offersAmount ?? BigNumber.from(0),
              purchaseCurrency.decimals
            ),
          },
          {
            nodeKind: 'value',
            value: bigToFixedNumber(
              auction.purchaseCurrencyOraclePriceUSDC,
              auction.purchaseCurrencyOraclePriceDecimals
            ),
          },
        ],
      },
      2
    )
    return [bidUsd, offerUsd]
  }, [
    auctionActivityData?.bidsAmount,
    auctionActivityData?.offersAmount,
    purchaseCurrency,
    auction.purchaseCurrencyOraclePriceUSDC,
    auction.purchaseCurrencyOraclePriceDecimals,
  ])

  return (
    <>
      <Controls>
        <TabButtons
          isDisabled={isDataLoading}
          value={tab}
          onChange={(tab) => dispatch({ name: 'choose-tab', tab })}
        />
      </Controls>
      <Container position="relative" p="12px">
        <HStack gap={'23px'} alignItems="start">
          {tab === 'loan' ? (
            <LoanTenders
              value={tenders}
              isSubmitting={isSubmitting}
              onChange={(tenders) =>
                dispatch({ name: 'update-tenders', with: tenders })
              }
              {...props}
            />
          ) : (
            <BorrowTenders
              value={tenders}
              isSubmitting={isSubmitting}
              onChange={(tenders) =>
                dispatch({ name: 'update-tenders', with: tenders })
              }
              {...props}
            />
          )}
          {tab === 'loan' &&
            auction.bombPotAuction &&
            auction.bombPotAmount &&
            auction.bombPotRewardTokenSymbol && (
              <BombPotInfoCard
                amount={auction.bombPotAmount}
                symbol={auction.bombPotRewardTokenSymbol}
              />
            )}
          <Box flexShrink={0} ml={'auto'}>
            {tab === 'loan' && (
              <LoanTenderSummary
                isDataLoading={isDataLoading}
                auction={auction}
                auctionStartTimestamp={auction.auctionStartTimestamp}
                auctionEndTimestamp={auction.auctionEndTimestamp}
                wallet={wallet}
                tenders={tenders}
                maturityDate={auction.maturityTimestamp}
                purchaseCurrency={purchaseCurrency}
                collateralCurrency={collateralCurrency}
                onApprovePurchaseToken={onApprovePurchaseToken}
                purchaseTokenBalance={purchaseTokenBalance}
                purchaseTokenAllowance={purchaseTokenAllowance}
                gasTokenCurrency={gasTokenCurrency}
                gasTokenBalance={gasTokenBalance}
                referralCode={referralCode}
                referralCodeIsConnectedAddress={referralCodeIsConnectedAddress}
                openConfirmDeleteReferralCodeModal={
                  onConfirmDeleteReferralCodeModalOpen
                }
                onDeleteReferralCode={onDeleteReferralCode}
                submitTenders={submitTenders}
                clearTenders={clearTenders}
                onConnect={onConnect}
                onKytCheck={onKytCheck}
                onWrapGasTokenCall={onWrapGasTokenCall}
                onWrapTokenCall={onWrapTokenCall}
                onUnwrapGasTokenCall={onUnwrapGasTokenCall}
                onUnwrapTokenCall={onUnwrapTokenCall}
              />
            )}
            {tab === 'borrow' && (
              <BorrowTenderSummary
                isDataLoading={isDataLoading}
                auction={auction}
                auctionStartTimestamp={auction.auctionStartTimestamp}
                auctionEndTimestamp={auction.auctionEndTimestamp}
                auctionServicingFee={auction.servicingFee}
                wallet={wallet}
                tenders={tenders}
                maturityDate={auction.maturityTimestamp}
                purchaseCurrency={purchaseCurrency}
                purchaseTokenPriceDecimals={
                  auction.purchaseCurrencyOraclePriceDecimals
                }
                purchaseTokenPrice={auction.purchaseCurrencyOraclePriceUSDC}
                collateralBalance={props.collateralTokenBalance}
                collateralCurrency={collateralCurrency}
                collateralTokenPrice={auction.collateralCurrencyOraclePriceUSDC}
                collateralTokenPriceDecimals={
                  auction.collateralCurrencyOraclePriceDecimals
                }
                collateralTokenAllowance={collateralTokenAllowance}
                nakedCollateralCurrency={nakedCollateralCurrency}
                nakedCollateralTokenBalance={nakedCollateralTokenBalance}
                nakedCollateralTokenAllowance={nakedCollateralTokenAllowance}
                convertedNakedCollateralTokenBalance={
                  convertedNakedCollateralTokenBalance
                }
                referralCode={referralCode}
                referralCodeIsConnectedAddress={referralCodeIsConnectedAddress}
                openConfirmDeleteReferralCodeModal={
                  onConfirmDeleteReferralCodeModalOpen
                }
                onDeleteReferralCode={onDeleteReferralCode}
                onApproveCollateralToken={onApproveCollateralToken}
                onApproveNakedCollateralToken={onApproveNakedCollateralToken}
                submitTenders={submitTenders}
                clearTenders={clearTenders}
                gasTokenCurrency={gasTokenCurrency}
                onConnect={onConnect}
                onKytCheck={onKytCheck}
                onWrapGasTokenCall={onWrapGasTokenCall}
                onWrapTokenCall={onWrapTokenCall}
                onUnwrapGasTokenCall={onUnwrapGasTokenCall}
                onUnwrapTokenCall={onUnwrapTokenCall}
              />
            )}
            {(!!auctionActivityData.offersCount ||
              !!auctionActivityData.bidsCount) && (
              <AuctionActivityBox
                offersCount={auctionActivityData.offersCount}
                bidsCount={auctionActivityData.bidsCount}
                offersAmount={totalOfferAmountUsd}
                bidsAmount={totalBidAmountUsd}
              />
            )}
          </Box>
        </HStack>
        <ConfirmDeleteReferralCodeModal
          isOpen={isConfirmDeleteReferralCodeModalOpen}
          onConfirm={onConfirmDeleteReferralCode}
          onClose={onConfirmDeleteReferralCodeModalClose}
          referralCode={referralCode}
          getBlockExplorerAddressUrl={chainConfig?.getExplorerAddressLink}
        />
        <Link
          isExternal
          href={externalLinks.nexusMutual}
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
          position="absolute"
          bottom={'12px'}
          left={'16px'}
          role="group"
        >
          <HStack gap="5px">
            <Text color="gray.4" variant="body-xs/medium">
              <Trans>Buy cover</Trans>
            </Text>
            <Image
              src={nexusText}
              transition="0.3s"
              opacity={0.6}
              _groupHover={{ opacity: 1 }}
            />
            <Image
              src={nexusIcon}
              transition="0.3s"
              opacity={0.6}
              _groupHover={{ opacity: 1, transform: 'rotate(-90deg)' }}
            />
          </HStack>
        </Link>
      </Container>
    </>
  )
}

function BorrowTenders({
  auction,
  purchaseCurrency,
  collateralTokenBalance,
  collateralCurrency,
  nakedCollateralCurrency,
  convertedNakedCollateralTokenBalance,
  rateSuggestions,
  isSubmitting,
  value,
  onChange,
}: {
  auction: Pick<
    Auction,
    | 'chainId'
    | 'maturityTimestamp'
    | 'purchaseCurrencyOraclePriceUSDC'
    | 'purchaseCurrencyOraclePriceDecimals'
    | 'collateralCurrencyOraclePriceUSDC'
    | 'collateralCurrencyOraclePriceDecimals'
    | 'initialMarginRatio'
    | 'servicingFee'
    | 'maxBidPrice'
    | 'minBidAmount'
  >
  purchaseCurrency: Currency
  collateralTokenBalance: BigNumber
  collateralCurrency: Currency
  nakedCollateralCurrency: Currency | undefined
  convertedNakedCollateralTokenBalance: BigNumber | undefined
  rateSuggestions?: SuggestedRatesByPlatform
  isSubmitting: boolean
  value: DraftBorrowTender[]
  onChange: (tenders: DraftBorrowTender[]) => void
}) {
  const chainConfig = useChainConfig(auction.chainId)

  let availCollateralTokensToSupply: BigNumber
  if (
    chainConfig?.wrappedTokenMapping &&
    collateralCurrency.address in chainConfig.wrappedTokenMapping &&
    nakedCollateralCurrency &&
    convertedNakedCollateralTokenBalance
  ) {
    availCollateralTokensToSupply = collateralTokenBalance.add(
      convertedNakedCollateralTokenBalance
    )
  } else {
    availCollateralTokensToSupply = collateralTokenBalance
  }

  return (
    <Box>
      {value.map((tender, index) => (
        <BorrowTenderForm
          key={index}
          auction={auction}
          purchaseCurrency={purchaseCurrency}
          collateralCurrency={collateralCurrency}
          collateralTokenBalance={collateralTokenBalance}
          nakedCollateralCurrency={nakedCollateralCurrency}
          convertedNakedCollateralTokenBalance={
            convertedNakedCollateralTokenBalance
          }
          value={tender}
          index={index}
          isLastIndex={index === value.length - 1}
          onChange={(tender) =>
            onChange([
              ...value.slice(0, index),
              tender,
              ...value.slice(index + 1),
            ])
          }
          onRemove={() =>
            onChange([...value.slice(0, index), ...value.slice(index + 1)])
          }
          tendersCount={value.length}
          availCollateralTokensToSupply={
            index > 0
              ? availCollateralTokensToSupply.sub(
                  [...value]
                    .splice(0, index)
                    .reduce(
                      (acc, tender) =>
                        acc.add(
                          parseUserInputCurrencyAmount(
                            tender.collateral,
                            collateralCurrency.decimals,
                            '0'
                          )
                        ),
                      BigNumber.from(0)
                    )
                )
              : availCollateralTokensToSupply
          }
          rateSuggestions={rateSuggestions}
          isSubmitting={isSubmitting}
        />
      ))}

      <AddBidButton
        onClick={() => onChange([...value, DEFAULT_BORROW_TENDER])}
      />
    </Box>
  )
}

function LoanTenders({
  auction,
  purchaseCurrency,
  purchaseTokenBalance,
  collateralCurrency,
  gasTokenCurrency,
  gasTokenBalance,
  rateSuggestions,
  isSubmitting,
  value,
  onChange,
}: {
  auction: Pick<
    Auction,
    | 'chainId'
    | 'maturityTimestamp'
    | 'purchaseCurrencyOraclePriceUSDC'
    | 'purchaseCurrencyOraclePriceDecimals'
    | 'collateralCurrencyOraclePriceUSDC'
    | 'initialMarginRatio'
    | 'minOfferAmount'
    | 'maxOfferPrice'
  >
  purchaseCurrency: Currency
  purchaseTokenBalance: BigNumber
  collateralCurrency: Currency
  gasTokenCurrency: NativeCurrency
  gasTokenBalance: FixedNumber
  rateSuggestions?: SuggestedRatesByPlatform
  isSubmitting: boolean
  value: DraftLoanTender[]
  onChange: (tenders: DraftLoanTender[]) => void
}) {
  // This can all be simplified when BigNumbers are removed from Auction page parameters
  const purchaseTokenBalanceFN: FixedNumber = bigToFixedNumber(
    purchaseTokenBalance,
    purchaseCurrency.decimals
  )
  const scaledGasTokenFN: FixedNumber = multiply(
    gasTokenBalance,
    FixedNumber.from(
      gasTokenCurrency.wrapConversionFactor,
      gasTokenCurrency.decimals
    )
  )
  const availPurchaseTokensToSupplyFN: FixedNumber =
    purchaseCurrency.symbol === gasTokenCurrency.wrappedGasSymbol
      ? add(scaledGasTokenFN, purchaseTokenBalanceFN, purchaseCurrency.decimals)
      : purchaseTokenBalanceFN

  const availPurchaseTokensToSupply: BigNumber = fixedToBigNumber(
    availPurchaseTokensToSupplyFN,
    purchaseCurrency.decimals
  )

  return (
    <Box>
      {value.map((tender, index) => (
        <LoanTenderForm
          key={index}
          auction={auction}
          purchaseCurrency={purchaseCurrency}
          purchaseTokenBalance={
            index > 0
              ? purchaseTokenBalance.sub(
                  [...value]
                    .splice(0, index)
                    .reduce(
                      (acc, tender) =>
                        acc.add(
                          parseUserInputCurrencyAmount(
                            tender.amount,
                            purchaseCurrency.decimals,
                            '0'
                          )
                        ),
                      BigNumber.from(0)
                    )
                )
              : purchaseTokenBalance
          }
          collateralCurrency={collateralCurrency}
          gasTokenCurrency={gasTokenCurrency}
          gasTokenBalance={gasTokenBalance}
          value={tender}
          index={index}
          isLastIndex={index === value.length - 1}
          onChange={(tender) =>
            onChange([
              ...value.slice(0, index),
              tender,
              ...value.slice(index + 1),
            ])
          }
          onRemove={() =>
            onChange([...value.slice(0, index), ...value.slice(index + 1)])
          }
          tendersCount={value.length}
          availPurchaseTokensToSupply={
            index > 0
              ? availPurchaseTokensToSupply.sub(
                  [...value]
                    .splice(0, index)
                    .reduce(
                      (acc, tender) =>
                        acc.add(
                          parseUserInputCurrencyAmount(
                            tender.amount,
                            purchaseCurrency.decimals,
                            '0'
                          )
                        ),
                      BigNumber.from(0)
                    )
                )
              : availPurchaseTokensToSupply
          }
          rateSuggestions={rateSuggestions}
          isSubmitting={isSubmitting}
        />
      ))}
      <AddOfferButton
        onClick={() => onChange([...value, DEFAULT_LOAN_TENDER])}
      />
    </Box>
  )
}

type State = Loan | Borrow
interface Loan {
  tab: 'loan'
  tenders: DraftLoanTender[]
}
interface Borrow {
  tab: 'borrow'
  tenders: DraftBorrowTender[]
}

const initialState: State = {
  tab: 'loan',
  tenders: [DEFAULT_LOAN_TENDER],
}

type Action = ChooseTab | UpdateTenders | ClearTenders
interface ChooseTab {
  name: 'choose-tab'
  tab: State['tab']
}
interface UpdateTenders {
  name: 'update-tenders'
  with: DraftLoanTender[] | DraftBorrowTender[]
}
interface ClearTenders {
  name: 'clear-tenders'
}

function reducer(state: State, action: Action): State {
  switch (action.name) {
    case 'choose-tab': {
      return action.tab === 'loan'
        ? {
            tab: action.tab,
            tenders: [DEFAULT_LOAN_TENDER],
          }
        : {
            tab: action.tab,
            tenders: [DEFAULT_BORROW_TENDER],
          }
    }
    case 'update-tenders': {
      return {
        ...state,
        tenders: action.with,
      } as State
    }
    case 'clear-tenders': {
      return state.tab === 'loan'
        ? {
            ...state,
            tenders: [DEFAULT_LOAN_TENDER],
          }
        : {
            ...state,
            tenders: [DEFAULT_BORROW_TENDER],
          }
    }
  }
}
