import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'
import { BigNumber, Contract, FixedNumber, Signer } from 'ethers'
import { RepoTokenLinkedList } from '../../abi-generated'
import RepoTokenLinkedListABI from '../../abi/listing/RepoTokenLinkedList.json'
import { useCallback, useEffect, useMemo } from 'react'
import { captureException } from '@sentry/react'
import { useChainConfigs, useConfig } from '../../providers/config'
import { TransactionStatus, useSendTransaction } from '@usedapp/core'
import { waitForStatus, waitForSubgraphIndexing } from '../../helpers/wait'
import { Address, Currency } from '../model'
import { bigToFixedNumber, fixedToBigNumber } from '../../helpers/conversions'
import { useSafary } from '../analytics/use-safary'

export function useListings(
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  mappedTokenAddressesByChain: {
    [chainId: string]: { purchaseToken: Currency; termRepoToken: Currency }[]
  },
  readFromSubgraph: () => void
): {
  discountRateMarkups: { [chainId: string]: BigNumber } | undefined
  minimumListingAmounts:
    | { [chainId: string]: { [termRepoToken: Address]: FixedNumber } }
    | undefined
  swapPurchaseTokensForRepoTokens: (
    chainId: number,
    termRepoToken: Address,
    purchaseAmount: FixedNumber
  ) => Promise<void>
  purchaseRepoTokens: (
    chainId: number,
    termRepoToken: Address,
    purchaseAmount: FixedNumber
  ) => Promise<void>
  createListing: (
    chainId: string,
    termRepoToken: Address,
    listingAmount: FixedNumber
  ) => Promise<void>
  cancelListings: (
    chainId: string,
    listingIds: number[],
    skipRedeem?: boolean
  ) => Promise<void>
} {
  const chainConfigs = useChainConfigs()
  const config = useConfig()
  const { trackSafaryEvent } = useSafary()

  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return (
        activeNetwork?.chainId &&
        activeNetwork.chainId.toString() === chainId.toString()
      )
    },
    [signer]
  )

  const transactionStates = useMemo(
    () =>
      ({}) as {
        swapPurchaseTokens: TransactionStatus | undefined
        purchaseRepoTokens: TransactionStatus | undefined
        createListing: TransactionStatus | undefined
        cancelListings: TransactionStatus | undefined
      },
    []
  )

  const {
    sendTransaction: swapPurchaseTokensTransaction,
    state: swapPurchaseTokensState,
    resetState: resetSwapPurchaseTokensState,
  } = useSendTransaction()

  const {
    sendTransaction: purchaseRepoTokensTransaction,
    state: purchaseRepoTokensState,
    resetState: resetPurchaseRepoTokensState,
  } = useSendTransaction()

  const {
    sendTransaction: createListingTransaction,
    state: createListingState,
    resetState: resetCreateListingState,
  } = useSendTransaction()

  const {
    sendTransaction: cancelListingsTransaction,
    state: cancelListingsState,
    resetState: resetCancelListingsState,
  } = useSendTransaction()

  useEffect(() => {
    transactionStates.swapPurchaseTokens = swapPurchaseTokensState
    transactionStates.purchaseRepoTokens = purchaseRepoTokensState
    transactionStates.createListing = createListingState
    transactionStates.cancelListings = cancelListingsState
  }, [
    cancelListingsState,
    createListingState,
    purchaseRepoTokensState,
    swapPurchaseTokensState,
    transactionStates,
  ])

  const { contracts, calls } = useMemo(() => {
    const contracts: { [chainId: number]: RepoTokenLinkedList } = {}
    const calls: MultichainCalls = {}

    chainConfigs.forEach((chainConfig) => {
      const { chainId, listingsContractAddress } = chainConfig

      // Create and store the contract per chain
      const contract = new Contract(
        listingsContractAddress,
        RepoTokenLinkedListABI,
        provider
      ) as RepoTokenLinkedList
      contracts[chainId] = contract

      // Prepare the call using the contract
      const discountRateMarkupCall = {
        contract,
        method: 'discountRateMarkup',
        args: [],
      }

      const minimumListingCalls =
        mappedTokenAddressesByChain?.[chainId]?.map((tokenPairing) => ({
          contract,
          method: 'minimumListingAmount',
          args: [tokenPairing.purchaseToken.address],
        })) ?? []

      calls[chainId] = [discountRateMarkupCall, ...minimumListingCalls]
    })

    return { contracts, calls }
  }, [chainConfigs, provider, mappedTokenAddressesByChain])

  const blockchainResults = useMultichainCalls(calls)

  // TODO: find decimals to use here
  const markups = useMemo(() => {
    const result: Record<string, any> = {}
    if (blockchainResults) {
      Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
        const discountRateMarkupResult = chainResults[0]
        if (
          discountRateMarkupResult?.error === undefined &&
          discountRateMarkupResult?.value !== undefined
        ) {
          result[chainId] = discountRateMarkupResult.value[0]
        }
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [blockchainResults])

  const minimumListingAmounts = useMemo(() => {
    const result: Record<string, Record<string, any>> = {}

    if (blockchainResults && mappedTokenAddressesByChain) {
      Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
        const chainResult: Record<string, any> = {}

        if (!mappedTokenAddressesByChain[chainId]) {
          return
        }

        // chainResults[0] is discountRateMarkupResult
        for (let i = 0; i < mappedTokenAddressesByChain[chainId]?.length; i++) {
          const index = i + 1 // Offset by 1 because of the discountRateMarkupResult
          const minimumListingAmountResult = chainResults[index]
          const tokenPairing = mappedTokenAddressesByChain[chainId][i]

          if (
            minimumListingAmountResult?.error === undefined &&
            minimumListingAmountResult?.value !== undefined
          ) {
            chainResult[tokenPairing.termRepoToken.address] = bigToFixedNumber(
              minimumListingAmountResult.value[0],
              tokenPairing.termRepoToken.decimals
            )
          }
        }

        if (Object.keys(chainResult).length > 0) {
          result[chainId] = chainResult
        }
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [blockchainResults, mappedTokenAddressesByChain])

  const blockchainErrors = useMemo(() => {
    const result: Record<string, any> = {}
    if (blockchainResults) {
      Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
        const chainErrors = chainResults
          .filter(
            (valueOrError) => valueOrError && valueOrError?.error !== undefined
          )
          .map((valueOrError) => valueOrError?.error)

        if (chainErrors.length > 0) {
          result[chainId] = chainErrors
        }
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [blockchainResults])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainErrors]) => {
        chainErrors.forEach((error: any) => {
          console.error(`Error on ${chainId}: ${error}`)
          captureException(error)
        })
      })
    }
  }, [blockchainErrors])

  const purchaseRepoTokens = useCallback(
    async (chainId: number, termRepoToken: Address, amount: FixedNumber) => {
      if (!signer) {
        return
      }

      if (!(await validateActiveNetwork(chainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const listingContract = contracts[chainId]

      console.log(
        'purchasing %o repo tokens on repo token contract %o using purchase method',
        amount.toString(),
        termRepoToken
      )

      const data = listingContract.interface.encodeFunctionData('purchase', [
        fixedToBigNumber(amount),
        termRepoToken,
      ])

      await purchaseRepoTokensTransaction({
        chainId,
        to: listingContract.address,
        from: await signer.getAddress(),
        data,
      })

      await waitForStatus(
        async () => transactionStates.purchaseRepoTokens,
        resetPurchaseRepoTokensState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        listingContract
      )

      trackSafaryEvent(
        config?.safary?.earn?.earnComplete?.type ?? 'click',
        'on lend',
        {
          chainId: chainId.toString(),
          contract: listingContract.address,
          method: 'purchase',
          amount: amount.toString(),
          termRepoToken,
        }
      )

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.purchaseRepoTokens?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(
          chainId.toString(),
          minedBlockNumber,
          5,
          1000
        )
      }
      readFromSubgraph()
    },
    [
      config?.safary?.earn?.earnComplete,
      trackSafaryEvent,
      contracts,
      purchaseRepoTokensTransaction,
      readFromSubgraph,
      resetPurchaseRepoTokensState,
      signer,
      transactionStates.purchaseRepoTokens,
      validateActiveNetwork,
    ]
  )

  const swapPurchaseTokensForRepoTokens = useCallback(
    async (
      chainId: number,
      termRepoToken: Address,
      purchaseAmount: FixedNumber
    ) => {
      if (!signer) {
        return
      }

      if (!(await validateActiveNetwork(chainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const listingContract = contracts[chainId]

      console.log(
        'swapping %o purchase tokens on repo token contract %o using swapExactPurchaseForRepo method',
        purchaseAmount.toString(),
        termRepoToken
      )

      console.log(
        'raw purchase amount: %o',
        fixedToBigNumber(purchaseAmount).toString()
      )
      const data = listingContract.interface.encodeFunctionData(
        'swapExactPurchaseForRepo',
        [fixedToBigNumber(purchaseAmount), termRepoToken]
      )

      await swapPurchaseTokensTransaction({
        chainId,
        to: listingContract.address,
        from: await signer.getAddress(),
        data,
      })

      await waitForStatus(
        async () => transactionStates.swapPurchaseTokens,
        resetSwapPurchaseTokensState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        listingContract
      )

      trackSafaryEvent(
        config?.safary?.earn?.earnComplete?.type ?? 'click',
        'on lend',
        {
          chainId: chainId.toString(),
          contract: listingContract.address,
          method: 'swapExactPurchaseForRepo',
          amount: purchaseAmount.toString(),
          termRepoToken,
        }
      )

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.swapPurchaseTokens?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(
          chainId.toString(),
          minedBlockNumber,
          5,
          1000
        )
      }
      readFromSubgraph()
    },
    [
      config?.safary?.earn?.earnComplete,
      trackSafaryEvent,
      contracts,
      swapPurchaseTokensTransaction,
      readFromSubgraph,
      resetSwapPurchaseTokensState,
      signer,
      transactionStates.swapPurchaseTokens,
      validateActiveNetwork,
    ]
  )

  const createListing = useCallback(
    async (
      chainId: string,
      termRepoToken: Address,
      listingAmount: FixedNumber
    ) => {
      if (!signer) {
        return
      }

      if (!(await validateActiveNetwork(Number(chainId)))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const listingContract = contracts[Number(chainId)]

      console.log(
        'creating listing of %o purchase tokens on repo token contract %o using createListing method (listing contract: %o)',
        listingAmount,
        termRepoToken,
        listingContract.address
      )

      const data = listingContract.interface.encodeFunctionData(
        'createListing',
        [termRepoToken, fixedToBigNumber(listingAmount)]
      )

      console.log('call data: %o', [
        termRepoToken,
        fixedToBigNumber(listingAmount).toString(),
      ])

      await createListingTransaction({
        chainId: Number(chainId),
        to: listingContract.address,
        from: await signer.getAddress(),
        data,
      })

      await waitForStatus(
        async () => transactionStates.createListing,
        resetCreateListingState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        listingContract
      )

      trackSafaryEvent(
        config?.safary?.earn?.createListing?.type ?? 'click',
        'create listing',
        {
          chainId: chainId.toString(),
          contract: listingContract.address,
          method: 'createListing',
          amount: listingAmount.toString(),
          termRepoToken,
        }
      )

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.createListing?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(
          chainId.toString(),
          minedBlockNumber,
          5,
          1000
        )
      }
      readFromSubgraph()
    },
    [
      config?.safary?.earn?.createListing,
      trackSafaryEvent,
      contracts,
      createListingTransaction,
      readFromSubgraph,
      resetCreateListingState,
      signer,
      transactionStates.createListing,
      validateActiveNetwork,
    ]
  )

  const cancelListings = useCallback(
    async (chainId: string, listingIds: number[], skipRedeem?: boolean) => {
      if (!signer) {
        return
      }

      if (!(await validateActiveNetwork(Number(chainId)))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const listingContract = contracts[Number(chainId)]

      console.log(
        'cancelling listing(s) %o on listing contract: %o',
        listingIds,
        listingContract.address
      )

      if (skipRedeem) {
        console.log('skipRedeem flag set')
      }

      const data = listingContract.interface.encodeFunctionData(
        'batchCancelListings',
        [listingIds, !!skipRedeem]
      )

      await cancelListingsTransaction({
        chainId: Number(chainId),
        to: listingContract.address,
        from: await signer.getAddress(),
        data,
      })

      await waitForStatus(
        async () => transactionStates.cancelListings,
        resetCancelListingsState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        listingContract
      )

      trackSafaryEvent(
        config?.safary?.earn?.cancelListings?.type ?? 'click',
        'cancel listings',
        {
          chainId: chainId.toString(),
          contract: listingContract.address,
          method: 'cancelListings',
          listingIds: listingIds.join(','),
          skipRedeem: (!!skipRedeem).toString(),
        }
      )

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.cancelListings?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(
          chainId.toString(),
          minedBlockNumber,
          5,
          1000
        )
      }
      readFromSubgraph()
    },
    [
      config?.safary?.earn?.cancelListings,
      trackSafaryEvent,
      cancelListingsTransaction,
      contracts,
      readFromSubgraph,
      resetCancelListingsState,
      signer,
      transactionStates.cancelListings,
      validateActiveNetwork,
    ]
  )

  return {
    discountRateMarkups: markups,
    minimumListingAmounts,
    swapPurchaseTokensForRepoTokens,
    purchaseRepoTokens,
    createListing,
    cancelListings,
  }
}
