import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FixedNumber } from 'ethers'
import { useCallback, useMemo, useState } from 'react'
import InfoBox from '../../../../components/elements/InfoBox'
import { useTermToast } from '../../../../hooks/toasts'
import { isRejectedTxn, termToastMessages } from '../../../../helpers/toasts'
import BorrowerCancelRolloverOverview from '../BorrowerCancelRolloverOverview'
import CancelRolloverButton from './CancelRolloverButton'
import { Trans, t } from '@lingui/macro'

export default function BorrowerCancelRolloverModal({
  purchaseSymbol,
  rolloverAmount,

  auctionLabel,

  endOfRepaymentTimestamp,
  outstandingDebt,

  onCancelRollover,

  isOpen,
  onClose,
}: {
  purchaseSymbol: string

  rolloverAmount: FixedNumber

  auctionLabel: string

  endOfRepaymentTimestamp: number
  outstandingDebt: FixedNumber

  onCancelRollover: () => Promise<void>

  isOpen: boolean
  onClose: () => void
}) {
  const termToast = useTermToast()

  const formatEndOfRepaymentTimestamp = useCallback(
    (timestamp: number) => dayjs.unix(timestamp).format('MMM D, ha'),
    []
  )

  const endOfRepaymentText = useMemo(
    () => formatEndOfRepaymentTimestamp(endOfRepaymentTimestamp),
    [formatEndOfRepaymentTimestamp, endOfRepaymentTimestamp]
  )

  const [isCancellingRollover, setIsCancellingRollover] = useState(false)

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="wide">
      <ModalOverlay />
      <ModalContent p="4px">
        <ModalHeader
          color="gray.900"
          fontSize="lg"
          fontWeight="700"
          lineHeight="7"
        >
          <Trans>Cancel Rollover</Trans>
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody>
          <BorrowerCancelRolloverOverview
            rolloverAmount={rolloverAmount}
            outstandingDebt={outstandingDebt}
            endOfRepaymentTimestamp={endOfRepaymentTimestamp}
            purchaseTokenSymbol={purchaseSymbol}
            title={t`Loan Details`}
            auctionLabel={auctionLabel}
          />
          <Box mt="16px" />
          <InfoBox kind="info">
            <Trans>
              By cancelling your rollover, you will be required to repay your
              total outstanding debt by
            </Trans>{' '}
            {dayjs.unix(endOfRepaymentTimestamp).format('MMM D, ha')}.
          </InfoBox>
          <Box mt="16px" />
          <CancelRolloverButton
            isSubmitting={isCancellingRollover}
            isDisabled={isCancellingRollover}
            onClick={async () => {
              setIsCancellingRollover(true)
              termToast.pending(termToastMessages.cancelRollover.pending)
              try {
                await onCancelRollover()
                setIsCancellingRollover(false)
                termToast.success(
                  termToastMessages.cancelRollover.success(endOfRepaymentText)
                )
                // close modal after rollover successfully cancelled
                onClose()
              } catch (error) {
                setIsCancellingRollover(false)
                if (isRejectedTxn(error)) {
                  termToast.dismissed()
                } else {
                  termToast.failure(termToastMessages.cancelRollover.failure)
                }
              }
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
