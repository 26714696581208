import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import {
  useSendTransaction,
  TransactionState,
  TransactionStatus,
} from '@usedapp/core'
import { Contract, FixedNumber, Signer } from 'ethers'
import { generateTermAuth } from '../auth'
import { Address, Currency } from '../model'
import TermCollateralManagerABI_0_2_4 from '../../abi/v0.2.4/TermRepoCollateralManager.json'
import TermCollateralManagerABI_0_4_1 from '../../abi/v0.4.1/TermRepoCollateralManager.json'
import TermCollateralManagerABI_0_6_0 from '../../abi/v0.6.0/TermRepoCollateralManager.json'
import { TermRepoCollateralManager as TermRepoCollateralManager_0_2_4 } from '../../abi-generated/abi/v0.2.4/TermRepoCollateralManager'
import { TermRepoCollateralManager as TermRepoCollateralManager_0_4_1 } from '../../abi-generated/abi/v0.4.1/TermRepoCollateralManager'
import { TermRepoCollateralManager as TermRepoCollateralManager_0_6_0 } from '../../abi-generated/abi/v0.6.0/TermRepoCollateralManager'
import {
  convertChainId,
  fixedToBigNumber,
  getABIVersion,
} from '../../helpers/conversions'
import { waitForStatus, waitForSubgraphIndexing } from '../../helpers/wait'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  MultichainCalls,
  useCurrentTime,
  useMultichainCalls,
  useMultiSends,
  useWrappedGasTokens,
} from './helper-hooks'
import { encodeMultisend } from '../multisend'

export type TermRepoCollateralManager =
  | TermRepoCollateralManager_0_2_4
  | TermRepoCollateralManager_0_4_1
  | TermRepoCollateralManager_0_6_0

export type ManageCollateralToken = {
  collateralManager: Address
  collateralCurrency: Currency
  version: string
}

export function useManageCollateral(
  tokens:
    | { [chainId: string]: ManageCollateralToken[] | undefined }
    | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined,
  signer: Signer | undefined,
  readFromSubgraph: () => void
): {
  collateralBalances?: { [chainId: string]: FixedNumber[] | undefined }
  lockCollateral?: (
    chainId: string,
    collateralManagerAddress: string,
    collateralTokenAddress: string,
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>
  unlockCollateral?: (
    chainId: string,
    collateralManagerAddress: string,
    collateralTokenAddress: string,
    amount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>
} {
  const {
    sendTransaction: sendUnlockTransaction,
    state: unlockState,
    resetState: resetUnlockState,
  } = useSendTransaction()
  const {
    sendTransaction: sendLockTransaction,
    state: lockState,
    resetState: resetLockState,
  } = useSendTransaction()

  const transactionStates = useMemo(
    () =>
      ({}) as {
        lockCollateral: TransactionStatus | undefined
        unlockCollateral: TransactionStatus | undefined
      },
    []
  )
  const multisends = useMultiSends(provider)
  const wrappedGasTokens = useWrappedGasTokens(provider)
  const currentTime = useCurrentTime()

  const validateActiveNetwork = useCallback(
    async (chainId: number) => {
      const activeNetwork = await signer?.provider?.getNetwork()
      return activeNetwork?.chainId === chainId
    },
    [signer]
  )

  // WARNING: These are used to update in-place so that the lock/delete
  //          methods always have access to the latest transaction state.
  useEffect(() => {
    transactionStates.unlockCollateral = unlockState
  }, [unlockState, transactionStates])
  useEffect(() => {
    transactionStates.lockCollateral = lockState
  }, [lockState, transactionStates])

  const collateralManagerContracts = useMemo(() => {
    const contracts: {
      [chainId: string]: {
        [collateralManager: Address]: {
          manager: TermRepoCollateralManager
          version: string
        }
      }
    } = {}

    if (tokens) {
      Object.entries(tokens).forEach(([chainId, tokenList]) => {
        if (!tokenList) return

        contracts[chainId] = {}

        tokenList.forEach((token) => {
          if (
            !token.collateralManager ||
            contracts[chainId][token.collateralManager]
          ) {
            return
          }

          const tmpContractData = {} as {
            manager: TermRepoCollateralManager
            version: string
          }

          const version = getABIVersion(token.version)
          tmpContractData.version = version

          switch (version) {
            case '0.2.4':
              tmpContractData.manager = new Contract(
                token.collateralManager,
                TermCollateralManagerABI_0_2_4,
                provider
              ) as TermRepoCollateralManager_0_2_4
              break
            case '0.4.1':
              tmpContractData.manager = new Contract(
                token.collateralManager,
                TermCollateralManagerABI_0_4_1,
                provider
              ) as TermRepoCollateralManager_0_4_1
              break
            case '0.6.0':
            default:
              tmpContractData.manager = new Contract(
                token.collateralManager,
                TermCollateralManagerABI_0_6_0,
                provider
              ) as TermRepoCollateralManager_0_6_0
          }

          contracts[chainId][token.collateralManager] = tmpContractData
        })
      })
    }

    return contracts
  }, [provider, tokens])

  const [account, setAccount] = useState<string | undefined>()
  useEffect(() => {
    signer?.getAddress()?.then(setAccount)
  }, [signer])

  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}

    if (account && tokens) {
      Object.entries(tokens).forEach(([chainId, tokenList]) => {
        if (!tokenList) return

        calls[chainId] = tokenList.map((token) => ({
          contract:
            collateralManagerContracts[chainId]?.[token.collateralManager]
              ?.manager,
          method: 'getCollateralBalance',
          args: [account, token.collateralCurrency?.address ?? ''],
        }))
      })
    }
    return calls
  }, [tokens, account, collateralManagerContracts])

  const blockchainResults = useMultichainCalls(contractCalls)

  const collateralBalances = useMemo(() => {
    if (!tokens || !blockchainResults) return undefined

    const balances: {
      [chainId: string]: FixedNumber[] | undefined
    } = {}

    Object.entries(tokens).forEach(([chainId, tokenList]) => {
      if (!tokenList) return

      balances[chainId] = tokenList.map((token, index) => {
        const balance = blockchainResults[chainId]?.[index]?.value?.[0] ?? 0
        const decimals = token?.collateralCurrency?.decimals ?? 0
        return FixedNumber.fromValue(balance, decimals)
      })
    })

    return balances
  }, [tokens, blockchainResults])

  const unlockCollateral = useCallback(
    async (
      chainId: string,
      collateralManagerAddress: string,
      collateralTokenAddress: string,
      amount: FixedNumber,
      isGasToken: boolean
    ) => {
      if (!signer) {
        return 'None'
      }

      const multisend = multisends[chainId]
      const wrappedGasToken = wrappedGasTokens[chainId]
      const parsedChainId = convertChainId(chainId)

      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const collateralManager =
        collateralManagerContracts?.[chainId]?.[collateralManagerAddress]
          ?.manager
      const contractVersion =
        collateralManagerContracts?.[chainId]?.[collateralManagerAddress]
          ?.version

      const amountBn = fixedToBigNumber(amount)
      console.log('cunlock' + amountBn.toString())

      let data: any

      switch (contractVersion) {
        case '0.6.0':
          console.info('withdrawing collateral using 0.6.0 abi')
          data = (
            collateralManager as TermRepoCollateralManager_0_6_0
          ).interface.encodeFunctionData('externalUnlockCollateral', [
            collateralTokenAddress,
            amountBn,
          ])
          break
        case '0.4.1':
          console.info('withdrawing collateral using 0.4.1 abi')
          data = (
            collateralManager as TermRepoCollateralManager_0_4_1
          ).interface.encodeFunctionData('externalUnlockCollateral', [
            collateralTokenAddress,
            amountBn,
          ])
          break
        case '0.2.4':
        default:
          console.info('withdrawing collateral using 0.2.4 abi')
          data = (
            collateralManager as TermRepoCollateralManager_0_2_4
          ).interface.encodeFunctionData('externalUnlockCollateral', [
            collateralTokenAddress,
            amountBn,
            await generateTermAuth(
              signer,
              collateralManager as TermRepoCollateralManager_0_2_4,
              'externalUnlockCollateral',
              [collateralTokenAddress, amountBn],
              currentTime.unix()
            ),
          ])
      }

      if (isGasToken) {
        // Unwrap WETH if isGasToken is true.
        const unwrapData = wrappedGasToken.interface.encodeFunctionData(
          'withdraw',
          [fixedToBigNumber(amount)]
        )
        const multisendData = encodeMultisend([
          {
            to: collateralManagerAddress,
            data,
            value: '0',
          },
          {
            to: wrappedGasToken.address,
            data: unwrapData,
            value: '0',
          },
        ])
        await sendUnlockTransaction({
          chainId: parsedChainId,
          to: multisend.address,
          from: account,
          data: multisendData,
        })
      } else {
        await sendUnlockTransaction({
          chainId: parsedChainId,
          to: collateralManagerAddress,
          from: account,
          data,
        })
      }

      // Wait for state of unlock transaction to complete.
      await waitForStatus(
        async () => transactionStates.unlockCollateral,
        resetUnlockState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        collateralManager
      )

      const result = transactionStates?.unlockCollateral?.status ?? 'None'

      // @dev Exception (cancelling txn) is thrown out of ethers library
      //  so only need to propogate Fail
      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.unlockCollateral?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(chainId, minedBlockNumber, 5, 1000)
      }
      readFromSubgraph()
      return result
    },
    [
      signer,
      multisends,
      wrappedGasTokens,
      validateActiveNetwork,
      collateralManagerContracts,
      resetUnlockState,
      transactionStates.unlockCollateral,
      readFromSubgraph,
      currentTime,
      sendUnlockTransaction,
      account,
    ]
  )

  const lockCollateral = useCallback(
    async (
      chainId: string,
      collateralManagerAddress: string,
      collateralTokenAddress: string,
      amount: FixedNumber,
      isGasToken: boolean
    ) => {
      if (!signer) {
        return
      }

      const multisend = multisends[chainId]
      const wrappedGasToken = wrappedGasTokens[chainId]
      const parsedChainId = convertChainId(chainId)

      if (!(await validateActiveNetwork(parsedChainId))) {
        throw new Error(`active network does not match desired chain id`)
      }

      const collateralManager =
        collateralManagerContracts?.[chainId]?.[collateralManagerAddress]
          ?.manager
      const contractVersion =
        collateralManagerContracts?.[chainId]?.[collateralManagerAddress]
          ?.version

      const amountBn = fixedToBigNumber(amount)

      let data: any

      switch (contractVersion) {
        case '0.6.0':
          console.info('depositing collateral using 0.6.0 abi')
          data = (
            collateralManager as TermRepoCollateralManager_0_6_0
          ).interface.encodeFunctionData('externalLockCollateral', [
            collateralTokenAddress,
            amountBn,
          ])
          break
        case '0.4.1':
          console.info('depositing collateral using 0.4.1 abi')
          data = (
            collateralManager as TermRepoCollateralManager_0_4_1
          ).interface.encodeFunctionData('externalLockCollateral', [
            collateralTokenAddress,
            amountBn,
          ])
          break
        case '0.2.4':
        default:
          console.info('depositing collateral using 0.2.4 abi')
          data = (
            collateralManager as TermRepoCollateralManager_0_2_4
          ).interface.encodeFunctionData('externalLockCollateral', [
            collateralTokenAddress,
            amountBn,
            await generateTermAuth(
              signer,
              collateralManager as TermRepoCollateralManager_0_2_4,
              'externalLockCollateral',
              [collateralTokenAddress, amountBn],
              currentTime.unix()
            ),
          ])
      }

      if (isGasToken) {
        // Wrap ETH if isGasToken is true.
        const wrapData = wrappedGasToken.interface.encodeFunctionData('deposit')
        const multisendData = encodeMultisend([
          // TODO: This doesn't work as it uses msg.sender which is the multisend contract in this case.
          {
            to: wrappedGasToken.address,
            data: wrapData,
            value: fixedToBigNumber(amount).toString(),
          },
          {
            to: collateralManagerAddress,
            data,
            value: '0',
          },
        ])
        await sendLockTransaction({
          chainId: parsedChainId,
          to: multisend.address,
          from: account,
          data: multisendData,
        })
      } else {
        await sendLockTransaction({
          chainId: parsedChainId,
          to: collateralManagerAddress,
          from: account,
          data,
        })
      }

      // Wait for state of lock transaction to complete.
      await waitForStatus(
        async () => transactionStates.lockCollateral,
        resetLockState,
        ['Success', 'Exception', 'Fail'],
        100,
        1000,
        collateralManager
      )

      const result = transactionStates?.lockCollateral?.status ?? 'None'

      // @dev Exception (cancelling txn) is thrown out of ethers library
      //  so only need to propogate Fail
      if (result === 'Fail') {
        throw new Error('Transaction failed')
      }

      // Wait for subgraph to index the transaction.
      const minedBlockNumber =
        transactionStates.lockCollateral?.receipt?.blockNumber
      if (minedBlockNumber) {
        await waitForSubgraphIndexing(chainId, minedBlockNumber, 5, 1000)
      }
      readFromSubgraph()
    },
    [
      account,
      collateralManagerContracts,
      currentTime,
      multisends,
      readFromSubgraph,
      resetLockState,
      sendLockTransaction,
      signer,
      transactionStates.lockCollateral,
      validateActiveNetwork,
      wrappedGasTokens,
    ]
  )

  return {
    collateralBalances,
    lockCollateral,
    unlockCollateral,
  }
}
