import {
  HStack as CHStack,
  Stack as CStack,
  VStack as CVStack,
  StackProps,
} from '@chakra-ui/react'

/**
 * Custom Stack components to handle this change:
 * https://chakra-ui.com/changelog/v2.7.0#chakra-uilayout220
 */

export function Stack({ spacing = '0.5rem', ...props }: StackProps) {
  return <CStack spacing={spacing} {...props} />
}

export function HStack({ spacing = '0.5rem', ...props }: StackProps) {
  return <CHStack spacing={spacing} {...props} />
}

export function VStack({ spacing = '0.5rem', ...props }: StackProps) {
  return <CVStack spacing={spacing} {...props} />
}
