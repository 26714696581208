import { Box, Button, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { MappedLendCurrencies } from '../../../data/model'
import AssetDropdownItem from '../AssetDropdownItem'
import { HStack } from '../Stack'
import TokenIconMultiple from '../TokenIconMultiple'
import TokenIconLabel from '../TokenLabelIcon'

export default function AssetDropdown({
  isDataLoading,
  currencies,
  selectedPurchaseToken = 'USDC',
  showAllOption,
  onChangePurchaseToken,
}: {
  isDataLoading?: boolean
  currencies: MappedLendCurrencies
  selectedPurchaseToken?: string
  showAllOption?: boolean
  onChangePurchaseToken: (token: string) => void
}) {
  const [selectedToken, setSelectedToken] = useState(selectedPurchaseToken)
  const currencySymbols = Object.keys(currencies)
  const uniqueChainIds = [
    ...new Set(
      Object.values(currencies).flatMap((chainAddresses) =>
        Object.keys(chainAddresses).map(Number)
      )
    ),
  ]

  const handleClick = (token: string) => {
    setSelectedToken(token)
    onChangePurchaseToken(token)
  }

  return (
    <Menu offset={[-12, 8]}>
      {({ isOpen }) => (
        <>
          <MenuButton
            bg="transparent"
            isDisabled={isDataLoading}
            isActive={isOpen}
            as={Button}
            rightIcon={
              <Box
                marginInlineStart={0}
                color="blue.5"
                as="span"
                transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                transition="transform 0.2s"
              >
                <FontAwesomeIcon
                  icon={['far', 'chevron-down']}
                  role="button"
                  size="2xl"
                  width="22px"
                />
              </Box>
            }
            _hover={{
              bg: 'transparent',
            }}
            _active={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            borderBottom={{
              base: '3px solid',
            }}
            borderBottomColor={{
              base: 'blue.5',
            }}
            borderRadius="0"
            pb="14px"
            px="0"
          >
            {showAllOption ? (
              <HStack spacing={2}>
                <TokenIconMultiple boxSize={33} symbols={currencySymbols} />
                <Text fontSize="34px" variant="body-3xl/bold">
                  All
                </Text>
              </HStack>
            ) : (
              <Text fontSize="40px" variant="body-3xl/bold">
                <TokenIconLabel
                  boxSize="33px"
                  key={selectedToken}
                  token={selectedToken}
                  isInheritParentTextVariant
                  isBold
                />
              </Text>
            )}
          </MenuButton>
          <MenuList minWidth="332px" p="2" border="none" borderRadius="6px">
            {showAllOption && (
              <AssetDropdownItem
                key="All"
                purchaseToken="empty"
                chainIds={uniqueChainIds}
                handleClick={handleClick}
                isActive={selectedToken === 'All'}
                showAllOption={showAllOption}
                symbols={currencySymbols}
              />
            )}
            {Object.entries(currencies || {}).map(
              ([symbol, chainAddresses]) => {
                const chainIds = Object.keys(chainAddresses).map((id) =>
                  Number(id)
                )
                return (
                  <AssetDropdownItem
                    key={symbol}
                    purchaseToken={symbol}
                    chainIds={chainIds}
                    handleClick={handleClick}
                    isActive={selectedToken === symbol}
                  />
                )
              }
            )}
          </MenuList>
        </>
      )}
    </Menu>
  )
}
