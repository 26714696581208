import { Flex, Link, Tag, TagLabel, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const NavLink = ({
  to,
  active,
  children,
  isNew,
  isBeta,
}: {
  to?: string
  active?: boolean
  children: ReactNode
  isNew?: boolean
  isBeta?: boolean
}) =>
  !to ? (
    <Flex alignItems="center" direction="column" cursor="default" pt="8px">
      <Text color="blue.8" opacity="0.3" as="span" variant="body-sm/semibold">
        {children}
      </Text>
      <Text
        fontSize="8px"
        color="blue.8"
        fontWeight="700"
        opacity="0.3"
        textDecoration="none"
        textTransform="uppercase"
        lineHeight="10px"
        as="span"
      >
        COMING SOON
      </Text>
    </Flex>
  ) : (
    <Link
      as={RouterLink}
      opacity={active ? 1 : 0.7}
      color={active ? 'blue.9' : 'blue.8'}
      py="18px"
      borderBottom={active ? '3px solid' : '3px solid transparent'}
      textDecoration="none"
      lineHeight="1"
      _hover={{
        textDecorationThickness: '3px',
        opacity: active ? 1 : 0.8,
      }}
      to={to}
    >
      <Text as="span" variant="body-sm/semibold">
        {children}
        {isNew && (
          <Tag
            as="span"
            borderRadius="3px"
            bg="#FFD53F"
            minHeight="20px"
            height="20px"
            px="4px"
            ml="8px"
          >
            <TagLabel fontSize="2xs" fontWeight="700" overflow="unset">
              NEW
            </TagLabel>
          </Tag>
        )}
        {isBeta && (
          <Tag
            as="span"
            borderRadius="3px"
            bg="#FFD53F"
            minHeight="20px"
            height="20px"
            px="4px"
            ml="8px"
          >
            <TagLabel fontSize="2xs" fontWeight="700" overflow="unset">
              BETA
            </TagLabel>
          </Tag>
        )}
      </Text>
    </Link>
  )
