import React, { useEffect, ReactNode } from 'react'
import { useConfig } from './config'
import ReactGA from 'react-ga4'

export const GoogleAnalyticsContext = React.createContext<undefined>(undefined)

export const GoogleAnalyticsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const config = useConfig()

  useEffect(() => {
    if (!config.googleAnalytics?.trackingId) {
      return
    }

    ReactGA.initialize(config.googleAnalytics?.trackingId)
  }, [config])

  return (
    <GoogleAnalyticsContext.Provider value={undefined}>
      {children}
    </GoogleAnalyticsContext.Provider>
  )
}
