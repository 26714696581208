import { Box, Circle, Grid, GridItem, Text, VStack } from '@chakra-ui/react'

const StepItem = ({
  stepNumber,
  title,
  description,
}: {
  stepNumber: number
  title: string
  description: string
}) => (
  <GridItem>
    <VStack align="center" justify="center" textAlign="center" spacing={0}>
      <Circle size="32px" bg={stepNumber === 3 ? 'green.5' : 'blue.5'} mb={1}>
        <Text as="span" color="white">
          {stepNumber}
        </Text>
      </Circle>
      <Text
        variant="body-md/semibold"
        color={stepNumber === 3 ? 'green.5' : 'blue.5'}
      >
        {title}
      </Text>
      <Text variant="body-md/normal" color="blue.9">
        {description}
      </Text>
    </VStack>
  </GridItem>
)

export default function InviteSteps() {
  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={3.5} px={3} py={6}>
      <StepItem
        stepNumber={1}
        title="Use Invite"
        description="Your friends input one of your invite codes."
      />
      <GridItem display="flex" alignItems="center" justifyContent="center">
        <Box
          border="1px solid"
          borderColor="#D9D9D9"
          borderWidth="1px"
          borderStyle="dashed"
          flexGrow="1"
        />
      </GridItem>
      <StepItem
        stepNumber={2}
        title="Sign Up"
        description="Your friend signs up by creating an account."
      />
      <GridItem display="flex" alignItems="center" justifyContent="center">
        <Box
          border="1px solid"
          borderColor="#D9D9D9"
          borderWidth="1px"
          borderStyle="dashed"
          flexGrow="1"
        />
      </GridItem>
      <StepItem
        stepNumber={3}
        title="Earn 10%!"
        description="Earn 10% of your friend’s points when they use Term!"
      />
    </Grid>
  )
}
