import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import ContractAddressesDropdown from '../../../../components/elements/ContractAddressesDropdown'
import AuctionBoxText from '../../../../components/elements/AuctionBoxText'
import AuctionTermBadge from '../../../../components/elements/AuctionTermBadge'
import TokenIconLabel from '../../../../components/elements/TokenLabelIcon'
import Tooltip from '../../../../components/elements/Tooltip'
import { Auction, Currency, TermPeriod } from '../../../../data/model'
import { bigToFixedNumber } from '../../../../helpers/conversions'
import { AuctionStatus } from '../../../../helpers/types'
import {
  formatFixedPercentage,
  getAuctionDisplayId,
  getAuctionDurationString,
  getCurrency,
} from '../../../../helpers/utils'
import { useConfig } from '../../../../providers/config'
import AuctionDetail from './AuctionDetail'
import NetworkIcon from '../../../../components/elements/NetworkIcon'

function AuctionModal({
  status,
  currencies,
  auction,
  term,
  isOpen,
  onClose,
}: {
  status: AuctionStatus
  currencies: { [chainId: string]: Currency[] }
  auction: Auction
  term: Pick<
    TermPeriod,
    | 'id'
    | 'repoServicerAddress'
    | 'rolloverManagerAddress'
    | 'collateralManagerAddress'
    | 'termRepoLockerAddress'
    | 'termRepoTokenAddress'
  >
  isOpen: boolean
  onClose: () => void
}) {
  const config = useConfig()

  const chainConfig = useMemo(
    () => !!auction && config.chains[auction.chainId],
    [config.chains, auction?.chainId]
  )

  const [detailsLink, setDetailsLink] = useState<string | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      // only check for results if auction is closed, url is complete and it is not an internal environment
      if (
        auction &&
        auction.closed &&
        config.resultsBucket &&
        config.isMainnet &&
        !config.isInternalEnvironment
      ) {
        setDetailsLink(
          `https://${config.resultsBucket}.s3.amazonaws.com/mainnet/${auction.auctionId}.pdf`
        )
      }
    })()
  }, [
    auction?.auctionId,
    auction?.closed,
    config.isMainnet,
    config.isInternalEnvironment,
    config.resultsBucket,
  ])

  if (!auction || !term || !chainConfig || !status) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent borderRadius="md" p="28px">
        <ModalHeader p="0px">
          <HStack justifyContent="space-between">
            <VStack alignItems="flex-start">
              <HStack>
                <Text
                  fontWeight="700"
                  fontSize="lg"
                  lineHeight="133%"
                  color="gray.700"
                >
                  {`Auction: ${getAuctionDisplayId({
                    ...auction,
                    termSymbol:
                      currencies[auction.chainId].find(
                        (c) => c.address === auction.purchaseCurrency
                      )?.symbol ?? '',
                    collateralSymbol:
                      currencies[auction.chainId].find(
                        (c) => c.address === auction.collateralCurrency
                      )?.symbol ?? '',
                  })}`}
                </Text>
                <NetworkIcon
                  chainId={chainConfig.chainId}
                  boxSize={'20px'}
                  variant="faded"
                  withTooltip
                />
              </HStack>
              <Flex alignItems="flex-start">
                {term && (
                  <ContractAddressesDropdown
                    chainId={chainConfig.chainId}
                    auction={auction.address}
                    bidLocker={auction.bidLockerAddress}
                    offerLocker={auction.offerLockerAddress}
                    repoLocker={term.termRepoLockerAddress}
                    repoServicer={term.repoServicerAddress}
                    collateralManager={term.collateralManagerAddress}
                    rolloverManager={term.rolloverManagerAddress}
                    termRepoToken={term.termRepoTokenAddress}
                  />
                )}
              </Flex>
            </VStack>

            <ModalCloseButton
              position="relative"
              top="initial"
              right="initial"
            />
          </HStack>
        </ModalHeader>
        <ModalBody p="0px" mt="20px">
          <VStack spacing="24px" alignItems="stretch">
            <Flex>
              <AuctionDetail title="Auction Open">
                <Text
                  fontWeight="400"
                  fontSize="md"
                  lineHeight="120%"
                  color="black"
                  textTransform="capitalize"
                >
                  {dayjs
                    .unix(auction.auctionStartTimestamp)
                    .format('MMM D, h:mma')}
                </Text>
              </AuctionDetail>
              <AuctionDetail
                title="Supply / Borrow"
                children={
                  <TokenIconLabel
                    isSmall
                    token={
                      getCurrency(
                        currencies[auction.chainId],
                        auction.purchaseCurrency
                      )?.symbol ?? ''
                    }
                  />
                }
              />
            </Flex>
            <Flex>
              <AuctionDetail title="Auction Duration">
                <AuctionBoxText>
                  {getAuctionDurationString(
                    auction.auctionStartTimestamp,
                    auction.auctionEndTimestamp
                  )}
                </AuctionBoxText>
              </AuctionDetail>
              <AuctionDetail
                title="Collateral"
                children={
                  <TokenIconLabel
                    isSmall
                    token={
                      getCurrency(
                        currencies[auction.chainId],
                        auction.collateralCurrency
                      )?.symbol ?? ''
                    }
                  />
                }
              />
            </Flex>
            <Flex>
              <AuctionDetail title="Auction Close">
                <AuctionBoxText>
                  {dayjs
                    .unix(auction.auctionRevealTimestamp)
                    .format('MMM D, h:mma')}
                </AuctionBoxText>
              </AuctionDetail>
              <AuctionDetail
                title={
                  <>
                    Initial Collateral Ratio{' '}
                    <Tooltip
                      label="The minimum collateral (expressed as a percentage of the loan amount) required to create a bid."
                      aria-label="initial-collateral-ratio-tooltip"
                    />
                  </>
                }
              >
                <AuctionBoxText>
                  {formatFixedPercentage(auction.initialMarginRatio, 1)}
                </AuctionBoxText>
              </AuctionDetail>
            </Flex>
            <Flex>
              <AuctionDetail
                title="Loan Term"
                children={
                  <Box w="fit-content">
                    <AuctionTermBadge auction={auction} />
                  </Box>
                }
              />
              <AuctionDetail
                title={
                  <>
                    Maintenance Collateral Ratio{' '}
                    <Tooltip
                      label="The minimum collateral (expressed as a percentage of outstanding debt) required to secure a loan and the point at which the loan would be liquidated."
                      aria-label="maintenance-collateral-ratio-tooltip"
                    />
                  </>
                }
              >
                <AuctionBoxText>
                  {formatFixedPercentage(auction.maintenanceMarginRatio, 1)}
                </AuctionBoxText>
              </AuctionDetail>
            </Flex>
            <Flex>
              <AuctionDetail title="Maturity">
                <AuctionBoxText>
                  {dayjs.unix(auction.maturityTimestamp).format('MMM D, h:mma')}
                </AuctionBoxText>
              </AuctionDetail>
              {status !== 'upcoming' && (
                <AuctionDetail title="Market Clearing Rate">
                  <Link isExternal href={detailsLink}>
                    <Text variant="body-md/semibold" color="blue.5">
                      {formatFixedPercentage(
                        bigToFixedNumber(auction.auctionClearingRate, 18),
                        3
                      )}
                    </Text>
                  </Link>
                </AuctionDetail>
              )}
            </Flex>
          </VStack>
        </ModalBody>
        <HStack justify="center" spacing="8px">
          <ModalFooter p="0px" mt="20px" w="full">
            <Button
              as="a"
              href={detailsLink}
              isDisabled={!detailsLink}
              target="_blank"
              variant="secondary"
              px="16px"
              py="10x"
              w="full"
            >
              Details
            </Button>
          </ModalFooter>
        </HStack>
      </ModalContent>
    </Modal>
  )
}

export default AuctionModal
