import { Image, Text } from '@chakra-ui/react'
import ThemedIcon from '../ThemedIcon'
import { HStack } from '../Stack/index'

export default function InfoBox({
  kind,
  icon,
  children,
}: {
  kind: 'info' | 'warning' | 'error'
  icon?: string
  children?: React.ReactNode
}) {
  const backgroundColor = (() => {
    switch (kind) {
      case 'info':
        return 'blue.50'
      case 'warning':
        return 'orange.2'
      case 'error':
        return 'red.50'
    }
  })()
  const color = (() => {
    switch (kind) {
      case 'info':
        return 'gray.6'
      case 'warning':
        return 'gray.6'
      case 'error':
        return 'gray.6'
    }
  })()
  const iconColor = (() => {
    switch (kind) {
      case 'info':
        return {
          color: 'blue',
          shade: '5',
        }
      case 'warning':
        return {
          color: 'orange',
          shade: '5',
        }
      case 'error': {
        return {
          color: 'red',
          shade: '5',
        }
      }
    }
  })()

  return (
    <HStack
      padding="8px"
      borderRadius="md"
      backgroundColor={backgroundColor}
      spacing={0}
    >
      {icon ? (
        <Image w={4} h={4} src={icon} />
      ) : (
        <ThemedIcon {...iconColor} icon={['far', 'circle-exclamation']} />
      )}
      <Text ml="8px" color={color} variant="body-sm/normal">
        {children}
      </Text>
    </HStack>
  )
}
