import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { BigNumberish, FixedNumber } from 'ethers'
import { useCallback, useState } from 'react'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import { Address, Currency } from '../../../../data/model'
import { fixedToBigNumber } from '../../../../helpers/conversions'
import { fixedCompare, subtract } from '../../../../helpers/math'
import { termToastMessages } from '../../../../helpers/toasts'
import { formatFixedToken } from '../../../../helpers/utils'
import { useTermToast } from '../../../../hooks/toasts'
import { useChainConfig } from '../../../../providers/config'
import ListTokensField from '../ListTokensField'
import ListingTooltip from './ListingTooltip'
import { captureException } from '@sentry/react'

export default function ListTokensModal({
  isOpen,
  chainId,
  listingContractAddress,
  listingRate,
  auctionClearingRate,
  listingMarkup,
  minimumListingAmount,
  auctionId,
  purchaseTokenCurrency,
  termTokenCurrency,
  termTokenBalance,
  defaultListAmountRaw,
  approvedAmount,
  onKytCheck,
  onApproveTermRepoToken,
  onCreateListing,
  onClose,
}: {
  isOpen: boolean
  chainId: string
  listingContractAddress: string
  listingRate: FixedNumber
  auctionClearingRate: FixedNumber
  listingMarkup: FixedNumber
  minimumListingAmount: FixedNumber
  auctionId: string
  purchaseTokenCurrency: Currency
  termTokenCurrency: Currency
  termTokenBalance: FixedNumber
  defaultListAmountRaw?: string
  approvedAmount: FixedNumber
  onKytCheck: () => Promise<boolean>
  onApproveTermRepoToken: (amount: BigNumberish) => Promise<void>
  onCreateListing: (
    chainId: string,
    termRepoToken: Address,
    listingAmount: FixedNumber
  ) => Promise<void>
  onClose: () => void
}) {
  const termToast = useTermToast()
  const chainConfig = useChainConfig(chainId)

  const listingExplorerUrl =
    chainConfig?.getExplorerAddressLink(listingContractAddress) ?? ''

  const [tokensToListRaw, setTokensToListRaw] = useState(
    defaultListAmountRaw || ''
  )

  const [isListing, setIsListing] = useState(false)

  // convert repo token input to FixedNumber
  const tokensToListFn = FixedNumber.fromString(
    tokensToListRaw || '0',
    termTokenBalance.format
  )

  const termTokenBalanceAfter = subtract(
    termTokenBalance,
    tokensToListFn,
    termTokenBalance.format.decimals
  )

  // TODO: finalise this calc
  const amountToApprove = subtract(
    tokensToListFn,
    approvedAmount,
    termTokenBalance.format.decimals
  )

  const onCloseModal = useCallback(() => {
    setTokensToListRaw('')
    setIsListing(false)
    onClose()
  }, [onClose])

  const submit = useCallback(async () => {
    setIsListing(true)
    try {
      await onCreateListing(chainId, termTokenCurrency.address, tokensToListFn)
      termToast.success(
        termToastMessages.listing.success(
          formatFixedToken(
            tokensToListFn,
            purchaseTokenCurrency.symbol,
            true,
            true,
            termTokenCurrency.symbol
          ),
          listingExplorerUrl,
          purchaseTokenCurrency.symbol
        )
      )
      onCloseModal()
    } catch (error) {
      setIsListing(false)
      termToast.failure(termToastMessages.listing.failure)
      captureException(error)
    }
  }, [
    chainId,
    termTokenCurrency,
    onCreateListing,
    tokensToListFn,
    termToast,
    listingExplorerUrl,
    purchaseTokenCurrency.symbol,
    onCloseModal,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} variant="standard">
      <ModalOverlay />
      <ModalContent p="4px">
        <ModalHeader>
          <Trans>List Tokens</Trans>
        </ModalHeader>
        <ModalCloseButton m="8px" />
        <ModalBody>
          <Text color="blue.9" variant="body-sm/normal" mb="16px">
            <Trans>
              Specify the amount of tokens to list at{' '}
              <ListingTooltip
                listingRate={listingRate}
                auctionClearingRate={auctionClearingRate}
                listingMarkup={listingMarkup}
                auctionId={auctionId}
              />
              .
            </Trans>
          </Text>
          <Box mb="20px" />
          <ListTokensField
            value={tokensToListRaw}
            onChange={setTokensToListRaw}
            purchaseCurrency={purchaseTokenCurrency}
            termTokenBalance={termTokenBalance}
            termTokenBalanceAfter={termTokenBalanceAfter}
            termTokenSymbol={termTokenCurrency.symbol}
            disabled={isListing || termTokenBalance.isZero()}
            helperText={
              (termTokenBalanceAfter.isNegative() && (
                <Text color="red">
                  <Trans>Insufficient Repo Tokens in connected wallet</Trans>
                </Text>
              )) ||
              (!tokensToListFn.isZero() &&
                fixedCompare(tokensToListFn, 'lt', minimumListingAmount) && (
                  <Text color="red">
                    Enter at least {minimumListingAmount.toString()} tokens to
                    list
                  </Text>
                ))
            }
          />
          <Box mb={4} />
          <SubmitApproveButton
            approvalAmount={fixedToBigNumber(amountToApprove)}
            approvedAmount={fixedToBigNumber(approvedAmount)}
            onApprove={async () => {
              await onApproveTermRepoToken(fixedToBigNumber(amountToApprove))
            }}
            onSubmit={submit}
            onKytCheck={onKytCheck}
            approvingCurrencySymbol={termTokenCurrency.symbol}
            isDisabled={
              isListing ||
              tokensToListFn.isZero() ||
              termTokenBalance.isZero() ||
              termTokenBalance.isNegative() ||
              termTokenBalanceAfter.isNegative() ||
              fixedCompare(tokensToListFn, 'lt', minimumListingAmount)
            }
            variant="secondary"
            size="sm"
            w="full"
            submitLabel={t`Create listing`}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
