import { Text, Center, Button, Heading, Image } from '@chakra-ui/react'
import { useContext } from 'react'
import { FiltersContext } from '../../../providers/filters'
import no_auctions_found_graphic from '../../../assets/no-auctions-found.svg'

export default function NoAuctionsFound() {
  const { clearFilters } = useContext(FiltersContext)
  return (
    <Center data-cy="no-auctions-found" flexDirection="column" mt={16}>
      <Heading as="h2" variant="heading/h2" color="blue.9" fontWeight="bold">
        No auctions found
      </Heading>
      <Text variant="body-sm/normal" mb="8px">
        Please try removing some filters.
      </Text>
      <Button variant="link" onClick={clearFilters} mb={10}>
        <Text as="span" variant="body-xs/semibold" color="blue.5">
          Clear all filters
        </Text>
      </Button>
      <Image src={no_auctions_found_graphic} w="363px" h="228px" />
    </Center>
  )
}
