import { FormControlProps } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { ReactNode } from 'react'
import TokenAmountField from '../../../../components/elements/TokenAmountField'
import { t } from '@lingui/macro'

export default function BorrowerRolloverAmountField({
  type = 'text',
  value,
  onChange,
  onChangeToken,
  disabled,
  isError,
  w,

  purchaseTokenSymbol,
  purchaseTokenDecimals,
  outstandingDebt,
  helperText,
}: {
  type?: string
  value: string
  onChange: (value: string) => void
  onChangeToken: (symbol: string) => void
  disabled?: boolean
  isError?: boolean
  w?: FormControlProps['w']

  purchaseTokenSymbol: string
  purchaseTokenDecimals: number
  outstandingDebt: FixedNumber
  helperText?: ReactNode
}) {
  return (
    <TokenAmountField
      label={t`Rollover Amount`}
      helperText={helperText}
      type={type}
      value={value}
      onChange={onChange}
      onChangeToken={onChangeToken}
      onMax={() => onChange(outstandingDebt.toString())}
      isDisabled={disabled}
      isError={isError}
      placeholder="0.00"
      w={w}
      tokenSymbol={purchaseTokenSymbol}
      tokenDecimals={purchaseTokenDecimals}
    />
  )
}
