import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CheckboxGroup,
  Heading,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { HStack, Stack } from '../../../elements/Stack'

export default function FilterLoading({ title }: { title: string }) {
  return (
    <AccordionItem
      border="0px"
      data-cy={`filters-${title.split(' ').join('-').toLowerCase()}`}
    >
      <Heading as="h5">
        <AccordionButton
          data-cy={`filters-${title.split(' ').join('-').toLowerCase()}`}
          px="0px"
          _hover={{
            background: 'transparent',
          }}
          py={0}
        >
          <Box flex="1" textAlign="left">
            <Text as="span" variant="body-sm/semibold" color="blue.9">
              {title}
            </Text>
          </Box>
          <AccordionIcon w="16px" h="16px" ml="4px" />
        </AccordionButton>
      </Heading>
      <AccordionPanel px="0px">
        <CheckboxGroup>
          <Stack>
            {Array.from({ length: 3 }).map((_, i) => (
              <HStack key={'stack' + i} w="full" justifyContent="space-between">
                <Skeleton key={'skel' + i} w="45px" h={8} />
                <Box
                  key={'box' + i}
                  border="2px solid"
                  borderColor="gray.2"
                  borderRadius="2px"
                  bg="white"
                  transition="all 0.3s ease"
                  w={4}
                  h={4}
                />
              </HStack>
            ))}
          </Stack>
        </CheckboxGroup>
      </AccordionPanel>
    </AccordionItem>
  )
}
