import { Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

export default function PageDescription({ children }: { children: ReactNode }) {
  return (
    <Text
      color="blue.9"
      variant="body-sm/normal"
      mb="16px"
      maxW="748px"
      minW="586px"
    >
      {children}
    </Text>
  )
}
