import { Box, Image, Skeleton, Text } from '@chakra-ui/react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import info from '../../../../assets/icons/info.svg'
import { Table, TableBody, Td, Tr } from '../../../../components/elements/Table'
import Tooltip from '../../../../components/elements/Tooltip'
import { components } from '../../../../models/profile-api'
import TableBorder from './TableBorder'
import TableHeader from './TableHeader'
import { useConfig } from '../../../../providers/config'
import { HStack } from '../../../../components/elements/Stack'
import { networkIcons } from '../../../../helpers/networkIcons'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { generateAvgLoanBalanceToolip } from '../../../../helpers/utils'
import { FixedNumber } from 'ethers'
import { formatFixed } from '../../../../helpers/conversions'

const LendingTable = ({
  points,
  totalPoints,
  totalActiveLoans,
  totalActivePositions,
  isDisabled = false,
  collapsed = false,
  maxColWidth = 100,
  season,
  handleColumnWidth,
  isLoading,
}: {
  points: components['schemas']['LendingPoints'] | undefined
  totalPoints: number
  totalActiveLoans: FixedNumber
  totalActivePositions: number
  isDisabled?: boolean
  collapsed: boolean
  maxColWidth: number
  season: components['schemas']['Season'] | undefined
  handleColumnWidth?: (width: number) => void
  isLoading?: boolean
}) => {
  const [isTableBodyVisible, setTableBodyVisible] = useState(true)

  const currentTime = useCurrentTime()
  const loanBalanceToolTip = generateAvgLoanBalanceToolip(currentTime, season)

  const toggleTableBody = () => {
    setTableBodyVisible(!isTableBodyVisible)
  }

  const percentTotalPoints = useMemo(
    () =>
      points && totalPoints
        ? (points._subtotal / totalPoints) * 100
        : undefined,
    [points, totalPoints]
  )

  const config = useConfig()

  useEffect(() => {
    setTableBodyVisible(!collapsed)
  }, [collapsed])

  return (
    <TableBorder isDisabled={isDisabled}>
      <Table noPadding>
        <TableHeader
          category="lending"
          title="Lenders earn points alongside their secure fixed-rate interest earnings."
          totalPoints={points?._subtotal || 0}
          handleClick={toggleTableBody}
          isTableBodyVisible={isDisabled ? false : isTableBodyVisible}
          percentOfTotalPointsEarned={percentTotalPoints}
          handleColumnWidth={handleColumnWidth}
          maxColWidth={maxColWidth}
          isLoading={isLoading}
        />
        <TableBody
          style={{
            display: !isTableBodyVisible || isDisabled ? 'none' : 'table',
          }}
          w="100%"
          mt={8}
        >
          <Tr noBorder _hover={{}}>
            <Td w="400px" h="auto"></Td>
            <Td colSpan={2} pb={0} px={0} h="auto">
              <Text
                color="blue.9"
                variant="body-md/semibold"
                borderBottom="1px"
                borderColor="gray.4"
                w="full"
                pb={1}
                mb={3}
              >
                Details
              </Text>
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr noBorder _hover={{}}>
                <Td pb={0}>
                  <HStack>
                    <Text color="gray.6" variant="body-md/semibold">
                      Your Average Loan Balance{' '}
                    </Text>
                    <Tooltip
                      iconColor="gray.4"
                      aria-label="your-average-loan-balance-tooltip"
                      label={loanBalanceToolTip}
                      noDelay
                    >
                      <Image src={info} boxSize="16px" opacity="0.5" />
                    </Tooltip>
                  </HStack>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" px={2}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : (
                      <Text color="blue.9" variant="body-md/semibold">
                        {points?.meta?.averageBalance ? (
                          `$${points?.meta.averageBalance.toLocaleString()}`
                        ) : (
                          <Text
                            color="gray.5"
                            as="span"
                            variant="body-md/normal"
                          >
                            &mdash;
                          </Text>
                        )}
                      </Text>
                    )}
                  </Box>
                </Td>
              </Tr>
              {points &&
                points.meta?.chainSubtotals &&
                Object.keys(points.meta?.chainSubtotals).length > 1 &&
                Object.entries(points.meta?.chainSubtotals).map(
                  ([chainId, subtotal]) => (
                    <Fragment key={chainId}>
                      <Tr noBorder _hover={{}} height={6} opacity={0.6}>
                        <Td pb={0} pl={4}>
                          <HStack>
                            {isLoading ? (
                              <Skeleton h="24px" w="80px" />
                            ) : (
                              <>
                                <Image src={networkIcons[Number(chainId)]} />
                                <Text color="gray.6" variant="body-md/semibold">
                                  {config?.chains?.[chainId]?.chainName}
                                </Text>
                              </>
                            )}
                          </HStack>
                        </Td>
                        <Td textAlign="right" w="full" pb={0}>
                          <Box w="fit-content" ml="auto" px={2}>
                            {isLoading ? (
                              <Skeleton h="24px" w="80px" />
                            ) : (
                              <Text color="gray.6" variant="body-md/medium">
                                {subtotal ? (
                                  `$${subtotal.toLocaleString()}`
                                ) : (
                                  <Text
                                    color="gray.5"
                                    as="span"
                                    variant="body-md/normal"
                                  >
                                    &mdash;
                                  </Text>
                                )}
                              </Text>
                            )}
                          </Box>
                        </Td>
                      </Tr>
                    </Fragment>
                  )
                )}
            </Td>
          </Tr>
          <Tr noBorder _hover={{}}>
            <Td w="400px"></Td>
            <Td colSpan={2} px={0}>
              <Tr
                noBorder
                _hover={{}}
                bg={
                  points &&
                  points.meta?.chainSubtotals &&
                  Object.keys(points.meta?.chainSubtotals).length % 2 === 0
                    ? 'blue.0'
                    : undefined
                }
              >
                <Td pb={0}>
                  <Text color="gray.6" variant="body-md/semibold">
                    Your Active Loan Balance
                  </Text>
                </Td>
                <Td textAlign="right" w="full" pb={0}>
                  <Box w="fit-content" ml="auto" px={2}>
                    {isLoading ? (
                      <Skeleton h="24px" w="80px" />
                    ) : (
                      <Text color="blue.9" variant="body-md/semibold">
                        {totalActiveLoans && totalActivePositions ? (
                          <>
                            {formatFixed(totalActiveLoans, {
                              displayDecimals: 3,
                              prefix: '$',
                            })}
                            <Text
                              as="span"
                              variant="body-md/medium"
                              opacity={0.5}
                            >
                              {` over ${totalActivePositions} positions`}
                            </Text>
                          </>
                        ) : (
                          <Text
                            color="gray.5"
                            as="span"
                            variant="body-md/normal"
                          >
                            &mdash;
                          </Text>
                        )}
                      </Text>
                    )}
                  </Box>
                </Td>
              </Tr>
            </Td>
          </Tr>
        </TableBody>
      </Table>
    </TableBorder>
  )
}
export default LendingTable
