import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { VStack } from '../../../../components/elements/Stack'
import { components as ProfileSchema } from '../../../../models/profile-api'
import AuctionTable from './AuctionTable'
import InviteTable from './InviteTable'
import LendingTable from './LendingTable'
import OtherTable from './OtherTable'
import { FixedNumber } from 'ethers'

export default function TrackPointsSummary({
  data,
  preseasonPoints,
  season,
  totalNumberOfWeeks,
  totalNumberOfAuctions,
  totalPoints,
  totalActiveLoans,
  totalNumOfActiveLoans,
  profileData,
  profileBadges,
  usedInvites,
  totalInvites,
  isLoading,
}: {
  data: ProfileSchema['schemas']['ProfilePoints'][string] | undefined
  preseasonPoints: ProfileSchema['schemas']['PreseasonPoints'] | undefined
  season: ProfileSchema['schemas']['Season'] | undefined
  totalNumberOfWeeks: number
  totalNumberOfAuctions: number
  totalPoints: number
  totalActiveLoans: FixedNumber
  totalNumOfActiveLoans: number
  profileData?: ProfileSchema['schemas']['Profile']
  profileBadges?: ProfileSchema['schemas']['Badge'][]
  usedInvites?: number
  totalInvites?: number
  isLoading?: boolean
}) {
  const [collapsed, setToggleCollapsed] = useState(false)
  const points = data?.points

  const toggleCollapse = () => {
    setToggleCollapsed(!collapsed)
  }

  const [maxColWidth, setMaxColWidth] = useState(0)

  const handleColumnWidth = (width: number) => {
    setMaxColWidth((oldWidth) => Math.max(oldWidth, width))
  }

  return (
    <>
      <Flex>
        <VStack align="start">
          <Text color="blue.9" variant="body-l/medium">
            Your distribution
          </Text>
          <Text color="gray.6" variant="body-md/normal">
            Your Term Points broken down by category.
          </Text>
        </VStack>
        <VStack align="end" justify="end" ml="auto">
          <Button
            variant="unstyled"
            alignSelf="flex-end"
            color="blue.5"
            height="auto"
            onClick={toggleCollapse}
          >
            <HStack justifyContent="flex-end" pr={1}>
              <Text as="span" variant="body-md/normal">
                {collapsed ? 'Open all' : 'Collapse all'}
              </Text>
              <Box
                marginInlineStart={0}
                color="blue.5"
                as="span"
                transform={!collapsed ? 'rotate(0deg)' : 'rotate(180deg)'}
                transition="transform 0.2s"
              >
                <FontAwesomeIcon
                  icon={['far', 'chevron-up']}
                  role="button"
                  width="12px"
                />
              </Box>
            </HStack>
          </Button>
        </VStack>
      </Flex>
      <Flex direction="column" gap={7}>
        <AuctionTable
          points={points?.auctionPoints}
          collapsed={collapsed}
          totalNumberOfAuctions={totalNumberOfAuctions}
          totalNumberOfWeeks={totalNumberOfWeeks}
          totalPoints={totalPoints}
          handleColumnWidth={handleColumnWidth}
          maxColWidth={maxColWidth}
          isLoading={isLoading}
        />
        <LendingTable
          points={points?.lendingPoints}
          totalActiveLoans={totalActiveLoans}
          totalActivePositions={totalNumOfActiveLoans}
          collapsed={collapsed}
          totalPoints={totalPoints}
          handleColumnWidth={handleColumnWidth}
          maxColWidth={maxColWidth}
          season={season}
          isLoading={isLoading}
        />
        <InviteTable
          points={points?.invitePoints}
          collapsed={collapsed}
          handleColumnWidth={handleColumnWidth}
          maxColWidth={maxColWidth}
          totalPoints={totalPoints}
          usedInvites={usedInvites}
          totalInvites={totalInvites}
          isLoading={isLoading}
        />
        <OtherTable
          profileData={profileData}
          profileBadges={profileBadges}
          otherPoints={points?.otherPoints}
          migrationPoints={points?.migrationPoints}
          preseasonPoints={preseasonPoints}
          totalPoints={totalPoints}
          collapsed={collapsed}
          handleColumnWidth={handleColumnWidth}
          maxColWidth={maxColWidth}
          isLoading={isLoading}
        />
      </Flex>
    </>
  )
}
