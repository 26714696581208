import { Box, BoxProps, useToken } from '@chakra-ui/react'
import { Cell, Pie, PieChart } from 'recharts'

interface ProgressDoughnutProps extends BoxProps {
  color: string
  totalValue: number
  value: number
}

export default function ProgressDoughnut({
  color,
  totalValue,
  value,
  ...props
}: ProgressDoughnutProps) {
  const [fillColor] = useToken('colors', [color])
  const [trackColor] = useToken('colors', ['gray.3'])

  return (
    <Box {...props}>
      <PieChart width={60} height={60} style={{ cursor: 'pointer' }}>
        <Pie
          data={[{ value: totalValue }]}
          innerRadius={22}
          outerRadius={25}
          fill={trackColor}
          startAngle={90}
          endAngle={-270}
          stroke="none"
          dataKey="value"
          paddingAngle={5}
        />
        <Pie
          data={[{ value }]}
          innerRadius={22}
          outerRadius={25}
          fill="none"
          startAngle={90}
          endAngle={90 + (value / totalValue) * 360}
          paddingAngle={5}
          dataKey="value"
          stroke={fillColor}
          strokeWidth={2}
        >
          <Cell fill={fillColor} />
        </Pie>
      </PieChart>
    </Box>
  )
}
