import { Box, BoxProps, HStack, Text, useToken } from '@chakra-ui/react'
import { Cell, Label, Pie, PieChart } from 'recharts'

interface PointsCardProgressProps extends BoxProps {
  color: string
  totalValue: number
  value: number
  label?: string
  multiplier?: number
}

export default function PointsCardProgress({
  color,
  totalValue,
  value,
  label,
  multiplier,
  ...props
}: PointsCardProgressProps) {
  const [fillColor] = useToken('colors', [color])

  return (
    <Box {...props}>
      <HStack>
        <PieChart width={55} height={55}>
          <Pie
            data={[{ value: value }]}
            innerRadius={22}
            outerRadius={26}
            fill="none"
            startAngle={90}
            endAngle={90 - (value / totalValue) * 360}
            paddingAngle={5}
            dataKey="value"
            stroke={fillColor}
            strokeWidth={2}
          >
            <Cell fill={fillColor} />
          </Pie>
          <Pie // Inner circle
            data={[{ value: totalValue }]}
            innerRadius={0}
            outerRadius={22}
            fill="#F4F4F4"
            stroke="none"
            dataKey="value"
            isAnimationActive={false}
          >
            <Label
              value={`${value}/${totalValue}`}
              position="center"
              style={{ fontSize: '12px', fontWeight: 400 }}
            />
          </Pie>
        </PieChart>
        {!!multiplier ? (
          <Text variant="body-xs/medium" color="blue.9">
            <Text as="span" opacity={0.5}>
              weeks |{' '}
            </Text>
            <Text as="span" variant="body-xs/semibold" opacity={1}>
              {multiplier}x active{' '}
            </Text>
          </Text>
        ) : (
          <Text variant="body-xs/medium" color="blue.9" opacity={0.5}>
            weeks participated
          </Text>
        )}
      </HStack>
    </Box>
  )
}
