import {
  Button,
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  MenuItemProps,
} from '@chakra-ui/react'
import collapseNow from '../../../../assets/icons/collapseNow.svg'
import link from '../../../../assets/icons/link.svg'
import info from '../../../../assets/icons/info.svg'
import clockIcon from '../../../../assets/icons/clock.svg'
import xIcon from '../../../../assets/icons/x.svg'
import earlyRepay from '../../../../assets/icons/repay-early.svg'

import { FixedNumber } from 'ethers'
import IconButton from '../../../../components/elements/IconButton'
import TokenIcon from '../../../../components/elements/TokenIcon'
import { ReactElement, FC } from 'react'
import { useConfig } from '../../../../providers/config'
import semver from 'semver'
import { t } from '@lingui/macro'

export default function BorrowMorePopout({
  termVersion,
  isMatured,
  outstandingDebt,
  collateralDeposited,
  rolloverAmount,
  rolloverElected,
  rolloverCancelled,
  rolloverFailed,
  collateralSymbol,
  auctionId = '',

  onRepay,
  onManageMargin,
  onElectEditRollover,
  onCancelRollover,
  onCollapseBorrow,
  onContractAddress,
}: {
  termVersion: string
  isMatured: boolean
  outstandingDebt: FixedNumber
  collateralDeposited: FixedNumber
  rolloverAmount: FixedNumber
  rolloverElected?: boolean
  rolloverCancelled?: boolean
  rolloverFailed?: boolean
  collateralSymbol: string
  auctionId?: string

  onRepay?: () => void
  onManageMargin?: () => void
  onElectEditRollover?: () => void
  onCancelRollover?: () => void
  onCollapseBorrow?: () => void
  onContractAddress?: () => void
}) {
  const config = useConfig()

  const displayRepay: boolean = !isMatured && semver.gte(termVersion, '0.5.5')
  const displayManagePosition: boolean =
    !collateralDeposited.isNegative() && !collateralDeposited.isZero()
  const displayRollOver: boolean =
    !isMatured && !outstandingDebt.isZero() && !outstandingDebt.isNegative()
  const displayCancelRollover: boolean =
    rolloverFailed ||
    (!isMatured &&
      !outstandingDebt.isZero() &&
      !outstandingDebt.isNegative() &&
      !rolloverAmount.isZero() &&
      !rolloverCancelled)
  const displayCollapseNow: boolean =
    !isMatured && !outstandingDebt.isZero() && !outstandingDebt.isNegative()

  const detailsLink =
    config.isMainnet && config.resultsBucket
      ? `https://${config.resultsBucket}.s3.amazonaws.com/mainnet/${auctionId}.pdf`
      : undefined

  return (
    <Menu
      computePositionOnMount={false}
      placement="bottom-end"
      strategy="fixed"
    >
      <MenuButton as={Button} variant="link" px="0" display="inline">
        <IconButton hasShadow />
      </MenuButton>
      <MenuList mr={4}>
        {displayManagePosition ? (
          <ImageTextMenuItem
            icon={
              <TokenIcon token={collateralSymbol} imageProps={{ mr: '6px' }} />
            }
            onClick={onManageMargin}
            isDisabled={!onManageMargin}
            text={t`Manage Collateral`}
          />
        ) : null}
        {displayRepay ? (
          <ImageTextMenuItem
            icon={<Image src={earlyRepay} mr="6px" boxSize="16px" />}
            onClick={onRepay}
            isDisabled={!onRepay}
            text={t`Repay Early`}
          />
        ) : null}
        {displayRollOver ? (
          <ImageTextMenuItem
            icon={<Image src={clockIcon} mr="6px" boxSize="16px" />}
            text={!rolloverElected ? t`Elect Rollover` : t`Edit Rollover`}
            onClick={onElectEditRollover}
            isDisabled={!onElectEditRollover}
          />
        ) : null}
        {displayCancelRollover ? (
          <ImageTextMenuItem
            icon={<Image src={xIcon} mr="6px" boxSize="12px" />}
            text={t`Cancel Rollover`}
            onClick={onCancelRollover}
            isDisabled={!onCancelRollover}
          />
        ) : null}
        {displayCollapseNow ? (
          <ImageTextMenuItem
            icon={<Image src={collapseNow} mr="6px" boxSize="16px" />}
            text={t`Collapse Now`}
            onClick={onCollapseBorrow}
            isDisabled={!onCollapseBorrow}
          />
        ) : null}
        <ImageTextMenuItem
          icon={<Image src={info} mr="6px" boxSize="16px" />}
          text={t`View Auction Results`}
          as="a"
          href={detailsLink}
          isDisabled={!detailsLink}
          target="_blank"
        />
        <ImageTextMenuItem
          icon={<Image src={link} mr="6px" boxSize="16px" />}
          text={t`View Contract Addresses`}
          onClick={onContractAddress}
          isDisabled={!onContractAddress}
        />
      </MenuList>
    </Menu>
  )
}

export const ImageTextMenuItem: FC<ImageTextMenuItemProps> = ({
  icon,
  text,
  children,
  ...props
}) => {
  return (
    <MenuItem {...props}>
      <Flex alignItems="center">
        <Box
          boxSize="20px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {icon || null}
        </Box>
        <Text as="span" ml="2px">
          {text}
        </Text>
        {children}
      </Flex>
    </MenuItem>
  )
}

interface ImageTextMenuItemProps extends MenuItemProps {
  icon?: ReactElement
  text?: string
  href?: string
  target?: string
}
