import ButtonGroup from '../../../../components/elements/ButtonGroup'
import { t } from '@lingui/macro'

export default function TabButtons({
  isDepositDisabled,
  isWithdrawDisabled,
  value,
  onChange,
}: {
  isDepositDisabled?: boolean
  isWithdrawDisabled?: boolean
  value: 'deposit' | 'withdraw'
  onChange: (value: 'deposit' | 'withdraw') => void
}) {
  return (
    <ButtonGroup
      isLeftDisabled={isDepositDisabled}
      isRightDisabled={isWithdrawDisabled}
      left={{ label: t`Deposit`, value: 'deposit' }}
      right={{ label: t`Withdraw`, value: 'withdraw' }}
      value={value}
      onChange={onChange}
      flexGrow={1}
    />
  )
}
