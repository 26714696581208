import { Box, Text, IconButton, Image, Link, Flex } from '@chakra-ui/react'
import { Fragment, useState } from 'react'
import { HStack } from '../Stack'
import aaveIcon from '../../../assets/icons/projects/aave-squared.svg'
import termIcon from '../../../assets/icons/projects/term-squared.svg'
import morphoIcon from '../../../assets/icons/projects/morpho-squared.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { SuggestedRatesByPlatform } from '../../../data/model'

export default function RatesHelper({
  variant,
  rateSuggestions,
  isOpen,
  handleSelection,
}: {
  variant: 'supply' | 'borrow'
  rateSuggestions: SuggestedRatesByPlatform
  isOpen: boolean
  handleSelection: (value: string) => void
}) {
  const sortedRateSuggestions: SuggestedRatesByPlatform = Object.keys(
    rateSuggestions
  )
    .sort((a, b) => {
      if (a === 'term') return -1
      if (b === 'term') return 1
      if (a === 'a3') return -1
      if (b === 'a3') return 1
      if (a === 'm1') return -1
      if (b === 'm1') return 1
      return a.localeCompare(b)
    })
    .reduce((acc, key) => {
      acc[key] = rateSuggestions[key]
      return acc
    }, {} as SuggestedRatesByPlatform)

  return (
    isOpen && (
      <Box
        position="absolute"
        width="100%"
        border="1px solid"
        borderColor="gray.3"
        borderRadius="0 0 4px 4px"
        bg="white"
        zIndex="1"
      >
        {Object.keys(sortedRateSuggestions).map((platform) => (
          <Fragment key={platform}>
            {platform === 'term' ? (
              <ProtocolPrimaryRateInfo
                platform={platform}
                label={'Last'}
                value={rateSuggestions[platform].lastAuctionRate}
                url={rateSuggestions[platform].url}
                handleSelection={handleSelection}
              />
            ) : (
              <>
                <ProtocolPrimaryRateInfo
                  platform={platform}
                  label={variant}
                  value={
                    variant === 'supply'
                      ? rateSuggestions[platform].supplyRate
                      : rateSuggestions[platform].borrowRate
                  }
                  url={rateSuggestions[platform].url}
                  handleSelection={handleSelection}
                />
                <ProtocolSecondaryRateInfo
                  label={'Average'}
                  value={rateSuggestions[platform].averageRate}
                  url={rateSuggestions[platform].url}
                  handleSelection={handleSelection}
                />
                <ProtocolSecondaryRateInfo
                  label={variant === 'supply' ? 'borrow' : 'supply'}
                  value={
                    variant === 'supply'
                      ? rateSuggestions[platform].borrowRate
                      : rateSuggestions[platform].supplyRate
                  }
                  url={rateSuggestions[platform].url}
                  isLastItem
                  handleSelection={handleSelection}
                />
              </>
            )}
          </Fragment>
        ))}
      </Box>
    )
  )
}

const ProtocolPrimaryRateInfo = ({
  platform,
  label,
  value,
  url,
  handleSelection,
}: {
  platform: string
  label: string
  value?: string
  url?: string
  handleSelection: (value: string) => void
}) => {
  const [isLinkVisible, setIsLinkVisible] = useState(false)
  const handleMouseEnter = () => setIsLinkVisible(true)
  const handleMouseLeave = () => setIsLinkVisible(false)

  const imageSrc =
    platform === 'term'
      ? termIcon
      : platform === 'a3'
        ? aaveIcon
        : platform === 'm1'
          ? morphoIcon
          : undefined

  return value ? (
    <RateItem
      label={label}
      value={value}
      url={url}
      icon={imageSrc}
      handleSelection={handleSelection}
      isLinkVisible={isLinkVisible}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  ) : undefined
}

const ProtocolSecondaryRateInfo = ({
  label,
  value,
  url,
  isLastItem,
  handleSelection,
}: {
  label: string
  value?: string
  isLastItem?: boolean
  url?: string
  handleSelection: (value: string) => void
}) => {
  const [isLinkVisible, setIsLinkVisible] = useState(false)
  const handleMouseEnter = () => setIsLinkVisible(true)
  const handleMouseLeave = () => setIsLinkVisible(false)

  return value ? (
    <Box
      p={0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleSelection(value)}
      cursor="pointer"
    >
      <HStack gap={2} w="full" py={0} pr={1}>
        {isLastItem ? (
          <Flex align="start" h="36px">
            <HStack align="end" justify="start" gap={0}>
              <Box borderRight="1px" borderColor="gray.3" w="15px" h="20px">
                {' '}
              </Box>
              <Box
                borderBottom="1px"
                borderColor="gray.3"
                w="5px"
                boxSizing="border-box"
              />
            </HStack>
          </Flex>
        ) : (
          <HStack align="center" justify="start" gap={0} h="36px">
            <Box
              borderRight="1px"
              borderColor="gray.3"
              w="15px"
              h="full"
              boxSizing="border-box"
            >
              {' '}
            </Box>
            <Box
              borderBottom="1px"
              borderColor="gray.3"
              w="5px"
              boxSizing="border-box"
            />
          </HStack>
        )}
        <Text variant="body-xs/normal" color="gray.6" flex="1" minWidth="50px">
          {value}%
        </Text>
        <Text
          variant="body-xs/normal"
          color="gray.5"
          mr="auto"
          flex="2"
          textTransform="capitalize"
        >
          {label}
        </Text>
        <LinkIcon url={url} isLinkVisible={isLinkVisible} />
      </HStack>
    </Box>
  ) : undefined
}

const RateItem = ({
  label,
  value,
  url,
  icon,
  handleSelection,
  isLinkVisible,
  onMouseEnter,
  onMouseLeave,
}: {
  label: string
  value: string
  icon?: string
  url?: string
  handleSelection: (value: string) => void
  isLinkVisible: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
}) => {
  return (
    <Box
      px={1}
      py={2.5}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => handleSelection(value)}
      cursor="pointer"
    >
      <HStack justify="start" spacing={3.5} w="full">
        <Text variant="body-md/normal" color="gray.6" flex="1" minWidth="67px">
          {value}%
        </Text>
        <Text
          variant="body-xs/normal"
          color="gray.5"
          flex="2"
          textTransform="capitalize"
        >
          {label}
        </Text>
        <Box ml="auto">
          <LinkIcon url={url} isLinkVisible={isLinkVisible} icon={icon} />
        </Box>
      </HStack>
    </Box>
  )
}

const LinkIcon = ({
  url,
  isLinkVisible,
  icon,
}: {
  url?: string
  isLinkVisible: boolean
  icon?: string
}) => {
  return (
    <>
      {!isLinkVisible && icon && (
        <IconButton
          aria-label="Icon"
          visibility="visible"
          variant="unstyled"
          width="16px"
          height="16px"
          minW={0}
          p={0}
          borderRadius={0}
          icon={<Image width="16px" height="16px" src={icon} />}
        >
          Icon
        </IconButton>
      )}
      {isLinkVisible && (
        <Link isExternal href={url}>
          <Text fontSize="xs" color="gray.3" _hover={{ color: 'gray.4' }}>
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              width="16px"
              height="16px"
            />
          </Text>
        </Link>
      )}
    </>
  )
}
