import {
  Text,
  Circle,
  Center,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  TextProps,
  ModalHeaderProps,
  ModalBodyProps,
  SquareProps,
  CenterProps,
} from '@chakra-ui/react'

export type ToSPanelId = 1 | 2 | 3

export const ToSHeading = ({
  children,
  fontWeight = '700',
  fontSize = '16px',
  lineHeight = '120%',
  color = 'blue.900',
  ...props
}: TextProps) => {
  return (
    <Text
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color}
      {...props}
    >
      {children}
    </Text>
  )
}

export const ToSSubHeading = ({
  children,
  fontWeight = '400',
  fontSize = '14px',
  lineHeight = '20px',
  color = 'gray.500',
  ...props
}: TextProps) => {
  return (
    <Text
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      color={color}
      {...props}
    >
      {children}
    </Text>
  )
}

export const ToSHeader = ({
  children,
  pt = '28px',
  px = '28px',
  ...props
}: ModalHeaderProps) => {
  return (
    <ModalHeader pt={pt} px={px} {...props}>
      {children}
      <ModalCloseButton size="sm" mr="12px" mt="16px" color="blue.900" />
    </ModalHeader>
  )
}

export const ToSProgressDot = ({
  size = '16px',
  border = '2px solid',
  borderColor = 'blue.500',
  backgroundColor = 'white',
  ...props
}: SquareProps) => {
  return (
    <Circle
      size={size}
      border={border}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      {...props}
    />
  )
}

export const ToSProgress = ({
  panel,
  display = 'flex',
  flexDirection = 'row',
  alignItems = 'center',
  gap = '16px',
  pt = '20px',
  pb = '5px',
  ...props
}: { panel: ToSPanelId } & Omit<CenterProps, 'children'>) => {
  return (
    <Center
      display={display}
      flexDirection={flexDirection}
      alignItems={alignItems}
      gap={gap}
      pt={pt}
      pb={pb}
      {...props}
    >
      <ToSProgressDot backgroundColor={panel === 1 ? 'blue.500' : 'white'} />
      <ToSProgressDot backgroundColor={panel === 2 ? 'blue.500' : 'white'} />
      <ToSProgressDot backgroundColor={panel === 3 ? 'blue.500' : 'white'} />
    </Center>
  )
}

export const ToSBody = ({
  children,
  px = '28px',
  panel,
  ...props
}: { panel: ToSPanelId } & ModalBodyProps) => {
  return (
    <ModalBody px={px} {...props}>
      {children}
      <ToSProgress panel={panel} />
    </ModalBody>
  )
}

export const ToSFooter = ModalFooter
