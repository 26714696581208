import { Box, Button, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FixedNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { ListingsData } from '../../../../data/model'
import LendTableEmpty from '../LendTableEmpty'
import RepoCard from '../RepoCard'
import RepoCardLoading from '../RepoCard/RepoCardLoading'

const sortButtons: { id: keyof ListingsData; label: string }[] = [
  {
    id: 'lendApy',
    label: 'Fixed APY',
  },
  {
    id: 'redemptionTimestamp',
    label: 'Maturity',
  },
  {
    id: 'remainingAmount',
    label: 'Earn Limit',
  },
  // {
  //   id: 'risk',
  //   label: 'Risk',
  // },
  {
    id: 'termPointsMultiplier',
    label: 'Multiplier',
  },
]

const ColumnSortButton = ({
  label,
  isActive,
  onClick,
}: {
  label: string
  isActive: boolean
  onClick: () => void
}) => (
  <Button variant="unstyled" height="fit-content" onClick={onClick}>
    <HStack>
      <Text as="span" variant="body-sm/medium" color="grey.6">
        {label}
      </Text>
      <Box
        marginInlineStart={0}
        color={isActive ? 'black' : 'gray.5'}
        as="span"
        transform={isActive ? 'rotate(0deg)' : 'rotate(180deg)'}
        transition="transform 0.2s"
      >
        <FontAwesomeIcon
          icon={['far', 'chevron-up']}
          role="button"
          width="12px"
        />
      </Box>
    </HStack>
  </Button>
)

export default function LendTable({
  isDataLoading,
  selectedPurchaseToken,
  lendData,
  selectedChainId,
  isTriggerHoverEffect,
  setActiveLendItem,
}: {
  isDataLoading?: boolean
  selectedPurchaseToken: string
  lendData: ListingsData[]
  selectedChainId: string
  isTriggerHoverEffect?: boolean
  setActiveLendItem: (item: ListingsData | undefined) => void
}) {
  const [activeSort, setActiveSort] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [activeLendId, setActiveLendId] = useState<string | undefined>(
    undefined
  )
  const [filteredAndSortedData, setFilteredAndSortedData] = useState(lendData)
  const [sortConfig, setSortConfig] = useState<{
    key: keyof ListingsData
    direction: string
  }>({
    key: 'redemptionTimestamp',
    direction: 'ASC',
  })

  useEffect(() => {
    // Step 1: Filter data based on selectedChainId
    let updatedData: ListingsData[] = []

    if (selectedChainId === '') {
      updatedData = lendData
    } else {
      updatedData = lendData.filter(
        (lend: ListingsData) => lend.chainId === selectedChainId
      )
    }

    // Step 2: Sort data based on current sortConfig
    if (sortConfig.key) {
      const direction = sortConfig.direction === 'ASC' ? 1 : -1
      updatedData = [...updatedData].sort((a, b) => {
        let valueA: any = a[sortConfig.key]
        let valueB: any = b[sortConfig.key]

        if (valueA instanceof FixedNumber) {
          valueA = parseFloat(valueA.toString())
        }
        if (valueB instanceof FixedNumber) {
          valueB = parseFloat(valueB.toString())
        }

        if (typeof valueA === 'string') {
          valueA = parseFloat(valueA.replace(/,/g, ''))
        }
        if (typeof valueB === 'string') {
          valueB = parseFloat(valueB.replace(/,/g, ''))
        }

        if (valueA === undefined && valueB === undefined) return 0
        if (valueA === undefined) return direction
        if (valueB === undefined) return -direction

        return valueA < valueB ? -direction : valueA > valueB ? direction : 0
      })
    }

    // Step 3: Update state if necessary to prevent redundant re-renders
    if (JSON.stringify(updatedData) !== JSON.stringify(filteredAndSortedData)) {
      setFilteredAndSortedData(updatedData)
    }
  }, [filteredAndSortedData, lendData, selectedChainId, sortConfig])

  const handleSort = (key: keyof ListingsData) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === 'ASC' ? 'DESC' : 'ASC'
    setSortConfig({ key, direction })
    setActiveSort(activeSort === key ? '' : key)
  }

  useEffect(() => {
    const item = lendData?.find(
      (item: ListingsData) => item.termRepoToken === activeLendId
    )
    setActiveLendItem(item)
    if (!item) {
      setActiveLendId(undefined)
    }
  }, [activeLendId, lendData, setActiveLendItem])

  return (
    <>
      <Flex direction="column" cursor="pointer">
        {/* Table Header */}
        <Flex pl={3} pr={0} pb={2}>
          <Flex
            gap={{
              base: 7,
              '1xl': 12,
            }}
          >
            <Box h="100%" w="130px" pr={2}>
              {sortButtons
                .filter((button) => button.id === 'lendApy')
                .map((button) => (
                  <ColumnSortButton
                    key={button.id}
                    label={button.label}
                    isActive={button.id === activeSort}
                    onClick={() => handleSort(button.id)}
                  />
                ))}
            </Box>
            <Grid
              gridTemplateColumns={{
                base: '60px 90px 60px',
              }}
              gap={{
                base: '38px',
                '1xl': 16,
                '3xl': 36,
              }}
            >
              {sortButtons
                .filter((button) => button.id !== 'lendApy')
                .map((button) => (
                  <ColumnSortButton
                    key={button.id}
                    label={button.label}
                    isActive={button.id === activeSort}
                    onClick={() => handleSort(button.id)}
                  />
                ))}
            </Grid>
          </Flex>

          <Flex ml="auto">
            <HStack
              spacing={1}
              onClick={() => {
                if (!isDataLoading) {
                  setShowDetails((prev) => !prev)
                }
              }}
            >
              {!isDataLoading && (
                <>
                  <Button
                    variant="body-sm/medium"
                    color="blue.5"
                    p={0}
                    height="fit-content"
                  >
                    {showDetails ? 'Close all' : 'Open all'}
                  </Button>
                  <Box
                    marginInlineStart={0}
                    color="blue.5"
                    as="span"
                    transform={showDetails ? 'rotate(0deg)' : 'rotate(180deg)'}
                    transition="transform 0.2s"
                    height="21px"
                    display="flex"
                    alignItems="center"
                  >
                    <FontAwesomeIcon
                      icon={['far', 'chevron-up']}
                      role="button"
                      width="12px"
                      height="6px"
                    />
                  </Box>
                </>
              )}
            </HStack>
          </Flex>
        </Flex>
        {/* Table Body */}
        <Flex
          direction="column"
          gap={5}
          key={`${showDetails}`}
          pt={
            filteredAndSortedData && filteredAndSortedData[0]?.externalProjects
              ? 3
              : 0
          }
        >
          {isDataLoading ? (
            Array.from({ length: 5 }).map((_, idx) => (
              <RepoCardLoading key={idx} />
            ))
          ) : filteredAndSortedData.length > 0 ? (
            filteredAndSortedData.map((lend: ListingsData) => (
              <RepoCard
                key={`${lend.chainId}-${lend.termRepoToken}`}
                lend={lend}
                isTriggerHoverEffect={isTriggerHoverEffect}
                showDetails={showDetails}
                activeLendId={activeLendId}
                setActiveLendId={setActiveLendId}
              />
            ))
          ) : (
            <Flex pt={10}>
              <LendTableEmpty purchaseToken={selectedPurchaseToken} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  )
}
