import { useState, useEffect, useMemo } from 'react'
import { useJsonRestCalls } from '../../hooks/helpers/rest'
import { Address, SuggestedRates, SuggestedRatesByPlatform } from '../model'
import dayjs from 'dayjs'
import { BigNumber, FixedNumber } from 'ethers'
import { useChainConfig, useConfig } from '../../providers/config'
import {
  DocumentType,
  getQueryDocument,
  getQueryVariables,
} from '../../managers/subgraphManager'
import { fixedToBigNumber } from '../../helpers/conversions'
import { useGraphQuery } from './helper-hooks'
import {
  LastAuctionClearingPricesQuery,
  LastAuctionClearingPricesQueryVariables,
} from '../../gql/graphql'
import { multiply } from '../../helpers/math'

export const RATE_MAPPING_URL: string =
  'https://term-finance-third-party-rate-mappings.s3.us-west-1.amazonaws.com/mainnet/thirdPartyRateMapping.json'

export const AAVE_API_URL: string =
  'https://aave-api-v2.aave.com/data/rates-history'

export const MORPHO_API_URL: string = 'https://blue-api.morpho.org/graphql'

type MorphoQueries =
  | 'getMarketSupplyApyTimeseries'
  | 'getMarketBorrowApyTimeseries'
  | 'getMarket'

type MorphoQueryVariables = {
  uniqueKey: string
  chainId: number
  options?: {
    startTimestamp: number
    endTimestamp: number
    interval: string
  }
}

type AaveRatesHistory = {
  liquidityRate_avg: number
  variableBorrowRate_avg: number
  utilizationRate_avg: number
  stableBorrowRate_avg: number
  x: {
    year: number
    month: number
    date: number
    hours: number
  }
}

type MorphoApyHistory = {
  x: number
  y: number
}

type MorphoRatesHistory = {
  data: {
    marketByUniqueKey: {
      historicalState: {
        [key: string]: MorphoApyHistory[]
      }
    }
  }
}

type MorphoMarketInfo = {
  data: {
    marketByUniqueKey: {
      dailyApys: {
        borrowApy: number
        supplyApy: number
      }
      weeklyApys: {
        borrowApy: number
        supplyApy: number
      }
      monthlyApys: {
        borrowApy: number
        supplyApy: number
      }
    }
  }
}

type MorphoUrlMapping = {
  supply: string
  borrow: string
  market: string
}

type RateMapping = {
  chainId: string
  purchaseToken: string
  collateralToken: string
  collateralRatio: number
  marketId: string
  platform: string
  networkName: string
}

/**
 * Custom hook that fetches and aggregates third-party rates for a given chain, purchase token, collateral tokens, and collateral ratio.
 *
 * TODO: return common timeseries data
 *
 * @param chainId - The chain ID.
 * @param purchaseToken - The purchase token.
 * @param collateralToken - The collateral token. (TODO: This should be an array of collateral tokens)
 * @param collateralRatio - The collateral ratio.
 * @returns The aggregated rates by platform (a3 and m1) or undefined if the rates are not available.
 */
export function useSuggestedRates(
  chainId: string | undefined,
  purchaseToken: string | undefined,
  collateralToken: string | undefined,
  maintenanceMarginRatioFN: FixedNumber | undefined
): SuggestedRatesByPlatform | undefined {
  const config = useConfig()
  const headers = useMemo(() => {
    const h = new Headers()
    h.set('Content-Type', 'application/json')
    return h
  }, [])

  // Fetch third party markets to compare rates
  const {
    data: rateMappingInfo,
    // error,
    // isLoading,
  } = useJsonRestCalls<{ '#call': RequestInfo }, RateMapping[]>({
    '#call': {
      url: RATE_MAPPING_URL,
      method: 'GET',
    } as RequestInfo,
  })

  const [a3url, setA3url] = useState<string | undefined>()
  const [m1urls, setM1urls] = useState<MorphoUrlMapping | undefined>()
  const [ratesByPlatform, setRatesByPlatform] = useState<
    SuggestedRatesByPlatform | undefined
  >()

  const [a3MarketUrl, setA3MarketUrl] = useState<string | undefined>()
  const [m1MarketUrl, setM1MarketUrl] = useState<string | undefined>()

  useEffect(() => {
    if (
      chainId &&
      purchaseToken &&
      collateralToken &&
      maintenanceMarginRatioFN &&
      rateMappingInfo
    ) {
      const matchingRateMappings = findMatchingRateEntry(
        rateMappingInfo,
        chainId,
        purchaseToken,
        collateralToken,
        maintenanceMarginRatioFN.toUnsafeFloat() * 100
      )
      matchingRateMappings?.forEach((entry) => {
        if (entry.platform === 'a3') {
          setA3url(
            AAVE_API_URL +
              `?reserveId=${entry.marketId}&from=${dayjs().subtract(1, 'month').unix()}&resolutionInHours=24`
          )
          setA3MarketUrl(
            `https://app.aave.com/reserve-overview/?underlyingAsset=${parseAaveReserveAddress(entry.marketId)}&marketName=${entry.networkName}`
          )
        } else if (entry.platform === 'm1') {
          setM1urls({
            market: constructMorphoQuery(
              'getMarket',
              entry.marketId,
              chainId,
              'e46b981f0c67f5074d3ee939834f3285d2d2211042b101b85b64b83e200c44eb'
            ),
            supply: constructMorphoQuery(
              'getMarketSupplyApyTimeseries',
              entry.marketId,
              chainId,
              '31a4b297ba9c3cb6e9a636bbca8a4da4fd84ebf4a2019b1cfdf056b5c9f9b695'
            ),
            borrow: constructMorphoQuery(
              'getMarketBorrowApyTimeseries',
              entry.marketId,
              chainId,
              'd877e365c1f429e2343892da5dc92ead1369c3eb6918ad7ee88ede4bb613f7e1'
            ),
          })
          setM1MarketUrl(
            `https://app.morpho.org/market?id=${entry.marketId}&network=${entry.networkName}`
          )
        }
      })
    }
  }, [
    chainId,
    maintenanceMarginRatioFN,
    collateralToken,
    purchaseToken,
    rateMappingInfo,
  ])

  const {
    data: a3Rates,
    // error,
    // isLoading,
  } = useJsonRestCalls<{ '#call': RequestInfo }, AaveRatesHistory[]>({
    '#call': {
      url: a3url,
      method: 'GET',
      headers,
    } as RequestInfo,
  })

  const {
    data: m1MarketInfo,
    // error,
    // isLoading,
  } = useJsonRestCalls<{ '#call': RequestInfo }, MorphoMarketInfo>({
    '#call': {
      url: m1urls?.market,
      method: 'GET',
      headers,
    } as RequestInfo,
  })

  const {
    data: m1SupplyRates,
    // error,
    // isLoading,
  } = useJsonRestCalls<{ '#call': RequestInfo }, MorphoRatesHistory>({
    '#call': {
      url: m1urls?.supply,
      method: 'GET',
      headers,
    } as RequestInfo,
  })

  const {
    data: m1BorrowRates,
    // error,
    // isLoading,
  } = useJsonRestCalls<{ '#call': RequestInfo }, MorphoRatesHistory>({
    '#call': {
      url: m1urls?.borrow,
      method: 'GET',
      headers,
    } as RequestInfo,
  })

  const termData = useTermLastAuctionPrices(
    chainId,
    purchaseToken,
    collateralToken,
    maintenanceMarginRatioFN
  )

  useEffect(() => {
    if (termData?.lastMatchingAuction?.auctionClearingPrice) {
      setRatesByPlatform((ratesByPlatform) => {
        return {
          ...ratesByPlatform,
          term: {
            lastAuctionRate: convertToPercentage(
              termData.lastMatchingAuction!.auctionClearingPrice
            ),
            url: `https://${config.resultsBucket}.s3.amazonaws.com/mainnet/${termData.lastMatchingAuction?.id}.pdf`,
          },
        }
      })
    }

    if (a3Rates) {
      setRatesByPlatform((ratesByPlatform) => {
        return {
          ...ratesByPlatform,
          a3: {
            ...aggregateA3Rates(a3Rates),
            url: a3MarketUrl,
          },
        }
      })
    }

    // if (m1MarketInfo?.data && m1SupplyRates?.data && m1BorrowRates?.data) {
    if (m1MarketInfo?.data) {
      setRatesByPlatform((ratesByPlatform) => {
        return {
          ...ratesByPlatform,
          // m1: aggregateM1Rates(m1MarketInfo, m1BorrowRates?.data?.marketByUniqueKey?.historicalState?.borrowApy, m1SupplyRates?.data?.marketByUniqueKey?.historicalState?.supplyApy),
          m1: {
            ...aggregateM1Rates(m1MarketInfo),
            url: m1MarketUrl,
          },
        }
      })
    }
  }, [
    a3Rates,
    m1MarketInfo,
    m1SupplyRates,
    m1BorrowRates,
    a3MarketUrl,
    m1MarketUrl,
    termData,
    config.resultsBucket,
  ])
  return ratesByPlatform
}

/**
 * Finds the matching rate entry based on the provided rate mappings, chain ID, purchase token, collateral token, and collateral ratio.
 *
 * @param rateMappings - The rate mappings.
 * @param chainId - The chain ID.
 * @param purchaseToken - The purchase token.
 * @param collateralToken - The collateral token.
 * @param collateralRatio - The collateral ratio.
 * @returns An array of matching rate entries or undefined if no match is found.
 */
const findMatchingRateEntry = (
  rateMappings: RateMapping[],
  chainId: string,
  purchaseToken: Address,
  collateralToken: Address,
  collateralRatio: number
) => {
  console.log('searching for matching third party rates...')
  return (
    rateMappings.filter(
      (entry) =>
        entry.chainId.toString() === chainId.toString() &&
        entry.purchaseToken.toLowerCase() === purchaseToken.toLowerCase() &&
        entry.collateralToken.toLowerCase() === collateralToken.toLowerCase() &&
        Math.abs(entry.collateralRatio - collateralRatio) <= 2
    ) || undefined
  )
}

/**
 * Constructs the Morpho API query URL based on the provided operation name, unique key, chain ID, and hash.
 *
 * @param operationName - The operation name.
 * @param uniqueKey - The unique key.
 * @param chainId - The chain ID.
 * @param hash - The hash.
 * @returns The constructed Morpho API query URL.
 */
const constructMorphoQuery = (
  operationName: MorphoQueries,
  uniqueKey: string,
  chainId: string,
  hash: string
): string => {
  let variables: MorphoQueryVariables = {
    uniqueKey,
    chainId: parseInt(chainId, 10),
  }

  if (
    operationName === 'getMarketBorrowApyTimeseries' ||
    operationName === 'getMarketSupplyApyTimeseries'
  ) {
    variables = {
      ...variables,
      options: {
        startTimestamp: dayjs().subtract(1, 'month').unix(),
        endTimestamp: dayjs().unix(),
        interval: 'DAY',
      },
    }
  }

  return (
    MORPHO_API_URL +
    `?operationName=${operationName}&variables=${encodeURIComponent(JSON.stringify(variables))}&extensions=${encodeURIComponent(
      JSON.stringify({
        persistedQuery: {
          version: 1,
          sha256Hash: hash,
        },
      })
    )}`
  )
}

/**
 * Aggregates the Aave V3 rates by calculating the latest rate, average rate for the last 7 days, and average rate for the entire array.
 *
 * @param data - The Aave rates history data.
 * @returns The aggregated Aave V3 rates.
 */
const aggregateA3Rates = (data: AaveRatesHistory[]): SuggestedRates => {
  // Get the latest entry
  const latestEntry = data[data.length - 1]
  const latestLiquidityRate = latestEntry.liquidityRate_avg
  const latestVariableBorrowRate = latestEntry.variableBorrowRate_avg

  // Calculate the average for the last 7 days
  const last7DaysData = data.slice(-7)
  const avgLast7DaysLiquidityRate =
    last7DaysData.reduce((sum, entry) => sum + entry.liquidityRate_avg, 0) /
    last7DaysData.length
  const avgLast7DaysVariableBorrowRate =
    last7DaysData.reduce(
      (sum, entry) => sum + entry.variableBorrowRate_avg,
      0
    ) / last7DaysData.length

  // Calculate the average for the entire array
  const avgAllLiquidityRate =
    data.reduce((sum, entry) => sum + entry.liquidityRate_avg, 0) / data.length
  const avgAllVariableBorrowRate =
    data.reduce((sum, entry) => sum + entry.variableBorrowRate_avg, 0) /
    data.length

  return {
    supplyRate: convertToPercentage(latestLiquidityRate),
    borrowRate: convertToPercentage(latestVariableBorrowRate),
    averageRate: convertToPercentage(
      (latestLiquidityRate + latestVariableBorrowRate) / 2
    ),
    supplyRate7d: convertToPercentage(avgLast7DaysLiquidityRate),
    borrowRate7d: convertToPercentage(avgLast7DaysVariableBorrowRate),
    averageRate7d: convertToPercentage(
      (avgLast7DaysLiquidityRate + avgLast7DaysVariableBorrowRate) / 2
    ),
    supplyRate30d: convertToPercentage(avgAllLiquidityRate),
    borrowRate30d: convertToPercentage(avgAllVariableBorrowRate),
    averageRate30d: convertToPercentage(
      (avgAllLiquidityRate + avgAllVariableBorrowRate) / 2
    ),
  }
}

/**
 * Aggregates the Morpho rates by extracting the rates from the Morpho market info and historical state data.
 *
 * Note - this is just using the average values in the market API, we are not using the time series data yet
 *
 * @param m1MarketInfo - The Morpho market info.
 * @param m1BorrowRates - The Morpho borrow rates.
 * @param m1SupplyRates - The Morpho supply rates.
 * @returns The aggregated Morpho rates.
 */
// const aggregateM1Rates = (m1MarketInfo: MorphoMarketInfo, m1BorrowRates: MorphoApyHistory[], m1SupplyRates: MorphoApyHistory[]): Rates => {
const aggregateM1Rates = (m1MarketInfo: MorphoMarketInfo): SuggestedRates => {
  return {
    supplyRate: convertToPercentage(
      m1MarketInfo.data.marketByUniqueKey.dailyApys.supplyApy
    ),
    borrowRate: convertToPercentage(
      m1MarketInfo.data.marketByUniqueKey.dailyApys.borrowApy
    ),
    averageRate: convertToPercentage(
      (m1MarketInfo.data.marketByUniqueKey.dailyApys.supplyApy +
        m1MarketInfo.data.marketByUniqueKey.dailyApys.borrowApy) /
        2
    ),
    supplyRate7d: convertToPercentage(
      m1MarketInfo.data.marketByUniqueKey.weeklyApys.supplyApy
    ),
    borrowRate7d: convertToPercentage(
      m1MarketInfo.data.marketByUniqueKey.weeklyApys.borrowApy
    ),
    averageRate30d: convertToPercentage(
      (m1MarketInfo.data.marketByUniqueKey.monthlyApys.supplyApy +
        m1MarketInfo.data.marketByUniqueKey.monthlyApys.borrowApy) /
        2
    ),
    supplyRate30d: convertToPercentage(
      m1MarketInfo.data.marketByUniqueKey.monthlyApys.supplyApy
    ),
    borrowRate30d: convertToPercentage(
      m1MarketInfo.data.marketByUniqueKey.monthlyApys.borrowApy
    ),
    averageRate7d: convertToPercentage(
      (m1MarketInfo.data.marketByUniqueKey.weeklyApys.supplyApy +
        m1MarketInfo.data.marketByUniqueKey.weeklyApys.borrowApy) /
        2
    ),
  }
}

const convertToPercentage = (value: number | undefined): string => {
  if (!value) {
    return '-'
  } else {
    return (value * 100).toFixed(3)
  }
}

const parseAaveReserveAddress = (marketId: string): string => {
  const firstOxIndex = marketId.indexOf('0x')
  if (firstOxIndex === -1) return marketId

  const secondOxIndex = marketId.indexOf('0x', firstOxIndex + 1)
  if (secondOxIndex === -1) return marketId

  return marketId.substring(0, secondOxIndex)
}

/**
 * Fetch auctions from Term subgraph and filter by maintenance margin ratio
 * @param chainId - The chain ID.
 * @param purchaseToken - The purchase token.
 * @param collateralToken - The collateral token.
 * @param maintenanceMarginRatioFN - The maintenance margin ratio.
 * @returns
 */
const useTermLastAuctionPrices = (
  chainId?: string,
  purchaseToken?: string,
  collateralToken?: string,
  maintenanceMarginRatioFN?: FixedNumber
) => {
  const chainConfig = useChainConfig(chainId)

  const [query, setQuery] = useState<any>({})

  useEffect(() => {
    if (
      !chainConfig ||
      !purchaseToken ||
      !collateralToken ||
      !maintenanceMarginRatioFN
    )
      return undefined
    const subgraphVersion = chainConfig.getSubgraphVersion()
    const queryDoc = getQueryDocument(
      subgraphVersion,
      DocumentType.COMPONENT_LAST_AUCTION_PRICE
    )
    const queryVariables = getQueryVariables({
      subgraphVersion,
      docType: DocumentType.COMPONENT_LAST_AUCTION_PRICE,
      variables: {
        purchaseToken: purchaseToken?.toLowerCase(),
        collateralToken: collateralToken?.toLowerCase(),
        currentTimestamp: dayjs().unix(),
        thirtyDaysAgoTimestamp: dayjs().subtract(30, 'days').unix(),
      },
    })
    const query = {
      chainId: chainConfig.chainId,
      url: chainConfig.subgraphUrl,
      query: queryDoc,
      variables: queryVariables,
    }
    setQuery(query)
  }, [chainConfig, purchaseToken, collateralToken, maintenanceMarginRatioFN])

  const {
    results: termData,
    // fetching,
    // error,
    // refresh: readFromSubgraph,
  } = useGraphQuery<
    LastAuctionClearingPricesQuery,
    LastAuctionClearingPricesQueryVariables
  >(query)

  const computeMatchingAuctions = (
    termData: LastAuctionClearingPricesQuery,
    maintenanceMarginRatioFN?: FixedNumber
  ) => {
    if (!termData || !maintenanceMarginRatioFN) return undefined

    const maintenanceRatioMin = fixedToBigNumber(
      multiply(maintenanceMarginRatioFN, FixedNumber.fromString('0.95'))
    )
    const maintenanceRatioMax = fixedToBigNumber(
      multiply(maintenanceMarginRatioFN, FixedNumber.fromString('1.05'))
    )

    return termData.termAuctions
      .filter((auction) => {
        return auction?.term?.collateralRatios?.some((ratio) => {
          const maintenanceRatio = BigNumber.from(ratio.maintenanceRatio)
          return (
            maintenanceRatio.gte(maintenanceRatioMin) &&
            maintenanceRatio.lte(maintenanceRatioMax)
          )
        })
      })
      .map((auction) => {
        let auctionClearingPrice = auction.auctionClearingPrice
        if (!auctionClearingPrice) {
          console.debug('Auction clearing price is missing for %o', auction.id)
          auctionClearingPrice = 0
        }
        return {
          id: auction.id,
          auctionClearingPrice: FixedNumber.fromValue(
            auctionClearingPrice,
            18
          ).toUnsafeFloat(),
        }
      })
  }

  const matchingAuctionClearingPrices = useMemo(
    () => computeMatchingAuctions(termData, maintenanceMarginRatioFN),
    [termData, maintenanceMarginRatioFN]
  )

  const result = useMemo(
    () => ({
      lastMatchingAuction: matchingAuctionClearingPrices?.[0],
      matchingAuctionClearingPrices,
    }),
    [matchingAuctionClearingPrices]
  )

  return result
}
