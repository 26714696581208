import { useState, useEffect } from 'react'
import useSWRMutation from 'swr/mutation'
import { postValidateInviteCode } from '../../../helpers/swr'
import { InviteCodeError } from '../../../models/points'
import { components } from '../../../models/profile-api'

const statusMap: Record<number, InviteCodeError> = {
  404: InviteCodeError.NOT_FOUND,
  403: InviteCodeError.FORBIDDEN,
  400: InviteCodeError.USED,
}

const parseError = (error: any): InviteCodeError | undefined => {
  if (!error?.status) {
    return
  }
  return statusMap[error.status]
}

export function useValidateInviteCode(): {
  lastValidatedInviteCode: string | undefined
  inviteCode: components['schemas']['ValidatedInviteCode'] | undefined
  isLoading: boolean
  reset: () => void
  validateInviteCode: (code: string) => void
  validationError?: InviteCodeError
} {
  const [lastValidatedInviteCode, setLastValidatedInviteCode] = useState<
    string | undefined
  >()
  const {
    data: inviteCode,
    error,
    reset,
    isMutating,
    trigger: validateInviteCode,
  } = useSWRMutation('/invite-code/validate-invite', postValidateInviteCode, {
    throwOnError: false,
  })

  const validationError = parseError(error)

  useEffect(() => {
    if (inviteCode?.code) {
      setLastValidatedInviteCode(inviteCode?.code)
    }
  }, [inviteCode?.code])

  return {
    inviteCode,
    lastValidatedInviteCode,
    reset,
    isLoading: isMutating,
    validateInviteCode,
    validationError,
  }
}
