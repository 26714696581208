import { Box, Heading, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FixedNumber } from 'ethers'
import { Fragment, useMemo, useRef, useState } from 'react'
import Chip from '../../../../components/elements/Chip'
import ContractAddressModal from '../../../../components/elements/ContractAddressesModal'
import {
  Table,
  TableBody,
  TableFoot,
  TableHead,
  Td,
  Th,
  Tr,
} from '../../../../components/elements/Table'
import {
  Address,
  ContractAddressesGrouping,
  Currency,
  LiquidationForDisplay,
  TermBorrow,
  TermLoan,
  TermPeriod,
} from '../../../../data/model'
import { bigToFixedNumber } from '../../../../helpers/conversions'
import { add, multiply, subtract } from '../../../../helpers/math'
import { NETWORK_COLORS } from '../../../../helpers/networkColors'
import { SortOrder, SortOrderEnum } from '../../../../helpers/sort'
import {
  formatFixedToken,
  formatFixedUsd,
  getAuctionDisplayId,
  getAuctionTermString,
} from '../../../../helpers/utils'
import { useColumnWidths } from '../../../../hooks/portfolio'
import LoanLiquidationModal from '../LoanLiquidationModal'
import LoanStatus from '../LoanStatus'
import {
  LoanCurrenciesAndTerm,
  MaturityDate,
  NetworkHeader,
} from '../OpenPositionsSection'
import HistoryPopout from './HistoryPopout'
import { useChainConfig } from '../../../../providers/config'
import { Trans, t } from '@lingui/macro'

interface ColumnWidths {
  [key: string]: number
}

const COLUMN_WIDTHS: ColumnWidths = {
  loan: 90,
  status: 90,
  maturity: 90,
  repaidRedeemed: 90,
  debtRepaidCreditRedeemed: 90,
  initialBorrowInitialLoan: 90,
  interest: 90,
  emptyColumn: 125,
}

type HistoryBorrow = {
  chainId: string
  maturityTimestamp: number
  repaymentTimestamp: number
  lastAuctionEndTimestamp: number

  purchaseTokenSymbol: string
  collateralTokenSymbol: string
  termPeriod: string

  // Units: purchase token
  totalRepaid: FixedNumber
  totalLiquidateRepaid: FixedNumber
  lastLiquidationTx?: string
  loanPrincipal: FixedNumber
  loanInterest: FixedNumber

  liquidations?: LiquidationForDisplay[]
  contractAddresses: ContractAddressesGrouping
}

type HistoryBorrowTotals = {
  count: number
  // Units: usd
  totalRepaid: FixedNumber
  loanPrincipal: FixedNumber
  loanInterest: FixedNumber
}

type HistoryBorrows = {
  borrows: { [chainId: string]: HistoryBorrow[] }
  totals: HistoryBorrowTotals
  chainTotals: { [chainId: string]: HistoryBorrowTotals }
}

type HistoryLoan = {
  chainId: string
  maturityTimestamp: number
  auctionEndTimestamp: number
  lastRedemptionTimestamp: number

  purchaseTokenSymbol: string
  collateralTokenSymbol: string
  termPeriod: string

  // Units: purchase token
  totalRedeemed: FixedNumber
  loanPrincipal: FixedNumber
  loanInterest: FixedNumber
  contractAddresses: ContractAddressesGrouping
}

type HistoryLoanTotals = {
  count: number
  // Units: usd
  totalRedeemed: FixedNumber
  loanPrincipal: FixedNumber
  loanInterest: FixedNumber
}

type HistoryLoans = {
  loans: { [chainId: string]: HistoryLoan[] }
  totals: HistoryLoanTotals
  chainTotals: { [chainId: string]: HistoryLoanTotals }
}

function summarizeHistory(
  borrows: TermBorrow[],
  loans: TermLoan[],
  currencies: { [chainId: string]: { [address: string]: Currency } },
  prices: { [chainId: string]: { [address: string]: FixedNumber } },
  terms: { [chainId: string]: { [repoTokenAddress: Address]: TermPeriod } }
): [HistoryBorrows, HistoryLoans] {
  const historyBorrowsbyChain: { [chainId: string]: HistoryBorrow[] } = {}
  let totalTotalRepaidUsd = FixedNumber.fromString('0')
  let totalBorrowPrincipalUsd = FixedNumber.fromString('0')
  let totalBorrowInterestUsd = FixedNumber.fromString('0')

  const historyBorrowTotals = {} as HistoryBorrowTotals
  historyBorrowTotals.count = 0
  const historyBorrowChainTotals = {} as {
    [chainId: string]: HistoryBorrowTotals
  }

  for (const borrow of borrows) {
    const chainId = borrow.chainId

    if (!historyBorrowsbyChain[chainId]) {
      historyBorrowsbyChain[chainId] = []
    }

    const chainTotal = historyBorrowChainTotals[borrow.chainId] || {
      count: 0,
      totalRepaid: FixedNumber.fromString('0'),
      loanPrincipal: FixedNumber.fromString('0'),
      loanInterest: FixedNumber.fromString('0'),
    }

    const collateralCurrency = currencies[chainId][borrow.collateralCurrency]
    const purchaseCurrency = currencies[chainId][borrow.purchaseCurrency]
    const purchasePrice = prices[chainId][borrow.purchaseCurrency]

    // Units: purchase token
    const totalRepaid = borrow.amountCollected
    const loanPrincipal = borrow.purchasePrice
    const loanInterest = subtract(borrow.repurchasePrice, borrow.purchasePrice)
    const totalRepaidUsd = multiply(
      totalRepaid,
      purchasePrice,
      totalRepaid.format.decimals
    )
    const loanPrincipalUsd = multiply(
      loanPrincipal,
      purchasePrice,
      loanPrincipal.format.decimals
    )
    const loanInterestUsd = multiply(
      loanInterest,
      purchasePrice,
      loanInterest.format.decimals
    )
    // Get the term length
    const lastAuctionEndTimestamp =
      terms[chainId][borrow.termTokenCurrency].completedAuctions?.[0]
        ?.auctionEndTimestamp
    const termPeriod =
      borrow.maturityTimestamp && lastAuctionEndTimestamp
        ? getAuctionTermString(
            lastAuctionEndTimestamp,
            borrow.maturityTimestamp
          )
        : ''

    const contractAddresses = {
      auction:
        terms[chainId][borrow.termTokenCurrency].completedAuctions?.[0].address,
      auctionBidLocker:
        terms[chainId][borrow.termTokenCurrency].completedAuctions?.[0]
          .bidLockerAddress,
      auctionOfferLocker:
        terms[chainId][borrow.termTokenCurrency].completedAuctions?.[0]
          .offerLockerAddress,
      termRepoLocker: borrow.repoLocker,
      termRepoServicer: borrow.repoServicer,
      termCollateralManager: borrow.repoCollateralManager,
      termRolloverManager: borrow.repoRolloverManager,
      termRepoToken: borrow.termTokenCurrency,
    } as ContractAddressesGrouping

    historyBorrowsbyChain[chainId].push({
      chainId,
      maturityTimestamp: borrow.maturityTimestamp,
      lastAuctionEndTimestamp: lastAuctionEndTimestamp ?? 0,
      repaymentTimestamp: borrow.repaymentTimestamp,
      purchaseTokenSymbol: purchaseCurrency.symbol,
      collateralTokenSymbol: collateralCurrency.symbol,
      totalLiquidateRepaid: borrow.amountLiquidated,
      lastLiquidationTx: borrow.lastLiquidationTxHash,
      liquidations: borrow.liquidations,
      totalRepaid,
      loanPrincipal,
      loanInterest,
      termPeriod: termPeriod,
      contractAddresses,
    })

    totalTotalRepaidUsd = add(
      totalTotalRepaidUsd,
      totalRepaidUsd,
      totalRepaidUsd.format.decimals
    )
    totalBorrowPrincipalUsd = add(
      totalBorrowPrincipalUsd,
      loanPrincipalUsd,
      loanPrincipalUsd.format.decimals
    )

    totalBorrowInterestUsd = add(
      totalBorrowInterestUsd,
      loanInterestUsd,
      loanInterestUsd.format.decimals
    )

    chainTotal.loanInterest = add(
      chainTotal.loanInterest,
      loanInterestUsd,
      loanInterestUsd.format.decimals
    )

    chainTotal.loanPrincipal = add(
      chainTotal.loanPrincipal,
      loanPrincipalUsd,
      loanPrincipalUsd.format.decimals
    )

    chainTotal.totalRepaid = add(
      chainTotal.totalRepaid,
      totalRepaidUsd,
      totalRepaidUsd.format.decimals
    )

    historyBorrowTotals.count++
    chainTotal.count++
    historyBorrowChainTotals[borrow.chainId] = chainTotal
  }

  const historyLoansByChain: { [chainId: string]: HistoryLoan[] } = {}
  let totalTotalRedeemedUsd = FixedNumber.fromString('0')
  let totalLoanPrincipalUsd = FixedNumber.fromString('0')
  let totalLoanInterestUsd = FixedNumber.fromString('0')

  const historyLoanTotals = {} as HistoryLoanTotals
  historyLoanTotals.count = 0
  const historyLoanChainTotals = {} as { [chainId: string]: HistoryLoanTotals }

  for (const loan of loans) {
    const chainId = loan.chainId

    if (!historyLoansByChain[chainId]) {
      historyLoansByChain[chainId] = []
    }

    const chainTotal = historyLoanChainTotals[loan.chainId] || {
      count: 0,
      totalRedeemed: FixedNumber.fromString('0'),
      loanPrincipal: FixedNumber.fromString('0'),
      loanInterest: FixedNumber.fromString('0'),
    }

    const purchaseCurrency = currencies[chainId][loan.purchaseCurrency]
    const collateralCurrency = currencies[chainId][loan.collateralCurrency]
    const purchasePrice = prices[chainId][loan.purchaseCurrency]

    // Units: purchase token
    const totalRedeemed = bigToFixedNumber(
      loan.redemptionAmount,
      purchaseCurrency.decimals
    )
    const loanPrincipal = bigToFixedNumber(
      loan.purchasePrice,
      purchaseCurrency.decimals
    )

    const loanInterest = subtract(
      bigToFixedNumber(loan.repurchasePrice, purchaseCurrency.decimals),
      loanPrincipal
    )

    const totalRedeemedUsd = multiply(
      totalRedeemed,
      purchasePrice,
      totalRedeemed.format.decimals
    )
    const loanPrincipalUsd = multiply(
      loanPrincipal,
      purchasePrice,
      loanPrincipal.format.decimals
    )
    const loanInterestUsd = multiply(
      loanInterest,
      purchasePrice,
      loanInterest.format.decimals
    )

    const termPeriod =
      loan.auctionEndTimestamp && loan.maturityTimestamp
        ? getAuctionTermString(loan.auctionEndTimestamp, loan.maturityTimestamp)
        : ''

    const contractAddresses = {
      auction: loan.auction,
      auctionBidLocker: loan.auctionBidLocker,
      auctionOfferLocker: loan.auctionOfferLocker,
      termRepoLocker:
        terms[chainId][loan.termTokenCurrency].termRepoLockerAddress,
      termRepoServicer: loan.repoServicerAddress,
      termCollateralManager:
        terms[chainId][loan.termTokenCurrency].collateralManagerAddress,
      termRolloverManager:
        terms[chainId][loan.termTokenCurrency].rolloverManagerAddress,
      termRepoToken: loan.termTokenCurrency,
    } as ContractAddressesGrouping

    historyLoansByChain[chainId].push({
      chainId,
      maturityTimestamp: loan.maturityTimestamp,
      lastRedemptionTimestamp: loan.redemptionTimestamp,
      purchaseTokenSymbol: purchaseCurrency.symbol,
      collateralTokenSymbol: collateralCurrency.symbol,
      termPeriod: termPeriod,
      totalRedeemed,
      loanPrincipal,
      loanInterest,
      auctionEndTimestamp: loan.auctionEndTimestamp,
      contractAddresses,
    })

    totalTotalRedeemedUsd = add(
      totalTotalRedeemedUsd,
      totalRedeemedUsd,
      totalRedeemedUsd.format.decimals
    )
    totalLoanPrincipalUsd = add(
      totalLoanPrincipalUsd,
      loanPrincipalUsd,
      loanPrincipalUsd.format.decimals
    )
    totalLoanInterestUsd = add(
      totalLoanInterestUsd,
      loanInterestUsd,
      loanInterestUsd.format.decimals
    )

    chainTotal.loanInterest = add(
      chainTotal.loanInterest,
      loanInterestUsd,
      loanInterestUsd.format.decimals
    )

    chainTotal.loanPrincipal = add(
      chainTotal.loanPrincipal,
      loanPrincipalUsd,
      loanPrincipalUsd.format.decimals
    )

    chainTotal.totalRedeemed = add(
      chainTotal.totalRedeemed,
      totalRedeemedUsd,
      totalRedeemedUsd.format.decimals
    )

    historyLoanTotals.count++
    chainTotal.count++
    historyLoanChainTotals[loan.chainId] = chainTotal
  }

  historyBorrowTotals.loanInterest = totalBorrowInterestUsd
  historyBorrowTotals.loanPrincipal = totalBorrowPrincipalUsd
  historyBorrowTotals.totalRepaid = totalTotalRepaidUsd

  historyLoanTotals.loanInterest = totalLoanInterestUsd
  historyLoanTotals.loanPrincipal = totalLoanPrincipalUsd
  historyLoanTotals.totalRedeemed = totalTotalRedeemedUsd

  return [
    {
      borrows: historyBorrowsbyChain,
      totals: historyBorrowTotals,
      chainTotals: historyBorrowChainTotals,
    },
    {
      loans: historyLoansByChain,
      totals: historyLoanTotals,
      chainTotals: historyLoanChainTotals,
    },
  ]
}

export function HistoryBorrowHeader({
  chainId,
  count,
  isFirst,
  sortDirection,
  onToggleOrder,
  columnWidths,
}: {
  chainId?: string
  count: number
  isFirst: boolean
  sortDirection: SortOrder
  onToggleOrder: () => void
  columnWidths?: ColumnWidths
}) {
  const chainConfig = useChainConfig(chainId)

  return (
    <Tr
      bg={chainConfig ? NETWORK_COLORS[chainConfig.chainId] : 'gray.1'}
      noBorder
    >
      <Th
        data-column="loan"
        sub={
          count && count > 0 ? (
            <NetworkHeader
              chainId={chainConfig?.chainId}
              chainName={chainConfig?.chainName}
              numTenders={count}
              spacing="8px"
            />
          ) : (
            t`Loan`
          )
        }
        compact={!isFirst}
        width={columnWidths ? `${columnWidths.loan}px` : ''}
      />
      <Th
        data-column="status"
        sub={t`Status`}
        width={columnWidths ? `${columnWidths.status}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="maturity"
        sub={t`Maturity`}
        width={columnWidths ? `${columnWidths.maturity}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="repaidRedeemed"
        onClick={onToggleOrder}
        sub={
          <HStack
            alignItems="center"
            justifyContent="flex-start"
            cursor="pointer"
          >
            <Text as="span" variant="body-xs/medium" color="blue.9">
              <Trans>Repaid</Trans>
            </Text>
            <Box
              color="blue.9"
              as="span"
              transform={
                sortDirection === SortOrderEnum.ASC
                  ? 'rotate(0deg)'
                  : 'rotate(180deg)'
              }
              transition="transform 0.2s"
            >
              <FontAwesomeIcon
                icon={['far', 'chevron-up']}
                role="button"
                fontSize="xs"
              />
            </Box>
          </HStack>
        }
        width={columnWidths ? `${columnWidths.repaidRedeemed}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="debtRepaidCreditRedeemed"
        sub={t`Debt Repaid`}
        textAlign="right"
        width={columnWidths ? `${columnWidths.debtRepaidCreditRedeemed}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="initialBorrowInitialLoan"
        sub={t`Initial Borrow`}
        textAlign="right"
        width={columnWidths ? `${columnWidths.initialBorrowInitialLoan}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="interest"
        sub={t`Interest`}
        width={columnWidths ? `${columnWidths.interest}px` : ''}
        compact={!isFirst}
        textAlign="right"
      />
      {/* Popout Menu */}
      <Th
        data-column="emptyColumn"
        width={columnWidths ? `${columnWidths.emptyColumn}px` : ''}
      />
    </Tr>
  )
}

function HistoryBorrowFooter({
  isSubtotal,
  totalRepaidUsd,
  initialBorrowUsd,
  interestUsd,
}: {
  isSubtotal?: boolean
  totalRepaidUsd: FixedNumber
  initialBorrowUsd: FixedNumber
  interestUsd: FixedNumber
}) {
  const tdProps = isSubtotal
    ? {
        pl: '29px',
      }
    : {
        h: '56px',
      }

  const _variant = isSubtotal ? 'body-xs/semibold' : 'body-xs/bold'
  const _title = isSubtotal ? 'Subtotal' : 'Total'

  return (
    <Tr noBorder>
      <Th colSpan={4} py="14px" {...tdProps}>
        <HStack>
          <Text as="span" variant={_variant} color="blue.9">
            {_title}
          </Text>
          <Chip>
            <Trans>USD</Trans>
          </Chip>
        </HStack>
      </Th>
      {/* Debt Repaid */}
      <Th justifyChildren="flex-end" py="14px">
        <Text as="span" variant={_variant} color="blue.9">
          {formatFixedUsd(totalRepaidUsd)}
        </Text>
      </Th>
      {/* Initial Borrow */}
      <Th justifyChildren="flex-end" py="14px">
        <Text as="span" variant={_variant} color="blue.9">
          {formatFixedUsd(initialBorrowUsd)}
        </Text>
      </Th>
      {/* Interest */}
      <Th justifyChildren="flex-end" py="14px">
        <Text as="span" variant={_variant} color="blue.9">
          {formatFixedUsd(interestUsd)}
        </Text>
      </Th>
      {/* Popout Menu */}
      <Th justifyChildren="flex-end" py="14px" />
    </Tr>
  )
}

function HistoryBorrowLoanFooterHeader({
  type,
  count,
}: {
  type: 'borrow' | 'loan'
  count?: number
}) {
  return (
    <Tr bg="gray.1" noBorder>
      {/* Total USD */}
      <Th
        colSpan={4}
        sub={<NetworkHeader allNetworks={true} numTenders={count} />}
        compact
      />
      {/* Total Debt Repaid / Credit Redeemed USD */}
      <Th
        textAlign="right"
        sub={type === 'borrow' ? 'Debt Repaid' : 'Credit Redeemed'}
        w={COLUMN_WIDTHS.debtRepaidCreditRedeemed}
        compact
      />
      {/* Total Initial Borrow / Loan USD */}
      <Th
        textAlign="right"
        sub={type === 'borrow' ? t`Initial Borrow` : t`Initial Loan`}
        w={COLUMN_WIDTHS.initialBorrowInitialLoan}
        compact
      />
      {/* Total Interest USD */}
      <Th sub={t`Interest`} w={COLUMN_WIDTHS.interest} compact />
      {/* Popout Menu */}
      <Th />
    </Tr>
  )
}

function EmptyTableFooter({ children }: { children: string }) {
  return (
    <Tr bgColor="white" noBorder>
      <Th colSpan={8}>
        <Text as="span" variant="body-xs/medium" color="gray.6">
          {children}
        </Text>
      </Th>
    </Tr>
  )
}

function HistoryBorrowRow({ borrow }: { borrow: HistoryBorrow }) {
  const auctionLabel = getAuctionDisplayId({
    termSymbol: borrow.purchaseTokenSymbol,
    collateralSymbol: borrow.collateralTokenSymbol,
    maturityTimestamp: borrow.maturityTimestamp,
    auctionEndTimestamp: borrow.lastAuctionEndTimestamp,
  })
  const {
    isOpen: isLiquidationModalOpen,
    onOpen: onLiquidationModalOpen,
    onClose: onLiquidationModalClose,
  } = useDisclosure()
  const {
    isOpen: isContractAddressOpen,
    onOpen: onContractAddressOpen,
    onClose: onContractAddressClose,
  } = useDisclosure()
  const lastLiquidation = borrow.liquidations?.[0]
  const pastLiquidations = borrow.liquidations?.slice(1)
  const defaultLiquidation = borrow.liquidations?.[0]?.defaultLiquidation

  return (
    <>
      <Tr noBorder>
        {/* Loan */}
        <Td verticalAlign="bottom">
          <LoanCurrenciesAndTerm
            purchaseTokenSymbol={borrow.purchaseTokenSymbol}
            collateralTokenSymbol={borrow.collateralTokenSymbol}
            loanTerm={borrow.termPeriod}
          />
        </Td>
        {/* Status */}
        <Td verticalAlign="bottom" paddingBottom="22px">
          <LoanStatus
            defaultLiquidation={defaultLiquidation}
            status={
              borrow.totalLiquidateRepaid.isZero()
                ? 'matured'
                : 'liquidatedAndClosed'
            }
            repaymentTimestamp={borrow.repaymentTimestamp}
            onViewLiquidationDetailsClick={onLiquidationModalOpen}
          />
        </Td>
        {/* Maturity */}
        <Td verticalAlign="bottom">
          <MaturityDate maturityTimestamp={borrow.maturityTimestamp} isLarge />
        </Td>
        {/* Last Repaid */}
        <Td verticalAlign="bottom">
          <MaturityDate maturityTimestamp={borrow.repaymentTimestamp} isLarge />
        </Td>
        {/* Debt Repaid */}
        <Td
          textAlign="right"
          fontWeight="700"
          verticalAlign="bottom"
          paddingBottom="22px"
        >
          <Text as="span" variant="body-xs/bold" color="blue.9">
            {formatFixedToken(
              borrow.totalRepaid,
              borrow.purchaseTokenSymbol,
              true
            )}
          </Text>
        </Td>
        {/* Initial Borrow */}
        <Td textAlign="right" verticalAlign="bottom" paddingBottom="22px">
          <Text as="span" variant="body-xs/normal" color="blue.9">
            {formatFixedToken(
              borrow.loanPrincipal,
              borrow.purchaseTokenSymbol,
              true
            )}
          </Text>
        </Td>
        {/* Interest */}
        <Td verticalAlign="bottom" paddingBottom="22px" textAlign="right">
          <Text as="span" variant="body-xs/normal" color="blue.9">
            {formatFixedToken(
              borrow.loanInterest,
              borrow.purchaseTokenSymbol,
              true
            )}
          </Text>
        </Td>
        <Td large verticalAlign="top">
          <HStack right={0} position="absolute">
            <Spacer />
            <HistoryPopout onContractAddress={() => onContractAddressOpen()} />
          </HStack>
        </Td>
      </Tr>
      {lastLiquidation && (
        <LoanLiquidationModal
          chainId={borrow.chainId}
          currentLiquidation={lastLiquidation}
          pastLiquidations={pastLiquidations}
          auctionLabel={auctionLabel}
          isOpen={isLiquidationModalOpen}
          onClose={onLiquidationModalClose}
        />
      )}
      <ContractAddressModal
        isOpen={isContractAddressOpen}
        onClose={onContractAddressClose}
        chainId={borrow.chainId}
        auctionLabel={auctionLabel}
        auction={borrow.contractAddresses.auction}
        bidLocker={borrow.contractAddresses.auctionBidLocker}
        offerLocker={borrow.contractAddresses.auctionOfferLocker}
        repoLocker={borrow.contractAddresses.termRepoLocker}
        repoServicer={borrow.contractAddresses.termRepoServicer}
        collateralManager={borrow.contractAddresses.termCollateralManager}
        rolloverManager={borrow.contractAddresses.termRolloverManager}
        termRepoToken={borrow.contractAddresses.termRepoToken}
      />
    </>
  )
}

export function HistoryLoanHeader({
  chainId,
  count,
  isFirst,
  sortDirection,
  onToggleOrder,
  columnWidths,
}: {
  chainId?: string
  count: number
  isFirst: boolean
  sortDirection: SortOrder
  onToggleOrder: () => void
  columnWidths?: ColumnWidths
}) {
  const chainConfig = useChainConfig(chainId)

  return (
    <Tr
      bg={chainConfig ? NETWORK_COLORS[chainConfig.chainId] : 'gray.1'}
      noBorder
    >
      <Th
        data-column="loan"
        sub={
          count && count > 0 ? (
            <NetworkHeader
              chainId={chainConfig?.chainId}
              chainName={chainConfig?.chainName}
              numTenders={count}
              spacing="8px"
            />
          ) : (
            t`Loan`
          )
        }
        width={columnWidths ? `${columnWidths.loan}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="status"
        sub={t`Status`}
        width={columnWidths ? `${columnWidths.status}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="maturity"
        sub={t`Maturity`}
        width={columnWidths ? `${columnWidths.maturity}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="repaidRedeemed"
        onClick={onToggleOrder}
        sub={
          <HStack
            alignItems="center"
            justifyContent="flex-start"
            cursor="pointer"
          >
            <Text as="span" variant="body-xs/medium" color="blue.9">
              <Trans>Last Redeemed</Trans>
            </Text>
            <Box
              color="blue.9"
              as="span"
              transform={
                sortDirection === SortOrderEnum.ASC
                  ? 'rotate(0deg)'
                  : 'rotate(180deg)'
              }
              transition="transform 0.2s"
            >
              <FontAwesomeIcon
                icon={['far', 'chevron-up']}
                role="button"
                fontSize="xs"
              />
            </Box>
          </HStack>
        }
        width={columnWidths ? `${columnWidths.repaidRedeemed}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="debtRepaidCreditRedeemed"
        sub={t`Credit Redeemed`}
        textAlign="right"
        width={columnWidths ? `${columnWidths.debtRepaidCreditRedeemed}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="initialBorrowInitialLoan"
        sub={t`Initial Loan`}
        textAlign="right"
        width={columnWidths ? `${columnWidths.initialBorrowInitialLoan}px` : ''}
        compact={!isFirst}
      />
      <Th
        data-column="interest"
        sub={t`Interest`}
        width={columnWidths ? `${columnWidths.interest}px` : ''}
        compact={!isFirst}
        textAlign="right"
      />
      {/* Popout Menu */}
      <Th
        data-column="emptyColumn"
        width={columnWidths ? `${columnWidths.emptyColumn}px` : ''}
      />
    </Tr>
  )
}

function HistoryLoanFooter({
  totalRedeemedUsd,
  initialLoanUsd,
  interestUsd,
}: {
  totalRedeemedUsd: FixedNumber
  initialLoanUsd: FixedNumber
  interestUsd: FixedNumber
}) {
  return (
    <Tr bgColor="gray.1" noBorder>
      <Th colSpan={4} py="14px">
        <HStack>
          <Text as="span" variant="body-xs/bold" color="blue.9">
            <Trans>Total</Trans>
          </Text>
          <Chip>
            <Trans>USD</Trans>
          </Chip>
        </HStack>
      </Th>
      <Th justifyChildren="flex-end" py="14px">
        <Text as="span" variant="body-xs/bold" color="blue.9">
          {formatFixedUsd(totalRedeemedUsd)}
        </Text>
      </Th>
      <Th justifyChildren="flex-end" py="14px">
        <Text as="span" variant="body-xs/bold" color="blue.9">
          {formatFixedUsd(initialLoanUsd)}
        </Text>
      </Th>
      <Th justifyChildren="flex-end" py="14px">
        <Text as="span" variant="body-xs/bold" color="blue.9">
          {formatFixedUsd(interestUsd)}
        </Text>
      </Th>
      {/* Popout Menu */}
      <Th />
    </Tr>
  )
}

function HistoryLoanRow({ loan }: { loan: HistoryLoan }) {
  const {
    isOpen: isContractAddressOpen,
    onOpen: onContractAddressOpen,
    onClose: onContractAddressClose,
  } = useDisclosure()
  const auctionLabel = getAuctionDisplayId({
    termSymbol: loan.purchaseTokenSymbol,
    collateralSymbol: loan.collateralTokenSymbol,
    maturityTimestamp: loan.maturityTimestamp,
    auctionEndTimestamp: loan.auctionEndTimestamp,
  })
  return (
    <>
      <Tr noBorder>
        {/** Loan */}
        <Td verticalAlign="bottom">
          <LoanCurrenciesAndTerm
            purchaseTokenSymbol={loan.purchaseTokenSymbol}
            collateralTokenSymbol={loan.collateralTokenSymbol}
            loanTerm={loan.termPeriod}
          />
        </Td>
        {/** Status */}
        <Td verticalAlign="bottom" paddingBottom="22px">
          <LoanStatus status="matured" />
        </Td>
        {/** Maturity */}
        <Td verticalAlign="bottom">
          <MaturityDate maturityTimestamp={loan.maturityTimestamp} isLarge />
        </Td>
        {/** Last Redeemed */}
        <Td verticalAlign="bottom">
          <MaturityDate
            maturityTimestamp={loan.lastRedemptionTimestamp}
            isLarge
          />
        </Td>
        {/** Credit Redeemed */}
        <Td
          textAlign="right"
          fontWeight="700"
          verticalAlign="bottom"
          paddingBottom="22px"
        >
          <Text as="span" variant="body-xs/normal" color="blue.9">
            {formatFixedToken(
              loan.totalRedeemed,
              loan.purchaseTokenSymbol,
              true
            )}
          </Text>
        </Td>
        {/** Initial Loan */}
        <Td textAlign="right" verticalAlign="bottom" paddingBottom="22px">
          <Text as="span" variant="body-xs/normal" color="blue.9">
            {formatFixedToken(
              loan.loanPrincipal,
              loan.purchaseTokenSymbol,
              true
            )}
          </Text>
        </Td>
        {/** Interest */}
        <Td textAlign="right" verticalAlign="bottom" paddingBottom="22px">
          <Text as="span" variant="body-xs/normal" color="blue.9">
            {formatFixedToken(
              loan.loanInterest,
              loan.purchaseTokenSymbol,
              true
            )}
          </Text>
        </Td>
        <Td large verticalAlign="top">
          <HStack right={0} position="absolute">
            <Spacer />
            <HistoryPopout onContractAddress={() => onContractAddressOpen()} />
          </HStack>
        </Td>
      </Tr>
      <ContractAddressModal
        isOpen={isContractAddressOpen}
        onClose={onContractAddressClose}
        chainId={loan.chainId}
        auctionLabel={auctionLabel}
        auction={loan.contractAddresses.auction}
        bidLocker={loan.contractAddresses.auctionBidLocker}
        offerLocker={loan.contractAddresses.auctionOfferLocker}
        repoLocker={loan.contractAddresses.termRepoLocker}
        repoServicer={loan.contractAddresses.termRepoServicer}
        collateralManager={loan.contractAddresses.termCollateralManager}
        rolloverManager={loan.contractAddresses.termRolloverManager}
        termRepoToken={loan.contractAddresses.termRepoToken}
      />
    </>
  )
}

export default function HistorySection({
  loans,
  borrows,
  currencies,
  prices,
  terms,
}: {
  loans: TermLoan[]
  borrows: TermBorrow[]
  currencies: { [chainId: string]: { [address: string]: Currency } }
  prices: { [chainId: string]: { [address: string]: FixedNumber } }
  terms: { [chainId: string]: { [repoTokenAddress: Address]: TermPeriod } }
}) {
  const [historyBorrows, historyLoans] = useMemo(
    () => summarizeHistory(borrows, loans, currencies, prices, terms),
    [borrows, loans, currencies, prices, terms]
  )

  const [sortBorrowDirection, setSortBorrowDirection] =
    useState<SortOrder>('desc')
  const [sortLoanDirection, setSortLoanDirection] = useState<SortOrder>('desc')

  const toggleLoanSortDirection = () => {
    setSortLoanDirection(sortLoanDirection === 'asc' ? 'desc' : 'asc')
  }

  const toggleBorrowSortDirection = () => {
    setSortBorrowDirection(sortBorrowDirection === 'asc' ? 'desc' : 'asc')
  }

  const sortedBorrows: { [chainId: string]: HistoryBorrow[] } = useMemo(() => {
    const sorted: { [chainId: string]: HistoryBorrow[] } = {}
    Object.keys(historyBorrows.borrows).forEach((chainId) => {
      sorted[chainId] = [...historyBorrows.borrows[chainId]].sort((a, b) => {
        const dateA = a.repaymentTimestamp
        const dateB = b.repaymentTimestamp

        return sortBorrowDirection === 'desc' ? dateB - dateA : dateA - dateB
      })
    })
    return sorted
  }, [historyBorrows.borrows, sortBorrowDirection])

  const sortedLoans: { [chainId: string]: HistoryLoan[] } = useMemo(() => {
    const sorted: { [chainId: string]: HistoryLoan[] } = {}
    Object.keys(historyLoans.loans).forEach((chainId) => {
      sorted[chainId] = [...historyLoans.loans[chainId]].sort((a, b) => {
        const dateA = a.lastRedemptionTimestamp
        const dateB = b.lastRedemptionTimestamp

        return sortLoanDirection === 'desc' ? dateB - dateA : dateA - dateB
      })
    })
    return sorted
  }, [historyLoans.loans, sortLoanDirection])

  const borrowMultipleNetworks =
    sortedBorrows && Object.keys(sortedBorrows).length > 1
  const loanMultipleNetworks =
    sortedLoans && Object.keys(sortedLoans).length > 1

  // Refs to tables to calculate column widths
  const borrowsTableRef = useRef(null)
  const loansTableRef = useRef(null)

  const tableRefs = useMemo(
    () => [borrowsTableRef, loansTableRef],
    [borrowsTableRef, loansTableRef]
  )

  const excludedColumnsFromEmptySpaceCalculation = useMemo(
    () => new Set(['repaidRedeemed']),
    []
  )

  const columnWidths = useColumnWidths(
    tableRefs,
    COLUMN_WIDTHS,
    excludedColumnsFromEmptySpaceCalculation,
    true
  )

  return (
    <Box my={5} mr="20px !important">
      <Heading variant="heading/h3" color="blue.9" mb="8px">
        <Trans>Borrow</Trans>
      </Heading>
      <Table tableRef={borrowsTableRef} isFixedLayout noPadding>
        {historyBorrows.totals.count > 0 ? (
          <>
            {Object.entries(sortedBorrows).map(
              ([chainId, sortedBorrows], index) => (
                <Fragment key={chainId}>
                  <TableHead>
                    <HistoryBorrowHeader
                      chainId={chainId}
                      count={historyBorrows.chainTotals[chainId]?.count}
                      isFirst={index === 0}
                      sortDirection={sortBorrowDirection}
                      onToggleOrder={toggleBorrowSortDirection}
                      columnWidths={columnWidths}
                    />
                  </TableHead>
                  <TableBody>
                    {sortedBorrows.map((borrow, index) => (
                      <HistoryBorrowRow key={index} borrow={borrow} />
                    ))}
                    {borrowMultipleNetworks &&
                      historyBorrows.chainTotals[chainId] && (
                        <HistoryBorrowFooter
                          isSubtotal
                          totalRepaidUsd={
                            historyBorrows.chainTotals[chainId].totalRepaid
                          }
                          initialBorrowUsd={
                            historyBorrows.chainTotals[chainId].loanPrincipal
                          }
                          interestUsd={
                            historyBorrows.chainTotals[chainId].loanInterest
                          }
                        />
                      )}
                  </TableBody>
                </Fragment>
              )
            )}
            {borrowMultipleNetworks && (
              <HistoryBorrowLoanFooterHeader
                type="borrow"
                count={historyBorrows.totals.count}
              />
            )}
            <TableFoot noTopBorder>
              <HistoryBorrowFooter
                totalRepaidUsd={historyBorrows.totals.totalRepaid}
                initialBorrowUsd={historyBorrows.totals.loanPrincipal}
                interestUsd={historyBorrows.totals.loanInterest}
              />
            </TableFoot>
          </>
        ) : (
          <>
            <TableHead>
              <HistoryBorrowHeader
                count={0}
                isFirst={true}
                sortDirection={sortBorrowDirection}
                onToggleOrder={toggleBorrowSortDirection}
                columnWidths={columnWidths}
              />
            </TableHead>
            <TableFoot noTopBorder>
              <EmptyTableFooter>You have no borrow history.</EmptyTableFooter>
            </TableFoot>
          </>
        )}
      </Table>
      <Heading variant="heading/h3" color="blue.9" mb="8px" mt="40px">
        <Trans>Loan</Trans>
      </Heading>
      <Table tableRef={loansTableRef} isFixedLayout noPadding>
        {historyLoans.totals.count > 0 ? (
          <>
            {Object.entries(sortedLoans).map(
              ([chainId, sortedLoans], index) => (
                <Fragment key={chainId}>
                  <TableHead>
                    <HistoryLoanHeader
                      chainId={chainId}
                      count={historyLoans.chainTotals[chainId]?.count}
                      isFirst={index === 0}
                      sortDirection={sortLoanDirection}
                      onToggleOrder={toggleLoanSortDirection}
                      columnWidths={columnWidths}
                    />
                  </TableHead>
                  <TableBody>
                    {sortedLoans.map((loan, index) => (
                      <HistoryLoanRow key={index} loan={loan} />
                    ))}
                    {loanMultipleNetworks &&
                      historyLoans.chainTotals[chainId] && (
                        <HistoryLoanFooter
                          totalRedeemedUsd={
                            historyLoans.chainTotals[chainId].totalRedeemed
                          }
                          initialLoanUsd={
                            historyLoans.chainTotals[chainId].loanPrincipal
                          }
                          interestUsd={
                            historyLoans.chainTotals[chainId].loanInterest
                          }
                        />
                      )}
                  </TableBody>
                </Fragment>
              )
            )}
            {borrowMultipleNetworks && (
              <HistoryBorrowLoanFooterHeader
                type="loan"
                count={historyLoans.totals.count}
              />
            )}
            <TableFoot noTopBorder>
              <HistoryLoanFooter
                totalRedeemedUsd={historyLoans.totals.totalRedeemed}
                initialLoanUsd={historyLoans.totals.loanPrincipal}
                interestUsd={historyLoans.totals.loanInterest}
              />
            </TableFoot>
          </>
        ) : (
          <>
            <TableHead>
              <HistoryLoanHeader
                count={0}
                isFirst={true}
                sortDirection={sortLoanDirection}
                onToggleOrder={toggleLoanSortDirection}
                columnWidths={columnWidths}
              />
            </TableHead>
            <TableFoot noTopBorder>
              <EmptyTableFooter>{t`You have no lending history.`}</EmptyTableFooter>
            </TableFoot>
          </>
        )}
      </Table>
    </Box>
  )
}
