import { Button, ButtonProps, Spinner } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

export default function ElectRolloverButton({
  isSubmitting,
  fontSize = 'sm',
  size = 'sm',
  variant = 'secondary',
  w = 'full',
  ...props
}: { isSubmitting: boolean } & ButtonProps) {
  return (
    <Button fontSize={fontSize} size={size} variant={variant} w={w} {...props}>
      {isSubmitting && (
        <>
          <Spinner size="sm" mr="2" />{' '}
        </>
      )}
      <Trans>Elect rollover</Trans>
    </Button>
  )
}
