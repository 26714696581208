import { BigNumber, constants, FixedNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { bigToFixedNumber, fixedToBigNumber } from '../helpers/conversions'
import {
  DraftBorrowEdit,
  DraftBorrowTender,
  DraftLoanEdit,
  DraftLoanTender,
  ParsedBorrowTender,
  ParsedLoanTender,
  ParsedValue,
  ParsedValueError,
  SubmittedBorrowTender,
  SubmittedLoanTender,
  TenderId,
} from './model'
import { evaluate } from '../helpers/math'
import { getRequiredMargin } from '../pages/Auction/utils'

function invalid<T>(value: T, error?: ParsedValueError): ParsedValue<T> {
  return { isValid: false, value, error }
}

function valid<T>(
  value: T,
  isValid?: boolean,
  error?: ParsedValueError
): ParsedValue<T> {
  return { isValid: isValid ?? true, value, error }
}

const validateBigNumber = (
  amount: string | undefined,
  decimals: number,
  min: BigNumber,
  max: BigNumber
): ParsedValue<BigNumber> => {
  const zero = BigNumber.from('0')
  if (!amount || amount === '' || decimals === undefined || decimals === null) {
    return invalid(zero, 'empty')
  }
  let parsedAmount: BigNumber
  const amountDecimals = amount.split('.')[1]?.length || 0

  if (amountDecimals > 0 && amountDecimals > decimals) {
    console.error('too many decimals in input')
    return invalid(zero, 'too-many-decimals')
  }

  try {
    parsedAmount = parseUnits(amount?.replace(',', '') || '0', decimals)
  } catch (err) {
    parsedAmount = zero
    return invalid(parsedAmount, 'invalid')
  }
  if (parsedAmount.lt(min)) {
    return invalid(parsedAmount, 'too-small')
  }
  if (parsedAmount.gt(max)) {
    return invalid(parsedAmount, 'too-large')
  }
  return valid(parsedAmount)
}

const validateFixedNumber = (
  rate: string | undefined,
  min: FixedNumber,
  max: FixedNumber
): ParsedValue<FixedNumber> => {
  const zero = FixedNumber.fromString('0')
  if (!rate || rate === '') {
    return invalid(zero, 'empty')
  }
  let interestRate: FixedNumber
  try {
    interestRate = rate ? FixedNumber.fromString(rate.replace(',', '')) : zero
  } catch (err) {
    interestRate = zero
    return invalid(interestRate, 'invalid')
  }
  if (
    evaluate({
      nodeKind: 'sub',
      args: [
        {
          nodeKind: 'value',
          value: interestRate,
        },
        {
          nodeKind: 'value',
          value: min,
        },
      ],
    }).isNegative()
  ) {
    return invalid(interestRate, 'too-small')
  }
  if (
    evaluate({
      nodeKind: 'sub',
      args: [
        {
          nodeKind: 'value',
          value: max,
        },
        {
          nodeKind: 'value',
          value: interestRate,
        },
      ],
    }).isNegative()
  ) {
    return invalid(interestRate, 'too-large')
  }
  return valid(interestRate)
}

export function parseLoanTender(
  draft: DraftLoanTender,
  loanDecimals: number,
  minAmount: BigNumber,
  maxAmount: BigNumber,
  minPrice: FixedNumber,
  maxPrice: FixedNumber
): ParsedLoanTender {
  try {
    const maxTenderLimitBN = constants.MaxUint256
    const amount = validateBigNumber(
      draft.amount,
      loanDecimals,
      minAmount,
      maxAmount
    )

    if (amount.value.gt(maxTenderLimitBN || '0')) {
      amount.isValid = false
      amount.error = 'over-max'
    }
    const interestRate = validateFixedNumber(
      draft.interestRate,
      minPrice,
      maxPrice
    )

    const isValid = amount?.isValid && interestRate?.isValid
    return {
      ...draft,
      isValid,
      amount,
      interestRate,
    }
  } catch (err) {
    return {
      ...draft,
      isValid: false,
      amount: invalid(BigNumber.from(0)),
      interestRate: invalid(FixedNumber.fromString('0')),
    }
  }
}

export function parseBorrowTender(
  draft: DraftBorrowTender,
  loanDecimals: number,
  collateralDecimals: number,
  requiredCollateral: FixedNumber,
  minAmount: BigNumber,
  maxAmount: BigNumber,
  minPrice: FixedNumber,
  maxPrice: FixedNumber
): ParsedBorrowTender {
  try {
    const maxTenderLimitBN = constants.MaxUint256
    const amount = validateBigNumber(
      draft.amount,
      loanDecimals,
      minAmount,
      maxTenderLimitBN || BigNumber.from('0')
    )
    const collateral = validateBigNumber(
      draft.collateral,
      collateralDecimals,
      fixedToBigNumber(requiredCollateral),
      maxAmount
    )
    const interestRate = validateFixedNumber(
      draft.interestRate,
      minPrice,
      maxPrice
    )

    const isValid =
      amount?.isValid && interestRate?.isValid && collateral?.isValid
    return {
      ...draft,
      isValid,
      amount,
      interestRate,
      collateral,
    }
  } catch (err) {
    return {
      ...draft,
      isValid: false,
      amount: invalid(BigNumber.from('0')),
      interestRate: invalid(FixedNumber.fromString('0')),
      collateral: invalid(BigNumber.from('0')),
    }
  }
}

export function parseLoanEdit(
  draft: DraftLoanEdit,
  existing: SubmittedLoanTender,
  loanDecimals: number,
  minAmount: BigNumber,
  maxAmount: BigNumber,
  minPrice: FixedNumber,
  maxPrice: FixedNumber
): ParsedLoanTender {
  try {
    const amount = validateBigNumber(
      draft.amount,
      loanDecimals,
      minAmount,
      maxAmount
    )
    const interestRate = validateFixedNumber(
      draft.interestRate,
      minPrice,
      maxPrice
    )
    const isValid = amount?.isValid && interestRate?.isValid
    return {
      ...existing,
      ...draft,
      isValid,
      amount,
      interestRate,
    }
  } catch (err) {
    return {
      ...existing,
      ...draft,
      isValid: false,
      amount: invalid(BigNumber.from(0)),
      interestRate: invalid(FixedNumber.fromString('0')),
    }
  }
}

export function parseBorrowEdit(
  draft: DraftBorrowEdit,
  existing: SubmittedBorrowTender,
  loanDecimals: number,
  collateralDecimals: number,
  minAmount: BigNumber,
  maxAmount: BigNumber,
  minPrice: FixedNumber,
  maxPrice: FixedNumber
): ParsedBorrowTender {
  try {
    const amount = validateBigNumber(
      draft.amount,
      loanDecimals,
      minAmount,
      maxAmount
    )
    const collateral = validateBigNumber(
      draft.collateral,
      collateralDecimals,
      constants.Zero,
      constants.MaxUint256
    )
    const interestRate = validateFixedNumber(
      draft.interestRate,
      minPrice,
      maxPrice
    )
    const isValid =
      amount?.isValid && interestRate?.isValid && collateral?.isValid
    return {
      ...existing,
      ...draft,
      isValid,
      amount,
      interestRate,
      collateral,
    }
  } catch (err) {
    return {
      ...existing,
      ...draft,
      isValid: false,
      amount: invalid(BigNumber.from(0)),
      interestRate: invalid(FixedNumber.fromString('0')),
      collateral: invalid(BigNumber.from(0)),
    }
  }
}

export type TenderDiff = {
  label: string
  oldValue: FixedNumber
  newValue: FixedNumber
  symbol: string
}

export type TenderEdit = {
  id: TenderId
  transaction: string
  differences: TenderDiff[]
  errors?: TenderEditErrors
}

export type TenderDelete = {
  id: TenderId
  transaction: string
  symbol: string
  oldAmount: FixedNumber
}

export type TenderEditErrors = LoanEditErrors | BorrowEditErrors

export type LoanEditErrors = {
  amountError: string | undefined
  globalAmountError?: string
  interestRateError: string | undefined
}

export type BorrowEditErrors = {
  amountError: string | undefined
  interestRateError: string | undefined
  collateralError: string | undefined
}

export enum EditErrors {
  INSUFFICIENT_FUNDS = `Insufficient funds in wallet;`,
  INSUFFICIENT_COLLATERAL = 'Insufficient collateral deposited',
  INVALID_COLLATERAL = 'Invalid collateral',
  INVALID_AMOUNT = 'Invalid amount',
  INVALID_INTEREST_RATE = 'Invalid interest rate',
  MINIMUM_SUPPLY_AMOUNT = 'Minimum supply amount',
  MINIMUM_BORROW_AMOUNT = 'Minimum borrow amount',
}

export function diffLoanEdit(
  draft: DraftLoanEdit,
  existing: SubmittedLoanTender,
  loanDecimals: number,
  minAmount: BigNumber,
  maxAmount: BigNumber,
  minPrice: FixedNumber,
  maxPrice: FixedNumber,
  purchaseSymbol: string
): {
  differences: TenderDiff[]
  errors: LoanEditErrors
} {
  const amount = validateBigNumber(
    draft.amount,
    loanDecimals,
    minAmount,
    maxAmount.add(existing?.amount?.shiftedValue ?? constants.Zero)
  )

  const interestRate = validateFixedNumber(
    draft.interestRate,
    minPrice,
    maxPrice
  )

  const result = [] as TenderDiff[]
  const errors: LoanEditErrors = {
    interestRateError: undefined,
    amountError: undefined,
  }

  if (!amount.isValid) {
    errors.amountError = amount?.error
    if (amount?.error === 'too-large') {
      errors.amountError = `${EditErrors.INSUFFICIENT_FUNDS} ${formatUnits(
        maxAmount,
        loanDecimals
      )} available`
    } else if (amount?.error === 'too-small') {
      errors.amountError = `${
        EditErrors.MINIMUM_SUPPLY_AMOUNT
      } is ${formatUnits(minAmount, loanDecimals)} ${purchaseSymbol}`
    }
    // can put more error messages here
    else {
      errors.amountError = EditErrors.INVALID_AMOUNT
    }
  }

  if (
    amount &&
    amount.isValid &&
    amount.value &&
    !amount.value.eq(existing?.amount?.shiftedValue ?? constants.Zero)
  ) {
    result.push({
      label: 'Supply Amount',
      oldValue: bigToFixedNumber(existing.amount.shiftedValue, loanDecimals),
      newValue: bigToFixedNumber(amount.value, loanDecimals),
      symbol: purchaseSymbol,
    })
  }

  if (!interestRate.isValid) {
    // TODO can put more interest rate error messages here
    errors.interestRateError = EditErrors.INVALID_INTEREST_RATE
  }

  if (
    interestRate &&
    interestRate.isValid &&
    interestRate.value &&
    !evaluate(
      {
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: interestRate.value,
          },
          {
            nodeKind: 'value',
            value: existing.interestRate ?? FixedNumber.fromString('0'),
          },
        ],
      },
      interestRate.value.format.decimals
    ).isZero()
  ) {
    result.push({
      label: 'Interest Rate',
      oldValue: existing?.interestRate as FixedNumber,
      newValue: interestRate.value,
      symbol: '%',
    })
  }
  return {
    differences: result,
    errors,
  }
}

export function diffBorrowEdit(
  draft: DraftBorrowEdit,
  existing: SubmittedBorrowTender,
  borrowDecimals: number,
  collateralDecimals: number,
  minAmount: BigNumber,
  maxAmount: BigNumber,
  minPrice: FixedNumber,
  maxPrice: FixedNumber,
  purchaseSymbol: string,
  collateralSymbol: string,
  purchaseTokenPrice: FixedNumber,
  collateralTokenPrice: FixedNumber,
  initialMarginRatio: FixedNumber
): {
  differences: TenderDiff[]
  errors: BorrowEditErrors
} {
  const amount = validateBigNumber(
    draft.amount,
    borrowDecimals,
    minAmount,
    maxAmount
  )

  const { requiredMargin } = getRequiredMargin(
    bigToFixedNumber(amount.value, borrowDecimals),
    initialMarginRatio,
    purchaseTokenPrice,
    collateralTokenPrice
  )

  const requiredMarginBn = fixedToBigNumber(requiredMargin, collateralDecimals)

  const collateral = validateBigNumber(
    draft.collateral,
    collateralDecimals,
    requiredMarginBn,
    constants.MaxUint256
  )

  const interestRate = validateFixedNumber(
    draft.interestRate,
    minPrice,
    maxPrice
  )

  const result = [] as TenderDiff[]
  const errors: BorrowEditErrors = {
    amountError: undefined,
    interestRateError: undefined,
    collateralError: undefined,
  }

  if (!amount.isValid) {
    if (amount?.error === 'too-small') {
      errors.amountError = `${
        EditErrors.MINIMUM_BORROW_AMOUNT
      } is ${formatUnits(minAmount, borrowDecimals)} ${purchaseSymbol}`
    } else {
      errors.amountError = EditErrors.INVALID_AMOUNT
    }
  }

  if (
    amount &&
    amount.isValid &&
    amount.value &&
    !amount.value.eq(existing?.amount?.shiftedValue ?? constants.Zero)
  ) {
    result.push({
      label: 'Borrow Amount',
      oldValue: bigToFixedNumber(existing.amount.shiftedValue, borrowDecimals),
      newValue: bigToFixedNumber(amount.value, borrowDecimals),
      symbol: purchaseSymbol,
    })
  }

  if (!interestRate.isValid) {
    errors.interestRateError = EditErrors.INVALID_INTEREST_RATE
  }

  if (
    interestRate &&
    interestRate.isValid &&
    interestRate.value &&
    !evaluate(
      {
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: interestRate.value,
          },
          {
            nodeKind: 'value',
            value: existing.interestRate ?? FixedNumber.fromString('0'),
          },
        ],
      },
      interestRate.value.format.decimals
    ).isZero()
  ) {
    result.push({
      label: 'Interest Rate',
      oldValue: existing.interestRate as FixedNumber,
      newValue: interestRate.value,
      symbol: '%',
    })
  }

  if (!collateral.isValid) {
    if (collateral?.error === 'too-small') {
      errors.collateralError = EditErrors.INSUFFICIENT_COLLATERAL
    } else {
      errors.collateralError = EditErrors.INVALID_COLLATERAL
    }
  }

  if (
    collateral &&
    collateral.isValid &&
    collateral.value &&
    !collateral.value.eq(existing?.collateral?.shiftedValue ?? constants.Zero)
  ) {
    result.push({
      label: 'Collateral',
      oldValue: bigToFixedNumber(
        existing.collateral.shiftedValue,
        collateralDecimals
      ),
      newValue: bigToFixedNumber(collateral.value, collateralDecimals),
      symbol: collateralSymbol,
    })
  }

  return {
    differences: result,
    errors,
  }
}
