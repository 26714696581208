import { Box, Heading } from '@chakra-ui/react'
import { ReactNode } from 'react'

export default function AuctionDetail({
  title,
  children,
  size,
}: {
  title: ReactNode
  children: ReactNode
  size?: string
}) {
  return (
    <Box width="100%">
      <Heading
        fontSize="sm"
        fontWeight="400"
        color="gray.500"
        whiteSpace="nowrap"
        mb={size === 'sm' ? '4px' : '12px'}
      >
        {title}
      </Heading>
      {children}
    </Box>
  )
}
