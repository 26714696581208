import { Flex, VStack, Text, Skeleton } from '@chakra-ui/react'

interface Props {
  top: React.ReactNode
  isDash?: boolean
  link?: string
  bottom: React.ReactNode
  isLoading?: boolean
}

export const SummaryItem = ({
  top,
  isDash,
  bottom,
  link,
  isLoading,
}: Props) => {
  return (
    <VStack alignItems="right" gap={1}>
      <Flex direction="row" justify="flex-end">
        <Text variant="body-md/medium" color="gray.6">
          {top}
        </Text>
        {/* {link && (
          <Link href={link}>
            <FontAwesomeIcon
              icon={['far', 'arrow-up-right']}
              width="12px"
              height="12px"
              color="gray"
            />
          </Link>
        )} */}
      </Flex>
      <Text
        variant="body-md/medium"
        color={isDash ? 'gray.6' : 'blue.9'}
        textAlign={'right'}
      >
        <Skeleton isLoaded={!isLoading}>{bottom}</Skeleton>
      </Text>
    </VStack>
  )
}

export default SummaryItem
