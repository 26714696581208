import { Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import StatsCard from '../../../../components/elements/StatsCard'
import StatsRow from '../../../../components/elements/StatsCard/StatsRow'
import StatsTitle from '../../../../components/elements/StatsCardTitle'
import TokenChip from '../../../../components/elements/TokenChip'
import { formatFixedUsd } from '../../../../helpers/utils'
import { evaluate } from '../../../../helpers/math'
import { Trans, t } from '@lingui/macro'

export default function RedeemOverview({
  purchaseTokenSymbol,

  termTokenInterestUsd,
  termTokenPrincipalUsd,

  auctionLabel,
}: {
  purchaseTokenSymbol: string

  termTokenInterestUsd: FixedNumber
  termTokenPrincipalUsd: FixedNumber

  auctionLabel: string
}) {
  return (
    <>
      <StatsCard>
        <StatsTitle value={auctionLabel}>Loan Details</StatsTitle>
        <StatsRow
          title={
            <>
              <b>
                <Trans>Outstanding Credit</Trans>
              </b>{' '}
              &nbsp;
              <TokenChip token={purchaseTokenSymbol} />
            </>
          }
        >
          <Text variant="body-sm/semibold" as="span" color="blue.9">
            {formatFixedUsd(
              evaluate({
                nodeKind: 'add',
                args: [
                  {
                    nodeKind: 'value',
                    value: termTokenPrincipalUsd,
                  },
                  {
                    nodeKind: 'value',
                    value: termTokenInterestUsd,
                  },
                ],
              }),
              true
            )}
          </Text>
        </StatsRow>
        <StatsRow title={t`Initial Loan`}>
          <Text variant="body-sm/normal" as="span" color="blue.9">
            {formatFixedUsd(termTokenPrincipalUsd, true)}
          </Text>
        </StatsRow>
        <StatsRow title={t`Interest`}>
          <Text variant="body-sm/normal" as="span" color="blue.9">
            {formatFixedUsd(termTokenInterestUsd, true)}
          </Text>
        </StatsRow>
      </StatsCard>
    </>
  )
}
