import { useMemo } from 'react'
import { Box, Button, ButtonProps, Flex, Spinner, Text } from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BigNumber } from 'ethers'
import SubmitApproveButton from '../../../../components/elements/SubmitApproveButton'
import ApproveStepIndicator from '../../../../components/elements/ApproveStepIndicator'
import { Trans, t } from '@lingui/macro'

export default function CancelRolloverRepayButtons({
  onCancelRollover,
  onRepay,
  onApprove,
  approvalAmount,
  approvedAmount,
  approvingCurrencySymbol,
  variant = 'secondary',
  size = 'sm',
  w = 'full',
  cancellingRollover,
  isSkipApproval,
  hasCancelledRollover,
  hasFailedRollover,
  isDisabled,
}: {
  onCancelRollover: () => Promise<void>
  onRepay: () => Promise<void>
  onApprove: () => Promise<void>
  approvalAmount: BigNumber
  approvedAmount: BigNumber
  approvingCurrencySymbol: string
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
  w?: ButtonProps['w']
  cancellingRollover: boolean
  isSkipApproval: boolean
  hasCancelledRollover: boolean
  hasFailedRollover?: boolean
  isDisabled?: boolean
}) {
  const buttonIcon = useMemo(() => {
    switch (true) {
      case cancellingRollover:
        return <Spinner size="sm" />
      case hasCancelledRollover:
        return (
          <FontAwesomeIcon
            icon={['far', 'check']}
            color={variant === 'secondary' ? 'blue.5' : 'white'}
          />
        )
    }
  }, [cancellingRollover, hasCancelledRollover, variant])

  const isCancelButtonDisabled =
    !hasFailedRollover || cancellingRollover || hasCancelledRollover

  const needsApproval = useMemo(
    () => approvalAmount.gt(approvedAmount),
    [approvalAmount, approvedAmount]
  )

  return (
    <VStack w="full" alignItems="flex-start">
      <VStack spacing="8px" w="full">
        {hasFailedRollover && (
          <Box position="relative" w="full">
            <ApproveStepIndicator>1</ApproveStepIndicator>
            <Button
              variant={variant}
              size={size}
              w={w}
              opacity={isCancelButtonDisabled ? 0.5 : 1}
              isDisabled={isCancelButtonDisabled}
              onClick={onCancelRollover}
            >
              <Flex as="span" alignItems="center" columnGap={1.5}>
                <Text as="span" variant="body-sm/semibold">
                  <Trans>Cancel rollover</Trans>
                </Text>
                {buttonIcon}
              </Flex>
            </Button>
          </Box>
        )}
        <Box position="relative" w="full" mt="0 !important">
          <SubmitApproveButton
            approvingCurrencySymbol={approvingCurrencySymbol}
            isDisabled={
              isDisabled || (hasFailedRollover && !hasCancelledRollover)
            }
            onSubmit={onRepay}
            onApprove={onApprove}
            approvalAmount={approvalAmount}
            approvedAmount={approvedAmount}
            submitLabel={t`Repay`}
            variant={variant}
            onKytCheck={async () => true}
            step={hasFailedRollover && needsApproval ? 2 : 1}
            forcefullyShowStep={!hasFailedRollover ? false : true}
            isSkipApproval={isSkipApproval}
          />
        </Box>
      </VStack>
    </VStack>
  )
}
