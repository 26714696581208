import { HStack, Text } from '@chakra-ui/react'
import { CategoryTagIcon, PointsCategory } from '../../utils'

export const EarningsChartTooltip = ({
  category,
  totalPoints,
  value,
}: {
  category: PointsCategory
  totalPoints: number
  value: number
}) => {
  const percentageOfTotal = ((value / totalPoints) * 100).toFixed(2)
  const variant = category.toLocaleLowerCase() as PointsCategory
  return (
    <>
      <HStack pl={2}>
        <svg
          width="16"
          height="8"
          viewBox="0 0 16 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8L16 8L8 -6.99382e-07L0 8Z"
            fill="#1A365D"
            fillOpacity="0.9"
          />
        </svg>
      </HStack>
      <HStack
        align="center"
        justify="space-between"
        bg="blue.900"
        mb={1.5}
        p={2}
        spacing={6}
        borderRadius="4px"
      >
        <HStack>
          <CategoryTagIcon variant={variant} />
          <Text variant="body-sm/medium" color="rgba(255, 255, 255, 0.7)">
            {category}
          </Text>
        </HStack>
        <Text variant="body-sm/medium">{percentageOfTotal}%</Text>
      </HStack>
    </>
  )
}
