import { useCallback } from 'react'

declare global {
  interface Window {
    safary?: {
      track: (args: {
        eventType: string
        eventName: string
        parameters: { [key: string]: string }
      }) => void
    }
  }
}

export function useSafary() {
  const trackSafaryEvent = useCallback(
    (
      eventType: string,
      eventName: string,
      parameters: { [key: string]: string }
    ) => {
      if (window.safary) {
        window.safary.track({
          eventType,
          eventName,
          parameters,
        })
      }
    },
    []
  )

  return { trackSafaryEvent }
}
