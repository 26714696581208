import { Tag, TagLabel } from '@chakra-ui/react'
import Tooltip from '../Tooltip'
import { getDisplayToken } from '../../../helpers/utils'

const TokenChip = ({
  token,
  size = 'xs',
  colorScheme = 'gray.6',
  variant = 'solid',
  fontWeight = '500',
  bg = 'gray.2',
  isSkipTruncation,
}: {
  token: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  colorScheme?: string
  variant?: string
  fontWeight?: string
  bg?: string
  isSkipTruncation?: boolean
}) => {
  const { displayToken, isTruncated } = getDisplayToken(token, {
    skipTruncation: isSkipTruncation,
  })

  return (
    <Tooltip
      leftAlign
      label={isTruncated ? token.toUpperCase() : undefined}
      placement="bottom"
    >
      <Tag
        size={size}
        variant={variant}
        fontWeight={fontWeight}
        color={colorScheme}
        bg={bg}
      >
        <TagLabel bg={bg} fontWeight={fontWeight}>
          {displayToken.toUpperCase()}
        </TagLabel>
      </Tag>
    </Tooltip>
  )
}

export default TokenChip
