import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { shortenAddress } from '../../../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useConfig } from '../../../providers/config'
import AuctionDetail from '../../../pages/Auctions/elements/AuctionModal/AuctionDetail'
import { Address } from '../../../data/model'
import ClickCopy from '../ClickCopy'
import { socialLinks } from '../../../socials'
import { VStack, HStack } from '../Stack'

function ContractAddress({
  chainId,
  address,
}: {
  chainId: string
  address: Address | undefined
}) {
  const config = useConfig()
  const addressLink: string = config.chains[chainId]?.getExplorerAddressLink(
    address ?? ''
  )
  return (
    <HStack width="80px">
      <Text align="left" width={24} variant="body-sm/normal" color="blue.9">
        {address && shortenAddress(address)}
      </Text>
      <Flex align="left">
        {address && (
          <HStack mr={1}>
            <Link href={addressLink} isExternal color="gray.3">
              <FontAwesomeIcon
                icon={['far', 'arrow-up-right']}
                width="16px"
                height="16px"
              />
            </Link>{' '}
            <ClickCopy
              copyText={address}
              helperText={'Copy address'}
              tooltipProps={{
                placement: 'bottom-end',
                offset: [8, 8],
              }}
            />
          </HStack>
        )}
      </Flex>
    </HStack>
  )
}

export default function ContractAddressModal({
  chainId,
  auctionLabel,
  auction,
  bidLocker,
  offerLocker,
  repoLocker,
  repoServicer,
  collateralManager,
  rolloverManager,
  termRepoToken,
  isOpen,
  onClose,
}: {
  chainId: string
  auctionLabel: string
  auction?: Address
  bidLocker?: Address
  offerLocker?: Address
  repoLocker?: Address
  repoServicer?: Address
  collateralManager?: Address
  rolloverManager?: Address
  termRepoToken?: Address
  isOpen: boolean
  onClose: () => void
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent borderRadius="md" p="28px">
        <ModalHeader p="0px">
          <HStack justifyContent="space-between">
            <VStack alignItems="flex-start" spacing={1}>
              <Text
                fontWeight="700"
                fontSize="xl"
                lineHeight="133%"
                color="gray.700"
              >
                {`Auction: ${auctionLabel}`}
              </Text>
              <Text variant="body-sm/normal" color="gray.5" mt={0}>
                Contract Addresses
              </Text>
            </VStack>
            <VStack spacing={0}>
              <ModalCloseButton
                position="relative"
                top="initial"
                right="initial"
              />
              <br />
            </VStack>
          </HStack>
        </ModalHeader>
        <ModalBody p="0px" mt="20px">
          <Text>
            Find contract details in the{' '}
            <Link
              href={socialLinks.deployedContracts}
              isExternal
              color="blue.5"
              textDecor="none"
            >
              documentation
            </Link>
          </Text>
          <br />
          <VStack spacing="20px" alignItems="stretch">
            <Flex>
              <AuctionDetail
                title="Auction"
                size="sm"
                children={
                  <ContractAddress chainId={chainId} address={auction} />
                }
              />
              <AuctionDetail
                title="Repo Servicer"
                size="sm"
                children={
                  <ContractAddress chainId={chainId} address={repoServicer} />
                }
              />
            </Flex>
            <Flex>
              <AuctionDetail
                title="Bid Locker"
                size="sm"
                children={
                  <ContractAddress chainId={chainId} address={bidLocker} />
                }
              />
              <AuctionDetail
                title="Collateral Manager"
                size="sm"
                children={
                  <ContractAddress
                    chainId={chainId}
                    address={collateralManager}
                  />
                }
              />
            </Flex>
            <Flex>
              <AuctionDetail
                title="Offer Locker"
                size="sm"
                children={
                  <ContractAddress chainId={chainId} address={offerLocker} />
                }
              />
              <AuctionDetail
                title="Rollover Manager"
                size="sm"
                children={
                  <ContractAddress
                    chainId={chainId}
                    address={rolloverManager}
                  />
                }
              />
            </Flex>
            <Flex>
              <AuctionDetail
                title="Repo Locker"
                size="sm"
                children={
                  <ContractAddress chainId={chainId} address={repoLocker} />
                }
              />
              <AuctionDetail
                title="Repo Token"
                size="sm"
                children={
                  <ContractAddress chainId={chainId} address={termRepoToken} />
                }
              />
            </Flex>
          </VStack>
        </ModalBody>
        <HStack justify="center" spacing="8px">
          <ModalFooter p="0px" mt="20px" w="full">
            <Button
              onClick={onClose}
              variant="secondary"
              px="16px"
              py="10x"
              w="full"
            >
              Close
            </Button>
          </ModalFooter>
        </HStack>
      </ModalContent>
    </Modal>
  )
}
