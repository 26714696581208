import { Box } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { Route, Routes } from 'react-router-dom'
import HistorySectionLoading from '../HistorySectionLoading'
import OpenOffersSectionLoading from '../OpenOffersSectionLoading'
import OpenPositionsSectionLoading from '../OpenPositionsSectionLoading'
import Sidebar from '../Sidebar'
import SummarySectionLoading from '../SummarySectionLoading'

export default function PortfolioPageLoading({
  pathPrefix,
}: {
  pathPrefix?: string
}) {
  return (
    <HStack alignItems="stretch" minH="calc(100vh - 60px - 60px)">
      <Sidebar
        pathPrefix={pathPrefix}
        openOffersCount={0}
        openPositionsCount={0}
      />
      <Box w="full" overflow="hidden" ml="0 !important" mb="35px" pb="80px">
        <Routes>
          <Route index element={<SummarySectionLoading />} />
          <Route path="positions" element={<OpenPositionsSectionLoading />} />
          <Route path="offers" element={<OpenOffersSectionLoading />} />
          <Route path="history" element={<HistorySectionLoading />} />
        </Routes>
      </Box>
    </HStack>
  )
}
