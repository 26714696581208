import React, { useEffect, useMemo, useRef } from 'react'
import { useToast, ToastId } from '@chakra-ui/react'
import { useCurrentTime } from '../data/hooks/helper-hooks'
import TermToast, {
  TermToastProps,
  WarningIcon,
  SuccessIcon,
  PendingIcon,
  ErrorIcon,
  DismissedIcon,
} from '../components/elements/TermToast'
import { Dayjs } from 'dayjs'
import { useEthers } from '@usedapp/core'
import { BidOrOffer } from '../helpers/toasts'
import { useChainConfig } from '../providers/config'

type CustomRender = (props: { onClose: () => void }) => React.ReactNode

export const useTermToast = (): {
  success: (termToastProps: TermToastProps) => ToastId
  pending: (termToastProps: TermToastProps) => ToastId
  warning: (termToastProps: TermToastProps) => ToastId
  failure: (termToastProps: TermToastProps) => ToastId
  dismissed: () => ToastId
  custom: (termToastProps: TermToastProps, render?: CustomRender) => ToastId
  close: (id: ToastId) => void
} => {
  const toast = useToast()
  const { library: provider } = useEthers()
  const chainConfig = useChainConfig(provider?.network?.chainId)

  // use currentTime but access the reference inside the useMemo
  //  this avoids having to include currentTime as a dependency in useMemo
  //  but still allows access to currentTime
  const currentTime = useCurrentTime()
  const currentTimeRef = useRef<Dayjs | null>(null)
  useEffect(() => {
    currentTimeRef.current = currentTime
  }, [currentTime])

  return useMemo(() => {
    const termToast = (
      termToastProps: TermToastProps,
      customRender?: CustomRender
    ) => {
      return toast({
        position: 'bottom-right',
        duration: termToastProps.duration === null ? null : 3000,
        isClosable: true,
        render: customRender
          ? customRender
          : (props) => (
              <TermToast
                {...termToastProps}
                onClose={props.onClose}
                eventTimestamp={
                  currentTimeRef.current
                    ? currentTimeRef.current.unix() * 1000
                    : undefined
                }
              />
            ),
      })
    }

    return {
      success: (termToastProps: TermToastProps) =>
        termToast({
          ...termToastProps,
          icon: <SuccessIcon />,
        }),
      pending: (termToastProps: TermToastProps) =>
        termToast({
          ...termToastProps,
          icon: <PendingIcon />,
        }),
      warning: (termToastProps: TermToastProps) =>
        termToast({
          ...termToastProps,
          icon: <WarningIcon />,
        }),
      failure: (
        termToastProps: TermToastProps & {
          specificRequestItem?: BidOrOffer
          requestItemIsPlural?: boolean
        }
      ) => {
        const isPlural = termToastProps?.requestItemIsPlural
        return termToast({
          ...termToastProps,
          children:
            termToastProps.children ??
            `Your ${
              !!termToastProps?.requestItemIsPlural
                ? `${termToastProps?.specificRequestItem}${isPlural ? 's' : ''}`
                : 'request'
            } ${isPlural ? 'were' : 'was'} not submitted to the ${
              chainConfig?.chainName ?? 'Unknwon'
            } network. Please try again.`,
          icon: <ErrorIcon />,
        })
      },
      dismissed: () =>
        termToast({
          title: 'Transaction dismissed',
          icon: <DismissedIcon />,
        }),
      custom: (termToastProps: TermToastProps, customRender?: CustomRender) =>
        termToast(termToastProps, customRender),
      close: (id: ToastId) => {
        toast.close(id)
      },
    }
  }, [toast, chainConfig?.chainName])
}
