import {
  Button,
  Flex,
  TableContainer,
  Tbody,
  Text,
  Tfoot,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode, memo, useCallback, useMemo, useRef, useState } from 'react'
import walletAnalyzerBackground from '../../../../assets/wallet-analyzer-background.svg'
import { HStack } from '../../../../components/elements/Stack'
import { Table, Td } from '../../../../components/elements/Table'
import { useColumnWidths } from '../../../../hooks/portfolio'
import { components } from '../../../../models/profile-api'
import { SocialActions, Socials } from '../../../../models/rewards'
import { InviteTableCols, compareValues } from '../../utils'
import SignIn from '../SignIn'
import { TableHeaderCell } from './TableHeaderCell'
import { TableRow } from './TableRow'

export interface ColumnWidths {
  [key: string]: number
}

type TableHeadings = {
  key: string
  column: string
  label: string
}

export const tableHeadings: TableHeadings[] = [
  { key: 'code', column: 'code', label: 'Invite Code' },
  {
    key: 'currentStep',
    column: 'meta.progress.currentStep',
    label: 'Track Invite',
  },
  { key: 'inviteeName', column: 'meta.inviteeName', label: 'Invitee' },
  { key: 'joined', column: 'consumedTimestamp', label: 'Joined' },
  { key: 'inviteePoints', column: 'meta.appPoints', label: 'Invitee Points' },
  { key: 'yourShare', column: 'meta.appPoints', label: 'Your Share' },
]

const mapInviteCode = (inviteCode: components['schemas']['InviteCode']) => {
  const progressStates = [
    {
      condition: inviteCode.meta.progress.earnedPoints,
      label: 'Earning 10%',
      tooltip: '',
      currentStep: 3,
    },
    {
      condition: inviteCode.meta.progress.signedUp,
      label: 'Signed Up',
      tooltip: 'To start earning, they need to use Term!',
      currentStep: 2,
    },
    {
      condition: inviteCode.meta.progress.usedInvite,
      label: 'Invite entered',
      tooltip:
        'Your invite code has been entered, make sure they complete sign up!',
      currentStep: 1,
    },
  ]

  const defaultState = {
    label: 'Invite available',
    tooltip: '',
    currentStep: 0,
  }

  const progressState =
    progressStates.find((state) => state.condition) || defaultState

  const inviteeName =
    inviteCode.meta.inviteeName !== undefined
      ? inviteCode.meta.inviteeName
      : 'No one (yet!)'

  return {
    ...inviteCode,
    meta: {
      ...inviteCode.meta,
      inviteeName: inviteeName,
      progress: {
        ...inviteCode.meta.progress,
        ...progressState,
      },
    },
  }
}

const COLUMN_WIDTHS: ColumnWidths = {
  code: 312,
  'meta.progress.currentStep': 274,
  'meta.inviteeName': 203,
  consumedTimestamp: 203,
  'meta.appPoints': 142,
  'meta.appPoints0': 220,
}

const TableComponent = memo(
  ({
    children,
    tableHeadings,
    handleThClick,
    sortColumn,
    sortDirection,
    columnWidths,
    currentPage,
    totalPages,
    paginate,
  }: {
    children: ReactNode
    tableHeadings: TableHeadings[]
    handleThClick: (column: InviteTableCols) => void
    sortColumn: InviteTableCols | null
    sortDirection: boolean
    columnWidths: ColumnWidths
    currentPage: number
    totalPages: number
    paginate: (page: number) => void
  }) => {
    return (
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              {tableHeadings.map(({ key, column, label }, index: number) => (
                <TableHeaderCell
                  key={key}
                  handleClick={() => handleThClick(column as InviteTableCols)}
                  column={column as InviteTableCols}
                  xsPadding={index >= tableHeadings.length - 2}
                  textRight={index >= tableHeadings.length - 2}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  width={columnWidths ? `${columnWidths[column]}px` : 'auto'}
                >
                  {label}
                </TableHeaderCell>
              ))}
            </Tr>
          </Thead>
          <Tbody borderBottom="solid 1px" borderColor="rgba(0, 16, 39, 0.10)">
            {children}
          </Tbody>
          <Tfoot>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          </Tfoot>
        </Table>
      </TableContainer>
    )
  }
)

const Pagination = ({
  currentPage,
  totalPages,
  paginate,
}: {
  currentPage: number
  totalPages: number
  paginate: (page: number) => void
}) => {
  const startPage = Math.floor((currentPage - 1) / 5) * 5 + 1
  const endPage = Math.min(startPage + 4, totalPages)

  const goBackOnePage = () => paginate(Math.max(currentPage - 1, 1))
  const goForwardOnePage = () => paginate(Math.min(currentPage + 1, totalPages))

  return (
    <Tr>
      <Td colSpan={6} pr={0}>
        <Flex justify="end" align="end" w="full" h="20px" mt={4}>
          <HStack gap={2}>
            <Button
              variant="link"
              color={currentPage === 1 ? 'gray.5' : 'blue.5'}
              onClick={goBackOnePage}
              disabled={currentPage === 1}
              px={2}
              minW="unset"
            >
              <FontAwesomeIcon
                icon={['far', 'chevron-left']}
                fontSize={'10px'}
              />
            </Button>
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
              const pageNumber = startPage + index
              return (
                <Button
                  key={pageNumber}
                  variant="link"
                  bg={currentPage === pageNumber ? 'blue.5' : 'transparent'}
                  color={currentPage === pageNumber ? 'white' : 'blue.5'}
                  onClick={() => paginate(pageNumber)}
                  px={1}
                  py={1}
                  minW="18px"
                >
                  <Text variant="body-sm/medium">{pageNumber}</Text>
                </Button>
              )
            })}
            {endPage < totalPages && (
              <>
                <span>...</span>
                <Button
                  variant="link"
                  color={currentPage === totalPages ? 'gray.5' : 'blue.5'}
                  onClick={() => paginate(totalPages)}
                  px={0}
                  minW="unset"
                >
                  <Text variant="body-sm/medium">{totalPages}</Text>
                </Button>
              </>
            )}
            <Button
              variant="link"
              color={currentPage === totalPages ? 'gray.5' : 'blue.5'}
              onClick={goForwardOnePage}
              disabled={currentPage === totalPages}
              pl={2}
              pr={1}
              minW="unset"
            >
              <FontAwesomeIcon
                icon={['far', 'chevron-right']}
                fontSize={'10px'}
              />
            </Button>
          </HStack>
        </Flex>
      </Td>
    </Tr>
  )
}

export default function TrackInvitesTable({
  account,
  inviteCodes,
  signedIn,
  connectedSocials,
  onLinkWallet,
  onLinkTwitter,
  onLinkDiscord,
}: {
  account: string
  inviteCodes?: components['schemas']['InviteCode'][]
  signedIn: boolean
  connectedSocials: Record<Socials, boolean>
  onLinkWallet?: () => Promise<void>
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => void
}) {
  const itemsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1)
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
  const [sortColumn, setSortColumn] = useState<InviteTableCols | null>(null)
  const [sortDirection, setSortDirection] = useState<boolean>(true) // true for ascending, false for descending

  const sortInviteCodes = useCallback(
    (inviteCodes: components['schemas']['InviteCode'][]) => {
      return inviteCodes.map(mapInviteCode).sort((a, b) => {
        const stepDiff = compareValues(
          Number(a.meta.progress.currentStep),
          Number(b.meta.progress.currentStep)
        )
        return stepDiff !== 0
          ? stepDiff
          : -compareValues(Number(a.meta.appPoints), Number(b.meta.appPoints))
      })
    },
    []
  )

  const sortedTableData = useMemo(() => {
    if (!inviteCodes) return []
    const sorted = sortInviteCodes(inviteCodes)
    if (sortColumn) {
      return sorted.sort((a: any, b: any) => {
        const keyParts = sortColumn.split('.')
        const aValue = keyParts.reduce((obj, keyPart) => obj[keyPart], a)
        const bValue = keyParts.reduce((obj, keyPart) => obj[keyPart], b)
        if (aValue < bValue) return sortDirection ? -1 : 1
        if (aValue > bValue) return sortDirection ? 1 : -1
        return 0
      })
    }
    return sorted
  }, [inviteCodes, sortColumn, sortDirection, sortInviteCodes])

  const handleThClick = (column: InviteTableCols) => {
    setSortDirection((prevSortDirection) => !prevSortDirection)
    setSortColumn(column)
  }

  const invitesTableRef = useRef(null)
  const tableRefs = useMemo(() => [invitesTableRef], [invitesTableRef])
  const excludedColumnsFromEmptySpaceCalculation = useMemo(
    () => new Set([]),
    []
  )
  const columnWidths = useColumnWidths(
    tableRefs,
    COLUMN_WIDTHS,
    excludedColumnsFromEmptySpaceCalculation,
    true
  )

  const totalPages = Math.ceil(sortedTableData.length / itemsPerPage)
  const currentItems = sortedTableData.slice(indexOfFirstItem, indexOfLastItem)

  return (
    <TableComponent
      key={currentPage}
      tableHeadings={tableHeadings}
      handleThClick={handleThClick}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      columnWidths={columnWidths}
      currentPage={currentPage}
      totalPages={totalPages}
      paginate={paginate}
    >
      {signedIn ? (
        currentItems &&
        currentItems.map((item) => (
          <TableRow key={item.code} inviteDetails={item} />
        ))
      ) : (
        <Tr>
          <Td colSpan={6}>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              height="400px"
              pt={10}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPosition="center"
              bgImage={`url(${walletAnalyzerBackground})`}
            >
              <SignIn
                account={account}
                subText="To track your invites"
                connectedSocials={connectedSocials}
                onLinkWallet={onLinkWallet}
                onLinkTwitter={() =>
                  onLinkTwitter
                    ? onLinkTwitter(
                        `/rewards/invite?action=${SocialActions.SignInTwitter}`
                      )
                    : undefined
                }
                onLinkDiscord={() =>
                  onLinkDiscord
                    ? onLinkDiscord(
                        `/rewards/invite?action=${SocialActions.SignInDiscord}`
                      )
                    : undefined
                }
              />
            </Flex>
          </Td>
        </Tr>
      )}
    </TableComponent>
  )
}
