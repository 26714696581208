import { Box, Heading, Spinner } from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'
import { useMemo, useRef } from 'react'
import {
  Table,
  TableFoot,
  TableHead,
  Th,
  Tr,
} from '../../../../components/elements/Table'
import { useColumnWidths } from '../../../../hooks/portfolio'
import { HistoryBorrowHeader, HistoryLoanHeader } from '../HistorySection'
import { Trans } from '@lingui/macro'

interface ColumnWidths {
  [key: string]: number
}

const COLUMN_WIDTHS: ColumnWidths = {
  loan: 90,
  status: 90,
  maturity: 90,
  repaidRedeemed: 90,
  debtRepaidCreditRedeemed: 90,
  initialBorrowInitialLoan: 90,
  interest: 90,
  emptyColumn: 125,
}

export default function HistorySection() {
  // Refs to tables to calculate column widths
  const borrowsTableRef = useRef(null)
  const loansTableRef = useRef(null)

  const tableRefs = useMemo(
    () => [borrowsTableRef, loansTableRef],
    [borrowsTableRef, loansTableRef]
  )

  const excludedColumnsFromEmptySpaceCalculation = useMemo(
    () => new Set(['repaidRedeemed']),
    []
  )

  const columnWidths = useColumnWidths(
    tableRefs,
    COLUMN_WIDTHS,
    excludedColumnsFromEmptySpaceCalculation,
    true
  )
  return (
    <Box my={5} mr="20px !important">
      <Heading variant="heading/h3" color="blue.9" mb="8px">
        <Trans>Borrow</Trans>
      </Heading>
      <Table tableRef={borrowsTableRef} isFixedLayout noPadding>
        <TableHead>
          <HistoryBorrowHeader
            sortDirection="desc"
            onToggleOrder={() => {}}
            count={0}
            isFirst
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableFoot noTopBorder>
          <Tr noBorder>
            <Th textAlign="center" colSpan={8} p="14px 0">
              <HStack w="full" justifyContent="center">
                <Spinner
                  color="blue.500"
                  w="42px"
                  h="42px"
                  className="chromatic-ignore"
                />
              </HStack>
            </Th>
          </Tr>
        </TableFoot>
      </Table>
      <Heading variant="heading/h3" color="blue.9" mb="8px" mt="40px">
        <Trans>Loan</Trans>
      </Heading>
      <Table tableRef={loansTableRef} isFixedLayout noPadding>
        <TableHead>
          <HistoryLoanHeader
            sortDirection="desc"
            onToggleOrder={() => {}}
            count={0}
            isFirst
            columnWidths={columnWidths}
          />
        </TableHead>
        <TableFoot noTopBorder>
          <Tr noBorder>
            <Th textAlign="center" colSpan={8} p="14px 0">
              <HStack w="full" justifyContent="center">
                <Spinner
                  color="blue.500"
                  w="42px"
                  h="42px"
                  className="chromatic-ignore"
                />
              </HStack>
            </Th>
          </Tr>
        </TableFoot>
      </Table>
    </Box>
  )
}
