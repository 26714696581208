import { Flex, Text } from '@chakra-ui/react'
import { components as ProfileSchema } from '../../../../models/profile-api'
import TrackPointsSummary from '../TrackPointsSummary'
import { FixedNumber } from 'ethers'

export default function TrackSection({
  profileData,
  profileBadges,
  usedInvites,
  totalInvites,
  seasonPointsBreakdown,
  preseasonPoints,
  season,
  totalNumberOfAuctions,
  totalNumberOfWeeks,
  totalPoints,
  totalActiveLoans,
  totalActivePositions,
  isLoading,
}: {
  profileData?: ProfileSchema['schemas']['Profile']
  profileBadges?: ProfileSchema['schemas']['Badge'][]
  usedInvites?: number
  totalInvites?: number
  seasonPointsBreakdown:
    | ProfileSchema['schemas']['ProfilePoints'][string]
    | undefined
  preseasonPoints: ProfileSchema['schemas']['PreseasonPoints'] | undefined
  season: ProfileSchema['schemas']['Season'] | undefined
  totalNumberOfAuctions: number
  totalNumberOfWeeks: number
  totalPoints: number
  totalActiveLoans: FixedNumber
  totalActivePositions: number
  isLoading?: boolean
}) {
  return (
    <Flex direction="column" gap={6}>
      <Text color={'blue.9'} variant={'body-2xl/medium'}>
        Track how you earn
      </Text>
      <TrackPointsSummary
        data={seasonPointsBreakdown}
        preseasonPoints={preseasonPoints}
        season={season}
        totalNumberOfAuctions={totalNumberOfAuctions}
        totalNumberOfWeeks={totalNumberOfWeeks}
        totalPoints={totalPoints}
        totalActiveLoans={totalActiveLoans}
        totalNumOfActiveLoans={totalActivePositions}
        profileData={profileData}
        profileBadges={profileBadges}
        usedInvites={usedInvites}
        totalInvites={totalInvites}
        isLoading={isLoading}
      />
    </Flex>
  )
}
