import {
  Button,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { HStack, VStack, Stack } from '../../../../../components/elements/Stack'
import { FixedNumber } from 'ethers'
import { useMemo, useState } from 'react'
import Chip from '../../../../../components/elements/Chip'
import TransactionHash from '../../../../../components/elements/TransactionHash'
import { TenderDelete } from '../../../../../data/parse'
import { formatFixedToken } from '../../../../../helpers/utils'
import { Trans } from '@lingui/macro'

export default function ConfirmDeleteModal({
  deletes,
  tenderKind,
  isOpen,
  onClose,
  onConfirm,
  getBlockExplorerTransactionUrl,
}: {
  deletes: TenderDelete[]
  tenderKind: 'offer' | 'bid'
  isOpen: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
  getBlockExplorerTransactionUrl?: (address: string) => string
}) {
  const tenderKindPluralLabel = useMemo(
    () =>
      tenderKind === 'bid'
        ? 'Bids'
        : tenderKind === 'offer'
          ? 'Offers'
          : 'Tenders',
    [tenderKind]
  )
  const tenderKindLabel = useMemo(
    () =>
      tenderKind === 'bid'
        ? 'Bid'
        : tenderKind === 'offer'
          ? 'Offer'
          : 'Tender',
    [tenderKind]
  )
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="standard">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <Stack p="28px" spacing={'20px'}>
            <HStack justify="space-between">
              <Heading
                variant="heading/h3"
                fontWeight="700"
                mt="0px"
                mb="0px"
                textAlign="start"
                color="blue.9"
              >
                <Trans>
                  Delete{' '}
                  {deletes?.length > 1
                    ? tenderKindPluralLabel
                    : tenderKindLabel}
                  ?
                </Trans>
              </Heading>
              <ModalCloseButton position="static" color="blue.9" />
            </HStack>
            <Text color="blue.9" variant="body-sm/normal">
              <Trans>
                Are you sure you want to delete the following{' '}
                {deletes?.length > 1
                  ? tenderKindPluralLabel.toLocaleLowerCase()
                  : tenderKindLabel.toLocaleLowerCase()}
                ? If you proceed, you will be prompted to confirm a wallet
                transaction.
              </Trans>
            </Text>
            <VStack w="full" spacing="28px">
              {deletes.map((deleteOp) => (
                <VStack
                  alignItems="center"
                  borderRadius="md"
                  bg="blue.0"
                  w="full"
                  borderWidth="1px"
                  borderColor="gray.2"
                >
                  <HStack
                    justifyContent="space-between"
                    pt="8px"
                    px="16px"
                    w="full"
                  >
                    <Text as="span" color="gray.6" variant="body-sm/semibold">
                      <Trans>{tenderKindLabel} Overview</Trans>
                    </Text>
                    <TransactionHash
                      address={deleteOp.transaction}
                      getBlockExplorerTransactionUrl={
                        getBlockExplorerTransactionUrl
                      }
                      color="gray.6"
                      iconColor="gray.6"
                      headChars={6}
                      tailChars={4}
                      variant="body-xs/medium"
                      bg="gray.2"
                      borderRadius="md"
                      py={0.5}
                      px={1.5}
                    />
                  </HStack>
                  <Divider borderColor="gray.2" margin={0} />
                  <HStack
                    justifyContent="space-between"
                    pb="8px"
                    px="16px"
                    w="full"
                  >
                    <HStack>
                      <Text as="span" color="gray.6" variant="body-sm/medium">
                        <Trans>
                          {' '}
                          {tenderKind === 'offer' ? 'Supply' : 'Borrow'} Amount
                        </Trans>
                      </Text>
                      <Chip size="xs">{deleteOp.symbol}</Chip>
                    </HStack>
                    <HStack>
                      <Text as="span" color="gray.4" variant="body-sm/normal">
                        {formatFixedToken(
                          deleteOp.oldAmount,
                          deleteOp.symbol,
                          true
                        )}{' '}
                        →
                      </Text>
                      <Text as="span" color="blue.9" variant="body-sm/normal">
                        {formatFixedToken(
                          FixedNumber.fromString(
                            '0',
                            deleteOp.oldAmount.format
                          ),
                          deleteOp.symbol,
                          true
                        )}
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
              ))}
            </VStack>
            <Button
              variant="error"
              w="full"
              onClick={async () => {
                setIsLoading(true)
                try {
                  await onConfirm()
                } catch (error) {
                  console.log(error)
                } finally {
                  setIsLoading(false)
                }
              }}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Delete{' '}
              {deletes?.length > 1
                ? tenderKindPluralLabel.toLocaleLowerCase()
                : tenderKindLabel.toLocaleLowerCase()}
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
