import { Stack } from '../Stack'

export default function StatsCard({
  hideBorder,
  children,
  mt = 0,
}: {
  hideBorder?: boolean
  children: React.ReactNode
  mt?: number
}) {
  return (
    <Stack
      bgColor="blue.0"
      borderColor={'gray.2'}
      borderWidth={!hideBorder ? 1 : 0}
      borderRadius="md"
      spacing={mt}
      pb={2}
      maxW={'524px'}
      // mt={mt}
    >
      {children}
    </Stack>
  )
}
