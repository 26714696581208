import { Box, Flex, Text } from '@chakra-ui/react'
import { FixedNumber } from 'ethers'
import { useState } from 'react'
import AssetDropdown from '../../components/elements/AssetDropdown'
import { HStack } from '../../components/elements/Stack'
import TotalDeposited from './elements/TotalDeposited'
import VaultsTable from './elements/VaultsTable'

export default function VaultsPage({
  currencies,
  vaultsData,
}: {
  currencies: any
  vaultsData: any
}) {
  const totalDeposited = FixedNumber.fromString('4532234')
  const [depositToken, setDepositToken] = useState<string | null>(null)
  const onChangePurchaseToken = (token: string) => {
    setDepositToken(token)
  }

  return (
    <Box maxW="1400px" px={{ base: 8, xl: 10 }} mx="auto">
      <Flex w="100%" pt={8} justify="space-between" mb={6}>
        <HStack>
          <Text fontSize="34px" variant="body-3xl/bold">
            Deposit
          </Text>
          <AssetDropdown
            currencies={currencies}
            onChangePurchaseToken={onChangePurchaseToken}
            showAllOption={true}
          />
        </HStack>
        <TotalDeposited total={totalDeposited} label="Total Deposited" />
      </Flex>
      <Flex w="100%" flexDir="column" gap={4}>
        <VaultsTable
          selectedDepositToken={depositToken ?? ''}
          vaultsData={vaultsData}
          selectedChainId={'1'}
        />
      </Flex>
    </Box>
  )
}
