import { Button, ButtonProps, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'

export default function AddBidButton({ onClick }: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      variant="link"
      mt="16px"
      color="blue.5"
      leftIcon={<FontAwesomeIcon icon={['far', 'plus']} />}
    >
      <Text as="span" variant="body-sm/semibold">
        <Trans>Add another bid</Trans>
      </Text>
    </Button>
  )
}
