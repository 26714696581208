import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { VStack } from '../../../../components/elements/Stack'
import dayjs from 'dayjs'
import { TransactionState } from '@usedapp/core'
import { BigNumberish, FixedNumber } from 'ethers'
import { useCallback, useMemo, useState } from 'react'
import StatsWalletBeforeAfter from '../../../../components/elements/StatsWalletBeforeAfter'
import TokenAmountField from '../../../../components/elements/TokenAmountField'
import { useTermToast } from '../../../../hooks/toasts'
import { termToastMessages } from '../../../../helpers/toasts'
import { useCurrentTime } from '../../../../data/hooks/helper-hooks'
import { Currency } from '../../../../data/model'
import {
  GAS_TOKEN_SYMBOL,
  TERM_REPO_TOKEN_SYMBOL,
} from '../../../../helpers/constants'
import { fixedToBigNumber } from '../../../../helpers/conversions'
import { isRejectedTxn } from '../../../../helpers/toasts'
import { evaluate, fixedCompare } from '../../../../helpers/math'
import { formatFixedToken, formatFixedUsd } from '../../../../helpers/utils'
import RepayLoanOverview from '../RepayLoanOverview'
import CancelRolloverRepayButtons from '../CancelRolloverRepayButtons'
import { Trans, t } from '@lingui/macro'

export default function RepayLoanModal({
  purchaseCurrency,
  collateralCurrency,
  defaultRepaymentAmount = '',

  auctionLabel,

  endOfRepaymentTimestamp,

  loanPrincipal,
  outstandingDebt,
  rolloverAmount,
  rolloverCancelled,

  termTokenBalance,
  purchaseBalance,
  collateralBalance,

  purchasePrice,
  collateralPrice,

  excessCollateral,
  collateralDeposited,
  maintenanceMarginRatio,

  approvedPurchaseTokens,
  onApprovePurchaseToken,
  onRepay,
  onCollapse,

  hasFailedRollover,
  onCancelRollover,

  isOpen,
  onClose,
}: {
  defaultRepaymentAmount?: string
  purchaseCurrency: Currency
  collateralCurrency: Currency

  auctionLabel: string

  endOfRepaymentTimestamp: number

  loanPrincipal: FixedNumber // purchase tokens
  outstandingDebt: FixedNumber
  rolloverAmount?: FixedNumber
  rolloverCancelled?: boolean // if the user has cancelled the rollover

  termTokenBalance: FixedNumber
  purchaseBalance: FixedNumber
  collateralBalance: FixedNumber

  purchasePrice: FixedNumber
  collateralPrice: FixedNumber

  excessCollateral: FixedNumber
  collateralDeposited: FixedNumber
  maintenanceMarginRatio: FixedNumber

  approvedPurchaseTokens: FixedNumber
  onApprovePurchaseToken: (amount: BigNumberish) => Promise<void>
  onCollapse: (
    termTokensToCollapse: FixedNumber,
    isGasToken: boolean
  ) => Promise<void>
  onRepay: (
    repaymentAmount: FixedNumber,
    isGasToken: boolean
  ) => Promise<TransactionState>

  hasFailedRollover?: boolean // elected rollover has failed (outside of user control)
  onCancelRollover: () => Promise<void>

  isOpen: boolean
  onClose: () => void
}) {
  const termToast = useTermToast()
  const currentTime = useCurrentTime()
  const [repaymentAmount, setRepaymentAmount] = useState(defaultRepaymentAmount)
  const [tokenSymbol, setTokenSymbol] = useState(purchaseCurrency.symbol)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [cancellingRollover, setIsCancellingRollover] = useState(false)
  const [hasCancelledRollover, setHasCancelledRollover] = useState(
    rolloverCancelled ?? false
  )

  const onCloseModal = useCallback(() => {
    setRepaymentAmount('')
    setTokenSymbol(purchaseCurrency.symbol)
    setIsSubmitting(false)
    onClose()
  }, [onClose, purchaseCurrency.symbol])

  const zero = FixedNumber.fromString(
    '0',
    `fixed128x${purchaseCurrency.decimals}`
  )

  const repaymentAmountParsed = useMemo(() => {
    if (!repaymentAmount) {
      return zero
    }
    try {
      return FixedNumber.fromString(
        repaymentAmount.replace(',', ''),
        `fixed128x${purchaseCurrency.decimals}`
      )
    } catch (err) {
      console.warn(err)
      return zero
    }
  }, [purchaseCurrency.decimals, repaymentAmount, zero])

  const formatRepaymentTimestamp = useCallback(
    (timestamp: number) => dayjs.unix(timestamp).format('MMM D, ha'),
    []
  )

  const repaymentText = useMemo(
    () => formatRepaymentTimestamp(endOfRepaymentTimestamp),
    [endOfRepaymentTimestamp, formatRepaymentTimestamp]
  )

  const maxRepayAmount = useMemo(
    () =>
      evaluate({
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: outstandingDebt,
          },
          {
            nodeKind: 'value',
            value:
              (!hasFailedRollover && !hasCancelledRollover && rolloverAmount) ||
              FixedNumber.fromString('0', loanPrincipal.format),
          },
        ],
      }),
    [
      loanPrincipal.format,
      outstandingDebt,
      rolloverAmount,
      hasCancelledRollover,
      hasFailedRollover,
    ]
  )

  const afterRepayDebt = useMemo(
    () =>
      evaluate({
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: outstandingDebt,
          },
          {
            nodeKind: 'value',
            value:
              repaymentAmountParsed ||
              FixedNumber.fromString('0', loanPrincipal.format),
          },
        ],
      }),
    [loanPrincipal.format, outstandingDebt, repaymentAmountParsed]
  )

  const afterExcessCollateral = useMemo(
    () =>
      evaluate({
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: collateralDeposited,
          },
          {
            nodeKind: 'div',
            args: [
              {
                nodeKind: 'mul',
                args: [
                  {
                    nodeKind: 'value',
                    value: afterRepayDebt,
                  },
                  {
                    nodeKind: 'value',
                    value: purchasePrice,
                  },
                  {
                    nodeKind: 'value',
                    value: maintenanceMarginRatio,
                  },
                ],
              },
              {
                nodeKind: 'value',
                value: collateralPrice,
              },
            ],
          },
        ],
      }),
    [
      afterRepayDebt,
      collateralDeposited,
      collateralPrice,
      maintenanceMarginRatio,
      purchasePrice,
    ]
  )

  const repaymentBalance =
    tokenSymbol === TERM_REPO_TOKEN_SYMBOL ? termTokenBalance : purchaseBalance
  const userMaxRepayment = fixedCompare(maxRepayAmount, 'gt', repaymentBalance)
    ? repaymentBalance
    : maxRepayAmount

  const usdBalance = useMemo(
    () =>
      evaluate(
        {
          nodeKind: 'mul',
          args: [
            {
              nodeKind: 'value',
              value:
                tokenSymbol === TERM_REPO_TOKEN_SYMBOL
                  ? termTokenBalance
                  : purchaseBalance,
            },
            {
              nodeKind: 'value',
              value: purchasePrice,
            },
          ],
        },
        loanPrincipal.format.decimals
      ),
    [
      loanPrincipal.format,
      purchaseBalance,
      purchasePrice,
      tokenSymbol,
      termTokenBalance,
    ]
  )

  const afterUsd = useMemo(
    () =>
      evaluate(
        {
          nodeKind: 'sub',
          args: [
            {
              nodeKind: 'value',
              value: usdBalance,
            },
            {
              nodeKind: 'mul',
              args: [
                {
                  nodeKind: 'value',
                  value: repaymentAmountParsed,
                },
                {
                  nodeKind: 'value',
                  value: purchasePrice,
                },
              ],
            },
          ],
        },
        loanPrincipal.format.decimals
      ),
    [usdBalance, repaymentAmountParsed, loanPrincipal.format, purchasePrice]
  )
  const repaymentTimestampForToast = useMemo(
    () => currentTime.format('MMM D'),
    [currentTime]
  )

  const repaymentAmountUsd = useMemo(
    () =>
      evaluate(
        {
          nodeKind: 'mul',
          args: [
            {
              nodeKind: 'value',
              value: repaymentAmountParsed,
            },
            {
              nodeKind: 'value',
              value: purchasePrice,
            },
          ],
        },
        loanPrincipal.format.decimals
      ),
    [repaymentAmountParsed, purchasePrice, loanPrincipal.format]
  )

  const cancelRollover = useCallback(async () => {
    setIsCancellingRollover(true)
    termToast.pending(termToastMessages.cancelRollover.pending)
    try {
      await onCancelRollover()
      termToast.success(termToastMessages.cancelRollover.success())
      setHasCancelledRollover(true)
    } catch (error) {
      if (isRejectedTxn(error)) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.cancelRollover.failure)
      }
    } finally {
      setIsCancellingRollover(false)
    }
  }, [termToast, onCancelRollover])

  const repay = useCallback(async () => {
    const formattedLoan = `${purchaseCurrency.symbol}(${collateralCurrency.symbol})-${repaymentTimestampForToast}`

    termToast.pending(termToastMessages.repayLoan.pending)
    try {
      setIsSubmitting(true)

      if (tokenSymbol === purchaseCurrency.symbol) {
        console.info('repaying loan using purchase currency balance')
        await onRepay(
          repaymentAmountParsed,
          purchaseCurrency.symbol === GAS_TOKEN_SYMBOL
        )
      } else {
        console.info('repaying loan using term token balance')
        await onCollapse(
          repaymentAmountParsed,
          purchaseCurrency.symbol === GAS_TOKEN_SYMBOL
        )
      }

      if (repaymentAmountParsed.toString() === maxRepayAmount.toString()) {
        termToast.success(
          termToastMessages.repayLoan.success(formattedLoan, 'full')
        )
      } else {
        termToast.success(
          termToastMessages.repayLoan.success(formattedLoan, 'partial')
        )
      }
    } catch (error) {
      if (isRejectedTxn(error)) {
        termToast.dismissed()
      } else {
        termToast.failure(termToastMessages.repayLoan.failure(formattedLoan))
      }
    }
    onCloseModal()
  }, [
    collateralCurrency.symbol,
    onCloseModal,
    onRepay,
    onCollapse,
    maxRepayAmount,
    purchaseCurrency.symbol,
    repaymentAmountParsed,
    repaymentTimestampForToast,
    termToast,
    tokenSymbol,
  ])

  const isMoreThanOwed = useMemo(
    () =>
      evaluate({
        nodeKind: 'sub',
        args: [
          {
            nodeKind: 'value',
            value: maxRepayAmount,
          },
          {
            nodeKind: 'value',
            value: repaymentAmountParsed,
          },
        ],
      }).isNegative(),
    [maxRepayAmount, repaymentAmountParsed]
  )
  const isMoreThanBalance = useMemo(() => afterUsd.isNegative(), [afterUsd])

  const notEnoughApproved = useMemo(() => {
    if (dayjs.unix(endOfRepaymentTimestamp).isBefore(currentTime)) {
      return repaymentAmountParsed
        ? evaluate({
            nodeKind: 'sub',
            args: [
              {
                nodeKind: 'value',
                value: approvedPurchaseTokens,
              },
              {
                nodeKind: 'value',
                value: repaymentAmountParsed,
              },
            ],
          }).isNegative()
        : true
    } else {
      return false
    }
  }, [approvedPurchaseTokens, endOfRepaymentTimestamp, repaymentAmountParsed])

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent pb={7} maxW="480px">
        <ModalHeader>
          <Trans>Repay Loan</Trans>
        </ModalHeader>
        <ModalCloseButton m={2} />
        <ModalBody pb={1}>
          <Text textAlign="left" pb={5} variant="body-sm/normal" color="blue.9">
            {!hasFailedRollover ? (
              <Trans>
                Repay by {repaymentText} to avoid liquidation. Please note, any
                debt that has been scheduled to rollover cannot be repaid.
              </Trans>
            ) : (
              <Trans>
                Repay by {repaymentText} to avoid liquidation. Since your
                rollover bid was unsuccessful, you are required to cancel the
                rollover and fulfill the repayment of your outstanding debt.
              </Trans>
            )}
          </Text>
          <RepayLoanOverview
            maxRepayment={maxRepayAmount}
            purchaseSymbol={purchaseCurrency.symbol}
            outstandingDebt={outstandingDebt}
            rolloverAmount={hasCancelledRollover ? zero : rolloverAmount}
            rolloverFailed={hasFailedRollover}
            auctionLabel={auctionLabel}
          />
          <Box mt={5} />
          <TokenAmountField
            label={t`Amount`}
            tokenSymbol={tokenSymbol}
            isError={isMoreThanOwed || isMoreThanBalance}
            placeholder="0.00"
            tokenDropdownList={[
              purchaseCurrency.symbol,
              TERM_REPO_TOKEN_SYMBOL,
            ]}
            helperText={
              // Do not show error if isSubmitting === true
              // (balance may reduce to lower than input value during transaction)
              !isSubmitting && isMoreThanOwed ? (
                <Text color="orange.5">
                  <Trans>You cannot repay more than what is due now</Trans>
                </Text>
              ) : isMoreThanBalance ? (
                <Text color="red.5">
                  <Trans>Insufficient funds in connected wallet</Trans>
                </Text>
              ) : repaymentAmount && tokenSymbol === TERM_REPO_TOKEN_SYMBOL ? (
                <p>~{formatFixedUsd(repaymentAmountUsd)}</p>
              ) : undefined
            }
            onChangeToken={setTokenSymbol}
            rightLabel={
              <VStack align="right" spacing={0}>
                <Text
                  align="right"
                  color={
                    isMoreThanOwed ? 'orange.5 !important' : 'gray.5 !important'
                  }
                >{t`Due Now: ${formatFixedToken(
                  maxRepayAmount,
                  purchaseCurrency.symbol,
                  true,
                  true
                )}`}</Text>

                <Text
                  as="span"
                  align="right"
                  color={isMoreThanBalance ? 'red.5' : 'gray.5 !important'}
                  mt="0 !important"
                >
                  {tokenSymbol === TERM_REPO_TOKEN_SYMBOL
                    ? t`Available: ${formatFixedToken(
                        termTokenBalance,
                        purchaseCurrency.symbol,
                        true,
                        true,
                        TERM_REPO_TOKEN_SYMBOL
                      )}`
                    : t`Available: ${formatFixedToken(
                        purchaseBalance,
                        purchaseCurrency.symbol,
                        true,
                        true
                      )}`}
                </Text>
              </VStack>
            }
            onMax={() => {
              setRepaymentAmount(userMaxRepayment.toString())
            }}
            value={repaymentAmount}
            tokenDecimals={purchaseCurrency.decimals}
            onChange={(value) => {
              setRepaymentAmount(value)
            }}
          />
          <Box mt={5}>
            <CancelRolloverRepayButtons
              onRepay={repay}
              onCancelRollover={cancelRollover}
              onApprove={async () => {
                await onApprovePurchaseToken(
                  fixedToBigNumber(repaymentAmountParsed)
                )
              }}
              approvalAmount={fixedToBigNumber(repaymentAmountParsed)}
              approvedAmount={fixedToBigNumber(approvedPurchaseTokens)}
              approvingCurrencySymbol={purchaseCurrency.symbol}
              cancellingRollover={cancellingRollover}
              hasFailedRollover={hasFailedRollover}
              hasCancelledRollover={hasCancelledRollover}
              isDisabled={
                repaymentAmountParsed.isZero() ||
                isMoreThanOwed ||
                isMoreThanBalance
              }
              isSkipApproval={
                tokenSymbol === TERM_REPO_TOKEN_SYMBOL ? true : false
              }
            />
          </Box>
          {!(isMoreThanOwed || isMoreThanBalance || notEnoughApproved) &&
          !repaymentAmountParsed.isZero() ? (
            <StatsWalletBeforeAfter
              symbol={collateralCurrency.symbol}
              afterRepaymentDebt={afterRepayDebt}
              tokenBalance={collateralBalance}
              tokenDelta={collateralDeposited}
              excessCollateralBefore={excessCollateral}
              excessCollateralAfter={afterExcessCollateral}
              mt={5}
            />
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
