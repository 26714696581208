import { createIcon } from '@chakra-ui/react'

export const Metamask = createIcon({
  displayName: 'Metamask',
  viewBox: '0 0 16 16',
  path: [
    <path
      d="M14.3265 0.666626L8.0332 5.34151L9.20351 2.58645L14.3265 0.666626Z"
      fill="#E17726"
    />,
    <path
      d="M0.354492 0.672119L5.46382 2.58679L6.57507 5.37798L0.354492 0.672119Z"
      fill="#E27625"
    />,
    <path
      d="M11.7936 10.6088L14.5752 10.6619L13.6031 13.9773L10.209 13.0391L11.7936 10.6088Z"
      fill="#E27625"
    />,
    <path
      d="M2.8725 10.6088L4.4513 13.0391L1.06288 13.9773L0.0966797 10.6619L2.8725 10.6088Z"
      fill="#E27625"
    />,
    <path
      d="M6.42448 4.66699L6.53819 8.35284L3.13672 8.19747L4.10424 6.73211L4.11648 6.71797L6.42448 4.66699Z"
      fill="#E27625"
    />,
    <path
      d="M8.20768 4.62585L10.5509 6.7181L10.563 6.73212L11.5306 8.19749L8.12988 8.35282L8.20768 4.62585Z"
      fill="#E27625"
    />,
    <path
      d="M4.55116 10.6195L6.40847 12.0723L4.25098 13.118L4.55116 10.6195Z"
      fill="#E27625"
    />,
    <path
      d="M10.1163 10.6193L10.4102 13.118L8.25879 12.0722L10.1163 10.6193Z"
      fill="#E27625"
    />,
    <path
      d="M8.30566 11.9353L10.4889 12.9966L8.45805 13.9656L8.47914 13.3251L8.30566 11.9353Z"
      fill="#D5BFB2"
    />,
    <path
      d="M6.36046 11.9358L6.19379 13.3147L6.20747 13.9648L4.17188 12.9966L6.36046 11.9358Z"
      fill="#D5BFB2"
    />,
    <path
      d="M5.7293 8.83386L6.29981 10.0376L4.35742 9.46634L5.7293 8.83386Z"
      fill="#233447"
    />,
    <path
      d="M8.93658 8.83398L10.3149 9.4663L8.36621 10.0374L8.93658 8.83398Z"
      fill="#233447"
    />,
    <path
      d="M4.69895 10.6072L4.38497 13.1977L2.70215 10.6638L4.69895 10.6072Z"
      fill="#CC6228"
    />,
    <path
      d="M9.96777 10.6072L11.9646 10.6638L10.2755 13.1978L9.96777 10.6072Z"
      fill="#CC6228"
    />,
    <path
      d="M11.579 8.05103L10.1257 9.53788L9.00531 9.02386L8.46886 10.156L8.11719 8.20914L11.579 8.05103Z"
      fill="#CC6228"
    />,
    <path
      d="M3.08691 8.05103L6.54929 8.20917L6.19762 10.156L5.66107 9.02403L4.54654 9.53793L3.08691 8.05103Z"
      fill="#CC6228"
    />,
    <path
      d="M2.98828 7.74585L4.63245 9.42078L4.68944 11.0743L2.98828 7.74585Z"
      fill="#E27525"
    />,
    <path
      d="M11.6798 7.7428L9.97559 11.0772L10.0397 9.42072L11.6798 7.7428Z"
      fill="#E27525"
    />,
    <path
      d="M6.46832 7.8479L6.53448 8.26604L6.698 9.30771L6.59288 12.5071L6.09587 9.937L6.0957 9.91042L6.46832 7.8479Z"
      fill="#E27525"
    />,
    <path
      d="M8.19689 7.84204L8.57049 9.91033L8.57033 9.93691L8.07207 12.5134L8.05235 11.869L7.97461 9.28867L8.19689 7.84204Z"
      fill="#E27525"
    />,
    <path
      d="M10.1856 9.35425L10.13 10.7909L8.39555 12.1475L8.04492 11.8988L8.43795 9.86647L10.1856 9.35425Z"
      fill="#F5841F"
    />,
    <path
      d="M4.4873 9.35425L6.22896 9.86649L6.62198 11.8988L6.27135 12.1475L4.53681 10.7907L4.4873 9.35425Z"
      fill="#F5841F"
    />,
    <path
      d="M3.83984 12.6793L6.05887 13.7349L6.04947 13.2841L6.23514 13.1205H8.43135L8.62374 13.2836L8.60956 13.734L10.8145 12.682L9.74158 13.5721L8.44418 14.4667H6.21736L4.92079 13.5684L3.83984 12.6793Z"
      fill="#C0AC9D"
    />,
    <path
      d="M8.14754 11.7948L8.46129 12.0173L8.64515 13.49L8.37907 13.2644H6.28933L6.02832 13.4945L6.20614 12.0174L6.52 11.7948H8.14754Z"
      fill="#161616"
    />,
    <path
      d="M13.9115 0.796021L14.667 3.07122L14.1952 5.37167L14.5311 5.63186L14.0765 5.98005L14.4182 6.24494L13.9658 6.65857L14.2435 6.8605L13.5064 7.72473L10.4832 6.84102L10.457 6.82692L8.27832 4.98188L13.9115 0.796021Z"
      fill="#763E1A"
    />,
    <path
      d="M0.755448 0.796021L6.38868 4.98188L4.21004 6.82692L4.18384 6.84102L1.16054 7.72473L0.423455 6.86049L0.700979 6.65872L0.24879 6.24494L0.589792 5.98034L0.12841 5.63115L0.477027 5.37081L0 3.07131L0.755448 0.796021Z"
      fill="#763E1A"
    />,
    <path
      d="M10.3348 6.64783L13.5381 7.58412L14.5789 10.8042L11.8332 10.8042L9.94141 10.8282L11.3172 8.13594L10.3348 6.64783Z"
      fill="#F5841F"
    />,
    <path
      d="M4.33205 6.64783L3.34942 8.13594L4.72542 10.8282L2.83452 10.8042H0.09375L1.12863 7.58415L4.33205 6.64783Z"
      fill="#F5841F"
    />,
    <path
      d="M9.36013 2.57104L8.46413 5.00046L8.27399 8.2824L8.20123 9.31105L8.19546 11.9388H6.47033L6.46474 9.31598L6.39174 8.28149L6.20152 5.00046L5.30566 2.57104H9.36013Z"
      fill="#F5841F"
    />,
  ],
})

export const Walletconnect = createIcon({
  displayName: 'Walletconnect',
  viewBox: '0 0 16 16',
  path: [
    <path
      d="M15.4795 8.00002C15.4795 12.1311 12.1306 15.48 7.99953 15.48C3.86844 15.48 0.519531 12.1311 0.519531 8.00002C0.519531 3.86893 3.86844 0.52002 7.99953 0.52002C12.1306 0.52002 15.4795 3.86893 15.4795 8.00002Z"
      fill="#3396FF"
      stroke="#66B1FF"
    />,
    <path
      d="M4.90072 5.95866C6.61236 4.2895 9.38756 4.2895 11.0992 5.95866L11.3052 6.15954C11.3908 6.24298 11.3908 6.3783 11.3052 6.46174L10.6005 7.14894C10.5577 7.19066 10.4884 7.19066 10.4456 7.14894L10.1621 6.8725C8.96796 5.70806 7.03196 5.70806 5.83784 6.8725L5.53424 7.16854C5.49144 7.21026 5.42208 7.21026 5.37928 7.16854L4.6746 6.48134C4.589 6.3979 4.589 6.26258 4.6746 6.17914L4.90072 5.95866ZM12.5566 7.37982L13.1838 7.99142C13.2694 8.07486 13.2694 8.21018 13.1838 8.29362L10.3558 11.0514C10.2702 11.1349 10.1314 11.1349 10.0458 11.0514L8.03872 9.09414C8.01732 9.07326 7.98264 9.07326 7.96124 9.09414L5.95412 11.0514C5.86856 11.1349 5.7298 11.1349 5.6442 11.0514L2.81614 8.29358C2.73056 8.21014 2.73056 8.07482 2.81614 7.99138L3.44332 7.37978C3.52891 7.29634 3.66766 7.29634 3.75325 7.37978L5.7604 9.33706C5.7818 9.35794 5.81648 9.35794 5.83788 9.33706L7.84496 7.37978C7.93052 7.2963 8.06928 7.2963 8.15488 7.37978L10.162 9.33706C10.1834 9.35794 10.2181 9.35794 10.2395 9.33706L12.2467 7.37982C12.3322 7.29634 12.471 7.29634 12.5566 7.37982Z"
      fill="white"
    />,
  ],
})
