import { Image, Text, TooltipProps } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import copyIcon from '../../../assets/icons/copy.svg'
import { HStack } from '../Stack'
import Tooltip from '../Tooltip'

export default function ValueTooltip(props: TooltipProps & { value?: string }) {
  return (
    <Tooltip
      hasArrow={false}
      label={
        <HStack w="max-content" h="full">
          <Text as="span" variant="body-sm/normal" color="white">
            {props.value?.toString()}
          </Text>
          <Image
            width={3}
            height={3}
            src={copyIcon}
            onClick={() => props.value && copy(`${props.value?.toString()}`)}
            filter="invert(1)"
            cursor="pointer"
          />
        </HStack>
      }
      {...props}
    >
      {props.children}
    </Tooltip>
  )
}
