import { Box, Button, Link, Text } from '@chakra-ui/react'
import { HStack, VStack } from '../../../../components/elements/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FixedNumber } from 'ethers'
import { useNavigate } from 'react-router-dom'
import Tooltip from '../../../../components/elements/Tooltip'
import { RolloverAuctionInfo } from '../../../../data/model'
import {
  formatFixedPercentage,
  formatFixedToken,
} from '../../../../helpers/utils'
import { OpenBorrow } from '../../utils'
import { Trans, t } from '@lingui/macro'

export default function ElectedRolloverTooltip({
  borrow,
  electedRolloverAuctionInfo,
  rolloverAuctionLabel,
  isMatured,
  isLoadingMissingRates,
  onElectRolloverOpen,
  showRollEmoji,
  onOpenLoadInterestRate,
}: {
  borrow: OpenBorrow
  electedRolloverAuctionInfo: RolloverAuctionInfo[]
  rolloverAuctionLabel: string
  isMatured: boolean
  isLoadingMissingRates: boolean
  onElectRolloverOpen: () => void
  showRollEmoji?: boolean
  onOpenLoadInterestRate: () => void
}) {
  const navigate = useNavigate()

  return (
    <Tooltip
      minW="320px"
      label={
        <VStack alignItems="flex-start" w="full" color="white">
          <Text as="span" variant="body-sm/bold">
            <Trans>Elected Rollover</Trans>
          </Text>
          <HStack
            justifyContent="space-between"
            w="full"
            mb={4}
            mt="0 !important"
          >
            <Text as="span" variant="body-sm/medium">
              <Trans>Auction:</Trans>
            </Text>
            <Link
              textDecoration="none"
              onClick={() =>
                navigate(
                  `/auctions/${electedRolloverAuctionInfo?.[0]?.auction}/${borrow.chainId}`
                )
              }
            >
              <Text as="span" variant="body-sm/normal" color="blue.4">
                {rolloverAuctionLabel}
              </Text>
            </Link>
          </HStack>
          <HStack justifyContent="space-between" w="full" mt="0 !important">
            <Text as="span" variant="body-sm/medium">
              <Trans>Amount:</Trans>
            </Text>
            <Text as="span" variant="body-sm/normal">
              {formatFixedToken(
                borrow.rolloverAmount,
                borrow.purchaseCurrency.symbol,
                true,
                true
              )}
            </Text>
          </HStack>
          <HStack justifyContent="space-between" w="full" mt="0 !important">
            <Text as="span" variant="body-sm/medium">
              <Trans>Bid Rate:</Trans>
            </Text>
            {borrow.rolloverInterestRate ? (
              <Text as="span" variant="body-sm/normal">
                {formatFixedPercentage(
                  borrow.rolloverInterestRate,
                  undefined,
                  false,
                  FixedNumber.fromString(
                    '1.0',
                    borrow.rolloverInterestRate.format
                  )
                )}
              </Text>
            ) : (
              <Button
                variant="link"
                px="0"
                onClick={onOpenLoadInterestRate}
                isDisabled={isLoadingMissingRates}
                isLoading={isLoadingMissingRates}
              >
                <Text as="span" variant="body-sm/normal" color="blue.4">
                  <Trans>Load missing rate</Trans>
                </Text>
              </Button>
            )}
          </HStack>
          {!isMatured &&
          !borrow.outstandingDebt.isZero() &&
          !borrow.outstandingDebt.isNegative() &&
          !borrow.rolloverLocked ? (
            <Link onClick={() => onElectRolloverOpen()} textDecoration="none">
              <Text as="span" variant="body-sm/normal" color="blue.4">
                <Trans>Edit rollover</Trans>
              </Text>
            </Link>
          ) : null}
        </VStack>
      }
      aria-label={t`rollover-info-tooltip`}
      shouldWrapChildren
    >
      <HStack
        color="gray.4"
        alignItems="center"
        marginTop="2px"
        paddingBottom="2px"
        spacing={0}
      >
        <Text as="span" fontSize="11px">
          Roll{showRollEmoji ? '' : ': '}
        </Text>
        {showRollEmoji ? (
          <Box ml="3px !important">
            <FontAwesomeIcon
              icon={['far', 'question-circle']}
              color="gray.3"
              width="16px"
              height="16px"
              fontSize="11px"
            />
            {': '}
          </Box>
        ) : null}
        <Text as="span" fontSize="11px" ml="3px !important">
          {formatFixedToken(
            borrow.rolloverAmount,
            borrow.purchaseCurrency.symbol,
            true,
            true
          )}
        </Text>
      </HStack>
    </Tooltip>
  )
}
