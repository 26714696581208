import { Flex, Link, MenuItem, Text } from '@chakra-ui/react'
import { useConfig } from '../../../providers/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Address } from '../../../data/model'
import { shortenAddress } from '../../../helpers/utils'
import ClickCopy from '../ClickCopy'
import { ChainId } from '@usedapp/core'
import { VStack, HStack } from '../Stack'

export default function ContractAddressRow({
  title,
  chainId,
  address,
}: {
  title: string
  chainId: ChainId
  address: Address
}) {
  const config = useConfig()
  const contractAddress: string =
    config.chains[chainId].getExplorerAddressLink(address)

  return (
    <MenuItem
      w="auto"
      onClick={() => {
        window.open(contractAddress, '_blank')
      }}
    >
      <Flex>
        <HStack>
          <VStack mr={2}>
            <Text align="right" w="100px" variant="body-xs/bold">
              {title}
            </Text>
            <Link
              href={contractAddress}
              textDecoration="none"
              color="gray.9"
              _hover={{ textDecoration: 'none' }}
              isExternal
            >
              <Text align="right" w="100px" variant="body-xs/normal">
                {shortenAddress(address)}
              </Text>
            </Link>
          </VStack>
          <Flex align="right">
            <HStack mr={1}>
              <Link href={contractAddress} isExternal color="gray.3">
                <FontAwesomeIcon
                  icon={['far', 'arrow-up-right']}
                  width="16px"
                  height="16px"
                />
              </Link>{' '}
              <ClickCopy
                copyText={address}
                helperText={'Copy address'}
                tooltipProps={{
                  placement: 'bottom-end',
                  offset: [8, 8],
                }}
                stopPropagation
              />
            </HStack>
          </Flex>
        </HStack>
      </Flex>
    </MenuItem>
  )
}
