import {
  Box,
  Button,
  Checkbox,
  Skeleton,
  TableContainer,
  Text,
} from '@chakra-ui/react'
import { HStack } from '../../../../components/elements/Stack'

import dayjs from 'dayjs'
import { BigNumber, FixedNumber } from 'ethers'
import { ReactNode, useCallback } from 'react'
import Chip from '../../../../components/elements/Chip'
import {
  Table,
  TableBody as Tbody,
  Td,
  TableFoot as Tfoot,
  TableHead as Thead,
  Tr,
} from '../../../../components/elements/Table'
import TransactionHash from '../../../../components/elements/TransactionHash'
import {
  Currency,
  DraftLoanEdit,
  SubmittedLoanTender,
  TenderId,
} from '../../../../data/model'
import { INTEREST_RATE_DECIMALS } from '../../../../helpers/constants'
import {
  formatBigToken,
  formatFixedPercentage,
  parseUserInput,
} from '../../../../helpers/utils'
import EditableCell from './EditableCell'
import TableCell from './TableCell'
import TableHeaderCell from './TableHeaderCell'

function LoanTableCell({
  textAlign,
  children,
  ...props
}: {
  textAlign?: 'left' | 'right' | 'center'
  children?: ReactNode
} & Omit<Parameters<typeof TableCell>[0], 'n' | 'children'>): JSX.Element {
  return (
    <TableCell n={5} textAlign={textAlign} {...props}>
      {children}
    </TableCell>
  )
}

export default function LoanTendersTable({
  rows,
  purchaseCurrency,
  selectedRows,
  setSelectedRows,
  editing,
  isLoadingMissingRates,
  onOpenLoadInterestRate,
  getBlockExplorerTransactionUrl,
}: {
  rows: SubmittedLoanTender[]
  purchaseCurrency: Currency
  selectedRows: TenderId[]
  setSelectedRows: (rows: TenderId[]) => void
  editing:
    | false
    | {
        edits: Record<TenderId, DraftLoanEdit>
        onChange: (id: TenderId, edit: DraftLoanEdit) => void
      }
  isLoadingMissingRates: boolean
  onOpenLoadInterestRate: () => void
  getBlockExplorerTransactionUrl?: (address: string) => string
}) {
  const toggleAll = useCallback(() => {
    if (rows.length === selectedRows.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(rows.map((row) => row.id))
    }
  }, [rows, selectedRows.length, setSelectedRows])

  const toggleCheckbox = useCallback(
    (id: string) => {
      if (selectedRows.includes(id)) {
        setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
      } else {
        setSelectedRows([...selectedRows, id])
      }
    },
    [selectedRows, setSelectedRows]
  )

  const hasAllRowsWithMissingRates = rows.every((row) => !row.interestRate)

  return (
    <TableContainer position="relative">
      <Table isFixedLayout noPadding>
        <Thead>
          <Tr noBorder>
            <TableHeaderCell w="180px">
              <HStack spacing={4}>
                {!!rows.length && (
                  <Checkbox
                    onChange={toggleAll}
                    isChecked={
                      !!rows.length && selectedRows.length === rows.length
                    }
                    isDisabled={editing !== false}
                  />
                )}
                <Box>Submitted</Box>
              </HStack>
            </TableHeaderCell>
            <TableHeaderCell textAlign="right" w="180px">
              <HStack spacing={1} w="full" justifyContent="flex-end">
                <Text as="span">Supply Amount</Text>
                <Chip size="xs">{purchaseCurrency.symbol}</Chip>
              </HStack>
            </TableHeaderCell>
            <TableHeaderCell textAlign="right" w="188px">
              <Text as="span" pr={1}>
                Min. Rate
              </Text>
              <Chip size="xs">APR</Chip>
            </TableHeaderCell>
            <TableHeaderCell textAlign="right" w="230px">
              Transaction
            </TableHeaderCell>
            <TableHeaderCell
              w="full"
              p={{
                base: '0 397px 0 0',
                xl: '0 522px 0 0',
              }}
            />
          </Tr>
        </Thead>

        {rows.map((row) => (
          <Tbody key={row.id}>
            <Tr
              noBorder
              bg={selectedRows.includes(row.id) ? '#F7FAFC' : 'inherit'}
            >
              <LoanTableCell>
                <HStack spacing={4}>
                  <Checkbox
                    isChecked={selectedRows.includes(row.id)}
                    onChange={() => toggleCheckbox(row.id)}
                    isDisabled={editing !== false}
                  />
                  <Box>
                    {dayjs.unix(row.submittedDate).format('MMMM D, h:mma')}
                  </Box>
                </HStack>
              </LoanTableCell>
              {editing === false || !editing.edits.hasOwnProperty(row.id) ? (
                <LoanTableCell textAlign="right">
                  {formatBigToken(
                    row.amount.shiftedValue,
                    purchaseCurrency.decimals,
                    purchaseCurrency.symbol,
                    true
                  )}
                </LoanTableCell>
              ) : (
                <EditableCell
                  textAlign="right"
                  value={editing.edits[row.id].amount}
                  editingError={
                    editing.edits[row.id].amount
                      ? ((editing.edits[row.id] as DraftLoanEdit)
                          ?.globalAmountError ??
                        (editing.edits[row.id] as DraftLoanEdit)?.amountError)
                      : undefined
                  }
                  onChange={(event) =>
                    editing.onChange(row.id, {
                      ...editing.edits[row.id],
                      amount: parseUserInput(
                        event.target.value,
                        true,
                        purchaseCurrency.decimals
                      ),
                    })
                  }
                />
              )}
              {editing === false || !(row.id in editing.edits) ? (
                <LoanTableCell textAlign="right">
                  <Skeleton
                    isLoaded={!isLoadingMissingRates || !!row.interestRate}
                  >
                    {row.interestRate ? (
                      formatFixedPercentage(
                        row.interestRate,
                        undefined,
                        undefined,
                        FixedNumber.fromString('1.0', 'fixed128x18')
                      )
                    ) : !hasAllRowsWithMissingRates ? (
                      <Button
                        variant="link"
                        onClick={onOpenLoadInterestRate}
                        px={0}
                      >
                        <Text
                          as="span"
                          variant="body-xs/semibold"
                          color="blue.5"
                        >
                          Load missing rate
                        </Text>
                      </Button>
                    ) : (
                      `-`
                    )}
                  </Skeleton>
                </LoanTableCell>
              ) : (
                <EditableCell
                  textAlign="right"
                  value={editing.edits[row.id].interestRate}
                  editingError={
                    editing.edits[row.id].interestRate
                      ? (editing.edits[row.id] as DraftLoanEdit)
                          ?.interestRateError
                      : undefined
                  }
                  onChange={(event) =>
                    editing.onChange(row.id, {
                      ...editing.edits[row.id],
                      interestRate: parseUserInput(
                        event.target.value,
                        true,
                        INTEREST_RATE_DECIMALS
                      ),
                    })
                  }
                />
              )}
              <LoanTableCell textAlign="right">
                <TransactionHash
                  address={row.transaction}
                  getBlockExplorerTransactionUrl={
                    getBlockExplorerTransactionUrl
                  }
                  headChars={6}
                  tailChars={4}
                />
              </LoanTableCell>
              <LoanTableCell />
            </Tr>
          </Tbody>
        ))}
        {!rows.length && (
          <Tbody>
            <Tr noBorder>
              <Td colSpan={5} p="8px 20px 8px 20px" backgroundColor="white">
                You have not submitted any offers in this auction.
              </Td>
            </Tr>
          </Tbody>
        )}
        {!!rows.length && (
          <Tfoot noTopBorder>
            <Tr noBorder>
              <TableHeaderCell isBold>Total</TableHeaderCell>
              <TableHeaderCell isBold textAlign="right">
                {formatBigToken(
                  rows.reduce(
                    (acc, tender) => acc.add(tender.amount.shiftedValue),
                    BigNumber.from(0)
                  ),
                  purchaseCurrency.decimals,
                  purchaseCurrency.symbol,
                  true
                )}
              </TableHeaderCell>
              <TableHeaderCell isBold textAlign="right">
                {hasAllRowsWithMissingRates && (
                  <Button
                    variant="link"
                    onClick={onOpenLoadInterestRate}
                    px={0}
                  >
                    <Text as="span" variant="body-xs/semibold" color="blue.5">
                      Load missing rates
                    </Text>
                  </Button>
                )}
              </TableHeaderCell>
              <TableHeaderCell isBold textAlign="right" colSpan={2} />
            </Tr>
          </Tfoot>
        )}
      </Table>
    </TableContainer>
  )
}
