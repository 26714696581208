import { Image, ImageProps } from '@chakra-ui/react'
import { useTokenIcon } from '../../../data/hooks/use-token-icons'

export default function TokenIcon({
  token,
  boxSize = '16px',
  imageProps,
}: {
  token: string
  boxSize?: string
  imageProps?: ImageProps
}) {
  const src = useTokenIcon(token)
  return <Image boxSize={boxSize} src={src} alt={token} {...imageProps} />
}
