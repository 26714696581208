import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Link,
  ListItem,
  ModalContent,
  ModalContentProps,
  OrderedList,
  Text,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { externalLinks } from '../../../links'
import { useStorage } from '../../../providers/storage'
import { socialLinks } from '../../../socials'
import {
  ToSBody,
  ToSFooter,
  ToSHeader,
  ToSHeading,
  ToSPanelId,
  ToSSubHeading,
} from './modal'

const SelectWalletProviderButton = ({
  children = 'Select wallet provider',
  display = 'flex',
  flexDirection = 'row',
  justifyContent = 'center',
  alignItems = 'center',
  padding = '12px',
  gap = '8px',
  width = '465px',
  height = '32px',
  acceptedColor = 'blue.500',
  notAcceptedColor = 'blue.200',
  acceptedOpacity = '1',
  notAcceptedOpacity = '0.5',
  borderRadius = '6px',
  flex = 'none',
  order = '4',
  alignSelf = 'stretch',
  flexGrow = '0',
  acceptedHover = { bg: 'blue.500' },
  notAcceptedHover = { bg: 'blue.500' },
  fontWeight = '600',
  acceptedTextColor = 'white',
  notAcceptedTextColor = 'blue.800',
  fontSize = '14px',
  lineHeight = '20px',
  accepted,
  ...props
}: {
  accepted: boolean
  acceptedColor?: ButtonProps['backgroundColor']
  notAcceptedColor?: ButtonProps['backgroundColor']
  acceptedOpacity?: ButtonProps['opacity']
  notAcceptedOpacity?: ButtonProps['opacity']
  acceptedHover?: ButtonProps['_hover']
  notAcceptedHover?: ButtonProps['_hover']
  acceptedTextColor?: ButtonProps['textColor']
  notAcceptedTextColor?: ButtonProps['textColor']
} & Omit<
  ButtonProps,
  'backgroundColor' | 'opacity' | '_hover' | 'textColor'
>) => {
  return (
    <Button
      display={display}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      padding={padding}
      gap={gap}
      width={width}
      height={height}
      backgroundColor={accepted ? acceptedColor : notAcceptedColor}
      opacity={accepted ? acceptedOpacity : notAcceptedOpacity}
      borderRadius={borderRadius ?? '6px'}
      flex={flex ?? 'none'}
      order={order ?? '4'}
      alignSelf={alignSelf ?? 'stretch'}
      flexGrow={flexGrow ?? '0'}
      _hover={accepted ? acceptedHover : notAcceptedHover}
      fontWeight={fontWeight ?? '600'}
      textColor={accepted ? acceptedTextColor : notAcceptedTextColor}
      fontSize={fontSize ?? '14px'}
      lineHeight={lineHeight ?? '20px'}
      {...props}
    >
      {children}
    </Button>
  )
}

export const tosAcceptedStorageKey = 'tosAccepted'
export const tosAcceptedStorageValue = 'true'
export const ConditionsOfUse = ({
  panelId = 1,
  onNextPanel,
  ...props
}: { panelId?: ToSPanelId; onNextPanel: () => void } & ModalContentProps) => {
  const { storage } = useStorage()
  const [tosAccepted, setTosAccepted] = useState(false)
  const nextPanel = useCallback(() => {
    storage.setItem(tosAcceptedStorageKey, tosAcceptedStorageValue)
    onNextPanel()
  }, [onNextPanel, storage])
  return (
    <ModalContent {...props}>
      <ToSHeader>
        <ToSHeading>Connect Wallet</ToSHeading>
        <ToSSubHeading>Conditions of Use</ToSSubHeading>
      </ToSHeader>
      <ToSBody panel={panelId}>
        <Box display="block" overflowY="scroll" height="300px">
          <OrderedList padding="10px">
            <ListItem>
              I represent that I have read the following and agree to comply
              with them (where applicable) as a strict condition of my use of
              this Interface to access the Term Finance Protocol:
              <OrderedList styleType="lower-alpha">
                <ListItem>
                  <Link
                    href={externalLinks.termsOfUse}
                    isExternal
                    textDecoration="none"
                  >
                    Terms of Use
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    href={socialLinks.gitbook}
                    isExternal
                    textDecoration="none"
                  >
                    Term Finance Protocol Documentation
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    href={externalLinks.privacyPolicy}
                    isExternal
                    textDecoration="none"
                  >
                    Privacy Policy
                  </Link>
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              I represent that I am not a resident of, or using the Interface
              from, any of the prohibited jurisdictions set forth in the linked
              materials above, including the United States of America.
            </ListItem>
            <ListItem>
              I understand that there is no guarantee by anyone as to the
              accuracy, correctness, and completeness of the information
              provided by this Interface.
            </ListItem>
            <ListItem>
              I represent that I am financially and technically sophisticated
              enough to understand the inherent risks associated with: (a) using
              cryptographic and blockchain-based systems, and that I have a
              working knowledge of the usage and intricacies of digital assets
              such as bitcoin (BTC), ether (ETH), and other digital tokens such
              as those following the ethereum token standard (ERC-20); and (b)
              the lending and borrowing activity enabled by the Term Finance
              Protocol and my use of the Term Finance Protocol.
            </ListItem>
            <ListItem>
              I represent that I have consulted with my independent financial
              and legal advisors regarding my use of the Term Finance Protocol
              and that I am relying solely on my independent judgment and no
              other representations.
            </ListItem>
          </OrderedList>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="left" pt="20px">
          <Checkbox
            height="16px"
            mt="2px"
            mr="8px"
            iconColor="gray.2"
            isChecked={tosAccepted}
            onChange={() => setTosAccepted(!tosAccepted)}
          >
            <Text
              width="452px"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              color="blue.900"
              pr="3px"
            >
              I have read and accept the Conditions of Use
            </Text>
          </Checkbox>
        </Box>
      </ToSBody>
      <ToSFooter>
        <SelectWalletProviderButton
          onClick={tosAccepted ? nextPanel : undefined}
          accepted={tosAccepted}
        />
      </ToSFooter>
    </ModalContent>
  )
}
