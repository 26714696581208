import { Flex, Text } from '@chakra-ui/react'
import { VStack, HStack } from '../Stack'
import { ReactNode } from 'react'

export default function StatsTitle({
  value,
  hideBorder,
  titleSub,
  valueSub,
  children,
}: {
  value?: ReactNode | string
  hideBorder?: boolean
  titleSub?: ReactNode | string
  valueSub?: ReactNode | string
  children: ReactNode | string
}) {
  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      borderBottomWidth={!hideBorder ? 1 : 0}
      borderColor="gray.2"
      px={4}
      py={2}
      mb={2}
    >
      <VStack alignItems="flex-start" justifyContent="center" spacing={0}>
        <Text
          as="span"
          variant="body-sm/semibold"
          color="gray.6"
          w="max-content"
          whiteSpace="nowrap"
        >
          {children}
        </Text>
        {!!titleSub && (
          <Text
            as="span"
            variant="body-xs/normal"
            color="gray.5"
            mt="0 !important"
          >
            {titleSub}
          </Text>
        )}
      </VStack>
      {!!value && (
        <VStack alignItems="flex-end" justifyContent="center" spacing={0}>
          <Flex alignItems="center" px={2} borderRadius="md" bg="gray.2" h={5}>
            <Text
              as="span"
              variant="body-xs/medium"
              color="gray.6"
              whiteSpace="nowrap"
              w="max-content"
            >
              {value}
            </Text>
          </Flex>
          {!!valueSub && (
            <Text
              as="span"
              variant="body-xs/normal"
              color="gray.5"
              mt="0 !important"
            >
              {valueSub}
            </Text>
          )}
        </VStack>
      )}
    </HStack>
  )
}
