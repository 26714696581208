import { captureException } from '@sentry/react'
import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { Client, createClient, fetchExchange } from 'urql'

type ClientsContextValue = {
  clients: Record<string, Client> // A map of subgraph URLs to GraphQL clients
  getClient: (url: string) => Client | undefined
}

const ClientsContext = createContext<ClientsContextValue | undefined>(undefined)

export const GraphProvider = ({
  subgraphUrls,
  children,
}: {
  subgraphUrls: string[]
  children: ReactNode
}) => {
  const [clients, setClients] = useState<Record<string, Client>>({})

  useEffect(() => {
    const graphClients: { [key: string]: Client } = {}
    subgraphUrls.forEach((url) => {
      try {
        graphClients[url] = createClient({
          url,
          exchanges: [fetchExchange],
        })
      } catch (error) {
        console.error(`Failed to create client for ${url}: ${error}`)
        captureException(error)
      }
    })
    setClients(graphClients)
  }, [subgraphUrls])

  const getClient = (url: string) => clients[url]

  return (
    <ClientsContext.Provider value={{ clients, getClient }}>
      {children}
    </ClientsContext.Provider>
  )
}

// Returns a single client
export const useClient = (url: string) => {
  const context = useContext(ClientsContext)
  if (!context) {
    throw new Error('useClient must be used within a GraphProvider')
  }
  const client = context.getClient(url)
  if (!client) {
    // throw new Error(`Client for URL ${url} not found`)
    return undefined
  }
  return client
}

// Returns multiple clients
export const useClients = (urls: string[]) => {
  const context = useContext(ClientsContext)
  if (!context) {
    throw new Error('useClient must be used within a GraphProvider')
  }
  const clients = useMemo(
    () =>
      urls.map((url) => {
        const client = context.getClient(url)
        if (!client) {
          // throw new Error(`Client for URL ${url} not found`)
          return undefined
        }
        return client
      }),
    [urls, context]
  )

  return clients
}
