import { Image, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import closeIcon from '../../../../assets/icons/close.svg'
import { SignUpStatus, SignUpValidation } from '../../../../models/rewards'
import SignUp from '../SignUp'

export default function SignUpModal({
  isOpen,
  socialPoints,
  currentActiveStepSignUp,
  hasPreseasonPoints,
  signUpStatus,
  signUpValidation,
  heading,
  subHeading,
  onClose,
  onLinkWalletNewUser,
  onLinkTwitter,
  onLinkDiscord,
  onBack,
  onSignUpLater,
  onAnalyzeWallet,
  resetSignUpStepsSignal,
}: {
  isOpen: boolean
  socialPoints?: number
  currentActiveStepSignUp?: number
  hasPreseasonPoints: boolean
  signUpStatus?: SignUpStatus
  signUpValidation?: SignUpValidation
  heading?: string
  subHeading?: string
  onClose: () => void
  onLinkWalletNewUser?: (
    referralType?: 'invite' | 'referral',
    code?: string
  ) => Promise<void>
  onLinkTwitter?: (redirPath?: string) => void
  onLinkDiscord?: (redirPath?: string) => void
  onBack?: () => void
  onSignUpLater: () => void
  onAnalyzeWallet: () => void
  resetSignUpStepsSignal?: {}
}) {
  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none" maxW="480px">
        <Image
          position="absolute"
          cursor="pointer"
          top={7}
          right={7}
          w="24px"
          p={'4px'}
          src={closeIcon}
          onClick={() => onClose()}
        />
        <SignUp
          socialPoints={socialPoints}
          currentActiveStepSignUp={currentActiveStepSignUp}
          hasPreseasonPoints={hasPreseasonPoints}
          signUpStatus={signUpStatus}
          signUpValidation={signUpValidation}
          onLinkWalletNewUser={onLinkWalletNewUser}
          onLinkTwitter={onLinkTwitter}
          onLinkDiscord={onLinkDiscord}
          onBack={onBack}
          onSignUpLater={onSignUpLater}
          onAnalyzeWallet={onAnalyzeWallet}
          heading={heading}
          subHeading={subHeading}
          isModal={true}
          resetSignUpStepsSignal={resetSignUpStepsSignal}
        />
      </ModalContent>
    </Modal>
  )
}
