import { JsonRpcProvider, FallbackProvider } from '@ethersproject/providers'
import {
  CallResult,
  ChainId,
  TransactionStatus,
  useSendTransaction,
} from '@usedapp/core'
import { BigNumber, Contract, FixedNumber } from 'ethers'
import { Address, Balance } from '../model'
import MumbaiTestnetTokenABI from '../../abi/testnet-tokens/MumbaiTestnetToken.json'
import TestnetTokenABI from '../../abi/testnet-tokens/TestnetToken.json'
import { MumbaiTestnetToken as LegacyMumbaiTestnetToken } from '../../abi-generated/abi/testnet-tokens/MumbaiTestnetToken'
import { TestnetToken as NewTestnetToken } from '../../abi-generated/abi/testnet-tokens/TestnetToken'
import { useEffect, useMemo, useState } from 'react'
import { bigToFixedNumber, convertChainId } from '../../helpers/conversions'
import { waitForStatus } from '../../helpers/wait'
import { captureException } from '@sentry/react'
import { MultichainCalls, useMultichainCalls } from './helper-hooks'

export function useTestnetTokens(
  account: Address | undefined,
  tokens: { [chainId: string]: Address[] | undefined } | undefined,
  provider: JsonRpcProvider | FallbackProvider | undefined
): {
  testnetTokenBalances: Balance[] | undefined
  ticketsRemaining: number[] | undefined
  claimsMade: number[] | undefined
  mintAmounts: { [symbol: string]: FixedNumber } | undefined
  mintTestnetTokens?: (chainId: number, tokenAddress: Address) => Promise<void>
} {
  const { sendTransaction, state, resetState } = useSendTransaction()

  type TestnetToken = LegacyMumbaiTestnetToken | NewTestnetToken

  const [testnetTokenContracts, setTestnetTokenContracts] = useState(
    {} as {
      [chainId: string]: {
        [testnetTokenAddress: string]: TestnetToken
      }
    }
  )

  const transactionStates = useMemo(
    () =>
      ({}) as {
        mint: TransactionStatus | undefined
      },
    []
  )

  // WARNING: These are used to update in-place so that the mint
  //          method always has access to the latest transaction state.
  useEffect(() => {
    transactionStates.mint = state
  }, [state, transactionStates])

  const contractCalls = useMemo(() => {
    const calls: MultichainCalls = {}
    const testnetContracts: {
      [chainId: string]: {
        [testnetTokenAddress: string]: TestnetToken
      }
    } = {}

    const createContract = (token: string, chainId: string) => {
      const abi =
        convertChainId(chainId) === ChainId.Mumbai
          ? MumbaiTestnetTokenABI
          : TestnetTokenABI
      return new Contract(token, abi, provider) as TestnetToken
    }

    const createCalls = (contracts: TestnetToken[], method: string) =>
      contracts.map((contract) => ({
        contract,
        method,
        args: ['balanceOf', 'claimsMade', 'ticketsRemaining'].includes(method)
          ? [account]
          : [],
      }))

    if (tokens) {
      Object.entries(tokens).forEach(([chainId, chainTokens]) => {
        const contracts = chainTokens?.map((token) => {
          const contract = createContract(token, chainId)
          testnetContracts[chainId] = testnetContracts[chainId] || {}
          testnetContracts[chainId][token] = contract
          return contract
        })

        if (contracts && account) {
          // Create calls for each method type and concatenate them
          calls[chainId] = [
            ...createCalls(contracts, 'balanceOf'),
            ...createCalls(contracts, 'decimals'),
            ...createCalls(contracts, 'symbol'),
            ...createCalls(contracts, 'amount'),
            ...createCalls(
              contracts,
              convertChainId(chainId) === ChainId.Mumbai
                ? 'ticketsRemaining'
                : 'claimsMade'
            ),
          ]
        }
      })
    }

    setTestnetTokenContracts(testnetContracts)
    return calls
  }, [tokens, account, provider])

  // useDebug('testnet token calls: ', contractCalls)

  const blockchainResults = useMultichainCalls(contractCalls)

  const {
    balancesAndErrors,
    decimalsAndErrors,
    symbolsAndErrors,
    mintAmountsAndErrors,
    ticketsOrClaimsAndErrors,
  } = useMemo(() => {
    const balancesResults: {
      [chainId: string]: CallResult<Contract, string>[]
    } = {}
    const decimalsResults: {
      [chainId: string]: CallResult<Contract, string>[]
    } = {}
    const symbolsResults: {
      [chainId: string]: CallResult<Contract, string>[]
    } = {}
    const mintAmountsResults: {
      [chainId: string]: CallResult<Contract, string>[]
    } = {}
    const ticketsOrClaimsResults: {
      [chainId: string]: CallResult<Contract, string>[]
    } = {}

    Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
      // Assuming each chain has the same number of contracts for balances, decimals, and symbols
      const fifthLengths = chainResults.length / 5
      balancesResults[chainId] = chainResults.slice(0, fifthLengths)
      decimalsResults[chainId] = chainResults.slice(
        fifthLengths,
        2 * fifthLengths
      )
      symbolsResults[chainId] = chainResults.slice(
        2 * fifthLengths,
        3 * fifthLengths
      )
      mintAmountsResults[chainId] = chainResults.slice(
        3 * fifthLengths,
        4 * fifthLengths
      )
      ticketsOrClaimsResults[chainId] = chainResults.slice(-fifthLengths)
    })
    return {
      balancesAndErrors: balancesResults,
      decimalsAndErrors: decimalsResults,
      symbolsAndErrors: symbolsResults,
      mintAmountsAndErrors: mintAmountsResults,
      ticketsOrClaimsAndErrors: ticketsOrClaimsResults,
    }
  }, [blockchainResults])

  const balances = useMemo(() => {
    let result: any = []
    if (balancesAndErrors) {
      Object.values(balancesAndErrors).forEach((chainBalances) => {
        result.push(
          ...chainBalances
            .filter(
              (balanceOrError) =>
                balanceOrError?.error === undefined &&
                balanceOrError?.value !== undefined
            )
            .map((balanceOrError) => balanceOrError?.value)
        )
      })
    }
    return result.length > 0 ? result : undefined
  }, [balancesAndErrors])

  const decimals = useMemo(() => {
    const result: any = []
    if (decimalsAndErrors) {
      Object.values(decimalsAndErrors).forEach((chainDecimals) => {
        result.push(
          ...chainDecimals
            .filter(
              (decimalOrError) =>
                decimalOrError?.error === undefined &&
                decimalOrError?.value !== undefined
            )
            .map((decimalOrError) => decimalOrError?.value)
        )
      })
    }
    return result.length > 0 ? result : undefined
  }, [decimalsAndErrors])

  const symbols = useMemo(() => {
    const result: any = []
    if (symbolsAndErrors) {
      Object.values(symbolsAndErrors).forEach((chainSymbols) => {
        result.push(
          ...chainSymbols
            .filter(
              (symbolOrError) =>
                symbolOrError?.error === undefined &&
                symbolOrError?.value !== undefined
            )
            .map((symbolOrError) => symbolOrError?.value)
        )
      })
    }
    return result.length > 0 ? result : undefined
  }, [symbolsAndErrors])

  const mintAmounts = useMemo(() => {
    let result: { [symbol: string]: FixedNumber } = {}
    if (mintAmountsAndErrors && symbolsAndErrors && decimalsAndErrors) {
      Object.entries(mintAmountsAndErrors).forEach(
        ([chainId, chainMintAmounts]) => {
          const symbolMap = symbolsAndErrors[chainId]
            ? Object.fromEntries(
                symbolsAndErrors[chainId]
                  .map((symbolOrError, index) => {
                    const tokenAddress = tokens?.[chainId]?.[index]
                    const symbolValue =
                      symbolOrError?.value !== undefined
                        ? symbolOrError.value[0]
                        : null
                    return symbolValue && tokenAddress
                      ? [tokenAddress, symbolValue]
                      : null
                  })
                  .filter((pair): pair is [Address, string] => pair !== null)
              )
            : {}

          const decimalMap = decimalsAndErrors[chainId]
            ? Object.fromEntries(
                decimalsAndErrors[chainId]
                  .map((decimalOrError, index) => {
                    const tokenAddress = tokens?.[chainId]?.[index]
                    const decimalValue =
                      decimalOrError?.value !== undefined
                        ? decimalOrError.value[0]
                        : null
                    return decimalValue && tokenAddress
                      ? [tokenAddress, decimalValue]
                      : null
                  })
                  .filter((pair): pair is [Address, number] => pair !== null)
              )
            : {}

          chainMintAmounts.forEach((mintAmountOrError, index) => {
            const tokenAddress = tokens?.[chainId]?.[index]
            if (
              mintAmountOrError?.error === undefined &&
              mintAmountOrError?.value !== undefined &&
              tokenAddress
            ) {
              const symbol = symbolMap[tokenAddress]
              const decimal = decimalMap[tokenAddress]
              if (symbol && decimal) {
                result[symbol] = bigToFixedNumber(
                  mintAmountOrError.value[0],
                  decimal
                )
              }
            }
          })
        }
      )
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [decimalsAndErrors, mintAmountsAndErrors, symbolsAndErrors, tokens])

  const { ticketsRemaining, claimsMade } = useMemo(() => {
    let ticketsResult: number[] = []
    let claimsResult: number[] = []

    Object.entries(ticketsOrClaimsAndErrors || {}).forEach(
      ([chainId, chainResults]) => {
        const isMumbai = convertChainId(chainId) === ChainId.Mumbai

        chainResults.forEach((result) => {
          if (
            result &&
            result.error === undefined &&
            result.value !== undefined
          ) {
            const count = BigNumber.from(result.value[0] ?? 0).toString()
            if (isMumbai) {
              ticketsResult.push(parseInt(count, 10))
            } else {
              claimsResult.push(parseInt(count, 10))
            }
          }
        })
      }
    )

    return {
      ticketsRemaining: ticketsResult.length > 0 ? ticketsResult : undefined,
      claimsMade: claimsResult.length > 0 ? claimsResult : undefined,
    }
  }, [ticketsOrClaimsAndErrors])

  const blockchainErrors = useMemo(() => {
    const result: { [chainId: string]: any } = {}
    if (blockchainResults) {
      Object.entries(blockchainResults).forEach(([chainId, chainResults]) => {
        const chainErrors = chainResults
          .filter(
            (valueOrError) => valueOrError && valueOrError?.error !== undefined
          )
          .map((valueOrError) => valueOrError?.error)

        if (chainErrors.length > 0) {
          result[chainId] = chainErrors
        }
      })
    }
    return Object.keys(result).length > 0 ? result : undefined
  }, [blockchainResults])

  useEffect(() => {
    if (blockchainErrors) {
      Object.entries(blockchainErrors).forEach(([chainId, chainError]) => {
        console.error(`Error on ${chainId}: ${chainError}`)
        captureException(chainError)
      })
    }
  }, [blockchainErrors])

  const testnetTokenBalances = useMemo(() => {
    let allBalances: Balance[] = []

    Object.entries(tokens || {}).forEach(([, chainTokens]) => {
      if (!chainTokens || !balances || !decimals || !symbols) {
        return
      }

      chainTokens.forEach((token, index) => {
        const balance = balances?.[index]
        const decimal = decimals?.[index]
        const symbol = symbols?.[index]

        if (balance && decimal && symbol) {
          const decimalsValue = decimal?.[0] ?? 18
          allBalances.push({
            address: token,
            symbol: symbol?.[0] ?? '',
            balance: bigToFixedNumber(
              balance?.[0] ?? BigNumber.from('0'),
              decimalsValue
            ),
            decimals: decimalsValue,
          })
        }
      })
    })

    return allBalances.length > 0 ? allBalances : undefined
  }, [tokens, balances, decimals, symbols])

  const mintTestnetTokens = async (
    chainId: number,
    testnetTokenAddress: Address
  ) => {
    const chainIdString = chainId.toString()
    if (
      !testnetTokenContracts[chainIdString][testnetTokenAddress] ||
      !account
    ) {
      return
    }

    // TODO - clean this up
    const contract =
      chainId === ChainId.Mumbai
        ? (testnetTokenContracts[chainIdString][
            testnetTokenAddress
          ] as LegacyMumbaiTestnetToken)
        : (testnetTokenContracts[chainIdString][
            testnetTokenAddress
          ] as TestnetToken)

    const data = contract.interface.encodeFunctionData('mint', [account])
    await sendTransaction({
      chainId,
      to: testnetTokenAddress,
      from: account,
      data,
    })

    // Wait for state of lock transaction to complete.
    await waitForStatus(
      async () => transactionStates.mint,
      resetState,
      ['Success', 'Exception', 'Fail'],
      100,
      1000,
      contract
    )
  }

  return {
    testnetTokenBalances,
    ticketsRemaining,
    claimsMade,
    mintAmounts,
    mintTestnetTokens,
  }
}
