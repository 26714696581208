import { Meta, StoryFn } from '@storybook/react'
import dayjs from 'dayjs'
import { config as mockConfig } from '../../../../test-helpers/mock-config'

import { Box } from '@chakra-ui/react'
import { ConfigProvider } from '../../../../providers/config'
import { TimeProvider } from '../../../../providers/time'
import { seasonInfo } from '../../../../test-helpers/data-points'
import PointsBreakdown from './index'

export default {
  title: 'Pages/Rewards/Elements/Points Breakdown',
  component: PointsBreakdown,
  decorators: [
    (Story) => (
      <ConfigProvider config={mockConfig}>
        <TimeProvider clock={{ now: () => dayjs('2024-04-03') }}>
          <Box maxW="1440px">
            <Story />
          </Box>
        </TimeProvider>
      </ConfigProvider>
    ),
  ],
} as Meta<typeof PointsBreakdown>

const earningsData = [
  { category: 'Auctions', value: 364000, color: '#5FD0FD' },
  { category: 'Lending', value: 3234, color: '#9AE6B4' },
  { category: 'Invites', value: 364200, color: '#FBD38D' },
  { category: 'Other', value: 4343, color: '#9375FF' },
]

const earningsDataEmpty = [
  { category: 'Auctions', value: 0, color: '#5FD0FD' },
  { category: 'Lending', value: 0, color: '#9AE6B4' },
  { category: 'Invites', value: 0, color: '#FBD38D' },
  { category: 'Other', value: 0, color: '#9375FF' },
]

const Template: StoryFn<typeof PointsBreakdown> = (args) => (
  <PointsBreakdown {...args} />
)

export const Default = Template.bind({})
Default.args = {
  earningsData: earningsDataEmpty,
  totalPoints: 0,
  season: seasonInfo[0],
}
Default.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/file/Ucfx6io3V8MpzlIo86hqFO/Points-Program?type=design&node-id=1%3A26069&mode=design&t=ojQLL0IXMlSg19IG-1',
  },
}

export const IsLoading = Template.bind({})
IsLoading.args = {
  earningsData,
  totalPoints: 41700,
  season: seasonInfo[1],
  isLoading: true,
}
IsLoading.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/design/XF3C6VPTiknXvmiEmxy7oQ/Points-Program?node-id=2210-52258&t=hSMqjMkr5B5YWEaj-0',
  },
}

export const WithProfile = Template.bind({})
WithProfile.args = {
  earningsData,
  totalPoints: 41700,
  season: seasonInfo[1],
}
WithProfile.parameters = {
  design: {
    type: 'figma',
    url: 'https://www.figma.com/design/XF3C6VPTiknXvmiEmxy7oQ/Points-Program?node-id=3811%3A20601&t=qqmbZYCOanuFpll1-1',
  },
}

export const WithThreeDigitsDonut = Template.bind({})
WithThreeDigitsDonut.args = {
  earningsData,
  totalPoints: 417103,
  season: seasonInfo[1],
}

export const WithTwoDigitsDonut = Template.bind({})
WithTwoDigitsDonut.args = {
  earningsData,
  totalPoints: 41148,
  season: seasonInfo[1],
}
