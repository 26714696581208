import { BigNumber } from 'ethers'
import lodash from 'lodash'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { Address, SubmittedBorrowTender } from '../model'
import { useStorage } from '../../providers/storage'
import { deserializeBorrowTender } from '../../services/private-bids-and-offers'
import { PagePortfolioQuery } from '../../gql/graphql'

export function useAllBorrowTenders(
  account: Address | undefined,
  data: Record<string, PagePortfolioQuery> | undefined
): [SubmittedBorrowTender[] | undefined, () => void] {
  const [localResult, setLocalResult] = useState<SubmittedBorrowTender[]>()
  const [result, setResult] = useState<SubmittedBorrowTender[]>()
  const { storage: localStorage } = useStorage()

  const readFromLocalStorage = useCallback(() => {
    if (account === undefined) {
      return
    }
    const tenders: SubmittedBorrowTender[] = lodash
      .range(localStorage.length)
      .map((i) => localStorage.key(i))
      .filter((key): key is string => key?.startsWith('borrow-') ?? false)
      .map((key) => localStorage.getItem(key))
      .filter((val): val is string => val !== null)
      .map((val) => deserializeBorrowTender(val))
      .filter((val): val is SubmittedBorrowTender => {
        // TODO: Validate the the borrow tenders have all required fields.
        return val !== undefined
      })
    setLocalResult(
      tenders.filter((t) => t.borrower.toLowerCase() === account.toLowerCase())
    )
  }, [account, localStorage])

  const subgraphResult = useMemo(() => {
    const allTenders: SubmittedBorrowTender[] = []
    if (data) {
      Object.entries(data).forEach(([chainId, pagePortfolioQuery]) => {
        const tenders = pagePortfolioQuery.termBids?.map(
          (remoteTender) =>
            ({
              type: 'borrow',
              chainId,
              id: remoteTender.id,
              amount: {
                currency:
                  remoteTender?.auction?.term?.purchaseToken?.toString(),
                shiftedValue: BigNumber.from(remoteTender.amount?.toString()),
              },
              auction: remoteTender.auction?.auction as string,
              borrower: remoteTender.bidder as string,
              collateral:
                remoteTender?.bidCollateral?.[0]?.collateralToken &&
                remoteTender?.bidCollateral?.[0]?.amount
                  ? {
                      currency:
                        remoteTender.bidCollateral[0].collateralToken?.toString(),
                      shiftedValue: BigNumber.from(
                        remoteTender.bidCollateral[0].amount
                      ),
                    }
                  : undefined,
            }) as SubmittedBorrowTender
        )
        if (tenders) {
          allTenders.push(...tenders)
        }
      })
    }
    return allTenders
  }, [data])

  const combineResults = useCallback(() => {
    const localIds = localResult?.reduce((acc, val) => {
      acc.add(val.id)
      return acc
    }, new Set<string>())
    const remoteIds = subgraphResult?.reduce((acc, val) => {
      acc.add(val.id)
      return acc
    }, new Set<string>())
    const localOnly = localResult?.filter((val) => !remoteIds?.has(val.id))
    const remoteOnly = subgraphResult?.filter((val) => !localIds?.has(val.id))
    const both = localResult
      ?.filter((val) => remoteIds?.has(val.id))
      .map((local) => {
        const remote = subgraphResult?.find((remote) => remote.id === local.id)
        // Combine localTenders with remoteTenders. Newer data should overwrite older data. Local data should generally overwrite remote data?
        return {
          ...remote,
          ...local,
        }
      })

    setResult([...(remoteOnly || []), ...(both || []), ...(localOnly || [])])
  }, [subgraphResult, localResult])

  useEffect(() => {
    if (account === undefined) {
      return
    }
    window.addEventListener('storage', readFromLocalStorage)
    readFromLocalStorage()

    return () => {
      window.removeEventListener('storage', readFromLocalStorage)
      setResult(undefined)
    }
  }, [readFromLocalStorage, account])

  useEffect(combineResults, [subgraphResult, localResult, combineResults])

  return [result, readFromLocalStorage]
}
